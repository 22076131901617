<template>
    <div>
        <v-card style="height: calc(100vh - 181px); overflow-y: auto;">
            <v-list dense>
                <v-list-item v-for="( item, index ) in items" :key="item.id" @click="selectItem( item )">
                    <v-list-item-content>
                      <v-list-item-title v-bind:class="{ select: ( selItem.id === item.id ) }">
                          {{item.name}}
                      </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card>
    </div>
</template>

<script>

export default {
  name: 'ActList',
  props: {
      items: null,
      selItem: null
  },
  data () {
    return {
        globalState: this.$globalStore.state
    };
  },
  components: {
  },
  methods: 
  {
      selectItem( item )
      {
          this.$emit( 'selectItem', item );
      }
  },
  mounted: function() 
  { 
  },   
  beforeDestroy: function()
  {
  }
}
</script>

<style scoped>
  .scroll 
  {
      overflow-y: auto;
  }
  .select
  {
      font-weight: 700 !important;
  }
  .invalid
  {
      font-style: italic;
  }
</style>
