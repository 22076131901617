/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
module.exports = {
    servers: [ 
        { number: 0, url: 'api/', title: 'Local system', port: 9090, group: 'sdai-group', address: '', ws: '/EDMtruePLM/' }//,
    ]
};

