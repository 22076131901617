<template>
    <v-data-table dense :headers="headers" :items="diffVals" hide-default-footer class="elevation-1 depTbl" 
                  :items-per-page=-1 fixed-header height="calc( 40vh - 70px )">
        <template v-slot:header.name="{ header }">
            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
       </template>

       <template v-slot:item="{ item }">
         <tr v-bind:class="{ removedT: !item.no_value && item.val1 !== item.val2 && item.type === 'Removed', 
                             modifiedT: !item.no_value && item.val1 !== item.val2 && item.type === 'Modified', 
                             addedT: !item.no_value && item.val1 !== item.val2 && item.type === 'Added' }">
             <td v-bind:class="{ hName: item.no_value }">{{ item.name }}</td>
             <td>{{ item.val1 }}</td>
             <td>{{ item.val2 }}</td>
         </tr>
       </template>
    </v-data-table>                             
</template>

<script>

import{ conf } from '@/utils/confTruePLM';

export default {
  name: 'diffDetail',
  props: {
      info: null
  },
  data () 
  {
    return {
        headers: [
            { text: 'message.det_name_col', value: 'name' },
            { text: 'message.det_val1_col', value: 'name' },
            { text: 'message.det_val2_col', value: 'name' }
        ],
        diffVals: [],
        row: null,
        propList: [ { title: 'General', no_value: true }, 
                    { title: 'Name', get_value: function( elem, self ){ return elem.name; } }, 
                    { title: 'Description', get_value: function( elem, self ){ return elem.description; } },
                    { title: 'Last modified by', get_value: function( elem, self ){ return elem.last_modified_by_user; } },
                    { title: 'Last modified date', get_value: function( elem, self ){ return elem.date_last_modified; } },
                    { title: 'Phase', get_value: function( elem, self ){ return elem.phase; } },
                    { title: 'Type', get_value: function( elem, self ){ return self.$utils.clearType( elem.element_type ); } },
                    { title: 'Version', get_value: function( elem, self ){ return elem.version_id; } },
                    { title: 'Structure', no_value: true },
                    { title: 'Nr of children', get_value: function( elem, self ){ return elem.children ? elem.children.length : 0; }, 
                        show: function( self ){ return ( self.row.difference_type === 'Modified' ) ? true : false; } },
                    { title: 'Nr of files', get_value: function( elem, self ){ return elem.data_contents ? elem.data_contents.length : 0; },
                        show: function( self ){ return ( self.row.difference_type === 'Modified' ) ? true : false;  } },
                    { title: 'Assigned properties', no_value: true }
                  ]
    };
  },
  methods: {
      fillDiffDetails( info )
      {
          var self = this;
          self.row = info.row;
          
          self.diffVals = [];
          for( let i = 0; i < self.propList.length; i++ )
          {
              if( !self.propList[ i ].show || self.propList[ i ].show( self ) )
                  self.diffVals.push( { num: ( i + 1 ), name: self.propList[ i ].title, val1: '', val2: '', 
                      no_value: self.propList[ i ].no_value, type:  self.row.difference_type } );
          }
          
          let elem1 = info.elem1 ? info.elem1.element : null;
          for( let i = 0; elem1 && i < self.propList.length; i++ )
          {
              if( !self.propList[ i ].no_value && ( !self.propList[ i ].show || self.propList[ i ].show( self ) ) )
                  self.diffVals[ i ].val1 = self.propList[ i ].get_value( elem1, self );
          }

          for( let i = 0; elem1 && elem1.properties && i < elem1.properties.length; i++ )
          {
              self.procProp( 1, elem1.properties[ i ] );
          } 
     
          let elem2 = info.elem2 ? info.elem2.element : null;
          for( let i = 0; elem2 && i < self.propList.length; i++ )
          {
              if( !self.propList[ i ].no_value && ( !self.propList[ i ].show || self.propList[ i ].show( self ) ) )
                  self.diffVals[ i ].val2 = self.propList[ i ].get_value( elem2, self );
          }

          for( let i = 0; elem2 && elem2.properties && i < elem2.properties.length; i++ )
          {
              self.procProp( 2, elem2.properties[ i ] );
          }
      },
      procProp( ind, prop )
      {
          let propName = this.$utils.clearType( prop.name );
          for( let i = this.propList.length - 2; i < this.diffVals.length; i++ )
          {
              if( this.diffVals[ i ].name === propName )
              {
                  this.diffVals[ i ][ 'val' + ind ] = prop.val;
                  this.diffVals[ i ].type = 'Modified'; 
                  return;
              }
          }
          this.diffVals.push( { num: this.diffVals.length, name: propName, val1: '', val2: '', 
                             type:  'Modified', is_prop: true } );
          this.diffVals[ this.diffVals.length - 1 ][ 'val' + ind ] = prop.val;
          if( ind === 1 )
              this.diffVals[ this.diffVals.length - 1 ].type = 'Removed';
          if( ind === 2 )
              this.diffVals[ this.diffVals.length - 1 ].type = 'Added';              
      },
      getValue( elem, name )
      {
          let val = elem[ name ];
          if( name.indexOf( 'date' ) < 0 )
              val = this.$utils.clearType( val );
          
          return val;
      }
    },
  watch: {
      info() 
      {
          this.fillDiffDetails( this.info );
      }
  },
  computed: {
  },
  components: {
  },
  mounted: function()
  {
  },
  beforeDestroy: function()
  {
  }
}
</script>

<style>
    .depTbl > div > table > tbody > tr > td
    {
        height: 30px;
    }        
    .modifiedT
    {
        color: blue;            
    }
    .removedT
    {
        color: red;            
    }
    .addedT
    {
        color: green;            
    }
    .hName
    {
        font-weight: 700 !important;
    }
</style>
