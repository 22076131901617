<template>
    
            <v-dialog v-model="visFl" persistent max-width="600">
                <v-toolbar flat color="white">
                    <v-toolbar-title>{{ $t( 'message.member_list_title' ) }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn color="green" left dark @click.native="addNew()" v-show="node.is_root_of_subdomain && usersForUse.length > 0">{{ $t( 'message.add_btn' ) }}</v-btn>
                </v-toolbar>
               <v-card>
                   
                <v-card-text>
                    <v-list two-line>
                        <v-list-item v-for="(elem, index) in items" :key="elem.name"  @click="">
                            <v-list-item-content>
                                
                                <v-container v-if="elem.isNew">
                                    <v-layout>
                                      <v-flex xs6 md5 >
                                        <v-select :label="$t( 'message.member_list_user' )" item-text="txt"
                                                item-value="val" v-model="elem.userNew" :items="usersForUse" ></v-select>
                                      </v-flex>
                                      <v-flex xs6 md5 offset-md1>                                          
                                        <v-select :label="$t( 'message.member_list_role' )" item-text="txt" clearable
                                                                    item-value="val" v-model="elem.rolesNew" :items="roles" multiple >
                                           <template v-slot:selection="{ item, index }">
                                                <span v-if="index === 0">{{ item.txt }}</span>
                                                <span v-if="index === 1" class="grey--text caption">
                                                    <span style="padding-left: 7px;">
                                                        +{{ elem.rolesNew.length - 1 }}
                                                    </span>
                                                </span>
                                           </template>
                                        </v-select>    
                                      </v-flex>
                                    </v-layout>
                                </v-container>
                                
                                <v-container v-else-if="elem.isEdit">
                                    <v-layout>
                                      <v-flex xs6 md5 >
                                          <v-text-field :label="$t( 'message.member_list_user' )" v-model="elem.user" readonly></v-text-field>
                                      </v-flex>
                                      <v-flex xs6 md5 offset-md1 >
                                        <v-select :label="$t( 'message.member_list_role' )" item-text="txt" clearable
                                            item-value="val" v-model="elem.rolesNew" :items="roles" multiple >
                                            <template v-slot:selection="{ item, index }">
                                                <span v-if="index === 0">{{ item.txt }}</span>
                                                <span v-if="index === 1" class="grey--text caption">
                                                    <span style="padding-left: 7px;">
                                                        +{{ elem.rolesNew.length - 1 }}
                                                    </span>
                                                </span>
                                            </template>
                                        </v-select>    
                                      </v-flex>
                                    </v-layout>
                                </v-container>
                                        
                                <v-list-item-title v-else>
                                    <b>{{elem.user}}</b> 
                                    ( 
                                    <span v-for="(role, index) in elem.fullRoles" :key="role.val"  @click="">
                                        {{role.txt}} <span class="caption"><i>[{{role.curR}}]</i></span>
                                    </span>
                                    )
                                </v-list-item-title>
                                
                            </v-list-item-content>
                            
                            <v-list-item-action>
                                <span style="white-space: nowrap">
                                    <v-icon @click="editItem( index )" v-show="!elem.isEdit && !elem.isNew">edit</v-icon>
                                    <v-icon @click="submitEdit( index )" v-show="elem.isEdit || elem.isNew">done</v-icon>
                                    <v-icon @click="cancelEdit( index )" v-show="elem.isEdit || elem.isNew">clear</v-icon>
                                    <v-icon @click="deleteItem( index )" v-show="!elem.isNew && node.is_root_of_subdomain">delete_forever</v-icon>
                                </span>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
<!--                    <div v-show="newItem">
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <v-textarea required v-model="content" 
                                                :rules="[v => !!v || $t( 'message.value_required' )]"></v-textarea>                        
                             <v-btn small color="green darken-1" text :disabled="!valid" @click="submitNewNote()">{{ $t( 'message.submit_btn' ) }}</v-btn>
                             <v-btn small color="green darken-1" text @click="cancelNewNote()">{{ $t( 'message.cancel_btn' ) }}</v-btn>
                        </v-form>
                    </div>-->
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click.native="close()">{{ $t( 'message.close_btn' ) }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>   
    
</template>

<script>
    
import Vue from 'vue'
import{ conf } from '@/utils/confTruePLM';

export default {
  name: 'memberList',
  data () 
  {
    return {
        visFl: false,
        items: [],
        roles: [],
        users: [],
        usersForUse: [],
        node: {},
        changed: false,
        valid: true,
    };
  },
  computed: {
  },
  methods: {
      submitEdit( ind )
      {
          var self = this;
          if( this.items[ ind ].isNew )
              this.items[ ind ].user = this.items[ ind ].userNew;
         // console.log( this.items[ ind ] );
          self.$modalDlg.sysDlgs.progressDlg.open();
          conf.memberAdd( this.node, this.items[ ind ].user, this.items[ ind ].rolesNew )
                  .then( ( data ) =>
          {
                self.fillUsers( data );
                self.$modalDlg.sysDlgs.progressDlg.close();
          })
                   .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      cancelEdit( ind )
      {
          if( this.items[ ind ].isNew )
              this.items.splice( 0, 1 );
          else
          {
              this.items[ ind ].isEdit = false;
              Vue.set( this.items, ind, this.items[ ind ] );
          }
      },
      addNew()
      {
          if( this.items.length < this.users.length )
              this.items.unshift( { isNew: true } );
      },
      editItem( ind )
      {
          this.items[ ind ].isEdit = true;
          var r = [];
          if( this.items[ ind ].fullRoles )
          {
                this.items[ ind ].fullRoles.forEach( ( el ) =>
                {
                    r.push( el.val );                            
                });
          }
          this.items[ ind ].rolesNew = r;
          Vue.set( this.items, ind, this.items[ ind ] );
      },
      deleteItem( ind )
      {
          var self = this;
          this.$modalDlg.sysDlgs.confirmDlg.open( 'conf_del_member_title', 'conf_del_member_msg' )
                  .then( () =>
          {
              self.$modalDlg.sysDlgs.progressDlg.open();
              return conf.memberDelete( this.node, this.items[ ind ].user );
          }, () => { throw new Error( 'canceled' ); } )
                  .then( ( data ) =>
          {
                self.fillUsers( data );
                self.$modalDlg.sysDlgs.progressDlg.close();
          })
                   .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              if( err.message !== 'canceled' )
                  self.$eventBus.$emit( 'queryError', err );
          });
      },
      fillUsers( data )
      {
          var self = this;
          self.items = [];
          self.usersForUse = [];
          self.usersForUse = self.usersForUse.concat( self.users );
          
          if( !data || !data.length )
              return;
          
          data.forEach( ( elem ) =>
          {
              for( let i = 0; i < self.usersForUse.length; i++ )
              {
                  if( self.usersForUse[ i ].val === elem.user_name )
                  {
                      self.usersForUse.splice( i, 1 );
                      break;
                  }
              }
              
              if( elem.assigned_roles && elem.assigned_roles.length > 0 )
              {
                  var roleV = '';
                  var fullRoles = [];
                  elem.assigned_roles.forEach( ( role ) =>
                  {
                      var v = null;
                      for( let j = 0; j < self.roles.length; j++ )
                      {
                          if( self.roles[ j ].val === role )
                          {
                              v = self.roles[ j ].txt;
                              fullRoles.push( self.roles[ j ] );
                              break;
                          }
                      }
                      if( !v )
                          v = this.$utils.clearType( role );
                      
                      if( roleV !== '' )
                          roleV += ', ';
                      roleV += v;
                  });
                  this.items.push( { user: elem.user_name, roles: elem.assigned_roles, roleV: roleV, fullRoles: fullRoles } );                                            
              }
              else
                  this.items.push( { user: elem.user_name } );
          });
      },
      open( node )
      {
          let self = this;
          
          this.node = node;
          this.changed = false;
          this.visFl = true;
          this.users = [];

          let st = this.$globalStore.state;
          let dLead = null;
          for( let i = 0; st.subdomainsInfo && i < st.subdomainsInfo.length; i++ )
          {
              if( st.curFolderInfo.is_part_of_subdomain === st.subdomainsInfo[ i ].root_bkdn_elem.instance_id )
              {
                  dLead = st.subdomainsInfo[ i ].project_leader.user_name;
                  break;
              }
          }

          conf.getProjectUsersEnum()
                  .then( ( usersList ) =>
          {
                usersList.forEach( ( user ) =>
                {
                    for( let i = 0; user.roles && i < user.roles.length; i++ )
                    {
                        if( user.val !== dLead && user.roles[ i ] === 'urn:rdl:epm-std:project_member' )    
                        {
                            self.users.push( user );
                            break;
                        }
                    }
                });
          });
                         
          conf.roleList( node )
                  .then( ( data ) =>
          {
             // console.log( data );
              self.roles = [];
              data.forEach( ( elem ) =>
              {
                  if( elem.name !== 'urn:rdl:epm-std:ALL' )
                  {
                      let curR = elem.read ? 'r' : '';
                      curR += elem.write ? 'w' : '';
                      curR += elem.delet ? 'd' : '';

                      self.roles.push( { val: elem.name, txtV: this.$utils.clearType( elem.name ) + ' [' + curR + ']', 
                          curR: curR, txt: this.$utils.clearType( elem.name ) } );
                  }
              });
              return conf.memberList( node );              
          })
                  .then( ( data ) =>
          {
              //console.log( data );
              self.fillUsers( data );                            
          });
          
          return new Promise( ( resolve, reject ) => 
          {
            this.resolve = resolve;
            this.reject = reject;
          });
      },
      close()
      {
          this.visFl = false;
          this.resolve( this.changed );
      }
    }
}
</script>



