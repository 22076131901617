import Vue from 'vue'
import Router from 'vue-router'

import Chain from '@/components/Chain/Chain.vue'
import Diff from '@/components/Diff/Diff.vue'
import ListGr from '@/components/ListGr.vue'
import GraphVis from '@/components/GraphVis.vue'
import Home from '@/components/Home.vue'
import Props from '@/components/Props/Props.vue'
import Login from '@/components/Login.vue'
import RegRequest from '@/components/RegRequest.vue'
import Admin from '@/components/Admin/Admin.vue'
import Actions from '@/components/Actions/Acts.vue'
import Triggers from '@/components/Triggers/Trgs.vue'
import NTasks from '@/components/NTasks/NTasks.vue'
import Orgs from '@/components/Orgs/Orgs.vue'
import Prods from '@/components/Prods/Prods.vue'
import SearchStart from '@/components/SearchStart.vue'
import LoginConnect from '@/components/LoginConnect.vue'
import YaLogin from '@/components/YaLogin.vue'
import YaAuthCallback from '@/components/YaAuthCallback.vue'
import GglLogin from '@/components/GglLogin.vue'
import GglAuthCallback from '@/components/GglAuthCallback.vue'
import KclLogin from '@/components/KclLogin.vue'
import KclAuthCallback from '@/components/KclAuthCallback.vue'

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: '/home',
      name: 'Home',
      component: Home
    },
    {
      path: '/home/:projID',
      name: 'HomeProj',
      component: Home,
      props: true
    },
    {
      path: '/search/:projID',
      name: 'Search',
      component: SearchStart,
      props: true
    },
    {
      path: '/home/:projID/:nodeID',
      name: 'HomeProjNode',
      component: Home,
      props: true
    },
    {
      path: '/home/:projID/:nodeID/:fileID',
      name: 'HomeProjNodeFile',
      component: Home,
      props: true
    },
    {
      path: '/diff',
      name: 'Diff',
      component: Diff
    },
    {
      path: '/chain',
      name: 'Chain',
      component: Chain
    },
    {
      path: '/listgr',
      name: 'ListGr',
      component: ListGr
    },
    {
      path: '/graphvis',
      name: 'GraphVis',
      component: GraphVis
    },
    {
      path: '/admin',
      name: 'Admin',
      component: Admin
    },
    {
      path: '/orgs',
      name: 'Orgs',
      component: Orgs
    },
    {
      path: '/prods',
      name: 'Prods',
      component: Prods
    },
    {
      path: '/actions',
      name: 'Actions',
      component: Actions
    },
    {
      path: '/triggers',
      name: 'Triggers',
      component: Triggers
    },
    {
      path: '/ntasks',
      name: 'NTasks',
      component: NTasks
    },
    {
      path: '/props',
      name: 'Props',
      component: Props
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/regreq',
      name: 'RegReq',
      component: RegRequest
    },
    {
      path: '/yalogin',
      name: 'YaLogin',
      component: YaLogin
    },
    {
      path: '/yacallback',
      name: 'YaAuthCallback',
      component: YaAuthCallback
    },
    {
      path: '/ggllogin',
      name: 'GglLogin',
      component: GglLogin
    },
    {
      path: '/gglcallback',
      name: 'GglAuthCallback',
      component: GglAuthCallback
    },
    {
      path: '/kcllogin',
      name: 'KclLogin',
      component: KclLogin
    },
    {
      path: '/kclcallback',
      name: 'KclAuthCallback',
      component: KclAuthCallback
    },
    {
      path: '/loginconnect',
      name: 'LoginConnect',
      component: LoginConnect
    },
    {
        path: '*',
        redirect: '/home'
    }]
});

//router.beforeEach( async( to, from, next ) => 
//{
//    console.log( to, from, next );
////    if( to.redirectedFrom )
////    {
////        let url = window.location.href;
////        if( url && url.includes( '/gglcallback#/state=' ) )
////        {
////            url = url.replace( '/gglcallback#/state=', '/#/gglcallback?state=' );
////            window.location.replace( url );
////            return false;
////        }
////        localStorage.setItem( 'ggl_info', JSON.stringify( to ) );
////        let url = window.location;
////        url.replace();
////        console.log( window.location );
////        return false;
////    }
//    next();
//});

export default router;
