<template>
              <v-dialog :value="visFl" max-width="330" persistent>
                <v-card>
                  <v-card-title class="headline">
                    {{ $t( 'message.user_ttl' ) }}
                  </v-card-title>
                  <v-card-text>
                        <div><strong>{{ $t( 'message.user_user_ttl' ) }}: </strong> {{curUser}}</div>
                        <div><strong>{{ $t( 'message.user_role_ttl' ) }}: </strong> {{curRole}}</div>
                        <div><strong>{{ $t( 'message.user_name_ttl' ) }}: </strong> {{curRName}}</div>
                        <div><strong>{{ $t( 'message.user_mail_ttl' ) }}: </strong> {{curEMail}}</div>
                        <div><strong>{{ $t( 'message.user_org_ttl' ) }}: </strong> {{curOrg}}</div>
                  </v-card-text>
                  <v-card-actions>
                      <v-btn color="green darken-1" text @click.native="logOut">{{ $t( 'message.logout_btn' ) }}</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn color="green darken-1" text @click.native="close">{{ $t( 'message.ok_btn' ) }}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
</template>

<script>
    
import{ conf } from '@/utils/confTruePLM';

export default {
  name: 'user-info',
  data () 
  {
    return {
        visFl: false,
        curUser: null,
        curRole: null,
        curRName: null,
        curEMail: null,
        curOrg: null
    };
  },
  computed: {
  },
  methods: {
      logOut()
      {
          this.close();
          this.$modalDlg.sysDlgs.logoutDlg.open( null, null, false, true ); 
      },
      close()
      {
          this.visFl = false;
          this.curUser = null;
          this.curRole = null;
          this.curRName = null;
          this.curEMail = null;
          this.curOrg = null;
      },
      open()
      {
         // this.curUser = conf.getUser().name;
          let proj = conf.getProj();
          this.curUser = this.$globalStore.state.curUser;
          if( proj )
              this.curRole = this.$utils.clearType( proj.userType );

          conf.getCurrentUsers()
                  .then( ( data ) =>
          {
              if( !data )
                  return;
              
              this.curRName = data[ 0 ].real_name;
              this.curEMail = data[ 0 ].user_email;
              this.curOrg = data[ 0 ].organization;
              this.curUser = data[ 0 ].user_name;
          });
          
          this.visFl = true;
      }
  },
  mounted: function() 
  { 
  },
  beforeDestroy: function()
  {
  }
}
</script>

<style scoped>
        
    .slide-enter, .slide-leave-to 
    {
        opacity: 0;
    }

    .slide-enter-to, .slide-leave 
    {
        opacity: 1;
    }
      
    .slide-enter-active, .slide-leave-active 
    {
        transition: all .5s ease-out;
    }
</style>


