<template>
    <span>
          <a @click="openRels( 'CHILD' )" v-if="chRels">{{ chRels }} direct</a>
          <span v-if="chRels && prRels">, </span>
          <a @click="openRels( 'PARENT' )" v-if="prRels">{{ prRels }} backward</a>
    </span>
</template>

<script>
    
import{ conf } from '../../utils/confTruePLM';
    
export default {
  name: 'RelsRow',
  props: {
      chRels: 0,
      prRels: 0
  },
  data () 
  {
    return {
        
    };
  },
  computed: 
  {
  },
  methods: 
  {
      openRels( type )
      {
          this.$emit( 'openRels', type );
      }
  },
  components: {
  },
  mounted: function()
  {
  },
  beforeDestroy: function()
  {
  }
}
</script>

<style scoped>
    
</style>
