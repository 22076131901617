var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.visFl),callback:function ($$v) {_vm.visFl=$$v},expression:"visFl"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[(_vm.isNew)?_c('span',[_vm._v(_vm._s(_vm.$t( 'message.user_add_title' )))]):_c('span',[_vm._v(_vm._s(_vm.$t( 'message.user_add_title' )))])]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[(_vm.isNew)?_c('v-select',{attrs:{"label":_vm.$t( 'message.set_user_proj_name' ),"required":"","item-text":"txt","item-value":"val","items":_vm.users,"rules":[v => !!v || _vm.$t( 'message.value_required' )]},model:{value:(_vm.userName),callback:function ($$v) {_vm.userName=$$v},expression:"userName"}}):_c('v-text-field',{attrs:{"label":_vm.$t( 'message.set_user_proj_name' ),"required":"","readonly":"","rules":[v => !!v || _vm.$t( 'message.value_required' )]},model:{value:(_vm.userName),callback:function ($$v) {_vm.userName=$$v},expression:"userName"}}),_c('v-select',{attrs:{"label":_vm.$t( 'message.set_user_proj_role' ),"item-text":"txt","required":"","item-value":"val","items":_vm.roles,"multiple":"","rules":[ ( v ) => 
                  { 
                        if( !v || v.length < 1 ) 
                            return _vm.$t( 'message.value_required' )
                        else
                            return true; 
                  } ]},scopedSlots:_vm._u([{key:"selection",fn:function({ item, index }){return [(index === 0)?_c('span',[_vm._v(_vm._s(item.txt))]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption"},[_c('span',{staticStyle:{"padding-left":"7px"}},[_vm._v(" +"+_vm._s(_vm.userRoles.length - 1)+" ")])]):_vm._e()]}}]),model:{value:(_vm.userRoles),callback:function ($$v) {_vm.userRoles=$$v},expression:"userRoles"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"id":"setUserOnProjOk","color":"green darken-1","text":"","disabled":!_vm.valid},nativeOn:{"click":function($event){return _vm.doAct()}}},[_vm._v(_vm._s(_vm.$t( 'message.ok_btn' )))]),_c('v-btn',{attrs:{"id":"setUserOnProjClose","color":"green darken-1","text":""},nativeOn:{"click":function($event){return _vm.close()}}},[_vm._v(_vm._s(_vm.$t( 'message.cancel_btn' )))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }