<template>
            <v-dialog v-model="visFl" persistent max-width="550">
               <v-card>
                <v-card-title class="headline">
                    <span v-if="forDoc">{{ $t( 'message.quick_doc_search_title' ) }}</span>
                    <span v-else>{{ $t( 'message.quick_search_title' ) }}</span>
                </v-card-title>
                <v-card-text>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-bind="attrs" v-on="on"
                                    :label="$t( 'message.quick_search_msg' )" required v-model="info.val"></v-text-field>
                            </template>
                            <span v-for="num in infoStrs">{{$t( 'message.qsearch_info' + num )}}<br></span>
                            
                          </v-tooltip>
                        
                        <v-checkbox :label="$t( 'message.qsearch_case')" v-model="info.case"></v-checkbox>
                        
                        <div style="display: flex; flex-direction: row;">
                            <div style="flex-grow: 1">
                                <span>{{$t( 'message.qsearch_in') }}</span>
                                <v-checkbox :label="$t( 'message.qsearch_name')" v-model="info.name" class="compact"></v-checkbox>
                                <v-checkbox :label="$t( 'message.qsearch_descr')" v-model="info.descr" class="compact"></v-checkbox>
                                <v-checkbox :label="$t( 'message.qsearch_type')" v-model="info.type" class="compact"></v-checkbox>
                                <v-checkbox :label="$t( 'message.qsearch_prop')" v-model="info.prop" class="compact"></v-checkbox>
                            </div>
                                
                            <div style="flex-grow: 1">
                                <span>{{$t( 'message.qsearch_area') }}</span>
                                <v-radio-group v-model="info.searchArea" class="compact" style="padding-top: 8px;">
                                    <v-radio :label="$t( 'message.qsearch_project_only')" value="proj" class="compact"></v-radio>
                                    <v-radio :label="$t( 'message.qsearch_branch_only')" value="branch" class="compact"></v-radio>
                                    <v-radio v-if="!forDoc" :label="$t( 'message.qsearch_folder_only')" value="folder" class="compact"></v-radio>
                                </v-radio-group>
                            </div>
                        </div>
                        <br>
                        <v-select :label="$t( 'message.qsearch_include' )" v-model="info.prop_list" multiple :disabled="!info.prop"
                                            item-text="txt" item-value="val" :items="props" clearable></v-select>
                    </v-form>    
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click.native="doAct()">{{ $t( 'message.ok_btn' ) }}</v-btn>
                    <v-btn color="green darken-1" text @click.native="close()">{{ $t( 'message.cancel_btn' ) }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>   
</template>

<script>
    
import Vue from 'vue'
import{ conf } from '../../utils/confTruePLM';

export default {
  name: 'QSearchNode',
  data () 
  {
    return {
        infoStrs: [ 1, 2, 3, 4, 5, 6, 7, 8, 9 ],
        globalState: this.$globalStore.state,
        visFl: false,
        info: { val: '*', descr: true, name: true, prop: true, type: true, searchArea: "proj" },
        props: [],
        resolve: null,
        reject: null,
        valid: true,
        forDoc: false
    };
  },
  computed: {
  },
  methods: {
      open( forDoc )
      {
          this.forDoc = forDoc;
          let self = this;
          this.props = [];
          let rootType = 'urn:rdl:epm-std:breakdown_element_type';
          if( forDoc )
              rootType = 'urn:rdl:epm-std:Document_content_type';
          conf.getAllPropsByName( rootType )
                  .then( ( data ) =>
          {
             data.forEach( ( p ) =>
             {
                 this.props.push( { val: p, txt: this.$utils.clearType( p ) } ); 
             });
             this.$utils.sortArray( this.props, ( p ) => ( p.txt ) );
          });
          
          self.visFl = true;
            
          return new Promise( ( resolve, reject ) => 
          {
              self.resolve = resolve;
              self.reject = reject;
          });          
      },
      close()
      {
          this.visFl = false;
          this.reject();
      },
      doAct()
      {
          this.visFl = false;
          this.resolve( this.info );
      }
    }
}
</script>

<style scoped>
    .compact
    {
        height: 25px;
        padding: 0;
        margin: 0;
    }        
</style>



