<template>
    <v-list-item @click.stop="toggleNode">
        <v-list-item-action>
            <v-icon v-if='model.back' large>chevron_left</v-icon>
            <v-img v-else :src="imgSrc" max-width="20"></v-img>
        </v-list-item-action>
        <v-list-item-content v-bind:class="{ back: model.back }">
            <v-list-item-title>
                    <span v-if="!model.back" v-bind:class="{ select: ( globalState.selectedID === model.elemID ) }">
                        {{ model.name ? model.name : model.part.name }} 
                        {{ model.location ? ( '(' + model.location + ')' ) : '' }} 
                    </span>    
            </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
            <v-menu v-if="!model.root && !noMenu && !globalState.actVer" bottom left v-model="showMenu" :close-on-content-click="true">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon  v-if="">
                        <v-icon>more_vert</v-icon>
                    </v-btn>
                </template>
                <v-list dense v-if="model.back">
                   <v-list-item key="1" @click.stop="procFunc( 'createProd' )" v-show="model.parent.root">
                        <v-list-item-title>{{$t( 'message.menu_create_prod' )}}</v-list-item-title>
                   </v-list-item>
                   <v-list-item key="50" @click.stop="procFunc( 'putProdToProd' )" 
                                v-show="!model.parent.root">
                        <v-list-item-title>{{$t( 'message.menu_put_prod' )}}</v-list-item-title>
                   </v-list-item>
                </v-list>
                <v-list dense v-else>
                    <v-list-item key="53" @click.stop="copyProd()">
                         <v-list-item-title>{{$t( 'message.menu_copy_prod' )}}</v-list-item-title>
                    </v-list-item>
                    <v-list-item key="51" @click.stop="removeProd()" v-if="!model.parent.root">
                         <v-list-item-title>{{$t( 'message.menu_remove_prod' )}}</v-list-item-title>
                    </v-list-item>
                    <v-list-item key="52" @click.stop="procFunc( 'deleteProd' )" v-else>
                         <v-list-item-title>{{$t( 'message.menu_delete_prod' )}}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-list-item-action>
    </v-list-item>
</template>

<script>
//import{ conf } from '../utils/confTruePLM';

export default {
  name: 'ProdListRow',
  props: {
      model: null,
      noMenu: false
  },
  data () {
    return {
           globalState: this.$globalStore.state,
           showMenu: false
    };
  },
  components: {
  },
  computed: {
    imgSrc: function()
    { 
        if( this.model.has_parts === 'True' )
            return require( '@/assets/assembly.png' );  
        else
            return require( '@/assets/prod2.png' );  
    }
  },
  methods: 
  {
      toggleNode: function()
      {
    //      console.log( 'prod', this.model, this.model.name, this.model.elemID );
          this.showMenu = false;
          if( this.globalState.selectedID === this.model.elemID )
          {
              if( this.model.back )
              {
                  this.$globalStore.setSelectedID( this.model.parent.parent.elemID );
                  this.$eventBus.$emit( 'goOutNode', this.model.parent );   
              }
              else
                  this.$eventBus.$emit( 'goInProd', this.model );
          }
          else
          {
              this.$globalStore.setSelectedID( this.model.elemID );  
              this.$eventBus.$emit( 'updateProd', this.model );
          }
      },
      procFunc: function( cmd )
      {
          this.showMenu = false;      
          this.$emit( 'procRowMenuCmd', [ cmd , this.model.back ? this.model.parent : this.model ] );       
      },   
      copyProd: function()
      {
          this.procFunc( 'copyProd' );  
      },
      removeProd: function()
      {
          if( this.model.parent.instance_id )
              this.procFunc( 'removeProdFromNode' );
          else
              this.procFunc( 'removeProdFromProd' );
      }
  },
  mounted: function() 
  { 
      var self = this;
//   
//      self.$eventBus.$on( 'sortNodeTab', function()
//      {
//          self.sortDec = !self.sortDec;
//          self.$utils.sortArray( self.nodeList, ( a ) => ( a.bkdn_elem_info.name ), self.sortDec );
//      });
  },   
  beforeDestroy: function()
  {
//      this.$eventBus.$off( 'sortNodeTab' );
  }
}
</script>

<style scoped>
  .scroll 
  {
      overflow-y: auto;
  }
  .select
  {
      font-weight: 700;
  }
</style>
