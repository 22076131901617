<template>
            <v-dialog v-model="visFl" persistent max-width="550">
               <v-card>
                <v-card-title class="headline">
                  {{ $t( 'message.txt_doc_search_title' ) }}
                </v-card-title>
                <v-card-text>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-text-field
                            :label="$t( 'message.txt_doc_search_pattern' )" required v-model="info.val"></v-text-field>
                        <span style="color: black; font-size: small;">{{$t( 'message.txt_doc_search_help' )}}</span>
                    </v-form>    
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click.native="doAct()">{{ $t( 'message.ok_btn' ) }}</v-btn>
                    <v-btn color="green darken-1" text @click.native="close()">{{ $t( 'message.cancel_btn' ) }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>   
</template>

<script>
    
import Vue from 'vue'
import{ conf } from '../../utils/confTruePLM';

export default {
  name: 'TxtSearchDoc',
  data () 
  {
    return {
        globalState: this.$globalStore.state,
        visFl: false,
        info: { val: '' },
        resolve: null,
        reject: null,
        valid: true
    };
  },
  computed: {
  },
  methods: {
      open()
      {
          let self = this;
          self.visFl = true;
            
          return new Promise( ( resolve, reject ) => 
          {
              self.resolve = resolve;
              self.reject = reject;
          });          
      },
      close()
      {
          this.visFl = false;
          this.reject();
      },
      doAct()
      {
          this.visFl = false;
          this.resolve( this.info );
      }
    }
}
</script>

<style scoped>
    .compact
    {
        height: 25px;
        padding: 0;
        margin: 0;
    }        
</style>



