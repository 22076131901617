<template>
    <v-app>
         <progress-view ref="progressDlg"></progress-view>
         <v-main>
            <v-container fluid fill-height >
                <v-layout fill-height>
                    <v-flex lg12 md12>
                        <v-card>
                            <v-card-title >
                              <span class="headline" style="padding-left: 7px;">{{$t( 'message.list_gr_ttl' )}}</span>
                            </v-card-title>
                            <v-card-text v-if="keyName" class="d-flex align-center" style="max-width: 900px;">
                                <div style="display: flex; flex-direction: column">
                                    <div style="display: flex; flex-direction: row; justify-content: flex-start;">
                                        <span style="padding-right: 7px; padding-left: 7px;">{{$t( 'message.list_gr_filter_by' )}}</span>
                                        {{ $utils.clearType( keyName ) }}
                                        <span style="padding-right: 7px; padding-left: 7px;">{{$t( 'message.list_gr_filter_field' )}}</span>
                                    </div>
                                    <div style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center;">
                                        <span style="padding-right: 7px; padding-left: 7px;">{{$t( 'message.list_gr_filter_from' )}}</span>
                                        <v-text-field v-model="fromKey" :type="keyType" style="max-width: 240px;"></v-text-field>
                                        <span style="padding-right: 7px; padding-left: 7px;">{{$t( 'message.list_gr_filter_to' )}}</span>
                                        <v-text-field v-model="toKey" :type="keyType" style="max-width: 240px;"></v-text-field>
<!--                                    </div>
                                    <div style="display: flex; flex-direction: row; justify-content: center;">-->
                                        <span style="padding-left: 7px;">
                                            <v-btn small @click="applyFilter()">{{$t( 'message.list_gr_apply' )}}</v-btn>
                                        </span>
                                        <span style="padding-left: 7px;">
                                            <v-btn small @click="downloadData()">{{$t( 'message.list_gr_download' )}}</v-btn>
                                        </span>
                                    </div>
                                </div>
                            </v-card-text>
                            <v-tabs v-model="aTab" show-arrows ref="tabs" style="padding-left: 16px;" @change="tabChange">
                                <v-tab href="#tab-1" >
                                    {{ $t( 'message.list_gr_dt_table' ) }}
                                </v-tab>
                                <v-tab href="#tab-2">
                                    {{ $t( 'message.list_gr_dt_graph' ) }}
                                </v-tab>
                                <v-tab-item value="tab-1">
                                    <div style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center;">
                                        <v-select v-model="selCols" :items="headers" :menu-props="{ maxHeight: '400' }"
                                            label="Columns" multiple dense item-text="text"
                                            no-data-text="All" return-object clearable @change="checkCols"></v-select>
                                    </div>
                                    
                                    <div style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center;">
                                        <v-checkbox v-model="revOrder" :label="$t( 'message.list_gr_rev_order' )" @change="getData()"></v-checkbox>
                                        <span style="padding-left: 20px"></span>
                                        <v-checkbox v-model="avrVals" :label="$t( 'message.list_gr_avr_vals' )" @change="getAvrData()"></v-checkbox>
                                    </div>
                                    <v-container fill-height fluid>
                                        <v-layout fill-height>
                                            <div style="overflow-x: auto;">
                                                <v-data-table dense :headers="tblHeads" :items="vals" hide-default-footer
                                                              :loading="loading" :server-items-length="totalNum" fixed-header>
                                                </v-data-table>
                                                <div class="text-center pt-2">
                                                    <v-pagination v-model="page" :length="pageCount" total-visible=10></v-pagination>                                        
                                                </div>
                                            </div>
                                        </v-layout>
                                    </v-container>
                                </v-tab-item>
                                <v-tab-item value="tab-2">
                                    <div class="chart">
                                        <LineElem :data="grData" :options="options" ref="line" />
                                        <div class="text-center pt-2" style="display: flex; justify-content: center;">
                                            <div>
                                                <v-pagination v-model="pageG" :length="pageCountG" total-visible=10
                                                    style="    float: left; padding-top: 10px;"></v-pagination>  
                                                <v-text-field v-model="pageSizeG" :label="$t( 'message.list_gr_gr_size' )"
                                                    @change="checkGrSize()" style="padding-left: 15px; float: left; width: 100px;"></v-text-field>   
                                            </div>                                  
                                        </div>
                                    </div>
                                </v-tab-item>
                            </v-tabs>
                        </v-card>                        
                    </v-flex>
                </v-layout>
            </v-container>
         </v-main>
    </v-app>
</template>

<script>

import router from '../router';
import { auth } from '../utils/auth';
import{ conf } from '../utils/confTruePLM';
import Progress from './Dlg/Progress.vue';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Colors,
  Tooltip,
  Legend
} from 'chart.js'

import { Line as LineElem  } from 'vue-chartjs';

ChartJS.register( CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Colors,
  Tooltip,
  Legend )

export default {
  name: 'listGr',
  data () 
  {
    return {
        aTab: 'tab-1',
        nodeID: 0,
        totalNum: 0,
        pageSize: 30,
        page: 1,
        pageCount: 1,
        pageSizeG: 1000,
        pageG: 1,
        pageCountG: 1,
        headers: [],
        loading: true,
        fromKey: null,
        toKey: null,
        fromVal: null,
        toVal: null,
        keyName: null,
        keyType: null,
        isNumDate: false,
        isEForm: false,
        isMSec: false,
        vals: [],
        selCols: [],
        tblHeads: [],
        revOrder: false,
        avrVals: false,
        avrValsQ: false,
        quickAvr: true,
        errCol: { text: '', value: '##er', obj: {}, sortable: false },
        options: {
          responsive: true,
        //   legend: {
        //       display: true
        //   },
          scales: {
                y: {
                    ticks: {
                        callback: function( value )
                        {
                            return value.toFixed( 1 );
                        }
                    }
                }
            }
        },
        grData: { labels: [], datasets: [] }
    };
  },
  methods: {
      tabChange()
      {
          if( this.aTab === 'tab-2' )
            this.getGrData();
          else
            this.getData();
      },
      checkCols()
      {
          if( this.selCols.length > 0 )
                  this.tblHeads = this.selCols.concat( [ this.errCol ] );
          else
              this.tblHeads = this.headers.concat( [ this.errCol ] );  
      },
      fillInfo( prms )
      {
          //this.$refs.progressDlg.open();
          this.nodeID = prms.node;
          this.prop = prms.prop;
          conf.setProj( { repository: prms.rep, in_project: { project_model_id: prms.model } }, prms.role );
          this.getKeyInfo();
          //this.getData();
      },
      getKeyInfo()
      {
          let self = this;
          
          conf.getConcept( this.prop )
                .then( ( data ) =>
          {
              let sType = null;
              for( let i = 0; i < data.classifiers.length; i++ )
              {
                  if( data.classifiers[ i ] !== 'urn:plcs:rdl:TruePLM:List' )
                  {
                      sType = data.classifiers[ i ];
                      break;
                  }
              }
              if( !sType )
                  throw new Error( 'no type' ); 
                  
              return conf.getKeyProp( sType );
          })
                  .then( ( data ) =>
          {
              if( !data || data.length < 1 )
                  throw new Error( 'no key' ); 
              
              self.keyName = data[ 0 ];
              
              return conf.getConcept( data[ 0 ] );
          })
                  .then( ( data ) =>
          {
              self.keyType = 'text';
              for( let i = 0; i < data.classifiers.length; i++ )
              {
                  if( data.classifiers[ i ] === 'urn:plcs:rdl:std:Date_time' )
                  {
                      self.keyType = 'datetime-local';
                      break;
                  }
                  else if( data.classifiers[ i ] === 'urn:plcs:rdl:TruePLM:Numeric_value' )
                  {
                      self.keyType = 'number';
                      break;
                  }
              }
              self.tabChange()
          })
                  .catch( ( err ) =>
          {
              console.log( err );
              self.getData();
          });
          
      },
      downloadData()
      {
          this.applyFilter();
          let cols = [];
          if( this.selCols.length > 0 )
          {
              this.selCols.forEach( c =>
              {
                  cols.push( this.$utils.clearType( c.value ) );
              });
          }

          let self = this;
          
          let items = [ { txt: 'JSON', val: 'json' }, { txt: 'CSV', val: 'csv' } ];
          this.$modalDlg.sysDlgs.confirmOneValDlg.open( 'download_aggr_title', 'download_aggr_msg', 'json', null, null, 'select', items )
                  .then( format => 
          {
                self.$modalDlg.sysDlgs.progressDlg.open();
                return conf.exportAggrProp( this.nodeID, this.prop, this.fromVal, this.toVal, null, null, format, cols );
          } )
                  .then( ( flInfo ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              if( flInfo )
              {
                  let url = conf.getFileLink( flInfo.title, flInfo.source );
                  window.location.href = encodeURI( url );     
              }
          })
                  .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              this.$eventBus.$emit( 'queryError', err );
          });
      },
      applyFilter()
      {
          this.fromVal = this.fromKey;
          if( this.fromVal && this.isNumDate )
          {
              this.fromVal = Date.parse( this.fromVal );
              if( !this.isMSec )
                  this.fromVal /= 1000 ;
              if( this.isEForm )
                this.fromVal = this.fromVal.toExponential();
          }
          
          this.toVal = this.toKey;
          if( this.toVal && this.isNumDate )
          {
              this.toVal = Date.parse( this.toVal );
              if( !this.isMSec )
                  this.toVal /= 1000 ;
              if( this.isEForm )
                this.toVal = this.toVal.toExponential();
          }
          
          this.page = 1;
          this.pageG = 1;

          this.tabChange();
      },
      getAvrData()
      {
          let self = this;
          
          if( !this.avrVals )
              this.getData();
          else
              this.$modalDlg.sysDlgs.confirmOneValDlg.open( 'list_gr_avr_vals_title', 'list_gr_avr_vals_msg', null, null, null, 'check', null, this.quickAvr )
                  .then( ( val ) =>
          {
                self.quickAvr = val;
                self.getData();
          });
          
      },
      getData()
      {
          this.loading = true;

          conf.getAggrProp( this.nodeID, this.prop, this.page, this.pageSize, this.fromVal, this.toVal, this.revOrder, this.avrVals, this.quickAvr )
                  .then( ( data ) =>
          {
              this.totalNum = data.rows;
              this.pageCount = Math.floor( data.rows / this.pageSize ) + 1;
              this.headers = [];
              this.tblHeads = [];

              for( let i = 0; i < data.columns.length; i++ )
              {
                  let obj = data.columns[ i ];
                  let name = this.$utils.clearType( obj.name );
                  let text = name;
                  let unit = obj.units ? this.$utils.clearType( obj.units ) : '';
                  obj.clearUnit = unit;
                  if( unit === 'Cx100' )
                      unit = 'C';
                  if( unit !== '' && unit !== 'EPOCH' )
                      text += ' (' + unit + ')';
                  this.headers.push( { text: text, value: name, obj: obj, sortable: false } );     
              } 

              if( this.selCols.length > 0 )
                  this.tblHeads = this.selCols.concat( [ this.errCol ] );
              else
                  this.tblHeads = this.headers.concat( [ this.errCol ] );
              
              this.vals = [];
              if( data.values ) 
                data.values.forEach( ( vRow ) =>
                {
                    let v = vRow.split( ',' );
                    let row = {};
                    let i = 0, j = 0;
                    this.headers.forEach( ( h ) =>
                    {
                        if( h.obj.types === 'urn:plcs:rdl:std:Date_time' ) 
                        {
                            let d = new Date( 0 );
                            if( isNaN( v[ i ] ) )
                            {
                                if( h.obj.name === this.keyName )
                                    this.isNumDate = false;

                                d.setTime( Date.parse( v[ i++ ] ) );
                            }
                            else
                            {
                                if( h.obj.name === this.keyName )
                                {
                                    this.isNumDate = true;
                                    if( v[ i ].indexOf( 'E' ) > 0 )
                                        this.isEForm = true;
                                }

                               // d.setUTCSeconds( v[ i++ ] );
                               if( v[ i ].length > 10 )
                               {
                                   d.setTime( v[ i++ ] );
                                   this.isMSec = true;
                               }
                               else
                                   d.setTime( v[ i++ ] * 1000 );
                            }

                            let locD = d.toLocaleString();
                            let dEnd = locD.substring( locD.length - 3 );
                            if( dEnd === ' AM' || dEnd === ' PM' )
                                row[ h.value ] = locD.substring( 0, locD.length - 3 ) + '.' + d.getMilliseconds() + dEnd;
                            else
                                row[ h.value ] = locD + '.' + d.getMilliseconds();
                        }
                        else if( h.obj.types === 'urn:plcs:rdl:TruePLM:Integer' || 
                                h.obj.types === 'urn:plcs:rdl:TruePLM:Numeric_value' ) 
                        {
                            if( h.obj.units === 'urn:plcs:rdl:ArrowHead:EPOCH' )
                            {
                                let d = new Date( 0 );
                                d.setUTCSeconds( v[ i++ ] );
                                row[ h.value ] = d.toLocaleString();
                            }
                            else if( h.obj.clearUnit === 'Cx100' )
                                row[ h.value ] = v[ i++ ] / 100.0;
                            else
                                row[ h.value ] = v[ i++ ];
                        }
  //                      else if( h.obj.types === 'urn:plcs:rdl:TruePLM:Real' )
  //                          row[ h.value ] = v[ i++ ];
                        else
                            row[ h.value ] = v[ i++ ];
                        
                        if( row[ h.value ] === '##Error data' )
                            row[ h.value ] = 'Error data';
                    });
                    this.vals.push( row );
                });
                            
              //this.$refs.progressDlg.close();
              this.loading = false;
          }, ( err ) => { this.procError( err ); } );
      },
      checkGrSize()
      {
          if( this.pageSizeG < 10 )
              this.pageSizeG = 100;

          this.getGrData();
      },
      getGrData()
      {
          this.loading = true;

          conf.getAggrProp( this.nodeID, this.prop, this.pageG, this.pageSizeG, this.fromVal, this.toVal )
                  .then( ( data ) =>
          {
              this.pageCountG = Math.floor( data.rows / this.pageSizeG ) + 1;
              let headersG = [];
              this.grData = { labels: [], datasets: [] };
              let grDt = { labels: [], datasets: [] };

              for( let i = 0; i < data.columns.length; i++ )
              {
                  let obj = data.columns[ i ];
                  let name = this.$utils.clearType( obj.name );
                  let text = name;
                  let unit = obj.units ? this.$utils.clearType( obj.units ) : '';
                  obj.clearUnit = unit;
                  if( unit === 'Cx100' )
                      unit = 'C';
                  if( unit !== '' && unit !== 'EPOCH' )
                      text += ' (' + unit + ')';
                  headersG.push( { text: text, value: name, obj: obj, sortable: false } );     
                  
                  if( obj.name !== this.keyName )
                      grDt.datasets.push( { label: text, data: [], borderWidth: 1.8, pointRadius: 2 } );
              } 

              if( data.values ) 
                data.values.forEach( ( vRow ) =>
                {
                    let v = vRow.split( ',' );
                    let row = {};
                    let i = 0, j = 0;
                    headersG.forEach( ( h ) =>
                    {
                        if( h.obj.types === 'urn:plcs:rdl:std:Date_time' ) 
                        {
                            let d = new Date( 0 );
                            if( isNaN( v[ i ] ) )
                            {
                                if( h.obj.name === this.keyName )
                                    this.isNumDate = false;

                                d.setTime( Date.parse( v[ i++ ] ) );
                            }
                            else
                            {
                                if( h.obj.name === this.keyName )
                                {
                                    this.isNumDate = true;
                                    if( v[ i ].indexOf( 'E' ) > 0 )
                                        this.isEForm = true;
                                }

                               // d.setUTCSeconds( v[ i++ ] );
                               if( v[ i ].length > 10 )
                               {
                                   d.setTime( v[ i++ ] );
                                   this.isMSec = true;
                               }
                               else
                                   d.setTime( v[ i++ ] * 1000 );
                            }

                            let locD = d.toLocaleString();
                            let dEnd = locD.substring( locD.length - 3 );
                            if( dEnd === ' AM' || dEnd === ' PM' )
                                row[ h.value ] = locD.substring( 0, locD.length - 3 ) + '.' + d.getMilliseconds() + dEnd;
                            else
                                row[ h.value ] = locD + '.' + d.getMilliseconds();
                        }
                        else if( h.obj.types === 'urn:plcs:rdl:TruePLM:Integer' || 
                                h.obj.types === 'urn:plcs:rdl:TruePLM:Numeric_value' ) 
                        {
                            if( h.obj.units === 'urn:plcs:rdl:ArrowHead:EPOCH' )
                            {
                                let d = new Date( 0 );
                                d.setUTCSeconds( v[ i++ ] );
                                row[ h.value ] = d.toLocaleString();
                            }
                            else if( h.obj.clearUnit === 'Cx100' )
                                row[ h.value ] = v[ i++ ] / 100.0;
                            else
                                row[ h.value ] = v[ i++ ];
                        }
  //                      else if( h.obj.types === 'urn:plcs:rdl:TruePLM:Real' )
  //                          row[ h.value ] = v[ i++ ];
                        else
                            row[ h.value ] = v[ i++ ];
                     
                        if( h.obj.name === this.keyName )
                            grDt.labels.push( row[ h.value ] );
                        else
                            grDt.datasets[ j++ ].data.push( row[ h.value ] );
                    });
                });
                            
            //  console.log( grDt );
              //this.$refs.progressDlg.close();
              this.grData = grDt;
              this.loading = false;
          }, ( err ) => { this.procError( err ); } );
      },
      procError( err )
      {
          let printErr = true;
          
          if( !err.message && err.response )
              err.message = err.response;
          
          if( err.message === 'Session expired!' )
              this.$modalDlg.sysDlgs.logoutDlg.open( 'no_session_msg', 'no_session_ttl', true );  
          else if( err.message === 'noConnInfo' || err.message === 'Connection expired' )
              this.$modalDlg.sysDlgs.logoutDlg.open( 'no_session_msg', 'no_session_ttl', true );
          else if( err.message === 'Wrong proj name' )
              this.$modalDlg.sysDlgs.confirmDlg.openError( 'error_ttl', 'error_proj_name_msg' );
          else if( err.message === 'Rstat Error: 50100' )
              this.$modalDlg.sysDlgs.confirmDlg.openError( 'error_ttl', 'error_50100_msg' );
          else if( err.message === 'baseline_create' )
              this.$modalDlg.sysDlgs.confirmDlg.openError( 'error_ttl', 'error_' + err.message + '_msg' );
          else if( err.message === 'Index: 0, Size: 0' )
          {
              
          }
          else
          {
              err.message = JSON.parse( err.message );
              if( err.message.errors )
              {
                  err.message.errors.forEach( er =>
                  {
                      console.log( er );
                  });
                  printErr = false;
              }
              if( err.message.message === 'linked docs' )
                  this.$modalDlg.sysDlgs.confirmDlg.openError( 'error_ttl', 'error_linked_docs_msg' );
              else
                  this.$modalDlg.sysDlgs.confirmDlg.open( 'error_ttl', 'error_from_server_msg', 
                        null, err.message.message, true, 'error', 'red' );
          }
          
          if( printErr )
              console.log( err.message );
      }
    },
   watch: {
      page: {
        handler() 
        {
            this.getData();
        }
      },
      pageG: {
        handler() 
        {
            this.getGrData();
        }
      }
    },
  computed: {
  },
  components: {
      'progress-view': Progress,
      LineElem
  },
  beforeMount: function()
  {
      auth.checkAuth();
      if( ! auth.getUser().authenticated )
          router.push( '/login' );
  },
  mounted: function()
  {
      var self = this;
      
      this.$eventBus.$on( 'queryError', function( err )
      {
          self.procError( err );
      });
      
      this.fillInfo( this.$route.query );
  },
  beforeDestroy: function()
  {
      this.$eventBus.$off( 'queryError' );
  }
}
</script>

<style scoped>
</style>
