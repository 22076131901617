<template>
        <v-dialog v-model="visFl" persistent max-width="290">
           <v-card>
            <v-card-title class="headline">
              {{ $t( 'message.create_rel_role_title' ) }}
            </v-card-title>
            <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-text-field :label="$t( 'message.create_rel_role_name' )" required v-model="name"
                        :rules="[v => !!v || $t( 'message.value_required' )]"></v-text-field>
                    <v-text-field :label="$t( 'message.create_rel_role_cap1' )" v-model="cap1"></v-text-field>
                    <v-text-field :label="$t( 'message.create_rel_role_cap2' )" v-model="cap2"></v-text-field>
                </v-form>
                <!--<small>*{{ $t( 'message.req_info_msg' ) }}</small>-->
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
                <v-btn color="green darken-1" text :disabled="!valid" @click.native="doAct()">{{ $t( 'message.ok_btn' ) }}</v-btn>
                <v-btn color="green darken-1" text @click.native="close()">{{ $t( 'message.cancel_btn' ) }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>   
</template>

<script>
    
import Vue from 'vue'
import{ conf } from '@/utils/confTruePLM';

export default {
  name: 'createRelRole',
  data () 
  {
    return {
        visFl: false,
        name: '',
        cap1: null,
        cap2: null,
        resolve: null,
        reject: null,
        valid: true
    };
  },
  computed: {
  },
  methods: {
      open()
      {
          
          this.name = null;
          this.cap1 = null;
          this.cap2= null;
          
          this.visFl = true;
          
          return new Promise( ( resolve, reject ) => 
          {
            this.resolve = resolve;
            this.reject = reject;
          });
      },
      close()
      {
          this.visFl = false;
          this.reject();
      },
      doAct()
      {
          if( this.$refs.form.validate() )
          {
            this.visFl = false;
            this.resolve( { name: this.name, cap1: this.cap1, cap2: this.cap2 } );
          }
      }
    }
}
</script>



