<template>
    
<!--      <v-container fluid fill-height>
          <v-layout align-center justify-center>-->
            <v-dialog v-model="visFl" persistent max-width="290">
              <v-card>
                <v-card-title class="headline">
                  {{ $t( 'message.select_role_title' ) }}
                </v-card-title>
                <v-card-text>
                    <v-container grid-list-md>
                        <v-layout wrap>
                          <v-flex xs12>
                            <v-select :label="$t( 'message.select_role_role' )" item-text="txt" item-value="val" v-model="selRole"
                                :items="roles" required :rules="[v => !!v || $t( 'message.value_required' )]"></v-select>
                          </v-flex>
                        </v-layout>
                    </v-container>
                    
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click.native="doAct">{{ $t( 'message.ok_btn' ) }}</v-btn>
                    <v-btn color="green darken-1" text @click.native="close">{{ $t( 'message.cancel_btn' ) }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>   
<!--          </v-layout>
      </v-container>-->
    
</template>

<script>
    
import Vue from 'vue'

export default {
  name: 'selectRole',
  data () 
  {
    return {
        visFl: false,
        resolve: null,
        reject: null,
        selRole: null,
        roles: []
    };
  },
  computed: {
  },
  methods: {
      open( roles )
      {
          this.selRole = null;
          this.roles = [];
          
          if( !roles || !roles.length  || roles.length < 1 )
              return Promise.resolve();
          else if( roles.length < 2 )
              return Promise.resolve( roles[ 0 ] );
          else
          {              
              roles.forEach( ( r ) =>
              {
                  var parts = r.split( ':' );
                  var type = parts[ parts.length - 1 ];
                  type = type.replace( '_', ' ' );
                  type = type.substring( 0, 1 ).toUpperCase() + type.substring( 1 );
                  this.roles.push( { val: r, txt: type } );
              });
              
              this.visFl = true;

              return new Promise( ( resolve, reject ) => 
              {
                  this.resolve = resolve;
                  this.reject = reject;
              });
          }
      },
      close()
      {
          this.visFl = false;
          this.reject();
      },
      doAct()
      {
          this.visFl = false;
          this.resolve( this.selRole );
      }
    }
}
</script>



