<template>
    
<!--      <v-container fluid fill-height>
          <v-layout align-center justify-center>-->
            <v-dialog v-model="visFl" persistent max-width="340">
              <v-card>
                <v-card-title class="headline">
                  {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${title}`) } ) }}
                </v-card-title>
                <v-card-text>
                    {{ $t( 'message.dlg_custom_msg', { cur_msg: $t(`${msg}`) } ) }}
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                    <v-btn id="logOutOk" color="green darken-1" text @click.native="doLogOut">{{ $t( 'message.ok_btn' ) }}</v-btn>
                    <v-btn id="logOutClose" v-if="showCancel" color="green darken-1" text @click.native="close">{{ $t( 'message.cancel_btn' ) }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>   
<!--          </v-layout>
      </v-container>-->
    
</template>

<script>
    
import Vue from 'vue'

import router from '../../router';

import { auth } from '../../utils/auth';
import{ conf } from '../../utils/confTruePLM';

export default {
  name: 'log-out',
  data () 
  {
    return {
        visFl: false,
        titleVal: 'logout_ttl',
        msgVal: 'logout_msg',
        keepState: false,
        showCancel: false
    };
  },
  computed: {
      title() 
      {
           return 'message.' + this.titleVal;
      },
      msg() 
      {
           return 'message.' + this.msgVal;
      }
  },
  methods: {
      open( msgIn, titleIn, keepState, showCancel )
      {
          this.titleVal = titleIn || 'logout_ttl';
          this.msgVal = msgIn || 'logout_msg';          
          this.keepState = keepState;
          this.showCancel = showCancel;
          this.visFl = true;
      },
      close()
      {
          this.visFl = false;
      },
      doLogOut()
      {
          this.close();
          this.$globalStore.clearCurUserRole();
          this.$globalStore.clearCurFolderInfo();
          this.$globalStore.clearCurUser();
          this.$globalStore.clearStompClient();
        //  this.$globalStore.clearInitInfo();
          
          if( !this.keepState )
          {
              conf.clearProj();
              localStorage.removeItem( 'proj_model_id' );
              localStorage.removeItem( 'proj_role' );
          }
          this.keepState = false;
          auth.logout(); 
          router.push( '/login' );
      }
    }
}
</script>



