<template>
    <div ref="tbl_grid"></div>
</template>

<script>
    
import { TabulatorFull as Tabulator } from 'tabulator-tables';
    
export default {
  name: 'searchTbl',
  props: {
      headerInfo: [],
      items: [],
      wHi: null,
      pID: null,
      rowMenuElems: [],
      exportXLSX: false,
      exportJSON: false,
      exportCSV: false,
      groupBy: null,
      dataTreeField: null
  },
  data () {
    return {
              tabulator: null,
              headers: []
    };
  },
  components: {
  },
  methods: 
  {
      configRead( id, type )
      {
        console.log( type );
          let data = localStorage.getItem( id + "-" + type );
          data = data ? JSON.parse( data ) : false;
          if( data )
          {
              let dt = [];
              data.forEach( col =>
              {
                  if( col.title.substring( col.title.length - 4 ) !== 'prop.' )
                      dt.push( col );
              })
              return dt;
          }
          
          return false;
      },
      configWrite( id, type, data )
      {
          localStorage.setItem( id + "-" + type, JSON.stringify( data ) );
      },
      selectRow( row )
      {
          let sRows = this.tabulator.getSelectedRows();
          sRows.forEach( r => { r.deselect(); } );
          row.select();
          this.$emit( 'selectRow', row );
      },
      rowDblClick( row )
      {
            this.$emit( 'rowDblClick', row );
      },
      rowMenu( e, row )
      {
          let self = this;
          let mTxt = '<div role="menuitem" class="v-list-item v-list-item--link theme--light conTabMenu"><div class="v-list-item__title">';
          let rMenu = [];
          this.rowMenuElems.forEach( el =>
          {
              if( !el.func || el.func( row ) )
              {
                    rMenu.push( {
                            label: mTxt + el.txt + '</div></div>',
                            action: function( e, row )
                            {
                                self.$emit( 'rowMenuClick', { cmd: el.cmd, row: row } );
                            }
                        } );
              }
          });
          return rMenu;
      },
      dateCmp( a, b, aRow, bRow, col )
      {
          let aR = aRow.getData();
          let bR = bRow.getData();
          let fNm = col.getDefinition().field + '_dVal';
          return aR[ fNm ] - bR[ fNm ];
      },
      headerMenu()
      {
            let menu = [];
            let columns = this.tabulator.getColumns();

            for( let column of columns )
            {
                let icon = document.createElement( 'span' );
                icon.className = 'material-icons theme--light primary--text';
                icon.textContent = column.isVisible() ? 'check_box' : 'check_box_outline_blank';
                icon.style.color = '#1976d2';

                //build label
                let label = document.createElement( 'span' );
                let title = document.createElement( 'span' );

                title.textContent = " " + column.getDefinition().title;
                title.style.verticalAlign = 'super';

                label.appendChild(icon);
                label.appendChild(title);

                //create menu item
                menu.push({
                    label: label,
                    action: function( e )
                    {
                        //prevent menu closing
                        e.stopPropagation();

                        //toggle current column visibility
                        column.toggle();

                        //change menu item icon
                        if( column.isVisible() )
                            icon.textContent = 'check_box';
                        else
                            icon.textContent = 'check_box_outline_blank';
                    }
                });
            }
            
            setTimeout( () =>
            {
                document.querySelectorAll( 'div.tabulator-menu' ).forEach( ( node ) =>
                {
                    const top = node.getBoundingClientRect().top;
                    if ( top < 0 ) // Only align the menu with the top of the viewport when the menu is too long
                    {
                        node.style.top = 0;
                        node.style.bottom = null;
                    }
                } );
            }, 0 );

           return menu;
      },
      procHeaders()
      {
          let self = this;
          this.headers = [];
          this.headerInfo.forEach( h =>
          {
                this.headers.push( { title: h.text, field: h.value, resizable: true, 
                    headerMenu: this.headerMenu, headerFilter: true, hozAlign: h.hozAlign,
                    sorter: ( h.sorter === 'datetime' || h.sorter === 'size' ) ? function( a, b, aRow, bRow, col )
                    {
                        return self.dateCmp( a, b, aRow, bRow, col );
                    } : h.sorter } );
          });
          
          if( this.tabulator )
                this.tabulator.setColumns( this.headers );
      }
  },
  watch: {
      exportXLSX()
      {
          this.tabulator.download( "xlsx", "SearchList.xlsx", { sheetName: "SearchList" } );
      },
      exportCSV()
      {
          this.tabulator.download( "csv", "SearchList.csv" );
      },
      exportJSON()
      {
          this.tabulator.download( "json", "SearchList.json" );
      },
      headerInfo()
      {
          this.procHeaders();
      },
      items()
      {
          let self = this;
          if( this.tabulator )
            this.tabulator.replaceData( this.items );
          else
          {
                this.tabulator = new Tabulator( this.$refs.tbl_grid, {
                    layout: 'fitDataFill',
                    movableColumns: true,
                    height: this.wHi,
                    reactiveData: true,
                    selectable: 1,
                    groupBy: this.groupBy,
                    dataTree: !!this.dataTreeField,
                    dataTreeChildField: this.dataTreeField,
                    data: this.items, 
                    columns: this.headers,
                    layoutColumnsOnNewData:true,
                    persistenceMode: true,
                    persistenceID: this.pID,
                    persistence:{
                        columns: true
                    },
                    rowContextMenu: this.rowMenu,
                    // persistenceWriterFunc: this.configWrite,
                    // persistenceReaderFunc: this.configRead
                });
                this.tabulator.on( 'rowClick', ( e, row ) => 
                {
                    self.selectRow( row );    
                });
                this.tabulator.on( 'rowDblClick', ( e, row ) => 
                {
                    self.rowDblClick( row );    
                });
                this.tabulator.on( 'rowContext', ( e, row ) => 
                {
                    self.selectRow( row );    
                });
          }
      }
  },
  mounted: function() 
  { 
       this.procHeaders();
  },   
  beforeDestroy: function()
  {
     
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
