<template>
  <div class="info_table">
      <table class="v-datatable v-table theme--light">
          <thead>
              <tr>
                  <th v-for="( h, index ) in headers" :key="index" class="column sortable active asc text-xs-right" :width="h.width">
                       <slot name="headerCell" v-bind:header="h"></slot>
                  </th>
              </tr>              
          </thead>
      </table>
  </div>
</template>

<script>
    
export default {
  name: 'dataTable',
  props:{
      headers: Array,
      data: Array
  },
  data () {
    return {
        
    };
  },
  components: {
  },
  methods: 
  {
  },
  mounted: function() 
  { 
  },   
  beforeDestroy: function()
  {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
