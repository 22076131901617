<template>
        <v-dialog v-model="visFl" persistent max-width="290">
           <v-card>
            <v-card-title class="headline">
              {{ $t( 'message.add_org_title' ) }}
            </v-card-title>
            <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-autocomplete :label="$t( 'message.add_org_org' )" required v-model="selOrg" 
                        :rules="[v => !!v || $t( 'message.value_required' )]" :auto-select-first="true" :clearable="true"
                        :items="orgs" :search-input.sync="search" item-text="title" item-value="org_id">
                        <template v-slot:item="data">
                            <template v-if="data.item.invalid_from">
                                <v-list-item-content v-text="data.item.title" style="font-style: italic" ></v-list-item-content>
                            </template>
                            <template v-else>
                                <v-list-item-content v-text="data.item.title"></v-list-item-content>
                            </template>
                        </template>
                        <template v-slot:selection="data">
                            <span v-if="data.item.invalid_from" style="font-style: italic">{{ data.item.title }}</span>
                            <span v-else="data.item.invalid_from">{{ data.item.title }}</span>
                        </template>
                    </v-autocomplete>
                    <v-select :label="$t( 'message.add_org_role' )" item-text="txt" item-value="val" v-model="selRole" 
                            required :rules="[v => !!v || $t( 'message.value_required' )]" :items="roles"></v-select>
                </v-form>
                <!--<small>*{{ $t( 'message.req_info_msg' ) }}</small>-->
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
                <v-btn id="addOrgToNodeOk" color="green darken-1" text :disabled="!valid" @click.native="doAct()">{{ $t( 'message.ok_btn' ) }}</v-btn>
                <v-btn id="addOrgToNodeClose" color="green darken-1" text @click.native="close()">{{ $t( 'message.cancel_btn' ) }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>   
</template>

<script>
    
import Vue from 'vue'
import{ conf } from '@/utils/confTruePLM';

export default {
  name: 'addOrgToNode',
  data () 
  {
    return {
        visFl: false,
        resolve: null,
        reject: null,
        valid: true,
        orgs: [],
        roles: [],
        search: null,
        selOrg: null,
        selRole: null
    };
  },
  computed: {
  },
  watch: {
      search( val ) 
      {
          if( val && val !== this.selOrg )
              this.fillOrgs( val );
      }
  },
  methods: {
      fillOrgs( val )
      {
          let self = this;
          
          if( val )
              val = '*' + val + '*';
          
          conf.selectOrgs( val )
                  .then( ( data ) =>
          {
              self.orgs = data;
//              data.forEach( org => 
//              {
//                  if( !org.invalid_from )
//                      self.orgs.push( org );
//              });
          });
      },
      open()
      {
          this.selOrg = null;
          this.selRole = null;
          let self = this;
          
          this.fillOrgs();
          
          let reqBase = 'urn:plcs:rdl:std:Organization_or_person_in_organization_assignment';
          conf.getChildTypes( reqBase )
                  .then( ( data ) =>
          {
              self.$utils.sortArray( data );
              data.forEach( ( elem ) =>
              {
                  self.roles.push( { val: elem, txt: this.$utils.clearType( elem ) } );
              });
          });
          
          this.visFl = true;
          
          return new Promise( ( resolve, reject ) => 
          {
            this.resolve = resolve;
            this.reject = reject;
          });
      },
      close()
      {
          this.visFl = false;
          this.reject();
      },
      doAct()
      {
          if( this.$refs.form.validate() )
          {
                this.visFl = false;
                this.resolve( { org: this.selOrg, role: this.selRole } );
          }
      }
    }
}
</script>



