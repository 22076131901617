<template>

    <v-dialog v-model="visFl" scrollable max-width="550">
       <v-card>
        <v-card-title class="headline">
          <span v-if="!info.id">{{ $t( 'message.add_issue_title' ) }}</span>
          <span v-else>{{ info.id }}</span>
          <v-spacer></v-spacer>
          <v-btn small @click.native="newElem()" v-show="active === 'tab-4' || active === 'tab-3'" id="addElemBtn"><v-icon dark>add</v-icon></v-btn>
        </v-card-title>
        <v-card-text id="addIssueCard">
            <v-tabs v-model="active">
                <v-tab href="#tab-1">{{ $t( 'message.add_issue_main_tb' ) }}</v-tab>
                <v-tab v-if="curRoleList.length > 0" href="#tab-2">{{ $t( 'message.add_issue_person_tb' ) }}</v-tab>
                <v-tab v-if="info.id" href="#tab-3">{{ $t( 'message.add_issue_link_tb' ) }}</v-tab>
                <v-tab v-if="info.id" href="#tab-4">{{ $t( 'message.add_issue_comm_tb' ) }}</v-tab>
                <v-tab-item value="tab-1">
                    <v-form ref="form" v-model="valid" lazy-validation>

                        <div style="display: flex; flex-direction: row;">
                            <v-select :label="$t( 'message.add_issue_prn' )" v-model="info.master" item-text="txt" item-value="val" 
                                    :items="issues" @change="masterChanged"></v-select>
                            <v-tooltip bottom v-if="info.master">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" @click="openIssue( info.master )">visibility</v-icon>
                                </template>
                                <span>{{ $t( 'message.add_issue_open_issue' ) }}</span>
                            </v-tooltip>
                        </div>

                        <v-text-field :label="$t( 'message.add_issue_domain' )" v-model="domain"
                            v-if="!info.id"></v-text-field>

                        <v-select :label="$t( 'message.add_issue_type' )" v-model="info.issue_type" 
                                 item-text="txt" item-value="val"  @change="typeChanged"
                                :items="types" required :rules="[v => !!v || $t( 'message.value_required' )]"></v-select>

                        <v-text-field :label="$t( 'message.add_issue_name' )" v-model="info.name" required 
                                        :rules="[v => !!v || $t( 'message.value_required' )]"></v-text-field>

                        <v-textarea :label="$t( 'message.add_issue_descr' )" v-model="info.description"></v-textarea>

                        <v-select :label="$t( 'message.add_issue_status' )" v-model="info.status" item-text="txt" item-value="val" 
                                :items="statuses"></v-select>

                        <v-select :label="$t( 'message.add_issue_prior' )" v-model="info.priority" item-text="txt" item-value="val" 
                                :items="priorities"></v-select>
                    
                        <v-text-field :label="$t( 'message.add_issue_subj' )" v-model="info.subj.name" 
                                readonly v-show="info.subj.name"></v-text-field>

                        <date-input-view :model="info.datetime_IssueDeadline" :label_txt="$t( 'message.add_issue_end_date' )" 
                                        ref="end_date"></date-input-view>
                    </v-form>
              </v-tab-item>
              <v-tab-item value="tab-2">
                <v-card>
                    <v-card-text>
                        <v-select v-for="role in curRoleList" :label="role.txt" v-model="info.personsList[ role.val ]" 
                            item-text="txt" item-value="val" :items="users" clearable
                            :multiple="role.val !== 'urn:plcs:rdl:TruePLM:Responsible'"></v-select>
                    </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item value="tab-3">
                <v-card>
                    <v-card-text>
                        <div v-show="newLink">
                            <v-form ref="form3" v-model="valid3" lazy-validation>
                                <v-select :label="$t( 'message.add_issue_link_role' )" v-model="newLinkRole" required 
                                    :rules="[v => !!v || $t( 'message.value_required' )]"
                                    item-text="txt" item-value="val" :items="linkTypes"></v-select> 
                                <v-select :label="$t( 'message.add_issue_link_issue' )" v-model="newLinkIssue" required 
                                    :rules="[v => !!v || $t( 'message.value_required' )]"
                                    item-text="txt" item-value="val" :items="issues"></v-select>                        
                                <v-btn id="newLinkOkBtn" small color="green darken-1" text :disabled="!valid3" @click="submitNewLink()">{{ $t( 'message.submit_btn' ) }}</v-btn>
                                <v-btn id="newLinkCloseBtn" small color="green darken-1" text @click="newLink = false">{{ $t( 'message.cancel_btn' ) }}</v-btn>
                            </v-form>
                        </div>
                        <div v-if="!$utils.isEmpty( info.chMap )">
                            <v-list v-for="( chList, key ) in info.chMap" :key="key" dense >
                                <v-subheader><span style="font-weight: 800;">{{ getLinkTypeName( key ) }}</span></v-subheader>
                                <v-list-item v-for="(item, i) in chList" :key="i">
                                    <v-list-item-content>
                                        <v-list-item-title v-text="item.related.id + ' \'' + item.related.name + '\''"></v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action style="flex-flow: nowrap;">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon v-bind="attrs" v-on="on" @click="openIssue( item.related.plcs_id )">visibility</v-icon>
                                            </template>
                                            <span>{{ $t( 'message.add_issue_open_issue' ) }}</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon v-bind="attrs" v-on="on" 
                                                    @click="deleteLink( item.related.plcs_id, key )">delete_forever</v-icon>
                                            </template>
                                            <span>{{ $t( 'message.add_issue_del_link' ) }}</span>
                                        </v-tooltip>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                        </div>
                    </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item value="tab-4">
                    <v-card>
                        <v-card-text>
                            <div v-show="newComm">
                                <v-form ref="form2" v-model="valid2" lazy-validation>
                                    <v-textarea required v-model="newCommMsg" id="newCommentText"
                                                        :rules="[v => !!v || $t( 'message.value_required' )]"></v-textarea>                        
                                    <v-btn id="newCommentOkBtn" small color="green darken-1" text :disabled="!valid2" @click="submitNewComm()">{{ $t( 'message.submit_btn' ) }}</v-btn>
                                    <v-btn id="newCommentCloseBtn" small color="green darken-1" text @click="newComm = false">{{ $t( 'message.cancel_btn' ) }}</v-btn>
                                </v-form>
                            </div>
                            <v-list >
                                <v-list-item v-for="(comm, index) in info.comm.slice().reverse()" :key="index"  @click="">
                                    <v-list-item-content>
                                        <v-list-item-subtitle>
                                            {{ comm.who }}, {{ comm.dt }}
                                        </v-list-item-subtitle>
                                        {{comm.message}}
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-icon @click="deleteComm( comm )">delete_forever</v-icon>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn color="green darken-1" text :disabled="!valid || newComm || newLink" @click.native="doAct()" id="addIssueOkBtn">{{ $t( 'message.ok_btn' ) }}</v-btn>
            <v-btn color="green darken-1" text :disabled="newComm || newLink" @click.native="close()" id="addIssueCloseBtn">{{ $t( 'message.cancel_btn' ) }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>   
    
</template>

<script>
    
import Vue from 'vue'

import DateInput from '../DateInput.vue'

import{ conf } from '@/utils/confTruePLM';

export default {
  name: 'addIssue',
  data () 
  {
    return {
        visFl: false,

        curRoleList: [],
        typeRoleMap: {},
        issues: [],
        types: [],
        linkTypes: [],
        statuses: [],
        priorities: [],
        domain: null,
        info: { subj: {}, comm: [], personsList: {} },
        users: [],
        resolve: null,
        reject: null,
        active: null,
        valid: true,
        valid2: true,
        valid3: true,
        newLink: false,
        newLinkIssue: null,
        newLinkRole: null,
        newComm: false,
        newCommMsg: null,
        active: null
    };
  },
  computed: {
  },
  components: {
      'date-input-view': DateInput
  },
  methods: {
      getLinkTypeName( val )
      {
            for( let i = 0; i < this.linkTypes.length; i++ )
            {
                if( this.linkTypes[ i ].val === val )
                    return this.linkTypes[ i ].txt;
            }
            return this.$utils.clearType( val );
      },
      dataUpdate( info )
      {
        console.log( info );
            this.info = { subj: {}, comm: [], personsList: {} };
            this.info.plcs_id = info.plcs_id;
            this.info.id = info.id;
            this.info.domain = this.domain = info.domain;
            this.info.name = info.name;
            this.info.master = info.masterID ? info.masterID : ( info.master_issue ? info.master_issue.relating.plcs_id : null );
            this.info.description = info.description;
            this.info.issue_type = info.issue_type;
            this.info.status = info.status;
            this.info.priority = info.priority;
            this.info.responsible = info.responsible;
            if( info.responsible )
                this.info.personsList[ 'urn:plcs:rdl:TruePLM:Responsible' ] = info.responsible;
            if( info.persons && info.persons.length > 0 )
            {
                for( let i = 0; i < info.persons.length; i++ )
                {
                    let pr = info.persons[ i ];
                    let list = this.info.personsList[ pr.role_urn ] || [];
                    list.push( pr.person );
                    this.info.personsList[ pr.role_urn ] = list;
                }
            }

            for( let i = 0; info.datetimes && i < info.datetimes.length; i++ )
                info[ 'datetime_' + info.datetimes[ i ].role_name ] = info.datetimes[ i ].dt_text;
            
            this.info.datetime_IssueDeadline = info.datetime_IssueDeadline;
            this.info.subj = info.subj || {};
            if( info.subjects && info.subjects.length > 0 && !info.subjects[ 0 ].deleted )
                this.info.subj = info.subjects[ 0 ];
            if( !this.info.subj.name )
                this.info.subj.name = this.info.subj.file_name;
            this.info.chMap = {};
            ( info.child_issues || [] ).forEach( ch =>
            {
                let lList = this.info.chMap[ ch.rel_type ] || [];
                lList.push( ch );
                this.info.chMap[ ch.rel_type ] = lList;
            });
            this.info.comm = info.comments || [];
      },
      typeChanged()
      {
            this.curRoleList = this.typeRoleMap[ this.info.issue_type ] || [];
      },
      masterChanged()
      {
            if( this.info.id )
                return;

            let mID = null;
            for( let i = 0; i < this.issues.length; i++ )
            {
                if( this.info.master === this.issues[ i ].val )
                {
                    mID = this.issues[ i ].txt;
                    break;
                }
            }
            if( mID )
            {
                let ind = mID.lastIndexOf( '-' );
                if( ind > 0 )
                    this.domain = mID.substring( 0, ind );
            }

            console.log( this.info );
      },
      deleteComm( comm )
      {
          let self = this;
          conf.delIssueComm( this.info.plcs_id, comm.plcs_id )
                .then( info => 
          {
                self.dataUpdate( info );
          })
                .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      deleteLink( linkID, role )
      {
          let self = this;
          conf.delIssueChild( this.info.plcs_id, linkID, role )
                .then( info => 
          {
                self.dataUpdate( info );
          })
                .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      submitNewLink()
      {
            let self = this;
            this.newLink = false;
            conf.addIssueChild( this.info.plcs_id, this.newLinkIssue, this.newLinkRole )
                .then( info =>
            {
                self.dataUpdate( info );
            })
                .catch( ( err ) =>
            {
                self.$eventBus.$emit( 'queryError', err );
            });
      },
      submitNewComm()
      { 
          let self = this;
          this.newComm = false;
          conf.addIssueComm( this.info.plcs_id, this.newCommMsg )
                .then( info => 
          {
                self.dataUpdate( info );
          })
                .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });

      },
      newElem()
      {
            if( this.active === 'tab-4' )
            {
                this.valid2 = false;
                this.newCommMsg = null;
                this.newComm = true;
            }
            else if( this.active === 'tab-3' )
            {
                this.valid3 = false;
                this.newLinkIssue = null;
                this.newLinkRole = null;
                this.newLink = true;
            }
      },
      open( info )
      {
          let self = this;
          this.info.subj = {};

          if( info )
            this.dataUpdate( info );

          this.issues = [];
          conf.getIssueList()
                  .then( ( data ) =>
          {
              data.forEach( iss => 
                    self.issues.push( { txt: iss.id + ' \'' + iss.name + '\'', val: iss.plcs_id } )
              );
          })
                .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
          
          this.linkTypes = [];
          conf.getChildTypesInfo( 'urn:plcs:rdl:TruePLM:IssueRelationshipType' )
                .then( data =>
          {
                data.forEach( el => 
                {
                    self.linkTypes.push( { val: el.class_uri, txt: el.name } );
                });
          });

          this.statuses = [];
          conf.getChildTypesInfo( 'urn:plcs:rdl:TruePLM:IssueStatus' )
                .then( data =>
          {
                let nameMap = {};
                data.forEach( el => 
                {
                    nameMap[ el.class_uri ] = el.name;
                });

                if( self.info.status )
                {
                        conf.getChildTypesInContext( 'urn:plcs:rdl:TruePLM:IssueStatus', self.info.status )
                                .then( ( data ) =>
                        {
                            self.$utils.getEnum( data, self.info.status, true ).forEach( ( opt ) =>
                            {
                                opt.txt = nameMap[ opt.val ];
                                self.statuses.push( opt );
                            });
                        } )
                                .catch( ( err ) =>
                        {
                            self.$eventBus.$emit( 'queryError', err );
                        });
                }
                else
                {
                        Promise.all( [ conf.getChildTypes( 'urn:plcs:rdl:TruePLM:IssueStatus' ), 
                                        conf.getDefaultProp( 'urn:plcs:rdl:TruePLM:IssueStatus' ) ] )
                                .then( data =>
                        {
                            self.$utils.getEnumClass( data[ 0 ] ).forEach( ( opt ) =>
                            {
                                opt.txt = nameMap[ opt.val ];
                                self.statuses.push( opt );
                            });
                            self.info.status = self.info.status || data[ 1 ][ 0 ] || self.statuses[ 0 ].val;
                        })
                                .catch( ( err ) =>
                        {
                            self.$eventBus.$emit( 'queryError', err );
                        });
                }
          } );

          this.priorities = [];
          conf.getChildTypes( 'urn:plcs:rdl:TruePLM:IssuePriority' )
                  .then( ( data ) =>
          {
              self.priorities = self.$utils.getEnumClass( data );
          })
                .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });

          this.curRoleList = [];
          this.typeRoleMap = {};
          this.types = [];
          Promise.all( [ conf.getChildTypes( 'urn:plcs:rdl:TruePLM:GenericIssue' ), conf.getChildTypes( 'urn:plcs:rdl:TruePLM:IssueUser' ) ] )
                  .then( data =>
          {
              self.types = self.$utils.getEnumClass( data[ 0 ] );
              let func = [ Promise.resolve( data[ 1 ] ) ];
              data[ 1 ].forEach( r =>
              {
                    func.push( conf.getAllowedChildren( r ) );
              });
              return Promise.all( func );
          })
                .then( data =>
          {
                for( let i = 1; i < data.length; i++ )
                {
                    let typeList = data[ i ] || self.types;
                    let role = { val: data[ 0 ][ i - 1 ], txt: this.$utils.clearType( data[ 0 ][ i - 1 ] ) };
                    typeList.forEach( t =>
                    {
                        let tVal = t.val || t;
                        let roleList = self.typeRoleMap[ tVal ] || [];
                        roleList.push( role );
                        self.typeRoleMap[ tVal ] = roleList;
                    });
                }
                self.typeChanged();
          })
                .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });

          this.users = [];
          conf.getProjectUsersEnum()
                  .then( ( data ) =>
          {
              self.users = data;
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
          
          this.active = 'tab-1';
          this.visFl = true;

          this.$nextTick( () => { this.$utils.scrollTop( 'addIssueCard' ); } );
          
          return new Promise( ( resolve, reject ) => 
          {
            this.resolve = resolve;
            this.reject = reject;
          });
      },
      openIssue( issueID )
      {
          let self = this;
          conf.getIssue( issueID )
                .then( info => 
          {
                self.dataUpdate( info );
                self.active = 'tab-1';
          })
      },
      close()
      {
          this.visFl = false;
          this.reject();
      },
      doAct()
      {
          if( this.$refs.form.validate() )
          {
                this.visFl = false;
                
                this.info.datetime_IssueDeadline = ( this.$refs.end_date && this.$refs.end_date.computedDateFormatted ) ? this.$refs.end_date.computedDateFormatted : undefined;
                if( this.info.datetime_IssueDeadline )
                this.info.datetime_IssueDeadline += ' 00:00:00';
                this.info.domain = this.domain;
                this.resolve( this.info );
          }
      }
    }
}
</script>





