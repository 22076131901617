<template>
        <div>
            <add-action-view ref="addActionDlg"></add-action-view>
            
            <tool-bar-view type="actions"></tool-bar-view>   
            
            <v-main class="main_correction">
                <v-container fill-height fluid v-show="globalState.curFolderName" style="padding-top: 0px; padding-bottom: 24px; margin-top: -5px;">
                 <v-layout row wrap>
                  <v-flex v-show="$vuetify.breakpoint.mdAndUp" lg12 md12>
                      <path-el-view no-save="true"></path-el-view>
                  </v-flex>

                  <Split :gutterSize="5" style="height: calc(100vh - 120px);">
                    <SplitArea :size="30">
                      <v-card height="95%">
                          <v-tabs v-model="active" show-arrows ref="tabs">
                            <v-tab href="#tab-1">
                                {{ $t( 'message.act_list_tab' ) }}
                            </v-tab>
                            <v-tab-item value="tab-1">
                                <act-list-view :selItem="selItem" :items="items" @selectItem="selectItem"></act-list-view>
                            </v-tab-item>
                          </v-tabs>
                      </v-card>
                    </SplitArea> 

                    <SplitArea :size="70">
                        <v-card height="95%">
                            <v-tabs v-model="active" show-arrows ref="tabs">
                                <v-tab href="#tab-1">
                                    {{ $t( 'message.act_info_tab' ) }}
                                </v-tab>
                                <v-tab-item value="tab-1">
                                    <act-info-view :c_item="selItem" @addItem="addItem"
                                                   @delItem="delItem" @editItem="editItem"></act-info-view>
                                </v-tab-item>
                            </v-tabs>
                        </v-card>
                    </SplitArea>
                  </Split>

                </v-layout>
              </v-container>
          </v-main>
        </div>
   
</template>

<script>
    import PathEl from '@/components/Path.vue';
    import AddAction from '@/components/Dlg/AddAction.vue';
    
    import ToolBar from '@/components/ToolBar.vue'
    
    import ActList from './ActList.vue';
    import ActInfo from './ActInfo.vue';
    
    import{ conf } from '@/utils/confTruePLM';
    import { auth } from '@/utils/auth';
    import router from '@/router';
    import { Split, SplitArea } from 'vue-split-panel';
    
export default {
  name: 'Acts',
  data () 
  {
    return {
        active: null,
        globalState: this.$globalStore.state,
        items: [],
        selItem: {}
    };
  },
  methods: {
      addItem()
      {
          let self = this;
          
          this.$refs.addActionDlg.open()
                .then( ( info ) =>
          {
              return conf.defineAction( info );
          }, () => {} )
                .then( ( item ) => 
          {
              if( item )
              {
                  self.selectItem( item );
                  self.initData();
              }
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      delItem()
      {
          let self = this;
          this.$modalDlg.sysDlgs.confirmDlg.open( 'act_del_title', 'act_del_msg', null, self.selItem.name )
                  .then( () =>
          {
              self.$modalDlg.sysDlgs.progressDlg.open();
              return conf.delAction( self.selItem );
          }, () => {} )
                  .then( () => 
          {
              self.selectItem( {} );
              self.initData();
          })
                  .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      editItem()
      {
          let self = this;
          
          this.$refs.addActionDlg.open( this.selItem )
                .then( info =>
          {
              return conf.defineAction( info );
          }, () => {} )
                .then( ( item ) => 
          {
               if( item )
               {
                    self.selectItem( item );
                    self.initData();
               }
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      selectItem( item )
      {
          this.$eventBus.$emit( 'setPath', [ { alt_name: 'Actions' }, { alt_name: item.name } ] );
          this.selItem = item;
      },
      initData()
      {
          let self = this;
          
          if( self.$modalDlg.sysDlgs.progressDlg )
                self.$modalDlg.sysDlgs.progressDlg.open();
            
          conf.listActions()
                .then( ( data ) =>
          {
              self.$utils.sortArray( data, ( p ) => ( p.name || '' ) );
              self.items = [];
              
              data.forEach( ( item ) =>
              {
                  if( item.subdomain )
                      item.subdomain_id = item.subdomain.root_bkdn_elem.instance_id;
                  
                  self.items.push( item );
                  if( self.selItem && item.id === self.selItem.id )
                      self.selItem = item;
                  
              });
              self.$modalDlg.sysDlgs.progressDlg.close();
          })
                .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      selectProj( proj, role )
      {
          if( role )
          {
              conf.setProj( proj, role );
              localStorage.setItem( 'proj_model_id', proj.in_project.project_model_id );
              this.$eventBus.$emit( 'selectedModel' );
              this.$eventBus.$emit( 'setPath', [ { alt_name: 'Actions' } ] );
              this.$globalStore.setCurFolderInfo( proj.in_project );
          }
      },
      updateProjList( info )
      {
            var selProj = null;
            var self = this;
            conf.getRepositoryList()
                    .then( ( data )=>
            {
                for( var i = 0; data && i < data.length; i++ )
                {
                    var proj = data[ i ];
                    if( proj.is_active_in_project && proj.user_registered_as.length > 0 )
                    {
                        if( info && proj.in_project.project_model_id === info.project_model_id )
                        {
                            selProj = proj;
                            break;
                        }
                    }
                }
                
                if( selProj )
                {
                    let selRole = null;
                    let prevRole = localStorage.getItem( 'proj_role' );
                    if( localStorage.getItem( 'proj_model_id' ) === info.project_model_id )
                    {
                        for( var i = 0; selProj.user_registered_as && i < selProj.user_registered_as.length; i++ )
                        {
                            if( selProj.user_registered_as[ i ] === prevRole )
                            {
                                selRole = prevRole;
                                break;
                            }
                        }
                    }
                    if( selRole )
                        self.selectProj( selProj, selRole, true ); 
                    
                    this.initData();
                }

            }, ( err )=>{ self.$eventBus.$emit( 'queryError', err ); } );
      },    
      onClosePage()
      {
          localStorage.setItem( 'closeTime', Date.now() );
      }
  },
  created() 
  {    
       window.addEventListener( 'beforeunload', this.onClosePage );  
  },
  components: {
    'path-el-view': PathEl,
    'add-action-view': AddAction,
    
    'tool-bar-view': ToolBar,

    'Split' : Split,
    'SplitArea': SplitArea,
    
    'act-list-view': ActList,
    'act-info-view': ActInfo
  },
  beforeMount: function()
  {
      auth.checkAuth();
      if( ! auth.getUser().authenticated )
          router.push( '/login' );
      
      if( !conf.getProj() )
          router.push( '/' );
      
      if( !this.$globalStore.isAdmin() )
          router.push( '/home' );
  },
  mounted: function()
  {
     if(  auth.getUser().authenticated )
     {
          var projModelID = localStorage.getItem( 'proj_model_id' );
          if( projModelID )
              this.updateProjList( { project_model_id: projModelID } );        
          else
              this.updateProjList();        
     }
  },
  beforeDestroy: function()
  {

  }
}
</script>

<style>
    .main_correction > div.v-main__wrap
    {
        height: 90%;            
    }
    
    .close-menu ul
    {
        min-width: 2em;
        white-space: nowrap;
    }
    
    .multipane.cust.layout-v .multipane-resizer 
    {
        margin: 0; left: 0; /* reset default styling */
        width: 5px;
        background: graytext;
    }
</style>
