<template>
    
  <v-app id="auth_callback">
    <v-main>
       
    </v-main>
    
  </v-app>
    
</template>

  <script>
      
  import router from '@/router';
  import { auth } from '@/utils/auth';
  
  export default {
    data() {
      return {
      };
    },
    computed: {
              
    },
    methods: 
    {
      
    },
    beforeCreate: function()
    {
       let state = this.$route.query.state;
       let reqData = JSON.parse( localStorage.getItem( 'yandex-' + state ) || '{}' );
       if( reqData && reqData.state === state )
       {
           localStorage.removeItem( 'yandex-' + state );
           auth.loginSSO( 'yandex', this.$route.query.access_token )
                   .then( data => 
           {
               if( data.status )
                   router.push( { name: 'Home' } );
               else
               {
                   this.$globalStore.setSsoId( data.sso_id );
                   router.push( '/loginconnect' );
               }
           })
                    .catch( ( err ) => 
           {
               console.log( err );
               router.push( { name: 'Home' } );
           });
    
//           fetch( 'https://login.yandex.ru/info?format=json&oauth_token=' 
//                   + this.$route.query.access_token, { method: 'GET', mode: 'cors' } )
//                   .then( resp => resp.json() )
//                   .then( data => 
//           {
//               console.log( data );
//           });
       }
             
    },
    components: {
        
    }
  }
  </script>