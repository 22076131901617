<template>
    <div id="app">
        <v-app>
            <user-info-view ref="userInfoDlg"></user-info-view>
            <about-info-view ref="infoDlg"></about-info-view>
            <confirm-view ref="confirmDlg"></confirm-view>
            <log-out-view ref="logoutDlg"></log-out-view>
            <add-notify-view ref="addNotifyDlg"></add-notify-view>
            <confirm-one-val-view ref="confirmOneValDlg"></confirm-one-val-view>
            <progress-view ref="progressDlg"></progress-view>
            
            <router-view></router-view> 
        </v-app>
    </div>
</template>

<script>
    
import{ conf } from '@/utils/confTruePLM';
import { auth } from '@/utils/auth';

import AboutInfo from '@/components/Dlg/AboutInfo.vue'
import LogOut from '@/components//Dlg/LogOut.vue'
import Confirm from '@/components/Dlg/Confirm.vue'
import AddNotify from '@/components/Dlg/AddNotify.vue';
import ConfirmOneVal from '@/components/Dlg/ConfirmOneVal.vue'
import Progress from '@/components/Dlg/Progress.vue'
import UserInfo from '@/components/Dlg/UserInfo.vue';
    
export default {
  name: 'App',
  data() {
      return {
        settings: require( process.env.VUE_APP_CONFIG_ROOT + '/settings' )          
      };
    },
  methods:
  {
      procError( err )
      {
          let printErr = true;
          
          if( !err.message && err.response )
              err.message = err.response;
          
          if( err.message === 'Session expired!' )
              this.$modalDlg.sysDlgs.logoutDlg.open( 'no_session_msg', 'no_session_ttl', true );  
          else if( err.message === 'noConnInfo' || err.message === 'Connection expired' )
              this.$modalDlg.sysDlgs.logoutDlg.open( 'no_session_msg', 'no_session_ttl', true );
          else if( err.message === 'Wrong proj name' )
              this.$modalDlg.sysDlgs.confirmDlg.openError( 'error_ttl', 'error_proj_name_msg' );
          else if( err.message === 'Rstat Error: 50100' )
              this.$modalDlg.sysDlgs.confirmDlg.openError( 'error_ttl', 'error_50100_msg' );
          else if( err.message === 'baseline_create' )
              this.$modalDlg.sysDlgs.confirmDlg.openError( 'error_ttl', 'error_' + err.message + '_msg' );
          else if( err.message === 'Index: 0, Size: 0' )
          {
              
          }
          else
          {
              err.message = JSON.parse( err.message );
              if( err.message.errors )
              {
                  err.message.errors.forEach( er =>
                  {
                      console.log( er );
                  });
                  printErr = false;
              }
              if( err.message.message === 'linked docs' )
                  this.$modalDlg.sysDlgs.confirmDlg.openError( 'error_ttl', 'error_linked_docs_msg' );
              else
                  this.$modalDlg.sysDlgs.confirmDlg.open( 'error_ttl', 'error_from_server_msg', 
                        null, err.message.message, true, 'error', 'red' );
          }
          
          if( printErr )
              console.log( err.message );
      }
  },
  components: {
    'log-out-view': LogOut,
    'about-info-view': AboutInfo,
    'confirm-view': Confirm,
    'confirm-one-val-view': ConfirmOneVal,
    'add-notify-view': AddNotify,
    'progress-view': Progress,
    'user-info-view': UserInfo
  },
  beforeMount: function()
  {
      let serverInfo = this.settings.servers[ 0 ];
      auth.getInitInfo( serverInfo )
              .then( ( info ) =>
      {
//          console.log( info );
          this.$globalStore.setInitInfo( info );
      });
      this.$globalStore.checkPlatform();
  },
  mounted: function()
  {
      this.$modalDlg.sysDlgs[ 'progressDlg' ] = this.$refs.progressDlg;
      this.$modalDlg.sysDlgs[ 'confirmDlg' ] = this.$refs.confirmDlg;
      this.$modalDlg.sysDlgs[ 'confirmOneValDlg' ] = this.$refs.confirmOneValDlg;
      this.$modalDlg.sysDlgs[ 'userInfoDlg' ] = this.$refs.userInfoDlg;
      this.$modalDlg.sysDlgs[ 'logoutDlg' ] = this.$refs.logoutDlg;
      this.$modalDlg.sysDlgs[ 'infoDlg' ] = this.$refs.infoDlg;
      this.$modalDlg.sysDlgs[ 'addNotifyDlg' ] = this.$refs.addNotifyDlg;
      
      let self = this;
      this.$eventBus.$on( 'queryError', function( err )
      {
          self.procError( err );
      });
      
      this.$eventBus.$emit( 'appReady' );
  },
  beforeDestroy: function()
  {
      this.$eventBus.$off( 'queryError' );
  }
}
</script>

<style>
    .cut-text 
    { 
      text-overflow: ellipsis;
      overflow: hidden; 
      max-width: 130px; 
      /*height: 1.2em;*/ 
      white-space: nowrap;
    }
   .node_img
   {
       width: 2em;
       background: white;
   }
#app {
/*  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;*/
  /*height: 95%;*/
  /*overflow-y: no-display !important;*/
}
 html, body {
    /*height: 95%;*/
    /*overflow-y: no-display !important;*/
  }
  
#app .v-list-item__action
{
    margin-right: 10px;
    margin-top: 0px;
}

#app .v-card__title
{
    word-break: normal;
    overflow-y: hidden;
}

#app .v-list-group__header__prepend-icon
{
    margin-right: 0px;
}
#app .v-list-group__header__append-icon
{
    min-width: 10px;    
}

#app .v-list-item__title
{
    font-size: 14px;        
}

#app .v-list-item__content
{
    margin-top: -8px;
    font-size: 14px;        
}

.deleted_prop
{
    font-style: italic;
}

.mandatory_field:after {
  content:" *";
  color: red;
}

.mandatory_value
{
   display: table-cell;
   border-bottom: 2px solid red;
   float: left;
   width: 100%;
   height: 90%;
}

.conTabMenu
{
  margin-left: -10px;
  margin-right: -10px;
  min-height: 30px !important;
}

.tabulator-selected
{
  font-weight: bold;
  background-color: #fff !important;
}

</style>
