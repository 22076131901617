<template>
    <div>
        <v-card :class="'normalSize'" style="overflow-y: hidden">

            <search-table :headerInfo="headerInfo" :items="items" pID="docTbl" :wHi="wHi" 
                          :rowMenuElems="rowMenu" @selectRow="selectRow" @rowMenuClick="rowMenuClick"
                          :exportXLSX="exportXLSX" :exportCSV="exportCSV" :exportJSON="exportJSON"></search-table>
            <div style="font-size: 14px; font-weight: 700; padding-left: 10px; padding-top: 3px;" 
                                 v-if="fileList">
                Found {{ fileList.length }} result<span v-if="fileList.length !== 1">s</span>
            </div>
        </v-card>
    </div>
</template>

<script>

import{ conf } from '../utils/confTruePLM';
import SearchTable from './SearchTable.vue';

export default {
  name: 'SearchDocListT',
  props: {
      fileList: null
  },
  data () {
    return {
        sortDec: false,
        pageData: [],
        headerInfo: [ { text: 'Title', value: 'title', sorter: 'string' }, 
                    { text: 'Name', value: 'file_name', sorter: 'string' }, 
                    { text: 'Description', value: 'description', sorter: 'string' },
                    { text: 'Size', value: 'size', sorter: 'size', hozAlign: 'right' },
                    { text: 'Created by', value: 'created_by_user', sorter: 'string' },
                    { text: 'Created date', value: 'date_created', sorter: 'datetime', hozAlign: 'right' },
                    { text: 'Last modified by', value: 'last_ver_user', sorter: 'string' },
                    { text: 'Last modified date', value: 'last_ver_date', sorter: 'datetime', hozAlign: 'right' },
                    { text: 'Version', value: 'last_ver_id', sorter: 'string' },
                    { text: 'Phase', value: 'last_ver_phase', sorter: 'string', clear: true },
                    { text: 'Approver', value: 'approver', sorter: 'string' },
                    { text: 'Release manager', value: 'release_manager', sorter: 'string' },
                    { text: 'Responsible', value: 'responsible', sorter: 'string' },
                    { text: 'Reviewer', value: 'reviewer', sorter: 'string' },
                    { text: 'Discipline', value: 'discipline', sorter: 'string', clear: true },
                    { text: 'External version', value: 'external_version', sorter: 'string' },
                    { text: 'Format', value: 'data_type', sorter: 'string' },
                    { text: 'RID references', value: 'rid', sorter: 'string'  },
                    { text: 'Source', value: 'source', sorter: 'string', clear: true },
                    { text: 'Status', value: 'status', sorter: 'string', clear: true },
                    { text: 'Type', value: 'document_type', sorter: 'string', clear: true }],
        items: [],
        tabulator: null,
        headers: [],
        rowMenu: [ { txt: this.$t( 'message.file_download_btn' ), cmd: 'downloadFile' }, 
                   { txt: this.$t( 'message.file_open_btn' ), cmd: 'openFile' },
                   { txt: this.$t( 'message.menu_file_goto' ), cmd: 'gotoNode' } ],
        exportXLSX: false,
        exportJSON: false,
        exportCSV: false
    };
  },
  components: {
      'search-table': SearchTable
  },
  computed:
  {
      wHi()
      {
             return "calc( 100vh - 200px )";
      }    
  },
  methods: 
  {
      procFunc( cmd, data, row )
      {
          //this.selectRow( row );
          this.$eventBus.$emit( 'procRowMenuCmd', [ cmd, data ] );   
      }, 
      selectRow( row )
      {
          let data = row.getData();
          this.$eventBus.$emit( 'updateFile', { file: data.fullData, node: { permissions: {} } } );  
          this.$globalStore.setSelectedID( data.item_id );          
          this.$eventBus.$emit( 'setURLData', { file: data.item_id } );
      },
      rowMenuClick( info )
      {
          if( info.cmd === 'downloadFile' )
              this.procFunc( 'downloadFile', info.row.getData().fullData, info.row );
          else if( info.cmd === 'openFile' )
              this.procFunc( 'openFile', info.row.getData().fullData, info.row );
          else if( info.cmd === 'gotoNode' )
              this.procFunc( 'gotoNode', info.row.getData().pathData, info.row );
      }
  },
  watch: {
      fileList() 
      {
          this.items = [];
         
          for( let el of this.fileList )
          {
              let row = {};
              row.pathData = el.tree_path_to_element;
              
              el = el.doc_info;
              
              let lastVer = el.versions[ el.versions.length - 1 ];
              el.last_ver = lastVer.version_id + ' [' + lastVer.date_submitted + ']';
              el.last_ver_user = lastVer.submitted_by_user;
              el.last_ver_id = lastVer.version_id;
              el.last_ver_date = lastVer.date_submitted;
              el.last_ver_phase = lastVer.phase;
              
              row.item_id = el.instance_id;
              row.fullData = el;
              for( let h of this.headerInfo )
              {
                 let val = el[ h.value ];
                 if( val )
                 {
                    if( h.clear )
                        val = this.$utils.clearType( val );
                    
                    if( h.sorter === 'datetime' )
                    {
                        row[ h.value + '_dVal' ] = Date.parse( val );
                        val = this.$utils.getLocalDateFromUTC( val );
                    }
                    else if( h.sorter === 'size' )
                    {
                        row[ h.value + '_dVal' ] = val;
                        val = '' + val + ' b';
                    }
                        
                 }
                 row[ h.value ] = val;
              }
              
              this.items.push( row );
          }
      }
  },
  mounted: function() 
  {  
      let self = this;

      self.$eventBus.$on( 'exportDocSearchXLSX', function()
      {
           self.exportXLSX = !self.exportXLSX;
      });
      self.$eventBus.$on( 'exportDocSearchCSV', function()
      {
           self.exportCSV = !self.exportCSV;
      });
      self.$eventBus.$on( 'exportDocSearchJSON', function()
      {
           self.exportJSON = !self.exportJSON;
      });
  },   
  beforeDestroy: function()
  {
      this.$eventBus.$off( 'exportDocSearchXLSX' );
      this.$eventBus.$off( 'exportDocSearchCSV' );
      this.$eventBus.$off( 'exportDocSearchJSON' );
  }
}
</script>

<style scoped>
  .scroll 
  {
      overflow-y: auto;
  }
  .normalSizeWithPage
  {
        height: calc(100vh - 251px);
  }
  .normalSize
  {
        height: calc(100vh - 181px);
  }
  .propTbl td
  {
        height: 30px;
  }        

  .propTbl div table.v-table thead tr
  {
      height: 30px;
  }  
</style>
