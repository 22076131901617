<template>
    <div style="height: 100%;">
        <context-menu id="context-menu" ref="ctxMenu">
            <li @click="closeTab()" v-show="menuTab > 1" class="v-list-item v-list-item--link theme--light close-menu">{{ $t( 'message.tab_menu_close' ) }}</li>
            <li @click="fixBom()" v-show="menuTab === 2 && !bomFixed" class="v-list-item v-list-item--link theme--light close-menu">{{ $t( 'message.tab_menu_fix' ) }}</li>
            <li @click="unfixBom()" v-show="menuTab === 2 && bomFixed" class="v-list-item v-list-item--link theme--light close-menu">{{ $t( 'message.tab_menu_unfix' ) }}</li>
        </context-menu>
        
        <v-tabs v-model="active">
            <v-tab href="#tab-1" :key="1">{{ $t( 'message.props_tb' ) }}</v-tab>
            <v-tab href="#tab-11" :key="11">{{ $t( 'message.file_props_pnl_tb' ) }}</v-tab>
            <v-tab href="#tab-12" :key="12">{{ $t( 'message.prod_props_pnl_tb' ) }}</v-tab>
            <v-tab href="#tab-2" @contextmenu.prevent="cMenuOpen( $event, 2 )" :key="2" v-show="bomVis">
                <span v-if="!isReq">{{ $t( 'message.bom_tb' ) }}</span>
                <span v-else>{{ $t( 'message.bom_req_tb' ) }}</span>
            </v-tab>
            <v-tab href="#tab-3" @contextmenu.prevent="cMenuOpen( $event, 3 )" :key="3" v-show="treeVis">{{ $t( 'message.node_tree_tb' ) }}</v-tab>
            <v-tab href="#tab-44" @contextmenu.prevent="cMenuOpen( $event,4 )" :key="4" v-show="copyVis">
                <span style="overflow: hidden; text-overflow: ellipsis;">{{ globalState.curCopyFolderName }}</span>
            </v-tab>
            <v-tab-item value="tab-1">
                <pnl-props-view forSys="true" elemID="1"></pnl-props-view>    
                <v-tabs v-model="active2">
                    <v-tab href="#tab-4">{{ $t( 'message.user_props_tb' ) }}</v-tab>
                    <v-tab-item value="tab-4">
                        <pnl-props-view elemID="3"></pnl-props-view>                            
                    </v-tab-item>
                </v-tabs>
            </v-tab-item>
            <v-tab-item value="tab-11">
                <pnl-doc-props-view forSys="true" :data="fileData"></pnl-doc-props-view>             
                <v-tabs v-model="active22">
                    <v-tab href="#tab-41">{{ $t( 'message.user_props_tb' ) }}</v-tab>
                    <v-tab-item value="tab-41">
                        <pnl-doc-props-view :data="fileData"></pnl-doc-props-view>             
                    </v-tab-item>
                </v-tabs>
            </v-tab-item>
            <v-tab-item value="tab-12">
                <pnl-prod-props-view forSys="true" :data="prodData"></pnl-prod-props-view>             
                <v-tabs v-model="active23">
                    <v-tab href="#tab-42">{{ $t( 'message.user_props_tb' ) }}</v-tab>
                    <v-tab-item value="tab-42">
                        <pnl-prod-props-view :data="prodData"></pnl-prod-props-view>             
                    </v-tab-item>
                </v-tabs>
            </v-tab-item>
            <v-tab-item value="tab-2">
                <tree-root-with-load-view :path="bomPath" :fixed="bomFixed"></tree-root-with-load-view>
            </v-tab-item>
            <v-tab-item value="tab-3">
                <tree-root-view :path="treePath"></tree-root-view>
            </v-tab-item>
             <v-tab-item value="tab-44">
                 <node-list-view></node-list-view>
            </v-tab-item>
        </v-tabs>
    </div>
</template>

<script>
    import PnlProps from '../Pnl_Props.vue'
    import PnlDocProps from '../Pnl_Doc_Props.vue'
    import PnlProdProps from '../Pnl_Prod_Props.vue'
    import PnlFiles from '../Pnl_Files.vue'
    import TreeRoot from '../Tree/Root.vue'
    import TreeRootWithLoad from '../Tree/RootWithLoad.vue'
    import NodeList from '../NodeList.vue'
    
    import contextMenu from 'vue-context-menu'
    
export default {
  name: 'home',
  data () 
  {
    return {
        active: null,
        active2: null,
        active22: null,
        active23: null,
        bomVis: false,
        treeVis: false,
        copyVis: false,
        isReq: false,
        menuTab: null,
        treePath: null,
        bomPath: null,
        bomFixed: false,
        globalState: this.$globalStore.state,
        fileData: {},
        prodData: {}
    };
  },
  methods: {
      cMenuOpen( e, num )
      {
  //        console.log( e ); 
          this.menuTab = num;
          this.$refs.ctxMenu.open( e );
      },
      fixBom()
      {
          this.bomFixed = true;
      },
      unfixBom()
      {
          this.bomFixed = false;
      },
      closeTab()
      {
          if( this.menuTab === 2 )
              this.bomVis = false;
          if( this.menuTab === 3 )
              this.treeVis = false;
          this.menuTab = null;
          this.active = 'tab-1';
          if( this.$refs.tabs )
              this.$refs.tabs.onResize();   
      }
    },
  components: {
      'pnl-props-view': PnlProps,
      'pnl-doc-props-view': PnlDocProps,
      'pnl-prod-props-view': PnlProdProps,
      'pnl-files-view': PnlFiles, 
      'tree-root-view': TreeRoot,
      'tree-root-with-load-view': TreeRootWithLoad,
      'node-list-view': NodeList,
      'context-menu': contextMenu
  },
  beforeMount: function()
  {
  },
  mounted: function()
  {
      var self = this;
      self.$eventBus.$on( 'showPathAsTree', function( path )
      {
          self.treePath = path;
          self.treeVis = true;
          self.active = 'tab-3';
      });
      self.$eventBus.$on( 'showBOMFromPath', function( path )
      {
          self.isReq = path[ path.length - 1 ].is_requirement;
          self.bomPath = path;
          self.bomVis = true;
          self.active = 'tab-2';
      });
      self.$eventBus.$on( 'selectedModel', function()
      {
          self.bomVis = false;
          self.bomFixed = false;
          self.treeVis = false;
          self.menuTab = null;
          self.active = 'tab-1';
          if( self.$refs.tabs )
              self.$refs.tabs.onResize(); 
      });
      
      self.$eventBus.$on( 'updateNode', function()
      {
          if( !self.bomFixed || self.active !== 'tab-2' )
              self.active = 'tab-1';
      });
      
      self.$eventBus.$on( 'updateFile', function( data )
      {
          if( !self.bomFixed || self.active !== 'tab-2' )
              self.active = 'tab-11';
          
          self.fileData = data;
      });
      
      self.$eventBus.$on( 'updateProd', function( data )
      {
          if( !self.bomFixed || self.active !== 'tab-2' )
              self.active = 'tab-12';

          self.prodData = data;
      });
      
      self.$eventBus.$on( 'showSelProps', function()
      {
          if( !self.bomFixed || self.active !== 'tab-2' )
              self.active = 'tab-1';
      });
      
      this.$eventBus.$emit( 'reInitState', {} );
  },
  beforeDestroy: function()
  {
      this.$eventBus.$off( 'showPathAsTree' );
      this.$eventBus.$off( 'showBOMFromPath' );
      this.$eventBus.$off( 'selectedModel' );
      
      this.$eventBus.$off( 'updateNode' );
      this.$eventBus.$off( 'updateFile' );
      this.$eventBus.$off( 'showSelProps' );
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
