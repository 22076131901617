<template>
            <v-dialog v-model="visFl" persistent max-width="380">
               <v-card>
                <v-card-title class="headline">
                  {{ $t( 'message.add_token_done_title' ) }}
                </v-card-title>
                <v-card-text>
                    <b>{{token}}</b><br><br>
                    <p>{{ $t( 'message.add_token_done_msg1' ) }}</p>
                    <p>{{ $t( 'message.add_token_done_msg2' ) }}</p>
                    <p>{{ $t( 'message.add_token_done_msg3' ) }}</p>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click.native="doAct()">{{ $t( 'message.ok_btn' ) }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>   
</template>

<script>
    
import Vue from 'vue'
import{ conf } from '@/utils/confTruePLM';

export default {
  name: 'confirm2fa',
  data () 
  {
    return {
        visFl: false,
        
        token: '',

        resolve: null,
        reject: null,
        valid: true
    };
  },
  computed: {
     
  },
  methods: {
      open( token )
      {
          this.token = token;
          
          this.visFl = true;
          
          return new Promise( ( resolve, reject ) => 
          {
            this.resolve = resolve;
            this.reject = reject;
          });
      },
      doAct()
      {
         this.visFl = false;
         this.resolve();
      }
    }
}
</script>



