<template>
    
<!--      <v-container fluid fill-height>
          <v-layout align-center justify-center>-->
            <v-dialog v-model="visFl" persistent max-width="350">
               <v-card>
                <v-card-title class="headline">
                  {{ $t( 'message.make_diff_title' ) }}
                </v-card-title>
                <v-card-text>
                    <v-icon @click="visHelp = !visHelp">help</v-icon>
                    <small v-show="visHelp">{{ $t( 'message.make_diff_info' ) }}</small>
                    <v-alert type="error" v-model="errShow" dismissible>{{$t( 'message.diff_wrong_roots' )}}</v-alert>
                    <v-form ref="form" v-model="valid" lazy-validation style="padding-top: 10px;">
                        
                        <v-autocomplete :items="vers" item-text="txt" item-value="val" :label="$t( 'message.make_diff_ver1' )"
                            v-model="ver1" @input="changeValV1()" light dense class="selectCl" required></v-autocomplete>   
                        
                        <v-select :label="$t( 'message.make_diff_base1' )" required item-text="txt" item-value="val" v-model="base1"
                            :items="bases" v-on:change="changeValB1"></v-select>
                        
                        <v-autocomplete :items="vers" item-text="txt" item-value="val" :label="$t( 'message.make_diff_ver2' )"
                            v-model="ver2" @input="changeValV2()" light dense class="selectCl" required></v-autocomplete>  
                            
                        <v-select :label="$t( 'message.make_diff_base2' )" required item-text="txt" item-value="val" v-model="base2"
                            :items="bases" v-on:change="changeValB2"></v-select>
                    </v-form>
                    
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text :disabled="!filled" @click.native="doAct()">{{ $t( 'message.ok_btn' ) }}</v-btn>
                    <v-btn color="green darken-1" text @click.native="close()">{{ $t( 'message.cancel_btn' ) }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>   
<!--          </v-layout>
      </v-container>-->
    
</template>

<script>
    
import Vue from 'vue'
import{ conf } from '../../utils/confTruePLM';

export default {
  name: 'makeDiff',
  data () 
  {
    return {
        visFl: false,
        visHelp: false,
        ver1: null,
        ver2: null,
        base1: null,
        base2: null,
        vers: [],
        bases: [],
        valid: true,
        errShow: false,
        resolve: null,
        reject: null        
    };
  },
  computed: {
      filled: function()
      {
          return ( this.ver1 || this.base1 ) && ( this.ver2 || this.base2 );
      },
      isSameRoot: function()
      {
          var id1 = this.ver1 ? this.ver1.id : ( this.base1 ? this.base1.id : 0 );
          var id2 = this.ver2 ? this.ver2.id : ( this.base2 ? this.base2.id : 0 );
          return ( id1 === id2 );
      }
  },
  methods: {
      changeValV1( val )
      {
          if( val )
              this.base1 = null;
      },
      changeValB1( val )
      {
          if( val )
              this.ver1 = null;
      },
      changeValV2( val )
      {
          if( val )
              this.base2 = null;
      },
      changeValB2( val )
      {
          if( val )
              this.ver2 = null;
      },      
      open()
      {
          var self = this;
          
          self.ver1 = null;
          self.ver2 = null;
          self.base1 = null;
          self.base2 = null;
          
          self.vers = [];
          self.bases = [];
          
          Promise.all( [ conf.getPBS(), conf.versionList() ] )
                  .then( data => 
          {
              let elem = data[ 0 ].root_bkdn_elem;
              for( let i = data[ 1 ].length - 1; i > -1; i-- )
              {
                  let v = data[ 1 ][ i ];
                  self.vers.push( { txt: ( 'Ver. ' + v + ' ' ), val: { ver: v, id: elem.instance_id, bkd_ver: v } } );
              }
          });
          conf.getBaselineList()
                  .then( ( data ) => 
          {
              data.forEach( ( item ) =>
              {
                  self.bases.push( { txt: item.id, val: { ver: item.id, is_baseline: true,
                          id: item.root_bkdn_elem.instance_id, bkd_ver: item.in_bkdn_version } } );
              });
          });
          this.visFl = true;
          
          return new Promise( ( resolve, reject ) => 
          {
            this.resolve = resolve;
            this.reject = reject;
          });
      },
      close()
      {
          this.visFl = false;
          this.reject();
      },
      doAct()
      {
          if( this.filled )
          {
              if( this.isSameRoot )              
              {
                  this.visFl = false;
                  this.resolve( { ver1: this.ver1, ver2: this.ver2, base1: this.base1, base2: this.base2 } );
              }
              else
                  this.errShow = true;
          }
      }
    }
}
</script>
<style scoped>
   
    .selectCl
    {
        font-size: 13px;
    }
    
</style>



