<template>
    <div>
         <v-main>
            <v-container fluid fill-height >
                <v-layout fill-height>
                    <v-flex lg12 md12>
                        <v-card>
                            <v-card-title >
                              <span class="headline">{{$t( 'message.diff_res_ttl' )}}</span>
                            </v-card-title>
                            <v-container fill-height fluid>
                                <v-layout fill-height>
                                    <Split :gutterSize="5" style="height: calc(100vh - 120px);">
                                        
                                        <!--<v-flex v-if="$vuetify.breakpoint.lgAndUp" lg3 md3>-->
                                        <SplitArea :size="25">
                                            <v-tabs v-model="act_tab" height="22px">
                                               <v-tab href="#tab-1" :key="1">{{lfTree}}</v-tab>
                                               <v-tab-item value="tab-1">
                                                   <!--<tree-root-with-load-view type="lf" hideProps="true" showFiles="true"></tree-root-with-load-view>-->
                                                   <diff-fld-view :prms="lfPrms" :path="lfPath" :elem="lfElem"></diff-fld-view>
                                               </v-tab-item>
                                            </v-tabs>  
                                        <!--</v-flex>-->
                                        </SplitArea>
                                        
                                        <!--<multipane-resizer></multipane-resizer>-->

                                        <SplitArea :size="50">
                                            <v-flex>
                                                <v-tabs v-model="act_tab" height="22px">
                                                   <v-tab href="#tab-1" :key="1">{{ $t( 'message.diff_list_tab' ) }}</v-tab>
                                                   <v-tab-item value="tab-1">
                                                       <v-card style="height: calc( 60vh - 115px );">
                                                           <diff-tbl-view :dataTbl="dataTbl" :prms="tblPrms"
                                                                  @fillDiffDetails="fillDiffDetails"></diff-tbl-view> 
                                                       </v-card>
                                                   </v-tab-item>
                                                </v-tabs> 

                                                <v-tabs v-model="act_tab" height="22px" style="padding-top: 10px;">
                                                   <v-tab href="#tab-1" :key="1">{{ $t( 'message.diff_detail_tab' ) }}</v-tab>
                                                   <v-tab-item value="tab-1">
                                                       <v-card style="height: calc( 40vh - 98px );">
                                                           <diff-detail-view :info="dtlInfo"></diff-detail-view> 
                                                       </v-card>
                                                   </v-tab-item>
                                                </v-tabs> 
                                            </v-flex>
                                        </SplitArea>
                                        
                                        <!--<multipane-resizer></multipane-resizer>-->                                                                           

                                        <!--<v-flex v-if="$vuetify.breakpoint.lgAndUp" lg3 md3>-->
                                        <SplitArea :size="25">
                                            <v-tabs v-model="act_tab" height="22px">
                                               <v-tab href="#tab-1" :key="1">{{rtTree}}</v-tab>
                                               <v-tab-item value="tab-1">
                                                   <!--<tree-root-with-load-view type="rt" hideProps="true" showFiles="true"></tree-root-with-load-view>-->
                                                   <diff-fld-view :prms="rtPrms" :path="rtPath" :elem="rtElem"></diff-fld-view>
                                               </v-tab-item>
                                            </v-tabs>  
                                        <!--</v-flex>-->
                                        </SplitArea>
                    
                                    </Split>
                                </v-layout>
                            </v-container>
                        </v-card>                        
                    </v-flex>
                </v-layout>
            </v-container>
         </v-main>
    </div>
</template>

<script>

import router from '@/router';
import { auth } from '@/utils/auth';
import{ conf } from '@/utils/confTruePLM';
import DiffTbl from './DiffTbl';
import DiffDetail from './DiffDetail';
import DiffFld from './DiffFld';
import TreeRootWithLoad from '@/components/Tree/RootWithLoad.vue';

import { Split, SplitArea } from 'vue-split-panel'

export default {
  name: 'diff',
  data () 
  {
    return {
        act_tab: 'tab-1',
        lfTree: '',
        rtTree: '',
        tblPrms: null,
        lfPrms: null,
        rtPrms: null,
        dtlInfo: null,
        dataTbl: null,
        lfPath: null,
        rtPath: null,
        lfElem: null,
        rtElem: null
    };
  },
  methods: {
      fillInfo( prms )
      {
          let self = this;
          this.lfTree = prms.base1 ? prms.base1 : ( 'Version ' + prms.ver1 );
          this.rtTree = prms.base2 ? prms.base2 : ( 'Version ' + prms.ver2 );
          conf.setProj( { repository: prms.rep, in_project: { project_model_id: prms.model } }, prms.role );
          
          this.tblPrms = prms;
          self.$modalDlg.sysDlgs.progressDlg.open();
          conf.getDiff( prms )
                  .then( ( data )=>
          {
              self.dataTbl = data;
              self.$modalDlg.sysDlgs.progressDlg.close();
          })
                  .catch( ( err ) => 
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.$eventBus.$emit( 'queryError', err );          
          });
          
          this.lfPrms = { ver: prms.ver1, base: prms.base1 };
          this.rtPrms = { ver: prms.ver2, base: prms.base2 };
      },
      fillDiffDetails( row )
      {
          let self = this;
          let rowDt = this.dataTbl.sections[ 0 ].differences[ row.ind ];
          let stepBack = rowDt.difference_type === 'Modified' ? 1 : 2;
          
          self.$modalDlg.sysDlgs.progressDlg.open();
          let elFunc1 = Promise.resolve( null );
          let elFunc2 = Promise.resolve( null );
          if( rowDt.element_1 )
          {
              let path = rowDt.element_1.treePath;
              elFunc1 = conf.getNodeChildren( { instance_id: path[ path.length - stepBack ] }, this.lfPrms.ver, 1, 100, false, true, true, false );
          }
          if( rowDt.element_2 )
          {
              let path = rowDt.element_2.treePath;
              elFunc2 = conf.getNodeChildren( { instance_id: path[ path.length - stepBack ] }, this.rtPrms.ver, 1, 100, false, true, true, false );
          }
          Promise.all( [ elFunc1, elFunc2 ] )
                  .then( ( data ) =>
          {
              self.dtlInfo = { row: rowDt, elem1: data[ 0 ], elem2: data[ 1 ] };
              self.lfElem = data[ 0 ];
              self.rtElem = data[ 1 ];
              self.$modalDlg.sysDlgs.progressDlg.close();
          })
                  .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.$eventBus.$emit( 'queryError', err );
          });
          let path1 = rowDt.element_1 ? rowDt.element_1.stringPath : null;
          let path2 = rowDt.element_2 ? rowDt.element_2.stringPath : null;
          if( path1 )
              path1 = path1.slice( 0, path1.length - stepBack + 1 );
          if( path2 )
              path2 = path2.slice( 0, path2.length - stepBack + 1 );
          
          this.lfPath = path1;
          this.rtPath = path2;

//          console.log( rowDt );
      },
      procError( err )
      {
          let printErr = true;
          
          if( !err.message && err.response )
              err.message = err.response;
          
          if( err.message === 'Session expired!' )
              this.$modalDlg.sysDlgs.logoutDlg.open( 'no_session_msg', 'no_session_ttl', true );  
          else if( err.message === 'noConnInfo' || err.message === 'Connection expired' )
              this.$modalDlg.sysDlgs.logoutDlg.open( 'no_session_msg', 'no_session_ttl', true );
          else if( err.message === 'Wrong proj name' )
              this.$modalDlg.sysDlgs.confirmDlg.openError( 'error_ttl', 'error_proj_name_msg' );
          else if( err.message === 'Rstat Error: 50100' )
              this.$modalDlg.sysDlgs.confirmDlg.openError( 'error_ttl', 'error_50100_msg' );
          else if( err.message === 'baseline_create' )
              this.$modalDlg.sysDlgs.confirmDlg.openError( 'error_ttl', 'error_' + err.message + '_msg' );
          else if( err.message === 'Index: 0, Size: 0' )
          {
              
          }
          else if( err.message === 'linked docs' )
              this.$modalDlg.sysDlgs.confirmDlg.openError( 'error_ttl', 'error_linked_docs_msg' );
          else
          {
              err.message = JSON.parse( err.message );
              if( err.message.errors )
              {
                  err.message.errors.forEach( er =>
                  {
                      console.log( er );
                  });
                  printErr = false;
              }
              
              this.$modalDlg.sysDlgs.confirmDlg.open( 'error_ttl', 'error_from_server_msg', 
                        null, err.message.message, true, 'error', 'red' );
          }
          
          if( printErr )
              console.log( err.message );
      }
    },
  computed: {
  },
  components: {
      'diff-fld-view': DiffFld,
      'diff-tbl-view': DiffTbl,
      'diff-detail-view': DiffDetail,
      'tree-root-with-load-view': TreeRootWithLoad,
      'Split' : Split,
      'SplitArea': SplitArea
  },
  beforeMount: function()
  {
      auth.checkAuth();
      if( ! auth.getUser().authenticated )
          router.push( '/login' );
  },
  mounted: function()
  {
      let self = this;
      this.$eventBus.$on( 'appReady', function()
      {
          self.fillInfo( self.$route.query );
      });
  },
  beforeDestroy: function()
  {
      this.$eventBus.$off( 'appReady' );
  }
}
</script>

<style scoped>
  
</style>
