<template>
    
<!--      <v-container fluid fill-height>
          <v-layout align-center justify-center>-->
            <v-dialog v-model="visFl" persistent max-width="350">
               <v-card>
                <v-card-title class="headline" v-if="dlgType === 'tmpl'">
                  {{ $t( 'message.upload_doc_tmpl' ) }}
                </v-card-title>
                <v-card-title class="headline" v-else>
                  {{ $t( 'message.add_file_node_title' ) }}
                </v-card-title>
                <v-card-text>
                        <v-tabs v-model="active">
                            <v-tab hidden-md-and-up href="#tab-1">{{ $t( 'message.file_file_tb' ) }}</v-tab>
                            <v-tab href="#tab-2">{{ $t( 'message.file_props_tb' ) }}</v-tab>
                            <v-tab href="#tab-3">{{ $t( 'message.file_users_tb' ) }}</v-tab>
                            <v-tab-item value="tab-1">
                                <v-form ref="form1" v-model="valid1" lazy-validation>
                                    
                                    <file-upload ref="fileUpload" v-on:change="fileChanged" :rules="[v => v.length > 0 || $t( 'message.file_required' )]"></file-upload>
                                    
                                    <v-text-field :label="$t( 'message.add_file_title' )" v-model="title"></v-text-field>
                                    
                                    <v-textarea :label="$t( 'message.create_node_descr' )" required v-model="info.descr" 
                                            :rules="[v => !!v || $t( 'message.value_required' )]"></v-textarea>
                                </v-form>
                            </v-tab-item>
                            <v-tab-item value="tab-2">
                                <v-form ref="form2" v-model="valid2" lazy-validation>
                                    <v-select :label="$t( 'message.add_file_node_source' )" required v-model="info.sourceVal" item-text="txt" item-value="val" 
                                                :items="sources" :rules="[v => !!v || $t( 'message.value_required' )]"></v-select>
                                    <v-select :label="$t( 'message.add_file_node_ctype' )" required v-model="info.cTypeVal" item-text="txt" item-value="val" 
                                                :items="cTypes" :rules="[v => !!v || $t( 'message.value_required' )]"></v-select>
                                    <v-select :label="$t( 'message.add_file_node_discipline' )" required v-model="info.disciplineVal" item-text="txt" item-value="val" 
                                                :items="disciplines" :rules="[v => !!v || $t( 'message.value_required' )]"></v-select>
                                    <v-select :label="$t( 'message.add_file_node_pphase' )" required v-model="info.pPhaseVal" item-text="txt" item-value="val" 
                                                :items="pPhases" :rules="[v => !!v || $t( 'message.value_required' )]"></v-select>
                                    <v-select :label="$t( 'message.add_file_node_status' )" required v-model="info.statusVal" item-text="txt" item-value="val" 
                                                :items="statuses" :rules="[v => !!v || $t( 'message.value_required' )]"></v-select>
                                </v-form>
                            </v-tab-item>
                            <v-tab-item value="tab-3">
                                <v-form ref="form3" v-model="valid3" lazy-validation>
                                    <v-select :label="$t( 'message.add_file_node_editor' )" required v-model="info.editorVal" item-text="txt" item-value="val" 
                                                :items="users" :rules="[v => !!v || $t( 'message.value_required' )]"></v-select>
                                    <v-select :label="$t( 'message.add_file_node_resp' )" required v-model="info.respVal" item-text="txt" item-value="val" 
                                                :items="users" :rules="[v => !!v || $t( 'message.value_required' )]"></v-select>
                                    <v-select :label="$t( 'message.add_file_node_rev' )" required v-model="info.revVal" item-text="txt" item-value="val" 
                                                :items="users" :rules="[v => !!v || $t( 'message.value_required' )]"></v-select>
                                    <v-select :label="$t( 'message.add_file_node_app' )" required v-model="info.appVal" item-text="txt" item-value="val" 
                                                :items="users" :rules="[v => !!v || $t( 'message.value_required' )]"></v-select>
                                    <v-select :label="$t( 'message.add_file_node_rman' )" required v-model="info.rmanVal" item-text="txt" item-value="val" 
                                                :items="users" :rules="[v => !!v || $t( 'message.value_required' )]"></v-select>
                                </v-form>
                            </v-tab-item>                            
                        </v-tabs>
<!--                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-text-field :label="$t( 'message.create_node_name' )" required v-model="name" :disabled="edit"
                            :rules="[v => !!v || 'Name is required']"></v-text-field>
                        <v-textarea :label="$t( 'message.create_node_descr' )" required v-model="descr" 
                            :rules="[v => !!v || 'Descr is required']"></v-textarea>
                        <v-select :label="$t( 'message.create_node_type' )" required v-model="selectedType" item-text="txt" item-value="val" 
                            :items="types" :rules="[v => !!v || 'Item is required']"></v-select>
                    </v-form>
                    <small>*{{ $t( 'message.req_info_msg' ) }}</small>-->
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                    <v-btn id="addFileToNodeYes" color="green darken-1" text :disabled="!valid1 || !valid2 || !valid3" @click.native="doAct()">{{ $t( 'message.ok_btn' ) }}</v-btn>
                    <v-btn id="addFileToNodeClose" color="green darken-1" text @click.native="close()">{{ $t( 'message.cancel_btn' ) }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>   
<!--          </v-layout>
      </v-container>-->
    
</template>

<script>
    
import Vue from 'vue'

import FileUpload from '../FileUpload'
import{ conf } from '@/utils/confTruePLM';

export default {
  name: 'addFileToNode',
  data () 
  {
    return {
        visFl: false,
        
        cTypes: [],
        sources: [],
        disciplines: [],
        pPhases: [],
        pPhaseVal: [],
        statuses: [],
        users: [],
        info: {},
        title: null,
        dlgType: null,
        
        resolve: null,
        reject: null,
        valid1: true,
        valid2: true,
        valid3: true,
        active: null
    };
  },
  computed: {
      
  },
  components: {
       'file-upload': FileUpload
  },
  methods: {
      fileChanged( data )
      {
          let name = '';
          if( data[ 0 ] )
          {
              name = data[ 0 ].name;
              let ind = name.lastIndexOf( '.' );
              if( ind > 0 )
                  name = name.substring( 0, ind );
          }
          
          this.title = name;          
      },
      getUploadedFile( data )
      {
         // console.log( data );
      },
      open( dlgType )
      {
          this.dlgType = dlgType;
          this.info = {};
          
          this.active = 'tab-1';
          
          let self = this;
          this.cTypes = [];
          conf.getChildTypes( 'urn:rdl:epm-std:Document_content_type' )
                  .then( ( data ) =>
          {
              self.cTypes = self.$utils.getEnumClass( data );
              self.info.cTypeVal = self.cTypes[ 0 ].val;
          });

          this.sources = [];
          conf.getChildTypes( 'urn:rdl:epm-std:Source_of_information' )
                  .then( ( data ) =>
          {
              self.sources = self.$utils.getEnumClass( data );
              self.info.sourceVal = self.sources[ 0 ].val;
          });

          this.disciplines = [];
          conf.getChildTypes( 'urn:rdl:epm-std:project_discipline' )
                  .then( ( data ) =>
          {
              self.disciplines = self.$utils.getEnumClass( data );
              self.info.disciplineVal = self.disciplines[ 0 ].val;
          });

          this.pPhases = [];
          Promise.all( [ conf.getProjPhase(), conf.getChildTypes( 'urn:rdl:epm-std:project_phase' ) ] )
                  .then( ( data ) =>
          {
            //  console.log( data );
              self.pPhases = self.$utils.getEnumClass( data[ 1 ] );
              self.info.pPhaseVal = data[ 0 ].result;
          });

          this.statuses = [];
          Promise.all( [ conf.getChildTypes( 'urn:rdl:epm-std:Document_status' ), 
                         conf.getDefaultProp( 'urn:rdl:epm-std:Document_status' ) ] )
                  .then( data =>
          {
              self.statuses = self.$utils.getEnumClass( data[ 0 ] );
              self.info.statusVal = data[ 1 ][ 0 ] || self.statuses[ 0 ].val;
          });

          this.users = [];
          conf.getProjectUsersEnum()
                  .then( ( data ) =>
          {
              self.users = data;
              self.info.respVal = self.users[ 0 ].val;
              self.info.revVal = self.users[ 0 ].val;
              self.info.appVal = self.users[ 0 ].val;
              self.info.rmanVal = self.users[ 0 ].val;
              self.info.editorVal = self.users[ 0 ].val;
          });
          
          this.title = '';
          
          this.visFl = true;
          
          return new Promise( ( resolve, reject ) => 
          {
            this.resolve = resolve;
            this.reject = reject;
          });
      },
      close()
      {
          this.visFl = false;
          this.reject();
      },
      doAct()
      {
          if( !this.$refs.form1.validate() )
          {
              this.active = 'tab-1';
              return;
          }
              
          if( this.$refs.form2 && !this.$refs.form2.validate() )
          {
              this.active = 'tab-2';
              return;
          }
          
          if( this.$refs.form3 && !this.$refs.form3.validate() )
          {
              this.active = 'tab-2';
              return;
          }
          
          this.visFl = false;
          this.info.files = this.$refs.fileUpload.items;
          this.info.title = this.title;
          this.$refs.fileUpload.clear();
          this.resolve( this.info );
      }
    }
}
</script>





