import { auth } from './auth';

var projObj = null;

export const conf = 
{
    clearProj()
    {
        projObj = null;        
    },
    
    setProj( inProj, role )
    {
        projObj = inProj;
        projObj.repository = conf.getRepository( inProj );
        projObj.userType = role;
    },
    
    getRepository( repProj )
    {
        if( !repProj.repository )
        {
            repProj.repository = 'TruePLMprojectsRep';
            if( repProj.in_project.is_bkd_template_project )
                repProj.repository = 'TruePLMbkdTemplatesRep';
            else if( repProj.in_project.is_template_project )
                repProj.repository = 'TruePLMtemplatesRep';
        }
        
        return repProj.repository;
    },
    
    getProj()
    {
        return projObj;
    },
    
    getUser()
    {
        return auth.getUser();
    },
    
    getProjModel()
    {
        return ( projObj && projObj.in_project ) ? projObj.in_project.project_model_id : '';
    },
    
    getPBS( ver )
    {
        if( !ver )
            ver = '';
        return conf.queryForModel( 'bkd', { query_prm: 'ver=' + ver } );
    },
    
    getDiff( info )
    {
        return conf.queryForModel( 'bkd/diff', { path_prm: '' + ( info.base1 || info.ver1 ) + '/' + ( info.base2 || info.ver2 ), 
            query_prm: 'base1=' + ( info.base1 ? true : false ) + '&base2=' + ( info.base2 ? true : false ) } );
    },
    
    exportStepPack()
    {
        return conf.queryForModel( 'exch/export/pack', {} );
    },
    
    importStepPack( files )
    {
        var fd = new FormData();
        fd.append( 'file', files[ 0 ].file );
                
        return conf.queryForModelWithData( 'exch/import/pack', { data: fd }, 'POST' );
    },
    
    exportAggrProp( nodeID, propUrn, fromKey, toKey, pageN, pageSize, format, cols )
    {
        let qPrm = '';
        if( pageN )
            qPrm += '&page=' + pageN;
        if( pageSize )
            qPrm += '&size=' + pageSize;
        if( fromKey )
            qPrm += '&from=' + fromKey;
        if( toKey )
            qPrm += '&to=' + toKey;
        if( format )
            qPrm += '&format=' + format;
        
        for( let i = 0; cols && i < cols.length; i++ )
            qPrm += '&cols=' + cols[ i ];
        
        if( qPrm !== '' )
            qPrm = qPrm.substring( 1 );
        
        return conf.queryForModel( 'bkd/aggr_exp', { path_prm: '' + nodeID + '/' + propUrn, 
            query_prm: qPrm } );
    },
    
    getAggrProp( nodeID, propUrn, pageN, pageSize, fromKey, toKey, revOrder, avrVals, useQuick )
    {
        let qPrm = 'page=' + pageN + '&size=' + pageSize;
        if( fromKey )
            qPrm += '&from=' + fromKey;
        if( toKey )
            qPrm += '&to=' + toKey;
        if( revOrder )
            qPrm += '&reverse_order=true';
        
        if( avrVals )
            return conf.queryForModel( 'bkd/aggr_avr', { path_prm: '' + nodeID + '/' + propUrn, 
                query_prm: qPrm + '&quick=' + useQuick } );
        else
            return conf.queryForModel( 'bkd/aggr', { path_prm: '' + nodeID + '/' + propUrn, 
                query_prm: qPrm } );
    },
    
    getProjectUsers( user_proj )
    {
        return conf.queryForModel( 'dat/info/users', {}, 'GET', user_proj );
    },
    
    getProjectUsersEnum( enum_proj )
    {
        let users = [];
        return conf.getProjectUsers( enum_proj )
                .then( ( data ) =>
        {
            if( data && data.available_users )
            {
                data.available_users.forEach( ( item ) =>
                {
                    if( item.user_name !== 'system' )
                        users.push( { txt: item.user_name, val: item.user_name, roles: item.user_type } );
                });
            }
            
            return users;
        });
    },
    
    getChildTypesWithStatus( prnType )
    {
        if( !prnType )
            return Promise.resolve( [] );
        
        return conf.queryForModel( 'adm_user/class', { path_prm: '' + prnType } );
    },
    
    getPropWithStatus( prnType, isAggr )
    {
        let qPrm = 'is_aggr=' + !!isAggr;
        return conf.queryForModel( 'adm_user/prop', { path_prm: '' + prnType, query_prm: qPrm } );
    },
    
    getChildTypesInfo( prnType )
    {
        return conf.queryForModelRDL( 'ifd_concept/info', { path_prm: '' + prnType } );
    },
    
    getChildTypes( prnType )
    {
        return conf.queryForModelRDL( 'ifd_concept', { path_prm: '' + prnType } );
    },
    
    getChildTypesTree( prnType )
    {
        return conf.queryForModel( 'adm_user/class_tree', { path_prm: '' + prnType } );
    },
    
    getTypesTree( prnType )
    {
        return conf.queryForModelRDL( 'ifd_concept/q_tree', { path_prm: '' + prnType } );
    },

    getChildTypesAbst( prnType )
    {
        let qPrm = '';
        if( prnType )
        {
            if( qPrm.length > 0 )
                qPrm += '&';
            qPrm += "root=" + prnType;
        }
        
        return conf.queryForModelRDL( 'ifd_concept/deep_abst', { query_prm: qPrm } );
    },
    
    getChildTypesInContext( prnType, context, restriction )
    {
        let qPrm = '';
        if( prnType )
        {
            if( qPrm.length > 0 )
                qPrm += '&';
            qPrm += "root=" + prnType;
        }
        if( context )
        {
            if( qPrm.length > 0 )
                qPrm += '&';
            qPrm += "context=" + context;
        }
        if( restriction )
        {
            if( qPrm.length > 0 )
                qPrm += '&';
            qPrm += "restriction=" + restriction;
        }
        
        return conf.queryForModelRDL( 'ifd_concept/deep', { query_prm: qPrm } );
    },
    
    calcHash( node )
    {
        return conf.queryForModel( 'hash', { path_prm: '' + '' + node.instance_id }, 'PUT' );
    },
    
    getHash( docID )
    {
        return conf.queryForModel( 'hash', { path_prm: '' + '' + docID } );
    },
    
    getChain()
    {
        return conf.queryForModel( 'hash/chain', {} );
    },
    
    getChainBlock( ver )
    {
        return conf.queryForModel( 'hash/chain', { path_prm: '' + ver } );
    },
    
    downloadChainFile()
    {
        return conf.queryForModel( 'hash/chain/file', {} );
    },
    
    getProjPhase()
    {
        return conf.queryForModel( 'adm_user/phase', {} );
    },
    
    updateProjPhase( val )
    {
        var fd = new FormData();
        fd.append( 'value', val );
        
        return conf.queryForModel( 'adm_user/phase', { data: fd }, 'POST' );
    },
    
    getPropInfo( propName )
    {
        return conf.queryForModel( 'adm_user/prop_info', { path_prm: '' + propName } );
    },
    
    incProjVer()
    {
        return conf.queryForModel( 'bkd/ver', {}, 'PUT' );
    },
    
    addDerivedProp( prop, conceptUrn )
    {
        let fd = new FormData();
        fd.append( 'concept_urn', conceptUrn );
        fd.append( 'super_urn', 'urn:plcs:rdl:TruePLM:Derived_property' );
                
        if( prop.type === 'Text_value' )
            fd.append( 'domain_urn', 'urn:plcs:rdl:TruePLM:Text_value' );
        else if( prop.type === 'Numeric_value' )
            fd.append( 'domain_urn', 'urn:plcs:rdl:TruePLM:Numeric_value' );
        else if( prop.type === 'Boolean' )
            fd.append( 'domain_urn', 'urn:plcs:rdl:TruePLM:Boolean' );
        else if( prop.type === 'Date_time' )
            fd.append( 'domain_urn', 'urn:plcs:rdl:std:Date_time' );
        else if( prop.type === 'oneOf' )
            fd.append( 'domain_urn', 'urn:owl:oneOf' );
        
        if( prop.exp )
            fd.append( 'descr', '=' + prop.exp );
        
        if( prop.units )
            fd.append( 'units', prop.units );
        if( prop.readOnly )
            fd.append( 'readonly', prop.readOnly );
        
        let urn = prop.urn;
        if( !urn )
            urn = 'urn:rdl:' + projObj.in_project.project_model_id + ':' + prop.name;
        
        return conf.queryForModelRDL( 'ifd_concept/prop', { path_prm: '' + urn, data: fd }, 'POST' );
    },
    
    addRefDataProp( prop, conceptUrn )
    {
//        console.log( prop );
        let type = prop.type;
        if( type === 'List' )
            type = prop.elemType;
        
        let fd = new FormData();
        if( prop.readOnly )
            fd.append( 'readonly', prop.readOnly );
        if( prop.mandatory )
            fd.append( 'mandatory', prop.mandatory );
        
        if( prop.domain )
            return conf.addRefDataSpecProp( fd, prop, conceptUrn );
        else if( type === 'Text_value' )
            return conf.addRefDataTxtProp( fd, prop, conceptUrn );
        else if( type === 'Date_time' )
            return conf.addRefDataDTProp( fd, prop, conceptUrn );
        else if( type === 'Numeric_value' )
            return conf.addRefDataNumProp( fd, prop, conceptUrn );
        else if( type === 'oneOf' )
            return conf.addRefDataEnumProp( fd, prop, conceptUrn );    
        else if( type === 'Boolean' )
            return conf.addRefDataBoolProp( fd, prop, conceptUrn ); 
        else
            return conf.addRefDataCustomProp( fd, prop, conceptUrn );
    },
    addRefDataSpecProp( fd, prop, conceptUrn )
    {
        fd.append( 'concept_urn', conceptUrn );
        fd.append( 'domain_urn', prop.domain );
        
        let urn = prop.urn;
        if( !urn )
            urn = 'urn:rdl:' + projObj.in_project.project_model_id + ':' + prop.name;
        return conf.queryForModelRDL( 'ifd_concept/prop', { path_prm: '' + urn, data: fd }, 'POST' );
    },
    addRefDataCustomProp( fd, prop, conceptUrn )
    {
        let type = prop.elemType;
        if( prop.type === 'List' )
            type = 'urn:plcs:rdl:TruePLM:List,' + type;
            
        fd.append( 'concept_urn', conceptUrn );
        fd.append( 'domain_urn', type );
        
        let urn = prop.urn;
        if( !urn )
            urn = 'urn:rdl:' + projObj.in_project.project_model_id + ':' + prop.name;
        return conf.queryForModelRDL( 'ifd_concept/prop', { path_prm: '' + urn, data: fd }, 'POST' );
    },
    addRefDataBoolProp( fd, prop, conceptUrn )
    {
        fd.append( 'concept_urn', conceptUrn );
        fd.append( 'values', 'True' );
        fd.append( 'values', 'False' );
        fd.append( 'domain_urn', 'urn:plcs:rdl:TruePLM:Boolean' );
        
        let urn = prop.urn;
        if( !urn )
            urn = 'urn:rdl:' + projObj.in_project.project_model_id + ':' + prop.name;
        return conf.queryForModelRDL( 'ifd_concept/prop_enum', { path_prm: '' + urn, data: fd }, 'POST' );
    },
    addRefDataEnumProp( fd, prop, conceptUrn )
    {
        fd.append( 'concept_urn', conceptUrn );
        if( prop.vals )
            fd.append( 'values', prop.vals );
        
        let urn = prop.urn;
        if( !urn )
            urn = 'urn:rdl:' + projObj.in_project.project_model_id + ':' + prop.name;
        
        return conf.queryForModelRDL( 'ifd_concept/prop_enum', { path_prm: '' + urn, data: fd }, 'POST' );
    },
    
    addRefDataNumProp( fd, prop, conceptUrn )
    {
        let type = 'urn:plcs:rdl:TruePLM:Numeric_value';
        if( prop.type === 'List' )
        {
            fd.append( 'super_urn', 'urn:plcs:rdl:std:Assigned_property' );
            type = 'urn:plcs:rdl:TruePLM:List,' + type;
        }
        
        fd.append( 'concept_urn', conceptUrn );
        fd.append( 'domain_urn', type );
        if( prop.units )
            fd.append( 'units', prop.units );
        
        let urn = prop.urn;
        if( !urn )
            urn = 'urn:rdl:' + projObj.in_project.project_model_id + ':' + prop.name;
        return conf.queryForModelRDL( 'ifd_concept/prop_num', { path_prm: '' + urn, data: fd }, 'POST' );
    },
    
    addRefDataDTProp( fd, prop, conceptUrn )
    {
        let type = 'urn:plcs:rdl:std:Date_time';
        if( prop.type === 'List' )
        {
            fd.append( 'super_urn', 'urn:plcs:rdl:std:Assigned_property' );
            type = 'urn:plcs:rdl:TruePLM:List,' + type;
        }
        
        fd.append( 'concept_urn', conceptUrn );
        fd.append( 'domain_urn', type );
        
        let urn = prop.urn;
        if( !urn )
            urn = 'urn:rdl:' + projObj.in_project.project_model_id + ':' + prop.name;
        
        return conf.queryForModelRDL( 'ifd_concept/prop', { path_prm: '' + urn, data: fd }, 'POST' );
    },
    
    addRefDataTxtProp( fd, prop, conceptUrn )
    {
        let type = 'urn:plcs:rdl:TruePLM:Text_value';
        if( prop.type === 'List' )
        {
            fd.append( 'super_urn', 'urn:plcs:rdl:std:Assigned_property' );
            type = 'urn:plcs:rdl:TruePLM:List,' + type;
        }
            
        fd.append( 'concept_urn', conceptUrn );
        fd.append( 'domain_urn', type );
        
        let urn = prop.urn;
        if( !urn )
            urn = 'urn:rdl:' + projObj.in_project.project_model_id + ':' + prop.name;
        return conf.queryForModelRDL( 'ifd_concept/prop', { path_prm: '' + urn, data: fd }, 'POST' );
    },
    
    addRefDataClass( val, prnUrn, descr )
    {
        var fd = new FormData();
        if( descr )
            fd.append( 'descr', descr );
        var urn = 'urn:rdl:' + projObj.in_project.project_model_id + ':' + val;
        return conf.queryForModelRDL( 'ifd_concept/class', { path_prm: '' + urn + '/' + prnUrn, data: fd }, 'POST' );
    },
    
    deletePropVals( urn, prnUrn )
    {
        return conf.queryForModel( 'bkd/prop', { path_prm: '' + urn, query_prm: 'remove_from=' + prnUrn }, 'DELETE' );
    },
    
    deleteRefData( urn, prnUrn )
    {
        return conf.queryForModelRDL( 'ifd_concept', { path_prm: '' + urn, query_prm: 'prn_urn=' + prnUrn }, 'DELETE' );
    },
    
    getAggrRules( prnUrn )
    {
        return conf.queryForModelRDL( 'ifd_concept/rules', { path_prm: '' + prnUrn }, 'GET' );
    },
    
    setAggrRules( rules, prnUrl )
    {
        let fd = new FormData();
        fd.append( 'rules', JSON.stringify( rules ) );
        
        return conf.queryForModelRDL( 'ifd_concept/rules', { path_prm: '' + prnUrl, data: fd }, 'POST' );
    },
    
    getAllowedChildren( prnUrn )
    {
         return conf.queryForModelRDL( 'ifd_concept/allowed_children', { path_prm: '' + prnUrn }, 'GET' );
    },
    
    setAllowedChildren( urns, prnUrn )
    {
        let ch = 'NONE';
        if( urns && urns.length > 0 )
        {
            if( urns.includes( 'UNSET' ) )
                ch = 'UNSET';
            else
                ch = urns.join();
        }
                
        let fd = new FormData();
        fd.append( 'urns', ch );
        
        return conf.queryForModelRDL( 'ifd_concept/allowed_children', { path_prm: '' + prnUrn, data: fd }, 'POST' );
    },
    
    setDefaultProp( urn, prnUrn )
    {
        return conf.queryForModelRDL( 'ifd_concept/def', { path_prm: '' + prnUrn + '/' + urn }, 'PUT' );
    },
    
    setKeyProp( urn, prnUrn )
    {
        return conf.queryForModelRDL( 'ifd_concept/key', { path_prm: '' + prnUrn + '/' + urn }, 'PUT' );
    },
    
    setOrderProp( urn, prnUrn )
    {
        return conf.queryForModelRDL( 'ifd_concept/order', { path_prm: '' + prnUrn + '/' + urn }, 'PUT' );
    },
    
    setDateFormat( prnUrn, format )
    {
        let fd = new FormData();
        fd.append( 'format', format );
        
        return conf.queryForModelRDL( 'ifd_concept/date_format', { path_prm: '' + prnUrn, data: fd }, 'POST' );
    },
    
    getDateFormat( prnUrn )
    {
        return conf.queryForModelRDL( 'ifd_concept/date_format', { path_prm: '' + prnUrn }, 'GET' );
    },
    
    getDefaultProp( prnUrn )
    {
        return conf.queryForModelRDL( 'ifd_concept/def', { path_prm: '' + prnUrn }, 'GET' );
    },
    
    getKeyProp( prnUrn )
    {
        return conf.queryForModelRDL( 'ifd_concept/key', { path_prm: '' + prnUrn }, 'GET' );
    },
    
    getOrderProp( prnUrn )
    {
        return conf.queryForModelRDL( 'ifd_concept/order', { path_prm: '' + prnUrn }, 'GET' );
    },
    
    checkIsBlockChain()
    {
        return conf.queryForModelRDL( 'ifd_concept/chain', {}, 'GET' );
    },

    checkIsDDIProject( bsl )
    {
        return conf.queryForModelRDL( 'ifd_concept/ddi', {}, 'GET' );
    },
    
    initBlockChain()
    {
        return conf.queryForModelRDL( 'ifd_concept/chain', {}, 'PUT' );
    },
    
    getConcept( urn, conc_proj )
    {
        return conf.queryForModelRDL( 'ifd_concept/concept', { path_prm: '' + urn }, 'GET', conc_proj );
    },
    
    getPropsByName( typeName )
    {
        return conf.queryForModelRDL( 'ifd_concept/prop', { path_prm: '' + typeName } );
    },
    
    getAllPropsByName( typeName )
    {
        return conf.queryForModelRDL( 'ifd_concept/prop/deep', { path_prm: '' + typeName } );
    },
    
    setPropOrder( typeName, orderStr )
    {
        let fd = new FormData();
        fd.append( 'order', orderStr );
        return conf.queryForModelRDL( 'ifd_concept/prop_order', { path_prm: '' + typeName, data: fd }, 'POST' );
    },
    
    moveProp( prop, oldPrn, newPrn )
    {
        let fd = new FormData();
        fd.append( 'class_from', oldPrn );
        fd.append( 'class_to', newPrn );
        return conf.queryForModelRDL( 'ifd_concept/move', { path_prm: '' + prop, data: fd }, 'POST' );
    },
   
//    openNode( node )
//    {
//        if( node.node_type )
//            return conf.queryForModel( 'node_t', { path_prm: '' + node.instance_id } );
//        else
//            return Promise.resolve( node );
//    },
//    
//    openNodePath( node )
//    {
//        return conf.queryForModel( 'stm_open_node', { id: node.INSTANCE_ID } );
//    
//    },

    getPathInfo( path, ver )
    {
        let qPrm = '';
        for( let i = 0; i < path.length; i++ )
        {
            if( path[ i ] )
                qPrm += ( qPrm ? ',' : '' ) + path[ i ];
        }
        qPrm = 'path=' + qPrm;
        if( ver )
            qPrm += '&ver=' + ver;
        
        return conf.queryForModel( 'bkd/path', { query_prm: qPrm } );        
    },

    searchFilesByContent( prms, limit )
    {
        let qPrm = '';
        if( prms.val )
            qPrm += 'pattern=' + encodeURIComponent( prms.val );
        
        if( limit !== null && limit !== undefined )
            qPrm += '&limit=' + limit;
        
        return conf.queryForModel( 'dat/txt_search', { query_prm: qPrm } );    
    },
    
    searchFiles( prms, limit, node )
    {
        let qPrm = '';
        Object.keys( prms ).forEach( function( key ) 
        {
            var val = prms[ key ];
            if( key === 'pCond' )
            {
                val.forEach( ( c ) =>
                {
                    qPrm += '&propName=' + c.urn;
                    qPrm += '&propVal=' + encodeURIComponent( c.val );                    
                });
            }
            else if( val && val !== '' )
            {
                if( qPrm !== '' )
                    qPrm += '&';
                
                if( key === 'pattern' || key === 'descr' )
                    qPrm += key + '=' + encodeURIComponent( val );
                else if( key === 'branchOnly' && val && node && node.instance_id )
                    qPrm += 'nodeID=' + node.instance_id;
                else
                    qPrm += key + '=' + val;
            }
        });
        if( limit !== null && limit !== undefined )
            qPrm += '&limit=' + limit;
        
        return conf.queryForModel( 'dat/a_search', { query_prm: qPrm } );        
    },
    
    getNodePath( node, guid )
    {
        return conf.queryForModel( 'bkd/search_path', { path_prm: '' + '' + node, 
                query_prm: guid ? ( 'id=' + guid ) : null } );        
    },
    
    getNodeChangeLog( node )
    {
        return conf.queryForModel( 'bkd/chlog', { path_prm: '' + '' + node } );        
    },
    
    searchNodes( prms, limit, node )
    {
        var qPrm = '';
        Object.keys( prms ).forEach( function( key ) 
        {
            var val = prms[ key ];
            if( key === 'pCond' )
            {
                val.forEach( ( c ) =>
                {
                    qPrm += '&propName=' + c.urn;
                    qPrm += '&propVal=' + encodeURIComponent( c.val );                    
                });
            }
            else if( val && val !== '' )
            {
                if( qPrm !== '' )
                    qPrm += '&';
                
                if( key === 'pattern' || key === 'descr' )
                    qPrm += key + '=' + encodeURIComponent( val );
                else if( key === 'branchOnly' && val && node && node.instance_id )
                    qPrm += 'nodeID=' + node.instance_id;
                else
                    qPrm += key + '=' + val;
            }
        });
        if( limit !== null && limit !== undefined )
            qPrm += '&limit=' + limit;
        
        return conf.queryForModel( 'bkd/a_search', { query_prm: qPrm } );        
    },
    
    searchFilesQuick( info, node )
    {
        let qPrm = 'pattern=';
        qPrm += info.val ? encodeURIComponent( info.val ) : '';
        
        if( info.case )
            qPrm += '&case_sens=true';
        
        let d = [];
        let p = [];
        if( info.descr )
            d.push( 'DESCRIPTION' );
        if( info.prop )
            d.push( 'PROPERTY' );
        if( info.type )
            d.push( 'CLASS' );
        if( info.name )
            d.push( 'ID' );
        
        if( d.length > 0 )
            qPrm += '&domains=' + d.join( ',' );
        
        for( let i = 0; info.prop && info.prop_list && info.prop_list.length > 0 && i < info.prop_list.length; i++ )
            p.push( info.prop_list[ i ] );
            
        if( p.length > 0 )
            qPrm += '&props=' + p.join( ',' );
        
        if( node && node.instance_id )
        {
            if( info.searchArea !== 'proj' )
                qPrm += '&node=' + node.instance_id;
        }
        
        return conf.queryForModel( 'dat/q_search', { query_prm: qPrm } );        
    },
    
    searchNodesQuick( info, node )
    {
//        console.log( info );
        let qPrm = 'pattern=';
        qPrm += info.val ? encodeURIComponent( info.val ) : '';
        
        if( info.case )
            qPrm += '&case_sens=true';
        
        let d = [];
        let p = [];
        if( info.descr )
            d.push( 'DESCRIPTION' );
        if( info.prop )
            d.push( 'PROPERTY' );
        if( info.type )
            d.push( 'CLASS' );
        if( info.name )
            d.push( 'ID' );
        
        if( d.length > 0 )
            qPrm += '&domains=' + d.join( ',' );
        
        for( let i = 0; info.prop && info.prop_list && info.prop_list.length > 0 && i < info.prop_list.length; i++ )
            p.push( info.prop_list[ i ] );
            
        if( p.length > 0 )
            qPrm += '&props=' + p.join( ',' );
        
        if( node && node.instance_id )
        {
            if( info.searchArea !== 'proj' )
                qPrm += '&node=' + node.instance_id;
            
            if( info.searchArea === 'folder' )
                qPrm += '&folder_only=true';
        }
        
        return conf.queryForModel( 'bkd/q_search', { query_prm: qPrm } );        
    },

    cancelCheckOutFile( file, actTime )
    {
        return conf.queryForModel( 'dat/file_checkout', { path_prm: '' + file.instance_id,
                                    query_prm: 'act_timestamp=' + actTime }, 'DELETE' );
    },

    checkOutFile( file, actTime )
    {
        return conf.queryForModel( 'dat/file_checkout', { path_prm: '' + file.instance_id,
                                    query_prm: 'act_timestamp=' + actTime }, 'PUT' );
    },

    linkFileToFile( file1, file2 )
    {
        return conf.queryForModel( 'dat/file_link', { path_prm: '' + file1.instance_id + '/' + file2.instance_id }, 'PUT' );
    },

    pasteFileToNode( node, file, moveFl, actTime )
    {
        return conf.queryForModel( 'dat/link', { path_prm: '' + node.instance_id + '/' + file.ass_doc_instance_id,
                                    query_prm: 'move=' + moveFl + '&act_timestamp=' + actTime }, 'PUT' );
    },

    deleteDocLink( file1, file2 )
    {
        return conf.queryForModel( 'dat/link', { path_prm: '' + file1.instance_id + '/' + file2.instance_id }, 'DELETE' );
    },
    
    clearRedFlag( file1, file2 )
    {
        return conf.queryForModel( 'dat/flag', { path_prm: '' + file1.instance_id + '/' + file2.instance_id }, 'PUT' );
    },
    
    getAssignment( inst )
    {
        return conf.queryForModel( 'bkd/assign', { path_prm: '' + '' + inst } );
    },
    
    deleteNodeOrg( node, org, role )
    {
        return conf.queryForModel( 'org', { path_prm: '' + '' + node.instance_id + '/' + org.org_id,
        query_prm: 'role=' + ( role || '' ) }, 'DELETE' );
    },
    
    getNodeOrgs( node, ver )
    {
        let qPrm = null;
        if( ver )
            qPrm = 'ver=' + ver;
        
        return conf.queryForModel( 'org', { path_prm: '' + '' + node.instance_id, query_prm: qPrm } );
    },
    
    addOrgToNode( node, org_id, role )
    {
        var fd = new FormData();
        fd.append( 'role', role );
        
        return conf.queryForModelWithData( 'org', { path_prm: '' + '' + node.instance_id + '/' + org_id, data: fd }, 'POST' );
    },

    setIssueComm( issueID, commID, text )
    {
        return conf.queryForModel( 'issue/comm', { path_prm: '' + issueID + '/' + commID, 
            query_prm: 'text=' + text }, 'PUT' );
    },

    delIssueComm( issueID, commID )
    {
        return conf.queryForModel( 'issue/comm', { path_prm: '' + issueID + '/' + commID }, 'DELETE' );
    },

    delIssueChild( issueID, childID, role )
    {
        return conf.queryForModel( 'issue/child', { path_prm: '' + issueID, 
                    query_prm: 'child=' + childID + '&role=' + role }, 'DELETE' );
    },

    addIssueComm( issueID, comm )
    {
        let fd = new FormData();
        fd.append( 'comm', comm );

        return conf.queryForModelWithData( 'issue/comm', { path_prm: '' + issueID, data: fd }, 'POST' );
    },
    
    cancelOrg( org )
    {
        return conf.queryForModel( 'org', { path_prm: '' + '' + org.org_id }, 'DELETE' );
    },
    
    selectOrgs( pattern )
    {
        return conf.queryForModel( 'org', { query_prm: 'pattern=' + ( pattern || '*' ) } );
    },

    setIssueTime( issueID, time, role )
    {
        return conf.queryForModel( 'issue/time', { path_prm: '' + issueID, 
                query_prm: 'time=' + time + '&role=' + role }, 'PUT' );
    },

    setIssueDescr( issueID, descr )
    {
        return conf.queryForModel( 'issue/descr', { path_prm: '' + issueID, 
                query_prm: 'descr=' + descr }, 'PUT' );
    },

    setIssuePersonList( issueID, users, role )
    {
        let funcName = 'person_list';
        let fd = new FormData();
        if( Array.isArray( users ) && users.length > 0 )
        {
            for( let i = 0; i < users.length; i++ )
            {
                fd.append( 'user', users[ i ] );
            }
        }
        else
        {
            if( Array.isArray( users ) )
                users = '';
            fd.append( 'user', users || '' );
            funcName = 'person';
        }

        fd.append( 'role', role );

        return conf.queryForModelWithData( 'issue/' + funcName, { path_prm: '' + issueID, data: fd }, 'PUT' );
    },

    setIssuePerson( issueID, user, role )
    {
        return conf.queryForModel( 'issue/person', { path_prm: '' + issueID, 
                query_prm: 'user=' + user + '&role=' + role }, 'PUT' );
    },

    setIssueID( issueID, prnURN, id )
    {
        return conf.queryForModel( 'issue/id', { path_prm: '' + issueID, 
                query_prm: 'domain=' + prnURN + '&id=' + id }, 'PUT' );
    },

    setIssueClass( issueID, prnURN, classURN )
    {
        return conf.queryForModel( 'issue/class', { path_prm: '' + issueID, 
                query_prm: 'domain=' + prnURN + '&class_urn=' + classURN }, 'PUT' );
    },

    addIssueSubject( issueID, subjID )
    {
        return conf.queryForModel( 'issue/subj', { path_prm: '' + issueID, query_prm: 'subj=' + subjID }, 'PUT' );
    },

    addIssueChild( prnID, issueID, role )
    {
        let prm = 'child=' + issueID;
        if( role )
            prm += '&role=' + role;

        return conf.queryForModel( 'issue/child', { path_prm: '' + prnID, query_prm: prm }, 'PUT' );
    },

    getIssue( issueID )
    {
        return conf.queryForModel( 'issue', { path_prm: '' + issueID } );
    },

    deleteIssue( issueID )
    {
        return conf.queryForModel( 'issue', { path_prm: '' + issueID }, 'DELETE' );
    },

    getIssueList( branch )
    {
        return conf.queryForModel( 'issue/list' );//, { query_prm: 'branch=' + ( branch || 0 ) } );
    },

    addIssue( info )
    {
        let fd = new FormData();
        fd.append( 'name', info.name );
        if( info.description )
            fd.append( 'descr', info.description );
        if( info.status )
            fd.append( 'status', info.status );
        if( info.priority )
            fd.append( 'prior', info.priority );
        if( info.issue_type )
            fd.append( 'type', info.issue_type );
        if( info.responsible )
            fd.append( 'resp', info.responsible );
        if( info.master )
            fd.append( 'prnID', info.master );
        if( info.domain )
            fd.append( 'domain', info.domain );
        
        return conf.queryForModelWithData( 'issue', { data: fd }, 'POST' );
    },
    
    addOrg( info )
    {
        let fd = new FormData();
        fd.append( 'name', info.title );
        if( info.uid )
            fd.append( 'id', info.uid );
        if( info.address )
            fd.append( 'address', info.address );
        if( info.email )
            fd.append( 'email', info.email );
        if( info.url )
            fd.append( 'url', info.url );
        if( info.phone )
            fd.append( 'phone', info.phone );
        if( info.description )
            fd.append( 'description', info.description );

        return conf.queryForModelWithData( 'org', { data: fd }, 'POST' );
    },
    
    editOrg( info )
    {
        var fd = new FormData();
        fd.append( 'name', info.title );
        if( info.uid )
            fd.append( 'id', info.uid );
        if( info.address )
            fd.append( 'address', info.address );
        if( info.email )
            fd.append( 'email', info.email );
        if( info.url )
            fd.append( 'url', info.url );
        if( info.phone )
            fd.append( 'phone', info.phone );
        if( info.description )
            fd.append( 'description', info.description );

        return conf.queryForModelWithData( 'org', { path_prm: '' + '' + info.org_id, data: fd }, 'POST' );
    },
    
    updateRelInfo( relID, rem )
    {
        var fd = new FormData();
        fd.append( 'remark',  rem );

        return conf.queryForModelWithData( 'bkd/rel_up', { path_prm: '' + '' + relID, data: fd }, 'POST' );
    },
    
    getRelInfo( node, type, role )
    {
        return conf.queryForModel( 'bkd/rels', { path_prm: '' + '' + node.instance_id, 
            query_prm: 'type=' + type + '&role=' + role } );
    },

    getRelsInfo( node, type, ver )
    {
        let qPrm = 'type=' + type ? type : '';
        if( ver )
            qPrm += '&ver=' + ver;
        
        return conf.queryForModel( 'bkd/rels', { path_prm: '' + '' + node.instance_id, query_prm: qPrm } );
    },
    
    addStickyNote( file, ver, content )
    {
        var fd = new FormData();
        fd.append( 'ver',  ver.version_id );
        fd.append( 'content', content );
        return conf.queryForModelWithData( 'dat/note', { path_prm: '' + file.instance_id, data: fd }, 'POST' );
    },
    
    deleteStickyNote( noteID )
    {
        return conf.queryForModel( 'dat/note', { path_prm: '' + noteID }, 'DELETE' );
    },
    
    deleteFile( file, forAll, actTime )
    {
        return conf.queryForModel( 'dat', { path_prm: '' + file.ass_doc_instance_id, 
            query_prm: 'all=' + forAll + '&act_timestamp=' + actTime }, 'DELETE' );
    },
    
    setFileReadOnly( file, val, actTime )
    {
        return conf.queryForModel( 'dat/acc', { path_prm: '' + '' + file.instance_id, 
            query_prm: 'read_only=' + val + '&act_timestamp=' + actTime }, 'PUT' );
    },
    
    checkInFile( file, info, actTime )
    {
        var fd = new FormData();
        fd.append( 'file', info.files[ 0 ].file );
        fd.append( 'descr', info.descr );
        fd.append( 'isNewIssue', info.isNewIssue );
        fd.append( 'status', info.statusVal );
        fd.append( 'act_timestamp', actTime );
        
        return conf.queryForModelWithData( 'dat/file_checkin', { path_prm: '' + file.ass_doc_instance_id, data: fd }, 'POST' );
    },
    
//    updateNodeProp( node, prop )
//    {
//        var fd = new FormData();
//        var props = prop.type;
//        var vals = prop.val;
//        var units = '';
//
//        if( prop.delete )
//            units += '$$act_delete$$';
//        else
//            units += prop.unit_code;
//        
//        fd.append( 'props', props );
//        fd.append( 'vals', vals );
//        fd.append( 'units', units );
//        return conf.queryForModelWithData( 'bkd/prop', { path_prm: '' + node.instance_id, data: fd }, 'POST' );
//    },

    updateFileClass( node, propList )
    {
        var fd = new FormData();
        fd.append( 'name', propList[ 0 ].type );
        fd.append( 'val', propList[ 0 ].val );

        return conf.queryForModelWithData( 'dat/class', { path_prm: '' + node.instance_id, data: fd }, 'POST' );
    },
    
    updateNodeProps( node, propList, nodeType, actTime )
    {
        var fd = new FormData();
        var props = '';
        var vals = '';
        var units = '';
        var propTypes = '';

        propList.forEach( ( prop ) =>
        {
            //console.log( prop );
            if( prop.obj && ( prop.val !== prop.obj.val || prop.unit_code !== prop.obj.unit || prop.delete ) )
            {
                if( props.length > 0 )
                {
                    props += ',';
                    vals += ',';
                    units += ',';
                    propTypes += ',';
                }
                props += prop.type ? prop.type : '';
                vals += prop.val ? encodeURIComponent( prop.val ) : '';
                
                if( prop.delete )
                    propTypes += '$$act_delete$$';
                else
                    propTypes += prop.type_view;
                    
                units += prop.unit_code ? prop.unit_code : '';
            }
        });
        fd.append( 'props', props );
        fd.append( 'vals', vals );
        fd.append( 'units', units );
        fd.append( 'ptypes', propTypes );
        fd.append( 'act_timestamp', actTime );
        if( nodeType === 'file' )
            return conf.queryForModelWithData( 'dat/prop', { path_prm: '' + node.instance_id, data: fd }, 'POST' );
        else if( nodeType === 'prod' )
            return conf.queryForModelWithData( 'prod/prop', { path_prm: '' + '' + node.view_id, data: fd }, 'POST' );
        else
            return conf.queryForModelWithData( 'bkd/prop', { path_prm: '' + node.instance_id, data: fd }, 'POST' );
    },
    
    addFileToNode( node, info, actTime )
    {
        let fd = new FormData();
        fd.append( 'file', info.files[ 0 ].file );
        fd.append( 'descr', info.descr );
        fd.append( 'title', info.title );
        fd.append( 'source', info.sourceVal );
        fd.append( 'contentType', info.cTypeVal );
        fd.append( 'discipline', info.disciplineVal );
        fd.append( 'projPhase', info.pPhaseVal );
        fd.append( 'status', info.statusVal );
        fd.append( 'editor', info.editorVal );
        fd.append( 'resp', info.respVal );
        fd.append( 'rev', info.revVal );
        fd.append( 'app', info.appVal );
        fd.append( 'revMan', info.rmanVal );
        fd.append( 'act_timestamp', actTime );
        return conf.queryForModelWithData( 'dat', { path_prm: '' + node.instance_id, data: fd }, 'POST' );
    },
    
    exportToAP242( node )
    {
        let qPrm = null;
        if( node )
            qPrm = 'node=' + node.instance_id;

        return conf.queryForModel( 'exch/export/ap242', { path_prm: '' + projObj.in_project.name, query_prm: qPrm } );
    },
    
    exportNodeToZip( node )
    {
        return conf.queryForModel( 'exch/export/zip', { path_prm: '' + '' + node.instance_id } );
    },
    
    exportBaselineToFile( bsl )
    {
        return conf.queryForModel( 'bsl/export', { path_prm: '' + bsl.id } );
    },
    
    exportProjToDEX( asStruct )
    {
        return conf.queryForModel( 'exch/export/dex', { path_prm: '' + projObj.in_project.name, query_prm: 'as_struct=' + asStruct } );
    },
    
    exportStructToFile()
    {
        return conf.queryForModel( 'exch/export/txt', { path_prm: '' + projObj.in_project.name } );
    },

    exportProjToDDI( bsl )
    {
        return conf.queryForModel( 'exch/export/ddi', { path_prm: '' + bsl.id } );
    },
    
    exportProjToFile( node )
    {
        let qPrm = null;
        if( node )
            qPrm = 'node=' + node.instance_id;
        return conf.queryForModel( 'exch/export', { path_prm: '' + projObj.in_project.name, query_prm: qPrm } );
    },
    
    importFileToProj( node, info )
    {
        var fd = new FormData();
        fd.append( 'file', info.files[ 0 ].file );
                
        return conf.queryForModelWithData( 'exch/import', { data: fd }, 'POST' );
    },
    
    importStructToProj( node, info )
    {
        var fd = new FormData();
        fd.append( 'file', info.files[ 0 ].file );
                
        return conf.queryForModelWithData( 'exch/import/txt', { data: fd }, 'POST' );
    },
    
    importFileToNode( node, info, actTime )
    {
        var fd = new FormData();
        fd.append( 'file', info.files[ 0 ].file );
        fd.append( 'act_timestamp', actTime );
        
        return conf.queryForModelWithData( 'exch/import', { path_prm: '' + node.instance_id, data: fd }, 'POST' );
    },
    
    uploadAggrPropData( node, prop, data, actTime )
    {
        var fd = new FormData();
        fd.append( 'file', data.files[ 0 ].file );
        fd.append( 'act_timestamp', actTime );
        
        let srv = 'bkd/aggr';
        if( data.fileType === 'json' )
            srv += '';//'_json';
        else if( data.fileType === 'csv' )
            srv += '_csv';
        return conf.queryForModelWithData( srv, 
        { path_prm: '' + node.instance_id + '/' + prop.type, data: fd }, 'POST' );
    },
    
    importAP242FileToNode( node, info, actTime )
    {
        var fd = new FormData();
        fd.append( 'file', info.files[ 0 ].file );
        if( info.packs && info.packs.length > 0 )
            fd.append( 'pack', info.packs[ 0 ].file );
        fd.append( 'act_timestamp', actTime );
        
        return conf.queryForModelWithData( 'exch/import242', { path_prm: '' + node.instance_id, data: fd }, 'POST' );
    },
    
    importCSVFileToNode( node, info, actTime )
    {
        var fd = new FormData();
        fd.append( 'file', info.files[ 0 ].file );
        fd.append( 'act_timestamp', actTime );
        
        return conf.queryForModelWithData( 'exch/importCSV', { path_prm: '' + node.instance_id, data: fd }, 'POST' );
    },

    importTxtFileToNode( node, info, actTime )
    {
        var fd = new FormData();
        fd.append( 'file', info.files[ 0 ].file );
        fd.append( 'act_timestamp', actTime );
        
        return conf.queryForModelWithData( 'exch/import_txt', { path_prm: '' + node.instance_id, data: fd }, 'POST' );
    },
    
    importCSVFileToProj( info, actTime )
    {
        var fd = new FormData();
        fd.append( 'file', info.files[ 0 ].file );
        fd.append( 'act_timestamp', actTime );
        
        return conf.queryForModelWithData( 'exch/importCSV', { data: fd }, 'POST' );
    },
    
    genDocFromTmpl( node, info, actTime )
    {
        let fd = new FormData();
        fd.append( 'file', info.files[ 0 ].file );
        fd.append( 'descr', info.descr );
        fd.append( 'title', info.title );
        fd.append( 'source', info.sourceVal );
        fd.append( 'contentType', info.cTypeVal );
        fd.append( 'discipline', info.disciplineVal );
        fd.append( 'projPhase', info.pPhaseVal );
        fd.append( 'status', info.statusVal );
        fd.append( 'editor', info.editorVal );
        fd.append( 'resp', info.respVal );
        fd.append( 'rev', info.revVal );
        fd.append( 'app', info.appVal );
        fd.append( 'revMan', info.rmanVal );
        fd.append( 'act_timestamp', actTime );
        fd.append( 'server', window.location.href );
        return conf.queryForModelWithData( 'tmpl', { path_prm: '' + '' + node.instance_id, data: fd }, 'POST' );
    },
    
    convertFileToWCAX( nodeID, fileName, fileVerID, actTime )
    {
        let fd = new FormData();
        fd.append( 'ver', fileVerID );
        fd.append( 'name', fileName );
        fd.append( 'act_timestamp', actTime );

        return conf.queryForModelWithData( 'conv/wcax', { path_prm: '' + '' + nodeID, data: fd }, 'POST' );
    },
    
    importReqIFToNode( node, info, actTime )
    {
        var fd = new FormData();
        fd.append( 'file', info.files[ 0 ].file );
        fd.append( 'act_timestamp', actTime );
        
        return conf.queryForModelWithData( 'exch/import_reqif', { path_prm: '' + node.instance_id, data: fd }, 'POST' );
    },
    
    downloadFileVer( fileName, verID, verStr )
    {
        let verAdd = '';
        if( verStr )
        {
            let verParts = verStr.split( '.' );
            verAdd = ' (rev.' + verParts[ 0 ] + ' ver.' + verParts[ 1 ] + ')';
            let ind = fileName.lastIndexOf( '.' );
            if( ind > 0 )
            {
                let ext = fileName.substring( ind );
                fileName = fileName.substring( 0, ind ) + verAdd + ext;
            }
        }
        return conf.queryForModel( 'dat/file/link', { query_prm: 'ver=' + verID + '&name="' + fileName + '"'  } );
    },

    getFileStrings( src )
    {
        return auth.apiReq( { path: 'dat/file/str_data/' + src } );
    },
    
    getFileLink( fileName, src )
    {
        return auth.apiReq( { path: 'dat/file/data/' + src + '/' + encodeURIComponent( fileName ), onlyURL: true } );
    },
    
    getFileLinkData( fileName, src )
    {
        return auth.apiReq( { path: 'dat/file/data/' + src + '/' + encodeURIComponent( fileName ), dataType: 'arraybuffer' } );
        //return conf.queryForModel( 'dat', { query_prm: 'ver=' + verID + '&name="' + fileName + '"', dataType: 'arraybuffer' } );
    },
    
    getBaselineList()
    {
        return conf.queryForModel( 'bsl', {} );
    },
    
    getNodeChildren( node, ver, strInd, pageSize, sortDec, showOccur, showShape, childOccur )
    {
        let query_prm = '';
//        for( let i = 0; node.properties && i < node.properties.length; i++ )
//        {
//            let prop = node.properties[ i ];
//            if( prop.name.substring( prop.name.length - 7 ) === 'OrderBy' )
//            {
//                query_prm = 'sort_attr=' + prop.name;
//                break;
//            }
//        }
        let nodeID = node.instance_id;
//        if( node.occurrence )
//            nodeID = node.occurrence.instance_id;
        
        return conf.queryForModel( 'bkd', { path_prm: '' + nodeID, 
            query_prm: query_prm + '&ver=' + ver + '&str_ind=' + ( strInd || 1 ) + '&count=' + ( pageSize || 100 )
        + '&asc_sort=' + !sortDec + '&show_occur=' + showOccur + '&show_shape=' + showShape + '&child_occur=' + childOccur } );
    },
    
    getTreeNodeChildren( node, ver, level, strInd, count )
    {
        if( !strInd )
            strInd = 1;
        if( !count )
            count = 1000000;
        
        return conf.queryForModel( 'bkd/opt', { path_prm: '' + node.instance_id , 
            query_prm: 'ver=' + ver + '&str_ind=' + strInd + '&count=' + count + '&level=' + level } );
    },
    
    getAllNodeChildren( node, ver, level )
    {
        return conf.queryForModel( 'bkd/full', { path_prm: '' + node.instance_id , 
            query_prm: 'ver=' + ver + '&level=' + level } );
    },
    
    deleteBaseline( baseline )
    {
        return conf.queryForModel( 'bsl', { path_prm: '' + baseline.id }, 'DELETE' );
    },
    
    getNodeDelHinders( node )
    {
        return conf.queryForModel( 'bkd/del_hinder', { path_prm: '' + node.instance_id } );
    },
    
    deleteNode( node, forAll, actTime )
    {
        return conf.queryForModel( 'bkd', { path_prm: '' + node.instance_id, query_prm: 'all=' + forAll + '&act_timestamp=' + actTime }, 'DELETE' );
    },
    
    renameNode( node, name, actTime )
    {
        var fd = new FormData();
        fd.append( 'name', name );
        fd.append( 'act_timestamp', actTime );
        return conf.queryForModelWithData( 'bkd', { path_prm: '' + node.instance_id, data: fd }, 'POST' );
    },
    
    moveNode( newPrnNode, node, copyNode, actTime, timeLim )
    {
        var fd = new FormData();
        fd.append( 'copy', copyNode );
        fd.append( 'act_timestamp', actTime );
        fd.append( 'time_lim', timeLim );
        return conf.queryForModelWithData( 'bkd', { path_prm: '' + node.instance_id + '/' + newPrnNode.instance_id, data: fd }, 'POST' );
    },
    
    addRelNode( node1, node2, type, rem, cap1, cap2 )
    {
        var fd = new FormData();
        fd.append( 'chNode', node2.instance_id );
        fd.append( 'r_type', type );
        if( rem )
            fd.append( 'remark', rem );
        if( cap1 )
            fd.append( 'title1', cap1 );
        if( cap2 )
            fd.append( 'title2', cap2 );
        
        return conf.queryForModelWithData( 'bkd/rel', { path_prm: '' + '' + node1.instance_id, data: fd }, 'POST' );
    },
    
    deleteRelNode( rel )
    {
        return conf.queryForModel( 'bkd/rel', { path_prm: '' + '' + rel.instance_id }, 'DELETE' );
    },
    
    versionList()
    {
        return conf.queryForModel( 'bkd/versions' );
    },
    
    subdomainList( node )
    {
        return conf.queryForModel( 'subd/list', {} );
    },
    
    memberList( node )
    {
        return conf.queryForModel( 'subd/member', { path_prm: '' + node.instance_id } );
    },
    
    roleList( node )
    {
        return conf.queryForModel( 'subd/role', { path_prm: '' + node.instance_id } );
    },
    
    roleAdd( node, role, perms )
    {
        var fd = new FormData();
        if( perms && perms.length > 0 )
        {
            perms.forEach( ( perm ) =>
            {
                fd.append( perm, true );                
            });
        }
        return conf.queryForModelWithData( 'subd/role', { path_prm: '' + node.instance_id + '/' + role, data: fd }, 'POST' );
    },
    
    roleDelete( node, role )
    {
        return conf.queryForModel( 'subd/role', { path_prm: '' + node.instance_id + '/' + role }, 'DELETE' );
    },
    
    memberAdd( node, user, roles )
    {
        var fd = new FormData();
        if( roles && roles.length > 0 )
        {
            roles.forEach( ( role ) =>
            {
                fd.append( 'role', role );                
            });
        }
        return conf.queryForModelWithData( 'subd/member', { path_prm: '' + node.instance_id + '/' + user, data: fd }, 'POST' );
    },
    
    memberDelete( node, user )
    {
        return conf.queryForModel( 'subd/member', { path_prm: '' + node.instance_id + '/' + user }, 'DELETE' );
    },

    createSubdomain( root, info )
    {
        var fd = new FormData();
        if( info.phase )
            fd.append( 'phase', info.phase );
        
        return conf.queryForModelWithData( 'subd', { path_prm: '' + root.instance_id + '/' + info.user, data: fd }, 'POST' );
    },
    
    deleteSubdomain( node )
    {
        return conf.queryForModel( 'subd', { path_prm: '' + node.id }, 'DELETE' );
    },
    
    createBaseline( root, baselineInfo )
    {
        var fd = new FormData();
        fd.append( 'baseline', baselineInfo.id );
        fd.append( 'descr', baselineInfo.descr );
        return conf.queryForModelWithData( 'bsl/create', { path_prm: '' + root.instance_id, data: fd }, 'POST' );
    },
    
    createNode( node, newNodeInfo, actTime )
    {
        var fd = new FormData();
        fd.append( 'name', newNodeInfo.name );
        fd.append( 'descr', newNodeInfo.descr );
        fd.append( 'nodeType', newNodeInfo.type );
        fd.append( 'act_timestamp', actTime );
        if( newNodeInfo.tmpl )
            fd.append( 'tmpl', newNodeInfo.tmpl.in_project.project_model_id );
        return conf.queryForModelWithData( 'bkd/create', { path_prm: '' + node.instance_id, data: fd }, 'POST' );
    },
    
    createRoot( newNodeInfo, actTime )
    {
        var fd = new FormData();
        fd.append( 'name', newNodeInfo.name );
        fd.append( 'descr', newNodeInfo.descr );
        fd.append( 'act_timestamp', actTime );
        return conf.queryForModelWithData( 'bkd/root', { data: fd }, 'POST' );
    },
    
    editNode( node, newNodeInfo, actTime )
    {
        var fd = new FormData();
        fd.append( 'name', newNodeInfo.name );
        fd.append( 'descr', newNodeInfo.descr );
        fd.append( 'nodeType', newNodeInfo.type );
        fd.append( 'act_timestamp', actTime );
        return conf.queryForModelWithData( 'bkd/edit', { path_prm: '' + node.instance_id, data: fd }, 'POST' );
    },
    
    getProdsInNode( node )
    {
        if( node.root || node.is_part )
            return Promise.resolve( [] );
        else
            return conf.queryForModel( 'bkd/unit', { path_prm: '' + '' + node.instance_id } );
    },
    
    removeProdFromNode( prod, actTime )
    {
        return conf.queryForModel( 'bkd/unit', { path_prm: '' + '' + prod.parent.instance_id,
            query_prm: 'prod=' + prod.elemID + '&act_timestamp=' + actTime }, 'DELETE' );
    },
    
    putProdToNode( node, prod, actTime )
    {
        var fd = new FormData();
        fd.append( 'prod', prod.elemID );
        fd.append( 'act_timestamp', actTime );
        return conf.queryForModelWithData( 'bkd/unit', { path_prm: '' + '' + node.instance_id, data: fd }, 'POST' );
    },
    
    getProdList( name )
    {
        let qPrm = '';
        if( name )
            qPrm += '&name=' + name;
        return conf.queryForModel( 'prod', { query_prm: qPrm } );
    },
    
    getProdsInAUnit( prod )
    {
        return conf.queryForModel( 'prod/unit', { path_prm: '' + '' + prod.elemID } );
    },
    
    delProd( prod )
    {
        return conf.queryForModel( 'prod', { path_prm: '' + '' + ( prod.elemID || prod.view_id ) }, 'DELETE' );
    },
    
    getProdInfo( prod )
    {
        return conf.queryForModel( 'prod/info', { path_prm: '' + '' + prod.elemID } );
    },
    
    removeProd( prod )
    {
        let qPrm = 'prod=' + prod.elemID;
        if( prod.location )
            qPrm += '&location=' + prod.location;
        if( prod.quantity )
            qPrm += '&quantity=' + prod.quantity;
        
        return conf.queryForModel( 'prod/unit', { path_prm: '' + '' + prod.parent.elemID,
            query_prm: qPrm }, 'DELETE' );
    },
    
    createProd( prodInfo, actTime )
    {
        var fd = new FormData();
        fd.append( 'name', prodInfo.name );
        if( prodInfo.descr )
            fd.append( 'description', prodInfo.descr );
        if( prodInfo.domain )
            fd.append( 'domain', prodInfo.domain );
        if( prodInfo.stage )
            fd.append( 'stage', prodInfo.stage);
        let types = prodInfo.types.join();
        fd.append( 'type_urn', types );
        fd.append( 'act_timestamp', actTime );
        return conf.queryForModelWithData( 'prod', { data: fd }, 'POST' );
    },
    
    putProdToAUnit( prnProd, prod, location, num, actTime )
    {
        var fd = new FormData();
        fd.append( 'prod', prod.elemID );
        if( location )
            fd.append( 'location', location );
        fd.append( 'quantity', num || 1 );
        fd.append( 'act_timestamp', actTime );
        return conf.queryForModelWithData( 'prod/unit', { path_prm: '' + '' + prnProd.elemID, data: fd }, 'POST' );
    },
    
    delAction( act )
    {
        return conf.queryForModel( 'notify/act', { query_prm: 'name=' + act.name }, 'DELETE' );
    },
    
    delTrigger( trg )
    {
        return conf.queryForModel( 'notify/trigger', { query_prm: 'name=' + trg.name }, 'DELETE' );
    },
    
    listTriggers( isAct, event )
    {
        let qPrm = null;
        if( isAct !== undefined )
            qPrm = 'is_active=' + isAct;
        
        if( event )
        {
            if( qPrm )
                qPrm += '&';
            else
                qPrm = '';
            
            qPrm += 'event=' + event;
        }

        return conf.queryForModel( 'notify/trigger', { query_prm: qPrm } );
    },
    
    listActions()
    {
        return conf.queryForModel( 'notify/act' );
    },
    
    listNTasks()
    {
        return conf.queryForModel( 'notify/n_task' );
    },
    listNTasksForProj( task_proj )
    {
        return conf.queryForModel( 'notify/user_task', { query_prm: 'act_type=NOTIFY' }, 'GET', task_proj );
    },
    listUserNTasks( task_proj )
    {
        return conf.queryForModel( 'notify/user_task', { query_prm: 'act_type=NOTIFY' }, 'GET', task_proj );
    },
    
    addP2PMsg( subj, text, users, nodeID, docID, strID, type, status, end_date, msg_proj )
    {
        var fd = new FormData();
        fd.append( 'type', type );
        fd.append( 'subj', subj );
        fd.append( 'text', text );
        fd.append( 'users', users.join( ',' ) );
        if( nodeID )
            fd.append( 'node', nodeID );
        if( docID )
            fd.append( 'doc', docID );
        if( strID )
            fd.append( 'str_id', strID );
        if( status )
            fd.append( 'status', status );
        if( end_date )
            fd.append( 'deadline', end_date );
        
        return conf.queryForModelWithData( 'notify/msg', { data: fd }, 'POST', msg_proj );
    },
        
    delNTask( task )
    {
        return conf.queryForModel( 'notify/n_task', { path_prm: '' + '' + task.id }, 'DELETE' );
    },
    
    actNTask( task )
    {
        return conf.queryForModel( 'notify/n_task', { path_prm: '' + '' + task.id }, 'PUT' );
    },
    
    cancelNTaskForUser( task_proj, task )
    {
        return conf.queryForModel( 'notify/user_task', { path_prm: '' + task.id }, 'DELETE', task_proj );
    },
    
    cancelNTask( task_proj, task )
    {
        return conf.queryForModel( 'notify/n_task', { path_prm: '' + task.id, query_prm: 'is_open=false' }, 'PUT', task_proj );
    },
    
    defineAction( actInfo )
    {
        var fd = new FormData();
        fd.append( 'name', actInfo.name );
        if( actInfo.id )
            fd.append( 'id', actInfo.id );
        if( actInfo.message )
            fd.append( 'msg', actInfo.message );
        if( actInfo.subj )
            fd.append( 'subj', actInfo.subj );
        if( actInfo.users )
            fd.append( 'users', actInfo.users );
        if( actInfo.roles )
            fd.append( 'roles', actInfo.roles );
        if( actInfo.action_type )
            fd.append( 'type', actInfo.action_type );
        if( actInfo.subdomain_id )
            fd.append( 'subdomain', actInfo.subdomain_id );
        
        return conf.queryForModelWithData( 'notify/act', { data: fd }, 'POST' );
    },
    
    defineTrigger( trgInfo )
    {
        let fd = new FormData();
        fd.append( 'name', trgInfo.name );
        if( trgInfo.id )
            fd.append( 'id', trgInfo.id );
        if( trgInfo.events )
            fd.append( 'event', trgInfo.events );
        if( trgInfo.condition )
            fd.append( 'cond', trgInfo.condition );
        if( trgInfo.act )
            fd.append( 'action', trgInfo.act );
        if( trgInfo.active )
            fd.append( 'active', trgInfo.active );
        
        
        return conf.queryForModelWithData( 'notify/trigger', { data: fd }, 'POST' );
    },
    
    queryForModelWithData( service, params, method, query_proj )
    {
        params.contentType = 'multipart/form-data';
        params.credentials = false;
        return conf.queryForModel( service, params, method, query_proj );
    },
    
    queryForModel( service, params, method, query_proj )
    {
        if( !query_proj )
            query_proj = projObj;
        
        if( !params )
            params = {};
        
        if( !method )
            method = 'GET';
        
        let newPath = service + '/' + conf.getRepository( query_proj ) + '/' + query_proj.in_project.project_model_id;
        if( params.path_prm )
        {
            params.path_prm = params.path_prm.replace( new RegExp( '\\?', 'g' ), '%3F' );
            newPath += '/' + params.path_prm;
        }

        params.path = newPath;
        params.method = method;
        
        return auth.apiReq( params );
    },
    
    queryForModelRDL( service, params, method, query_proj )
    {
        if( !query_proj )
            query_proj = projObj;
        
        let newPath = service + '/' + conf.getRepository( query_proj ) + '/' + query_proj.in_project.project_model_id + '_RDL';
        if( params.path_prm )
        {
            params.path_prm = params.path_prm.replace( new RegExp( '\\?', 'g' ), '%3F' );
            newPath += '/' + params.path_prm;
        }
        
        params.path = newPath;
        params.method = method;
        
        return auth.apiReq( params );
    },
    
    getTokenList()
    {
        return auth.apiReq( { path: 'token' } )
                .then( ( data ) =>
        {
            return data;
        });
    },
    
    addToken( descr )
    {
        var fd = new FormData();
        fd.append( 'descr', descr ); 
        
        return auth.apiReq( { path: 'token', data: fd, method: 'POST' } )
                .then( ( data ) =>
        {
            return data;
        });
    },
    
    deleteToken( tokenID )
    {
        return auth.apiReq( { path: 'token/' + tokenID, method: 'DELETE' } )
                .then( ( data ) =>
        {
            return data;
        });
    },
    
    getAllSync()
    {
        return auth.apiReq( { path: 'sync' } )
                .then( ( data ) =>
        {
            return data;
        });
    },
    
    deleteSync( id )
    {
        return auth.apiReq( { path: 'sync/' + id, method: 'DELETE' } )
                .then( ( data ) =>
        {
            return data;
        });
    },
    
    createSync( id, addr, sync_proj )
    {
        var fd = new FormData();
        fd.append( 'id', id ); 
        fd.append( 'addr', addr );
        fd.append( 'proj', sync_proj );
       
        return auth.apiReq( { path: 'sync', data: fd, method: 'POST' } )
                .then( ( data ) =>
        {
            return data;
        });
    },
    
    updateSync( id, addr, sync_proj )
    {
        return auth.apiReq( { path: 'sync/' + id, method: 'PUT',
            query_prm: 'addr=' + addr + '&proj=' + sync_proj } )
                .then( ( data ) =>
        {
            return data;
        });
    },
    
    getProjStruct()
    {
        return auth.apiReq( { path: 'pfolder' } );
    },
    
    moveProjToFolder( folderName, projID )
    {
        folderName = encodeURIComponent( folderName );
        return auth.apiReq( { path: 'pfolder/' + folderName, query_prm: 'proj=' + projID, method: 'PUT' } );
    },
    
    renameFolder( folderName, newName )
    {
        folderName = encodeURIComponent( folderName );
        newName = encodeURIComponent( newName );
        return auth.apiReq( { path: 'pfolder/rename/' + folderName, query_prm: 'name=' + newName, method: 'PUT' } );
    },
    
    deleteFolder( folderName )
    {
        folderName = encodeURIComponent( folderName );
        return auth.apiReq( { path: 'pfolder/' + folderName, method: 'DELETE' } );
    },
    
    createFolder( folderName )
    {
        let fd = new FormData();
        fd.append( 'folder', folderName ); 
       
        return auth.apiReq( { path: 'pfolder', data: fd, method: 'POST' } );
    },
    
    getRepositoryList()
    {
        return auth.apiReq( { path: 'adm_user/all_user_proj' } )
                .then( ( data ) =>
        {
            return data;
        });
    },
    
    getUserNames()
    {
        return auth.apiReq( { path: 'adm_user/names' } )
                .then( ( data ) =>
        {
            return data;
        });
    },
    
    getAllUsers()
    {
        return auth.apiReq( { path: 'adm_user/all_users_info' } );
    },
    
    getCurrentUsers()
    {
        return auth.apiReq( { path: 'adm_user/all_user_info' } );
    },
    
    getProjectUserList( projID )
    {
        return auth.apiReq( { path: 'adm_user/users', query_prm: 'proj=' + projID } );
    },
    
    deleteUserFromProj( projID, user )
    {
        return auth.apiReq( { path: 'adm_user/users', query_prm: 'proj=' + projID + '&user=' + user, method: 'DELETE' } );
    }, 
    
    deleteUser( user )
    {
        return auth.apiReq( { path: 'adm_user/names/' + user, method: 'DELETE' } );
    }, 
    
    setUserPass( oldPass, newPass )
    {
        let info = { oldPass: oldPass, newPass: newPass };

        let fd = new FormData();
        fd.append( 'info', btoa( JSON.stringify( info ) ) ); 
       
        return auth.apiReq( { path: 'adm_user/names/user/pass', data: fd, method: 'POST' } );
    },
    
    setUserPassByAdmin( user, pass )
    {
        let info = { newPass: pass };

        let fd = new FormData();
        fd.append( 'info', btoa( JSON.stringify( info ) ) ); 
       
        return auth.apiReq( { path: 'adm_user/names/' + user, data: fd, method: 'POST' } );
    },
    
    disble2faUser( user )
    {
        let fd = new FormData();
        fd.append( 'use2fa', false ); 
       
        return auth.apiReq( { path: 'adm_user/user2fa/' + user, data: fd, method: 'POST' } );
    },
    
    getQRcodeLink( user )
    {
        return auth.apiReq( { path: 'adm_user/code2fa/' + user, onlyURL: true } ) + '?_=' + new Date().getTime();
    },
    
    set2fa( user, use2fa, resetCode )
    {
        let fd = new FormData();
        fd.append( 'use2fa', use2fa ); 
        fd.append( 'reset_code', resetCode ); 
       
        return auth.apiReq( { path: 'adm_user/user2fa/' + user, data: fd, method: 'POST' } );
    },
    
    getUserSettings( user )
    {
        return auth.apiReq( { path: 'adm_user/settings/' + user } );
    },
    
    setUser( info )
    {
        var fd = new FormData();
        info.name = info.name.replace( ' ', '_' );
        fd.append( 'name', info.name ); 
        if( info.rName || info.rName === '' )
            fd.append( 'realName', info.rName ); 
        if( info.org  || info.org === '' )
            fd.append( 'organization', info.org ); 
        if( info.email  || info.email === '' )
            fd.append( 'email', info.email ); 
        if( info.pass )
            fd.append( 'pass', info.pass ); 
       
        return auth.apiReq( { path: 'adm_user/names', data: fd, method: 'POST' } );
    }, 
    
    setUserOnProj( projID, user, roles )
    {
        let fd = new FormData();
        fd.append( 'proj', projID ); 
        fd.append( 'user', user ); 
        roles.forEach( ( r ) => 
        { 
            fd.append( 'role', r ); 
        } );
        return auth.apiReq( { path: 'adm_user/users', data: fd, method: 'POST' } );
    }, 
    
    getDocByHash( hash )
    {
        return auth.apiReq( { path: 'hash/file', query_prm: 'hash=' + hash } );
    },
    
    getErrorInfo( err )
    {
        return auth.apiReq( { path: 'adm_user/error/' + err } );
    },
    
    getUpdateList()
    {
        return auth.apiReq( { path: 'update' } );
    },
    
    reInitConnection()
    {
        return auth.apiReq( { path: 'reconnect' } );
    },
    
    doUpdate( pack )
    {
        var fd = new FormData();
        fd.append( 'pack', pack ); 
        
        return auth.apiReq( { path: 'update', data: fd, method: 'POST' } );
    },
    
    inactiveProj( projID )
    {
        return auth.apiReq( { path: 'adm_user/proj/inactive/' + projID, method: 'PUT' } );
    },
    
    activeProj( projID )
    {
        return auth.apiReq( { path: 'adm_user/proj/active/' + projID, method: 'PUT' } );
    },
    
    deleteProj( projID )
    {
        return auth.apiReq( { path: 'adm_user/proj/' + projID, method: 'DELETE' } );
    },
    
    getAllProj()
    {
        return auth.apiReq( { path: 'adm_user/proj' } );
    },
    
    getAllTmpl()
    {
        return auth.apiReq( { path: 'adm_user/tmpl' } );
    },
    
    getProjInfo( projID )
    {
        return auth.apiReq( { path: 'adm_user/proj/' + projID } );
    },
    
    importDataToProj( files, srcType, user, rep, dt_proj )
    {
        var fd = new FormData();
        if( files && files.length > 0 )
            fd.append( 'file', files[ 0 ].file );
        else
            fd.append( 'file', null );
        
        fd.append( 'src', srcType );
        if( user )
            fd.append( 'user', user );
        
        return auth.apiReq( { path: 'adm_user/import/' + ( rep || projObj.repository )  + '/'
                    + ( dt_proj || projObj.in_project.project_model_id ), data: fd, method: 'POST' } );
    },
    
    createProj( name, descr, files, type, tmpl, srcType, folder )
    {
        var fd = new FormData();
        if( files && files.length > 0 )
            fd.append( 'file', files[ 0 ].file );
        else
            fd.append( 'file', null );
        
        fd.append( 'descr', descr );
        fd.append( 'src', srcType );
        fd.append( 'name', name );
        fd.append( 'is_tmpl', ( type === 'tmpl' ) );
        fd.append( 'is_bkd_tmpl', ( type === 'bkd_tmpl' ) );
        if( tmpl )
            fd.append( 'tmpl', tmpl.in_project.project_model_id );
        if( folder )
            fd.append( 'folder', folder );
        
        return auth.apiReq( { path: 'adm_user', data: fd, method: 'POST' } );
    }, 
    
    setProjInfo( projID, info )
    {
        var fd = new FormData();
        Object.keys( info ).forEach( function( key ) 
        {
            var val = info[ key ];
            if( val && val !== '' )
                fd.append( key, val );
        });
        
        return auth.apiReq( { path: 'adm_user/proj/' + projID, data: fd, method: 'POST' } );
    },
    
    searchInOtherModel( rep, model, val )
    {
        let qPrm = 'pattern=';
        qPrm += val ? encodeURIComponent( '*' + val + '*' ) : '*';

        qPrm += '&domains=ID&props=urn:rdl:epm-std:breakdown_element_name';
        
        return auth.apiReq( { path: 'bkd/q_search/' + rep + '/' + model, query_prm: qPrm } );   
    },
    
    searchInOtherModelByGuid( rep, model, guid )
    {      
        return auth.apiReq( { path: 'bkd/search_path/' + rep + '/' + model + '/0', 
                query_prm: guid ? ( 'id=' + guid ) : null } )
                    .then( data =>
            {
                let id = data.tree_path_to_element[ data.tree_path_to_element.length - 1 ];
                return auth.apiReq(  { path: 'bkd/' + rep + '/' + model + '/' + id, 
                        query_prm: '&ver=&str_ind=1&count=1&asc_sort=true' } );
            });
    }
};
 

