<template>
    <v-card>
      <v-data-table :headers="heads" :items="propVals" hide-default-footer class="elevation-1 propTbl" 
                    :items-per-page=-1 fixed-header height="calc( ( 100vh - 181px )">
        <template v-slot:header.num="{ header }">
            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
        </template>
          <template v-slot:header.name="{ header }">
            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
        </template>
          <template v-slot:header.val="{ header }">
            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
        </template>
        <template v-slot:header.act="{ header }">
            <v-tooltip bottom >
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on" @click="addItem()">add</v-icon>
                </template>
                <span>{{ $t( 'message.create_prod_add' ) }}</span>
            </v-tooltip>
<!--            <v-tooltip bottom v-if="prod.view_id">
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on" @click="editItem()">edit</v-icon>
                </template>
                <span>{{ $t( 'message.create_prod_edit' ) }}</span>
            </v-tooltip>-->
            <v-tooltip bottom v-if="prod.view_id">
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on" @click="delItem()">delete</v-icon>
                </template>
                <span>{{ $t( 'message.create_prod_del' ) }}</span>
            </v-tooltip>
        </template>
        <template v-slot:item="{ item }">
            <tr>
                  <td>{{ item.num }}</td>
                  <td>{{ propName( item.name ) }}</td>
                  <td colspan="2">{{item.val}}</td>
            </tr>
        </template>
      </v-data-table>
    </v-card>
</template>

<script>
import{ conf } from '@/utils/confTruePLM';

export default {
  name: 'ProdInfo',
  props: {
      prod: null
  },
  data () {
    return {
        heads: [
            { text: 'message.org_num_col', value: 'num', width: '10%' }, 
            { text: 'message.org_name_col', value: 'name' }, 
            { text: 'message.org_value_col', value: 'val' },
            { text: 'message.org_act_col', value: 'act', sortable: false, width: '15%' }
        ],
        globalState: this.$globalStore.state,
        propVals : [],
        props: [ 'name', 'part_type', 'description', 'created', 'created_by', 'ver', 'domain', 'stage' ]
    };
  },
  watch: {
      prod() 
      {
            this.updateInfo();
      }
  },
  methods: 
  {
      propName( name )
      {
          let rez = null;
          if( this.$te( "create_prod_" + name ) )
              rez = this.$t( "create_prod_" + name );
          else
              rez = this.$utils.transName( name );
          
          return rez;
      },
      
      addItem()
      {
          this.$emit( 'addProd' );
      },
      delItem()
      {
          this.$emit( 'delProd' );
      },
      updateInfo()
      {
          this.propVals = [];
          
          if( !this.prod )
              return;
          
          let ind = 0;
          this.props.forEach( ( prop ) =>
          {
              let val = this.prod[ prop ];
              if( prop === 'part_type' && val )
              {
                  let parts = val.split( ',' );
                  val = '';
                  parts.forEach( ( v ) => 
                  {
                      if( val !== '' )
                          val += ', ';
                      val += this.$utils.clearType( v );
                  });
              }
              else if( ( prop === 'domain' || prop === 'stage' ) && val )
                  val = this.$utils.clearType( val );
              
              this.propVals.push( { num: ind++, name: prop, val: val } );                                        
          });       
      }
  },
  mounted: function() 
  { 
      this.updateInfo();
  },   
  beforeDestroy: function()
  {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .propTbl td
    {
        height: 30px;
    }        
</style>
