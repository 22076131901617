<template>
    
            <v-dialog v-model="visFl" persistent max-width="1000">
                <v-toolbar flat color="white">
                    <v-toolbar-title>{{ $t( 'message.baseline_list_title' ) }}</v-toolbar-title>
                </v-toolbar>
               <v-card>
                <v-card-text>
                    <v-data-table dense :headers="headers" :items="bslData" hide-default-footer class="elevation-1 propTbl" 
                                  :items-per-page=-1 fixed-header>
                        <template v-slot:header.name="{ header }">
                            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
                        </template>
                        <template v-slot:header.act="{ header }">
                            <span></span>
                        </template>
                        <template v-slot:item="{ item }">
                            <tr>
                              <td>{{ item.id }}</td>
                              <td>{{ $utils.getLocalDateFromUTC( item.creation_date ) }}</td>
                              <td>{{ item.created_by_user.user_name }}</td>
                              <td>{{ item.description }}</td>
                              <td>{{ item.root_bkdn_elem.name }} (ver.{{item.root_bkdn_elem.version_id}})</td>
                              <td>
                                  <span style="white-space: nowrap">
                                      <v-tooltip bottom v-if="( globalState.curUserRole === 'urn:rdl:epm-std:project_manager' 
                                                                || globalState.curUserRole === 'urn:rdl:epm-std:project_admin' )">
                                            <template v-slot:activator="{ on }">
                                                <v-icon v-on="on" @click="deleteBaseline( item )">delete_forever</v-icon>
                                            </template>
                                            <span>
                                                {{$t( 'message.bsl_delete' )}}
                                            </span>
                                      </v-tooltip>
                                      <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-icon v-on="on" @click="showBaseline( item )">visibility</v-icon>
                                            </template>
                                            <span>
                                                {{$t( 'message.bsl_show' )}}
                                            </span>
                                      </v-tooltip>
                                      <v-tooltip bottom v-if="( globalState.curUserRole === 'urn:rdl:epm-std:project_manager' 
                                                                || globalState.curUserRole === 'urn:rdl:epm-std:project_admin' )">
                                            <template v-slot:activator="{ on }">
                                                <v-icon v-on="on" @click="exportBaseline( item )">save</v-icon>
                                            </template>
                                            <span>
                                                {{$t( 'message.bsl_export' )}}
                                            </span>
                                      </v-tooltip>
                                      <v-tooltip bottom v-if="globalState.isDDIProject && ( globalState.curUserRole === 'urn:rdl:epm-std:project_manager' 
                                                                || globalState.curUserRole === 'urn:rdl:epm-std:project_admin' )">
                                            <template v-slot:activator="{ on }">
                                                <v-icon v-on="on" @click="exportBaselineDDI( item )">save_as</v-icon>
                                            </template>
                                            <span>
                                                {{$t( 'message.bsl_export_ddi' )}}
                                            </span>
                                      </v-tooltip>
                                      <v-tooltip bottom v-if="( globalState.curUserRole === 'urn:rdl:epm-std:project_manager' 
                                                                || globalState.curUserRole === 'urn:rdl:epm-std:project_admin' )">
                                            <template v-slot:activator="{ on }">
                                                <v-icon v-on="on" @click="addIssue( item )">task</v-icon>
                                            </template>
                                            <span>
                                                {{$t( 'message.bsl_add_issue' )}}
                                            </span>
                                      </v-tooltip>
                                  </span>
                              </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click.native="close()">{{ $t( 'message.close_btn' ) }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>   
    
</template>

<script>
    
import Vue from 'vue'
import{ conf } from '../../utils/confTruePLM';

export default {
  name: 'baselineList',
  data () 
  {
    return {
        globalState: this.$globalStore.state,
        visFl: false,
        headers: [
            { text: 'message.bsl_id_col', value: 'name' },
            { text: 'message.bsl_date_col', value: 'name' },
            { text: 'message.bsl_user_col', value: 'name' },
            { text: 'message.bsl_descr_col', value: 'name' },
            { text: 'message.bsl_root_col', value: 'name' },
            { text: 'message.bsl_act_col', value: 'act', sortable: false }
        ],
        bslData : [],
        resolve: null,
        reject: null
    };
  },
  computed: {
  },
  methods: {
      exportBaseline( bsl )
      {
          let self = this;
          self.$modalDlg.sysDlgs.progressDlg.open();
          conf.exportBaselineToFile( bsl )
                  .then( ( flInfo ) =>
          {
              let url = conf.getFileLink( flInfo.title, flInfo.source );
              self.$modalDlg.sysDlgs.progressDlg.close();
              window.location.href = encodeURI( url );          
          })
                  .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              this.$eventBus.$emit( 'queryError', err );
          });
      },
      exportBaselineDDI( bsl )
      {
          let self = this;
          self.$modalDlg.sysDlgs.progressDlg.open();
          conf.exportProjToDDI( bsl )
                  .then( ( flInfo ) =>
          {
              let url = conf.getFileLink( flInfo.title, flInfo.source );
              self.$modalDlg.sysDlgs.progressDlg.close();
              window.location.href = encodeURI( url );          
          })
                  .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              this.$eventBus.$emit( 'queryError', err );
          });
      },
      addIssue( bsl )
      {
            this.$eventBus.$emit( 'procIssueRowMenuCmd', { cmd: 'addIssue', data: { domain: bsl.id } } );
      },
      showBaseline( bsl )
      {
          this.visFl = false;
          this.resolve( bsl );
      },
      deleteBaseline( bsl )
      {
          var self = this;
          this.$modalDlg.sysDlgs.confirmDlg.open( 'bsl_remove_title', 'bsl_remove_msg' )
                  .then( () =>
          {
              return conf.deleteBaseline( bsl );
          }, () => {} )
                  .then( () =>
          {
              return conf.getBaselineList()
          })
                  .then( ( data ) => 
          {
              self.bslData = data; 
          })
                  .catch( ( err ) =>
          {
              this.$eventBus.$emit( 'queryError', err );
          });
      },
      open()
      {
          var self = this;
          self.bslData = [];
          self.visFl = true;
          conf.getBaselineList()
                  .then( ( data ) => 
          {
              //console.log( data );
              for( let i = 0; data && i < data.length; i++ )
                  self.bslData.push( data[ i ] );              
          });
          
          return new Promise( ( resolve, reject ) => 
          {
              self.resolve = resolve;
              self.reject = reject;
          });          
      },
      close()
      {
          this.visFl = false;
          this.resolve();
      }
    }
}
</script>

<style scoped>
    .propTbl td
    {
        height: 30px;
    }        
</style>



