<template>
    <v-dialog :value="visFl" max-width="360" persistent>
      <v-card>
        <v-card-title>
              <v-row justify="center" align="center">
                  <v-container>
                      <v-progress-linear indeterminate color="blue"></v-progress-linear>                               
                  </v-container>
              </v-row>
        </v-card-title>
      </v-card>
    </v-dialog>
</template>

<script>
    
export default {
  name: 'progress-view',
  data () 
  {
    return {
        visFl: false
    };
  },
   methods: {
      close()
      {
          this.visFl = false;
      },
      open()
      {
          this.visFl = true;
      }
  },
  mounted: function() 
  { 
  },
  beforeDestroy: function()
  {
  }
}
</script>



