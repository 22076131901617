<template>

  <v-navigation-drawer app clipped fixed v-model="drawer.visNav" id="dr">
     <v-list>
         <v-list-group prepend-icon="dashboard" :value="mini1" key="2" no-action>
            <v-list-item slot="activator" style="padding-left: 10px;">
              <v-list-item-title class="big">{{ $t( 'message.adm_projects_ttl' ) }}</v-list-item-title>
            </v-list-item>
            <v-list-item v-for="( proj, index ) in projList" :key="proj.in_project.guid" :value="mini1" @click="editProj( proj )" 
                         v-bind:class="{ selected: ( selProj === proj ) }" >
                <v-list-item-content>
                    <v-list-item-title>{{proj.in_project.name}}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
         </v-list-group>
      <v-list-group prepend-icon="extension" :value="mini2" key="3" no-action>
        <v-list-item slot="activator" style="padding-left: 10px;">
          <v-list-item-title class="big">{{ $t( 'message.b_templ_ttl' ) }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-for="( proj, index ) in bTemplList" :key="proj.in_project.guid" sub-group no-action :value="mini2" @click="editProj( proj )"
                      v-bind:class="{ selected: ( selProj === proj ) }" >
            <v-list-item-title>{{proj.in_project.name}}</v-list-item-title>
        </v-list-item>
      </v-list-group>
      <v-list-group prepend-icon="extension" :value="mini3" key="4" no-action>
        <v-list-item slot="activator" style="padding-left: 10px;">
          <v-list-item-title class="big">{{ $t( 'message.templ_ttl' ) }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-for="( proj, index ) in templList" :key="proj.in_project.guid" sub-group no-action :value="mini3" @click="editProj( proj )"
                      v-bind:class="{ selected: ( selProj === proj ) }" >
            <v-list-item-title>{{proj.in_project.name}}</v-list-item-title>
        </v-list-item>
      </v-list-group>
         
<!--      <v-list-group prepend-icon="settings_applications" :value="mini4" key="1" no-action>
          <template v-slot:activator>
              <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="big">{{ $t( 'message.admin_ttl' ) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
          </template>-->
        
<!--        <v-list-item key="11" @click="newProj()">
            <v-list-item-action>
                <v-icon>work</v-icon>
            </v-list-item-action>
            <v-list-item-content>
                <v-list-item-title class="big">{{ $t( 'message.admin_proj_ttl' ) }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>-->
        <v-list-item key="12" @click="editUsers()" v-bind:class="{ selected: ( selProj === 'EDIT_USERS' ) }">
            <v-list-item-action>
                <v-icon>account_box</v-icon>
            </v-list-item-action>
            <v-list-item-content>
                <v-list-item-title class="big">{{ $t( 'message.admin_users_ttl' ) }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-list-item key="13" @click="editProjList()" v-bind:class="{ selected: ( selProj === 'EDIT_PROJ_LIST' ) }">
            <v-list-item-action>
                <v-icon>folder</v-icon>
            </v-list-item-action>
            <v-list-item-content>
                <v-list-item-title class="big">{{ $t( 'message.admin_proj_list_ttl' ) }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-list-item v-show="globalState.initInfo && globalState.initInfo.sync" key="14" @click="editSyncList()" v-bind:class="{ selected: ( selProj === 'EDIT_SYNC_LIST' ) }">
            <v-list-item-action>
                <v-icon>sync</v-icon>
            </v-list-item-action>
            <v-list-item-content>
                <v-list-item-title class="big">{{ $t( 'message.admin_sync_list_ttl' ) }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
      <!--</v-list-group>-->
    </v-list>

    <v-list dense>
        <v-list-group prepend-icon="settings" :value="mini4" key="6" no-action>
          <v-list-item slot="activator" style="padding-left: 10px;">
              <v-list-item-title>{{ $t( 'message.adm_utils_ttl' ) }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click.stop="reConnect">
            <v-list-item-action>
              <v-icon>wifi</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ $t( 'message.admin_reconnect_ttl' ) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click.stop="openApiTokens">
            <v-list-item-action>
              <v-icon>settings_remote</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ $t( 'message.admin_tokens_ttl' ) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click.stop="showError">
            <v-list-item-action>
              <v-icon>info</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ $t( 'message.error_info_ttl' ) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click.stop="showProj">
            <v-list-item-action>
              <v-icon>info</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ $t( 'message.proj_url_ttl' ) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click.stop="showUpdate" v-show="globalState.initInfo && globalState.initInfo.updates">
            <v-list-item-action>
              <v-icon>system_update</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ $t( 'message.set_update_ttl' ) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
      </v-list-group>
      <v-list-item @click.stop="showInfo">
        <v-list-item-action>
          <v-icon>question_answer</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title >{{ $t( 'message.info_ttl' ) }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click.stop="logOut" id="domElem">
        <v-list-item-action>
            <v-icon>all_out</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title >{{ $t( 'message.logout_ttl' ) }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
    
</template>

<script>

import router from '@/router';

import { auth } from '@/utils/auth';
import{ conf } from '@/utils/confTruePLM';

export default {
  name: 'admin-list',
  data () 
  {
    return {
        globalState: this.$globalStore.state,
        projList: null,
        bTemplList: null,
        templList: null,
        drawer: this.$modalDlg.state,
        mini1: false,
        mini2: false,
        mini3: false,
        mini4: false,
        selProj: null
    };
  },
  methods: {
      reConnect()
      {
          let self = this;
          
          conf.reInitConnection()
                  .then( num =>
          {
              if( num > 0 )
                  self.$modalDlg.sysDlgs.confirmDlg.open( 'reinit_rez_title', 'reinit_ok_msg', null, num, true );
              else
                  self.$modalDlg.sysDlgs.confirmDlg.open( 'reinit_rez_title', 'reinit_fail_msg', null, null, true, 'warning', '#fb8c00' );
          })
                  .catch( err =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      openApiTokens()
      {
          this.$modalDlg.sysDlgs.tokenListDlg.open( true );
      },
      editUsers()
      {
           this.selProj = 'EDIT_USERS';
           this.$eventBus.$emit( 'editUsers' );                    
      },
      editProjList()
      {
           this.selProj = 'EDIT_PROJ_LIST';
           this.$eventBus.$emit( 'editProjList' );                    
      },
      editSyncList()
      {
           this.selProj = 'EDIT_SYNC_LIST';
           this.$eventBus.$emit( 'editSyncList' );                    
      },
//      newProj()
//      {
//           this.selProj = '';
//          var self = this;
//          self.$modalDlg.sysDlgs.createProjDlg.open( true )
//                  .then( ( proj ) =>
//          {
//              if( proj )
//                  self.createProj( proj );  
//          });      
//      },
      showUpdate()
      {
          let self = this;
          conf.getUpdateList()
                  .then( list => 
          {
              let vals = [];
              list.forEach( v => 
              {
                  vals.push( { txt: v, val: v } );
              });
              return self.$modalDlg.sysDlgs.confirmOneValDlg.open( 'conf_up_list_title', 'conf_up_list_msg',
                                null, null, null, 'select', vals );
          } )
                  .then( pack =>
          {
              self.$modalDlg.sysDlgs.progressDlg.open();
              return conf.doUpdate( pack );
          }, () => { throw new Error( "canceled" ); } )
                  .then( rez => 
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              if( rez )
                  self.$modalDlg.sysDlgs.confirmDlg.openOkOnly( 'conf_up_rez_title', 'conf_up_done_msg' );
              else
                  self.$modalDlg.sysDlgs.confirmDlg.openOkOnly( 'conf_up_rez_title', 'conf_up_not_msg' );
          })
                  .catch( err =>
          {
              if( err.message !== 'canceled')
              {
                  self.$modalDlg.sysDlgs.progressDlg.close();
                  self.$eventBus.$emit( 'queryError', err );
              }
          });
      },
      showProj()
      {
          let self = this;
          let projID = null;
          this.$modalDlg.sysDlgs.confirmOneValDlg.open( 'conf_proj_url_title', 'conf_proj_url_msg' )
                  .then( url =>
          {
              self.$modalDlg.sysDlgs.progressDlg.open();
              let parts = url.split( '/' );
              if( parts.length === 1 )
                  projID = parts[ 0 ];
              else
              {
                    for( let i = 0; i < parts.length; i++ )
                    {
                        if( parts[ i ] === 'home' )
                        {
                            projID = parts[ i + 1 ];
                            break;
                        }
                    }
              }
              return conf.getProjInfo( projID );
          }, () => {} )
                  .then( info => 
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              console.log( info );
              if( info )
                  this.$modalDlg.sysDlgs.confirmDlg.open( 'conf_proj_url_title', 'empty_msg', null, 
                '<b>Name:</b> ' + info.name + '<br/><b>Model:</b> ' + info.project_model_id 
                        + '<br/><b>Description:</b> ' + info.project_descr, true );
          })
                  .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.$eventBus.$emit( 'queryError', err );
          });
          
      },
      showError()
      {
          let self = this;
          this.$modalDlg.sysDlgs.confirmOneValDlg.open( 'conf_err_info_title', 'conf_err_info_msg' )
                  .then( ( num ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.open();
              return conf.getErrorInfo( num );
          }, () => {} )
                  .then( ( info ) => 
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              if( info )
                  this.$modalDlg.sysDlgs.confirmDlg.open( 'conf_err_info_title', 'empty_msg', null, info.result, true );
          })
                  .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.$eventBus.$emit( 'queryError', err );
          });
          
      },
      editProj( proj )
      {
          this.selProj = proj;
          let role = 'urn:rdl:epm-std:trueplm_system';
          this.$globalStore.setCurUserRole( role );
          conf.setProj( proj, role );
          this.$eventBus.$emit( 'editProj', proj );
      },
//      createProj( info )
//      {
//          var self = this;
//          self.$modalDlg.sysDlgs.progressDlg.open();
//          conf.createProj( info.name, info.descr, info.files, info.type, info.tmpl )
//                  .then( ( rez ) =>
//          {
//              
//              self.updateProjList();  
//              self.$modalDlg.sysDlgs.progressDlg.close();
//              if( rez && !rez.error )
//                  this.$modalDlg.sysDlgs.confirmDlg.open( 'create_proj_done_title', 'create_proj_done_msg', null, info.name, true )
//          }, ( err )=>
//          { 
//              self.$modalDlg.sysDlgs.progressDlg.close();
//              if( err.message === 'Rstat Error: 100002' )
//                  err.message = 'Wrong proj name';
//              self.$eventBus.$emit( 'queryError', err ); 
//          } );
//      },
      updateProjList()
      {
            let self = this;
            conf.getRepositoryList()
                    .then( ( data )=>
            {
                let p = [];
                let bt = [];
                let t = [];
                if( data && data.length > 0 )
                {
                    this.$globalStore.setCurUser( data[ 0 ].user_name );
                    if( this.$globalStore.state.curUser !== 'superuser' && this.$globalStore.state.curUser !== 'system' )
                        router.push( '/home' );
                    
                    data.forEach( ( proj ) =>
                    {
                        if( proj.is_active_in_project && proj.user_registered_as.length > 0 )
                        {
                            if( proj.in_project.is_bkd_template_project )
                                bt.push( proj );
                            else if( proj.in_project.is_template_project )
                                t.push( proj );
                            else
                                p.push( proj );
                        }
                    });
                }
                if( p && p.length > 0 )
                    self.$utils.sortArray( p, ( a ) => ( a.in_project.name ) );
                if( bt && bt.length > 0 )
                    self.$utils.sortArray( bt, ( a ) => ( a.in_project.name ) );
                if( t && t.length > 0 )
                    self.$utils.sortArray( t, ( a ) => ( a.in_project.name ) );
                self.projList = p;
                self.bTemplList = bt;
                self.templList = t;
            }, 
            ( err )=>{ self.$eventBus.$emit( 'queryError', err ); } );
      },
      showInfo()
      {
          this.$modalDlg.sysDlgs.infoDlg.open();  
      },
      logOut()
      {
          this.$modalDlg.sysDlgs.logoutDlg.open( null, null, false, true );    
      }
  },
  beforeMount: function()
  {
      auth.checkAuth();
      if( !auth.getUser().authenticated )
          router.push( '/login' );
  },
  mounted: function()
  {
      var self = this;
      
      this.$eventBus.$on( 'updateProjList', function()
      {
          self.updateProjList();        
      });
      
      if( auth.getUser().authenticated )
          self.updateProjList();     
  },
  beforeDestroy: function()
  {
      this.$eventBus.$off( 'updateProjList' );
  }
}
</script>

<style scoped>
  .big 
  {
      font-weight: bold !important;
  }
  .selected
  {
      background: lightgray;    
  }
</style>


