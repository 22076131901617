<template>
    <v-list-item :key="model.name" @click.stop="selectFile">
<!--        <v-list-item-avatar>
            <img :src="imgSrc">
        </v-list-item-avatar>-->
        <v-list-item-action>
            <v-img :src="imgSrc" max-width="24"></v-img>
        </v-list-item-action>
        <v-list-item-content>
            <v-list-item-title style="height: 100%;">
                <span v-bind:class="{ select: ( globalState.selectedID === modelID ), gray: ( !node.permissions.can_i_read || model.readonly ) }">{{model.title}} <i>(rev.{{lastVer}})</i></span>       
                <v-icon color="red lighten-2" v-if="model.is_checked_out_by_me || model.is_checked_out_by_user">lock</v-icon>
                <v-icon color="red lighten-2" v-if="model.red_flags && model.red_flags.length > 0">flag</v-icon>
                <v-badge overlap color="blue lighten-3" v-if="getStickNum( model ) > 0">
                    <span slot="badge">{{ getStickNum( model ) }}</span>
                    <v-icon color="yellow darken-3" @click="procFunc( 'openNotes' )">subject</v-icon>
                </v-badge>
<!--                <v-badge overlap color="blue lighten-3">
                    <span slot="badge">{{ getLinkNum( model ) }}</span>
                    <v-icon color="green darken-2" v-if="getLinkNum( model )" @click="openLinks( model )">link</v-icon>
                </v-badge>  -->
            </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
            <v-menu bottom left v-if="!noMenu">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon>
                        <v-icon>more_vert</v-icon>
                    </v-btn>
                </template>
                <v-list dense v-if="shortForm">
                    <v-list-item key="1" @click="procFunc( 'downloadFile' )">
                        <v-list-item-title>{{$t( 'message.file_download_btn' )}}</v-list-item-title>
                    </v-list-item>
                    <v-list-item key="2" @click="procFunc( 'openFile' )">
                        <v-list-item-title>{{$t( 'message.file_open_btn' )}}</v-list-item-title>
                    </v-list-item>
                    <v-list-item key="3" @click="procFunc( 'gotoNode' )">
                        <v-list-item-title>{{$t( 'message.menu_file_goto' )}}</v-list-item-title>
                    </v-list-item>
                </v-list>
                <v-list dense v-else-if='!globalState.actVer'>
                    <v-list-item key="4" @click="procFunc( 'checkOutFile' )" :disabled="model.is_checked_out_by_someone_else" v-if="model.permissions.can_i_write">
                        <v-list-item-title>{{$t( 'message.menu_file_checkout' )}}</v-list-item-title>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" @click.stop="openHelp( 'Checkout+file' )" style="font-size: 18px; padding-left: 5px; pointer-events: auto;">help</v-icon>
                            </template>
                            <span>
                                {{$t( 'message.open_help' )}}
                            </span>
                       </v-tooltip>
                    </v-list-item>
                    <v-list-item key="5" @click="procFunc( 'cancelCheckOutFile' )" :disabled="!model.is_checked_out_by_me" v-if="model.permissions.can_i_write">
                        <v-list-item-title>{{$t( 'message.menu_file_checkout_cancel' )}}</v-list-item-title>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" @click.stop="openHelp( 'Undo+checkout' )" style="font-size: 18px; padding-left: 5px; pointer-events: auto;">help</v-icon>
                            </template>
                            <span>
                                {{$t( 'message.open_help' )}}
                            </span>
                       </v-tooltip>
                    </v-list-item>
                    <v-list-item key="6" @click="procFunc( 'checkInFile' )" :disabled="!model.is_checked_out_by_me" v-if="model.permissions.can_i_write">
                        <v-list-item-title>{{$t( 'message.menu_file_checkin' )}}</v-list-item-title>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" @click.stop="openHelp( 'Checkin+file' )" style="font-size: 18px; padding-left: 5px; pointer-events: auto;">help</v-icon>
                            </template>
                            <span>
                                {{$t( 'message.open_help' )}}
                            </span>
                       </v-tooltip>
                    </v-list-item>
                    <v-list-item key="7" @click="procFunc( 'setFileReadOnly' )" v-if="model.permissions.can_i_write && !model.readonly">
                        <v-list-item-title>{{$t( 'message.menu_file_readonly_set' )}}</v-list-item-title>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon slot="activator" @click.stop="openHelp( 'Set-file-read-only-/-Clear-file-read-only' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                            </template>
                            <span>
                                {{$t( 'message.open_help' )}}
                            </span>
                       </v-tooltip>
                    </v-list-item>
                    <v-list-item key="8" @click="procFunc( 'clearFileReadOnly' )" v-if="model.permissions.can_i_write && model.readonly">
                        <v-list-item-title>{{$t( 'message.menu_file_readonly_clear' )}}</v-list-item-title>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                            <v-icon slot="activator" @click.stop="openHelp( 'Set-file-read-only-/-Clear-file-read-only' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                            </template>
                            <span>
                                {{$t( 'message.open_help' )}}
                            </span>
                       </v-tooltip>
                    </v-list-item>
                    <v-list-item key="9" @click="procFunc( 'downloadFile' )" v-if="model.permissions.can_i_read">
                        <v-list-item-title>{{$t( 'message.file_download_btn' )}}</v-list-item-title>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" @click.stop="openHelp( 'Download' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                            </template>
                            <span>
                                {{$t( 'message.open_help' )}}
                            </span>
                       </v-tooltip>
                    </v-list-item>
                    <v-list-item key="10" @click="procFunc( 'openFile' )" v-if="model.permissions.can_i_read">
                        <v-list-item-title>{{$t( 'message.file_open_btn' )}}</v-list-item-title>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon slot="activator" @click.stop="openHelp( 'Open' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                            </template>
                            <span>
                                {{$t( 'message.open_help' )}}
                            </span>
                       </v-tooltip>
                    </v-list-item>
                    <v-list-item key="11" @click="procFunc( 'convertFile' )" v-if="node.permissions.can_i_write 
                                 && globalState.initInfo.vcolab && isConvType">
                        <v-list-item-title>{{$t( 'message.file_convert_btn' )}}</v-list-item-title>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon slot="activator" @click.stop="openHelp( 'VCollab-Visualization' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                            </template>
                            <span>
                                {{$t( 'message.open_help' )}}
                            </span>
                       </v-tooltip>
                    </v-list-item>
                    <v-list-item key="12" @click="showHistory" v-if="node.permissions.can_i_read">
                        <v-list-item-title>{{$t( 'message.file_hist_btn' )}}</v-list-item-title>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" @click.stop="openHelp( 'View+history' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                            </template>
                            <span>
                                {{$t( 'message.open_help' )}}
                            </span>
                       </v-tooltip>
                    </v-list-item>
                    <v-list-item key="13" @click="procFunc( 'deleteFile' )" v-if="model.permissions.can_i_delete">
                        <v-list-item-title>{{$t( 'message.file_delete_btn' )}}</v-list-item-title>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" @click.stop="openHelp( 'Delete' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                            </template>
                            <span>
                                {{$t( 'message.open_help' )}}
                            </span>
                       </v-tooltip>
                    </v-list-item>
                    <v-list-item key="14" @click="procFunc( 'copyFile' )" v-if="model.permissions.can_i_write">
                        <v-list-item-title>{{$t( 'message.menu_file_copy' )}}</v-list-item-title>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" @click.stop="openHelp( 'copy+file' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                            </template>
                            <span>
                                {{$t( 'message.open_help' )}}
                            </span>
                       </v-tooltip>
                    </v-list-item>
                    <v-list-item key="15" @click="procFunc( 'cutFile' )" v-if="model.permissions.can_i_write">
                        <v-list-item-title>{{$t( 'message.menu_file_cut' )}}</v-list-item-title>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" @click.stop="openHelp( 'cut+file' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                            </template>
                            <span>
                                {{$t( 'message.open_help' )}}
                            </span>
                       </v-tooltip>
                    </v-list-item>
                    <v-list-item key="16" @click="procFunc( 'linkFile1' )" v-if="model.permissions.can_i_write"
                                 :disabled="( !globalState.fileForLink || ( globalState.fileForLink.instance_id === model.instance_id ) )">
                        <v-list-item-title>{{$t( 'message.menu_file_link1' )}}</v-list-item-title>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" @click.stop="openHelp( 'Paste+file+as+affected' )" style="font-size: 18px; padding-left: 5px; pointer-events: auto;">help</v-icon>
                            </template>
                            <span>
                                {{$t( 'message.open_help' )}}
                            </span>
                       </v-tooltip>
                    </v-list-item>
                    <v-list-item key="17" @click="procFunc( 'linkFile2' )" v-if="model.permissions.can_i_write"
                                 :disabled="( !globalState.fileForLink || ( globalState.fileForLink.instance_id === model.instance_id ) )">
                        <v-list-item-title>{{$t( 'message.menu_file_link2' )}}</v-list-item-title>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" @click.stop="openHelp( 'Paste+file+as+affecting' )" style="font-size: 18px; padding-left: 5px; pointer-events: auto;">help</v-icon>
                            </template>
                            <span>
                                {{$t( 'message.open_help' )}}
                            </span>
                       </v-tooltip>
                    </v-list-item>
                    <v-list-item key="24" @click.stop="procFunc( 'addIssue' )">
                    <v-list-item-title>{{$t( 'message.menu_add_issue' )}}</v-list-item-title>
                        <!-- <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" @click.stop="openHelp( 'Personal-messages-(Task-or-Info-notifications)' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                            </template>
                            <span>
                                {{$t( 'message.open_help' )}}
                            </span>
                        </v-tooltip> -->
                    </v-list-item>
                    <v-list-item key="18" @click.stop="procFunc( 'sendFileMsg' )" v-if="globalState.userMsgReady">
                    <v-list-item-title>{{$t( 'message.menu_send_msg' )}}</v-list-item-title>
                   <v-tooltip bottom>
                         <template v-slot:activator="{ on }">
                            <v-icon v-on="on" @click.stop="openHelp( 'Personal-messages-(Task-or-Info-notifications)' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                         </template>
                         <span>
                             {{$t( 'message.open_help' )}}
                         </span>
                       </v-tooltip>
                    </v-list-item>
                    <v-list-item key="19" @click="procFunc( 'openNotes' )">
                        <v-list-item-title>{{$t( 'message.menu_file_notes' )}}</v-list-item-title>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" @click.stop="openHelp( 'Sticky+notes' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                            </template>
                            <span>
                                {{$t( 'message.open_help' )}}
                            </span>
                       </v-tooltip>
                    </v-list-item>
                    <v-list-item key="20" @click="procFunc( 'openLinks' )">
                        <v-list-item-title>{{$t( 'message.menu_file_links' )}}</v-list-item-title>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" @click.stop="openHelp( 'Dependencies' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                            </template>
                            <span>
                                {{$t( 'message.open_help' )}}
                            </span>
                       </v-tooltip>
                    </v-list-item>
                </v-list>
                <v-list dense v-else-if="model.permissions.can_i_read">
                    <v-list-item key="21" @click="procFunc( 'downloadFile' )">
                        <v-list-item-title>{{$t( 'message.file_download_btn' )}}</v-list-item-title>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" @click.stop="openHelp( 'Download' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                            </template>
                            <span>
                                {{$t( 'message.open_help' )}}
                            </span>
                       </v-tooltip>
                    </v-list-item>
                    <v-list-item key="22" @click="procFunc( 'openFile' )">
                        <v-list-item-title>{{$t( 'message.file_open_btn' )}}</v-list-item-title>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon slot="activator" @click.stop="openHelp( 'Open' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                            </template>
                            <span>
                                {{$t( 'message.open_help' )}}
                            </span>
                       </v-tooltip>
                    </v-list-item>
                    <v-list-item key="23" @click="procFunc( 'openLinks' )">
                        <v-list-item-title>{{$t( 'message.menu_file_links' )}}</v-list-item-title>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" @click.stop="openHelp( 'Paste+element' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                            </template>
                            <span>
                                {{$t( 'message.open_help' )}}
                            </span>
                       </v-tooltip>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-list-item-action>
    </v-list-item>
    
</template>

<script>

import{ conf } from '../utils/confTruePLM'

export default {
  name: 'NodeListRow',
  props: {
      model: {},
      node: { permissions: {} },
      shortForm: false,
      noMenu: false
  },
  data () {
    return {
         globalState: this.$globalStore.state
    };
  },
  computed: {
    isConvType: function()
    {
        return ( this.model.data_type && 
                ( this.model.data_type.toLowerCase() === '.stp' || this.model.data_type.toLowerCase() === '.step' ) );
    },
    imgSrc: function()
    { 
        try
        {
            var ext = this.model.data_type.split( '.' );
            ext = ext[ 1 ].toLowerCase();
            if( ext === 'docx' )
                ext = 'doc';
            else if( ext === 'step' )
                ext = 'stp';
            else if( ext === 'pptx' )
                ext = 'ppt';
            return require( '../assets/file_icons/' + ext + '.png' );             
        }
        catch( err )
        {
//            console.log( err );
            return require( '../assets/file_icons/_blank.png' );             
        }
    },
    modelID: function()
    {
        return this.model.instance_id;
    },
    lastVer: function()
    {
        var verParts = this.model.versions[ this.model.versions.length - 1 ].version_id.split( '.' );
        return verParts[ 0 ] + ' ver.' + verParts[ 1 ];
    }
  },
  methods: 
  {
      openHelp( link )
      {
          this.$utils.openHelp( link );
      },
      showHistory()
      {
          this.$modalDlg.sysDlgs.fileHistoryDlg.open( this.model, this.node )
                  .then( () => {}, () => {} );
      },
      selectFile()
      {
          this.$eventBus.$emit( 'updateFile', { file: this.model, node: this.node } );  
          this.$globalStore.setSelectedID( this.model.instance_id );          
          this.$eventBus.$emit( 'setURLData', { file: this.model.instance_id } );  
      },
      getLinkNum( file )
      {
          var num = 0;
          if( file.affects_documents )
              num += file.affects_documents.length;
          if( file.affected_by_documents )
              num += file.affected_by_documents.length;
      
          return num;
      },
      getStickNum( file )
      {
          var ver = file.versions[ file.versions.length - 1 ];
          if( !ver.sticky_notes )
              return 0;
          else
              return ver.sticky_notes.length;
      },
      procFunc: function( cmd )
      {
          this.showMenu = false;      
          this.$eventBus.$emit( 'procRowMenuCmd', [ cmd , this.model, this.node ] );       
      },  
      procModel()
      {
          this.$utils.sortArray( this.model.versions, ( a ) => ( a.version_id ) );
          var lastVer = this.model.versions[ this.model.versions.length - 1 ];
          this.model.last_ver = lastVer.version_id + ' [' + lastVer.date_submitted + ']';
          this.model.last_ver_user = lastVer.submitted_by_user;
          this.model.last_ver_id = lastVer.version_id;
          this.model.last_ver_date = lastVer.date_submitted;
          this.model.last_ver_phase = lastVer.phase;
//          console.log( this.model );
      }
  },
  components: {
  },
  watch: {
      model() 
      {
          this.procModel();
      }
  },
  mounted: function() 
  { 
      this.procModel();
  },   
  beforeDestroy: function()
  {
  }
}
</script>

<style scoped>
    .select
    {
        font-weight: 700;
    }
    .back
    {
        font-weight: 600;
        font-size: 20px;
    }
    .gray
    {
        color: gray;
    }
    .selectCl
    {
        font-size: 13px;
    }
</style>
