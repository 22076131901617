<template>
            <v-dialog v-model="visFl" persistent max-width="550">
               <v-card>
                <v-card-title class="headline">
                  {{ title }}
                </v-card-title>
                <v-card-text>
                    <span style="font-size: 16px; font-weight: 500;">{{ msg }}</span>
                    <v-form ref="form" v-if="!isMember">
                        <v-checkbox :label="check_msg" v-model="forAll" id="deleteElemAll"></v-checkbox>
                    </v-form>    
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                    <v-btn id="deleteElemOk" color="green darken-1" text @click.native="doAct()">{{ $t( 'message.ok_btn' ) }}</v-btn>
                    <v-btn id="deleteElemClose" color="green darken-1" text @click.native="close()">{{ $t( 'message.cancel_btn' ) }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>   
</template>

<script>
    
import Vue from 'vue'
import{ conf } from '../../utils/confTruePLM';

export default {
  name: 'DeleteDoc',
  data () 
  {
    return {
        globalState: this.$globalStore.state,
        visFl: false,
        forAll: true,
        isMember: false,
        title: null,
        msg: null,
        check_msg: null,
        resolve: null,
        reject: null,
    };
  },
  computed: {
  },
  methods: {
      open( isMember, isNode )
      {
          this.title = this.$t( 'message.del_doc_title' );
          this.msg = this.$t( 'message.del_doc_msg' );
          this.check_msg = this.$t( 'message.del_doc_all' );
          if( isNode )
          {
              this.title = this.$t( 'message.del_doc_node_title' );
              this.msg = this.$t( 'message.del_doc_node_msg' );
              this.check_msg = this.$t( 'message.del_doc_node_all' );
          }
          
          let self = this;
          self.isMember = isMember;
          self.forAll = !isMember;
          self.visFl = true;
            
          return new Promise( ( resolve, reject ) => 
          {
              self.resolve = resolve;
              self.reject = reject;
          });          
      },
      close()
      {
          this.visFl = false;
          this.reject();
      },
      doAct()
      {
          this.visFl = false;
          this.resolve( this.forAll );
      }
    }
}
</script>

<style scoped>
    .compact
    {
        height: 25px;
        padding: 0;
        margin: 0;
    }        
</style>



