<template>
    <v-app>
         <progress-view ref="progressDlg"></progress-view>
         <v-main>
            <v-container fluid fill-height >
                <v-layout fill-height>
                    <v-flex lg12 md12>
                        <v-card>
                            <v-card-title >
                              <span class="headline" style="padding-left: 7px;">{{$t( 'message.chain_ttl' )}}</span>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" @click="downloadAll()" 
                                            style="padding-left: 15px">save_alt</v-icon>
                                </template>
                                <span>{{ $t( 'message.chain_down_all_btn' ) }}</span>
                            </v-tooltip>
                            </v-card-title>
                            <v-card-text v-if="keyName" class="d-flex align-center" style="max-width: 900px;">
                            </v-card-text>
                            <v-container fill-height fluid>
                                <v-layout fill-height>
                                    <div style="width: 100%">
                                        <v-data-table :headers="headers" :items="vals" hide-default-footer
                                                      :loading="loading" fixed-header :sort-desc="true" sort-by="num"
                                                      item-key="num" show-expand :single-expand="false" :expanded.sync="expanded">
                                            <template v-slot:header.num="{ header }">
                                                {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
                                            </template>
                                            <template v-slot:header.hash="{ header }">
                                                {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
                                            </template>
                                            <template v-slot:item.hash="{ item }">
                                                {{item.hash}}
                                                <v-tooltip bottom v-if="isSecure">
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon v-on="on" @click="copy( item )" 
                                                                style="padding-left: 15px">content_copy</v-icon>
                                                    </template>
                                                    <span>{{ $t( 'message.chain_copy_btn' ) }}</span>
                                                </v-tooltip>
                                            </template>
                                            <template v-slot:expanded-item="{ headers, item }">
                                                <td :colspan="headers.length">
                                                    <i>Document info:</i> 
                                                    <b>Title:</b> {{item.doc.title}} 
                                                    <b>Version:</b> {{item.doc.versions[ 0 ].version_id}} 
                                                    <b>Date:</b> {{ item.doc.dateInfo }} 
                                                    <b>User:</b> {{ item.doc.versions[ 0 ].submitted_by_user }}
                                                    <b>Hash:</b> {{ item.doc.hash }}
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on }">
                                                            <v-icon v-on="on" @click="download( item )" 
                                                                    style="padding-left: 15px">save_alt</v-icon>
                                                        </template>
                                                        <span>{{ $t( 'message.chain_down_btn' ) }}</span>
                                                    </v-tooltip>
                                                </td>
                                           </template>
                                        </v-data-table>
                                        <div class="text-center pt-2">
                                            <!--<v-pagination v-model="page" :length="pageCount" total-visible=10></v-pagination>-->                                        
                                        </div>
                                    </div>
                                </v-layout>
                            </v-container>
                        </v-card>                        
                    </v-flex>
                </v-layout>
            </v-container>
         </v-main>
    </v-app>
</template>

<script>

import router from '@/router';
import { auth } from '@/utils/auth';
import{ conf } from '@/utils/confTruePLM';
import Progress from '../Dlg/Progress.vue'

export default {
  name: 'listGr',
  data () 
  {
    return {
        nodeID: 0,
        totalNum: 0,
        pageSize: 30,
        page: 1,
        pageCount: 1,
        expanded: [],
        headers: [ 
            { width: '15%', text: 'message.chain_num_col', value: 'num' }, 
            { text: 'message.chain_hash_col', sortable: false, value: 'hash' }, 
            { text: '', value: 'data-table-expand' }],
        loading: true,
        fromKey: null,
        toKey: null,
        fromVal: null,
        toVal: null,
        keyName: null,
        keyType: null,
        isNumDate: false,
        isEForm: false,
        vals: []
    };
  },
  methods: {
      fillInfo( prms )
      {
//          this.$refs.progressDlg.open();
          conf.setProj( { repository: prms.rep, in_project: { project_model_id: prms.model } }, prms.role );
          this.getData();
      },
      downloadAll()
      {
          conf.downloadChainFile()
                  .then( ( flInfo ) =>
          {
              let url = conf.getFileLink( flInfo.title, flInfo.source );
              window.location.href = encodeURI( url );          
          })
                  .catch( ( err ) =>
          {
              this.$eventBus.$emit( 'queryError', err );
          });
      },
      download( item )
      {
          conf.downloadFileVer( item.doc.file_name, item.doc.versions[ 0 ].instance_id )
                  .then( ( flInfo ) =>
          {
              let url = conf.getFileLink( flInfo.title, flInfo.source );
              window.location.href = encodeURI( url );          
          })
                  .catch( ( err ) =>
          {
              this.$eventBus.$emit( 'queryError', err );
          });
      },
      copy( item )
      {
          navigator.clipboard.writeText( item.hash )
                  .then( () => 
          {
                console.log( 'Async: Copying to clipboard was successful!' );
          }, ( err ) =>
          {
                console.error( 'Async: Could not copy text: ', err );
          });
      },
      blockData( item )
      {
          let self = this;
          conf.getChainBlock( item.ver )
                  .then( block =>
          {
              item.info = block;
          }, ( err ) => { self.procError( err ); } );
      },
      getData()
      {
          this.loading = true;
          let self = this;
          
          self.vals = [];
          conf.getChain()
                  .then( data => 
          {
             // console.log( data );
              let i = 1;
              data.forEach( el =>
              {
                  el.doc.hash = el.docHash;
                  el.doc.dateInfo = this.$utils.getLocalDateFromUTC( el.doc.versions[ 0 ].date_submitted );
                  self.vals.push( { num: i++, hash: el.hash, ver: el.bkdVer, doc: el.doc } );
              });

              //this.$refs.progressDlg.close();
              self.loading = false;
          }, ( err ) => { this.procError( err ); } );
      },
      procError( err )
      {
          if( !err.message && err.response )
              err.message = err.response;
          
          if( err.message === 'Session expired!' )
              router.push( '/login' );
          else if( err.message === 'noConnInfo' || err.message === 'Connection expired' )
              router.push( '/login' );
          console.log( 'E - ' + err );
      }
    },
   watch: {
      page: {
        handler() 
        {
            this.getData();
        }
      }
    },
  computed: {
      isSecure()
      {
          return window.isSecureContext;
      }
  },
  components: {
      'progress-view': Progress
  },
  beforeMount: function()
  {
  //    console.log( 'login' );
      auth.checkAuth();
      if( ! auth.getUser().authenticated )
          router.push( '/login' );
  },
  mounted: function()
  {
      var self = this;
      
      this.$eventBus.$on( 'queryError', function( err )
      {
          self.procError( err );
      });
      
      this.fillInfo( this.$route.query );
  },
  beforeDestroy: function()
  {
      this.$eventBus.$off( 'queryError' );
  }
}
</script>

<style scoped>
</style>
