<template>
</template>

<script>
    
import router from '@/router';
    
export default {
   name: 'searchStart',
   props: {
        projID: { 
          required: true
        }
   },
   data () 
   {
        return {
        };
   },
   beforeMount: function()
   {
       console.log( 'search' );
      if( this.projID )
          localStorage.setItem( 'projID', this.projID );
      
      localStorage.setItem( 'curr_path', '[]' );
      localStorage.setItem( 'search_prm', JSON.stringify( this.$route.query ) );
      
      router.push( '/home' );    
   }
}
</script>