<template>

            <v-dialog v-model="visFl" persistent max-width="290">
               <v-card>
                <v-card-title class="headline">
                  {{ $t( 'message.set_proj_info_ttl' ) }}
                </v-card-title>
                <v-card-text>
                        <v-tabs v-model="active">
                            <v-tab href="#tab-1">{{ $t( 'message.set_proj_info_general_tb' ) }}</v-tab>
                            <v-tab href="#tab-2">{{ $t( 'message.set_proj_info_advanced_tb' ) }}</v-tab>
                            <v-tab-item value="tab-1">
                                <v-form ref="form1" v-model="valid1" lazy-validation>
                                    
                                    <date-input-view :model="date1" :label_txt="$t( 'message.set_proj_info_start' )" ref="date1"></date-input-view>
                                    <date-input-view :model="date2" :label_txt="$t( 'message.set_proj_info_deadline' )" ref="date2"></date-input-view>
                                    <date-input-view :model="date3" :label_txt="$t( 'message.set_proj_info_end' )" ref="date3"></date-input-view>
                                    
                                    <v-select :label="$t( 'message.set_proj_info_status' )" v-model="info.status"
                                         :items="statuses" clearable/>
                                    <v-select :label="$t( 'message.set_proj_info_pphase' )" v-model="info.phase" item-text="txt" 
                                        item-value="val" :items="pPhases"></v-select>
                                    
                                   <v-text-field :label="$t( 'message.set_proj_info_ponum' )" v-model="info.po_number"></v-text-field> 
                                   
                                </v-form>
                            </v-tab-item>   
                            <v-tab-item value="tab-2">
                                <v-form ref="form2" v-model="valid2" lazy-validation>
                                    <v-textarea :label="$t( 'message.set_proj_info_descr' )" v-model="info.descr"></v-textarea>
                                    <v-text-field :label="$t( 'message.set_proj_info_budget' )" v-model="info.budget" type="number"></v-text-field> 
                                    <v-text-field :label="$t( 'message.set_proj_info_customer' )" v-model="info.customer"></v-text-field> 
                                    <v-text-field :label="$t( 'message.set_proj_info_partners' )" v-model="info.partners"></v-text-field> 
                                    <v-select :label="$t( 'message.set_proj_info_autover' )" v-model="info.auto_ver" item-text="txt" 
                                        item-value="val" :items="aVers"></v-select>
                                </v-form>
                            </v-tab-item> 
                        </v-tabs>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click.native="doAct()">{{ $t( 'message.ok_btn' ) }}</v-btn>
                    <v-btn color="green darken-1" text @click.native="close()">{{ $t( 'message.cancel_btn' ) }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>   

</template>

<script>
    
import Vue from 'vue'

import DateInput from '../DateInput.vue'

import{ conf } from '@/utils/confTruePLM';

export default {
  name: 'SetProjInfo',
  data () 
  {
    return {
        visFl: false,
        
        date1: null,
        date2: null,
        date3: null,
        
        pPhases: [],
        statuses: [ 'Planned', 'In work', 'Stopped', 'Completed' ],
        aVers: [ { val: true, txt: 'Automatic' }, { val: false, txt: 'Manual' } ],
        info: {},
        valid1: true,
        valid2: true,
        
        resolve: null,
        reject: null,
        valid: true,
        active: null
    };
  },
  computed: {
  },
  components: {
       'date-input-view': DateInput
  },
  methods: {
      open( data )
      {
          let self = this;
          self.pPhases = [];
          conf.getChildTypes( 'urn:rdl:epm-std:project_phase' )
                  .then( ( phData ) =>
          {
              phData.forEach( ( ph ) =>
              {
                  this.pPhases.push( { val: ph, txt: this.$utils.clearType( ph ) } );                                
              })
          });
          
          this.info = { auto_ver: data.autoVersioning, descr: data.project_descr, status: data.current_status, budget: data.budget,
                phase: data.current_phase, customer: data.customer, partners: data.partners, po_number: data.poNumber };
            
//          if( data.start_date )
           this.date1 = data.start_date;
           this.date2 = data.deadline;
           this.date3 = data.end_date;
//              this.$refs.date1.setVals( data.start_date.split( ' ' )[ 0 ] );
//          else
//              this.$refs.date1.clear();
//          
//          if( data.deadline )
//              this.$refs.date2.setVals( data.deadline.split( ' ' )[ 0 ] );
//          else
//              this.$refs.date2.clear();
//          
//          if( data.end_date )
//              this.$refs.date3.setVals( data.end_date.split( ' ' )[ 0 ] );
//          else
//              this.$refs.date3.clear();

          this.visFl = true;
          
          return new Promise( ( resolve, reject ) => 
          {
            this.resolve = resolve;
            this.reject = reject;
          });
      },
      close()
      {
          this.visFl = false;
          this.reject();
      },
      doAct()
      {
          this.visFl = false;
          this.info.start_date = this.$refs.date1.computedDateFormatted ? this.$refs.date1.computedDateFormatted + ' 00:00:00' : null;
          this.info.deadline = this.$refs.date2.computedDateFormatted ? this.$refs.date2.computedDateFormatted + ' 00:00:00' : null;
          this.info.end_date = this.$refs.date3.computedDateFormatted ? this.$refs.date3.computedDateFormatted + ' 00:00:00' : null
          
          //console.log( this.info );

          this.resolve( this.info );
      }
    }
}
</script>





