<template>

    <v-dialog v-model="visFl" persistent max-width="350">
       <v-card>
        <v-card-title class="headline">
          {{ $t( 'message.add_notify_title' ) }}
        </v-card-title>
        <v-card-text>
                <v-tabs v-model="active">
                    <v-tab href="#tab-0" v-if="aggrInfo">{{ $t( 'message.notify_cond_tb' ) }}</v-tab>
                    <v-tab href="#tab-1">{{ $t( 'message.notify_trigger_tb' ) }}</v-tab>
                    <v-tab href="#tab-2" :disabled="disabledAct">{{ $t( 'message.notify_act_tb' ) }}</v-tab>
                    
                    <v-tab-item value="tab-0">
                        <v-form ref="form3" v-model="valid3" lazy-validation>
                            <v-select :label="$t( 'message.notify_afield' )" v-model="aField" item-text="txt" item-value="val" 
                                 :items="aFields" id="aField"></v-select>
                            
                            <v-select :label="$t( 'message.notify_afield' )" v-model="aSign" 
                                 :items="aSigns" id="aSign"></v-select>
                            
                            <v-text-field :label="$t( 'message.notify_avalue' )" v-model="aValue" 
                                    type="number" id="aValue"></v-text-field>
                            
                            <v-text-field :label="$t( 'message.notify_rule_alias' )" v-model="ruleAlias" id="ruleAlias"></v-text-field>
                        </v-form>
                        <v-btn style="margin-top: -30px;" small @click.native="addRule()">{{ $t( 'message.notify_add_rule_btn' ) }}</v-btn>
                        
                        <v-form ref="form0" v-model="valid0" lazy-validation>
                            <div style="font-weight: 700" v-if="aRules.length < 1">
                                {{$t( 'message.notify_arules' )}}
                                <span style="color: red; font-weight: 700">{{ $t( 'message.cond_empty' )}}</span>
                            </div>
                            <div style="font-weight: 700" v-else>{{$t( 'message.notify_arules' )}}</div>
                            <v-chip-group column active-class="primary--text">
                              <span v-for="( item, ind ) in aRules" :key="ind">
                                {{ ( ind > 0 ) ? 'OR' : ''}} 
                                <v-chip close @click:close="delRule( ind )">
                                    <span class="cut-text">{{ $utils.clearType( item.fld ) }}</span>
                                    <span style="padding-left: 7px;">
                                        {{item.sign}} {{ item.val }}
                                    </span>
                                    <span style="padding-left: 7px;" v-if="item.name"> ({{ item.name }})</span>
                                </v-chip>
                              </span>      
                            </v-chip-group>
                            <v-text-field :label="$t( 'message.notify_pause' )" required v-model="aPause" id="aPause"
                                          type="number" :rules="[v => !( !v || parseInt( v ) < 1 ) || $t( 'message.value_required' )]"></v-text-field>
                        </v-form>
                    </v-tab-item>
                    <v-tab-item value="tab-1">
                        <v-form ref="form1" v-model="valid1" lazy-validation>
                            <v-text-field :label="$t( 'message.add_notify_name' )" required v-model="info.name"
                                    :rules="[v => !!v || $t( 'message.value_required' )]"></v-text-field>
                            
                            <v-select :label="$t( 'message.add_notify_events' )" required v-model="info.events" item-text="txt" item-value="val" 
                                        :items="events" :rules="[v => !!v || $t( 'message.value_required' )]" :disabled="pref"></v-select>
                            
                            <v-text-field :label="$t( 'message.add_notify_cond' )" required v-model="info.condition" :disabled="pref"
                                    :rules="[v => !!v || $t( 'message.value_required' )]"></v-text-field>
                            
                            <v-select :label="$t( 'message.add_notify_act' )" required v-model="info.act" item-text="txt" item-value="val" 
                                 :items="acts" :rules="[v => !!v || $t( 'message.value_required' )]" @change="checkAct"></v-select>
                            
                            <v-checkbox :label="$t( 'message.add_notify_active' )" v-model="info.active"></v-checkbox>
                        </v-form>
                    </v-tab-item>
                    <v-tab-item value="tab-2">
                        <v-form ref="form2" v-model="valid2" lazy-validation>
                            <v-text-field :label="$t( 'message.add_notify_act_name' )" required v-model="act.name"
                                    :rules="[v => !!v || $t( 'message.value_required' )]"></v-text-field>
                            
                            <v-select :label="$t( 'message.add_notify_act_type' )" required v-model="act.action_type" item-text="txt" item-value="val" 
                                        :items="act_types" :rules="[v => !!v || $t( 'message.value_required' )]"></v-select>
                            
                            <v-select :label="$t( 'message.add_notify_act_users' )" v-model="act.users" item-text="txt" item-value="val" 
                                        :items="users"  multiple></v-select>
                            <v-select :label="$t( 'message.add_notify_act_roles' )" v-model="act.roles" item-text="txt" item-value="val" 
                                        :items="roles"  multiple></v-select>
                            <v-select :label="$t( 'message.add_notify_act_subdomain' )" v-model="act.subdomain_id" item-text="txt" item-value="val" 
                                        :items="subdomains" clearable ></v-select>
                            
                            <v-text-field :label="$t( 'message.add_notify_act_subj' )" required v-model="act.subj"
                                    :rules="[v => !!v || $t( 'message.value_required' )]"></v-text-field>
                            
                            <v-textarea :label="$t( 'message.add_notify_act_msg' )" required v-model="act.message" 
                                        :rules="[v => !!v || $t( 'message.value_required' )]"></v-textarea>
                        </v-form>
                    </v-tab-item>                            
                </v-tabs>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn color="green darken-1" text :disabled="!valid1 || !valid2" @click.native="doAct()">{{ $t( 'message.ok_btn' ) }}</v-btn>
            <v-btn color="green darken-1" text @click.native="close()">{{ $t( 'message.cancel_btn' ) }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>   
    
</template>

<script>
    
import Vue from 'vue'

import{ conf } from '@/utils/confTruePLM';

export default {
  name: 'addNotify',
  data () 
  {
    return {
        visFl: false,
        
        roles: [],
        events: [],
        users: [],
        subdomains: [],
        info: {},
        act: {},
        act_types: [ { txt: 'Notification', val: 'NOTIFY'}, { txt: 'Mail', val: 'SEND_EMAIL' } ],
        acts: [],
        aSigns: [ '=', '>', '>=', '<', '<=' ],
        aFields: [],
        aField: null,
        aSign: '=',
        aValue: 0,
        aRules: [],
        ruleAlias: null,
        title: null,
        pref: false,
        aPause: 120,
        
        resolve: null,
        reject: null,
        valid0: true,
        valid1: true,
        valid2: true,
        valid3: true,
        active: null,
        disabledAct: true,
        aggrInfo: null
    };
  },
  computed: {
      
  },
  components: {
  },
  methods: {
      checkAct()
      {
          if( this.info.act === 'NEW_ACT' )
          {
              this.disabledAct = false;
              this.active = 'tab-2';
          }
          else
              this.disabledAct = true;
      },
      delRule( ind )
      {
          this.aRules.splice( ind, 1 );
      },
      addRule()
      {
          let rule = { fld: this.aField, sign: this.aSign, val: this.aValue, name: this.ruleAlias };
          for( let i = 0; i < this.aRules.length; i++ )
          {
              let r = this.aRules[ i ];
              if( rule.fld === r.fld && rule.sign === r.sign && rule.val === r.val )
                  return;
          }
          this.aRules.push( rule );
      },
      open( nInfo, aggrInfo )
      {
    //      console.log( nInfo, aggrInfo );
          let self = this;
          this.aggrInfo = aggrInfo;
          this.aFields = [];
          this.aRules = [];
          this.aValue = 0;
          this.aPause = 120;
          
          if( aggrInfo )
          {
              this.active = 'tab-0';
              Promise.all( [ conf.getPropsByName( aggrInfo.cur_type ), conf.getAggrRules( aggrInfo.urn ) ] )
                      .then( data => 
              {
                 data[ 0 ].forEach( val => 
                 {
                    if( val.domains.includes( 'urn:plcs:rdl:TruePLM:Numeric_value' ) ) 
                    {
                        self.aFields.push( { val: val.urn, txt: this.$utils.clearType( val.urn ) } );           
                    }
                 });
                 self.aField = self.aFields[ 0 ].val;
                 
                 if( data[ 1 ] )
                 {
                    let rData = JSON.parse( data[ 1 ] );
                    if( rData.pause )
                        self.aPause = rData.pause;
                    if( rData.rules )
                        self.aRules = rData.rules;
                 }
              });
          }
          
          this.info = { active: true };
          this.act = {};
          this.pref = false;
          if( nInfo )
          {
              this.info = nInfo;
              this.pref = true;
              if( this.info.action )
                  this.info.act = this.info.action.name;
              if( this.info.event_types )
                  this.info.events = this.info.event_types[ 0 ].name;
          }
          
          this.acts = [ { txt: 'New action', val: 'NEW_ACT' } ];
          conf.listActions()
                .then( ( data ) =>
          {
              self.$utils.sortArray( data, ( p ) => ( p.name || '' ) );
              data.forEach( ( item ) =>
              {
                   self.acts.push( { val: item.name, txt: item.name } );    
              });
          })
                .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.$eventBus.$emit( 'queryError', err );
          });
          
          this.events = [];
          conf.getChildTypesInContext( 'urn:plcs:rdl:TruePLM:System_event' )
                  .then( ( data ) =>
          {
              this.$utils.sortArray( data, ( a ) => ( this.$utils.clearType( a ) ) );
              data.forEach( ( val ) =>
              {
                  self.events.push( { val: val, txt: this.$utils.clearType( val ) } );                  
              });
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });

          this.roles = [];
          conf.getChildTypes( 'urn:rdl:epm-std:project_role' )
                  .then( ( data ) =>
          {
              self.roles = self.$utils.getEnum( data );
          });

          this.users = [];
          conf.getProjectUsersEnum()
                  .then( ( data ) =>
          {
              self.users = data;
          });
          
          this.subdomains = [];
          conf.subdomainList()
                  .then( data => 
          {
              data.forEach( s => 
              {
                  self.subdomains.push( { txt: s.root_bkdn_elem.name, val: s.root_bkdn_elem.instance_id } );
              });
          });
          
          this.title = '';
          
          this.visFl = true;
          
          return new Promise( ( resolve, reject ) => 
          {
            this.resolve = resolve;
            this.reject = reject;
          });
      },
      close()
      {
          this.visFl = false;
          this.reject();
      },
      doAct()
      {
          if( this.aggrInfo && this.$refs.form0 && ( this.aRules.length < 1 || !this.$refs.form0.validate() ) )
              return;
          
          if( !this.$refs.form1 || !this.$refs.form1.validate() )
          {
              this.active = 'tab-1';
              return;
          }
          
          if( this.$refs.form1.validate() && ( this.info.act !== 'NEW_ACT' || this.$refs.form2.validate() ) )
          {
                this.visFl = false;
                
                let aggrRules = null;
                if( this.aggrInfo )
                    aggrRules = { rules: this.aRules, pause: this.aPause };
                
                this.resolve( { trigger: this.info, act: this.act, aggrRules: aggrRules } );
          }
      }
    }
}
</script>





