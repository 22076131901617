<template>
    
<v-app id="inspire">
    <v-main>
        <v-banner v-if="!hideCookies" color="light-green" style="z-index: 10; bottom: 1px; position: absolute; width: 100%;">
            {{ $t( 'message.cookies_banner' ) }}
            <template v-slot:actions>
              <v-btn text color="primary" @click="closeCookies()">{{ $t( 'message.cookies_banner_close' ) }}</v-btn>
            </template>
        </v-banner>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-1">
              <v-toolbar dark color="primary">
                <v-toolbar-title>{{ $t( 'message.login_ttl' ) }}</v-toolbar-title>
                <v-spacer></v-spacer>
                 <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn color="primary" dark v-on="on">{{langInfo.locale}}</v-btn>
                  </template>

                  <v-list>
                    <v-list-item v-for="l in langs" :key="l" @click="setLang( l )">
                      <v-list-item-title>{{ l }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-toolbar>
              <v-card-text>
                  <v-alert type="error" v-model="errShow" dismissible>{{errText}}</v-alert>
                <v-form>
                    <v-select prepend-icon="home" v-show="settings.servers.length > 1" :items="settings.servers" item-text="title" item-value="number" v-model="server" label="Server" single-line></v-select>  
                    <v-text-field prepend-icon="person" name="login" :label="$t( 'message.user_name' )" type="text" id="login" ref="fEl"
                          placeholder=" " persistent-placeholder v-model="credentials.username" @keypress.native="keyCheck">
                    </v-text-field>
                    <v-text-field prepend-icon="lock" name="password" :label="$t( 'message.pass' )" id="password" type="password" 
                        placeholder=" " persistent-placeholder v-model="credentials.password" @keypress.native="keyCheck" autocomplete="">
                    </v-text-field>
<!--                    <v-text-field prepend-icon="verified_user" name="code" :label="$t( 'message.code2fa' )" type="text" id="code"
                        placeholder=" " persistent-placeholder v-model="code2fa" @keypress.native="keyCheck" v-show="show2fa">
                    </v-text-field>-->
                </v-form>
              </v-card-text>
              <v-card-actions>
                  <div style="display: flex; flex-direction: column; width: 100%;">
                      <div style="display: flex; flex-direction: row; justify-content: space-between; ">
                        <v-btn v-show="globalState.initInfo && globalState.initInfo.usereg" text color="primary" @click="regReq">{{ $t( 'message.reg_req_btn' ) }}</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn id="s_btn" color="primary" @click="submit">{{ $t( 'message.login_btn' ) }}</v-btn>
                      </div>
                      <div class="login-choice" v-show="globalState.initInfo 
                           && ( globalState.initInfo.google_id || globalState.initInfo.yandex_id || globalState.initInfo.keycloak_id )">
                          <span>{{ $t( 'message.login_sso') }}</span></div>
                      <div style="display: flex; flex-direction: row; justify-content: space-around; ">
                        <v-btn v-show="globalState.initInfo && globalState.initInfo.yandex_id"
                               id="ya_btn" @click="login_ya"><v-img :src="yaImg"></v-img></v-btn>

                        <v-btn v-show="globalState.initInfo && globalState.initInfo.google_id" 
                               id="ggl_btn" @click="login_ggl"><v-img :src="gglImg"></v-img></v-btn>
                               
                        <v-btn v-show="globalState.initInfo && globalState.initInfo.keycloak_id" 
                               id="kcl_btn" @click="login_kcl"><v-img :src="kclImg"></v-img></v-btn>
                      </div>
                  </div>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
    
</template>

  <script>
      
  import router from '@/router';
  import { auth } from '@/utils/auth';
  
  export default {
    data() {
      return {
        errShow: false,
        errText: '',
        settings: require( process.env.VUE_APP_CONFIG_ROOT + '/settings' ),
        globalState: this.$globalStore.state,
        server: {},  
        credentials: {
          username: '',
          password: ''
        },
        code2fa: null,
        show2fa: false,
        error: '',
        langInfo: this.$i18n,
        hideCookies: false
      };
    },
    computed: {
        yaImg()
        {
            return require( '../assets/Yandex.svg' );
        },
        gglImg()
        {
            return require( '../assets/Google.svg' );
        },
        kclImg()
        {
            return require( '../assets/Keycloak.svg' );
        },
        langs() 
        {
            return Object.keys( this.$i18n.messages );
        }        
    },
    methods: 
    {
      login_kcl()
      {
          router.push( '/kcllogin' );  
      },
      login_ya()
      {
          router.push( '/yalogin' );  
      },
      login_ggl()
      {
          router.push( '/ggllogin' );  
      },
      regReq()
      {
          router.push( '/regreq' );          
      },
      setLang( l )
      {
          this.$i18n.locale = l;
      },  
      keyCheck( e )
      {
          if( e.keyCode === 13 )
              this.submit();
      }, 
      closeCookies()
      {
          this.hideCookies = true;
          localStorage.setItem( 'hideCookies', true );          
      },
      submit() 
      {
          if( this.show2fa )
              this.send2FAcode();
          else
              this.sendLogin();
      },
      send2FAcode()
      {
           let self = this;
           auth.check2fa( this.code2fa )
                   .then( rez => 
           {
               //console.log( rez );
               if( rez.token )
                   self.gotoNext();
           })
                   .catch( err => 
           {
                self.procLoginError( err );
           } );
      },
      gotoNext()
      {
          this.show2fa = false;
          this.code2fa = null;
//          console.log( auth.getUser() );

          this.$globalStore.setCurUser( auth.getUser().name );

          if( auth.getUser().name === 'superuser' )
              router.push( { name: 'Admin' } );
          else
              router.push( '*' );
      },
      sendLogin()
      {
        this.errShow = false;
        let serverInfo = this.settings.servers[ this.server ];
        let credentials = {
          user: this.credentials.username,
          password: this.credentials.password,
          server: serverInfo,
          pageURL: window.location.href
        };
        
        let self = this;
        
        auth.login( credentials )
                .then( ( rez ) =>
        {
            //console.log( rez );
            if( rez.status )
            {
                if( rez.use2fa )
                {
                    //self.show2fa = true;
                    this.$modalDlg.sysDlgs.confirmOneValDlg.open( 'otp_title', 'otp_msg', null, null, null, 'otp' )
                            .then( otp =>
                    {
                        if( otp )
                        {
                            self.code2fa = otp;
                            self.send2FAcode();
                        }
                    }, () => {} );
                }
                else
                    self.gotoNext();
            }
            
//            let stompCl = self.$utils.connectWS(  auth.getServer().ws_url, this.$eventBus ); 
//            this.$globalStore.setStompClient( stompCl );                        
        })
                .catch( ( err ) => 
        {
            self.procLoginError( err );
        } );
      },
      procLoginError( err )
      {
          console.log( err );
          if( err.response )
          {
              err = err.response;                
          }
          else if( err.code === 400 )
          {
              err = 'Bad Request';                
          }
          else
          {
              let errInfo = err.response.split( ':' );
              if( errInfo && errInfo.length > 1 )
                  err = errInfo[ 2 ];
              err = err.substring( err.indexOf( ' ', 1 ) );
              err = err.substring( 0, err.indexOf( '\n', 1 ) );
          }

          this.errText = err;
          this.errShow = true;

//            this.$alert( err, 'Login error', 
//            {
//                confirmButtonText: 'OK',
//                type: 'error'
//            });
      }
    },
    mounted: function()
    {
        //console.log( this.$globalStore.state.initInfo );
        this.hideCookies = localStorage.getItem( 'hideCookies' );          
        this.server = 0;//this.settings.servers[ 0 ];
    }
  }
  </script>
  <style>
      .login-choice span {
    color: #5b6987;
    display: -ms-grid;
    display: grid;
    font-size: 16px;
    width: 100%;
    line-height: 40px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    -ms-grid-columns: minmax(20px,1fr) auto minmax(20px,1fr);
    grid-template-columns: minmax(20px,1fr) auto minmax(20px,1fr);
    grid-gap: 19px;
}
.login-choice span:after, .login-choice span:before {
    content: "";
    border-top: 1px solid #e5e8ed;
}
  </style>