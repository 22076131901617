<template>
            <v-dialog v-model="visFl" persistent max-width="290">
               <v-card>
                <v-card-title class="headline">
                  {{ $t( 'message.create_baseline_title' ) }}
                </v-card-title>
                <v-card-text>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-text-field :label="$t( 'message.create_baseline_id' )" required v-model="id"
                            :rules="[v => !!v || $t( 'message.value_required' )]"></v-text-field>
                        <v-textarea :label="$t( 'message.create_baseline_descr' )" required v-model="descr" 
                            :rules="[v => !!v || $t( 'message.value_required' )]"></v-textarea>
                    </v-form>
                    <!--<small>*{{ $t( 'message.req_info_msg' ) }}</small>-->
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text :disabled="!valid" @click.native="doAct()">{{ $t( 'message.ok_btn' ) }}</v-btn>
                    <v-btn color="green darken-1" text @click.native="close()">{{ $t( 'message.cancel_btn' ) }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>   
</template>

<script>
    
import Vue from 'vue'

export default {
  name: 'createBaseline',
  data () 
  {
    return {
        visFl: false,
        name: '',
        descr: '',
        id: '',
        resolve: null,
        reject: null,
        valid: true,
    };
  },
  computed: {
  },
  methods: {
      open( node )
      {
          this.name = node.name;
          this.descr = '';
          this.id = '';
          
          this.visFl = true;
          
          return new Promise( ( resolve, reject ) => 
          {
            this.resolve = resolve;
            this.reject = reject;
          });
      },
      close()
      {
          this.visFl = false;
          this.reject();
      },
      doAct()
      {
          if( this.$refs.form.validate() )
          {
            this.visFl = false;
            this.resolve( { id: this.id, descr: this.descr } );
          }
      }
    }
}
</script>



