<template>
        <v-dialog v-model="visFl" persistent max-width="450">
           <v-card>
            <v-card-title class="headline">
              {{ $t( 'message.date_format_title' ) }}
            </v-card-title>
            <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-radio-group v-model="dateType">
                        <v-radio :label="$t( 'message.date_format_date_type' )" value="date"></v-radio>
                        <div style="display: flex; flex-direction: column; padding-left: 15px;">
                            <div style="display: flex; flex-direction: row; align-items: center;">
                                <v-text-field v-model="date1" readonly :disabled="dateType !== 'date'"></v-text-field>
                                <v-btn icon :disabled="dateType !== 'date'" @click="changeParts( 1 )"><v-icon>sync_alt</v-icon></v-btn>
                                <v-text-field v-model="date2" readonly :disabled="dateType !== 'date'"></v-text-field>
                                <v-btn icon :disabled="dateType !== 'date'" @click="changeParts( 2 )"><v-icon>sync_alt</v-icon></v-btn>
                                <v-text-field v-model="date3" readonly :disabled="dateType !== 'date'"></v-text-field>
                                <v-text-field v-model="dateD" :label="$t( 'message.date_format_delim' )" id="dateD"
                                          :disabled="dateType !== 'date'" style="padding-left: 15px;" @change="procFormat"></v-text-field>
                            </div>
                            <div style="display: flex; flex-direction: row; align-items: center;">
                                <v-checkbox v-model="includeTime" :label="$t( 'message.date_format_time' )" dense
                                            :disabled="dateType !== 'date'" @change="procFormat"></v-checkbox>
                                <v-text-field v-model="timeD" :label="$t( 'message.date_format_delim' )"
                                       :disabled="dateType !== 'date'" id="timeD" @change="procFormat"
                                       style="padding-left: 15px; max-width: 6em"></v-text-field>
                            </div>
                            <div style="display: flex; flex-direction: row; align-items: center;">
                                <v-checkbox v-model="includeMSec" :label="$t( 'message.date_format_msec' )" dense
                                        :disabled="dateType !== 'date' || !includeTime" @change="procFormat"></v-checkbox>
                                <v-text-field v-model="msecD" :label="$t( 'message.date_format_delim' )"
                                       :disabled="dateType !== 'date' || !includeTime" id="msecD" @change="procFormat"
                                       style="padding-left: 15px; max-width: 6em"></v-text-field>
                            </div>
                            <span :class="{ disabled: ( dateType !== 'date' ) }">{{ formatStr }}</span>
                        </div>
                        
                        <v-divider inset style="margin-left: 0; max-width: 100%;"></v-divider>
                        <v-radio :label="$t( 'message.date_format_sec_type' )" value="sec" style="padding-top: 15px;"></v-radio>
                        <v-radio :label="$t( 'message.date_format_msec_type' )" value="msec"></v-radio>
                    </v-radio-group>
                </v-form>
                <!--<small>*{{ $t( 'message.req_info_msg' ) }}</small>-->
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
                <v-btn id="dateFormatOk" color="green darken-1" text :disabled="!valid" @click.native="doAct()">{{ $t( 'message.ok_btn' ) }}</v-btn>
              </v-card-actions>
          </v-card>
        </v-dialog>   
</template>

<script>
    
import Vue from 'vue'
import{ conf } from '@/utils/confTruePLM';

export default {
  name: 'dateFormat',
  data () 
  {
    return {
        visFl: false,
        formatStr: null,
        dateType: 'sec',
        includeTime: true,
        includeMSec: false,
        date1: 'Year',
        date2: 'Month',
        date3: 'Day',
        timeD: ':',
        dateD: '-',
        msecD: ',',
        resolve: null,
        reject: null,
        valid: true
    };
  },
  computed: {
  },
  methods: {
      changeParts( pos )
      {
          if( pos === 1 )
          {
              let t = this.date2;
              this.date2 = this.date1;
              this.date1 = t;
          }
          else
          {
              let t = this.date2;
              this.date2 = this.date3;
              this.date3 = t;
          }
          this.procFormat();
      },
      procFormat()
      {
          this.formatStr = this.getDateVal( this.date1 );
          this.formatStr += this.dateD + this.getDateVal( this.date2 );
          this.formatStr += this.dateD + this.getDateVal( this.date3 );
          
          if( this.includeTime )
          {
              this.formatStr += ' ' + 'HH' + this.timeD + 'mm' + this.timeD + 'ss' ;
              if( this.includeMSec )
                  this.formatStr += this.msecD + 'SSS'

          }
          
      },
      getDateVal( part )
      {
          if( part === 'Year' )
              return 'yyyy';
          else if( part === 'Month' )
              return 'MM';
          else if( part === 'Day' )
              return 'dd';
          
          return '';
      },
      getDateTtl( part )
      {
          if( part === 'yyyy' )
              return 'Year';
          else if( part === 'MM' )
              return 'Month';
          else if( part === 'dd' )
              return 'Day';
          
          return '';
      },
      setFormat( data )
      {
          if( this.dateType !== 'date' )
              return;
          
          let parts = data.expression.split( ' ' );
          if( parts.length > 1 )
          {
              this.includeTime = true;
              if( parts[ 1 ].indexOf( 'SSS' ) > -1 )
              {
                  this.includeMSec = true;
                  this.msecD = parts[ 1 ].substring( parts[ 1 ].length - 4, parts[ 1 ].length - 3 );
              }
              else
                  this.includeMSec = false;
          }
          else
              this.includeTime = false;
          
          let s = parts[ 0 ].substring( 0, 2 );
          if( s === 'MM' || s === 'dd' )
              this.dateD = parts[ 0 ].substring( 2, 3 );
          else
              this.dateD = parts[ 0 ].substring( 4, 5 );
          parts = parts[ 0 ].split( this.dateD );
          this.date1 = this.getDateTtl( parts[ 0 ] );
          this.date2 = this.getDateTtl( parts[ 1 ] );
          this.date3 = this.getDateTtl( parts[ 2 ] );
      },
      open( data )
      {   
          this.dateType = data.dateType || 'sec';
          this.setFormat( data );
          this.procFormat();
          this.visFl = true;
          
          return new Promise( ( resolve, reject ) => 
          {
            this.resolve = resolve;
            this.reject = reject;
          });
      },
      close()
      {
          this.visFl = false;
          this.reject();
      },
      doAct()
      {
          if( this.$refs.form.validate() )
          {
            this.visFl = false;
            this.resolve( this.dateType + '&&&' + this.formatStr );
          }
      }
    }
}
</script>
<style>
#timeD, #dateD, #msecD
{
    font-weight: 700;
}

.disabled
{
    color: rgba(0,0,0,.38);
}
</style>



