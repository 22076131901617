<template>

  <v-navigation-drawer app clipped fixed v-model="drawer.visNav" id="dr" style="width: 330px;">
     <v-list>
       <v-list-group prepend-icon="dashboard" :value="mini1" key="2" no-action>
        <v-list-item slot="activator">
          <v-list-item-title class="big">{{ $t( 'message.projects_ttl' ) }}</v-list-item-title>
        </v-list-item>
           <v-list-group v-for="( folder, index ) in folderList" :key="'f' + index" :value="mini1" sub-group no-action>
               <v-list-item slot="activator" style="padding-right: 0px;">
                    <v-list-item-title class="big">{{folder.name}}</v-list-item-title>
                    <v-list-item-action>
                        <v-menu bottom right>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" icon>
                                    <v-icon>more_vert</v-icon>
                                </v-btn>
                            </template>
                            <v-list-item key="mi3" style="background-color: white;" @click.stop="renameFolder( folder )">
                                <v-list-item-title>{{$t( 'message.pmenu_rename' )}}</v-list-item-title>
                            </v-list-item>
                            <v-list-item key="mi4" style="background-color: white;" @click.stop="deleteFolder( folder )">
                                <v-list-item-title>{{$t( 'message.pmenu_delete' )}}</v-list-item-title>
                            </v-list-item>
                        </v-menu>
                    </v-list-item-action>
               </v-list-item>    
               <v-list-item v-for="( proj, index ) in folder.projects" :key="'p1' + index" :value="mini2" @click="selectProjWithRole( proj )">
                    <v-list-item-title>{{proj.in_project.name}}</v-list-item-title>
                    <v-list-item-action>
                        <v-menu bottom right>
                         <template v-slot:activator="{ on }">
                             <v-btn v-on="on" icon>
                                 <v-icon>more_vert</v-icon>
                             </v-btn>
                         </template>
                            <v-list-item key="mi2" style="background-color: white;" @click.stop="moveToFolder( proj )">
                                <v-list-item-title>{{$t( 'message.pmenu_move' )}}</v-list-item-title>
                            </v-list-item>
                        </v-menu>
                    </v-list-item-action>
               </v-list-item>
           </v-list-group>
           <v-list-item v-for="( proj, index ) in projList" :key="'p2' + index" :value="mini1" @click="selectProjWithRole( proj )">
               <v-list-item-title>{{proj.in_project.name}}</v-list-item-title>   
               <v-list-item-action>
                    <v-menu bottom right>
                     <template v-slot:activator="{ on }">
                         <v-btn v-on="on" icon>
                             <v-icon>more_vert</v-icon>
                         </v-btn>
                     </template>
                        <v-list-item key="mi1" style="background-color: white;" @click.stop="moveToFolder( proj )">
                            <v-list-item-title>{{$t( 'message.pmenu_move' )}}</v-list-item-title>
                        </v-list-item>
                    </v-menu>
              </v-list-item-action>
           </v-list-item>
           
<!--        <v-list-group v-for="( proj, index ) in projList" :key="proj.in_project.guid" sub-group no-action :value="mini1">
          <v-list-item slot="activator">
            <v-list-item-title class="big">{{proj.in_project.name}}</v-list-item-title>
          </v-list-item>
          <v-list-item v-for="( role, index ) in proj.user_registered_as" :key="role"  @click="selectProj( proj, role )">
            <v-list-item-title>{{getRole(role)}}</v-list-item-title>
          </v-list-item>
        </v-list-group>  -->
      </v-list-group>
      <v-list-group prepend-icon="extension" :value="mini2" key="3" no-action v-show="bTemplList && bTemplList.length > 0">
        <v-list-item slot="activator">
          <v-list-item-title class="big">{{ $t( 'message.b_templ_ttl' ) }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-for="( proj, index ) in bTemplList" :key="'p3' + index" :value="mini1" @click="selectProjWithRole( proj )">
           <v-list-item-title>{{proj.in_project.name}}</v-list-item-title>
        </v-list-item>
<!--        <v-list-group v-for="( proj, index ) in bTemplList" :key="proj.in_project.guid" sub-group no-action :value="mini2">
          <v-list-item slot="activator">
            <v-list-item-title class="big">{{proj.in_project.name}}</v-list-item-title>
          </v-list-item>
          <v-list-item v-for="( role, index ) in proj.user_registered_as" :key="role"  @click="selectProj( proj, role )">
            <v-list-item-title>{{getRole(role)}}</v-list-item-title>
          </v-list-item>
        </v-list-group>  -->
      </v-list-group>
      <v-list-group prepend-icon="extension" :value="mini3" key="4" no-action v-show="templList && templList.length > 0">
        <v-list-item slot="activator">
          <v-list-item-title class="big">{{ $t( 'message.templ_ttl' ) }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-for="( proj, index ) in templList" :key="'p4' + index" :value="mini1" @click="selectProjWithRole( proj )">
           <v-list-item-title>{{proj.in_project.name}}</v-list-item-title>
        </v-list-item>
<!--        <v-list-group v-for="( proj, index ) in templList" :key="proj.in_project.guid" sub-group no-action :value="mini3">
          <v-list-item slot="activator">
            <v-list-item-title class="big">{{proj.in_project.name}}</v-list-item-title>
          </v-list-item>
          <v-list-item v-for="( role, index ) in proj.user_registered_as" :key="role"  @click="selectProj( proj, role )">
            <v-list-item-title>{{getRole(role)}}</v-list-item-title>
          </v-list-item>
        </v-list-group>  -->
      </v-list-group>
         
      <v-list-group prepend-icon="settings_applications" :value="mini4" key="1" no-action>
          
              <v-list-item slot="activator">
                    <v-list-item-title class="big">{{ $t( 'message.admin_ttl' ) }}</v-list-item-title>
              </v-list-item>
          
        <v-list-item key="17" @click="newProjFolder()">
            <v-list-item-title>{{ $t( 'message.admin_folder_ttl' ) }}</v-list-item-title>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-icon slot="activator" @click.stop="openHelp( 'Create-project-folder' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                </template>
                <span>
                     {{$t( 'message.open_help' )}}
                </span>
            </v-tooltip>
        </v-list-item>
        <v-list-item key="11" @click="newProj( null )">
            <v-list-item-title>{{ $t( 'message.admin_proj_ttl' ) }}</v-list-item-title>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-icon slot="activator" @click.stop="openHelp( 'Create-project' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                </template>
                <span>
                     {{$t( 'message.open_help' )}}
                </span>
            </v-tooltip>
        </v-list-item>
        <v-list-item key="12" @click="newProj( 'pdm' )">
            <v-list-item-title>{{ $t( 'message.admin_proj_pdm_ttl' ) }}</v-list-item-title>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-icon slot="activator" @click.stop="openHelp( 'Create-project-from-PDM-file' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                </template>
                <span>
                     {{$t( 'message.open_help' )}}
                </span>
            </v-tooltip>
        </v-list-item>
        <v-list-item key="13" @click="newProj( 'txt' )">
            <v-list-item-title>{{ $t( 'message.admin_proj_txt_ttl' ) }}</v-list-item-title>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-icon slot="activator" @click.stop="openHelp( 'Create-project-from-text' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                </template>
                <span>
                     {{$t( 'message.open_help' )}}
                </span>
            </v-tooltip>
        </v-list-item>
        <v-list-item key="14" @click="newProj( 'bsl' )">
            <v-list-item-title>{{ $t( 'message.admin_proj_bsl_ttl' ) }}</v-list-item-title>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-icon slot="activator" @click.stop="openHelp( 'Create-project-from-baseline' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                </template>
                <span>
                     {{$t( 'message.open_help' )}}
                </span>
            </v-tooltip>
        </v-list-item>
        <v-list-item key="15" @click="newProj( 'ap242' )">
            <v-list-item-title>{{ $t( 'message.admin_proj_ap242_ttl' ) }}</v-list-item-title>
           <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-icon slot="activator" @click.stop="openHelp( 'Create-project-from-AP242-Domain' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                </template>
                <span>
                     {{$t( 'message.open_help' )}}
                </span>
            </v-tooltip>
        </v-list-item>
        <v-list-item key="16" @click="newProj( 'dex' )">
            <v-list-item-title>{{ $t( 'message.admin_proj_dex_ttl' ) }}</v-list-item-title>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-icon slot="activator" @click.stop="openHelp( 'Create-project-from-ASD-DEX1' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                </template>
                <span>
                     {{$t( 'message.open_help' )}}
                </span>
            </v-tooltip>
        </v-list-item>
        <v-list-item key="18" @click="setPass()">
            <v-list-item-title>{{ $t( 'message.admin_pass_ttl' ) }}</v-list-item-title>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-icon slot="activator" @click.stop="openHelp( 'Change-user-password' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                </template>
                <span>
                     {{$t( 'message.open_help' )}}
                </span>
            </v-tooltip>
        </v-list-item>
<!--        <v-list-item key="18" @click="open2fa()">
            <v-list-item-content>
                <v-list-item-title>{{ $t( 'message.admin_2fa_ttl' ) }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>-->
        <v-list-item key="19" @click="openApiTokens()">
            <v-list-item-title>{{ $t( 'message.admin_tokens_ttl' ) }}</v-list-item-title>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-icon slot="activator" @click.stop="openHelp( 'API-Tokens' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                </template>
                <span>
                     {{$t( 'message.open_help' )}}
                </span>
            </v-tooltip>
        </v-list-item>
      </v-list-group>
    </v-list>

    <v-list dense>
      <!--<v-divider></v-divider>-->
      <v-list-item @click.stop="downloadFile">
        <v-list-item-action>
          <v-icon>file_download</v-icon>
        </v-list-item-action>
          <v-list-item-title class="big">{{ $t( 'message.down_ttl' ) }}</v-list-item-title>
          <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-icon slot="activator" @click.stop="openHelp( 'Download-file-by-Hash' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                </template>
                <span>
                     {{$t( 'message.open_help' )}}
                </span>
          </v-tooltip>
      </v-list-item>
      <v-list-item @click.stop="showInfo">
        <v-list-item-action>
          <v-icon>question_answer</v-icon>
        </v-list-item-action>
          <v-list-item-title class="big">{{ $t( 'message.info_ttl' ) }}</v-list-item-title>
          <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-icon slot="activator" @click.stop="openHelp( 'About-(for-a-project-user)' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                </template>
                <span>
                     {{$t( 'message.open_help' )}}
                </span>
          </v-tooltip>
      </v-list-item>
      <v-list-item @click.stop="logOut" id="domElem">
        <v-list-item-action>
            <v-icon>all_out</v-icon>
        </v-list-item-action>
          <v-list-item-title class="big">{{ $t( 'message.logout_ttl' ) }}</v-list-item-title>
          <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-icon slot="activator" @click.stop="openHelp( 'Log-Out' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                </template>
                <span>
                     {{$t( 'message.open_help' )}}
                </span>
          </v-tooltip>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
    
</template>

<script>

import router from '../router';

import { auth } from '../utils/auth';
import{ conf } from '../utils/confTruePLM';

export default {
  name: 'nav-models',
  data () 
  {
    return {
        projList: null,
        bTemplList: null,
        templList: null,
        folderList: null,
        drawer: this.$modalDlg.state,
        showMenu: false,
        mini1: false,
        mini2: false,
        mini3: false,
        mini4: false
    };
  },
  methods: {
      toggleMini()
      {
          this.mini = !this.mini;          
      },
      openHelp( link )
      {
          this.$utils.openHelp( link );
      },
      downloadFile()
      {
          let self = this;
          this.$modalDlg.sysDlgs.confirmOneValDlg.open( 'down_file_hash_title', 'down_file_hash_msg' )
                  .then( ( hash ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.open();
              return conf.getDocByHash( hash );
          }, () => {} )
                  .then( ( flInfo ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              if( flInfo )
              {
                  let url = conf.getFileLink( flInfo.title, flInfo.source );
                  window.location.href = encodeURI( url );  
              }
          })
                  .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      moveToFolder( proj )
      {
          let self = this;
          let list = [];
          self.folderList.forEach( f =>
          {
              list.push( f.name );
          });
          this.$modalDlg.sysDlgs.confirmOneValDlg.open( 'folder_move_proj_title', 'folder_move_proj_msg',
                    null, proj.in_project.name, null, 'select', list )
                  .then( ( name ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.open();
              return conf.moveProjToFolder( name, proj.in_project.guid );
          }, () => {} )
                  .then( ( rez ) => 
          {
              self.updateProjList( null );
              self.$modalDlg.sysDlgs.progressDlg.close();
          })
                  .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      newProjFolder()
      {
          let self = this;
          this.$modalDlg.sysDlgs.confirmOneValDlg.open( 'folder_create_title', 'folder_create_msg' )
                  .then( ( name ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.open();
              return conf.createFolder( name );
          }, () => {} )
                  .then( ( rez ) => 
          {
              self.updateProjList( null );
              self.$modalDlg.sysDlgs.progressDlg.close();
          })
                  .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      renameFolder( folder )
      {
          let self = this;
          this.$modalDlg.sysDlgs.confirmOneValDlg.open( 'folder_rename_title', 'folder_rename_msg', folder.name )
                  .then( ( name ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.open();
              return conf.renameFolder( folder.name, name );
          }, () => {} )
                  .then( ( rez ) => 
          {
              self.updateProjList( null );
              self.$modalDlg.sysDlgs.progressDlg.close();
          })
                  .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      deleteFolder( folder )
      {
          let self = this;
          this.$modalDlg.sysDlgs.confirmDlg.open( 'folder_del_title', 'folder_del_msg', null, folder.name, false )
                  .then( () =>
          {
              self.$modalDlg.sysDlgs.progressDlg.open();
              return conf.deleteFolder( folder.name );
          }, () => {} )
                  .then( ( rez ) => 
          {
              self.updateProjList( null );
              self.$modalDlg.sysDlgs.progressDlg.close();
          })
                  .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      openApiTokens()
      {
          this.$modalDlg.sysDlgs.tokenListDlg.open();
      },
//      open2fa()
//      {
//          let self = this;
//          let curUser = this.$globalStore.state.curUser;
//          
//          this.$modalDlg.sysDlgs.set2faDlg.open( curUser )
//                  .then( rez =>
//          {
//              return conf.set2fa( rez.use2fa, !rez.code );
//          }, () => { return Promise.resolve(); } )
//                  .then( rez =>
//          {
//              if( rez && rez.user_2FA_code )
//              {
//                  if( rez.use_2FA )
//                    this.$modalDlg.sysDlgs.confirm2faDlg.open( rez.user_2FA_code );
//                  else
//                    this.$modalDlg.sysDlgs.confirmDlg.open( 'set_2fa_title', 'notuse_2fa_msg', null, null, true );
//              }
//          })
//                  .catch( ( err ) =>
//          {
//              self.$eventBus.$emit( 'queryError', err );
//          });  
//      },
      setPass()
      {
          let self = this;
          let curUser = this.$globalStore.state.curUser;
          this.$modalDlg.sysDlgs.setUserPassDlg.open( curUser, false )
                  .then( ( info ) =>
          {
              return conf.setUserPass( info.oldPass, info.newPass );
          }, () => { return Promise.resolve(); } )
                  .then( ( rez ) => 
          {
              if( rez && !rez.error_code )
                this.$modalDlg.sysDlgs.confirmDlg.open( 'user_pass_done_title', 'user_pass_done_msg', null, curUser, true );
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });  
      },
      newProj( type )
      {
          let self = this;
          self.$modalDlg.sysDlgs.createProjDlg.open( false, type, false, self.folderList )
                  .then( ( proj ) =>
          {
              if( proj )
                  self.$eventBus.$emit( 'createProj', proj );   
          }, () => {} );      
      },
      selectProjWithRole( proj, isSaved )
      {
          let role = this.$utils.getRole( proj.user_registered_as );
          this.selectProj( proj, role, isSaved );
      },
      selectProj( proj, role, isSaved )
      {
          if( role )
          {
            this.$modalDlg.hideNav();//toggleNav();
            this.$globalStore.setCurUserRole( role );
            conf.setProj( proj, role );
            localStorage.setItem( 'proj_model_id', proj.in_project.project_model_id );
            localStorage.setItem( 'proj_role', role );
            if( isSaved )
            {
                let prevPath = localStorage.getItem( 'curr_path' );
                let searchPrm = localStorage.getItem( 'search_prm' );
                localStorage.removeItem( 'search_prm' );
                
                let nodeID = localStorage.getItem( 'nodeID' );
                localStorage.removeItem( 'nodeID' );
                if( nodeID )
                {
                    conf.getNodePath( nodeID )
                            .then( ( data ) =>
                    {
                        //console.log( data );
                        if( data && data.tree_path_to_element )
                            this.$eventBus.$emit( 'selectedModel', { path: data.tree_path_to_element, str_path: data.path, search: searchPrm } );                    
                        else
                            this.$eventBus.$emit( 'selectedModel', { search: searchPrm } );
                    })
                            .catch( ( err ) =>
                    {
                        //console.log( err )
                        this.$eventBus.$emit( 'selectedModel', { search: searchPrm } );
                    })
                }
                else
                    this.$eventBus.$emit( 'selectedModel', { path: prevPath, search: searchPrm } );
            }
            else
                this.$eventBus.$emit( 'selectedModel' );
            
            this.$globalStore.setCurFolderInfo( proj.in_project );
            this.$globalStore.setProjType( proj.in_project );
            this.$globalStore.setProjAutoVer( proj.in_project );
          }

          this.$modalDlg.sysDlgs.searchNodeDlg.clearCond();
          this.$modalDlg.sysDlgs.searchDocDlg.clearCond();
      },
      updateProjList( info )
      {
          let self = this;
          conf.getProjStruct()
                  .then( data =>
          {
             if( info && !info.fromURL && ( !conf.getProj() || !conf.getProj().in_project 
                     || conf.getProj().in_project.name !== info.project_model_id ) )
             {
                this.$modalDlg.sysDlgs.confirmDlg.open( 'open_last_title', 'open_last_msg' )
                          .then( () => 
                  {
                      self.updateProjListInfo( info, data.children || []  );
                  }, () => 
                  {
                      localStorage.removeItem( 'proj_model_id' );
                      self.updateProjListInfo( null, data.children || []  );
                  });
              }
              else
                self.updateProjListInfo( info, data.children || []  );
          }, ( err )=>{ self.$eventBus.$emit( 'queryError', err ); } );
      },
      updateProjListInfo( info, folderInfo )
      {
            let selProj = null;
            let self = this;
            conf.getRepositoryList()
                    .then( ( data )=>
            {
                let p = [];
                let bt = [];
                let t = [];
                if( data )
                { 
                    data.forEach( ( proj ) =>
                    {
                        if( proj.is_active_in_project && proj.user_registered_as.length > 0 )
                        {
                            if( proj.in_project.is_bkd_template_project )
                                bt.push( proj );
                            else if( proj.in_project.is_template_project )
                                t.push( proj );
                            else
                                p.push( proj );
                            
                            if( info && proj.in_project.project_model_id === info.project_model_id )
                                selProj = proj;
                        }
                    });
                }
                if( p && p.length > 0 )
                    self.$utils.sortArray( p, ( a ) => ( a.in_project.name ) );
                if( bt && bt.length > 0 )
                    self.$utils.sortArray( bt, ( a ) => ( a.in_project.name ) );
                if( t && t.length > 0 )
                    self.$utils.sortArray( t, ( a ) => ( a.in_project.name ) );
                
                self.updateNotifications( JSON.parse( JSON.stringify( p ) ) );
                
                folderInfo.forEach( fld =>
                {
                    for( let i = 0; fld.projects && i < fld.projects.length; i++ )
                    {
                        self.$utils.sortArray( fld.projects[ i ], ( a ) => ( a.name ) );
                        for( let j = 0; p && j < p.length; j++ )
                        {
                            if( fld.projects[ i ].guid === p[ j ].in_project.guid )
                            {
                                fld.projects[ i ] = p[ j ];
                                p.splice( j, 1 );
                                break;
                            }
                        }
                        if( !fld.projects[ i ].in_project )
                        {
                            fld.projects.splice( i, 1 );
                            i--;
                        }
                    }
                });
                self.folderList = folderInfo;
                self.projList = p;
                self.bTemplList = bt;
                self.templList = t;
                
                if( selProj )
                    self.selectProjWithRole( selProj, true ); 
                else
                    self.drawer.visNav = true;
//                if( selProj )
//                {
//                    let selRole = null;
//                    let prevRole = localStorage.getItem( 'proj_role' );
//                    if( localStorage.getItem( 'proj_model_id' ) === info.project_model_id )
//                    {
//                        for( var i = 0; selProj.user_registered_as && i < selProj.user_registered_as.length; i++ )
//                        {
//                            if( selProj.user_registered_as[ i ] === prevRole )
//                            {
//                                selRole = prevRole;
//                                break;
//                            }
//                        }
//                    }
//                    if( selRole )
//                        self.selectProj( selProj, selRole, true ); 
//                    else if( info.fromURL )
//                    {
//                        this.$modalDlg.sysDlgs.selectRoleDlg.open( selProj.user_registered_as )
//                                .then( ( role ) =>
//                        {
//                            self.selectProj( selProj, role, true );                                                                            
//                        }, () => 
//                        {
//                            self.drawer.visNav = true;
//                        } );
//                    }
//                    else
//                        self.drawer.visNav = true;
//                }
//                else
//                    self.drawer.visNav = true;

            }, ( err )=>{ self.$eventBus.$emit( 'queryError', err ); } );
      },
      updateNotifications( pList )
      {
          let self = this;
          let fnc = [];
          ( pList || [] ).forEach( proj =>
          {
              fnc.push( conf.listNTasksForProj( proj ).catch( err => {} ) );
          });
          Promise.all( fnc )
                      .then( data => 
              {
                  self.$globalStore.setNotifications( [] );
                  let pInd = 0;
                  data.forEach( rez => 
                  {
                      self.$globalStore.procProjNotifications( pList[ pInd ].in_project, rez );
                      pInd++;
                  });
              });
      },
      showInfo()
      {
          this.$modalDlg.sysDlgs.infoDlg.open();  
      },
      logOut()
      {
          this.$modalDlg.sysDlgs.logoutDlg.open( null, null, false, true );  
      },
      getRole( userType )
      {
          return this.$utils.getRoleName( userType, this.$t );
      },
      useProjID( id, fromURL )
      {
          let projModelID = id || localStorage.getItem( 'proj_model_id' );
          if( projModelID )
              this.updateProjList( { project_model_id: projModelID, fromURL: fromURL } );        
          else
              this.updateProjList();     
      },
      reInitState()
      {
          if( auth.getUser().authenticated )
          {
              let self = this;
              let projID = localStorage.getItem( 'projID' );
              localStorage.removeItem( 'projID' );
              if( projID )
              {
                  if( isNaN( projID ) )
                        self.useProjID( projID, true );
                  else
                  {
                       conf.getProjInfo( projID )
                               .then( ( data ) =>
                       {
                           self.useProjID( data.project_model_id, true );
                       }, () => { self.useProjID(); } );
                  }
              }
              else
                  self.useProjID();
          }
      }
    },
  beforeMount: function()
  {
  },
  mounted: function()
  {
      let self = this;
      
      this.$eventBus.$on( 'updateProjList', function( info )
      {
          self.updateProjList( info );        
      });
      
      this.$eventBus.$on( 'reInitState', function()
      {
          self.reInitState();        
      });
      
      this.$eventBus.$on( 'openProjFromURL', function( info )
      {
           if( info.paths.length > 3 )
               localStorage.setItem( 'nodeID', info.paths[ 3 ] );
           if( info.paths.length > 4 )
               localStorage.setItem( 'fileID', info.paths[ 4 ] );
           
           let projID = info.paths[ 2 ];
           if( isNaN( projID ) )
               self.useProjID( projID, true );
           else
           {
                conf.getProjInfo( info.paths[ 2 ] )
                           .then( ( data ) =>
                   {
                        self.useProjID( data.project_model_id, true );
                   }, () => { self.useProjID(); } );
           }
      });
      
      if( auth.getUser().authenticated )
      {
//          let projID = localStorage.getItem( 'projID' );
//          localStorage.removeItem( 'projID' );
//          if( projID )
//          {
//              conf.getProjInfo( projID )
//                      .then( ( data ) =>
//              {
//                  self.useProjID( data.project_model_id, true )
//              }, () => { self.useProjID(); } );
//          }
//          else
//              self.useProjID();
 //        console.log( this.$globalStore.state.stompClient ) ;
          if( !this.$globalStore.state.stompClient )
          {
              let stompCl = this.$utils.connectWS( auth.getServer().ws_url, this.$eventBus, this.$globalStore ); 
              this.$globalStore.setStompClient( stompCl );  
          }
      }
  },
  beforeDestroy: function()
  {
      this.$eventBus.$off( 'updateProjList' );
      this.$eventBus.$off( 'openProjFromURL' );
      this.$eventBus.$off( 'reInitState' );
  }//,
//  created() 
//  {
//        console.log( 'open12' );
//        window.addEventListener( 'beforeunload', () => 
//        {
//            auth.logout(); 
//        }, false );
//  }
}
</script>

<style scoped>
  .big 
  {
      font-weight: bold;
      font-size: 14px;
      
  }
</style>


