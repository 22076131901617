<template>
    <v-dialog v-model="visFl" persistent max-width="320">
       <v-card>
        <v-card-title class="headline">
          <span>{{ $t( 'message.upload_doc_tmpl' ) }}</span>
        </v-card-title>
        <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
                <file-upload ref="fileUpload" :rules="[v => v.length > 0 || $t( 'message.file_required' )]"></file-upload>
                
                <v-select :label="$t( 'message.upload_doc_tmpl_type' )" required v-model="docType" 
                          item-text="txt" item-value="val" :items="types" 
                          :rules="[v => !!v || $t( 'message.value_required' )]"></v-select>
                <v-select :label="$t( 'message.upload_doc_tmpl_disc' )" required v-model="docDisc" 
                          item-text="txt" item-value="val" :items="disc" 
                          :rules="[v => !!v || $t( 'message.value_required' )]"></v-select>
            </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn color="green darken-1" text :disabled="!valid" @click.native="doAct()">{{ $t( 'message.ok_btn' ) }}</v-btn>
            <v-btn color="green darken-1" text @click.native="close()">{{ $t( 'message.cancel_btn' ) }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>       
</template>

<script>
    
import Vue from 'vue'

import FileUpload from '../FileUpload';
import{ conf } from '@/utils/confTruePLM';

export default {
  name: 'genDoc',
  data () 
  {
    return {
        visFl: false,
        types: [],
        disc: [],
        docType: null,
        docDisc: null,
                
        resolve: null,
        reject: null,
        valid: true
    };
  },
  computed: {
      
  },
  components: {
       'file-upload': FileUpload
  },
  methods: {
      open()
      {
          let self = this;
          this.types = [];
          this.disc = [];
          Promise.all( [ conf.getChildTypes( 'urn:rdl:epm-std:Document_content_type' ),
              conf.getChildTypes( 'urn:rdl:epm-std:project_discipline' ) ] )
                  .then( data => 
          {
              self.types = self.$utils.getEnumClass( data[ 0 ] );
              self.disc = self.$utils.getEnumClass( data[ 1 ] );
          })
                .catch( ( err ) =>
          {
              this.$eventBus.$emit( 'queryError', err );
          });
//          conf.getChildTypes( 'urn:rdl:epm-std:Document_content_type' )
//                  .then( ( data ) =>
//          {
//              self.types = self.$utils.getEnumClass( data );
//          });
//          
//          this.disc = [];
//          conf.getChildTypes( 'urn:rdl:epm-std:project_discipline' )
//                  .then( ( data ) =>
//          {
//              self.disc = self.$utils.getEnumClass( data );
//          });
          
          this.docType = null;
          this.docDisc = null;
          
          this.visFl = true;
          
          return new Promise( ( resolve, reject ) => 
          {
            this.resolve = resolve;
            this.reject = reject;
          });
      },
      close()
      {
          this.visFl = false;
          this.reject();
      },
      doAct()
      {
          if( this.$refs.form.validate() )
          {
            this.visFl = false;
            let files = this.$refs.fileUpload.items;
            this.$refs.fileUpload.clear();
            if( files && files.length > 0 )
                this.resolve( { files: files, type: this.docType, discipline: this.docDisc } );
            else
                this.reject();
          }
      }
    }
}
</script>





