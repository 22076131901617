module.exports = {
    login_ttl: 'Login',
    login_btn: 'Login',
    user_name: 'User name',
    user_name_pl: 'enter your user name',
    pass: 'Password',
    pass_pl: 'enter your password',
    logout_ttl: 'Log Out',
    info_ttl: 'About',
    full_custom_title: '{cur_ttl}',
    models_ttl: 'Models',
    projects_ttl: 'Projects',
    structure_tb: 'Structure',
    files_tb: 'Files',
    ok_btn: 'Ok',
    cancel_btn: 'Cancel',
    support_ttl: 'Support',
    useful_info_ttl: 'Useful info',
    dlg_custom_msg: '{cur_msg}',
    logout_msg: 'You are going to LogOut. Continue?',
    prop_name_col: 'Name',
    prop_value_col: 'Value',
    file_name_col: 'Name',
    file_type_col: 'Type',
    file_introduced_by_col: 'Creator',
    file_introduced_col: 'Introduced',
    file_descr_col: 'Description',
    file_last_ver_col: 'Last version',
    file_last_ver_user_col: 'Submitted by',
    conf_del_title: 'Delete element',
    conf_del_msg: 'Are You sure?',
    conf_rename_title: 'Rename element',
    conf_rename_msg: 'New element name',
    file_download_btn: 'Download',
    file_delete_btn: 'Delete',
    conf_del_doc_title: 'Delete document',
    req_info_msg: 'indicates required field',
    create_node_title: 'Create',
    edit_node_title: 'Edit',
    create_node_name: 'Name',
    create_node_descr: 'Description',
    create_node_type: 'Type',
    menu_paste: 'Paste element',
    menu_create: 'Create element',
    menu_delete: 'Delete element',
    menu_rename: 'Rename element',
    menu_edit: 'Edit element',
    menu_open: 'Open element',
    menu_cut: 'Cut element',
    menu_add_file: 'Add data file',
    confirm_ttl: 'Confirme',
    confirm_msg: 'Are You sure?',
    b_templ_ttl: 'Breakdown templates',
    templ_ttl: 'Project templates',
    add_file_node_title: 'Add data file',
    file_file_tb: 'File',
    file_props_tb: 'Props',
    file_users_tb: 'Users',
    add_file_node_ctype: 'Content type',
    add_file_node_source: 'Source',
    add_file_node_discipline: 'Discipline',
    add_file_node_pphase: 'Project phase',
    add_file_node_status: 'Status',
    add_file_node_editor: 'Editor',
    add_file_node_resp: 'Responsible',
    add_file_node_rev: 'Reviewer',
    add_file_node_app: 'Approver',
    add_file_node_rman: 'Release manager',
    error_ttl: 'Error',
    no_conn_msg: 'Login token is invalid. Log out?',
    no_session_msg: 'Your session has expired, please re-login',
    sticky_notes_title: 'Sticky notes',
    close_btn: 'Close',
    conf_del_note_title: 'Delete note',
    new_btn: 'New',
    submit_btn: 'Submit',
    doc_links_title: 'Dependencies',
    conf_del_link_title: 'Delete dependency',
    doc_links1_title: 'Affected files',
    doc_links2_title: 'Affecting files',
    menu_file_copy: 'Copy file',
    menu_file_cut: 'Cut file',
    menu_paste_file: 'Paste data file',
    menu_file_link1: 'Paste file as affected',
    menu_file_link2: 'Paste file as affecting',
    menu_file_checkout: 'Checkout file',
    menu_file_checkout_cancel: 'Undo checkout',
    menu_file_checkin: 'Checkin file',
    menu_file_notes: 'Sticky notes',
    menu_file_links: 'Dependencies',
    checkin_file_title: 'CheckIn file',
    checkin_file_new_issue: 'Create new revision',
    prop_num_col: 'Num',
    search: 'Search',
    doc_search_tb: 'Documents',
    node_search_tb: 'Nodes',
    search_title: 'Title',
    search_descr: 'Description',
    search_doc_tab: 'Doc search',
    menu_file_goto: 'Go to',
    search_name: 'Name',
    search_node_tab: 'Node search',
    menu_search_node: 'Node search',
    menu_search_doc: 'Document search',
    search_doc_ttl: 'Document search',
    search_node_ttl: 'Node search',
    search_advanced_tb: 'Advanced',
    search_general_tb: 'General',
    search_user_tb: 'User',
    search_date_tb: 'Date',
    search_user_create: 'Created by',
    search_user_edit: 'Edited by',
    search_create_after: 'Created after',
    search_create_before: 'Created before',
    search_edit_after: 'Edited after',
    search_edit_before: 'Edited before',
    search_submit_after: 'Submitted after',
    search_submit_before: 'Submitted before',
    search_user_reviewer: 'Reviewer',
    search_user_editor: 'Editor',
    search_user_resp: 'Responsible',
    search_user_approver: 'Approver',
    search_user_rmanager: 'Release manager',
    search_ext_ver: 'External version',
    search_rid: 'Rid number',
    search_user_checkout: 'Checked out by',
    search_only_red_flag: 'Have red flag',
    search_only_sticky_note: 'Have sticky note',
    search_only_checkout: 'Have been checked out',
    bom_tb: 'Structure tree',
    menu_show_bom: 'Show Structure tree',
    node_tree_tb: 'Element in context',
    menu_show_node_tree: 'Show element in context',
    menu_create_baseline: 'Create baseline',
    create_baseline_title: 'Create baseline',
    create_baseline_descr: 'Description',
    create_baseline_id: 'ID',
    baseline_list_title: 'Baselines',
    bsl_id_col: 'Baseline ID',
    bsl_descr_col: 'Description',
    bsl_date_col: 'Date created',
    bsl_user_col: 'Created by',
    bsl_root_col: 'Root',
    bsl_act_col: '',

    make_diff_title: 'Version / Baseline comparison',
    make_diff_info: 'Select a Version 1 or Baseline 1 and compare with a Version 2 or Baseline 2.',
    make_diff_ver1: 'Version 1',
    make_diff_base1: 'Baseline 1',
    make_diff_ver2: 'Version 2',
    make_diff_base2: 'Baseline 2',
    add_file_title: 'Title',
    value_required: 'Value is required',
    file_required: 'File is required',
    diff_type_col: '',
    diff_name_col: 'Name',
    diff_elem_col: 'Element',
    diff_diff_col: 'Difference',
    diff_ver1_col: 'Version (left)',
    diff_ver2_col: 'Version (right)',
    diff_list_tab: 'List of differences',
    diff_res_ttl: 'Comparing project data versions',
    tab_menu_sort: 'Sort',
    tab_menu_close: 'Close tab',
    diff_wrong_roots: 'The two structures have different roots; they cannot be compared. Please, change your selection.',
    diff_detail_tab: 'Details',
    det_name_col: 'Property name',
    det_val1_col: 'Value - Left version',
    det_val2_col: 'Value - Right version',
    new_project_btn: 'Create new project',
    baseline_list_btn: 'Baselines',
    diff_btn: 'Make comparison',
    new_proj_title: 'Create project',
    new_proj_name: 'Name',
    new_proj_descr: 'Description',
    menu_import: 'Import from STEP PDM-file',
    import_file_title: 'Import from STEP PDM-file',
    menu_export: 'Export to STEP PDM-file',
    prop_add_btn: 'Add property',
    prop_save_btn: 'Save changes',
    add_prop_title: 'Add property',
    edit_prop_title: 'Edit property',
    prop_clear_title: 'Clear property',
    prop_clear_msg: 'Are You sure?',
    edit_prop_type: 'Name',
    edit_prop_value: 'Value',
    edit_prop_unit: 'Unit',
    prop_btn: 'Reference data definitions',
    prop_info_tab: 'Reference data values',
    prop_list_tab: 'Types of reference data',
    to_proj_btn: 'Back to project',
    refdata_delete_title: 'Delete reference data value',
    refdata_delete_msg: 'Are You sure?',
    refdata_add_title: 'New reference data values',
    refdata_add_msg: 'Value',
    docprop_delete_title: 'Delete document property',
    docprop_delete_msg: 'Are You sure',
    docprop_add_title: 'New document property',
    docprop_add_msg: 'Name',
    nodeprop_delete_title: 'Delete node property',
    nodeprop_delete_msg: 'Are You sure?',
    nodeprop_add_title: 'New node property',
    nodeprop_add_msg: 'Name',
    node_prop_sel: 'Node type',
    prop_type_col: 'Type',
    prop_vals_col: 'Values/Expression',
    prop_units_col: 'Units',
    create_prop_title: 'Create new property',
    create_prop_type: 'Type',
    create_prop_name: 'Name',
    create_prop_vals: 'Values',
    create_prop_units: 'Units',
    admin_ttl: 'Administration',
    admin_proj_ttl: 'Create project',
    menu_phase: 'Current project phase',
    phase_ttl: 'Current project phase',
    menu_create_subdomain: 'Create subdomain',
    create_subdomain_title: 'Create subdomain',
    create_subdomain_user: 'Subdomain leader',
    create_subdomain_pphase: 'Limit to project phase',
    menu_delete_subdomain: 'Delete subdomain',
    subdomain_delete_title: 'Delete subdomain',
    subdomain_delete_msg: 'Are You sure?',
    created_baseline_title: 'Baseline',
    created_baseline_msg: 'Baseline \'{val}\' was created',
    menu_users_subdomain: 'Subdomain members',
    menu_roles_subdomain: 'Subdomain roles',
    member_list_title: 'Subdomain members',
    add_btn: 'Add',
    conf_del_member_msg: 'Are You sure?',
    conf_del_member_title: 'Remove member from subdomain',
    member_list_role: 'Role',
    member_list_user: 'User',
    role_list_title: 'Subdomain roles',
    role_list_perms: 'Permissions',
    role_list_name: 'Role',
    search_format: 'Format',
    menu_subdomain: 'Subdomain management',
    tooltip_subdomain: 'Subdomain',
    info_proj_ttl: 'Project',
    info_user_ttl: 'User',
    info_pphase_ttl: 'Phase',
    info_pdescr_ttl: 'Description',
    menu_req: 'Create requirement',
    edit_req_title: 'Edit',
    create_req_title: 'Create',
    menu_info_subdomain: 'Subdomain info',
    sub_info_ttl: 'Subdomain info',
    no_subinfo_msg: 'Unknown subdomain',
    sub_create_user: 'Created by',
    sub_create_date: 'Created at',
    sub_lead_user: 'Leader',
    create_prop_readonly: 'read only access',
    info_server_ttl: 'Version',
    checkin_file_wrong_name: 'Selected file has a different name!',
    file_hist_ver: 'Version',
    file_hist_date: 'Submission date',
    file_hist_user: 'Submitted by',
    file_hist_status: 'Status',
    file_hist_descr: 'Description',
    file_hist_act: '',
    file_hist_title: 'Document history',
    file_hist_btn: 'View history',
    error_proj_name_msg: 'Specified project name cannot be used',
    error_50100_msg: 'File locked by other user.',
    error_baseline_create_msg: 'Baseline was not created.',
    error_from_server_msg: '{val}',
    search_general2_tb: 'Options',
    menu_show_bom_req: 'Show requirement tree',
    bom_req_tb: 'Requirements',
    user_props_tb: 'User defined',
    props_tb: 'Breakdown properties',
    file_props_pnl_tb: 'Document properties',
    conf_del_doc_msg: 'You are going to delete file and all its dependences. Are You sure?',
    conf_del_doc_msg_member: 'You are going to delete selected file. Are You sure?',
    more_info_link: 'Show more',
    nomore_info_link: 'Hide more',
    change_note_msg: 'Content was changed',
    ignore_btn: 'Ignore',
    update_btn: 'Update',
    admin_users_ttl: 'User list',
    admin_info_tab: 'Info',
    admin_user_tab: 'Users',
    user_name_col: 'Name',
    user_role_col: 'Role',
    user_add_btn: 'Add user',
    user_delete_btn: 'Delete user from project',
    user_edit_btn: 'Edit user\'s role on project',
    user_delete_title: 'Delete user',
    user_delete_msg: 'You are going to delete user \'{val}\' from the project. Are You sure?',
    user_edit_title: 'Edit user\'s role on project',
    user_add_title: 'Add user to project',
    set_user_proj_name: 'User',
    set_user_proj_role: 'Role',
    menu_file_readonly_set: 'Set file read only',
    menu_file_readonly_clear: 'Clear file read only',
    file_open_btn: 'Open',
    user_real_name_col: 'Real name',
    user_email_col: 'E-mail',
    user_org_col: 'Organization',
    user_name_delete_title: 'Delete user',
    user_name_delete_msg: 'You are going to delete user \'{val}\' from the system. Are You sure?',
    create_user_name: 'Name',
    create_user_email: 'E-mail',
    create_user_real_name: 'Real name',
    create_user_org: 'Organization',
    create_user_title: 'Create user',
    edit_user_title: 'Edit user',
    create_user_pass: 'Password',
    user_edit_pass_btn: 'Change password',
    edit_user_pass_title: 'Change password for user \'{val}\'',
    edit_user_pass_msg: 'New password',
    user_pass_done_title: 'Change password',
    user_pass_done_msg: 'Password for the user \'{val}\' was successfully changed',

    rename_element_help: 'Rename the node',
    edit_element_help: 'Edit node properties',
    delete_element_help: 'Delete node ',
    cut_element_help: 'Move the node',
    create_element_help: 'Create a new node',
    paste_element_help: 'Paste the node here',
    add_data_file_help: 'Add a  data file to node',
    paste_data_file_help: 'Paste the file here',
    show_element_in_context_help: 'Show position of the node within the tree',
    show_BOM_help: 'Show Structure tree',
    create_baseline_help: 'Make a baseline for the node',
    subdomain_info_help: 'Show subdomain information',
    subdomain_management_help: 'Open the subdomain admin gui',
    create_subdomain_help: 'Create a new subdomain',
    delete_subdomain_help: 'Remove the subdomain',
    subdomain_members_help: 'Shows members of the subdomain',
    subdomain_roles_help: 'Manage subdomain roles',
    import_from_STEP_PDM_file_help: 'Create a breakdown structure from given PDM file',
    checkout_file_help: 'Checkout file fior editing',
    undo_checkout_help: 'Undo the checkout',
    checkin_file_help: 'check in the given file',
    download_help: 'Download the file to local machine',
    view_history_help: 'View history of changes for the selected file',
    delete_help: 'Delete the file',
    copy_file_help: 'Copy the file',
    cut_file_help: 'Move the file',
    paste_file_as_affected_help: 'Create an affected relationship with the selected file',
    paste_file_as_affecting_help: 'Create an affecting relationship with the selected file',
    sticky_notes_help: 'Add notes to the file',
    dependencies_help: 'Show all file dependencies',

    no_root_title: 'Breakdown not found',
    no_root_msg: 'Would You mark the project inactive?',
    deact_proj_done_title: 'Inactive project',
    deact_proj_done_msg: 'The project was set inactive',
    admin_pass_ttl: 'Change password',
    open_help: 'Open help',
    admin_proj_list_ttl: 'Project list',
    admin_proj_list_tab: 'Projects',
    proj_list_name_col: 'Name',
    proj_list_type_col: 'Type',
    proj_list_status_col: 'Status',
    proj_list_delete_btn: 'Delete project from the system',
    proj_list_delete_title: 'Delete project',
    proj_list_delete_msg: 'You are going to delete project \'{val}\' from the system. Are You sure?',
    proj_list_act_btn: 'Set project active',
    proj_list_inact_btn: 'Set project inactive',
    proj_list_inact_title: 'Deactivate project',
    proj_list_act_title: 'Activate project',
    proj_list_inact_msg: 'You are going to set project \'{val}\' inactive. Are You sure?',
    proj_list_act_msg: 'You are going to set project \'{val}\' active. Are You sure?',
    proj_info_name_col: 'Name',
    proj_info_val_col: 'Value',
    proj_info_num_col: 'Num',
    proj_info_edit_btn: 'Edit project info',
    proj_info_name: 'Name',
    proj_info_project_descr: 'Description',
    proj_info_start_date: 'Start date',
    proj_info_deadline: 'Deadline',
    proj_info_end_date: 'End date',
    proj_info_customer: 'Customer',
    proj_info_partners: 'Partners',
    proj_info_current_status: 'Status',
    proj_info_current_phase: 'Phase',
    proj_info_budget: 'Budget',
    proj_info_poNumber: 'PO number',
    proj_info_autoVersioning: 'Versioning',
    set_proj_info_ttl: 'Edit project info',
    set_proj_info_advanced_tb: 'Advanced',
    set_proj_info_general_tb: 'General',
    set_proj_info_ponum: 'PO Number',
    set_proj_info_pphase: 'Phase',
    set_proj_info_status: 'Status',
    set_proj_info_end: 'End date',
    set_proj_info_deadline: 'Deadline',
    set_proj_info_start: 'Start date',
    set_proj_info_descr: 'Description',
    set_proj_info_budget: 'Budget',
    set_proj_info_customer: 'Customer',
    set_proj_info_partners: 'Partners',
    set_proj_info_autover: 'Versioning',

    create_proj_done_title: 'Create project',
    create_proj_done_msg: 'The project \'{val}\' was created',
    proj_list_add_btn: 'Add project',
    new_proj_type: 'Type',
    new_proj_type_bkd_tmpl: 'Breakdown template',
    new_proj_type_tmpl: 'Project template',
    new_proj_type_proj: 'Project',
    create_node_tmpl: 'Template',
    user_edit_delete_btn: 'Delete user from system',
    user_edit_edit_btn: 'Edit user\'s info',
    select_role_role: 'Role',
    select_role_title: 'Select role',
    menu_importAP242: 'Import AP242 Domain model',
    importAP242_file_title: 'Import AP242 Domain model',
    menu_export_txt: 'Export structure to text file',
    menu_import_txt: 'Import structure from text file',
    import_zip_title: 'Import structure from file',
    create_node_src: 'Source type',
    new_proj_src_type_pdm: 'PDM STEP',
    new_proj_src_type_txt: 'Structure in text',
    admin_proj_txt_ttl: 'Create project from text',
    admin_proj_pdm_ttl: 'Create project from PDM',
    admin_proj_bsl_ttl: 'Create project from baseline',
    new_proj_src_type_bsl: 'Zipped baseline',
    menu_export_to_zip: 'Export to zipped folder',
    bsl_delete: 'Delete baseline',
    bsl_show: 'View baseline',
    bsl_export: 'Export baseline',
    menu_export_DEX: 'Export project to DEX',
    export_proj_DEX_title: 'Export project to ASD DEX1',
    export_proj_DEX_msg: 'Export as assembled structure?',
    yes_btn: 'Yes',
    no_btn: 'No',
    admin_proj_dex_ttl: 'Create project from ASD DEX1',
    new_proj_src_type_dex: 'ASD DEX1',
    error_linked_docs_msg: 'There are documents linked from outside of the selected branch',
    menu_copy: 'Copy element',
    menu_importReqIF: 'Import from Req-IF file',
    importReqIF_file_title: 'Import from Req-IF file',
    menu_rename_by_attr: 'Rename by attribute',
    set_rename_attr_title: 'Rename by attribute',
    set_rename_attr_msg: 'Set attribute for node name',
    tab_menu_unfix: 'UnFreeze',
    tab_menu_fix: 'Freeze',
    list_gr_ttl: 'Representation of the aggregate property',
    user_ttl: 'User info',
    user_user_ttl: 'User',
    user_role_ttl: 'Role',
    user_name_ttl: 'Name',
    user_mail_ttl: 'E-Mail',
    user_org_ttl: 'Organization',
    search_adv_tb: 'MetaData',
    search_prop_name: 'Property name',
    search_prop_value: 'Property value',
    search_prop_add_btn: 'Add',
    cond_delete_btn: 'Delete',
    adm_projects_ttl: 'Project admin',
    user_role_name__project_leader: 'Subdomain leader',
    user_import_btn: 'Import data',
    new_proj_imp_title: 'Import data',
    import_data_done_title: 'Import data',
    import_data_done_msg: 'Data were imported',
    cookies_banner: 'The system uses cookies and local storage of the browser.',
    cookies_banner_close: 'understand',
    reg_req_ttl: 'Registration',
    reg_req_send_btn: 'Send',
    reg_req_close_btn: 'Close',
    reg_req_btn: 'Registration',
    reg_req_login: 'Login',
    reg_req_f_name: 'Name',
    reg_req_s_name: 'Surname',
    reg_req_comp: 'Company',
    reg_req_email: 'EMail',
    value_valid: 'Value must be valid',
    reg_req_send_title: 'Registration',
    reg_req_send_msg: 'The registration request was successfully sent',
    reg_req_err_msg: 'The registration request can not be sent',
    prop_dadd_btn: 'Add derived property',
    edit_dprop_title: 'Edit derived property',
    create_dprop_title: 'Create derived property',
    create_prop_inmode: 'Value mode: ',
    create_prop_inmode_true: 'basic',
    create_prop_inmode_false: 'advanced',
    create_prop_exp: 'Expression',
    create_prop_exp_text: 'to add static text or delimiter, use + and put the text in single quotes',
    prop_type_col: 'Type',
    create_prop_prop: 'Property',
    create_prop_add: 'Add',
    no_session_ttl: 'Session timeout',
    node_prop_sel_struct: 'Struct type',
    create_prop_type_elem: 'Element type',
    upload_json_title: 'Upload JSON data',
    cond_cond_exp: 'Search condition:',
    nodeprop_set_key_title: 'Set primary key',
    nodeprop_set_key_msg: 'You are going to set \'{val}\' as the key field. Are You sure?',
    prod_list_tab: 'Products',
    prod_btn: 'Open product list',
    menu_put: 'Put element',
    menu_remove: 'Remove element',
    put_prod_title: 'Put product',
    put_prod_prod: 'Name',
    put_prod_location: 'Location',
    put_prod_num: 'Quantity',
    conf_remove_title: 'Remove element',
    conf_remove_msg: 'Are You sure?',
    prod_props_pnl_tb: 'Product properties',
    menu_put_prod: 'Put product',
    menu_remove_prod: 'Remove product',
    menu_delete_prod: 'Delete product',
    menu_copy_prod: 'Copy product',
    menu_create_prod: 'Create product',
    list_gr_filter_by: 'filter by ',
    list_gr_filter_from: 'from ',
    list_gr_filter_to: ' to ',
    list_gr_apply: 'Apply',
    bsl_remove_title: 'Delete baseline',
    bsl_remove_msg: 'Are You sure?',
    prop_ro_col: 'RO',
    proj_import_title: 'Import data',
    proj_import_msg: 'All existing data in this project will be replaced by the imported data. Continue?',
    conf_del_role_title: 'Delete role from subdomain',
    conf_del_role_msg: 'Are You sure?',
    prop_edit_btn: 'Edit value',
    prop_clear_btn: 'Clear value',
    prop_upload_btn: 'Upload values',
    prop_download_btn: 'Download values',
    admin_sync_list_ttl: 'Sync servers',
    admin_sync_list_tab: 'Sync servers',
    sync_list_name_col: 'Name',
    sync_list_adr_col: 'URL',
    sync_list_proj_col: 'Project',
    create_sync_title: 'Create sync server',
    edit_sync_title: 'Edit sync server',
    create_sync_name: 'Name',
    create_sync_adr: 'URL',
    create_sync_proj: 'Project',
    create_sync_done_title: 'Create sync server',
    create_sync_done_msg: 'The sync server \'{val}\' was created',
    sync_list_delete_btn: 'Delete sync info from the system',
    sync_list_delete_title: 'Delete sync info',
    sync_list_delete_msg: 'You are going to delete sync \'{val}\' from the system. Are You sure?',
    cond_empty: 'Empty',
    rels_list_prn_title: 'Backward links',
    rels_list_ch_title: 'Direct links',
    rel_role_col: 'Role',
    rel_name_col: 'Name',
    rel_node_col: 'Node',
    rel_node_type_col: 'Node type',
    rel_dt_col: 'Create date',
    rel_use_col: 'Creator',
    rel_rem_col: 'Remark',
    rel_act_col: '',
    orgs_list_title: 'Organisations',
    orgL_name_col: 'Name',
    orgL_type_col: 'Type',
    orgL_descr_col: 'Description',
    orgL_create_col: 'Created',
    orgL_update_col: 'Updated',
    orgL_class_col: 'Classification',
    orgL_state_col: 'State',
    menu_show_node_history: 'View change log',
    node_hist_title: 'Node change log',
    node_hist_time: 'Timestamp',
    node_hist_operation: 'Operation',
    node_hist_user: 'User',
    node_hist_ver: 'Version',
    node_hist_trace: 'Trace',
    node_hist_remark: 'Remark',
    search_limit_title: 'Search limited',
    search_limit_msg: 'Search resulted in too many hits. Please, refine your search criteria.',
    rel_show: 'Go to node',
    menu_search_quick: 'Quick node search',
    quick_search_title: 'Quick node search',
    quick_search_msg: 'Input a search pattern',
    no_ch_elems_title: 'No children types',
    no_ch_elems_msg: 'There are no available types for the children elements.',
    prop_value_add_btn: 'Add value',
    prop_value_del_btn: 'Delete value',
    prop_type_add_btn: 'Add subtype',
    prop_type_del_btn: 'Remove item',
    prop_value_type_add_btn: 'Add type',
    refdata_add_subtype_title: 'New \'{val}\' subtype',
    refdata_add_type_title: 'New type',
    refdata_add_type_msg: 'New type',
    refdata_add_subtype_msg: 'New subtype',
    menu_importCSV: 'Import CSV file',
    importCSV_file_title: 'Import CSV file',
    rep_info_col: 'Info',
    rep_list_title: 'Report',
    path: 'Path',
    prop_prn_col: 'Inherited from',
    qsearch_in: 'search by',
    qsearch_name: 'name',
    qsearch_descr: 'description',
    qsearch_type: 'type',
    qsearch_prop: 'property values',
    qsearch_case: 'case sensitive',
    qsearch_include: 'include only properties',
    qsearch_info1: '@ - Matches any letter',
    qsearch_info2: '^ - Matches any upper case letter',
    qsearch_info3: '? - Matches any character',
    qsearch_info4: '& - Matches remainder of string',
    qsearch_info5: '# - Matches any digit',
    qsearch_info6: '$ - Matches a substring terminated by a space character or end-of-string',
    qsearch_info7: '* - Matches any number of characters',
    qsearch_info8: '\\ - Begins a pattern escape sequence',
    qsearch_info9: '! - Negation character (used with the other characters)',
    menu_paste_rel1: 'Paste element as relating',
    menu_paste_rel2: 'Paste element as related',
    add_rel_title: 'Add relation',
    edit_rel_title: 'Edit relation',
    add_rel_name1: 'Relating',
    add_rel_name2: 'Related',
    add_rel_rem: 'Remark',
    add_rel_type: 'Role',
    add_rel_cap1: 'Relating caption',
    add_rel_cap2: 'Related caption',
    rel_remove_title: 'Delete relation',
    rel_remove_msg: 'Are You sure?',
    rels_delete_btn: 'Delete relation',
    rels_edit_btn: 'Edit relation',
    user_role_name__project_manager: 'Project manager',
    rels_cap2_col: 'Related caption',
    rels_cap1_col: 'Relating caption',
    create_rel_role_title: 'New relation role',
    create_rel_role_name: 'Role name',
    create_rel_role_cap1: 'Relating caption',
    create_rel_role_cap2: 'Related caption',
    org_btn: 'Organisations',
    org_info_tab: 'Information',
    org_list_tab: 'Organisations',
    org_num_col: 'Num',
    org_name_col: 'Name',
    org_value_col: 'Value',
    org_act_col: '',
    org_add_btn: 'Add organisation',
    org_edit_btn: 'Edit organisation',
    org_del_btn: 'Cancel organisation',
    add_org_title: 'New organisation',
    edit_org_title: 'Edit organisation',
    add_org_title: 'Title',
    add_org_uid: 'Uid',
    add_org_address: 'Address',
    add_org_email: 'Email',
    add_org_url: 'WWW',
    add_org_phone: 'Phone',
    add_org_description: 'Description',
    add_org_roles: 'Classification',
    add_org_registered: 'Registered',
    add_org_invalid_from: 'Invalid from',
    org_del_title: 'Cancel organisation',
    org_del_msg: 'You are going to cancel \'{val}\' organisation. All assignments to the organisation will be deleted. Are You sure?',
    menu_add_org: 'Add organisation',
    add_org_title: 'Add organisation',
    add_org_role: 'Role',
    add_org_org: 'Organisation',
    orgL_act_col: '',
    orgL_delete_btn: 'Delete organisation',
    orgL_remove_title: 'Delete organisation',
    orgL_remove_msg: 'You are going to delete \'{val}\' organisation. Are You sure?',
    catalog_btn: 'Catalogues',
    menu_catalog_org: 'Organisations',
    menu_catalog_prod: 'Products',
    create_prod_add: 'Add product',
    create_prod_del: 'Delete product',
    create_prod_edit: 'Edit product',
    prod_del_title: 'Delete product',
    prod_del_msg: 'You are going to delete \'{val}\' product. Are You sure?',
    user_download_btn: 'Download STEP pack',
    user_upload_btn: 'Upload STEP pack',
    new_proj_src_type_pack: 'STEP pack',
    menu_gen_doc: 'Generate document',
    upload_doc_tmpl: 'Generate document from template',
    value_range: 'Value range',
    list_gr_download: 'Download',
    list_gr_filter_field: 'field',
    create_prod_name: 'Name',
    create_prod_descr: 'Description',
    create_prod_part_type: 'Type',
    create_prod_domain: 'Domain',
    download_aggr_title: 'Download data',
    download_aggr_msg: 'Choose format:',
    create_prop_mandatory: 'mandatory',
    prop_notify_btn: 'Add notification',
    prop_keyP_btn: 'Set key field',
    prop_deleteP_btn: 'Delete property',
    prop_editP_btn: 'Edit property',
    add_notify_title: 'Create notification',
    notify_trigger_tb: 'Trigger',
    notify_act_tb: 'Action',
    add_notify_name: 'Title',
    add_notify_events: 'Events',
    add_notify_cond: 'Condition',
    add_notify_active: 'active',
    add_notify_act_name: 'Name',
    add_notify_act_users: 'Users',
    add_notify_act_roles: 'Roles',
    add_notify_act_subj: 'Subject',
    add_notify_act_subdomain: 'Subdomain',
    add_notify_act_msg: 'Message',
    add_notify_act_type: 'Type',
    add_notify_act: 'Action',
    menu_catalog_trg: 'Triggers',
    menu_catalog_act: 'Actions',
    c_item_num_col: 'Num',
    c_item_name_col: 'Name',
    c_item_value_col: 'Value',
    c_item_act_col: '',
    act_list_tab: 'Actions',
    act_info_tab: 'Information',
    acts_prop_name: 'Name',
    acts_prop_action_type: 'Type',
    acts_prop_subj: 'Subject',
    acts_prop_message: 'Message',
    acts_prop_subdomain: 'Subdomain',
    acts_prop_users: 'Users',
    acts_prop_roles: 'Roles',
    trgs_prop_name: 'Name',
    trgs_prop_action: 'Action',
    trgs_prop_event_types: 'Event types',
    trgs_prop_condition: 'Condition',
    trgs_prop_author: 'Author',
    trgs_prop_timestamp: 'Last update',
    trgs_prop_active: 'Active',
    acts_add_btn: 'Add action',
    acts_edit_btn: 'Edit action',
    acts_del_btn: 'Delete action',
    trgs_add_btn: 'Add trigger',
    trgs_edit_btn: 'Edit trigger',
    trgs_del_btn: 'Delete trigger',
    trg_del_title: 'Delete trigger',
    trg_del_msg: 'You are going to delete \'{val}\' trigger. Are You sure?',
    act_del_title: 'Delete action',
    act_del_msg: 'You are going to delete \'{val}\' action. Are You sure?',
    add_act_title: 'Add action',
    edit_act_title: 'Edit action',
    add_trg_title: 'Add trigger',
    edit_trg_title: 'Edit trigger',
    create_prod_stage: 'Stage',
    node_prop_sel_req: 'Requirement type',
    node_prop_sel_doc: 'Document type',
    node_prop_sel_prod: 'Product type',
    empty_props_title: 'Empty fields',
    empty_props_msg2: 'Mandatory fields: \'{val}\' are empty. Continue anyway?',
    empty_props_msg: 'Mandatory field: \'{val}\' is empty. Continue anyway?',
    trg_info_tab: 'Information',
    trg_list_tab: 'Triggers',
    node_prop_sel_event: 'Events type',
    create_trg_done_title: 'Create trigger done',
    create_trg_done_msg: 'The notification trigger \'{val}\' was created',
    upload_doc_tmpl_type: 'Document type',
    upload_doc_tmpl_disc: 'Document discipline',
    node_type_order_by: 'Order elements by',
    nodeprop_set_order_title: 'Set order by',
    nodeprop_set_order_msg: 'You are going to set \'{val}\' as the order by property. Are You sure?',
    menu_catalog_ntask: 'Notification tasks',
    ntasks_tab: 'Notification tasks',
    ntasks_serial_col: 'SN',
    ntasks_act_col: 'Action',
    ntasks_atype_col: 'Type',
    ntasks_etype_col: 'Event',
    ntasks_data_col: 'Date',
    ntasks_subj_col: 'Subject',
    ntasks_msg_col: 'Message',
    ntasks_state_col: 'State',
    ntasks_delete_btn: 'Delete notification task',
    ntasks_act_btn: 'Reactivate notification task',
    ntasks_del_title: 'Delete notification task',
    ntasks_del_msg: 'You are going to delete notification task number {val}. Are You sure?',
    ntasks_act_title: 'Reactivate notification task',
    ntasks_act_msg: 'You are going to reactivate notification task number {val}. Are You sure?',
    qsearch_area: 'filter results by',
    qsearch_folder_only: 'current folder only',
    qsearch_branch_only: 'current branch only',
    qsearch_project_only: 'current project',
    search_branch_only: 'Search in current branch only',
    proj_info_chain: 'Blockchain ready',
    proj_info_init_chain: 'Initiate',
    open_chain: 'Open blockchain',
    chain_ttl: 'Blockchain',
    chain_num_col: 'Num',
    chain_hash_col: 'Block',
    chain_down_btn: 'Download',
    chain_down_all_btn: 'Download chain',
    pmenu_move: 'Move to folder',
    pmenu_delete: 'Delete folder',
    pmenu_rename: 'Rename folder',
    folder_rename_title: 'Rename folder',
    folder_rename_msg: 'New folder name',
    folder_del_title: 'Delete folder',
    folder_del_msg: 'You are going to delete folder \'{val}\'. Are You sure?',
    admin_folder_ttl: 'Create project folder',
    folder_create_title: 'Create project folder',
    folder_create_msg: 'Folder name',
    folder_move_proj_title: 'Move project \'{val}\' to folder',
    folder_move_proj_msg: 'Folder',
    create_proj_folder: 'Folder',
    down_ttl: 'Download file by HASH',
    down_file_hash_title: 'Download file by HASH',
    down_file_hash_msg: 'HASH',
    chain_copy_btn: 'Copy block number to the clipboard',
    proj_list_size_col: 'Data size',
    proj_info_dataSize: 'Data size',
    file_convert_btn: 'Convert to WCAX',
    code2fa: 'Code 2FA',
    code2fa_pl: '123456',
    admin_2fa_ttl: '2FA settings',
    set_2fa_title: '2FA settings for \'{val}\'',
    set2fa_off: '2FA disabled',
    set2fa_on: '2FA enabled',
    notuse_2fa_msg: '2FA was disabled',
    use_2fa_msg: '2FA was enabled. Use code \'{val}\' to create new account in the Authenticator applicaton for the one time 6 digital password generation.',
    use_2fa_msg1: '2FA was enabled.',
    use_2fa_msg2: 'Now You have to install the',
    use_2fa_msg3: 'Authenticator application',
    use_2fa_msg4: '(Google Authenticator, Microsoft Authenticator or other) on your phone.',
    use_2fa_msg5: 'And create new account to generate one time 6 digital password.',
    use_2fa_msg6: 'Use the code \'{val}\' for new account or scan QR code.',
    set2fa_show_code: 'Show application code',
    set2fa_code: 'Application code',
    set2fa_reset_code: 'Reset code',
    user_edit_2fa_btn: '2FA settings',
    user_2fa_disable_title: 'Disable 2FA',
    user_2fa_disable_msg: 'You are going to disable 2FA for user \'{val}\'. Are You sure?',
    prop_calc_btn: 'Get HASH',
    user_2fa_col: '2FA',
    tab_menu_export: 'Export',
    tab_menu_export_xslx: 'Export to XLSX',
    tab_menu_export_csv: 'Export to CSV',
    tab_menu_export_json: 'Export to JSON',
    error_info_ttl: 'Error info',
    conf_err_info_title: 'Error info',
    conf_err_info_msg: 'Error code',
    admin_tokens_ttl: 'API Tokens',
    token_list_title: 'API Tokens',
    token_descr_col: 'Description',
    token_date_col: 'Create date',
    token_add_btn: 'Generate API token',
    add_token_title: 'Generate API token',
    add_token_msg: 'Token description',
    add_token_done_title: 'New API token',
    add_token_done_msg1: 'Copy and store the token in your application.',
    add_token_done_msg2: 'The token will be never shown again.',
    add_token_done_msg3: 'The token description should be stored too, as a way to identify the token in the list.',
    token_remove_title: 'Delete API token',
    token_remove_msg: 'You are going to delete API token. Are You sure?',
    set_pass_title: 'Set password',
    set_pass_admin_title: 'Set password for user \'{val}\'',
    set_pass_old: 'Current password',
    set_pass_new1: 'New password',
    set_pass_new2: 'New password again',
    set_pass_token_msg: 'All API tokens of the user will be deleted.',
    wrong_pass: 'Passwords do NOT match',
    login_ya_btn: 'Yandex login',
    login_ggl_btn: 'Google login',
    login_sso: 'or Sign In with',
    login_conn_ttl: 'Login to connect SSO account',
    logout_btn: 'Logout',
    set_update_ttl: 'Update',
    conf_up_list_title: 'Update',
    conf_up_list_msg: 'Choose Update package',
    conf_up_rez_title: 'Update',
    conf_up_done_msg: 'Update package was installed',
    conf_up_not_msg: 'Update package failed',
    otp_title: 'One-Time Password',
    otp_msg: 'Enter one-time password from your Authenticator application',
    token_delete: 'Delete API token',
    error_prop_exist_used_msg: 'The property with the same name and different type already exist and used in the system',
    warning_prop_exist_msg: 'The property with the same name and different type already exist. You are going to update the existing definition. Are You sure?',
    warning_ttl: 'Warning',
    menu_export_AP242: 'Export to AP242 Domain',
    menu_inc_ver: 'Increment version',
    conf_inc_ver_title: 'Increment version',
    conf_inc_ver_msg: 'You are going to increment project version. Are You sure?',
    save_btn: 'Save',
    open_last_title: 'Open project',
    open_last_msg: 'System is going to open the location from your last session',
    prop_in_use: 'property is in use and can\'t be edited',
    conf_del_msg_ch: 'You a going to delete element and all the children. Are You sure?',
    adm_utils_ttl: 'Utilities',
    admin_reconnect_ttl: 'Reinit connection',
    reinit_rez_title: 'Reinit connection',
    reinit_fail_msg: 'EDM server connection failed',
    reinit_ok_msg: 'Connection pool has \'{val}\' servers',
    menu_search_doc_txt: 'Document search by content',
    txt_doc_search_title: 'Document search by content',
    txt_doc_search_pattern: 'Search pattern',
    del_doc_title: 'Delete document',
    del_doc_msg: 'You are going to delete document. Are You sure?',
    del_doc_all: 'Delete all document dependencies',
    prod_material_title: 'Edit material',
    prod_material_msg: 'Material',
    upload_aggr_title: 'Upload data file',
    import_file_type: 'File type',
    search_ttl: 'Search',
    search_material_ttl: 'Search for material',
    propsL_name_col: 'Name',
    propsL_val_col: 'Value',
    notify_list_btn: 'Notifications',
    help_btn: 'Help',
    user_info_btn: 'User info',
    noti_list_title: 'User notifications',
    noti_list_proj: 'Project',
    noti_list_date: 'Data',
    noti_list_user: 'User',
    noti_list_msg: 'Message',
    noti_list_status: 'Status',
    noti_list_goto: 'Open element',
    noti_list_close: 'Set watched',
    txt_doc_search_help: 'Type in one or several words. Wildcards: ?, *. Operators: +, -, \\ . See manual for details.',
    send_user_msg: 'Send personal message',
    add_pmsg_title: 'New message',
    add_pmsg_text: 'Text',
    add_pmsg_users: 'Users',
    send_msg_title: 'Personal message',
    send_msg_msg: 'Message was sent',
    menu_send_msg: 'Send message',
    add_pmsg_node: 'Attached node',
    add_pmsg_doc: 'Attached document',
    add_pmsg_subj: 'Subject',
    noti_list_subj: 'Subject',
    noti_list_replay: 'Reply',
    add_pmsg_type: 'Type',
    add_pmsg_status: 'Status',
    add_pmsg_end_date: 'Deadline',
    proj_url_ttl: 'Project from URL',
    conf_proj_url_title: 'Project info',
    conf_proj_url_msg: 'Project URL',
    empty_msg: '{val}',
    admin_proj_ap242_ttl: 'Create project from AP242 Domain',
    new_proj_src_type_ap242: 'AP242',
    notify_cond_tb: 'Rules',
    notify_pause: 'Cooldown time (minutes)',
    notify_afield: 'Field',
    notify_avalue: 'Value',
    notify_add_rule_btn: 'Add rule',
    notify_arules: 'Rules:',
    notify_rule_alias: 'Alias',
    not_send_msg_msg: 'Your message could not be sent. Triggers and Actions are not correctly set. Contact the project administrator.',
    prop_info_btn: 'Full info',
    rels_list_title: 'Links',
    prop_rel_btn: 'Go to node',
    struct_in_use: 'struct is in use and can\'t be edited',
    menu_search_doc_quick: 'Quick document search',
    quick_doc_search_title: 'Quick document search',
    rename_list_title: 'Rename report',
    nodeprop_used_delete_msg: 'You are going delete the USED property. Are You sure?',
    tab_menu_occur: 'Show occurrences',
    tab_menu_no_occur: 'Hide occurrences',
    tab_menu_shape: 'Show shape elements',
    tab_menu_no_shape: 'Hide shape elements',
    prop_move_btn: 'Move property to current type',
    tab_menu_child_occur: 'Show child occurrences',
    tab_menu_no_child_occur: 'Hide child occurrences',
    tab_menu_nav_part: 'Child parts',
    tab_menu_nav_occur: 'Child occurrences',
    tab_menu_nav: 'Navigation mode',
    menu_show_node_graph: 'Open connection graph',
    list_gr_rev_order: 'Use reverse order',
    list_gr_avr_vals: 'Show average values',
    del_doc_node_title: 'Delete element',
    del_doc_node_msg: 'You a going to delete element and all the children. Are You sure?',
    del_doc_node_all: 'Delete all content dependencies',
    list_gr_avr_vals_title: 'Show average values',
    list_gr_avr_vals_msg: 'Use quick algorithm',
    date_format_title: 'Date format',
    date_format_date_type: 'Date string',
    date_format_sec_type: 'Number of seconds',
    date_format_msec_type: 'Number of milliseconds',
    date_format_time: 'Include time',
    date_format_msec: 'Include milliseconds',
    date_format_delim: 'with delimiter',
    prop_value_children_col: 'Allowed children',
    create_elem_ch_title: 'Add reference data item',
    edit_elem_ch_title: 'Edit reference data item',
    create_elem_ch_name: 'Item name',
    create_elem_ch_children: 'Allowed children',
    hinder_list_title: 'Hinder list',
    hinder_name_col: 'Title',
    hinder_role_col: 'Role',
    hinder_del_btn: 'Delete with references',
    hinder_cancel_btn: 'Cancel',
    hinder_goto_btn: 'Go to file',
    hinder_path_col: 'Path',
    copy_node_title: 'Copy node',
    copy_node_msg: 'Operation time limit (sec)',
    refdata_def_title: 'Default value',
    refdata_def_msg: 'You are going to set \'{val}\' as default value. Are You sure?',
    prop_defP_btn: 'Set default',
    list_gr_dt_table: 'Table',
    list_gr_dt_graph: 'Graph',
    import_file_pack: 'File pack',
    menu_importTxt: 'Import from text file',
    list_gr_gr_size: 'Page size',
    noti_list_issue: 'Add issue',
    noti_list_back: 'Go back',
    noti_list_action: 'Add action',
    menu_export_to_pdm: 'Export to STEP PDM file',
    menu_export_to_ap242: 'Export to AP242 Domain',
    issue_list_btn: 'Issues',
    menu_add_issue: 'Add issue',
    add_issue_title: 'New issue',
    add_issue_type: 'Type',
    add_issue_name: 'Title',
    add_issue_status: 'Status',
    add_issue_prior: 'Priority',
    add_issue_subj: 'Subject',
    add_issue_resp: 'Responsible',
    add_issue_descr: 'Description',
    add_issue_end_date: 'Deadline',
    add_issue_title: 'New issue',
    add_issue_msg: 'Issue was created',
    issue_list_title: 'Issue list',
    menu_issue_open: 'Open issue',
    menu_issue_subj: 'Go to subject',
    add_issue_prn: 'Master issue',
    edit_issue_title: '\'{val}\' issue',
    edit_issue_msg: 'Issue was changed',
    add_issue_open_issue: 'Open issue',
    add_issue_children: 'Subordinate issues',
    menu_issue_delete: 'Delete issue',
    menu_issue_add: 'Add subordinate issue',
    conf_del_issue_title: 'Delete issue',
    conf_del_issue_msg: 'You are going to delete issue \'{val}\'. Are You sure?',
    issue_list_add_btn: 'Add issue',
    add_issue_main_tb: 'General',
    add_issue_comm_tb: 'Comments',
    add_issue_domain: 'Domain',
    bsl_add_issue: 'Add issue',
    prop_value_prn_col: 'Allowed for',
    add_issue_person_tb: 'Persons',
    add_issue_link_tb: 'Links',
    add_issue_link_role: 'Role',
    add_issue_link_issue: 'Issue',
    add_issue_del_link: 'Delete link',
    menu_export_DDI: 'Export to DDI spreadsheet',
    bsl_export_ddi: 'Export to DDI spreadsheet'
}
