<template>
   
        <div>
            <create-prop-view ref="createPropDlg"></create-prop-view>
            <create-dprop-view ref="createDPropDlg"></create-dprop-view>
            <create-rrole-view ref="createRelRoleDlg"></create-rrole-view>
            <date-format-view ref="dateFormatDlg"></date-format-view>
            <create-elem-achildren-view ref="createElemWithAChildrenDlg"></create-elem-achildren-view>
            
            <tool-bar-view type="props"></tool-bar-view> 
            
            <v-main class="main_correction">
                <v-container fill-height fluid v-show="globalState.curFolderName" style="padding-top: 0px; padding-bottom: 24px; margin-top: -5px;">
                 <v-layout row wrap>
                  <v-flex v-show="$vuetify.breakpoint.mdAndUp" lg12 md12>
                      <path-el-view no-save="true"></path-el-view>
                  </v-flex>

                  <Split :gutterSize="5" style="height: calc(100vh - 120px);">
                    <SplitArea :size="30">
                      <v-card height="95%">
                          <v-tabs>
                            <v-tab href="#tab-1">
                                {{ $t( 'message.prop_list_tab' ) }}
                            </v-tab>
                            <v-tab-item value="tab-1">
                                <prop-list-view @selectProp="selectProp" :selProp="selProp"></prop-list-view>
                            </v-tab-item>
                          </v-tabs>
                      </v-card>
                    </SplitArea>  

                    <SplitArea :size="70">
                        <v-card height="95%">
                            <v-tabs>
                                <v-tab href="#tab-1">
                                    {{ $t( 'message.prop_info_tab' ) }}
                                </v-tab>
                                <v-tab-item value="tab-1">
                                    <prop-info-view :prop="selProp" v-if="!selProp.type"></prop-info-view>
                                    <prop-info-with-children-view :prop="selProp" v-else-if="selProp.type === 'prop_type_with_children'"></prop-info-with-children-view>
                                    <prop-info-in-prn-view :prop="selProp" v-else-if="selProp.type === 'prop_type_in_prn'"></prop-info-in-prn-view>
                                    <prop-info-node-view :prop="selProp" 
                                         v-else-if="selProp.type === 'prop_type' || selProp.type === 'struct' || selProp.type === 'prod'"></prop-info-node-view>
                                    <prop-info-rels-view :prop="selProp" v-else-if="selProp.type === 'rel_type'"></prop-info-rels-view>
                                    <prop-tree-info-node-view :prop="selProp" v-else-if="selProp.type === 'node_type'"></prop-tree-info-node-view>
                                </v-tab-item>
                            </v-tabs>
                        </v-card>
                    </SplitArea>
                  </Split>
                </v-layout>
              </v-container>
          </v-main>
        </div>
   
</template>

<script>
    import PathEl from '../Path.vue'
    import PropList from './PropList.vue'
    import PropInfo from './PropInfo.vue'
    import PropInfoWithChildren from './PropInfoWithChildren.vue'
    import PropInfoInPrn from './PropInfoInPrn.vue'
    import PropTreeInfo from './PropTreeInfo.vue'
    import PropInfoNode from './PropInfoNode.vue'
    import PropInfoRels from './PropInfoRels.vue'

    import CreateElemWithAChildren from '@/components/Dlg/CreateElemWithAChildren.vue'
    import CreateProp from '@/components/Dlg/CreateProp.vue'
    import CreateDProp from '@/components/Dlg/CreateDProp.vue'
    import CreateRelRole from '@/components/Dlg/CreateRelRole.vue'
    import DateFormat from '@/components/Dlg/DateFormat.vue'
    
    import ToolBar from '@/components/ToolBar.vue'
    
    import{ conf } from '@/utils/confTruePLM';
    import { auth } from '@/utils/auth';
    import router from '@/router';
    import { Split, SplitArea } from 'vue-split-panel'
    
export default {
  name: 'props',
  data () 
  {
    return {
        globalState: this.$globalStore.state,
        selProp: {},
        prnType: {}
    };
  },
  methods: {
      selectProp( prop )
      {
          this.selProp = prop;
          this.$eventBus.$emit( 'setPath', [ { alt_name: 'Reference data' }, { alt_name: prop.name } ] );
      },
      selectProj( proj, role )
      {
          if( role )
          {
              conf.setProj( proj, role );
              localStorage.setItem( 'proj_model_id', proj.in_project.project_model_id );
              this.$eventBus.$emit( 'selectedModel' );
              this.$eventBus.$emit( 'setPath', [ { alt_name: 'Reference data' } ] );
              this.$globalStore.setCurFolderInfo( proj.in_project ); //.name );
          }
      },
      updateProjList( info )
      {
            var selProj = null;
            var self = this;
            conf.getRepositoryList()
                    .then( ( data )=>
            {
                for( var i = 0; data && i < data.length; i++ )
                {
                    var proj = data[ i ];
                    if( proj.is_active_in_project && proj.user_registered_as.length > 0 )
                    {
                        if( info && proj.in_project.project_model_id === info.project_model_id )
                        {
                            selProj = proj;
                            break;
                        }
                    }
                }
                
                if( selProj )
                {
                    let selRole = null;
                    let prevRole = localStorage.getItem( 'proj_role' );
                    if( localStorage.getItem( 'proj_model_id' ) === info.project_model_id )
                    {
                        for( var i = 0; selProj.user_registered_as && i < selProj.user_registered_as.length; i++ )
                        {
                            if( selProj.user_registered_as[ i ] === prevRole )
                            {
                                selRole = prevRole;
                                break;
                            }
                        }
                    }
                    if( selRole )
                        self.selectProj( selProj, selRole, true ); 
                }

            }, ( err )=>{ self.$eventBus.$emit( 'queryError', err ); } );
      },    
      onClosePage()
      {
          localStorage.setItem( 'closeTime', Date.now() );
      }
    },
    created() 
    {    
        window.addEventListener( 'beforeunload', this.onClosePage );  
    },
  components: {
    'path-el-view': PathEl,
    'prop-list-view': PropList,
    'prop-info-view': PropInfo,
    'prop-info-with-children-view': PropInfoWithChildren,
    'prop-info-in-prn-view': PropInfoInPrn,
    'prop-tree-info-node-view': PropTreeInfo,
    'prop-info-node-view': PropInfoNode,
    'prop-info-rels-view': PropInfoRels,
    'create-prop-view': CreateProp,
    'create-elem-achildren-view': CreateElemWithAChildren,
    'create-dprop-view': CreateDProp,
    'create-rrole-view': CreateRelRole,
    'date-format-view': DateFormat,
    
    'tool-bar-view': ToolBar,
    
    'Split' : Split,
    'SplitArea': SplitArea
  },
  beforeMount: function()
  {
      auth.checkAuth();
      if( ! auth.getUser().authenticated )
          router.push( '/login' );
      
      if( !conf.getProj() )
          router.push( '/' );
      
      if( this.$globalStore.state.curUserRole !== 'urn:rdl:epm-std:project_manager'
              && this.$globalStore.state.curUserRole !== 'urn:rdl:epm-std:project_admin'
              && this.$globalStore.state.curUserRole !== 'urn:rdl:epm-std:rdl_manager' )
          router.push( '/home' );
  },
  mounted: function()
  {
      this.$modalDlg.sysDlgs[ 'dateFormatDlg' ] = this.$refs.dateFormatDlg;
      this.$modalDlg.sysDlgs[ 'createPropDlg' ] = this.$refs.createPropDlg;
      this.$modalDlg.sysDlgs[ 'createDPropDlg' ] = this.$refs.createDPropDlg;
      this.$modalDlg.sysDlgs[ 'createRelRoleDlg' ] = this.$refs.createRelRoleDlg;
      this.$modalDlg.sysDlgs[ 'createElemWithAChildrenDlg' ] = this.$refs.createElemWithAChildrenDlg;
      
      if(  auth.getUser().authenticated )
      {
          var projModelID = localStorage.getItem( 'proj_model_id' );
          if( projModelID )
              this.updateProjList( { project_model_id: projModelID } );        
          else
              this.updateProjList();        
      }
  },
  beforeDestroy: function()
  {
  }
}
</script>

<style>
    .main_correction > div.v-main__wrap
    {
        height: 90%;            
    }
    
    .close-menu ul
    {
        min-width: 2em;
        white-space: nowrap;
    }
    
    .multipane.cust.layout-v .multipane-resizer 
    {
        margin: 0; left: 0; /* reset default styling */
        width: 5px;
        background: graytext;
    }
</style>
