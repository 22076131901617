<template>
    <v-card>
      <v-data-table dense :headers="headers" :items="propVals" sort-by="num" hide-default-footer class="elevation-1 propTbl" 
                    :items-per-page=-1 fixed-header :height="wHi" group-by="groupData">
          <template v-slot:header.num="{ header }">
            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
          </template>
          <template v-slot:header.name="{ header }">
            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
          </template>
          <template v-slot:header.val="{ header }">
            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
          </template>
          <template v-slot:header.type="{ header }">
            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
          </template>
          <template v-slot:header.act="{ header }">
            <span>
              <v-tooltip bottom v-show="aProps.length > 0">
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on" @click="addItem()">add</v-icon>
                </template>
                <span>{{ $t( 'message.prop_add_btn' ) }}</span>
              </v-tooltip>
              <v-tooltip bottom v-show="hasChanges">
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on" @click="saveChanges()">archive</v-icon>
                </template>
                <span>{{ $t( 'message.prop_save_btn' ) }}</span>
              </v-tooltip>
            </span>
          </template>
          <template v-slot:group.header="{ items, isOpen, toggle }">
            <th colspan="7" v-if="items[ 0 ].groupInfo && ( !isOpen || !items[ 0 ].cols )">
              <v-icon @click="toggle">
                  {{ isOpen ? 'remove' : 'add' }}
              </v-icon>
              {{ items[0].groupInfo }}
            </th>
            <th v-if="items[ 0 ].groupInfo && isOpen && items[ 0 ].cols">
              <v-icon @click="toggle">
                  {{ isOpen ? 'remove' : 'add' }}
              </v-icon>
              {{ items[0].groupInfo }}
            </th>
            <th v-if="items[ 0 ].groupInfo && isOpen && items[ 0 ].cols">
              {{ items[0].cols[ 0 ] }}
            </th>
             <th v-if="items[ 0 ].groupInfo && isOpen && items[ 0 ].cols">
              {{ items[ 0 ].cols.length > 1 ? items[0].cols[ 1 ] : '' }}
            </th>
            <th v-if="items[ 0 ].groupInfo && isOpen && items[ 0 ].cols">
              {{ items[ 0 ].cols.length > 2 ? items[0].cols[ 2 ] : '' }}
            </th>
            <th v-if="items[ 0 ].groupInfo && isOpen && items[ 0 ].cols">
                <span v-if="items[ 0 ].groupDataVal === 'orgs'">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" small @click="openOrgs()">info_outline</v-icon>
                        </template>
                        <span>{{ $t( 'message.prop_info_btn' ) }}</span>
                    </v-tooltip>
                </span>
                <span v-if="items[ 0 ].groupDataVal === 'rels'">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" small @click="openRels()">info_outline</v-icon>
                        </template>
                        <span>{{ $t( 'message.prop_info_btn' ) }}</span>
                    </v-tooltip>
                </span>
                <span v-else></span>
            </th>
          </template>
          <template v-slot:item="{ item }">
            <tr v-if="item.groupDataVal === 'parents'">
                <td>{{ item.num }}</td>
                <td colspan="3">
                    <span>{{ item.val }}</span>
                </td>
                <td>
                    <v-tooltip bottom v-if="item.obj.node_id !== globalState.curFolderInfo.instance_id">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" small @click="gotoParent( item.obj )">visibility</v-icon>
                        </template>
                        <span>{{ $t( 'message.prop_rel_btn' ) }}</span>
                    </v-tooltip>
                </td>
            </tr>
            <tr v-else>
              <td>{{ item.num }}</td>
              <td :class="{ mandatory_field: item.isMandatory }">
                  <span :class="{ deleted_prop: item.deleted }">{{ item.name }}</span> 
                  <span v-if="item.unit">({{ item.unit }})</span>
              </td>
              <td>
                  <span v-if="item.url_data">
                      <span v-for="data in item.url_data">
                          <a v-if="data.isURL" :href="data.val" target="_blank" rel="noopener noreferrer" >{{ data.val }}</a>
                      </span>
                  </span>
                  <span v-else-if="item.isList"><a @click="openListGr( item )">{{ item.val_view }}</a></span>
                  <rels-row-view v-else-if="item.val && ( item.chRels || item.prRels )" 
                           :prRels="item.prRels" :chRels="item.chRels" @openRels="openRels"></rels-row-view>
                  <span v-else-if="item.val && item.val.length && item.val.length > 0 && item.val[ 0 ].ass_id">
                      <a @click="openOrgs( item.val )">{{ item.val.length }} elements</a>
                  </span>
                  <span v-else-if="!item.val_view && item.isMandatory" class="mandatory_value"></span>
                  <span v-else-if="item.name === 'Material'"><a @click="openMaterial( item )">{{ item.val_view }}</a></span>
                  <span v-else>{{ item.val_view }}</span>
              </td>
              <td>{{ item.type_view }}</td>
              <td v-if="( !globalState.actVer && ( item.edit || item.msg || item.class || item.groupData ) ) || item.name === 'Hash'">
                  <span style="white-space: nowrap;">
                    <v-tooltip bottom v-if="item.isList && model.permissions && model.permissions.can_i_write">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon small class="mr-2" @click="uploadData( item )" v-bind="attrs" v-on="on">vertical_align_top</v-icon>
                        </template>
                        <span>{{ $t( 'message.prop_upload_btn' ) }}</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="item.isList">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon small class="mr-2" @click="downloadData( item )" v-bind="attrs" v-on="on">vertical_align_bottom</v-icon>
                        </template>
                        <span>{{ $t( 'message.prop_download_btn' ) }}</span>
                    </v-tooltip>
                    <v-tooltip bottom v-else>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-show="model.permissions && model.permissions.can_i_write && item.name !== 'Hash' && !item.groupData" 
                                    small class="mr-2" @click="editItem(item)" v-bind="attrs" v-on="on">edit</v-icon>
                        </template>
                        <span>{{ $t( 'message.prop_edit_btn' ) }}</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-if="item.name !== 'Quantity' && !item.msg && !item.class && !item.groupData
                                    && item.val !== undefined && model.permissions && model.permissions.can_i_write" small 
                                    @click="deleteItem(item)" v-bind="attrs" v-on="on">clear</v-icon>
                        </template>
                        <span>{{ $t( 'message.prop_clear_btn' ) }}</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-if="item.name === 'Hash'" v-bind="attrs" v-on="on" small @click="calcHash()">restore</v-icon>
                        </template>
                        <span>{{ $t( 'message.prop_calc_btn' ) }}</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="item.groupDataVal === 'rels'">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" small @click="gotoRel( item.obj )">visibility</v-icon>
                        </template>
                        <span>{{ $t( 'message.prop_rel_btn' ) }}</span>
                    </v-tooltip>
                  </span>
              </td>
<!--              <td v-else-if="!globalState.actVer && item.isRel">
                 <span style="white-space: nowrap;">
                     <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon small v-on="on" @click="gotoRel( item.val )">visibility</v-icon>
                            </template>
                            <span>
                                {{$t( 'message.rel_show' )}}
                            </span>
                      </v-tooltip>
                     <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon small v-on="on" @click="editRel( item.val )">edit</v-icon>
                            </template>
                            <span>{{ $t( 'message.rels_edit_btn' ) }}</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon small v-on="on" @click="deleteRel( item.val )">delete_forever</v-icon>
                        </template>
                        <span>
                            {{$t( 'message.rels_delete_btn' )}}
                        </span>
                    </v-tooltip>
                 </span>
              </td>-->
              <td v-else>
              </td>
            </tr>
        </template>
      </v-data-table>
<!--        <data-tbl :header="headers">
            <template v-slot:headerCell="props">
                <span v-if="props.header.text">
                    {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${props.header.text}`) } ) }}
                </span>
                <span v-else>
                  <v-tooltip bottom v-show="aProps.length > 0">
                    <v-icon slot="activator" @click="addItem()">add</v-icon>
                    <span>{{ $t( 'message.prop_add_btn' ) }}</span>
                  </v-tooltip>
                  <v-tooltip bottom v-show="hasChanges">
                    <v-icon slot="activator" @click="saveChanges()">archive</v-icon>
                    <span>{{ $t( 'message.prop_save_btn' ) }}</span>
                  </v-tooltip>
                </span>
            </template>
        </data-tbl>-->
    </v-card>
</template>

<script>
import{ conf } from '../utils/confTruePLM';
import DataTable from './DataTable';
import RelsRow from './Elems/RelsRow'

export default {
  name: 'PnlProps',
  props: {
      forSys: false,
      smallWin: false,
      elemID: null
  },
  data () {
    return {
        globalState: this.$globalStore.state,
        headers: [
            { text: 'message.prop_num_col', value: 'num', width: '15%', align: 'right' },
            { text: 'message.prop_name_col', value: 'name', width: '20%' },
            { text: 'message.prop_value_col', value: 'val' }, 
            { text: 'message.prop_type_col', value: 'type', width: '15%' }, 
            { width: '5%', sortable: false, val: 'act' }
        ],
        propVals : [],
        aProps: [],
        model: {},
        hasChanges: false,
        propList: [ { title: 'Name', value: 'name', msg: 'renameNode' }, 
                    { title: 'Type', value: 'element_type', msg: 'editNode' },
                    { title: 'Description', value: 'description', msg: 'editNode', parse: true },
//                    { title: 'ID', value: 'id' },
                    { title: 'Created by', value: 'created_by_user' },
                    { title: 'Created date', value: 'date_created' },
                    { title: 'Last modified by', value: 'last_modified_by_user' },
                    { title: 'Last modified date', value: 'date_last_modified' },
//                    { title: 'Phase', value: 'phase' },
//                    { title: 'Version', value: 'version_id' },
//                    { title: 'Links', value: 'rels' },
//                    { title: 'Organisations', value: 'orgs' },
                 //   { title: 'Hash', value: 'hash' }
                  ]
    };
  },
  methods: 
  {
      editRel( rel )
      {
          this.$eventBus.$emit( 'editRel', { node: this.model, rel: rel } );
      },
      deleteRel( rel )
      {
          this.$eventBus.$emit( 'deleteRel', rel );
      },
//      isAssignedProp( prop )
//      {
////          console.log( prop );
////          console.log( this.propVals );
//          for( var j = 0; j < this.propVals.length; j++ )
//          {
//              if( this.propVals[ j ].obj 
//                      && this.$utils.noGUID( this.propVals[ j ].obj.name ) === prop.uri )
//                  return true;
//          }
//          
//          return false;
//      },
              
//      addItem()
//      {
//          var self = this;
//          this.$modalDlg.sysDlgs.assignPropDlg.open( null, self.aProps )
//                  .then( ( rez ) =>
//          {
//              var prop = { name: self.$utils.clearType( rez.type ), type: rez.type, unit_code: rez.unit,
//                      val: rez.val, unit: self.$utils.clearType( rez.unit ), obj: {} };
//              
//              self.$eventBus.$emit( 'updateProps', { node: self.model, props: [ prop ] } ); 
////              console.log( rez );
////              self.hasChanges = true;
//          });
//      },
      openListGr( item )
      {
          let proj = conf.getProj();
          let url = '#/listgr?';
          let propUrn = encodeURIComponent( item.obj.urn || item.type );
          
          window.open( url + '&rep=' + proj.repository + '&model=' + proj.in_project.project_model_id 
                  + '&node=' + this.model.instance_id + '&prop=' + propUrn + '&role=' + proj.userType, '_blank' );
      },   
      openMaterial( item )
      {
          let key = item.type + ' ref.';
          let ref = null;
          for( let i = 0; i < this.model.properties.length; i++ )
          {
              if( this.model.properties[ i ].name === key )
              {
                  ref = this.model.properties[ i ].val;
                  break;
              }
          }
     
        //  console.log( ref );
          this.$modalDlg.sysDlgs.propsListDlg.open( item.val_view, { rep: 'TruePLMprojectsRep', ref: ref } );
      },
      setMaterial( item )
      {
          let self = this;
          let model = 'Material_Catalogue';
          this.$modalDlg.sysDlgs.searchOneValDlg.open( 'search_material_ttl', null, { rep: 'TruePLMprojectsRep', model: model } )
                    .then( rez =>
          {
//              console.log( rez );
              item.val = rez.elem.bkdn_elem_info.name;
              let item2 = JSON.parse( JSON.stringify( item ) );
              item2.type += ' ref.';
              item2.val = model + '.' + rez.elem.bkdn_elem_info.id;//rez.elem.path.join( '/' );
              self.$eventBus.$emit( 'updateProps', { node: self.model, props: [ item, item2 ], isClass: item.class || item.class_name } );  
          }, () => {} );
      },
      editItem( item )
      {
          if( item.msg )
          {
              this.$eventBus.$emit( 'procRowMenuCmd', [ item.msg , this.model ] );    
          }
          else
          {
              let self = this;
              let propList = [];
//              if( item.name === 'Material' )
//              {
//                  this.setMaterial( item );
//              }
//              else 
                  if( item.class )
              {
                  let opts = [];
                  conf.getChildTypes( item.class )
                            .then( ( data ) =>
                  {
                        self.$utils.getEnum( data ).forEach( ( opt ) =>
                        {
                            opts.push( opt.val );
                        });
                        item.obj = { type: item.class, domain: 'urn:owl:oneOf', options: opts };
                        self.openUpdateProp( item, propList );
                  })
                        .catch( ( err ) =>
                  {
                    self.$eventBus.$emit( 'queryError', err );
                  });      
                  item.type = item.class;
              }
              else
              {
                  conf.getChildTypes( this.model.element_type )
                            .then( ( data ) =>
                  {
                        self.$utils.getEnum( data ).forEach( ( opt ) =>
                        {
                            propList.push( opt.val );
                        });
                        self.openUpdateProp( item, propList );
                  })
                        .catch( ( err ) =>
                  {
                    self.$eventBus.$emit( 'queryError', err );
                  });      
              }
          }
      },
      openUpdateProp( item, propList )
      {
          let self = this;
          this.$modalDlg.sysDlgs.assignPropDlg.open( item, propList )
                    .then( ( rez ) =>
          {
              self.$eventBus.$emit( 'updateProps', { node: self.model, props: [ item ], isClass: item.class || item.class_name } ); 
          }, () => {} );
      },
      uploadData( item )
      {
          this.$eventBus.$emit( 'uploadAggrPropData', { node: this.model, prop: item } );
      },
      
      downloadData( item )
      {
          this.$eventBus.$emit( 'downloadAggrPropData', { node: this.model, prop: item } );
      },
      
      deleteItem( item )
      {
          var self = this;
          this.$modalDlg.sysDlgs.confirmDlg.open( 'prop_clear_title', 'prop_clear_msg' )
                  .then( () =>
          {
              item.delete = true;
              self.$eventBus.$emit( 'updateProps', { node: self.model, props: [ item ] } ); 
//              self.hasChanges = true;
          }, () => {} );
      },
      
//      saveChanges()
//      {
//          this.$eventBus.$emit( 'updateProps', { node: this.model, props: this.propVals } ); 
//      },
      openRels( type )
      {
          let self = this;
          this.$modalDlg.sysDlgs.relsListDlg.open( this.model, type )
                  .then( ( rel ) =>
          {
              if( rel && rel.related )
              {
                  conf.getNodePath( rel.related.instance_id )
                          .then( ( rez ) =>
                  {
                      if( rez && rez.tree_path_to_element )
                         self.$eventBus.$emit( 'procRowMenuCmd', [ 'gotoNode', rez.tree_path_to_element ] ); 
                  })
                        .catch( ( err ) =>
                  {
                    self.$eventBus.$emit( 'queryError', err );
                  });      
              }
              else
                  self.$eventBus.$emit( 'updateCurFolder' );
          });
      },
      gotoRel( rel )
      {
          let self = this;
          conf.getNodePath( rel.related.instance_id )
                  .then( ( rez ) =>
          {
              if( rez && rez.tree_path_to_element )
                 self.$eventBus.$emit( 'procRowMenuCmd', [ 'gotoNode', rez.tree_path_to_element ] ); 
          }); 
      },
      gotoParent( prn )
      {
          this.$eventBus.$emit( 'procRowMenuCmd', [ 'gotoNode', prn.paths[ 0 ].ids ] );
      },
//      openOrg( item )
//      {
//          let self = this;
//          this.$modalDlg.sysDlgs.orgsListDlg.open( this.model )
//                  .then( () =>
//          {
//              self.$eventBus.$emit( 'updateCurFolder' );
//          });
//      },
      
      openOrgs()
      {
          let self = this;
          this.$modalDlg.sysDlgs.orgsListDlg.open( this.model )
                  .then( () =>
          {
              self.$eventBus.$emit( 'updateCurFolder' );
          });
      },
      
      calcHash()
      {
          conf.calcHash( this.model )
                  .then( rez =>
          {
              console.log( rez );
          });
          
      },
      
      procModel()
      {
          let self = this;
          let ind = 1;
          let emptyFields = [];
          self.propVals = [];
          self.aProps = [];
          if( this.forSys )
          {
                let tView = 'T';
                self.propList.forEach( ( item ) =>
                {
                    let chRels = 0, prRels = 0;
                    let urlData = null;
                    let val = self.model[ item.value ];
 
                    if( item.value === 'orgs' )
                    {
                        if( !val )
                            val = 'no elements';
                    }
                    else if( item.value === 'rels' )
                    {
                        if( !val )
                            val = 'no elements';
                        else
                        {
                            val.forEach( r => 
                            {
                               if( r.parent_child === 'CHILD' )
                                   chRels++;
                               else if( r.parent_child === 'PARENT' )
                                   prRels++;
                            });
                        }
                    }
                    else if( item.value === 'name' || item.value === 'description' )
                        val = self.model[ item.value ];
                    else if( item.value.indexOf( 'date' ) )
                        val = self.$utils.clearType( self.model[ item.value ] );
                    else if( val )
                        val = this.$utils.getLocalDateFromUTC( val );
                    
                    if( item.parse )
                        urlData = this.$utils.getURLData( val );
                    
                    let msg = item.msg;
                    if( item.value === 'element_type' && val === 'product_list' )
                        msg = null;
                    
                    if( item.title === 'Type' && this.model.is_part )
                        msg = null;
                    self.propVals.push( { num: ind++, name: item.title, val: val, val_view: val, prRels: prRels, chRels: chRels,
                        msg: msg, class: item.class, url_data: urlData, type_view: tView } );
                });
                
                let aDataInfo = [ { aData: 'ids', title: 'Identifiers', nameFld: 'role_name', valFld: 'id', 
                                            addValFld: 'org_context', cols: [ 'Role', 'ID', 'Context' ] },
                                  { aData: 'classified_as', title: 'Classified by', nameFld: 'role_name', 
                                            valFld: 'class_name', cols: [ 'Role', 'Class' ] },
                                  { aData: 'described_as', title: 'Described as', nameFld: 'role_name', 
                                            valFld: 'text', cols: [ 'Role', 'Text' ] },
                                  { aData: 'datetimes', title: 'Timestamps', nameFld: 'role_name', 
                                            valFld: 'dt_text', cols: [ 'Role', 'Date' ] },
                                  { aData: 'persons', title: 'Persons', nameFld: 'role_name', valFld: 'person',
                                      addValFld: 'org', cols: [ 'Role', 'Name', 'Organisation' ] },
                                  
                                  { aData: 'rels', title: 'Links', nameFld: 'caption', addValFld: 'parent_child',
                                            valFld: 'related.name', cols: [ 'Role', 'Node', 'Type' ] },
                                  { aData: 'parents', title: 'Parents', valFld: 'node_name', cols: [ 'Path' ] },
                                  { aData: 'orgs', title: 'Organisations', nameFld: 'caption', 
                                            valFld: 'organization.title', cols: [ 'Role', 'Organisation' ] }];
                ind = 0;
                aDataInfo.forEach( info => 
                {
                    if( this.model[ info.aData ] )
                    {
                        ind++;
                        this.model[ info.aData ].forEach( el =>
                        {
                            let val = this.$utils.getValByPath( el, info.valFld );
                            if( info.aData === 'datetimes' )
                                val = this.$utils.getLocalDateFromUTC( val );
                            else if( info.aData === 'parents' && el.paths[ 0 ] )
                                val = el.paths[ 0 ].names.join( ' / ' );
                            
                            let addVal = this.$utils.getValByPath( el, info.addValFld );
                            if( info.aData === 'rels' )
                                addVal = ( addVal === 'CHILD' ) ? 'direct' : 'backward';
                            
                            self.propVals.push( { groupData: ind + '_' + info.aData, groupInfo: info.title, 
                                name: this.$utils.getValByPath( el, info.nameFld ), obj: el, groupDataVal: info.aData,
                                val_view: val, val: val, type_view: addVal, type: addVal, cols: info.cols } );
                        });
                    }
                } );
          }

          if( !this.forSys  && ( this.model.parent || this.model.isSearch ) )
          {
                let usedProps = [];
                for( let i = 0; self.model.properties && i < self.model.properties.length; i++ )
                    usedProps.push( i );
                
                let typePart = this.$utils.noGUID( this.model.element_type );
                conf.getPropsByName( typePart )
                        .then( ( fullPList ) =>
                {
                      for( var i = 0; i < fullPList.length; i++ )
                      {
                          let isList = false;
                          let item = fullPList[ i ];
                          let name = self.$utils.clearType( item.urn );

                          if( "NameInList" === name )
                              continue;

                          let item_val = {};
                          if( self.model.properties )
                          {
                              for( var j = 0; j < self.model.properties.length; j++ )
                              {
                                  if( this.$utils.noGUID( self.model.properties[ j ].name ) === item.urn )
                                  {
                                      item_val = self.model.properties[ j ];
                                      usedProps[ j ] = -1;
                                      break;
                                  }
                              }
                          }

                          let tView = this.$utils.getPropType( item.domains ) || 'T';

                          let pVal = this.$utils.procPropVal( item_val.val, tView );

                          let urlData = null;
                      
                          if( pVal && tView === 'N' )
                          {
                              item_val.val = item_val.num_val;
                              pVal = item_val.num_val.toString();
                          }
                          else if( tView === 'A' )
                          {
                              isList = true;
                          }
                          else
                            urlData = this.$utils.getURLData( pVal );    

                          if( this.$utils.isDerivedProp( item.super_types ) )
                              tView = 'D' + tView;

                          let propObj = { num: ind++, name: name, type: item.urn, unit_code: item_val.unit, type_view: tView,
                                  val_view: pVal, val: item_val.val, unit: self.$utils.clearType( item_val.unit ), url_data: urlData, isList: isList,
                                  edit: ( !item.expression && ( !( item.editable_for && item.editable_for.length > 0 ) || 
                                            this.globalState.curUserRole === 'urn:rdl:epm-std:project_manager' ) ), 
                                  obj: item, isMandatory: !item.optional_flag };

                          if( propObj.isMandatory && !propObj.val_view )
                              emptyFields.push( propObj.name );

                          self.propVals.push( propObj );      
                      }
                      
                      if( self.$globalStore.state.actVer )
                      {
                          usedProps.forEach( i =>
                          {
                              if( i > -1 )
                              {
                                  let prop = self.model.properties[ i ];
                                  let tView = this.$utils.getPropType( ( prop.value_domain || '' ).split( ',' ) ) || 'T';
                                  let pVal = this.$utils.procPropVal( prop.val, tView );

                                  let urlData = null;
                                  let isList = false;

                                  if( pVal && tView === 'N' )
                                  {
                                        prop.val = prop.num_val;
                                        pVal = prop.num_val.toString();
                                  }
                                  else if( tView === 'A' )
                                  {
                                        isList = true;
                                  }
                                  else
                                    urlData = this.$utils.getURLData( pVal );    

                                  if( prop.is_derived )
                                      tView = 'D' + tView;
                          
                                  let propObj = { num: ind++, name: self.$utils.clearType( prop.name ), type: prop.name, 
                                      unit_code: prop.unit, type_view: tView, val_view: pVal, val: prop.val, 
                                      unit: self.$utils.clearType( prop.unit ), url_data: urlData, isList: isList,
                                      edit: false, obj: {}, isMandatory: false, deleted: true };
                                  self.propVals.push( propObj );    
                              }
                          });
                      }

                      self.$eventBus.$emit( 'propStatus', { emptyFields: emptyFields, node: self.model } );
                 })
                        .catch( ( err ) =>
                 {
                     self.$eventBus.$emit( 'queryError', err );
                 }); 
          }
      }
  },
  components: {
    'data-tbl': DataTable,
    'rels-row-view': RelsRow
  },
  computed:
  {
      wHi()
      {
          if( this.smallWin )
             return "calc( 100vh - 105px )";
          else
             return "calc( ( 100vh - 229px ) / 2 )";
      }    
  },
  mounted: function() 
  { 
      var self = this;
      
      this.$eventBus.$on( 'selectedModel', function()
      {
          self.model = {};
          self.propVals = [];
      });
      this.$eventBus.$on( 'updateNode', function( data )
      {
          self.hasChanges = false;
          self.model = data;

          if( self.model.name )
             self.procModel();
          else
              self.propVals = [];
      });
      this.$eventBus.$on( 'showSelProps', function( data )
      {
          self.hasChanges = false;
          self.model = data;
          
          if( self.model.name )
             self.procModel();
          else
              self.propVals = [];
      });
  },   
  beforeDestroy: function()
  {
      this.$eventBus.$off( 'selectedModel' );
      this.$eventBus.$off( 'updateNode' );  
      this.$eventBus.$off( 'showSelProps' );
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .propTbl td
    {
        height: 30px;
    }        
    
    .propTbl div table.v-table thead tr
    {
        height: 30px;
    }        
</style>
