<template>
   <v-app-bar app fixed clipped-left>
      <v-img :src="logoSrc" max-width="100" max-height="30"></v-img>
      <v-app-bar-nav-icon @click.stop="toggleVis"></v-app-bar-nav-icon>
      <v-icon @click="openHelp()" v-if="$vuetify.breakpoint.mdAndUp">help</v-icon>
      <v-spacer></v-spacer>

      <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" small fab text>{{langInfo.locale}}</v-btn>
          </template>
          <v-list dense>
            <v-list-item v-for="l in langs" :key="l" @click="setLang( l )">
              <v-list-item-title>{{ l }}</v-list-item-title>
            </v-list-item>
          </v-list>
      </v-menu>
  </v-app-bar> 
</template>

<script>
    
export default {
  name: 'admin-tool-bar',
  data () 
  {
      return {
          langInfo: this.$i18n,
          globalState: this.$globalStore.state
      };
  },
  computed: {
      langs() 
      {
           return Object.keys( this.$i18n.messages );
      },
      logoSrc: function()
      { 
           return require( '../../assets/logo_EPM.svg' );  
      }
  },
  methods: {
      openHelp()
      {
          this.$utils.openHelp( 'EDMtruePLM-Administration-GUI' );
      },
      setLang( l )
      {
          this.$i18n.locale = l;
      },
      toggleVis()
      {
          this.$modalDlg.toggleNav();
      }
    },
  components: {
    
  }
}
</script>

<style scoped>
    @-moz-document url-prefix()
    {
        .i_correct > div > i
        {
           padding-top: 10px;
        }
    }
    
    .i_info
    {
        padding-left: 10px;
    }    
</style>




