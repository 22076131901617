<template>
        <div>
            <create-prod-view ref="createProdDlg"></create-prod-view>
            
            <tool-bar-view type="prods"></tool-bar-view>
            
            <v-main class="main_correction">
                <v-container fill-height fluid v-show="globalState.curFolderName" style="padding-top: 0px; padding-bottom: 24px; margin-top: -5px;">
                 <v-layout row wrap>
                  <v-flex v-show="$vuetify.breakpoint.mdAndUp" lg12 md12>
                      <path-el-view no-save="true"></path-el-view>
                  </v-flex>

                  <Split :gutterSize="5" style="height: calc(100vh - 120px);">
                    <SplitArea :size="30">
                      <v-card height="95%">
                          <v-tabs v-model="active" show-arrows ref="tabs">
                            <v-tab href="#tab-1">
                                {{ $t( 'message.prod_list_tab' ) }}
                            </v-tab>
                            <v-tab-item value="tab-1">
                                <prod-list-view :selProd="selProd" :prods="prods" @selectProd="selectProd"></prod-list-view>
                            </v-tab-item>
                          </v-tabs>
                      </v-card>
                    </SplitArea> 

                    <SplitArea :size="70">
                        <v-card height="95%">
                            <v-tabs v-model="active" show-arrows ref="tabs">
                                <v-tab href="#tab-1">
                                    {{ $t( 'message.org_info_tab' ) }}
                                </v-tab>
                                <v-tab-item value="tab-1">
                                    <prod-info-view :prod="selProd" @addProd="addProd"
                                                   @delProd="delProd" ></prod-info-view>
                                </v-tab-item>
                            </v-tabs>
                        </v-card>
                    </SplitArea>
                  </Split>

                </v-layout>
              </v-container>
          </v-main>
        </div>
   
</template>

<script>
    import PathEl from '@/components/Path.vue';
    import CreateProd from '@/components/Dlg/CreateProd.vue';
    
    import ToolBar from '@/components/ToolBar.vue'
    
    import ProdList from './ProdList.vue';
    import ProdInfo from './ProdInfo.vue';
    
    import{ conf } from '@/utils/confTruePLM';
    import { auth } from '@/utils/auth';
    import router from '@/router';
    import { Split, SplitArea } from 'vue-split-panel';
    
export default {
  name: 'Prods',
  data () 
  {
    return {
        active: null,
        globalState: this.$globalStore.state,
        prods: [],
        selProd: {}
    };
  },
  methods: {
      addProd()
      {
          let self = this;
          
          this.$refs.createProdDlg.open()
                .then( ( info ) =>
          {
              return conf.createProd( info, this.$globalStore.setActTimestamp() );
          }, () => {} )
                .then( ( prod ) => 
          {
              if( prod )
              {
                  self.selectProd( prod );
                  self.initData();
              }
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      delProd()
      {
          var self = this;
          this.$modalDlg.sysDlgs.confirmDlg.open( 'prod_del_title', 'prod_del_msg', null, self.selProd.name )
                  .then( () =>
          {
              self.$modalDlg.sysDlgs.progressDlg.open();
              return conf.delProd( self.selProd );
          }, () => {} )
                  .then( () => 
          {
              self.selectProd( {} );
              self.initData();
          })
                  .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      
      selectProd( prod )
      {
          this.$eventBus.$emit( 'setPath', [ { alt_name: 'Products' }, { alt_name: prod.name } ] );
          this.selProd = prod;
      },
      initData()
      {
          let self = this;
          
          self.$modalDlg.sysDlgs.progressDlg.open();
          self.prods = [];
          conf.getProdList()
                .then( ( data ) =>
          {
              self.$utils.sortArray( data, ( p ) => ( p.name || '' ) );
              data.forEach( ( prod ) =>
              {
                  self.prods.push( prod );
                  if( self.selProd && prod.view_id === self.selProd.view_id )
                    self.selProd = prod;
              });
              self.$modalDlg.sysDlgs.progressDlg.close();
          })
                .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      selectProj( proj, role )
      {
          if( role )
          {
              conf.setProj( proj, role );
              localStorage.setItem( 'proj_model_id', proj.in_project.project_model_id );
              this.$eventBus.$emit( 'selectedModel' );
              this.$eventBus.$emit( 'setPath', [ { alt_name: 'Products' } ] );
              this.$globalStore.setCurFolderInfo( proj.in_project );
          }
      },
      updateProjList( info )
      {
            var selProj = null;
            var self = this;
            conf.getRepositoryList()
                    .then( ( data )=>
            {
                for( var i = 0; data && i < data.length; i++ )
                {
                    var proj = data[ i ];
                    if( proj.is_active_in_project && proj.user_registered_as.length > 0 )
                    {
                        if( info && proj.in_project.project_model_id === info.project_model_id )
                        {
                            selProj = proj;
                            break;
                        }
                    }
                }
                
                if( selProj )
                {
                    let selRole = null;
                    let prevRole = localStorage.getItem( 'proj_role' );
                    if( localStorage.getItem( 'proj_model_id' ) === info.project_model_id )
                    {
                        for( var i = 0; selProj.user_registered_as && i < selProj.user_registered_as.length; i++ )
                        {
                            if( selProj.user_registered_as[ i ] === prevRole )
                            {
                                selRole = prevRole;
                                break;
                            }
                        }
                    }
                    if( selRole )
                        self.selectProj( selProj, selRole, true ); 
                }

            }, ( err )=>{ self.$eventBus.$emit( 'queryError', err ); } );
      },    
      onClosePage()
      {
          localStorage.setItem( 'closeTime', Date.now() );
      }
  },
  created() 
  {    
       window.addEventListener( 'beforeunload', this.onClosePage );  
  },
  components: {
    'path-el-view': PathEl,
    'create-prod-view': CreateProd,
    'Split' : Split,
    'SplitArea': SplitArea,
    
    'tool-bar-view': ToolBar,
    
    'prod-list-view': ProdList,
    'prod-info-view': ProdInfo
  },
  beforeMount: function()
  {
      auth.checkAuth();
      if( ! auth.getUser().authenticated )
          router.push( '/login' );
      
      if( !conf.getProj() )
          router.push( '/' );
      
      if( this.$globalStore.state.curUserRole !== 'urn:rdl:epm-std:project_manager'
              && this.$globalStore.state.curUserRole !== 'urn:rdl:epm-std:project_admin' )
          router.push( '/home' );
  },
  mounted: function()
  {
     if(  auth.getUser().authenticated )
     {
          var projModelID = localStorage.getItem( 'proj_model_id' );
          if( projModelID )
              this.updateProjList( { project_model_id: projModelID } );        
          else
              this.updateProjList();        
     }
      
     this.initData();
  },
  beforeDestroy: function()
  {

  }
}
</script>

<style>
    .main_correction > div.v-main__wrap
    {
        height: 90%;            
    }
    
    .close-menu ul
    {
        min-width: 2em;
        white-space: nowrap;
    }
    
    .multipane.cust.layout-v .multipane-resizer 
    {
        margin: 0; left: 0; /* reset default styling */
        width: 5px;
        background: graytext;
    }
</style>
