<template>
    <v-card>
      <v-data-table :headers="heads" :items="userVals" sort-by="name"  hide-default-footer class="elevation-1 propTbl" 
                    :items-per-page=-1 fixed-header height="calc(100vh - 130px)">
        <template v-slot:header.name="{ header }">
            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
        </template>
        <template v-slot:header.role="{ header }">
            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
        </template>
        <template v-slot:header.act="{ header }">
            <v-tooltip bottom v-if="proj && aUsers.length > 0">
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on" @click="addItem()" id="addPrjUser">add</v-icon>
                </template>
                <span>{{ $t( 'message.user_add_btn' ) }}</span>
            </v-tooltip>
        </template>
        <template v-slot:item="{ item }">
            <tr>
                  <td>{{ item.name }}</td>
                  <td>{{ item.role }}</td>
                  <td>
                      <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                 <v-icon v-on="on" @click="editItem( item )" id="editPrjUser">edit</v-icon>
                            </template>
                            <span>{{ $t( 'message.user_edit_btn' ) }}</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" @click="deleteItem( item )" id="deletePrjUser">delete</v-icon>
                            </template>
                            <span>{{ $t( 'message.user_delete_btn' ) }}</span>
                      </v-tooltip>
                      <v-tooltip bottom v-if="item.isManager">
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" @click="importData( item )" id="impData">save_alt</v-icon>
                            </template>
                            <span>{{ $t( 'message.user_import_btn' ) }}</span>
                      </v-tooltip>
                      <v-tooltip bottom v-if="item.isManager">
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" @click="downloadData( item )" id="packDown">cloud_download</v-icon>
                            </template>
                            <span>{{ $t( 'message.user_download_btn' ) }}</span>
                      </v-tooltip>
                      <v-tooltip bottom v-if="item.isManager">
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" @click="uploadData( item )" id="packUp">cloud_upload</v-icon>
                            </template>
                            <span>{{ $t( 'message.user_upload_btn' ) }}</span>
                      </v-tooltip>
                  </td>
            </tr>
        </template>
      </v-data-table>
    </v-card>
</template>

<script>
import{ conf } from '@/utils/confTruePLM';

export default {
  name: 'ProjUsers',
  props: {
//      proj: null
  },
  data () {
    return {
        heads: [
            { text: 'message.user_name_col', value: 'name' }, 
            { text: 'message.user_role_col', value: 'role' }, 
            { width: '15%', sortable: false, value: 'act' }
        ],
        globalState: this.$globalStore.state,
        userVals : [],
        userNames: [],
        aUsers: [],
        roles: [],
        proj: null
    };
  },
  methods: 
  {
      uploadData()
      {
          let self = this;
          this.$modalDlg.sysDlgs.confirmDlg.openWarning( 'proj_import_title', 'proj_import_msg' )
                  .then( () =>
          {
              return self.$modalDlg.sysDlgs.createProjDlg.open( false, 'pack', true );
          }, () => { throw new Error( 'no act' ); } )
                  .then( ( info ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.open();
              return conf.importStepPack( info.files );
          }, () => {} )
                  .then( ( rez ) =>
          {
             // console.log( rez );
              self.$modalDlg.sysDlgs.progressDlg.close();
              
              if( rez )
                  self.$modalDlg.sysDlgs.confirmDlg.open( 'import_data_done_title', 'import_data_done_msg', null, null, true );
          }, ( err )=>
          { 
              if( err.message !== 'no act' )
              {
                    self.$modalDlg.sysDlgs.progressDlg.close();
                    self.$eventBus.$emit( 'queryError', err ); 
              }
          } );
      },
      
      downloadData()
      {
          let self = this;
          self.$modalDlg.sysDlgs.progressDlg.open();
          conf.exportStepPack()
                  .then( ( flInfo ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              let url = conf.getFileLink( flInfo.title, flInfo.source );
              window.location.href = encodeURI( url );          
          })
                  .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              this.$eventBus.$emit( 'queryError', err );
          });
      },
      
      importData( user )
      {
          let self = this;
          this.$modalDlg.sysDlgs.confirmDlg.openWarning( 'proj_import_title', 'proj_import_msg' )
                  .then( () =>
          {
              return self.$modalDlg.sysDlgs.createProjDlg.open( false, null, true );
          }, () => { throw new Error( 'no act' ); } )
                  .then( ( info ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.open();
              return conf.importDataToProj( info.files, info.srcType, user.name,
                            self.proj.repository, self.proj.in_project.project_model_id );
          }, () => {} )
                  .then( ( rez ) =>
          {
             // console.log( rez );
              self.$modalDlg.sysDlgs.progressDlg.close();
              
              if( rez )
                  self.$modalDlg.sysDlgs.confirmDlg.open( 'import_data_done_title', 'import_data_done_msg', null, null, true );
          }, ( err )=>
          { 
              if( err.message !== 'no act' )
              {
                    self.$modalDlg.sysDlgs.progressDlg.close();
                    self.$eventBus.$emit( 'queryError', err ); 
              }
          } );
      },
      getRoleName( role )
      {
          return this.$utils.getRoleName( role, this.$t );
      },
      addItem()
      {
          var self = this;
          this.$modalDlg.sysDlgs.setUserOnProjDlg.open( null, this.aUsers, this.roles, 
                this.proj.in_project.is_bkd_template_project || this.proj.in_project.is_template_project )
                  .then( ( rez ) =>
          {
              return conf.setUserOnProj( self.proj.in_project.guid, rez.user, rez.roles );
          }, () => {} )
                  .then( () => 
          {
               self.procProj();  
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      editItem( user )
      {
          var self = this;
          this.$modalDlg.sysDlgs.setUserOnProjDlg.open( user, this.aUsers, this.roles )
                  .then( ( rez ) =>
          {
              return conf.setUserOnProj( self.proj.in_project.guid, rez.user, rez.roles );
          }, () => {} )
                  .then( () => 
          {
               self.procProj();  
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      deleteItem( user )
      {
          var self = this;
          this.$modalDlg.sysDlgs.confirmDlg.open( 'user_delete_title', 'user_delete_msg', null, user.name )
                  .then( () =>
          {
              return conf.deleteUserFromProj( self.proj.in_project.guid, user.name );
          }, () => {} )
                  .then( () =>
          {
              self.procProj();              
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      procProj()
      {
          this.userVals = [];
          this.userNames = [];
          this.aUsers = [];
          var self = this;
          
          conf.getUserNames()
                  .then( ( data ) =>
          {
              if( data )
              {
                    data.forEach( ( elem ) =>
                    {
                        if( elem.str_value !== 'system' && elem.str_value !== 'superuser' )
                        {
                            this.userNames.push( elem.str_value );
                            this.aUsers.push( elem.str_value );
                        }
                    });
              }
              return conf.getProjectUserList( self.proj.in_project.guid );
          })
                  .then( ( data ) =>
          {
              if( !data )
                  return;

              data.forEach( ( elem ) =>
              {
                  let rView = '';
                  let isManager = false;
                  elem.user_registered_as.forEach( ( role ) =>
                  {
                      if( role === 'urn:rdl:epm-std:project_manager' )
                          isManager = true;
                      
                      if( rView !== '' )
                          rView += ', ';
                      let r = this.getRoleName( role );
                      rView += r
                  });
                  if( elem.user_name !== 'system' && elem.user_name !== 'superuser' )
                      self.userVals.push( { name: elem.user_name, role: rView, roles: elem.user_registered_as, isManager: isManager } );                                        
                  for( let i = 0; i < this.aUsers.length; i++ )
                  {
                      if( this.aUsers[ i ] === elem.user_name )
                      {
                          this.aUsers.splice( i, 1 );
                          break;
                      }
                  }
              });  
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      }
  },
  mounted: function() 
  { 
      var self = this;
      this.$eventBus.$on( 'editProj', function( proj )
      {
          self.proj = proj;
          self.procProj();
          conf.getChildTypes( 'urn:rdl:epm-std:user_type' )
              .then( ( data ) =>
          {
                self.roles = data;          
          });
      });
  },
  beforeDestroy: function()
  {
      this.$eventBus.$off( 'editProj' );
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .propTbl td
    {
        height: 30px;
    }        
</style>
