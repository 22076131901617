<template>
    <v-card>
      <v-data-table :headers="heads" :items="propVals" hide-default-footer class="elevation-1 propTbl" 
                    :items-per-page=-1 fixed-header height="calc( ( 100vh - 181px )" :sort-by="sortBy">
        <template v-slot:header.name="{ header }">
            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
        </template>
        <template v-slot:header.alChildren_view="{ header }">
            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
        </template>
        <template v-slot:header.act="{ header }">
            <v-tooltip bottom v-if="prop.id">
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on" @click="addItem()">add</v-icon>
                </template>
                <span>{{ $t( 'message.prop_add_btn' ) }}</span>
            </v-tooltip>
        </template>
        <template v-slot:item="{ item }">
            <tr>
                <td>{{ item.name }}<i v-if="item.default"> (Default)</i></td>
                  <td>{{ item.alChildren_view }}</td>
                  <td>
                      <v-tooltip bottom v-if="!item.in_use">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" small @click="deleteItem( item )">delete</v-icon>
                            </template>
                            <span>{{ $t( 'message.prop_deleteP_btn' ) }}</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" small @click="editItem( item )">edit</v-icon>
                            </template>
                            <span>{{ $t( 'message.prop_editP_btn' ) }}</span>
                      </v-tooltip>
                      <v-tooltip bottom v-if="!item.default">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" small @click="setDefItem( item )">check</v-icon>
                            </template>
                            <span>{{ $t( 'message.prop_defP_btn' ) }}</span>
                      </v-tooltip>
                  </td>
            </tr>
        </template>
      </v-data-table>
    </v-card>
</template>

<script>
import{ conf } from '@/utils/confTruePLM';

export default {
  name: 'PropInfoWithChildren',
  props: {
      prop: null
  },
  data () {
    return {
        heads: [
            { text: 'message.prop_value_col', value: 'name' }, 
            { text: 'message.prop_value_children_col', value: 'alChildren_view' }, 
            { width: '15%', sortable: false, value: 'act' }
        ],
        globalState: this.$globalStore.state,
        propVals : [],
        type: null,
        model: this.prop,
        sortBy: [ { key: 'name', order: 'asc' } ]
    };
  },
  watch: {
      prop( val ) 
      {
          this.updateModel();
      }
  },
  methods: 
  {
      addItem()
      {
          let self = this;
           let chList = [ { txt: 'All', val: 'UNSET' } ];
          let newItem = {};

          this.propVals.forEach( el =>
          {
              chList.push( { txt: el.name, val: el.val } );
          });
          this.$modalDlg.sysDlgs.createElemWithAChildrenDlg.open( null, chList )
                  .then( info =>
          {
              newItem = info;
              return conf.addRefDataClass( newItem.name, self.model.rdlName );
          }, () => {} )
                  .then( newURN => 
          {
              return conf.setAllowedChildren( newItem.alChildren, newURN.result );
          })
                  .then( () => 
          {
               self.updateModel( self.model );  
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      setDefItem( prop )
      {
          let self = this;
          this.$modalDlg.sysDlgs.confirmDlg.open( 'refdata_def_title', 'refdata_def_msg', null, prop.name )
                  .then( () =>
          {
              return conf.setDefaultProp( prop.val, self.model.rdlName );
          }, () => {} )
                  .then( () =>
          {
              self.updateModel( self.model );              
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      deleteItem( prop )
      {
          let self = this;
          this.$modalDlg.sysDlgs.confirmDlg.open( 'refdata_delete_title', 'refdata_delete_msg' )
                  .then( () =>
          {
              return conf.deleteRefData( prop.val, self.model.rdlName );
          }, () => {} )
                  .then( () =>
          {
              self.updateModel( self.model );              
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      editItem( item )
      {
          let self = this;
          let chList = [ { txt: 'All', val: 'UNSET' } ];

          this.propVals.forEach( el =>
          {
              chList.push( { txt: el.name, val: el.val } );
          });
          this.$modalDlg.sysDlgs.createElemWithAChildrenDlg.open( item, chList )
                  .then( ( info ) =>
          {
              return conf.setAllowedChildren( info.alChildren, item.val );
          }, () => {} )
                  .then( () => 
          {
               self.updateModel( self.model );  
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      updateModel()
      {
          this.propVals = [];
          this.model = this.prop;
          let self = this;
          let info = [];
          let nameMap = {};

          Promise.all( [ conf.getChildTypesWithStatus( this.model.rdlName ), conf.getDefaultProp( this.model.rdlName ) ] )
                  .then( ( data ) =>
          {
              let chInfo = [];
              data[ 0 ].forEach( ( elem ) =>
              {
                  let tElem = { val: elem.rdlInfo.class_uri, name: elem.rdlInfo.name || this.$utils.clearType( elem.rdlInfo.class_uri ), 
                      in_use: elem.inUse || ( elem.rdlInfo.status === 'CHECKED' ) };
                             
                  if( elem.rdlInfo.class_uri === data[ 1 ][ 0 ] )
                      tElem.default = true;
                      
                  chInfo.push( conf.getAllowedChildren( tElem.val ) );    
                  info.push( tElem );
                  nameMap[ tElem.val ] = tElem.name;
              } );
                  Promise.all( chInfo )
                          .then( data => 
                  {
                      for( let i = 0; i < info.length; i++ )
                      {
                            let chList = data[ i ];
                            if( chList && chList.length > 0 )
                            {
                                info[ i ].alChildren_view = '';
                                info[ i ].alChildren = [];

                                if( chList[ 0 ] !== 'NONE' )
                                {
                                      info[ i ].alChildren = chList;
                                      chList.forEach( ch =>
                                      {
                                          if( info[ i ].alChildren_view !== '' )
                                              info[ i ].alChildren_view += ', ';

                                          info[ i ].alChildren_view += nameMap[ ch ];
                                      });
                                }
                            }
                            else
                            {
                                info[ i ].alChildren = ['UNSET'];
                                info[ i ].alChildren_view = 'All';
                            }
                      }
                      
                      self.propVals = info;
                  })
              })     
        //   })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      }
  },
  mounted: function() 
  { 
      this.updateModel();
  },   
  beforeDestroy: function()
  {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .propTbl td
    {
        height: 30px;
    }        
</style>
