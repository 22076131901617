<template>
    <v-dialog v-model="visFl" persistent max-width="290">
       <v-card>
        <v-card-title class="headline" v-if='!importOnly'>
          {{ $t( 'message.new_proj_title' ) }}
        </v-card-title>
        <v-card-title class="headline" v-else>
          {{ $t( 'message.new_proj_imp_title' ) }}
        </v-card-title>
        <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validatio>
                <v-text-field :label="$t( 'message.new_proj_name' )" v-model="name" v-show='!importOnly'
                              required :rules="[ ( ( v ) => 
                              { if( !v && !importOnly ) 
                                    return $t( 'message.value_required' );
                                else 
                                    return true; } ) ]" id="createPrjName"></v-text-field>

                <v-textarea :label="$t( 'message.new_proj_descr' )" required v-model="descr" v-show='!importOnly'
                        :rules="[ ( ( v ) => 
                        { if( !v && !importOnly ) 
                              return $t( 'message.value_required' );
                          else 
                              return true; } ) ]" id="createPrjDescr"></v-textarea>
                              
                <file-upload ref="fileUpload" v-on:change="fileChanged" v-show="!admForm && useFiles"
                             required :rules="[ ( ( v ) => 
                        { if( ( !v || v.length < 1 ) && useFiles ) 
                              return $t( 'message.value_required' );
                          else 
                              return true; } ) ]" id="createPrjFile"></file-upload>
                
                <v-select :label="$t( 'message.create_node_src' )" item-text="txt" item-value="val" v-model="selectedSrc" 
                          :disabled='!importOnly' :items="srcs" v-show="useFiles" id="createPrjFileSelect"></v-select>

                <v-select :label="$t( 'message.new_proj_type' )" required item-text="txt" item-value="val" v-model="type" v-show="admForm"
                        :items="types" :rules="[v => !!v || $t( 'message.value_required' )]" id="createPrjTypeSelect"></v-select>
                
                <v-select :label="$t( 'message.create_proj_folder' )" v-model="selectedFolder" item-text="name" item-value="name"
                          v-show='!importOnly && folders && folders.length > 0' clearable :items="folders" id="createPrjFolderSelect"></v-select>
                
                <v-select :label="$t( 'message.create_node_tmpl' )" item-text="txt" item-value="val" v-model="selectedTmpl" @change="tmplChanged()" 
                          v-show='!selectedSrc && !importOnly' clearable :items="tmpls" id="createPrjTmplSelect"></v-select>
            </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn id="createPrjOk" color="green darken-1" text :disabled="!valid" @click.native="doAct()">{{ $t( 'message.ok_btn' ) }}</v-btn>
            <v-btn id="createPrjCancel" color="green darken-1" text @click.native="close()">{{ $t( 'message.cancel_btn' ) }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>       
</template>

<script>
    
import Vue from 'vue'
import{ conf } from '@/utils/confTruePLM';
import FileUpload from '../FileUpload'

export default {
  name: 'createProj',
  data () 
  {
    return {
        visFl: false,
        
        descr: null,
        name: null,
        admForm: false,
        type: 'proj',
        types: [ { val: 'proj', txt: this.$t( 'message.new_proj_type_proj' ) },
                 { val: 'tmpl', txt: this.$t( 'message.new_proj_type_tmpl' ) },
                 { val: 'bkd_tmpl', txt: this.$t( 'message.new_proj_type_bkd_tmpl' ) } ],
        srcs: [ { val: 'pdm', txt: this.$t( 'message.new_proj_src_type_pdm' ) },
                { val: 'bsl', txt: this.$t( 'message.new_proj_src_type_bsl' ) },
                { val: 'txt', txt: this.$t( 'message.new_proj_src_type_txt' ) },
                { val: 'dex', txt: this.$t( 'message.new_proj_src_type_dex' ) },
                { val: 'ap242', txt: this.$t( 'message.new_proj_src_type_ap242' ) } ],
        tmpls: [],
        folders: [],
        noFile: true,
        selectedFolder: null,
        selectedTmpl: null,
        selectedSrc: 'pdm',
        importOnly: false,
        resolve: null,
        reject: null,
        valid: true
    };
  },
  computed: {
      useFiles()
      {
          return !!this.selectedSrc || this.importOnly;
      }
  },
  components: {
       'file-upload': FileUpload
  },
  methods: {
      tmplChanged()
      {
          if( this.selectedTmpl )
          {
              this.$refs.fileUpload.clear();
              this.noFile = true;
          }
      },
      fileChanged( data )
      {
          let name = '';
          if( data[ 0 ] )
          {
              name = data[ 0 ].name;
              let ind = name.lastIndexOf( '.' );
              if( ind > 0 )
                  name = name.substring( 0, ind );
              
              this.selectedTmpl = null;
              this.noFile = false;
          }
          
          if( !this.name )
            this.name = name;          
      },
      getUploadedFile( data )
      {
      },
      open( admForm, srcType, importOnly, folderList )
      {
          this.name = null;
          this.descr = null;
          this.admForm = admForm;
          this.selectedTmpl = null;
          this.selectedSrc = srcType;
          this.importOnly = !!importOnly;
          this.folders = folderList;
          this.valid = true;
          
          if( srcType === 'pack' )
              this.srcs = [ { val: 'pack', txt: this.$t( 'message.new_proj_src_type_pack' ) } ];
          else
              this.srcs = [ { val: 'pdm', txt: this.$t( 'message.new_proj_src_type_pdm' ) },
                { val: 'bsl', txt: this.$t( 'message.new_proj_src_type_bsl' ) },
                { val: 'txt', txt: this.$t( 'message.new_proj_src_type_txt' ) },
                { val: 'dex', txt: this.$t( 'message.new_proj_src_type_dex' ) },
                { val: 'ap242', txt: this.$t( 'message.new_proj_src_type_ap242' ) } ];
          
          if( !srcType )
              this.srcs.splice( 2, 1 );
          
          this.tmpls = [];
          if( !importOnly )
          {
                conf.getAllTmpl()
                              .then( ( data ) =>
                  {
                      data.forEach( ( elem ) =>
                      {
                          if( elem.in_project.is_template_project )
                              this.tmpls.push( { val: elem, txt: elem.in_project.name } );
                      });
                  });
          }
         
          this.visFl = true;
          
          if( this.$refs.fileUpload )
              this.$refs.fileUpload.clear();
          
          return new Promise( ( resolve, reject ) => 
          {
            this.resolve = resolve;
            this.reject = reject;
          });
      },
      close()
      {
          this.visFl = false;
          this.reject();
      },
      doAct()
      {
          if( this.$refs.form.validate() )
          {
              let files = this.$refs.fileUpload.items;

              this.visFl = false;
              if( this.admForm )
                    this.resolve( { files: [], name: this.name, descr: this.descr, type: this.type, tmpl: this.selectedTmpl, srcType: this.selectedSrc } );
              else
                    this.resolve( { files: files, name: this.name, descr: this.descr, tmpl: this.selectedTmpl, srcType: this.selectedSrc, folder: this.selectedFolder } );
          }
      }
    }
}
</script>





