<template>
    <v-card>
      <v-data-table :headers="heads" :items="propVals" hide-default-footer class="elevation-1 propTbl" 
                    :items-per-page=-1 fixed-header height="calc( ( 100vh - 181px )">
        <template v-slot:header.num="{ header }">
            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
        </template>
          <template v-slot:header.name="{ header }">
            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
        </template>
          <template v-slot:header.val="{ header }">
            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
        </template>
        <template v-slot:header.act="{ header }">
            <v-tooltip bottom >
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on" @click="addItem()">add</v-icon>
                </template>
                <span>{{ $t( 'message.trgs_add_btn' ) }}</span>
            </v-tooltip>
            <v-tooltip bottom v-if="c_item.id">
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on" @click="editItem()">edit</v-icon>
                </template>
                <span>{{ $t( 'message.trgs_edit_btn' ) }}</span>
            </v-tooltip>
            <v-tooltip bottom v-if="c_item.id">
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on" @click="delItem()">delete</v-icon>
                </template>
                <span>{{ $t( 'message.trgs_del_btn' ) }}</span>
            </v-tooltip>
        </template>
        <template v-slot:item="{ item }">
            <tr>
                  <td>{{ item.num }}</td>
                  <td>{{ $t( 'message.trgs_prop_' + item.name ) }}</td>
                  <td colspan="2">{{item.val}}</td>
            </tr>
        </template>
      </v-data-table>
    </v-card>
</template>

<script>
import{ conf } from '@/utils/confTruePLM';

export default {
  name: 'TrgInfo',
  props: {
      c_item: null
  },
  data () {
    return {
        heads: [
            { text: 'message.c_item_num_col', value: 'num', width: '10%' }, 
            { text: 'message.c_item_name_col', value: 'name' }, 
            { text: 'message.c_item_value_col', value: 'val' },
            { text: 'message.c_item_act_col', value: 'act', sortable: false, width: '15%' }
        ],
        globalState: this.$globalStore.state,
        propVals : [],
        props: [ 'name', 'event_types', 'condition', 'action', 'author', 'timestamp', 'active' ]
    };
  },
  watch: {
      c_item() 
      {
            this.updateInfo();
      }
  },
  methods: 
  {
      addItem()
      {
          this.$emit( 'addItem' );
      },
      delItem()
      {
          this.$emit( 'delItem' );
      },
      editItem()
      {
          this.$emit( 'editItem' );
      },
      updateInfo()
      {
          this.propVals = [];
          
          if( !this.c_item )
              return;
          
          let self = this;
          let ind = 0;
          this.props.forEach( ( prop ) =>
          {
              let val = self.c_item[ prop ];
              if( prop === 'event_types' )
              {
                  let eVals = [];
                  let events = [];
                  ( val || [] ).forEach( ( e ) => 
                  {
                      eVals.push( self.$utils.clearType( e.name ) );
                      events.push( e.name );
                  });
                  
                  val = eVals.join( ',' );
                  self.c_item.events = events;
              }
              else if( prop === 'active' )
                  val = val ? 'Yes' : 'No';
              else if( prop === 'action' )
              {
                  val = val ? val.name : null;
                  self.c_item.act = val;
              }
              
              this.propVals.push( { num: ind++, name: prop, val: val } );  
          });       
      }
  },
  mounted: function() 
  { 
      this.updateInfo();
  },   
  beforeDestroy: function()
  {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .propTbl td
    {
        height: 30px;
    }        
</style>
