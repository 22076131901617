<template>

            <v-dialog v-model="visFl" persistent max-width="450">
               <v-card>
                <v-card-title class="headline">
                  {{ $t( 'message.search_node_ttl' ) }}
                </v-card-title>
                <v-card-text>
                        <v-tabs v-model="active" show-arrows>
                            <v-tab href="#tab-1">{{ $t( 'message.search_general_tb' ) }}</v-tab>
                            <v-tab href="#tab-2">{{ $t( 'message.search_date_tb' ) }}</v-tab>
                            <v-tab href="#tab-3">{{ $t( 'message.search_user_tb' ) }}</v-tab>
                            <v-tab href="#tab-4">{{ $t( 'message.search_adv_tb' ) }}</v-tab>
                            <v-tab-item value="tab-1">
                                <v-form ref="form1" v-model="valid1" lazy-validation>
                                    <v-text-field :label="$t( 'message.search_name' )" v-model="info.pattern"></v-text-field>
                                    <v-text-field :label="$t( 'message.search_descr' )" v-model="info.descr"></v-text-field>
                                                            
                                    <v-select :label="$t( 'message.create_node_type' )" v-model="info.type"
                                        item-text="txt" item-value="val" :items="nTypes" clearable/>
                                    <v-select :label="$t( 'message.add_file_node_pphase' )" v-model="info.pPhase" item-text="txt" 
                                        item-value="val" :items="pPhases" clearable></v-select>
                                    <v-checkbox :label="$t( 'message.search_branch_only')" v-model="info.branchOnly" class="compact">
                                    </v-checkbox>
                                </v-form>
                            </v-tab-item>   
                            <v-tab-item value="tab-2">
                                <v-form ref="form2" v-model="valid2" lazy-validation>
                                    <date-input-view :model="date1" :label_txt="$t( 'message.search_create_after' )" ref="date1"></date-input-view>
                                    <date-input-view :model="date2" :label_txt="$t( 'message.search_create_before' )" ref="date2"></date-input-view>
                                    <date-input-view :model="date3" :label_txt="$t( 'message.search_edit_after' )" ref="date3"></date-input-view>
                                    <date-input-view :model="date4" :label_txt="$t( 'message.search_edit_before' )" ref="date4"></date-input-view>
                                </v-form>
                            </v-tab-item> 
                             <v-tab-item value="tab-3">
                                <v-form ref="form3" v-model="valid3" lazy-validation>
                                    <v-select :label="$t( 'message.search_user_create' )" v-model="info.createUser" 
                                        item-text="txt" item-value="val" :items="users" clearable></v-select>
                                    <v-select :label="$t( 'message.search_user_edit' )" v-model="info.editUser" 
                                        item-text="txt" item-value="val" :items="users" clearable></v-select>
                                </v-form>
                            </v-tab-item> 
                            <v-tab-item value="tab-4">
                                <v-form ref="form4" v-model="valid4" lazy-validation>
                                    <v-select :label="$t( 'message.search_prop_name' )" v-model="prop.name" @change="checkInfo"
                                        item-text="txt" item-value="val" :items="props" clearable></v-select>
                                    
                                    <v-select :label="$t( 'message.search_prop_value' )" v-model="prop.enumVal" v-if="enumVals.length > 0"
                                        item-text="txt" item-value="val" :items="enumVals" clearable></v-select>
                                    
                                    <date-input-view :model="date5" :label_txt="$t( 'message.search_prop_value' )" ref="date5" v-if="isDtProp"></date-input-view>
                                    
                                    <v-text-field :label="$t( 'message.search_prop_value' )" v-model="prop.val" v-if="enumVals.length < 1 && !isDtProp" ></v-text-field>
                                    
                                    <v-btn style="margin-top: -30px;" small @click.native="addCond()">{{ $t( 'message.search_prop_add_btn' ) }}</v-btn>
                                    <div style="font-weight: 700" v-if="propCond.length < 1">
                                        {{$t( 'message.cond_cond_exp' )}}
                                        <span style="color: red; font-weight: 700">{{ $t( 'message.cond_empty' )}}</span>
                                    </div>
                                    <div style="font-weight: 700" v-else>{{$t( 'message.cond_cond_exp' )}}</div>
                                    <v-chip-group column active-class="primary--text">
                                      <span v-for="( item, ind ) in propCond" :key="ind">
                                        {{ ( ind > 0 ) ? 'AND' : ''}} 
                                        <v-chip close @click:close="delCond( ind )">
                                            <span class="cut-text">{{ item.name }}</span>
                                            <span style="padding-left: 7px;">
                                                = {{ item.valView }}
                                            </span>
                                        </v-chip>
                                      </span>      
                                    </v-chip-group>
<!--                                    <v-simple-table dense>
                                        <template v-slot:default>
                                          <tbody>
                                            <tr v-for="( item, ind ) in propCond" :key="ind">
                                              <td>{{ item.name }}</td>
                                              <td>{{ item.valView }}</td>
                                              <td width="10%">
                                                  <v-tooltip bottom>
                                                        <template v-slot:activator="{ on }">
                                                            <v-icon v-on="on" @click="delCond( ind )">delete_forever</v-icon>
                                                        </template>
                                                        <span>
                                                            {{$t( 'message.cond_delete_btn' )}}
                                                        </span>
                                                  </v-tooltip>
                                          <v-btn small text @click.native="delCond()">{{ $t( 'message.cond_delete_btn' ) }}</v-btn>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </template>
                                    </v-simple-table>-->
                                </v-form>
                            </v-tab-item> 
                        </v-tabs>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click.native="doAct()">{{ $t( 'message.ok_btn' ) }}</v-btn>
                    <v-btn color="green darken-1" text @click.native="close()">{{ $t( 'message.cancel_btn' ) }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>   

</template>

<script>
    
import Vue from 'vue'

import DateInput from '../DateInput.vue'
import{ conf } from '@/utils/confTruePLM';

export default {
  name: 'SearchNode',
  data () 
  {
    return {
        visFl: false,
        
        date1: null,
        date2: null,
        date3: null,
        date4: null,
        date5: null,
        
        nTypes: [],
        cTypes: [],
        sources: [],
        disciplines: [],
        pPhases: [],
        pPhaseVal: [],
        statuses: [],
        users: [],
        props: [],
        info: {},
        prop: {},
        isDtProp: false,
        enumVals: [],
        propCond: [],
        valid1: true,
        valid2: true,
        valid3: true,
        valid4: true,
        
        resolve: null,
        reject: null,
        valid: true,
        active: 'tab-1'
    };
  },
  computed: {
  },
  components: {
       'date-input-view': DateInput
  },
  methods: {
      checkInfo()
      {
          this.isDtProp = false;
          this.enumVals = [];
          if( this.prop.name )
          {
              conf.getConcept( this.prop.name )
                        .then( ( info ) =>
              {
                  if( info.oneof_elements )
                  {
                      info.oneof_elements.forEach( ( val ) =>
                      {
                           this.enumVals.push( { val: val, txt: this.$utils.clearType( val ) } );                  
                      });
                  }
                  else 
                  {
                      for( let i = 0; i < info.classifiers.length; i++ )
                      {
                          if( info.classifiers[ i ] === 'urn:plcs:rdl:std:Date_time' )
                          {
                              this.isDtProp = true;
                              break;
                          }
                      }
                  }
              });
          }
          this.prop.val = null;
          this.prop.enumVal = null;
          if( this.$refs.date5 )
            this.$refs.date5.clear();
      },
      clearCond()
      {
          this.prop = {};
          this.propCond = [];
      },
      delCond( ind )
      {
          this.propCond.splice( ind, 1 );
      },
      addCond()
      {
          let dtVal = ( this.$refs.date5 && this.$refs.date5.computedDateFormatted ) ? this.$refs.date5.computedDateFormatted : null;
          if( ( this.prop.val || this.prop.enumVal || dtVal ) && this.prop.name )
          {
              let val = this.prop.val;
              let valView = '';
              if( !val && this.prop.enumVal )
              {
                  val = this.prop.enumVal;
                  valView = this.$utils.clearType( val );
              }
              else if( !val && dtVal )
              {
                  val = dtVal;
                  valView = dtVal;
              }
              else
                  valView = val;
              
              this.propCond.push( { name: this.$utils.clearType( this.prop.name ), valView: valView, urn: this.prop.name, val: valView } );
          }
      },
      open()
      {
          let self = this;
          
          this.nTypes = [];
          conf.getChildTypesInContext( 'urn:rdl:epm-std:breakdown_element_type' )
                  .then( ( data ) =>
          {
               data.forEach( ( p ) =>
               {
                    self.nTypes.push( { val: p, txt: this.$utils.clearType( p ) } ); 
               });
             self.$utils.sortArray( self.nTypes, ( p ) => ( p.txt ) );
          });

          this.pPhases = [];
          conf.getChildTypes( 'urn:rdl:epm-std:project_phase' )
                  .then( ( data ) =>
          {
              self.pPhases = self.$utils.getEnum( data, null, true );
          });

          this.users = [];
          conf.getProjectUsersEnum()
                  .then( ( data ) =>
          {
              self.users = data;
          });
          
          this.props = [];
          conf.getAllPropsByName( 'urn:rdl:epm-std:breakdown_element_type' )
                  .then( ( data ) =>
          {
        //     console.log( data );
             data.forEach( ( p ) =>
             {
                 self.props.push( { val: p, txt: this.$utils.clearType( p ) } ); 
             });
             self.$utils.sortArray( self.props, ( p ) => ( p.txt ) );
          });
          
          if( this.info.pattern )
          {
              let branchOnly = this.info.branchOnly;
              this.info = { pattern: '*', descr: '*', branchOnly: branchOnly,
                createAfter: null, createBefore: null, editAfter: null, editBefore: null };
          }
          else
            this.info = { pattern: '*', descr: '*', branchOnly: 'true',
              createAfter: null, createBefore: null, editAfter: null, editBefore: null };
//          this.$refs.date1.clear();
//          this.$refs.date2.clear();
//          this.$refs.date3.clear();
//          this.$refs.date4.clear();
          this.visFl = true;
          
          return new Promise( ( resolve, reject ) => 
          {
            this.resolve = resolve;
            this.reject = reject;
          });
      },
      close()
      {
          this.visFl = false;
          this.reject();
      },
      doAct()
      {
          this.visFl = false;
          this.info.createAfter = ( this.$refs.date1 && this.$refs.date1.computedDateFormatted ) ? this.$refs.date1.computedDateFormatted + ' 00:00:00' : null;
          this.info.createBefore = ( this.$refs.date2 && this.$refs.date2.computedDateFormatted ) ? this.$refs.date2.computedDateFormatted + ' 00:00:00' : null;
          this.info.editAfter = ( this.$refs.date3 && this.$refs.date3.computedDateFormatted ) ? this.$refs.date3.computedDateFormatted + ' 00:00:00' : null;
          this.info.editBefore = ( this.$refs.date4 && this.$refs.date4.computedDateFormatted ) ? this.$refs.date4.computedDateFormatted + ' 00:00:00' : null;
          this.info.pCond = this.propCond;
          this.resolve( this.info );
      }
    }
}
</script>





