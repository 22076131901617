<template>
            <v-dialog v-model="visFl" persistent max-width="290">
               <v-card>
                <v-card-title class="headline">
                  {{ $t( 'message.set_2fa_title', { val: userN } ) }}
                </v-card-title>
                <v-card-text>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-switch v-model="use2fa" :label="state2fa"></v-switch>
                        <br>
                        <v-switch v-model="showCode" :label="$t( 'message.set2fa_show_code' )" :disabled="!use2fa"></v-switch>
                        <v-text-field :value="code2fa" :label="$t( 'message.set2fa_code' )" v-show="showCode" readonly></v-text-field>
                        <img :src="qrSrc" v-show="showCode && code2fa" style="margin-top: -45px; margin-bottom: -30px;"></img>
                        <v-btn x-small v-show="showCode" @click.native="resetCode()">{{ $t( 'message.set2fa_reset_code' ) }}</v-btn>
                    </v-form>
                    <!--<small>*{{ $t( 'message.req_info_msg' ) }}</small>-->
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text :disabled="!valid" @click.native="doAct()">{{ $t( 'message.ok_btn' ) }}</v-btn>
                    <v-btn color="green darken-1" text @click.native="close()">{{ $t( 'message.cancel_btn' ) }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>   
</template>

<script>
    
import Vue from 'vue'
import{ conf } from '@/utils/confTruePLM';

export default {
  name: 'set2fa',
  data () 
  {
    return {
        visFl: false,
        
        use2fa: false,
        code2fa: null,
        showCode: false,
        qrSrc: '',
        userN: '',

        resolve: null,
        reject: null,
        valid: true
    };
  },
  computed: {
     
      state2fa()
      {
          return this.use2fa ? this.$t( 'message.set2fa_on' ) : this.$t( 'message.set2fa_off' );
      }
  },
  methods: {
      open( user )
      {
          this.userN = user;
          
          let self = this;
          
          this.showCode = false;
          
          conf.getUserSettings( user )
                  .then( ( data ) =>
          {
              self.use2fa = data.use_2FA;
              self.code2fa = data.user_2FA_code;
              self.qrSrc = conf.getQRcodeLink( user );
          })
                .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });  
          
          this.visFl = true;
          
          return new Promise( ( resolve, reject ) => 
          {
            this.resolve = resolve;
            this.reject = reject;
          });
      },
      resetCode()
      {
          this.code2fa = null;
      },
      close()
      {
          this.visFl = false;
          this.reject();
      },
      doAct()
      {
          if( this.$refs.form.validate() )
          {
              this.visFl = false;
              this.resolve( { use2fa: this.use2fa, code: this.code2fa } );
          }
      }
    }
}
</script>



