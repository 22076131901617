<template>
    
            <v-dialog v-model="visFl" persistent max-width="1000">
                <v-toolbar flat color="white">
                    <v-toolbar-title>{{ $t( 'message.file_hist_title' ) }}</v-toolbar-title>
                </v-toolbar>
               <v-card>
                <v-card-text>
                    <v-data-table :headers="headers" :items="histData" hide-default-footer class="elevation-1 propTbl" 
                                  :items-per-page=-1 fixed-header>
                        <template v-slot:header.version_id="{ header }">
                            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
                        </template>
                        <template v-slot:header.date_submitted="{ header }">
                            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
                        </template>
                        <template v-slot:header.submitted_by_user="{ header }">
                            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
                        </template>
                        <template v-slot:header.status="{ header }">
                            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
                        </template>
                        <template v-slot:header.changes_description="{ header }">
                            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
                        </template>
                        <template v-slot:header.act="{ header }">
                            <span></span>
                        </template>
                        <template v-slot:item="{ item }">
                            <tr>
                                  <td>{{ item.version_id }}</td>
                                  <td>{{ $utils.getLocalDateFromUTC( item.date_submitted ) }}</td>
                                  <td>{{ item.submitted_by_user }}</td>
                                  <td>{{ status_view( item ) }}</td>
                                  <td>{{ item.changes_description }}</td>
                                  <td>
                                      <v-icon @click="downloadVer( item )">save</v-icon>
                                  </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click.native="close()">{{ $t( 'message.close_btn' ) }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>   
    
</template>

<script>
    
import Vue from 'vue'
import{ conf } from '../../utils/confTruePLM'

export default {
  name: 'fileHistory',
  data () 
  {
    return {
        visFl: false,
        headers: [
            { text: 'message.file_hist_ver', value: 'version_id' },
            { text: 'message.file_hist_date', value: 'date_submitted' },
            { text: 'message.file_hist_user', value: 'submitted_by_user' },
            { text: 'message.file_hist_status', value: 'status' },
            { text: 'message.file_hist_descr', value: 'changes_description' },
            { text: 'message.file_hist_act', value: 'act', sortable: false }
        ],
        histData : [],
        node: {},
        file: null,
        resolve: null,
        reject: null
    };
  },
  computed: {
  },
  methods: {
      status_view( item )
      {
          return this.$utils.clearType( item.status );
      },
      downloadVer( item )
      {
          conf.downloadFileVer( this.file.file_name, item.instance_id, item.version_id )
                  .then( ( flInfo ) =>
          {
              let url = conf.getFileLink( flInfo.title, flInfo.source );
              window.location.href = encodeURI( url );          
          })
                  .catch( ( err ) =>
          {
              this.$eventBus.$emit( 'queryError', err );
          });
      },
      open( file, node )
      {
          this.file = file;
          this.node = node;
          this.histData = file.versions;
          
          this.visFl = true;
          
          var self = this;                    
          return new Promise( ( resolve, reject ) => 
          {
              self.resolve = resolve;
              self.reject = reject;
          });          
      },
      close()
      {
          this.visFl = false;
          this.file = null;
          this.node = {};
          this.resolve();
      }
    }
}
</script>

<style scoped>
    .propTbl td
    {
        height: 30px;
    }        
</style>



