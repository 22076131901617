<template>
    
  <v-app id="auth_callback">
    <v-main>
       
    </v-main>
    
  </v-app>
    
</template>

  <script>
      
  import router from '@/router';
  import { auth } from '@/utils/auth';
  
  export default {
    data() {
      return {
          globalState: this.$globalStore.state
      };
    },
    computed: {
              
    },
    methods: 
    {
      
    },
    beforeCreate: function()
    {
       let state = this.$route.query.state;
       let reqData = JSON.parse( localStorage.getItem( 'keycloak-' + state ) || '{}' );
       if( reqData && reqData.state === state )
       {
           localStorage.removeItem( 'keycloak-' + state );
           let callbackURL = window.location.href.replace( '/#/kclcallback', '/kclcallback.html' );
           callbackURL = callbackURL.split('?')[0];
           auth.loginSSO( 'keycloak', this.$route.query.code, callbackURL )
                   .then( data => 
           {
               if( data.status )
                   router.push( '*' );
               else
               {
                   this.$globalStore.setSsoId( data.sso_id );
                   router.push( '/loginconnect' );
               }
           })
                    .catch( ( err ) => 
           {
               console.log( err );
               router.push( { name: 'Home' } );
           });
       }
             
    },
    components: {
        
    }
  }
  </script>