<template>
            <v-dialog v-model="visFl" persistent max-width="290">
               <v-card>
                <v-card-title class="headline">
                    <span v-if="titleVal">{{ $t( 'message.full_custom_title', { cur_ttl: $t(`${title}`) } ) }}</span>
                    <span v-else-if="isNew">{{ $t( 'message.add_prop_title' ) }}</span>
                    <span v-else>{{ $t( 'message.edit_prop_title' ) }}</span>
                </v-card-title>
                <v-card-text>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-select v-if="isNew" :label="$t( 'message.edit_prop_type' )" required item-text="txt" item-value="val" v-model="propType"
                            :items="types" :rules="[v => !!v || $t( 'message.value_required' )]" @change="typeChanged()"></v-select>
                        <v-text-field v-else :label="$t( 'message.edit_prop_type' )" required v-model="propTypeView" :readonly="!isNew"
                                :rules="[v => !!v || $t( 'message.value_required' )]"></v-text-field>
                        
                        <v-select v-if="values.length > 0" :label="$t( 'message.edit_prop_value' )" required item-text="txt" item-value="val" v-model="propVal"
                            :items="values" :rules="[v => !!v || $t( 'message.value_required' )]" id="assignPropListVal"></v-select>
                        <date-input-view v-else-if="isDate" :model="propVal" :label_txt="$t( 'message.edit_prop_value' )" 
                                         required ref="dateVal" id="assignPropDateVal"></date-input-view>             
                        <v-text-field v-else :label="$t( 'message.edit_prop_value' )" required v-model="propVal" :type="inType" :mask="inMask"
                                :rules="[v => !!v || $t( 'message.value_required' )]" id="assignPropTextVal"></v-text-field>
                        
                        <v-select :label="$t( 'message.edit_prop_unit' )" item-text="txt" item-value="val" v-model="propUnit" v-show="units.length > 0"
                            :items="units" ></v-select>
                        
                    </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                    <v-btn id="assignPropYes" color="green darken-1" text :disabled="!valid" @click.native="doAct()">{{ $t( 'message.ok_btn' ) }}</v-btn>
                    <v-btn id="assignPropClose" color="green darken-1" text @click.native="close()">{{ $t( 'message.cancel_btn' ) }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>   
</template>

<script>
    
import Vue from 'vue'

import DateInput from '../DateInput.vue';
import{ conf } from '@/utils/confTruePLM';

export default {
  name: 'assignProp',
  data () 
  {
    return {
        visFl: false,
        prop: null,
        titleVal: null,
        propValPrev: null,
        propVal: null,
        propName: null,
        propType: null,
        propUnit: null,
        units: [],
        types: [],
        values: [],
        propList: [],
        inType: 'text',
        isDate: false,
        inMask: null,
        isNew: true,
        resolve: null,
        reject: null,
        valid: true
    };
  },
  computed: {
      propTypeView()
      {
          if( this.prop && this.prop.name )
              return this.prop.name;
          else
              return this.propType ? this.$utils.clearType( this.propType ) : null;
      },
      title() 
      {
           return 'message.' + this.titleVal;
      }
  },
  components: {
      'date-input-view': DateInput
  },
  methods: {
//      checkInVal()
//      {
//          console.log( this.propVal );
//         if( this.propUnit )
//         {
//              if( isNaN( this.propVal ) )
//                  this.propVal = this.propValPrev;
//
//              this.propValPrev = this.propVal;
//         }
//      },
//      typeChanged()
//      {
//          var self = this;
//          self.units = [];
//          for( var i = 0; self.propList && i < self.propList.length; i++ )
//          {
//              if( self.propList[ i ].uri === self.propType )
//              {
//                  console.log( self.propList[ i ] );
//                  if( self.propList[ i ].unit_uri )
//                  {
//                      var elems = self.propList[ i ].unit_uri.split( ',' );
//                      elems.forEach( ( elem ) =>
//                      {
//                          self.units.push( { txt: self.$utils.clearType( elem ), val: elem } );                          
//                      });
//                      if( self.units.length == 1 )
//                          self.propUnit = self.units[ 0 ].val;
//                  }
//                  return;
//              }
//          }
//          self.values = [];
//      },
      
      open( prop, list, title )
      {
//          console.log( prop );
          this.isNew = !prop;
          this.titleVal = title;
          this.isDate = false;
          this.inType = 'text';
          
          for( let i = 0; prop.obj && prop.obj.domains && i < prop.obj.domains.length; i++ )
          {
                let dom = prop.obj.domains[ i ];

                dom = this.$utils.clearType( dom );
                if( dom === 'Date_time' )
                {
                    this.isDate = true;
                    break;
                }
                else if( dom === 'Numeric_value' )
                {
                    this.inType = 'number';
                    break;
                }
          }
          
          this.prop = prop || {};
          this.propVal = prop ? ( prop.num_val ? prop.num_val : prop.val ) : null;
          this.propType = prop ? prop.type : null;
          this.propUnit = prop ? prop.unit_code : null;
          
          this.units = [];
          this.types = [];
          this.values = [];
          this.propList = list;
          
//          if( prop.obj && prop.obj.domain === 'urn:owl:oneOf' )
//          {
//              ( prop.obj.options || [] ).forEach( ( op ) =>
//              {
//                  this.values.push( { txt: op, val: op } );
//              });
//          }
          
//          var propInList = this.isNew;
//          for( var i = 0; list && i < list.length; i++ )
//          {
//              this.types.push( { txt: this.$utils.clearType( list[ i ].uri ), val: list[ i ].uri } );
////              if( !propInList && list[ i ].uri === this.propType )
////                  propInList = true;
//          }
          
//          if( !propInList )
//          {
//              this.types.push( { txt: prop.name, val: prop.type } );
//              if( this.propUnit )
//                  this.units.push( { txt: prop.unit, val: prop.unit_code } ); 
//          }
//          else
//              this.typeChanged();
          
//          if( this.propUnit )
//              this.inType = 'number';
////          else if( this.$utils.clearType( prop.obj.domain ) === 'Date_time' )
////              this.inType = 'datetime-local';
//          else
//              this.inType = 'text';
          
          if( prop.obj && prop.obj.units )
          {
              prop.obj.units.forEach( ( elem ) =>
              {
                  this.units.push( { txt: this.$utils.clearType( elem ), val: elem } );                          
              });
          }
          
          if( prop.obj && prop.obj.options )
          {
              prop.obj.options.forEach( ( elem ) =>
              {
                  this.values.push( { txt: this.$utils.clearType( elem ), val: elem } );                          
              });
          }
          
//          if( this.$utils.clearType( prop.obj.domain ) === 'Date_time' )
//              this.inMask = '####-##-## ##:##:##';
//          else
//              this.inMask = null;
          this.visFl = true;
          
          if( this.isDate && this.$refs.dateVal )
            this.$refs.dateVal.setVals( this.propVal );
        
        //console.log( this.propVal );
          
          return new Promise( ( resolve, reject ) => 
          {
            this.resolve = resolve;
            this.reject = reject;
          });
      },
      close()
      {
          this.visFl = false;
          this.reject();
      },
//      procValByMask( val )
//      {
//          if( !this.inMask )
//              return val;
//          
//          var maskData = this.inMask.split( '' );
//          var valData = val.split( '' );
//          var ind = 0;
//          var rez = '';
//          for( var i = 0; i < maskData.length; i++ )
//          {
//              var ch = maskData[ i ];
//              if( ch === '#' )
//              {
//                  if( ind < valData.length )
//                      rez += valData[ ind++ ];
//                  else
//                      rez += '0';
//              }
//              else
//              {
//                  if( ch === ' ' && ind > valData.length )
//                      break;
//                  rez += ch;
//              }
//          }
//          return rez;
//      },
      doAct()
      {
          if( this.$refs.form.validate() )
          {
              this.visFl = false;
              this.prop.val = this.isDate ? this.$refs.dateVal.computedDateFormatted : this.propVal;
              this.prop.type = this.propType;
              this.prop.unit_code = this.propUnit;
//              this.$refs.dateVal.clear();
              this.resolve( this.prop );
          }
      }
    }
}
</script>



