<template>
   <v-app-bar app fixed clipped-left>
       <v-img :src="logoSrc" max-width="100" max-height="30"></v-img>
       
<!--      <v-toolbar-title style="width: 300px" class="ml-0 pl-3">
        <v-toolbar-side-icon @click.stop="toggleVis"></v-toolbar-side-icon>
        <span class="hidden-sm-and-down">PLM</span>
      </v-toolbar-title>-->
      <v-app-bar-nav-icon @click.stop="toggleVis" v-if="!type"></v-app-bar-nav-icon>
      
      <v-tooltip bottom v-if="$vuetify.breakpoint.mdAndUp">
        <template v-slot:activator="{ on }">
              <v-icon v-on="on" @click="openHelp()" style="padding-left: 10px;">help</v-icon>
        </template>
        <span>{{ $t( 'message.help_btn' ) }}</span>
      </v-tooltip>
      
      <v-tooltip bottom v-if="$vuetify.breakpoint.mdAndUp">
        <template v-slot:activator="{ on }">
              <v-icon v-on="on" @click="openUserInfo()">account_circle</v-icon>
        </template>
        <span>{{ $t( 'message.user_info_btn' ) }}</span>
      </v-tooltip>

      <v-tooltip bottom v-if="!type && $vuetify.breakpoint.mdAndUp">
        <template v-slot:activator="{ on }">
              <v-icon v-on="on" @click="openIssueList()">task</v-icon>
        </template>
        <span>{{ $t( 'message.issue_list_btn' ) }}</span>
      </v-tooltip>

      <v-tooltip bottom v-if="!type && $vuetify.breakpoint.mdAndUp">
        <template v-slot:activator="{ on }">
            <v-badge overlap color="green lighten-1" v-show="newNoti > 0" slot="activator">
                <span slot="badge">{{ newNoti }}</span>
                <v-icon v-on="on" @click="openNotify()">mail</v-icon>
            </v-badge>
            <v-icon v-show="newNoti === 0" v-on="on" @click="openNotify()">mail</v-icon>
        </template>
        <span>{{ $t( 'message.notify_list_btn' ) }}</span>
      </v-tooltip>
      <v-tooltip bottom v-if="!type && globalState.curFolderName && globalState.userMsgReady && $vuetify.breakpoint.mdAndUp">
        <template v-slot:activator="{ on }">
              <v-icon v-on="on" @click="newMsg()" style="padding-left: 10px;">chat</v-icon>
        </template>
        <span>{{ $t( 'message.send_user_msg' ) }}</span>
      </v-tooltip>
<!--      <v-tooltip bottom v-if="$vuetify.breakpoint.mdAndUp">
        <v-icon slot="activator" @click="newProj()">create_new_folder</v-icon>
        <span>{{ $t( 'message.new_project_btn' ) }}</span>
      </v-tooltip>-->
                 
      <v-spacer></v-spacer>
<!--      <v-tooltip bottom v-if="globalState.curFolderName && $vuetify.breakpoint.mdAndUp">
          <template v-slot:activator="{ on }">
              <v-icon v-on="on" @click="openProds()">category</v-icon>              
          </template>
        
        <span>{{ $t( 'message.prod_btn' ) }}</span>
      </v-tooltip>-->
        <v-tooltip bottom v-if="!type && globalState.curFolderName && globalState.blockChainReady 
        && $vuetify.breakpoint.mdAndUp && $globalStore.isAdmin()">
        <template v-slot:activator="{ on }">
              <v-icon v-on="on" @click="openChain()">share</v-icon>
        </template>
        <span>{{ $t( 'message.open_chain' ) }}</span>
      </v-tooltip>

      <v-tooltip bottom v-show="globalState.curFolderName" v-if="type && $vuetify.breakpoint.mdAndUp">
        <template v-slot:activator="{ on }">
              <v-icon v-on="on" @click="backToProj()">reply</v-icon>
        </template>
        <span>{{ $t( 'message.to_proj_btn' ) }}</span>
      </v-tooltip>

      <v-tooltip bottom v-if="!type && globalState.curFolderName && $vuetify.breakpoint.mdAndUp">
          <template v-slot:activator="{ on }">
              <v-icon v-on="on" @click="openDiff()">compare</v-icon>              
          </template>
        
        <span>{{ $t( 'message.diff_btn' ) }}</span>
      </v-tooltip>
      <v-tooltip bottom v-if="!type && globalState.curFolderName && $vuetify.breakpoint.mdAndUp">
        <template v-slot:activator="{ on }">
              <v-icon v-on="on" @click="openBaselines()">offline_pin</v-icon>
        </template>
        <span>{{ $t( 'message.baseline_list_btn' ) }}</span>
      </v-tooltip>
      <v-menu offset-y v-if="!type && globalState.curFolderName && $vuetify.breakpoint.mdAndUp">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" small fab text class="i_correct">
                <v-icon>search</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item :key="1" @click="quickSearch()">
              <v-list-item-title>{{ $t( 'message.menu_search_quick' ) }}</v-list-item-title>
            </v-list-item>
            <v-list-item :key="2" @click="nodeSearch()">
              <v-list-item-title>{{ $t( 'message.menu_search_node' ) }}</v-list-item-title>
            </v-list-item>
            <v-list-item :key="3" @click="docQuickSearch()">
              <v-list-item-title>{{ $t( 'message.menu_search_doc_quick' ) }}</v-list-item-title>
            </v-list-item>
            <v-list-item :key="4" @click="docSearch()">
              <v-list-item-title>{{ $t( 'message.menu_search_doc' ) }}</v-list-item-title>
            </v-list-item>
            <v-list-item :key="5" @click="docTxtSearch()">
              <v-list-item-title>{{ $t( 'message.menu_search_doc_txt' ) }}</v-list-item-title>
            </v-list-item>
          </v-list>
      </v-menu>
      <v-menu offset-y v-if="globalState.curFolderName && $globalStore.isAdmin()
                 && globalState.projType !== 'BkdTmpl' && $vuetify.breakpoint.mdAndUp">
          <template v-slot:activator="{ on: onMenu }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on: onTooltip }">
                    <v-btn v-on="{ ...onMenu, ...onTooltip }" small fab text class="i_correct">
                        <v-icon style="color: rgba(0,0,0,.54)">receipt</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t( 'message.catalog_btn' ) }}</span>
              </v-tooltip>
          </template>
          <v-list dense>
            <v-list-item :key="1" @click="openOrgs()">
              <v-list-item-title>{{ $t( 'message.menu_catalog_org' ) }}</v-list-item-title>
            </v-list-item>
            <v-list-item :key="2" @click="openProds()">
              <v-list-item-title>{{ $t( 'message.menu_catalog_prod' ) }}</v-list-item-title>
            </v-list-item>
            <v-list-item :key="3" @click="openActions()" v-if="$globalStore.isAdmin()">
              <v-list-item-title>{{ $t( 'message.menu_catalog_act' ) }}</v-list-item-title>
            </v-list-item>
            <v-list-item :key="4" @click="openTriggers()" v-if="$globalStore.isAdmin()">
              <v-list-item-title>{{ $t( 'message.menu_catalog_trg' ) }}</v-list-item-title>
            </v-list-item>
            <v-list-item :key="5" @click="openNTasks()" v-if="$globalStore.isAdmin()">
              <v-list-item-title>{{ $t( 'message.menu_catalog_ntask' ) }}</v-list-item-title>
            </v-list-item>
          </v-list>
      </v-menu>
<!--      <v-tooltip bottom v-if="globalState.curFolderName 
                 && ( globalState.curUserRole === 'urn:rdl:epm-std:project_manager' || globalState.curUserRole === 'urn:rdl:epm-std:project_admin' )
                 && globalState.projType !== 'BkdTmpl' && $vuetify.breakpoint.mdAndUp">
        <template v-slot:activator="{ on }">
              <v-icon v-on="on" @click="openOrgs()">receipt</v-icon>
        </template>
        <span>{{ $t( 'message.org_btn' ) }}</span>
      </v-tooltip>-->
      <v-tooltip bottom v-if="!type && globalState.curFolderName 
                 && ( $globalStore.isAdmin() || globalState.curUserRole === 'urn:rdl:epm-std:rdl_manager' )
                 && globalState.projType !== 'BkdTmpl' && $vuetify.breakpoint.mdAndUp">
        <template v-slot:activator="{ on }">
              <v-icon v-on="on" @click="openProps()">settings</v-icon>
        </template>
        <span>{{ $t( 'message.prop_btn' ) }}</span>
      </v-tooltip>
      <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn small fab text v-on="on">{{langInfo.locale}}</v-btn>
          </template>
          <v-list dense>
            <v-list-item v-for="l in langs" :key="l" @click="setLang( l )">
              <v-list-item-title>{{ l }}</v-list-item-title>
            </v-list-item>
          </v-list>
      </v-menu>
      
  </v-app-bar> 
</template>

<script>
    
import{ conf } from '@/utils/confTruePLM';  
import router from '@/router';

export default {
  name: 'tool-bar',
  props: {
      type: null
  },
  data () 
  {
      return {
          langInfo: this.$i18n,
          globalState: this.$globalStore.state
      };
  },
  computed: {
      langs() 
      {
           return Object.keys( this.$i18n.messages );
      },
      logoSrc: function()
      { 
           return require( '../assets/logo_EPM.svg' );  
      },
      newNoti()
      {
          let cnt = 0;
          this.$globalStore.state.notifications.forEach( n =>
          {
              if( n.noti.open && !n.noti.viewed )
                  cnt++;
          } );
          return cnt;
      }
  },
  methods: {
      openIssueList()
      {
          this.$modalDlg.sysDlgs.issueListDlg.open();
      },
      newMsg( info )
      {
          let self = this;
          if( !info )
              info = {}; 
 //console.log( info );         
          self.$modalDlg.sysDlgs.addP2PMsgDlg.open( info.node, info.file, info.subj, info.users, info.type,
                                                    info.status, info.end_date, info.proj )
                .then( data =>
          {
            //  console.log( data );
              return conf.addP2PMsg( data.subj, data.text, data.users, data.node, data.doc, info.id,
                                        data.type, data.status, data.end_date, info.proj );
          }, () => {} )
                .then( rez => 
          {
             // console.log( rez );
              if( rez )
              {
                   self.$modalDlg.sysDlgs.confirmDlg.openOkOnly( 'send_msg_title', 'send_msg_msg' );
                   return conf.listUserNTasks( info.proj );
              }
              else if( rez !== undefined )
                  self.$modalDlg.sysDlgs.confirmDlg.openWarning( 'send_msg_title', 'not_send_msg_msg', true );
          })
                  .then( data => 
          {
              if( data )
                  self.$globalStore.procProjNotifications( ( info.proj || conf.getProj() ).in_project, data );
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      openNotify()
      {
          let self = this;
          self.$modalDlg.sysDlgs.userNotiDlg.open();
      },
      openHelp()
      {
          let link = '';
          if( this.type === 'props' )
              link = 'Reference-data-definitions';
          else if( this.type === 'ntasks' )
              link = 'Notification';
          else if( this.type === 'orgs' )
              link = 'Organisation';
          else if( this.type === 'prods' )
              link = 'Product';
          else if( this.type === 'actions' )
              link = 'System-notifications-of-changes-in-the-Breakdown';
          else if( this.type === 'triggers' )
              link = 'System-notifications-of-changes-in-the-Breakdown';
            
          this.$utils.openHelp( link );
      },
      openUserInfo()
      {
           this.$modalDlg.sysDlgs.userInfoDlg.open();  
      },
      setLang( l )
      {
          this.$i18n.locale = l;
      },  
      toggleVis()
      {
          this.$modalDlg.toggleNav();
      },
      nodeSearch()
      {
          var self = this;
          self.$modalDlg.sysDlgs.searchNodeDlg.open()
                 .then( ( info ) =>
          {
              self.$eventBus.$emit( 'searchNode', info );            
          }, () => {} );
      },
      quickSearch()
      {
          let self = this;
          this.$modalDlg.sysDlgs.qSearchNodeDlg.open()
                  .then( ( info ) =>
          {
             self.$eventBus.$emit( 'searchQuick', info );            
          }, () => {} );
      },
      docQuickSearch()
      {
          let self = this;
          this.$modalDlg.sysDlgs.qSearchNodeDlg.open( true )
                  .then( ( info ) =>
          {
             self.$eventBus.$emit( 'searchDocQuick', info );            
          }, () => {} );
      },
      docTxtSearch()
      {
          let self = this;
          this.$modalDlg.sysDlgs.txtSearchDocDlg.open()
                  .then( ( info ) =>
          {
        //      console.log( info );
             self.$eventBus.$emit( 'searchDocTxt', info );            
          }, () => {} );
      },
      openChain()
      {
          let proj = conf.getProj();
          let url = '#/chain?';
          window.open( url + 'rep=' + proj.repository + '&model=' + proj.in_project.project_model_id + '&role=' + proj.userType, '_blank' );        
      },
      backToProj()
      {
          router.push( '/home' );          
      },
      openProps()
      {
          router.push( '/props' );          
      },
      openOrgs()
      {
          router.push( '/orgs' );          
      },
      openProds()
      {
          router.push( '/prods' );
      },
      openNTasks()
      {
          router.push( '/ntasks' );
      },
      openTriggers()
      {
          router.push( '/triggers' );
      },
      openActions()
      {
          router.push( '/actions' );
      },
      docSearch()
      {
          var self = this;
          self.$modalDlg.sysDlgs.searchDocDlg.open()
                 .then( ( info ) =>
          {
              self.$eventBus.$emit( 'searchDoc', info );            
          })
                  .catch( ( err ) => {} );
      },
      newProj()
      {
          var self = this;
          self.$modalDlg.sysDlgs.createProjDlg.open()
                  .then( ( proj ) =>
          {
              if( proj )
                  self.$eventBus.$emit( 'createProj', proj );   
          });      
      },
      openBaselines()
      {
          var self = this;
          self.$modalDlg.sysDlgs.baselineListDlg.open()
                  .then( ( bsl ) =>
          {
              if( bsl )
                  self.$eventBus.$emit( 'openBaseline', bsl );   
          });
      },
      openDiff()
      {
          var self = this;
          self.$modalDlg.sysDlgs.makeDiffDlg.open()
                  .then( ( info ) =>
          {
     //         console.log( info );
              let proj = conf.getProj();
              
              let url = '#/diff?';
              
              let obj1 = info.ver1 ? info.ver1 : info.base1;
              let obj2 = info.ver2 ? info.ver2 : info.base2;
              
              if( obj1.bkd_ver > obj2.bkd_ver )
              {
                  obj2 = info.ver1 ? info.ver1 : info.base1;
                  obj1 = info.ver2 ? info.ver2 : info.base2;
              }
              
              if( obj1.is_baseline )
                  url += 'base1=' + obj1.ver + '&ver1=' + obj1.bkd_ver;
              else
                  url += 'ver1=' + obj1.ver;
              
              if( obj2.is_baseline )
                  url += '&base2=' + obj2.ver + '&ver2=' + obj2.bkd_ver;
              else
                  url += '&ver2=' + obj2.ver;
              
              window.open( url + '&rep=' + proj.repository + '&model=' + proj.in_project.project_model_id + '&role=' + proj.userType, '_blank' );
          }, () => {});
      },
      procIssueRowMenuCmd( info )
      {
          if( info.cmd === 'openIssue' )
              this.openIssue( info.data );
          else if( info.cmd === 'openSubj' )
              this.openSubj( info.data );
          else if( info.cmd === 'addIssue' )
              this.addIssue( info.data.subj, info.data.prn, info.data.domain );
      },
      addIssue( subj, prn, domain )
      {
          let self = this;
          let newDt = {};

          if( !domain && prn )
          {
                let ind = prn.id.lastIndexOf( '-' );
                if( ind > 0 )
                    domain = prn.id.substring( 0, ind );
          }

          self.$modalDlg.sysDlgs.addIssueDlg.open( { subj: subj, masterID: prn ? prn.plcs_id : null, domain: domain } )
                .then( data =>
          {
             newDt = data;
             return conf.addIssue( data );
          }, () => {} )
                .then( issue =>
          {
              if( issue )
              {
                  let func = [];
                  if( subj )
                        func.push( conf.addIssueSubject( issue.plcs_id, subj.instance_id ) );
                  if( newDt.datetime_IssueDeadline )
                        func.push( conf.setIssueTime( issue.plcs_id, newDt.datetime_IssueDeadline, 
                                            'urn:plcs:rdl:TruePLM:IssueDeadline' ) );                       
                
                  for( let key in newDt.personsList )
                        func.push( conf.setIssuePersonList( issue.plcs_id, newDt.personsList[ key ], key ) );

                  return Promise.all( func );
              }
          })
                .then( issue => 
          {
              if( issue ) 
                   self.$modalDlg.sysDlgs.confirmDlg.openOkOnly( 'add_issue_title', 'add_issue_msg' );
          })
                .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      openSubj( info )
      {
          this.$eventBus.$emit( 'procRowMenuCmd', [ 'gotoNode', info.subjects[ 0 ].path_id ] );  
      },
      openIssue( info )
      {
          let self = this;
          let edit = false;
          self.$modalDlg.sysDlgs.addIssueDlg.open( info )
                .then( data =>
          {
      //      console.log( data );
              let func = [];
              if( data.plcs_id === info.plcs_id )
              {
                  if( data.master !== info.masterID )
                      func.push( conf.addIssueChild( data.master, info.plcs_id ) );

                  if( data.status !== info.status )
                      func.push( conf.setIssueClass( info.plcs_id, 'urn:plcs:rdl:TruePLM:IssueStatus', data.status ) );
                
                  if( data.priority !== info.priority )
                      func.push( conf.setIssueClass( info.plcs_id, 'urn:plcs:rdl:TruePLM:IssuePriority', data.priority ) );
                  
                  if( data.issue_type !== info.issue_type )
                      func.push( conf.setIssueClass( info.plcs_id, 'urn:plcs:rdl:TruePLM:GenericIssue', data.issue_type ) );
                  
                  if( data.name !== info.name )
                      func.push( conf.setIssueID( info.plcs_id, 'urn:plcs:rdl:TruePLM:IssueName', data.name ) );

                  if( data.description !== info.description )
                      func.push( conf.setIssueDescr( info.plcs_id, data.description ) );

                  if( info.responsible )
                        info.persons.push( { role_urn: 'urn:plcs:rdl:TruePLM:Responsible', person: info.responsible } );

                  let checkMap = {};
                  let writePersons = this.$utils.isEmpty( info.persons );
                  if( !writePersons )  
                  {
                        info.persons.forEach( p =>
                        {
                            let uList = data.personsList[ p.role_urn ];
                            if( !uList )
                            {
                                data.personsList[ p.role_urn ] = '';
                                writePersons = true;
                            }
                            else
                            {
                                if( uList !== '' )
                                {
                                    if( !uList.includes( p.person ) )
                                        writePersons = true;
                                    else
                                        checkMap[ p.role_urn ] = ( checkMap[ p.role_urn ] || 0 ) + 1;
                                }
                            }
                        });
                  } 

                  if( !writePersons )
                  {
                        for( let key in data.personsList )
                        {
                            if( !( ( key === 'urn:plcs:rdl:TruePLM:Responsible' && checkMap[ key ] === 1 ) 
                                    || data.personsList[ key ].length === checkMap[ key ] ) )
                                writePersons = true;
                        }
                  }

                  if( writePersons )
                  {
                        for( let key in data.personsList )
                                func.push( conf.setIssuePersonList( info.plcs_id, data.personsList[ key ], key ) );
                  }
                 
                  if( data.datetime_IssueDeadline !== info.datetime_IssueDeadline )
                      func.push( conf.setIssueTime( info.plcs_id, data.datetime_IssueDeadline, 'urn:plcs:rdl:TruePLM:IssueDeadline' ) ); 
              }
              
              return Promise.all( func );
          }, () => {} )
                .then( rez =>
          {
              if( rez && rez.length > 0 )
                  self.$modalDlg.sysDlgs.confirmDlg.open( 'edit_issue_title', 'edit_issue_msg', info.id, null, true );
          } )
                .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      }
    },
  components: {
    
  },
  mounted: function()
  {
      let self = this;
      self.$eventBus.$on( 'sendNewMsg', function( info )
      {
          self.newMsg( info );
      });
      self.$eventBus.$on( 'procIssueRowMenuCmd', function( info )
      {
          self.procIssueRowMenuCmd( info );
      });
  },
  beforeDestroy: function()
  {
      this.$eventBus.$off( 'procIssueRowMenuCmd' );
      this.$eventBus.$off( 'sendNewMsg' );
  }
}
</script>

<style scoped>
    @-moz-document url-prefix()
    {
        .i_correct > div > i
        {
           padding-top: 10px;
        }
    }
</style>




