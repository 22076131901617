<template>
            <v-dialog v-model="visFl" persistent max-width="290">
               <v-card>
                <v-card-title class="headline">
                    <span v-if="isNew">{{ $t( 'message.create_user_title' ) }}</span>
                    <span v-else>{{ $t( 'message.edit_user_title' ) }}</span>
                </v-card-title>
                <v-card-text>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-text-field :label="$t( 'message.create_user_name' )" required v-model="name" id="ename"
                             @input="checkName" :disabled="!isNew"          
                            :rules="[v => !!v || $t( 'message.value_required' )]"></v-text-field>
                        <v-text-field v-if="isNew" :label="$t( 'message.create_user_pass' )" required v-model="pass" type="password"  id="epass"
                            :rules="[v => !!v || $t( 'message.value_required' )]" autocomplete="off"></v-text-field>
                        <v-text-field :label="$t( 'message.create_user_real_name' )" v-model="rName"></v-text-field>
                        <v-text-field :label="$t( 'message.create_user_email' )" v-model="email"></v-text-field>
                        <v-text-field :label="$t( 'message.create_user_org' )" v-model="org"></v-text-field>
                    </v-form>
                    <!--<small>*{{ $t( 'message.req_info_msg' ) }}</small>-->
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text :disabled="!valid" @click.native="doAct()">{{ $t( 'message.ok_btn' ) }}</v-btn>
                    <v-btn color="green darken-1" text @click.native="close()">{{ $t( 'message.cancel_btn' ) }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>   
</template>

<script>
    
import Vue from 'vue'

export default {
  name: 'createUser',
  data () 
  {
    return {
        visFl: false,
        isNew: false,
        name: '',
        rName: '',
        email: '',
        org: '',
        pass: '',
        resolve: null,
        reject: null,
        valid: true
    };
  },
  computed: {
  },
  methods: {
      checkName( val )
      {
          this.name = val.toLowerCase();
      },
      open( info )
      {
          this.isNew = !info;
          if( this.isNew )
          {
              this.name = '';
              this.rName = null;
              this.org = null;
              this.pass = null;
              this.email = null;
          }
          else
          {
              this.name = info.name.toLowerCase();
              this.rName = info.rName;
              this.org = info.org;
              this.pass = null;
              this.email = info.email;
          }
          
          this.visFl = true;
          
          return new Promise( ( resolve, reject ) => 
          {
            this.resolve = resolve;
            this.reject = reject;
          });
      },
      close()
      {
          this.visFl = false;
          this.reject();
      },
      doAct()
      {
          if( this.$refs.form.validate() )
          {
            this.visFl = false;
            this.resolve( { name: this.name, org: this.org, rName: this.rName, email: this.email, pass: this.pass } );
          }
      }
    }
}
</script>



