<template>
    
            <v-dialog v-model="visFl" persistent max-width="1100">
                <v-toolbar flat color="white">
                    <v-toolbar-title >{{ name }}</v-toolbar-title>
               </v-toolbar>
               <v-card>
                <v-card-text>
                    <v-data-table dense :headers="headers" :items="propData" hide-default-footer class="elevation-1 propTbl" 
                                  :items-per-page=-1 fixed-header>
                        <template v-slot:header.name="{ header }">
                            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
                        </template>
                        <template v-slot:header.val="{ header }">
                            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
                        </template>
                        
                        <template v-slot:item="{ item }">
                            <tr>
                              <td>{{ $utils.clearType( item.name ) }}</td>
                              <td>{{ item.val }}</td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click.native="close()">{{ $t( 'message.close_btn' ) }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>   
    
</template>

<script>
    
import Vue from 'vue'
import{ conf } from '../../utils/confTruePLM';

export default {
  name: 'propList',
  data () 
  {
    return {
        globalState: this.$globalStore.state,
        visFl: false,
        headers: [
            { text: 'message.propsL_name_col', value: 'name', width: '40%' },
            { text: 'message.propsL_val_col', value: 'val' }
        ],
        propData : [],
        info: {},
        name: '',
        resolve: null,
        reject: null
    };
  },
  computed: {
  },
  methods: {
      getData()
      {
          this.propData = [];
          let self = this;
          let refParts = this.info.ref.split( '.' );
          conf.searchInOtherModelByGuid( this.info.rep, refParts[ 0 ], refParts[ 1 ] )
                  .then( ( data ) =>
          {
              console.log( data );
              self.propData = data.element.properties;
          });
      },
      open( name, info )
      {
          this.name = name;
          this.info = info;
          this.getData();
     
          let self = this;
          self.visFl = true;
          
          return new Promise( ( resolve, reject ) => 
          {
              self.resolve = resolve;
              self.reject = reject;
          });          
      },
      close()
      {
          this.visFl = false;
          this.resolve();
      }
    }
}
</script>

<style scoped>
    .propTbl td
    {
        height: 30px;
    }        
</style>



