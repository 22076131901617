<template>
    <v-data-table dense :headers="headers" :items="diffVals" hide-default-footer class="elevation-1 propTbl" 
                  :items-per-page=-1 fixed-header height="calc( 60vh - 115px )">
       <template v-slot:header.elem="{ header }">
            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
       </template>
       
       <template v-slot:item="{ item }">
          <tr :active="!!selected && selected.ind == item.ind" 
                          @click="selectRow( item )">
         <td><v-img :src="item.img" width="24"></v-img></td>
         <td>{{ item.name }}</td>
         <td>{{ item.elem }}</td>
         <td v-bind:class="{ removedT: item.type === 'Removed', modifiedT: item.type === 'Modified', addedT: item.type === 'Added' }">
             {{ item.type }}
         </td>
         <td>{{ item.ver1 }}</td>
         <td>{{ item.ver2 }}</td>
         </tr>
       </template>
    </v-data-table>                             
</template>

<script>

import{ conf } from '@/utils/confTruePLM';

export default {
  name: 'diffTbl',
  props: {
      prms: null,
      dataTbl: null
  },
  data () 
  {
    return {
        headers: [
            { text: 'message.diff_type_col', value: 'elem', width: '5%' },
            { text: 'message.diff_name_col', value: 'elem' },
            { text: 'message.diff_elem_col', value: 'elem' },
            { text: 'message.diff_diff_col', value: 'elem' },
            { text: 'message.diff_ver1_col', value: 'elem', sortable: false, width: '5%' },
            { text: 'message.diff_ver2_col', value: 'elem', sortable: false, width: '5%' }
        ],
        diffVals : [],
        selected: null
    };
  },
  methods: {
      selectRow( row )
      {
          this.selected = row;
          this.$emit( 'fillDiffDetails', row );  
      },
      fillDiff( data )
      {
          if( !data )
              return;
          
          let self = this;
          let needExchange = false;
          try
          {
             needExchange = parseInt( this.prms.ver1 ) > parseInt( this.prms.ver2 );
          }
          catch( err ){ console.log( err ); }
          
          if( !data.sections || data.sections.length < 1 )
              return;
              
          self.diffVals = []; 
          var ind = 0;

          data.sections[ 0 ].differences.forEach( ( item ) =>
          {
              if( needExchange )
              {
                  let tmp = item.element_1;
                  item.element_1 = item.element_2;
                  item.element_2 = tmp;
              }

              let diff = {};
              diff.type = item.difference_type;
              diff.name = item.element_2 ? ( item.element_2.name || item.element_2.description ) : ( item.element_1 ? ( item.element_1.name || item.element_1.description ) : '' );  
              diff.elem = item.element_2 ? item.element_2.nodetype : ( item.element_1 ? item.element_1.nodetype : '' );                 
              diff.ver1 = item.element_1 ? item.element_1.version : '';                 
              diff.ver2 = item.element_2 ? item.element_2.version : '';        
              diff.img = self.$utils.getImage( diff.elem );
              if( ( item.element_1 && item.element_1.doc_instance_id )
                      || ( item.element_2 && item.element_2.doc_instance_id ) )
                  diff.img = self.$utils.getImage();

              diff.elem = self.$utils.clearType( diff.elem );
              diff.ind = ind++;
              diff.value = true;
              self.diffVals.push( diff ); 
          });
      }
    },
  watch: {
      dataTbl() 
      {
          this.fillDiff( this.dataTbl );
      }
  },
  computed: {
  },
  components: {
  },
  mounted: function()
  {
  },
  beforeDestroy: function()
  {
  }
}
</script>

<style>   
    .propTbl td
    {
        height: 30px;
    }        
    
    .propTbl div table.v-table thead tr
    {
        height: 30px;
    } 
    .modifiedT
    {
        color: blue;            
    }
    .removedT
    {
        color: red;            
    }
    .addedT
    {
        color: green;            
    }
</style>
