import { render, staticRenderFns } from "./OrgList.vue?vue&type=template&id=52470218&scoped=true&"
import script from "./OrgList.vue?vue&type=script&lang=js&"
export * from "./OrgList.vue?vue&type=script&lang=js&"
import style0 from "./OrgList.vue?vue&type=style&index=0&id=52470218&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52470218",
  null
  
)

export default component.exports