<template>
    <v-card>
      <v-data-table :headers="heads" :items="info" hide-default-footer class="elevation-1 propTbl" 
                    :items-per-page=-1 fixed-header height="calc(100vh - 130px)">
        <template v-slot:header.name="{ header }">
            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
        </template>
        <template v-slot:header.act="{ header }">
            <v-tooltip bottom v-if="proj">
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on" @click="editItem()">edit</v-icon>
                </template>
                <span>{{ $t( 'message.proj_info_edit_btn' ) }}</span>
            </v-tooltip>
        </template>
        <template v-slot:item="{ item }">
          <tr>
              <td>{{ item.num }}</td>
              <td>{{ $t( 'message.proj_info_' + item.name ) }}</td>
              <td>{{ procVal( item ) }}</td>
              <td>
                    <v-btn v-if="item.name === 'chain' && !item.val" small @click="initChain()">{{ $t( 'message.proj_info_init_chain' ) }}</v-btn>
              </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
</template>

<script>
import{ conf } from '@/utils/confTruePLM';

export default {
  name: 'ProjInfo',
  props: {
      proj: null
  },
  data () {
    return {
        heads: [
            { width: '10%', text: 'message.proj_info_num_col', value: 'name' }, 
            { text: 'message.proj_info_name_col', value: 'name', width: '30%' }, 
            { text: 'message.proj_info_val_col', value: 'name' }, 
            { width: '15%', sortable: false, value: 'act' }
        ],
        globalState: this.$globalStore.state,
//        proj: null,
        flds: [ 'name', 'project_descr', 'start_date', 'deadline', 'end_date', 'customer', 
            'partners', 'current_status', 'current_phase', 'budget', 'poNumber', 'autoVersioning', 'chain', 'dataSize' ],
        info: []
    };
  },
  methods: 
  {
      procVal( item )
      {
          if( item.name === 'current_phase' ) 
              return this.$utils.clearType( item.val );
          
          if( item.name === 'autoVersioning' ) 
              return item.val ? 'Automatic' : 'Manual';
          else if( item.name === 'chain' ) 
              return item.val ? 'Yes' : 'No';
          else
              return item.val;
          
      },
      initChain()
      {
          let self = this;
          self.$modalDlg.sysDlgs.progressDlg.open();
          
          conf.initBlockChain()
                  .then( rez =>
          {
              for( let i = 0; i < self.info.length; i++ )
              {
                  if( self.info[ i ].name === 'chain' )
                  {
                      self.info[ i ].val = rez.result === 'true';
                      break;
                  }
              }
              self.$modalDlg.sysDlgs.progressDlg.close();
           }, ( err )=>
           { 
               self.$modalDlg.sysDlgs.progressDlg.close();
               self.$eventBus.$emit( 'queryError', err ); 
           } );
      },
      editItem()
      {
          let self = this;
          this.$modalDlg.sysDlgs.setProjInfoDlg.open( self.proj.in_project )
                  .then( ( info ) =>
          {
              return conf.setProjInfo( self.proj.in_project.guid, info );
          }, () => {} )
                  .then( () => 
          {
               self.procProj();  
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      procProj()
      {
          var self = this;
          self.info = [];
          if( !self.proj || !self.proj.in_project )
              return;
          
          Promise.all( [ conf.getProjInfo( self.proj.in_project.guid ), conf.checkIsBlockChain() ] )
                  .then( ( data ) =>
          {
              self.proj.in_project = data[ 0 ]; 
              let i = 1;
              self.flds.forEach( ( fld ) =>
              {
                  let val = data[ 0 ][ fld ];
                  if( fld === 'start_date' || fld === 'deadline' || fld === 'end_date' )
                      val = ( val || '' ).split( ' ' )[ 0 ];
                  else if( fld === 'chain' )
                      val = data[ 1 ].result === 'true';
                  else if( fld === 'dataSize' )
                      val = Math.round( val * 1000 / 1024 / 1024 ) / 1000 + ' M';
                  
                  self.info.push( { num: i++, name: fld, val: val } );
              });
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      }
  },
   watch: {
      proj( val ) 
      {
          this.procProj();
      }
  },
  mounted: function() 
  { 
      var self = this;
     
      self.procProj();
  },
  beforeDestroy: function()
  {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .propTbl td
    {
        height: 30px;
    }        
</style>
