<template>
            <v-dialog v-model="visFl" persistent max-width="1100">
                <v-toolbar flat color="white">
                    <v-toolbar-title>{{ curTitle() }}</v-toolbar-title>
                    <v-tooltip bottom v-if="!!curItem">
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" @click="goBack()">reply</v-icon>
                        </template>
                        <span>{{ $t( 'message.noti_list_back' ) }}</span>
                    </v-tooltip>
                </v-toolbar>
               <v-card>
                <v-card-text>
                    <v-data-table :headers="headers" :items="notiData" hide-default-footer class="elevation-1 propTbl" 
                             :items-per-page=-1 fixed-header sort-by="date" group-by="group" :height="wHi">
                        <template v-slot:header.data.proj="{ header }">
                            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
                        </template>
                        <template v-slot:header.data.date="{ header }">
                            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
                        </template>
                        <template v-slot:header.data.user="{ header }">
                            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
                        </template>
                        <template v-slot:header.data.subj="{ header }">
                            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
                        </template>
                        <template v-slot:header.data.msg="{ header }">
                            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
                        </template>
                        <template v-slot:header.data.status="{ header }">
                            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
                        </template>
                        <template v-slot:header.act="{ header }">
                        </template>
                        <template v-slot:group.header="{ items, isOpen, toggle }">
                            <th colspan="7" v-if="items.length > 1 ">
                              <v-icon @click="toggle">
                                  {{ isOpen ? 'remove' : 'add' }}
                              </v-icon>
                              Topic: {{ getSubj( items[ 0 ] ) }}</span>
                              <v-badge color="green lighten-1" v-show="getNewCnt( items ) > 0" >
                                    <span slot="badge">{{ getNewCnt( items ) }}</span>
                                    <span style="padding-left: 5px;"></span>
                              </v-badge>
                              <v-tooltip bottom v-if="!curItem || items[ 0 ].group !== curItem.id">
                                <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" @click="goIntoItem( items[ 0 ] )" style="padding-left: 10px;">navigate_next</v-icon>
                                </template>
                                <span>{{ $t( 'message.noti_list_goto' ) }}</span>
                              </v-tooltip>
                            </th>
                            <th colspan="7" v-else style="height: 5px">
                              
                            </th>
                        </template>
                        <template v-slot:item="{ item }">
                            <tr>
                                  <td>{{ item.data.proj }}</td>
                                  <td>{{ item.data.date }}</td>
                                  <td>{{ item.data.pref }} {{ item.data.user }}</td>
                                  <td>{{ item.data.subj }}</td>
                                  <td>{{ item.data.msg }}</td>
                                  <td>
                                      {{ item.data.status }} {{ item.data.type }}
                                      <span v-if="item.data.task_status"><br>{{item.data.task_status}}</span>
                                      <span v-if="item.data.task_end_date"><br>Deadline: {{item.data.task_end_date}}</span>
                                  </td>
                                  <td>
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                              <v-icon v-on="on" @click="gotoItem( item.data )">folder_open</v-icon>
                                        </template>
                                        <span>{{ $t( 'message.noti_list_goto' ) }}</span>
                                      </v-tooltip>
                                      <v-tooltip bottom v-if="item.data.status === 'New'">
                                        <template v-slot:activator="{ on }">
                                              <v-icon v-on="on" @click="closeItem( item.data )">visibility_off</v-icon>
                                        </template>
                                        <span>{{ $t( 'message.noti_list_close' ) }}</span>
                                      </v-tooltip>
                                      <v-tooltip bottom v-if="item.data.status !== 'Outgoing' && item.data.type ">
                                        <template v-slot:activator="{ on }">
                                              <v-icon v-on="on" @click="replyItem( item )">send</v-icon>
                                        </template>
                                        <span>{{ $t( 'message.noti_list_replay' ) }}</span>
                                      </v-tooltip>
                                      <v-tooltip bottom v-if="item.data.type === 'Task' && item.id === item.group">
                                        <template v-slot:activator="{ on }">
                                              <v-icon v-on="on" @click="addIssue( item )">bug_report</v-icon>
                                        </template>
                                        <span>{{ $t( 'message.noti_list_issue' ) }}</span>
                                      </v-tooltip>
                                      <v-tooltip bottom v-if="item.data.type === 'Issue' && item.id === item.group">
                                        <template v-slot:activator="{ on }">
                                              <v-icon v-on="on" @click="addAction( item )">star</v-icon>
                                        </template>
                                        <span>{{ $t( 'message.noti_list_action' ) }}</span>
                                      </v-tooltip>
                                  </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click.native="close()">{{ $t( 'message.close_btn' ) }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>   
    
</template>

<script>
    
import Vue from 'vue'
import{ conf } from '@/utils/confTruePLM'

export default {
  name: 'userNotiList',
  data () 
  {
    return {
        visFl: false,
        headers: [
            { text: 'message.noti_list_proj', value: 'data.proj' },
            { text: 'message.noti_list_date', value: 'data.date' },
            { text: 'message.noti_list_user', value: 'data.user' },
            { text: 'message.noti_list_subj', value: 'data.subj' },
            { text: 'message.noti_list_msg', value: 'data.msg' },
            { text: 'message.noti_list_status', value: 'data.status' },
            { text: 'message.noti_list_act', value: 'act', sortable: false }
        ],
        curItem: null,
        notiData : [],
        originMap: {},
        globalState: this.$globalStore.state,
        resolve: null,
        reject: null
    };
  },
  computed: {
      wHi()
      {
             return "calc( ( 100vh - 200px ) )";
      }
  },
  methods: {
      getNewNumber( items )
      {
            let cnt = this.getNewCnt( items );
            if( cnt > 0 )
                return '(' + cnt + ')';
            else
                return '';
      },
      getNewCnt( items )
      {
            let cnt = 0;
            for( let i = 0; i < items.length; i++ )
            {
                let el = items[ i ];
            
                if( el.data.status === 'New' )
                    cnt++;

                if( el.children.length > 0 && el.id !== el.group )
                    cnt += this.getNewCnt( el.children );
            }

            return cnt;
      },
      curTitle()
      {
            if( this.curItem )
                return this.curItem.data.subj;
            else
                return this.$t( 'message.noti_list_title' );
      },   
      getSubj( item )
      {
          let originItem = this.originMap[ item.data.start ];
          if( originItem )
              return originItem.data.subj;
          else
              return item.data.subj;
      },
      open()
      {
          this.initData();
          this.visFl = true;
          
          var self = this;                    
          return new Promise( ( resolve, reject ) => 
          {
              self.resolve = resolve;
              self.reject = reject;
          });          
      },
      goBack()
      {
            if( this.curItem.prn )
            {
                let originItem = this.originMap[ this.curItem.prn ];
                this.notiData = originItem.children;
                this.curItem = originItem;
            }
            else
            {
                this.notiData = this.fullData;
                this.curItem = null;
            }

      },
      goIntoItem( item )
      {
            let originItem = this.originMap[ item.group ];
            this.notiData = originItem.children;
            this.curItem = originItem;
      },
      addAction( item )
      {
          item = item.data;
          conf.cancelNTaskForUser( item.obj.proj, item.obj.noti )
                  .then( rez => 
          {
              if( rez )
                  item.obj.noti.open = false;
          });
          
          let doc = null;
          if( item.obj.noti.event.prms.Msg_doc.val )
              doc = JSON.parse( item.obj.noti.event.prms.Msg_doc.val );
          
          let node = null;
          if( item.obj.noti.event.prms.Msg_node.val )
              node = JSON.parse( item.obj.noti.event.prms.Msg_node.val );
          
          this.$eventBus.$emit( 'sendNewMsg', { proj: item.obj.proj, node: node, file: doc, 
                    subj: item.obj.noti.event.prms.Msg_subj.val, users: item.user.split( ',' ), 
                    id: item.obj.noti.serial,
                    type: "Action",
                    status: ( item.obj.noti.event.prms.Task_status || {} ).val,
                    end_date: ( item.obj.noti.event.prms.Task_end_date || {} ).val } );
                
          this.close();

      },
      addIssue( item )
      {
          item = item.data;
          conf.cancelNTaskForUser( item.obj.proj, item.obj.noti )
                  .then( rez => 
          {
              if( rez )
                  item.obj.noti.open = false;
          });
          
          let doc = null;
          if( item.obj.noti.event.prms.Msg_doc.val )
              doc = JSON.parse( item.obj.noti.event.prms.Msg_doc.val );
          
          let node = null;
          if( item.obj.noti.event.prms.Msg_node.val )
              node = JSON.parse( item.obj.noti.event.prms.Msg_node.val );
          
          this.$eventBus.$emit( 'sendNewMsg', { proj: item.obj.proj, node: node, file: doc, 
                    subj: item.obj.noti.event.prms.Msg_subj.val, users: item.user.split( ',' ), 
                    id: item.obj.noti.serial,
                    type: "Issue",
                    status: ( item.obj.noti.event.prms.Task_status || {} ).val,
                    end_date: ( item.obj.noti.event.prms.Task_end_date || {} ).val } );
                
          this.close();

      },
      replyItem( item )
      {
          item = item.data;
          conf.cancelNTaskForUser( item.obj.proj, item.obj.noti )
                  .then( rez => 
          {
              if( rez )
                  item.obj.noti.open = false;
          });
          
          let doc = null;
          if( item.obj.noti.event.prms.Msg_doc.val )
              doc = JSON.parse( item.obj.noti.event.prms.Msg_doc.val );
          
          let node = null;
          if( item.obj.noti.event.prms.Msg_node.val )
              node = JSON.parse( item.obj.noti.event.prms.Msg_node.val );

          let startVal = item.obj.noti.serial;
          let originItem = this.originMap[ item.start ];
          if( originItem && originItem.type === item.type && item.obj.noti.event.prms.Msg_start.val )
              startVal = item.obj.noti.event.prms.Msg_start.val;
       
          this.$eventBus.$emit( 'sendNewMsg', { proj: item.obj.proj, node: node, file: doc, 
                    subj: item.obj.noti.event.prms.Msg_subj.val, users: [ item.user ], 
                    id: startVal,
                    type: item.obj.noti.event.prms.Msg_type.val,
                    status: ( item.obj.noti.event.prms.Task_status || {} ).val,
                    end_date: ( item.obj.noti.event.prms.Task_end_date || {} ).val } );
                
          this.close();
      },
      initData()
      {
          this.fullData = [];
          this.$globalStore.state.notifications.forEach( n =>
          {
              let data = { obj: n };
              data.proj = n.proj.in_project.name;
              data.date = this.$utils.getLocalDateFromUTC( n.noti.event.timestamp );
              data.pref = 'from';
              data.user = n.noti.event.user;

              if( !data.status )
                data.status = ( n.noti.open && !n.noti.viewed ) ? 'New' : '';

              let item = { id: data.proj + ' ' + n.noti.serial, data: data, children: [] };
              item.group = item.id;
              
              if( n.noti.event.name === 'Broadcast_message')
              {
                  if( n.noti.event.user === this.$globalStore.state.curUser )
                  {
                      data.user = null;
                      data.status = 'Outgoing';
                      data.pref = 'to';
                  }

                  if( n.noti.event.prms.Msg_type )
                      data.type = n.noti.event.prms.Msg_type.val;
                  if( n.noti.event.prms.Task_status )
                      data.task_status = n.noti.event.prms.Task_status.val;
                  if( n.noti.event.prms.Task_end_date )
                      data.task_end_date = this.$utils.getLocalDateFromUTC( n.noti.event.prms.Task_end_date.val, true );
                  if( n.noti.event.prms.Msg_subj )
                      data.subj = n.noti.event.prms.Msg_subj.val;
                  if( n.noti.event.prms.Msg_text )
                      data.msg = n.noti.event.prms.Msg_text.val;
                  if( n.noti.event.prms.Msg_user && !data.user )
                      data.user = n.noti.event.prms.Msg_user.val;

                  if( n.noti.event.prms.Msg_start.val )
                  {
                      item.group = data.proj + ' ' + n.noti.event.prms.Msg_start.val;
                      item.prn = item.group;
                  }
                  else
                      this.originMap[ item.id ] = item;
              }
              else
                data.msg = n.noti.resolved_message;
            
              let originItem = this.originMap[ item.group ];
              if( originItem )
              {
                      if( originItem.data.type !== item.data.type )
                      {
                          this.originMap[ item.id ] = item;
                          originItem.children.push( { id: item.id, group: item.id, children: [], data: item.data } );
                          item.children.push( { id: item.id, group: item.id, children: [], data: item.data } );
                      }
                      else
                          originItem.children.push( item );

                      if( !originItem.prn )
                            this.fullData.push( item );
                      else
                      {
                            let prnItem = this.originMap[ originItem.prn ];
                            if( prnItem )
                                prnItem.children.push( item );
                      }      
              }
              else 
                  this.fullData.push( item );
          });

          if( this.curItem )
                this.curItem = this.originMap[ this.curItem.id ];
          if( this.curItem )
                this.notiData = this.curItem.children;
          else
                this.notiData = this.fullData;
      },
      closeItem( item )
      {
          let self = this;
                  
          self.$modalDlg.sysDlgs.progressDlg.open();
          conf.cancelNTaskForUser( item.obj.proj, item.obj.noti )
                  .then( rez => 
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              if( rez )
              {
                  item.obj.noti.open = false;
                  self.initData();
              }
          })
                  .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      gotoItem( item )
      {
          let nodeID = '';
          let prms = item.obj.noti.event.prms;
          if( prms.Node_id )
              nodeID = '' + prms.Node_id.val;
          else if( prms.Msg_node && prms.Msg_node.val )
              nodeID = '' + JSON.parse( prms.Msg_node.val ).instance_id;  
          let paths = [ '', '', item.obj.proj.in_project.guid, nodeID ];
          
          if( prms.Msg_doc && prms.Msg_doc.val )
              paths.push( JSON.parse( prms.Msg_doc.val ).instance_id );
          
          this.$eventBus.$emit( 'openProjFromURL', { paths } );
          this.close();
      },
      close()
      {
          this.visFl = false;
          this.resolve();
      }
    }
}
</script>

<style scoped>
    .propTbl td
    {
        height: 30px;
    }        
</style>



