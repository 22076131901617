<template>
    <v-card style="height: calc(100vh - 181px);">
        <div class="v-data-table elevation-1 propTbl v-data-table--fixed-header theme--light">
            <div class="v-data-table__wrapper">           
                <table>
                    <thead class="v-data-table-header">
                        <tr>
                            <th class="text-start sortable" width="92%">
                                <!--<span style="padding-left: 50px">{{ $t( 'message.prop_value_col' ) }}</span>-->
                                <v-text-field :label="$t( 'message.prop_value_col' )" v-model="search" clearable ></v-text-field>
                            </th>
                            <th class="text-start sortable">
                                <v-tooltip bottom v-if="prop.rdlName !== 'urn:plcs:rdl:TruePLM:System_event'">
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" @click="addItem()">add</v-icon>
                                    </template>
                                    <span>{{ $t( 'message.prop_value_type_add_btn' ) }}</span>
                                </v-tooltip>
                            </th>
                        </tr>
                    </thead>
                </table>
            </div>
        </div>
        <div style="height: calc(100vh - 240px); overflow-y: auto">
            <v-treeview dense :items="elems" transition style="font-size: 14px;"
                        :search="search" :filter="filter" :open="open">
                <template v-slot:label="{ item, open }">
                    <span :class="{ abstr: item.abstr }">{{ item.name }}</span>
                </template>
                <template v-slot:append="{ item, open }">
                    <v-tooltip bottom v-if="prop.rdlName !== 'urn:plcs:rdl:TruePLM:System_event'">
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" small @click="addItem( item.val )">add</v-icon>
                        </template>
                        <span>{{ $t( 'message.prop_type_add_btn' ) }}</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="!item.inUse">
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" small @click="deleteItem( item )">delete</v-icon>
                        </template>
                        <span>{{ $t( 'message.prop_type_del_btn' ) }}</span>
                    </v-tooltip>
                    <span v-else style="width: 37px; display: block;"></span>
                </template>
            </v-treeview>
<!--            <ul class="tree_view v-list--dense">
                <tree-row-view v-for="( el, ind ) in elems" :key="ind" pkey:="ind" :node="el"
                               :readOnly="prop.rdlName === 'urn:plcs:rdl:TruePLM:System_event'"></tree-row-view>
            </ul>-->
        </div>
    </v-card>
</template>

<script>
    
import{ conf } from '@/utils/confTruePLM';
import TreeRow from '../Tree/TreeRow.vue'

export default {
  name: 'PropTreeInfo',
  props: {
      prop: null
  },
  data () {
    return {
        
        model: this.prop,
        elems: [],
        search: null,
        open: [],
        elemInd: 0,
        openTmp: []
    };
  },
  watch: {
      prop( val ) 
      {
          this.updateModel();
      }
  },
  computed: {
      filter () 
      {
            return ( item, search, textKey ) => 
            { 
                return item[ textKey ].indexOf( search ) > -1;
            };
      }
  },
  methods: 
  {
      addItem( prnProp )
      {
          let self = this;
          let title = prnProp ? 'refdata_add_subtype_title' : 'refdata_add_type_title';
          let msg = prnProp ? 'refdata_add_subtype_msg' : 'refdata_add_type_msg';
          this.$modalDlg.sysDlgs.confirmOneValDlg.open( title, msg, '', this.$utils.clearType( prnProp || self.prop.rdlName ) )
                  .then( ( val ) =>
          {
              return conf.addRefDataClass( val, prnProp || self.prop.rdlName );
          }, () => {} )
                  .then( () => 
          {
               self.updateModel( self.prop );  
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      deleteItem( prop )
      {
          let self = this;
          this.$modalDlg.sysDlgs.confirmDlg.open( 'refdata_delete_title', 'refdata_delete_msg' )
                  .then( () =>
          {
              return conf.deleteRefData( prop.val, prop.prn );
          }, () => {} )
                  .then( () =>
          {
              self.updateModel( self.model );              
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      updateModel()
      {
          this.propVals = [];
          this.model = this.prop;
          let self = this;
          let els = [];
          this.elemInd = 0;
          this.openTmp = [];
          
          self.$modalDlg.sysDlgs.progressDlg.open();
          
          conf.getChildTypesTree( this.model.rdlName )
                  .then( ( data ) =>
          {
              data.forEach( ( elem ) =>
              {
                  els.push( self.getTypeElem( elem, self.model.rdlName ) );                                       
              });     
              
              self.$utils.sortArray( els, ( a ) => ( a.name ) );
              
              if( self.model.rdlName === 'urn:plcs:rdl:TruePLM:System_event' )
                  return conf.getChildTypesTree( 'urn:plcs:rdl:TruePLM:User_defined_event' );
              else
                  return Promise.resolve();
          } )
                  .then( ( data ) =>
          {
              if( data )
              {
                  data.forEach( ( elem ) =>
                  {
                      els.push( self.getTypeElem( elem, self.model.rdlName ) );                                       
                  }); 
              }
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.elems = els;

              self.open = self.openTmp;
          })
                  .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      getTypeElem( data, prn )
      {
          let rdl = data.rdlClass.rdlInfo;
          let elem = { val: rdl.class_uri, name: rdl.name || this.$utils.clearType( rdl.class_uri ),  
              prn: prn, inUse: data.rdlClass.inUse || ( rdl.status === 'CHECKED' ), id: this.elemInd++,
              abstr: rdl.abstr }; 
          
          if( data.subtypes && data.subtypes.length > 0 )
          {
              elem.children = [];
              this.openTmp.push( elem.id );
          }
          
          data.subtypes.forEach( st =>
          {
              elem.children.push( this.getTypeElem( st, rdl.class_uri ) );
          } );
          this.$utils.sortArray( elem.subtypes, ( a ) => ( a.name ) );
          return elem;
      }
  },
  components: {
    'tree-row-view': TreeRow
  },
  mounted: function() 
  { 
      this.updateModel();
      
      let self = this;
      self.$eventBus.$on( 'addBkdProp', function( prop )
      {
          self.addItem( prop.val );
      });
      self.$eventBus.$on( 'deleteBkdProp', function( prop )
      {
          self.deleteItem( prop );
      });
  },   
  beforeDestroy: function()
  {
      this.$eventBus.$off( 'addBkdProp' );
      this.$eventBus.$off( 'deleteBkdProp' );
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .propTbl td
    {
        height: 30px;
    }    
    
    .abstr
    {
        font-style: italic;
        color: #929292;
    }
    
    .tree_view
    {
        list-style-type: none;
    }
</style>
