<template>
        <v-dialog v-model="visFl" persistent max-width="290">
           <v-card>
            <v-card-title class="headline" v-if="edit">
              {{ $t( 'message.edit_node_title' ) }}
            </v-card-title>
            <v-card-title class="headline" v-else>
              {{ $t( 'message.create_node_title' ) }}
            </v-card-title>
            <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-text-field :label="$t( 'message.create_prod_name' )" required v-model="name" :disabled="edit"
                        :rules="[v => !!v || $t( 'message.value_required' )]"></v-text-field>
                    <v-textarea :label="$t( 'message.create_prod_descr' )" v-model="descr"></v-textarea>
                    <v-select :label="$t( 'message.create_prod_part_type' )" required item-text="txt" item-value="val" v-model="selectedTypes"
                        :items="types" :rules="[v => { if( v.length < 1 ) return $t( 'message.value_required' ); else return true; } ]" multiple></v-select>
                    <v-select :label="$t( 'message.create_prod_domain' )" required item-text="txt" item-value="val" v-model="domain"
                        :items="domains" :rules="[v => !!v || $t( 'message.value_required' )]"></v-select>
                    <v-select :label="$t( 'message.create_prod_stage' )" required item-text="txt" item-value="val" v-model="stage"
                        :items="stages" :rules="[v => !!v || $t( 'message.value_required' )]"></v-select>
                </v-form>
                <!--<small>*{{ $t( 'message.req_info_msg' ) }}</small>-->
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
                <v-btn color="green darken-1" text :disabled="!valid" @click.native="doAct()">{{ $t( 'message.ok_btn' ) }}</v-btn>
                <v-btn color="green darken-1" text @click.native="close()">{{ $t( 'message.cancel_btn' ) }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>   
</template>

<script>
    
import Vue from 'vue'
import{ conf } from '@/utils/confTruePLM';

export default {
  name: 'createProd',
  data () 
  {
    return {
        visFl: false,
        name: '',
        descr: '',
        types: [],
        domains: [],
        stages: [],
        selectedType: null,
        selectedTypes: [],
        domain: null,
        stage: null,
        resolve: null,
        reject: null,
        valid: true,
        edit: false
    };
  },
  computed: {
  },
  methods: {
      open( info )
      {
          this.types = [];
          if( info )
          {
              this.name = info.name;
              this.descr = info.description;
              this.selectedTypes = this.$utils.noGUID( info.element_type );
              this.stage = info.stage;
              this.domain = info.domain;
              
              this.edit = true;
              this.has_parent = !!info.parent;
          }
          else
          {
              this.name = null;
              this.descr = null;
              this.selectedTypes = [];
              this.selectedType = null;
              this.stage = null;
              this.domain = null;
              
              this.edit = false;
              this.types = [];
              conf.getChildTypesInContext( 'urn:rdl:epm-std:Part_type' )
                        .then( ( data ) =>
                {
                    data.forEach( ( elem ) =>
                    {
                        this.types.push( { val: elem, txt: this.$utils.clearType( elem ) } );
                    });
                });
           }
           
           this.domains = [];
           conf.getChildTypesInContext( 'urn:plcs:rdl:std:Application_domain' )
                    .then( ( data ) =>
            {
                data.forEach( ( elem ) =>
                {
                    this.domains.push( { val: elem, txt: this.$utils.clearType( elem ) } );
                });
            });
                
           this.stages = [];
           conf.getChildTypesInContext( 'urn:plcs:rdl:std:Life_cycle_stage' )
                    .then( ( data ) =>
            {
                data.forEach( ( elem ) =>
                {
                    this.stages.push( { val: elem, txt: this.$utils.clearType( elem ) } );
                });
            });     
           
          
           this.visFl = true;
          
           return new Promise( ( resolve, reject ) => 
           {
                this.resolve = resolve;
                this.reject = reject;
           });
      },
      close()
      {
          this.visFl = false;
          this.reject();
      },
      doAct()
      {
          if( this.$refs.form.validate() )
          {
                this.visFl = false;
                if( this.selectedTypes.length < 1 )
                    this.selectedTypes = [ this.selectedType ];
                
                this.resolve( { name: this.name, descr: this.descr, types: this.selectedTypes, 
                                stage: this.stage, domain: this.domain } );
          }
      }
    }
}
</script>



