<template>
      <div>
        <div style="padding-left: 10px; display: flex; justify-content: flex-start; align-items: center">
            <span style="font-size: .85rem;">Path:</span>
            <v-menu bottom :close-on-content-click="true">
                <template v-slot:activator="{ on: onMenu }">
                    <v-tooltip bottom v-show="pathView">
                        <template #activator="{ on: onTooltip }">
                          <v-btn dark v-on="{ ...onMenu, ...onTooltip }" plain small outlined color="black" 
                                style="border-style: hidden; text-transform: none;">
                             {{ path ? path[ path.length - 1 ] : ' ' }}
                         </v-btn>
                        </template>
                        <span>{{pathView}}</span>
                    </v-tooltip>
                </template>
                <v-list v-if="path">
                    <v-list-item v-for="(item, index) in path" :key="index">
                      <v-list-item-title>{{ item }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
            </v-menu>
        </div>
        <v-card :class="smallWin ? 'smallSize' : ( showPager ? 'normalSizeWithPage' : 'normalSize' )" style="overflow-y: auto;">
            <v-list dense>
                <prod-list-row-view v-if="backNode.element_type === 'product_elem'" :model="backNode" :noMenu="true"></prod-list-row-view>
                <node-list-row-view v-else-if="backNode.name" :model="backNode" :noMenu="true"></node-list-row-view>
                
                <node-list-row-view v-for="( node, index ) in nodeList" :model="node" :key="'n' + index" :noMenu="true"></node-list-row-view>
                <prod-list-row-view v-for="( prod, index ) in prodList" :model="prod" :key="'p' + index" :noMenu="true"></prod-list-row-view>
                <node-list-file-row-view v-for="file in curModel.data_contents" :model="file" :noMenu="true"
                                         :node="curModel" :key="file.instance_id"></node-list-file-row-view>
            </v-list>
        </v-card>
        <v-pagination v-model="curPage" class="my-4" :length="pageCnt" v-show="showPager" @input="updatePage"></v-pagination>
    </div>                           
</template>

<script>

import{ conf } from '@/utils/confTruePLM';
import NodeListRow from '@/components/NodeListRow.vue';
import ProdListRow from '@/components/ProdListRow.vue';
import FileListRow from '@/components/FileListRow.vue';

export default {
  name: 'diffFld',
  props: {
      prms: null,
      path: null,
      elem: null
  },
  data () 
  {
    return {
        pageCnt: 1,
        curPage: 1,
        showPager: false,
        smallWin:  false,
        backNode: {},
        nodeList: [],
        prodList: [],
        curModel: {},
        pageSize: 100
    };
  },
  methods: {
      updateData( elem )
      {
          this.nodeList = [];
          this.curModel = {};
          this.showPager = false;
          
          if( !elem )
              return;
          
          this.curModel = elem.element;
          this.nodeList = elem.element.children;
          this.pageCnt = Math.floor( this.curModel.children_count / this.pageSize ) + 1;
          this.showPager = this.pageCnt > 1;
          
          this.curPage = 1;
      },
      
      updatePage()
      {
          if( !this.elem )
              return;
          
          let self = this;
          let strInd = ( this.curPage - 1 ) * this.pageSize + 1;
          self.$modalDlg.sysDlgs.progressDlg.open();
          conf.getNodeChildren( this.elem.element, this.prms.ver, strInd, this.pageSize, false )
                .then( data =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              this.curModel = data.element;
              this.nodeList = data.element.children;
          })
                .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      
      fillData( info )
      {
          if( !info )
              return;
          
          var self = this;
          if( info.base )
          {
            conf.getBaselineList()
                  .then( ( data ) => 
            {
                for( let i = 0; i < data.length; i++ )
                {
                    if( data[ i ].id === info.base )
                    {
                        self.openTree( data[ i ].root_bkdn_elem, { version_id:  data[ i ].in_bkdn_version } );
                        self.$eventBus.$emit( 'openedTreeFromRoot', [ { type: self.type, root: data[ i ].root_bkdn_elem } ] );   
                        break;
                    }
                }
            })
                .catch( ( err ) =>
            {
                  self.$eventBus.$emit( 'queryError', err );
            });
          }
          else if( info.ver )
          {
            conf.getPBS( info.ver ).then( ( data )=>
            {
                self.nodeList = [ data.root_bkdn_elem ];
            })
                .catch( ( err ) =>
            {
                  self.$eventBus.$emit( 'queryError', err );
            });
          }
      }
    },
  watch: {
      elem() 
      {
          this.updateData( this.elem );
      }
  },
  computed: {
      pathView()
      {
          let str = '';
          if( this.path )
          {
              this.path.forEach( p =>
              {
                  str += p + ' / ';
              });
          }
          
          return str;
      }
  },
  components: {
      'node-list-row-view': NodeListRow,
      'prod-list-row-view': ProdListRow,
      'node-list-file-row-view': FileListRow,
  },
  mounted: function()
  {
      var self = this;
      
  },
  beforeDestroy: function()
  {
      
  }
}
</script>

<style scoped>
  
  .scroll 
  {
      overflow-y: auto;
  }
  .smallSize
  {
        height: calc(100vh - 105px);
  }
  .normalSizeWithPage
  {
        height: calc(100vh - 251px);
  }
  .normalSize
  {
        height: calc(100vh - 180px);
  }
  
</style>
