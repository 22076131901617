<template>
    <v-dialog v-model="visFl" persistent max-width="320">
       <v-card>
        <v-card-title class="headline">
          <span v-if="type === 'ap242'">{{ $t( 'message.importAP242_file_title' ) }}</span>
          <span v-else-if="type === 'csv'">{{ $t( 'message.importCSV_file_title' ) }}</span>
          <span v-else-if="type === 'reqif'">{{ $t( 'message.importReqIF_file_title' ) }}</span>
          <span v-else-if="type === 'txt'">{{ $t( 'message.import_zip_title' ) }}</span>
          <span v-else-if="type === 'json_prop'">{{ $t( 'message.upload_json_title' ) }}</span>
          <span v-else-if="type === 'aggr_prop'">{{ $t( 'message.upload_aggr_title' ) }}</span>
          <span v-else-if="type === 'doc_tmpl'">{{ $t( 'message.upload_doc_tmpl' ) }}</span>
          <span v-else>{{ $t( 'message.import_file_title' ) }}</span>
        </v-card-title>
        <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
                <file-upload ref="fileUpload" v-on:change="fileChanged" :rules="[v => v.length > 0 || $t( 'message.file_required' )]"></file-upload>
                <v-select :label="$t( 'message.import_file_type' )" item-text="txt" item-value="val" v-model="fileType"  
                          v-show='fileTypes' :items="fileTypes" id="importFileSelect"></v-select>

                <!-- <file-upload :label="$t( 'message.import_file_pack' )" accept="application/x-zip-compressed" ref="packUpload" v-on:change="packChanged" v-show="type === 'ap242'"></file-upload> -->
            </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn id="importOk" color="green darken-1" text :disabled="!valid" @click.native="doAct()">{{ $t( 'message.ok_btn' ) }}</v-btn>
            <v-btn id="importCancel" color="green darken-1" text @click.native="close()">{{ $t( 'message.cancel_btn' ) }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>       
</template>

<script>
    
import Vue from 'vue'

import FileUpload from '../FileUpload'

export default {
  name: 'importFile',
  data () 
  {
    return {
        visFl: false,
        type: null,
        fileTypes: null,
        fileType: null,
        packType: null,
                
        resolve: null,
        reject: null,
        valid: true
    };
  },
  computed: {
      
  },
  components: {
       'file-upload': FileUpload
  },
  methods: {
      packChanged()
      {
          let files = this.$refs.packUpload.items;
          if( !files || files.length < 1 || !this.fileTypes )
              return;
          
          let typeParts = files[ 0 ].type.split( '/' );
          if( typeParts.length > 1 )
            this.packType = typeParts[ 1 ];
      },
      fileChanged()
      {
          let files = this.$refs.fileUpload.items;
          if( !files || files.length < 1 || !this.fileTypes )
              return;
          
          let typeParts = files[ 0 ].type.split( '/' );
          if( typeParts.length > 1 )
            this.fileType = typeParts[ 1 ];
      },
      open( type, fileTypes )
      {
          //console.log( type );
          this.type = type;
          this.fileTypes = fileTypes;
         
          this.visFl = true;
          
          return new Promise( ( resolve, reject ) => 
          {
            this.resolve = resolve;
            this.reject = reject;
          });
      },
      close()
      {
          this.visFl = false;
          this.$refs.fileUpload.clear();
          this.$refs.packUpload.clear();
          this.reject();
      },
      doAct()
      {
          if( this.$refs.form.validate() )
          {
            this.visFl = false;
            let files = this.$refs.fileUpload.items;
            let packs = null;//this.$refs.packUpload.items;

            this.$refs.fileUpload.clear();
            // this.$refs.packUpload.clear();
            if( ( files && files.length > 0 ) || ( packs && packs.length > 0 ) )
                this.resolve( { files: files, fileType: this.fileType, packs: packs, packType: this.packType } );
            else
                this.reject();
          }
      }
    }
}
</script>





