<template>
    
<!--      <v-container fluid fill-height>
          <v-layout align-center justify-center>-->
              <v-dialog :value="visFl" max-width="390" persistent>
                <v-card>
                  <v-card-title class="headline">
                    {{ $t( 'message.info_ttl' ) }}
                  </v-card-title>
                  <v-card-text>
                      <div v-if="curProj">
                          <div><strong>{{ $t( 'message.info_proj_ttl' ) }}: </strong> {{curProj}}</div>
                           <div>{{ $t( 'message.info_pphase_ttl' ) }}: {{curPPhase}}</div>
                           <div>{{ $t( 'message.info_pdescr_ttl' ) }}: {{curProjDescr}}</div>
                        <br>
                      </div>
                      <div v-if="curProj">
                        <div><strong>{{ $t( 'message.info_user_ttl' ) }}: </strong> {{curUser}} ( {{curRole}} )</div>
                        <br>
                      </div>
                      <div v-else>
                        <div><strong>{{ $t( 'message.info_user_ttl' ) }}: </strong> {{curUser}}</div>
                        <br>
                      </div>
                      <div><strong>{{ $t( 'message.support_ttl' ) }}: </strong> <a href="mailto:helpdesk@jotne.com">helpdesk@jotne.com</a></div>
                      <br>
<!--                      <div>
                          <strong>{{ $t( 'message.useful_info_ttl' ) }}: </strong> 
                          <a href="http://www2.epmtech.jotne.com/download/EDMopenSimDM/R11/User_Guide_PLM_workflow.htm" target="_blank">Use in a typical PLM-workflow</a>
                      </div>-->
                      <div v-if="serverInfo">
                           <br>
                        <div><strong>{{ $t( 'message.info_server_ttl' ) }}: </strong> EDMtruePLM ver. {{serverInfo.system_version}} 
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <a v-on="on" @click="showMore = !showMore" v-show="!showMore">show more</a>
                                </template>
                                <span v-if="showMore">{{ $t( 'message.nomore_info_link' ) }}</span>
                                <span v-else="showMore">{{ $t( 'message.more_info_link' ) }}</span>
                            </v-tooltip>
                            <transition name="slide" >
                                <div v-if="showMore"><br>
                                    <p> Web application ver. {{serverInfo.web_version}}</p>
                                    <p> {{serverInfo.plcs_info}}</p>
                                    <p> {{serverInfo.rdl_info}}</p>
                                    <p> {{serverInfo.edm_info}}</p>
                                    <a v-on="on" @click="showMore = !showMore" v-show="showMore" style="margin-left: 250px;">show less</a>
                                </div>
                            </transition>
                        </div>
                      </div>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                      <v-btn color="green darken-1" text @click.native="close">{{ $t( 'message.ok_btn' ) }}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
<!--          </v-layout>
      </v-container>-->
   
</template>

<script>
    
import{ conf } from '../../utils/confTruePLM';
import { auth } from '../../utils/auth';

export default {
  name: 'about-info',
  data () 
  {
    return {
        visFl: false,
        curProj: null,
        curUser: null,
        curRole: null,
        curPPhase: null,
        curProjDescr: null,
        serverInfo: null,
        showMore: false
    };
  },
  computed: {
  },
  methods: {
      close()
      {
          this.visFl = false;
          this.curProj = null;
          this.curUser = null;
          this.curRole = null;
          this.curPPhase = null;
          this.curProjDescr = null;
          this.server = null;
      },
      open()
      {
          this.curUser = conf.getUser().name;
          let proj = conf.getProj();
          this.curUser = this.$globalStore.state.curUser;
          
          if( proj )
              this.curRole = this.$utils.clearType( proj.userType );

          conf.getCurrentUsers()
                  .then( ( data ) =>
          {
              if( !data )
                  return;

              this.curUser = data[ 0 ].user_name;
          });
          
          if( proj )
          {
              this.curProj = proj.in_project.name;
              this.curUser = proj.user_name;
              this.curProjDescr = proj.in_project.project_descr;
              this.curRole = this.$utils.clearType( proj.userType );
              this.curPPhase = this.$utils.clearType( proj.in_project.current_phase );
          }
          this.serverInfo =  auth.getServer().info;
         // console.log( this.serverInfo );
          this.visFl = true;
      }
  },
  mounted: function() 
  { 
  },
  beforeDestroy: function()
  {
  }
}
</script>

<style scoped>
        
    .slide-enter, .slide-leave-to 
    {
        opacity: 0;
    }

    .slide-enter-to, .slide-leave 
    {
        opacity: 1;
    }
      
    .slide-enter-active, .slide-leave-active 
    {
        transition: all .5s ease-out;
    }
    p 
    {
        text-indent: 5px; 
    }
</style>


