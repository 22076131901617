<template>
    <v-dialog v-model="visFl" persistent max-width="98%">
        <v-toolbar flat color="white">
            <v-toolbar-title>{{ $t( 'message.issue_list_title' ) }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip bottom >
                <template v-slot:activator="{ on }">
                    <v-btn elevation="2" small v-on="on" @click="addIssue()" id="addIssueBtn">
                        <v-icon dark>add</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t( 'message.issue_list_add_btn' ) }}</span>
            </v-tooltip>
        </v-toolbar>
        <v-card :class="'normalSize'" style="overflow-y: hidden">
          <v-card-text>
            <search-table :headerInfo="headers" :items="items" pID="issueTbl" :wHi="wHi" 
                          :rowMenuElems="rowMenu" @selectRow="selectRow" @rowMenuClick="rowMenuClick"
                          @rowDblClick="rowDblClick"
                          :exportXLSX="exportXLSX" :exportCSV="exportCSV" :exportJSON="exportJSON"></search-table>
            <div style="font-size: 14px; font-weight: 700; padding-left: 10px; padding-top: 3px;" 
                                 v-if="items && items.length">
                Found {{ items.length }} result<span v-if="items.length !== 1">s</span>
            </div>
          </v-card-text>
          <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click.native="close()" id="issueListCloseBtn">{{ $t( 'message.close_btn' ) }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

import{ conf } from '@/utils/confTruePLM';
import SearchTable from '@/components/SearchTable.vue';

export default {
  name: 'IssueListT',
  data () {
    return {
        visFl: false,
        sortDec: false,
        pageData: [],
        headerInfo: [ { text: 'ID', value: 'id', sorter: 'string' },
                      { text: 'Type', value: 'issue_type', sorter: 'string', clear: true },
                      { text: 'Name', value: 'name', sorter: 'string' },
                      { text: 'Status', value: 'status_name', sorter: 'string', clear: true },
                      { text: 'Priority', value: 'priority', sorter: 'string', clear: true },
                      { text: 'Created', value: 'created', sorter: 'datetime' },
                      { text: 'Created by', value: 'created_by', sorter: 'string' },
                      { text: 'Deadline', value: 'datetime_IssueDeadline', sorter: 'date' },
                      { text: 'Responsible', value: 'responsible', sorter: 'string' },
                      { text: 'Parent issue', value: 'masterName', sorter: 'string' }, 
                      { text: 'Subject', value: 'subjNames' } ],
        issueList: [],
        items: [],
        tabulator: null,
        headers: [],
        rowMenu: [ { txt: this.$t( 'message.menu_issue_open' ), cmd: 'openIssue' },
                   { txt: this.$t( 'message.menu_issue_delete' ), cmd: 'delIssue' },
                   { txt: this.$t( 'message.menu_issue_add' ), cmd: 'addIssue' },
                   { txt: this.$t( 'message.menu_issue_subj' ), cmd: 'openSubj', func: this.checkSubj } ],
        exportXLSX: false,
        exportJSON: false,
        exportCSV: false
    };
  },
  components: {
      'search-table': SearchTable
  },
  computed:
  {
      wHi()
      {
             return "calc( 100vh - 250px )";
      }    
  },
  methods: 
  {
      open()
      {
          this.initData();
          this.visFl = true;
          
          var self = this;                    
          return new Promise( ( resolve, reject ) => 
          {
              self.resolve = resolve;
              self.reject = reject;
          });          
      },
      checkSubj( row )
      {
            let data = row.getData();
            return data.fullData.subjects && data.fullData.subjects.length > 0 
                        && !data.fullData.subjects[ 0 ].deleted;
      },
      procFunc( cmd, data )
      {
          this.$eventBus.$emit( 'procIssueRowMenuCmd', { cmd: cmd, data: data } );  
          this.close(); 
      }, 
      selectRow( row )
      {
          let data = row.getData();
          this.$globalStore.setSelectedID( data.id );
      },
      delIssue( info )
      {
            let self = this;
            this.$modalDlg.sysDlgs.confirmDlg.open( 'conf_del_issue_title', 'conf_del_issue_msg', null, info.id )
                 .then( () =>
            {
                self.$modalDlg.sysDlgs.progressDlg.open();
                return conf.deleteIssue( info.plcs_id );
            }, () => {} )
                .then( ( rez ) => 
            {
                if( rez )
                    self.initData();
                
                self.$modalDlg.sysDlgs.progressDlg.close();
            })
                    .catch( ( err ) =>
            {
                self.$modalDlg.sysDlgs.progressDlg.close();
                self.$eventBus.$emit( 'queryError', err );
            });
      },
      addIssue( info )
      {
            this.procFunc( 'addIssue', { prn: info } );
      },
      rowDblClick( row )
      {
          this.procFunc( 'openIssue', row.getData().fullData );
      },
      rowMenuClick( info )
      {
          if( info.cmd === 'openIssue' )
              this.procFunc( 'openIssue', info.row.getData().fullData );
          else if( info.cmd === 'openSubj' )
              this.procFunc( 'openSubj', info.row.getData().fullData );
          else if( info.cmd === 'delIssue' )
              this.delIssue( info.row.getData().fullData );
          else if( info.cmd === 'addIssue' )
              this.addIssue( info.row.getData().fullData );
      },
      addRows( data, items )
      {
            let nameMap = {};
            data[ 1 ].forEach( el => 
            {
                nameMap[ el.class_uri ] = el.name;
            });

            for( let el of data[ 0 ] )
            {
                let row = {};
                for( let i = 0; el.datetimes && i < el.datetimes.length; i++ )
                    el[ 'datetime_' + el.datetimes[ i ].role_name ] = el.datetimes[ i ].dt_text;

                el.masterID = null;
                el.masterName = '';
                if( el.master_issue )
                {
                    el.masterID = el.master_issue.relating.plcs_id;
                    el.masterName = el.master_issue.relating.id;
                }
                
                el.subjNames = '';
                if( el.subjects )
                {
                    el.subjects.forEach( s =>
                    {
                        if( !s.deleted )
                        {
                            if( el.subjNames !== '' )
                                el.subjNames += ', ';

                            el.subjNames += s.name;
                        }
                    });
                }

                // if( el.child_count > 0 )
                //     row.children = [];
                
                el.status_name = nameMap[ el.status ];
                row.fullData = el;
                
                for( let h of this.headers )
                {
                    let val = el[ h.value ];
                    
                    if( val )
                    {
                        if( h.clear )
                            val = this.$utils.clearType( val );
                        
                        if( h.sorter === 'datetime' )
                        {
                            row[ h.value + '_dVal' ] = Date.parse( val );
                            val = this.$utils.getLocalDateFromUTC( val );
                        }
                        else if( h.sorter === 'date' )
                        {
                            row[ h.value + '_dVal' ] = Date.parse( val );
                            val = this.$utils.getLocalDateFromUTC( val, true );
                        }
                    }
                    row[ h.value ] = val;
                }
                
                items.push( row );
            }
      },
      initData() 
      {
          let self = this;
          this.items = [];
          this.headers = this.headerInfo;
          Promise.all( [ conf.getIssueList(), conf.getChildTypesInfo( 'urn:plcs:rdl:TruePLM:IssueStatus' ) ] )
                .then( data =>
            {
                self.addRows( data, self.items );
            })
                .catch( ( err ) =>
           {
              self.$eventBus.$emit( 'queryError', err );
           });
      },
      close()
      {
          this.visFl = false;
          this.resolve();
      }
  },
  mounted: function() 
  {  
      let self = this;

      self.$eventBus.$on( 'exportNodeSearchXLSX', function()
      {
           self.exportXLSX = !self.exportXLSX;
      });
      self.$eventBus.$on( 'exportNodeSearchCSV', function()
      {
           self.exportCSV = !self.exportCSV;
      });
      self.$eventBus.$on( 'exportNodeSearchJSON', function()
      {
           self.exportJSON = !self.exportJSON;
      });
  },   
  beforeDestroy: function()
  {
      this.$eventBus.$off( 'exportNodeSearchXLSX' );
      this.$eventBus.$off( 'exportNodeSearchCSV' );
      this.$eventBus.$off( 'exportNodeSearchJSON' );
  }
}
</script>

<style scoped>
</style>
