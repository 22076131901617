<template>
    <div>
        <v-card style="height: calc(100vh - 181px); overflow-y: auto;">
            <v-list dense>
                <!--<prod-list-row-view v-show="backNode.parent.name" :model="backNode" :key="-1"></prod-list-row-view>-->
                <!--<prod-list-row-view v-for="( node, index ) in nodeList" :model="node" :key="index"></prod-list-row-view>-->
            </v-list>
        </v-card>
    </div>
</template>

<script>
import ProdListRow from './ProdListRow.vue';

import { conf } from '../utils/confTruePLM';

export default {
  name: 'ProdList',
  props: {
  },
  data () {
    return {
        sortDec: false,
        nodeList: [ { root: true, name: 'Product list', hasChildren: true, parent: {}, permissions: {} } ],
        backNode: { back: true, parent: {}, permissions: {} }
    };
  },
  components: {
       'prod-list-row-view': ProdListRow
  },
  methods: 
  {
      goInProd( info )
      {
          this.nodeList = [];
          
          this.backNode.parent = info;
          if( info.root )
              this.getProdList( info );
          else
              this.getProdInfo( info );
      },
      getProdInfo( info )
      {
          let self = this;
          conf.getProdInfo( info )
            .then( ( data ) =>
          {
              self.$eventBus.$emit( 'updateProd', data );
              data.parts.forEach( ( el ) =>
              {
                  el.parent = info;
                  el.name = el.part.name;
                  el.elemID = el.part.view_id;
              });
              self.nodeList = data.parts;
          })
              .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      getProdList( info )
      {
          let self = this;
          conf.getProdList()
            .then( ( data ) =>
          {
              self.$eventBus.$emit( 'updateProd', null );
              data.forEach( ( el ) =>
              {
                  el.parent = info;
                  el.elemID = el.view_id;
              });
              self.nodeList = data;
          })
              .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      goOutProd( info )
      {
          this.backNode.parent = info.parent;
          this.nodeList = [];
          if( info.root )
          {
              this.nodeList = [ info ];
          }
          else
          {
              this.goInProd( info.parent );
          }
      },
      createProd( prn )
      {
          var self = this;
          this.$modalDlg.sysDlgs.createProdDlg.open()
                  .then( ( data ) =>
          {
              //console.log( data );
              if( prn.root )
                  return conf.createProd( data, this.$globalStore.setActTimestamp() );
              else
                  return Promise.resolve();
          }, () => {} )
                  .then( () => 
          {
              self.refreshData();
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      deleteProd( prod )
      {
          var self = this;
          this.$modalDlg.sysDlgs.confirmDlg.open( 'conf_del_title', 'conf_del_msg' )
                  .then( () =>
          {
              return conf.delProd( prod, self.$globalStore.setActTimestamp() );
          }, () => {} )
                  .then( ( rez ) => 
          {   
              self.refreshData();
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      removeProd( prod )
      {
       //   console.log( 'rem2' );
          var self = this;
          this.$modalDlg.sysDlgs.confirmDlg.open( 'conf_remove_title', 'conf_remove_msg' )
                  .then( () =>
          {
              return conf.removeProd( prod, self.$globalStore.setActTimestamp() );
          }, () => {} )
                  .then( ( rez ) => 
          {   
              self.refreshData();
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      refreshData()
      {
          return this.goInProd( this.backNode.parent );             
      },
      putProd( prod )
      {
          var self = this;
//          if( !self.$globalStore.state.prodForPut )
//              return;
          
          this.$modalDlg.sysDlgs.putProdDlg.open( self.$globalStore.state.prodForPut )
                  .then( ( data ) =>
          {
              return conf.putProdToAUnit( prod, { elemID: data.prod }, 
                    data.location, data.num, self.$globalStore.setActTimestamp() );
          }, () => {} )
                    .then( () => 
          {
              self.$globalStore.clearProdForPut();
              self.refreshData();
          })
                    .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      }
  },
  mounted: function() 
  { 
      var self = this;
   
      self.$eventBus.$on( 'sortProdTab', function()
      {
          self.sortDec = !self.sortDec;
          self.$utils.sortArray( self.nodeList, ( a ) => ( a.bkdn_elem_info.name ), self.sortDec );
      });
      self.$eventBus.$on( 'goInProd', function( info )
      {
          self.goInProd( info );
      });
      self.$eventBus.$on( 'goOutProd', function( info )
      {
          if( info.instance_id )
              return;
          
          self.goOutProd( info );
      });
      self.$eventBus.$on( 'procProdMenuCmd', function( data )
      {
          if( data[ 0 ] === 'createProd' )
              self.createProd( data[ 1 ] );
          else if( data[ 0 ] === 'deleteProd' )
              self.deleteProd( data[ 1 ] );
           else if( data[ 0 ] === 'putProd' )
              self.putProd( data[ 1 ] );
           else if( data[ 0 ] === 'removeProd' )
              self.removeProd( data[ 1 ] );
      });
  },   
  beforeDestroy: function()
  {
      this.$eventBus.$off( 'sortProdTab' );
      this.$eventBus.$off( 'goInProd' );
      this.$eventBus.$off( 'goOutProd' );
      this.$eventBus.$off( 'procProdMenuCmd' );
  }
}
</script>

<style scoped>
  .scroll 
  {
      overflow-y: auto;
  }
</style>
