<template>
            <v-dialog v-model="visFl" max-width="290">
               <v-card>
                <v-card-title class="headline">
                  {{ $t( 'message.create_subdomain_title' ) }}
                </v-card-title>
                <v-card-text>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-select :label="$t( 'message.create_subdomain_user' )" v-model="user" required
                            :rules="[v => !!v || $t( 'message.value_required' )]" item-text="txt" item-value="val" :items="users" ></v-select>
                        
                        <v-select :label="$t( 'message.create_subdomain_pphase' )" v-model="pPhase" item-text="txt" 
                                        item-value="val" :items="pPhases" clearable></v-select>
                    </v-form>
                    <!--<small>*{{ $t( 'message.req_info_msg' ) }}</small>-->
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text :disabled="!valid" @click.native="doAct()">{{ $t( 'message.ok_btn' ) }}</v-btn>
                    <v-btn color="green darken-1" text @click.native="close()">{{ $t( 'message.cancel_btn' ) }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>   
</template>

<script>
    
import Vue from 'vue'
import{ conf } from '@/utils/confTruePLM';

export default {
  name: 'createSubdomain',
  data () 
  {
    return {
        visFl: false,
        user: null,
        pPhase: null,
        users: [],
        pPhases: [],
        resolve: null,
        reject: null,
        valid: false
    };
  },
  computed: {
  },
  methods: {
      open( node )
      {
          var self = this;
          
          this.name = node.name;
          this.pPhase = null;
          this.user = null;
          
          this.pPhases = [];
          conf.getChildTypes( 'urn:rdl:epm-std:project_phase' )
                  .then( ( data ) =>
          {
              self.pPhases = self.$utils.getEnum( data );
          });
          
          this.users = [];
          conf.getProjectUsersEnum()
                  .then( ( usersList ) =>
          {
              usersList.forEach( ( item ) =>
              {
                    for( var i = 0; i < item.roles.length; i++ )
                    {
                        if( item.roles[ i ] === 'urn:rdl:epm-std:project_leader' )
                        {
                            self.users.push( item );
                            break;
                        }
                    }
              });
          });
          
          this.visFl = true;
          
          return new Promise( ( resolve, reject ) => 
          {
            this.resolve = resolve;
            this.reject = reject;
          });
      },
      close()
      {
          this.visFl = false;
          this.reject();
      },
      doAct()
      {
          if( this.$refs.form.validate() )
          {
              this.visFl = false;
              this.resolve( { user: this.user, phase: this.pPhase } );
          }
      }
    }
}
</script>



