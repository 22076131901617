<template>
    
  <v-app id="ya_login">
    <v-main>
       
    </v-main>
    
  </v-app>
    
</template>

  <script>
//import * as OAuth from '@zalando/oauth2-client-js';
      
  export default {
    data() {
      return {
          globalState: this.$globalStore.state
      };
    },
    computed: {
              
    },
    methods: 
    {
      
    },
    mounted: function()
    {
//        let callBackUrl = window.location.href.replace( '/#/yalogin', '/#/yacallback?info=1' );
//        let ya = new OAuth.Provider({
//                id: 'yandex',   // required
//                authorization_url: 'https://oauth.yandex.ru/authorize' // required
//            });
//            
//        // Create a new request
//        let request = new OAuth.Request({
//            client_id: this.globalState.initInfo.yandex_id, 
//            redirect_uri: callBackUrl
//        });
//
//        // Give it to the provider
//        let uri = ya.requestToken( request );
//
//        // Later we need to check if the response was expected
//        // so save the request
//        ya.remember( request );
//
//        // Do the redirect
//        window.location.href = uri;
             
    },
    components: {
        
    }
  }
  </script>