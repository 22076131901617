<template>
        <v-dialog v-model="visFl" persistent max-width="290">
           <v-card>
            <v-card-title class="headline" v-if="edit">
              {{ $t( 'message.edit_req_title' ) }}
            </v-card-title>
            <v-card-title class="headline" v-else>
              {{ $t( 'message.create_req_title' ) }}
            </v-card-title>
            <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-text-field :label="$t( 'message.create_node_name' )" required v-model="name" :disabled="edit"
                        :rules="[v => !!v || $t( 'message.value_required' )]"></v-text-field>
                    <v-textarea :label="$t( 'message.create_node_descr' )" required v-model="descr" 
                        :rules="[v => !!v || $t( 'message.value_required' )]"></v-textarea>
                    <v-select :label="$t( 'message.create_node_type' )" required item-text="txt" item-value="val" v-model="selectedType"
                        :items="types" :rules="[v => !!v || $t( 'message.value_required' )]" :disabled="is_req"></v-select>
                </v-form>
                <!--<small>*{{ $t( 'message.req_info_msg' ) }}</small>-->
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
                <v-btn color="green darken-1" text :disabled="!valid" @click.native="doAct()">{{ $t( 'message.ok_btn' ) }}</v-btn>
                <v-btn color="green darken-1" text @click.native="close()">{{ $t( 'message.cancel_btn' ) }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>   
</template>

<script>
    
import Vue from 'vue'
import{ conf } from '@/utils/confTruePLM';

export default {
  name: 'createReq',
  data () 
  {
    return {
        visFl: false,
        name: '',
        descr: '',
        types: [],
        selectedType: '',
        resolve: null,
        reject: null,
        oneVal: null,
        valid: true,
        edit: false,
        is_req: false
    };
  },
  computed: {
      title() 
      {
           return 'message.' + this.titleVal;
      },
      msg() 
      {
           return 'message.' + this.msgVal;
      }
  },
  methods: {
      open( info )
      {
          this.types = [];
          var reqBase = 'urn:plcs:rdl:TruePLM:Requirement';
          conf.getChildTypes( reqBase )
                  .then( ( data ) =>
          {
              this.types.push( { val: reqBase, txt: this.$utils.clearType( reqBase ) } );
              data.forEach( ( elem ) =>
              {
                  this.types.push( { val: elem, txt: this.$utils.clearType( elem ) } );
              });
          });
          
          if( info )
          {
              this.name = info.name;
              this.descr = info.description;
              this.selectedType = this.$utils.noGUID( info.element_type );
              
              this.edit = true;
              this.is_req = info.is_requirement;
          }
          else
          {
              this.name = null;
              this.descr = null;
              this.selectedType = null;
              
              this.edit = false;
              this.is_req = false;
          }
          
          this.visFl = true;
          
          return new Promise( ( resolve, reject ) => 
          {
            this.resolve = resolve;
            this.reject = reject;
          });
      },
      close()
      {
          this.visFl = false;
          this.reject();
      },
      doAct()
      {
          if( this.$refs.form.validate() )
          {
            this.visFl = false;
            this.resolve( { name: this.name, descr: this.descr, type: this.selectedType } );
          }
      }
    }
}
</script>



