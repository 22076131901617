<template>
    
<!--      <v-container fluid fill-height>
          <v-layout align-center justify-center>-->
            <v-dialog v-model="visFl" persistent width="auto" max-width="800" min-width="250" @keydown.esc="close" @keydown.enter="doAct">
              <v-card>
                <v-card-title class="headline">
                  <v-icon v-show="useIcon" large :color="iconColor">{{useIcon}}</v-icon>
                  <span style="padding-left: 7px;">
                      {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${title}`, { val: titleData } ) } ) }}
                  </span>
                </v-card-title>
                <v-card-text>
                    <span v-html= "$t( 'message.dlg_custom_msg', { cur_msg: $t(`${msg}`, { val: msgData } ) } )">
                    </span>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                    <div v-if="isConf">
                      <v-btn id="confirmOk" color="green darken-1" text @click.native="doAct">{{ $t( 'message.ok_btn' ) }}</v-btn>
                      <v-btn id="confirmClose" color="green darken-1" text @click.native="close" v-show="!noCancel">{{ $t( 'message.cancel_btn' ) }}</v-btn>
                    </div>
                    <div v-else>
                      <v-btn id="confirmYes" color="green darken-1" text @click.native="doAct">{{ $t( 'message.yes_btn' ) }}</v-btn>
                      <v-btn id="confirmNo" color="green darken-1" text @click.native="close" v-show="!noCancel">{{ $t( 'message.no_btn' ) }}</v-btn>
                    </div>
                  
                </v-card-actions>
              </v-card>
            </v-dialog>   
<!--          </v-layout>
      </v-container>-->
    
</template>

<script>
    
import Vue from 'vue'

import router from '../../router';

import { auth } from '../../utils/auth';

export default {
  name: 'confirm',
  data () 
  {
    return {
        visFl: false,
        noCancel: false,
        useIcon: null,
        iconColor: '',
        titleVal: 'confirm_ttl',
        msgVal: 'confirm_msg',
        isConf: true,
        msgData: null,
        titleData: null,
        resolve: null,
        reject: null        
    };
  },
  computed: {
      title() 
      {
           return 'message.' + this.titleVal;
      },
      msg() 
      {
           return 'message.' + this.msgVal;
      }
  },
  methods: {
      openError( titleIn, msgIn )
      {
          return this.open( titleIn, msgIn, null, null, true, 'error', 'red' );
      },
      
      openWarning( titleIn, msgIn, noCancel )
      {
          return this.open( titleIn, msgIn, null, null, noCancel, 'warning', '#fb8c00' );
      },
      
      openAsk( titleIn, msgIn )
      {
          return this.open( titleIn, msgIn, null, null, false, 'help_outline', 'green', false );
      },
      
      openOkOnly( titleIn, msgIn )
      {
          return this.open( titleIn, msgIn, null, null, true );
      },
      
      open( titleIn, msgIn, titleData, msgData, noCancel, useIcon, iconColor, isConf )
      {
          this.titleVal = titleIn || 'confirm_ttl';
          this.msgVal = msgIn || 'confirm_msg';          
          this.titleData = titleData;
          this.msgData = msgData;
          this.noCancel = noCancel;
          this.useIcon = useIcon || 'info';
          this.iconColor = iconColor;
          
          if( isConf !== undefined )
            this.isConf = isConf;
          
          this.visFl = true;
          
          return new Promise( ( resolve, reject ) => 
          {
            this.resolve = resolve;
            this.reject = reject;
          });
      },
      close()
      {
          this.visFl = false;
          this.reject();
      },
      doAct()
      {
          this.visFl = false;
          this.resolve();
      }
    }
}
</script>



