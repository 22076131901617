<template>
        <v-dialog v-model="visFl" persistent max-width="290">
           <v-card>
            <v-card-title class="headline">
              {{ $t( 'message.put_prod_title' ) }}
            </v-card-title>
            <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
<!--                    <v-text-field :label="$t( 'message.put_prod_prod' )" required v-model="name" :disabled="copy"
                        :rules="[v => !!v || $t( 'message.value_required' )]"></v-text-field>-->
                    <v-autocomplete :label="$t( 'message.put_prod_prod' )" required v-model="name" :disabled="copy"
                        :rules="[v => !!v || $t( 'message.value_required' )]" :auto-select-first="true" :clearable="true"
                        :items="prods" :search-input.sync="search" item-text="name" item-value="view_id">
                    </v-autocomplete>
                    <v-textarea v-if="!forNode" :label="$t( 'message.put_prod_location' )" v-model="location"></v-textarea>
                    <v-text-field v-if="!forNode" :label="$t( 'message.put_prod_num' )" required v-model="num" type="number"
                                :rules="[ v => !!v || $t( 'message.value_required' ) ]"></v-text-field>
                </v-form>
                <!--<small>*{{ $t( 'message.req_info_msg' ) }}</small>-->
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
                <v-btn id="putProdOk" color="green darken-1" text :disabled="!valid" @click.native="doAct()">{{ $t( 'message.ok_btn' ) }}</v-btn>
                <v-btn id="putProdClose" color="green darken-1" text @click.native="close()">{{ $t( 'message.cancel_btn' ) }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>   
</template>

<script>
    
import Vue from 'vue'
import{ conf } from '@/utils/confTruePLM';

export default {
  name: 'putProd',
  data () 
  {
    return {
        visFl: false,
        name: '',
        location: '',
        resolve: null,
        reject: null,
        valid: true,
        copy: false,
        num: 1,
        prods: [],
        search: null,
        forNode: false
    };
  },
  computed: {
  },
  watch: {
      search( val ) 
      {
          if( val && val !== this.name )
              this.fillProds( val );
      }
  },
  methods: {
      fillProds( val )
      {
          if( val )
              val = '*' + val + '*';
          conf.getProdList( val )
                  .then( ( data ) =>
          {
              this.prods = data;
          });
      },
      open( info, forNode )
      {
          this.location = null;
          this.forNode = forNode;
          
          if( info )
          {
              this.search = info.name;
              this.items = [ info ];
              this.name = info.elemID;
              //this.copy = true;
          }
          else
          {
              this.fillProds();
              this.name = null;
              this.location = null;
              this.copy = false;
          }
          
          this.visFl = true;
          
          return new Promise( ( resolve, reject ) => 
          {
            this.resolve = resolve;
            this.reject = reject;
          });
      },
      close()
      {
          this.visFl = false;
          this.reject();
      },
      doAct()
      {
          if( this.$refs.form.validate() )
          {
                this.visFl = false;
                this.resolve( { location: this.location, num: this.num, prod: this.name } );
          }
      }
    }
}
</script>



