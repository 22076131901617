<template>
    <v-card>
      <v-data-table :headers="heads" :items="propVals" hide-default-footer class="elevation-1 propTbl" 
                    :items-per-page=-1 fixed-header height="calc( ( 100vh - 181px )">
        <template v-slot:header.num="{ header }">
            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
        </template>
          <template v-slot:header.name="{ header }">
            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
        </template>
          <template v-slot:header.val="{ header }">
            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
        </template>
        <template v-slot:header.act="{ header }">
            <v-tooltip bottom >
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on" @click="addItem()">add</v-icon>
                </template>
                <span>{{ $t( 'message.acts_add_btn' ) }}</span>
            </v-tooltip>
            <v-tooltip bottom v-if="c_item.id">
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on" @click="editItem()">edit</v-icon>
                </template>
                <span>{{ $t( 'message.acts_edit_btn' ) }}</span>
            </v-tooltip>
            <v-tooltip bottom v-if="c_item.id">
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on" @click="delItem()">delete</v-icon>
                </template>
                <span>{{ $t( 'message.acts_del_btn' ) }}</span>
            </v-tooltip>
        </template>
        <template v-slot:item="{ item }">
            <tr>
                  <td>{{ item.num }}</td>
                  <td>{{ $t( 'message.acts_prop_' + item.name ) }}</td>
                  <td colspan="2">{{item.val}}</td>
            </tr>
        </template>
      </v-data-table>
    </v-card>
</template>

<script>
import{ conf } from '@/utils/confTruePLM';

export default {
  name: 'ActInfo',
  props: {
      c_item: null
  },
  data () {
    return {
        heads: [
            { text: 'message.c_item_num_col', value: 'num', width: '10%' }, 
            { text: 'message.c_item_name_col', value: 'name' }, 
            { text: 'message.c_item_value_col', value: 'val' },
            { text: 'message.c_item_act_col', value: 'act', sortable: false, width: '15%' }
        ],
        globalState: this.$globalStore.state,
        propVals : [],
        props: [ 'name', 'action_type', 'subj', 'message', 'subdomain', 'roles', 'users' ]
    };
  },
  watch: {
      c_item() 
      {
            this.updateInfo();
      }
  },
  methods: 
  {
      addItem()
      {
          this.$emit( 'addItem' );
      },
      delItem()
      {
          this.$emit( 'delItem' );
      },
      editItem()
      {
          this.$emit( 'editItem' );
      },
      updateInfo()
      {
          this.propVals = [];
          
          if( !this.c_item )
              return;
          
          let ind = 0;
          this.props.forEach( ( prop ) =>
          {
              if( prop === 'roles' )
              {
                  let r = [], u = [];
                  let targs = this.c_item[ 'targets' ] || [];
                  targs.forEach( ( t ) => 
                  {
                      if( t.target_type === 'USER' )
                          u.push( t.name );
                      else if( t.target_type === 'GROUP' )
                          r.push( t.name );
                  });
                  
                  this.c_item.users = u;
                  this.c_item.roles = r;
                  this.propVals.push( { num: ind++, name: 'users', val: u.join( ',' ) } );
                  this.propVals.push( { num: ind++, name: 'roles', val: r.join( ',' ) } );  
              }
              else if( prop === 'subdomain' )
              {
                  let val = this.c_item[ prop ];
                  if( val )
                      this.propVals.push( { num: ind++, name: prop, val: val.root_bkdn_elem.name } );
                  else
                      this.propVals.push( { num: ind++, name: prop, val: '' } ); 
              }
              else if( prop !== 'users' )
              {
                    let val = this.c_item[ prop ];
                    if( prop === 'action_type' )
                    {
                        if( val === 'NOTIFY' ) 
                            val = 'Notification';
                        else if( val === 'SEND_EMAIL' ) 
                            val = 'EMail';
                    }
                    
                    this.propVals.push( { num: ind++, name: prop, val: val } );  
              }
          });       
      }
  },
  mounted: function() 
  { 
      this.updateInfo();
  },   
  beforeDestroy: function()
  {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .propTbl td
    {
        height: 30px;
    }        
</style>
