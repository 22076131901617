<template>
    <v-app>
         <progress-view ref="progressDlg"></progress-view>
         <v-main>
            <v-container fluid fill-height>
                <v-expansion-panels>
                    <v-expansion-panel style="z-index: 100;position: absolute; width: auto; left: 10px;" v-if="selData">
                        <v-expansion-panel-header>
                            {{ $utils.clearType( selData.elem_type ) }}<span style='padding-left: 15px; font-weight: 800'>'{{ selData.name }}'</span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-simple-table>
                            <template v-slot:default>
                              <tbody>
                                <tr v-for="item in selData.properties" :key="item.instance_id">
                                  <td>{{ $utils.clearType( item.name ) }}</td>
                                  <td>{{ item.val }}</td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                
                <div id="graphView" style="width: 100%; height: 100%"></div>
            </v-container>
         </v-main>
    </v-app>
</template>

<script>

import router from '../router';
import { auth } from '../utils/auth';
import{ conf } from '../utils/confTruePLM';
import Progress from './Dlg/Progress.vue'

//import Neo4jd3 from 'neo4jd3';
import * as d3 from 'd3';
//window.d3 = d3;

export default {
  name: 'graphVis',
  data () 
  {
    return {
        d3Data: { nodes: [], links: [] },
        svg: null,
        mainG: null,
        linkG: null,
        wD: 0,
        hD: 0,
        links: null,
        nodes: null,
        simul: null,
        nodeMap: {},
        clickInfo: {},
        selData: null,
        selObj: null
    };
  },
  computed: {
      selName()
      {
          if( !this.selData )
              return '';
          
          return this.selData.name + ' (' + this.$utils.clearType( this.selData.elem_type ) + ')';
      },
  },
  methods: {
      fillInfo( prms )
      {
          conf.setProj( { repository: prms.rep, in_project: { project_model_id: prms.model } }, prms.role );
          
          let box = d3.select( '#graphView' ).node().getBoundingClientRect();
          this.wD = box.width;
          this.hD = box.height;

          this.svg = d3.select( '#graphView' ).append( 'svg' )
                  .attr( 'viewBox', [ 0, 0, this.wD, this.hD ] )
                  .style( 'font', '10px sans-serif' );
          this.linkG = this.svg.append( 'g' ).attr( 'fill', 'none' ).attr( 'stroke-width', 1.5 );
          this.mainG = this.svg.append( 'g' );
          
          this.simul = d3.forceSimulation()
                .nodes( this.d3Data.nodes )
                .force( 'link', d3.forceLink( this.d3Data.links ).id( d => d.id ) )
                .force( 'charge', d3.forceManyBody().strength( -400 ) )
                .force( 'center', d3.forceCenter( this.wD / 2, this.hD / 2 ).strength( 1 ) )
                .force( 'x', d3.forceX() )
                .force( 'y', d3.forceY() )
                .force( 'collide', d3.forceCollide( d => 50 ) )
                .on( 'tick', this.ticked );
        
          this.svg.call( d3.zoom().extent( [ [ 0, 0 ], [ this.wD, this.hD ] ] )
                .scaleExtent( [ 0.1, 10 ] ).on( "zoom", this.zoomed ) );
          this.svg.on( 'dblclick.zoom', null );
        
          this.addNodeData( prms.node, true );
      },
      addNodeData( nodeID, addCurNode, d3Obj )
      {
          let self = this;
          
          let curNode = self.nodeMap[ nodeID ];
          if( curNode && curNode.opened )
                return;
            
          conf.getNodeChildren( { instance_id: nodeID }, '', 1, 100, false, true, true, false )
                 .then( data => 
          {
              if( addCurNode )
              {
                  let node = self.getNodeObj( data.element );
                  self.d3Data.nodes.push( node );
                  self.nodeMap[ nodeID ] = node;
              }
              
              let curNode = self.nodeMap[ nodeID ];
              if( curNode )
                  curNode.opened = true;
              
              for( let i = 0; data.element.children && i < data.element.children.length; i++ )
              { 
                  let chData = data.element.children[ i ];
                  let chNode = self.nodeMap[ chData.instance_id ];
                  if( !chNode )
                  {
                      chNode = self.getNodeObj( chData );
                      self.d3Data.nodes.push( chNode );
                      self.nodeMap[ chNode.id ] = chNode;
                  }
                  
                  if( !curNode.linkMap[ chNode.id ] )
                  {
                      let chRel = self.getRelObj( curNode, chNode, 'child' );
                      self.d3Data.links.push( chRel );
                      curNode.linkMap[ chNode.id ] = chRel;
                      chNode.linkMap[ curNode.id ] = chRel;
                  }
                  
                  if( chData.rels )
                  {
                      chData.rels.forEach( rel =>
                      {
                          if( !chNode.linkMap[ rel.related.instance_id ] )
                          {
                              let relNode = self.nodeMap[ rel.related.instance_id ];
                              if( relNode )
                              {
                                    let chRel = self.getRelObj( chNode, relNode, 'link' );
                                    self.d3Data.links.push( chRel );
                                    relNode.linkMap[ chNode.id ] = chRel;
                                    chNode.linkMap[ relNode.id ] = chRel;
                              }
                          }
                      });
                  }
              }
              
              if( data.element.children )
                  self.addNodeVis();

              if( addCurNode )
              {
                  curNode = self.nodeMap[ nodeID ];
                  self.mainG.selectAll( 'g' ).data( [ curNode ], d => d.id ).join( enter => null, 
                                update => update.append( 'circle' ).attr( 'r', 2 ).style( 'fill', 'black' )
                                                .classed( 'centerC', true ), 
                                exit => null );
              }
              
          })
                .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      selectNode( d3Obj, d )
      {
          if( !d3Obj )
              return;

          this.deSelectNode( this.selObj, this.selData );
          if( this.selData && this.selData.id === d.id )
          {
              this.selData = null;
              this.selObj = null;
              return;
          }
          
          this.selObj = d3Obj;
          this.selData = d;
              
          d3.select( d3Obj ).select( 'circle' ).classed( 'selectCircle', true );
          
          this.linkG.selectAll( 'line' ).data( Object.values( d.linkMap ), d => d.id ).join( enter => null,
                            update => update.classed( 'selectLink', true ), exit => null );
      },
      deSelectNode( d3Obj, d )
      {
          if( !d3Obj )
              return;
          
          let sC = d3.select( d3Obj ).select( '.selectCircle' );
          sC.classed( 'selectCircle', false );
          this.linkG.selectAll( 'line' ).data( Object.values( d.linkMap ), d => d.id ).join( enter => null,
                            update => update.classed( 'selectLink', false ), exit => null );
      },
      
      addNodeVis()
      {
            let self = this;
            self.links = self.linkG.selectAll( 'line' ).data( self.d3Data.links, d => d.id ).join( enter => enter
                          .append( 'line' ).style( 'stroke', '#888' ).style( 'stroke-dasharray', d => 
                  {
                      if( d.type === 'link' )
                          return '5,5';
                      else
                          return '0,0';
                  }) );
//                    self.links = self.linkG.selectAll( 'path' ).data( self.d3Data.links, d => d.id ).join( enter => enter
//                          .append( 'path' ).style( 'stroke', '#aaa' ) );

            let newNodes = self.mainG.selectAll( 'g' ).data( self.d3Data.nodes, d => d.id ).enter().append( 'g' )
                  .on( 'mousedown', function( event )
                  {
                      self.clickInfo.downPt = d3.pointer( event, document.body);
                  })
                  .on( 'click', function( event, d ) 
                  {
                      let a = self.clickInfo.downPt;
                      let b = d3.pointer( event, document.body );
                      let dist = Math.sqrt( Math.pow( a[0] - b[0], 2) + Math.pow( a[1] - b[1], 2 ) );
                      if( dist > 10 )
                          return;
                      
                      let d3Obj = this;
                      if( self.clickInfo.waitId ) 
                      {
                          window.clearTimeout( self.clickInfo.waitId );
                          self.clickInfo.waitId = null;
                          self.procDblClick( event, d, d3Obj );
                      } 
                      else 
                      {
                          self.clickInfo.waitId = window.setTimeout( () => 
                          {
                              self.procClick( event, d, d3Obj );
                              self.clickInfo.waitId = null;
                          }, 300 );
                      }
//                      console.log( e, d );
//                      e.stopPropagation();
        //       self.selectNode( this );
                  });
            newNodes.append( 'circle' ).attr( 'r', 8 )
                    .style( 'fill', d => 
            { 
                if( d.type === 'occurrence' ) 
                    return '#598054';
                else if( d.type === 'shape' ) 
                    return '#a8b6c5';
                else
                    return '#e4b270';
            } );     
            newNodes.append( 'text' ).attr( 'x', 9 ).attr( 'y', '0.31em' ).text( d => d.name )
                    .clone( true ).lower().attr( 'fill', 'none' ).attr( 'stroke', 'white' )
                    .attr( 'stroke-width', 3 );
            newNodes.call( self.drag( self.simul ) );

            self.nodes = self.mainG.selectAll( 'g' ).data( self.d3Data.nodes, d => d.id ).join();

            self.simulation();
      },
      procClick( event, d, d3Obj )
      {
          this.selectNode( d3Obj, d );
      },
      procDblClick( event, d, d3Obj )
      {
          this.procNodeFix( d, d3Obj );
          this.addNodeData( d.id, false, d3Obj );
      },
      procNodeFix( node, d3Obj )
      {
          let cC = d3.select( d3Obj ).select( '.centerC' );
          if( cC.empty() )
              cC = d3.select( d3Obj ).append( 'circle' ).attr( 'r', 2 ).classed( 'centerC', true )
                                                .style( 'fill', 'black' );
          if( node.fixed )
          {
              node.fx = null;
              node.fy = null;
              node.fixed = false;
              cC.style( 'fill', 'black' );
              this.simul.alpha( 0.001 ).restart();
          }
          else
          {
              node.fx = node.x;
              node.fy = node.y;
              node.fixed = true;
              cC.style( 'fill', 'white' );
          }
      },
      zoomed( { transform } )
      {
           this.mainG.attr( "transform", transform );
           this.linkG.attr( "transform", transform );
      },
      simulation()
      {
          this.simul.nodes( this.d3Data.nodes );
          this.simul.force( 'link' ).links( this.d3Data.links );
          this.simul.alpha( 0.08 ).restart();
      },
      ticked() 
      {
          if( !this.links )
              return;
          
//          this.links.attr( "d", d =>`M${d.source.x},${d.source.y}A0,0 0 0,1 ${d.target.x},${d.target.y}`);
          this.links
            .attr( "x1", function( d ){ return d.source.x; } )
            .attr( "y1", function( d ){ return d.source.y; } )
            .attr( "x2", function( d ){ return d.target.x; } )
            .attr( "y2", function( d ){ return d.target.y; } );

          this.nodes
             .attr( "transform", d => `translate(${d.x},${d.y})` );
//             .attr( "cx", function( d ){ return d.x; } )
//             .attr( "cy", function( d ){ return d.y; } );
      },
      drag( simulation )
      {
        function dragstarted(event, d) {
//          if( !event.active ) 
//              simulation.alphaTarget( 0.08 ).restart();
//          
//          d.fx = d.x;
//          d.fy = d.y;
        }

        function dragged(event, d) {
          d.fx = event.x;
          d.fy = event.y;
//          simulation.alpha(1).restart();
        }

        function dragended(event, d) 
        {
            if( !d.fixed )
            {
                  d.fx = null;
                  d.fy = null;
            }
            if( !event.active ) 
                  simulation.alphaTarget( 0.001 );
        }

        return d3.drag()
            .on("start", dragstarted)
            .on("drag", dragged)
            .on("end", dragended);
      },
      getRelObj( prnObj, obj, type )
      {
          let rel = {};
          rel.id = this.d3Data.links.length;
          rel.type = type;
          rel.properties = {};
          rel.source = prnObj;
          rel.target = obj;
          return rel;
      },
      getNodeObj( data )
      {
          let node = {};
          node.id = data.instance_id;
          node.name = data.name;
          node.opened = false;
          node.type = data.quantity;
          node.elem_type = data.element_type;
          node.properties = data.properties;
          node.x = Math.floor( Math.random() * this.wD );
          node.y = Math.floor( Math.random() * this.hD );
          node.linkMap = {};
          node.fixed = false;

          return node;
      },
      procError( err )
      {
          if( !err.message && err.response )
              err.message = err.response;
          
          if( err.message === 'Session expired!' )
              router.push( '/login' );
          else if( err.message === 'noConnInfo' || err.message === 'Connection expired' )
              router.push( '/login' );
          console.log( 'E - ' + err );
      }
    },
   watch: {
     
    },
  components: {
      'progress-view': Progress,
  },
  beforeMount: function()
  {
      auth.checkAuth();
      if( ! auth.getUser().authenticated )
          router.push( '/login' );
  },
  mounted: function()
  {
      var self = this;
      
      this.$eventBus.$on( 'queryError', function( err )
      {
          self.procError( err );
      });
      
      this.fillInfo( this.$route.query );
  },
  beforeDestroy: function()
  {
      this.$eventBus.$off( 'queryError' );
  }
}
</script>

<style>
    .selectCircle
    {
        stroke: black; 
        stroke-width: 2;
    }
    .selectLink
    {
        stroke: black !important;
        stroke-width: 2;
    }
</style>
