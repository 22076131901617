<template>
 <div>
   <v-data-table :header="headers" :items="model.data_contents" hide-default-footer class="elevation-1" 
                 :items-per-page=-1 fixed-header>
        <template v-slot:header.name="{ header }">
            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
        </template>
        <template v-slot:header.act="{ header }">
            <span></span>
        </template>
    <template v-slot:item="{ item }">
        <tr>
              <td>{{ $utils.clearType(item.document_type ) }}</td>
              <td>
                <span>{{ item.file_name }}</span>
                <v-badge overlap color="blue lighten-3">
                    <span slot="badge">{{ getStickNum( item ) }}</span>
                    <v-icon color="yellow darken-3" @click="openNotes( item )">subject</v-icon>
                </v-badge>
                <v-badge overlap color="blue lighten-3">
                    <span slot="badge">{{ getLinkNum( item ) }}</span>
                    <v-icon color="green darken-2" @click="openLinks( item )">link</v-icon>
                </v-badge>
                <v-icon color="red lighten-2" v-if="item.is_checked_out_by_me || item.is_checked_out_by_user">lock</v-icon>
              </td>
              <td>{{ item.description }}</td>
              <td>{{ item.last_ver }}</td>
              <td>{{ item.last_ver_user }}</td>
              <td>
                <v-menu bottom left>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon>
                            <v-icon>more_vert</v-icon>
                        </v-btn>    
                    </template>
                    <v-list dense>
                        <v-list-item key="7" @click="checkOutFile( item )" :disabled="item.is_checked_out_by_me">
                            <v-list-item-title>{{$t( 'message.menu_file_checkout' )}}</v-list-item-title>
                        </v-list-item>
                        <v-list-item key="8" @click="cancelCheckOutFile( item )" :disabled="!item.is_checked_out_by_me">
                            <v-list-item-title>{{$t( 'message.menu_file_checkout_cancel' )}}</v-list-item-title>
                        </v-list-item>
        <!--                <v-list-item key="9" @click="checkInFile( item )" :disabled="!item.is_checked_out_by_me">
                            <v-list-item-title>{{$t( 'message.menu_file_checkin' )}}</v-list-item-title>
                        </v-list-item>-->
                        <v-list-item key="1" @click="downloadFile( item )">
                            <v-list-item-title>{{$t( 'message.file_download_btn' )}}</v-list-item-title>
                        </v-list-item>
                        <v-list-item key="2" @click="deleteFile( item )">
                            <v-list-item-title>{{$t( 'message.file_delete_btn' )}}</v-list-item-title>
                        </v-list-item>
                        <v-list-item key="3" @click="copyFile( item )">
                            <v-list-item-title>{{$t( 'message.menu_file_copy' )}}</v-list-item-title>
                        </v-list-item>
                        <v-list-item key="4" @click="cutFile( item )">
                            <v-list-item-title>{{$t( 'message.menu_file_cut' )}}</v-list-item-title>
                        </v-list-item>
                        <v-list-item key="5" @click="linkFile( item, 1 )" :disabled="( !globalState.fileForLink || ( globalState.fileForLink.instance_id === item.instance_id ) )">
                            <v-list-item-title>{{$t( 'message.menu_file_link1' )}}</v-list-item-title>
                        </v-list-item>
                        <v-list-item key="6" @click="linkFile( item, 2 )" :disabled="( !globalState.fileForLink || ( globalState.fileForLink.instance_id === item.instance_id ) )">
                            <v-list-item-title>{{$t( 'message.menu_file_link2' )}}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
              </td>
        </tr>
    </template>
  </v-data-table>
  <snotes-view ref="sNotesDlg"></snotes-view>
  <dlinks-view ref="dLinksDlg"></dlinks-view>
 </div>
</template>

<script>
//import EventBus from './Bus.vue'
import{ conf } from '../utils/confTruePLM'
import StickyNotes from './Dlg/StickyNotes.vue'
import DocLinks from './Dlg/DocLinks.vue'

export default {
  name: 'PnlFiles',
  props: {
  },
  data () {
    return {
        headers: [
            { text: 'message.file_type_col', value: 'name' },
            { text: 'message.file_name_col', value: 'name' },
            { text: 'message.file_descr_col', value: 'name' },
            { text: 'message.file_last_ver_col', value: 'name' },
            { text: 'message.file_last_ver_user_col', value: 'name' },
            { text: '', value: '', width: '5%', value: 'act', sortable: false }
        ],
        model: { data_contents: [] },
        globalState: this.$globalStore.state
    };
  },
  methods: 
  {
      checkInFile( file )
      {
          this.$eventBus.$emit( 'checkInFile', file );
      },
      cancelCheckOutFile( file )
      {
          this.$eventBus.$emit( 'cancelCheckOutFile', file );
      },
      checkOutFile( file )
      {
          this.$eventBus.$emit( 'checkOutFile', file );
      },
      linkFile( file, type )
      {
          if( type === 1 )
              this.$eventBus.$emit( 'linkFile1', file );
          else
              this.$eventBus.$emit( 'linkFile2', file );
      },
      cutFile( file )
      {
          file.parentNode = this.model;
          this.$globalStore.clearFileForLink();          
          this.$globalStore.setFileForPaste( file );          
      },
      copyFile( file )
      {
          file.parentNode = this.model;
          this.$globalStore.clearFileForPaste();          
          this.$globalStore.setFileForLink( file );          
      },      
      openLinks( file )
      {
          var self = this;
          this.$refs.dLinksDlg.open( file )
                  .then( ( rez ) =>
          {
              if( rez )
                  this.$eventBus.$emit( 'goInNode', self.model );                                
          });
      },
      openNotes( file )
      {
          var self = this;
          this.$refs.sNotesDlg.open( file )
                  .then( ( rez ) =>
          {
              if( rez )
                  this.$eventBus.$emit( 'goInNode', self.model );                                
          });
      },
      getLinkNum( file )
      {
          var num = 0;
          if( file.affects_documents )
              num += file.affects_documents.length;
          if( file.affected_by_documents )
              num += file.affected_by_documents.length;
      
          return num;
      },
      getStickNum( file )
      {
          var ver = file.versions[ file.versions.length - 1 ];
          if( !ver.sticky_notes )
              return 0;
          else
              return ver.sticky_notes.length;
            
      },
      downloadFile( file )
      {
          conf.downloadFileVer( file.file_name, file.versions[ file.versions.length - 1 ].instance_id )
                  .then( ( flInfo ) =>
          {
              let url = conf.getFileLink( flInfo.title, flInfo.source );
              window.location.href = encodeURI( url );          
          })
                  .catch( ( err ) =>
          {
              this.$eventBus.$emit( 'queryError', err );
          });
      },
      deleteFile( file )
      {
           this.$eventBus.$emit( 'deleteFile', file );
      },      
      procModel()
      {
          if( !this.model.data_contents )
          {
              this.model.data_contents = [];
              return;
          }
              
          this.model.data_contents.forEach( ( file ) =>
          {
              var lastVer = file.versions[ file.versions.length - 1 ];
              file.last_ver = lastVer.version_id + ' [' + lastVer.date_submitted + ']';
              file.last_ver_user = lastVer.submitted_by_user;
          });
      }
  },
  components: {
    'snotes-view': StickyNotes,
    'dlinks-view': DocLinks
  },
  mounted: function() 
  { 
      var self = this;
      
      this.$eventBus.$on( 'selectedModel', function()
      {
          self.model = {};
          self.procModel();
      });
      
      this.$eventBus.$on( 'goInNode', function( data )
      {
          self.model = data;
          self.procModel();
      });
      
      this.$eventBus.$on( 'updateNode', function( data )
      {
          self.model = data;
          self.procModel();
      });
      
      this.$eventBus.$on( 'goOutNode', function( data )
      {
          if( data.parent )
              self.model = data.parent;
          else
              self.model = data;
          
          self.procModel();
      });
  },   
  beforeDestroy: function()
  {
      this.$eventBus.$off( 'selectedModel' );
      this.$eventBus.$off( 'goInNode' );
      this.$eventBus.$off( 'updateNode' );
      this.$eventBus.$off( 'goOutNode' );
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
