<template>
    <v-card>
        <v-data-table dense :headers="headers" :items="propVals" sort-by="num" hide-default-footer class="elevation-1 propTbl" 
                    :items-per-page=-1 fixed-header height="calc( ( 100vh - 229px ) / 2 )">
          <template v-slot:header.num="{ header }">
            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
          </template>
          <template v-slot:header.name="{ header }">
            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
          </template>
          <template v-slot:header.val="{ header }">
            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
          </template>
          <template v-slot:header.type="{ header }">
            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
          </template>
          <template v-slot:header.act="{ header }">
            <span></span>
          </template>
        <template v-slot:item="{ item }">
            <tr>
                  <td>{{ item.num }}</td>
                  <td>{{ item.name }} <span v-if="item.unit">({{ item.unit }})</span></td>
                  <td>{{ item.val_view }}</td>
                  <td>{{ item.type_view }}</td>
                  <td v-if="( item.edit || item.msg || item.class || item.class_name )">
                      <span style="white-space: nowrap;">
                        <v-icon small class="mr-2" v-if="item.name" @click="editItem(item)">edit</v-icon>
                        <v-icon v-if="item.name !== 'Quantity' && !item.msg && !item.class && !item.class_name 
                                && item.val !== undefined" small @click="deleteItem(item)">clear</v-icon>
                      </span>
                  </td>
                  <td v-else>
                  </td>
            </tr>
        </template>
      </v-data-table>
    </v-card>
</template>

<script>
import{ conf } from '../utils/confTruePLM';

export default {
  name: 'PnlProdProps',
  props: {
      forSys: false,
      data: null
  },
  data () {
    return {
        globalState: this.$globalStore.state,
        headers: [
            { text: 'message.prop_num_col', value: 'num', width: '10%', align: 'right' },
            { text: 'message.prop_name_col', value: 'name', width: '20%' },
            { text: 'message.prop_value_col', value: 'val' },
            { text: 'message.prop_type_col', value: 'type', width: '10%' }, 
            { width: '5%', value: 'act', sortable: false }
        ],
        propVals : [],
        model: {},
        node: { permissions: {} },
        propList: [ { title: 'Name', value: 'name' }, 
                    { title: 'Type', value: 'part_type' },
                    { title: 'Description', value: 'description' },
//                    { title: 'ID', value: 'id' },
                    { title: 'Created by', value: 'created_by' },
                    { title: 'Created date', value: 'created' },
                    { title: 'Version', value: 'ver' },
                    { title: 'Domain', value: 'domain' },
                    { title: 'Stage', value: 'stage' }
                  ]
    };
  },
  methods: 
  {
      editItem( item )
      {
          let self = this;
          let propList = [];
          if( item.class )
          {
              let opts = [];
              conf.getChildTypes( item.class )
                      .then( ( data ) =>
              {
                  self.$utils.getEnum( data ).forEach( ( opt ) =>
                  {
                      opts.push( opt.val );
                  });
                  item.obj = { type: item.class, domain: 'urn:owl:oneOf', options: opts };
              });
              item.type = item.class;
          }
          else if( item.class_name )
          {
              item.obj = { type: item.class_name, domains: [ 'urn:plcs:rdl:TruePLM:Text_value' ] };
              item.type = item.class_name;
          }
          else
              propList = this.$globalStore.state.propEnums.fileProps;

          this.$modalDlg.sysDlgs.assignPropDlg.open( item, propList )
                    .then( ( rez ) =>
          {
              self.$eventBus.$emit( 'updateProdProps', { node: self.model, props: [ item ], isClass: item.class || item.class_name } ); 

  //              item.val = rez.val;
  //              self.hasChanges = true;
          }, () => {} );
      },
      
      deleteItem( item )
      {
          let self = this;
          this.$modalDlg.sysDlgs.confirmDlg.open( 'prop_clear_title', 'prop_clear_msg' )
                  .then( () =>
          {
              item.delete = true;
              self.$eventBus.$emit( 'updateProdProps', { node: self.model, props: [ item ] } ); 
//              self.hasChanges = true;
          }, () => {} );
      },
      
      procModel()
      {
          var self = this;
          var ind = 1;
          let pVals = [];
          if( this.forSys )
          {
                let tView = 'T';
                self.propList.forEach( ( item ) =>
                {
                    var urlData = null;
                    var val = self.model[ item.value ] || '';

                    if( item.value === 'created' )
                        val = this.$utils.getLocalDateFromUTC( val );
                    else
                    {
                        val = self.$utils.clearType( val );
                        if( item.parse )
                            var urlData = this.$utils.getURLData( val );
                    }
                    
                    pVals.push( { num: ind, name: item.title, val: val, val_view: val, msg: item.msg, class: item.class, url_data: urlData, type_view: tView } );
                    ind++;
                });
                self.propVals = pVals;
          }
          
          if( !this.forSys )
          {
                conf.getPropsByName( this.model.part_type )
                        .then( ( fullPList ) =>
                {
                     // this.$utils.sortArray( fullPList, ( a ) => ( this.$utils.clearType( a.urn ) ) );
                      for( var i = 0; i < fullPList.length; i++ )
                      {
                          let isList = false;
                          let item = fullPList[ i ];
                          let item_val = {};
                          if( self.model.properties )
                          {
                              for( var j = 0; j < self.model.properties.length; j++ )
                              {
                                  if( this.$utils.noGUID( self.model.properties[ j ].name ) === item.urn )
                                  {
                                      item_val = self.model.properties[ j ];
                                      break;
                                  }
                              }
                          }
                          
                          let tView = this.$utils.getPropType( item.domains ) || 'T';
                          
                          let pVal = item_val.val;
                          if( pVal && ( tView === 'B' || tView === 'E' ) )
                               pVal = this.$utils.clearType( pVal );
                           
                          let urlData = null;
                          if( pVal && tView === 'D' )
                          {
                              if( pVal.indexOf( ' ' ) > -1 )
                                  pVal = pVal.replace( ' ', 'T' );
                              
                              let d = new Date( pVal + 'Z' );
                              pVal = d.toLocaleDateString();
                          }
                          else if( pVal && tView === 'N' )
                          {
                              item_val.val = item_val.num_val;
                              pVal = item_val.num_val.toString();
                          }
                          else if( tView === 'A' )
                          {
                              isList = true;
                          }
                          else
                            urlData = this.$utils.getURLData( pVal );    
                        
                          for( let i = 0; i < item.super_types.length; i++ )
                          {
                              if( item.super_types[ i ] === 'urn:plcs:rdl:TruePLM:Derived_property' )
                              {
                                  tView = 'D' + tView;
                                  break;
                              }
                          }
                        
                          pVals.push( { num: ind, name: self.$utils.clearType( item.urn ), type: item.urn, unit_code: item_val.unit, type_view: tView,
                                  val_view: pVal, val: item_val.val, unit: self.$utils.clearType( item_val.unit ), url_data: urlData, isList: isList,
                                  edit: ( !item.expression && ( !( item.editable_for && item.editable_for.length > 0 ) || 
                                            self.globalState.curUserRole === 'urn:rdl:epm-std:project_manager' ) ), obj: item } );
                          ind++;
                      }
                      self.propVals = pVals;
                 });
          }
      },
      updateProd( data )
      {
          if( !data )
              return;

          this.model = data;
          if( data.part )
          {
              this.model = data.part;
              this.model.elemID = data.elemID;
              this.model.parent = data.parent;
          }
          
          if( this.model && this.model.name )
             this.procModel();
          else
              this.propVals = [];
      }
  },
  watch: {
      data( val ) 
      {
          this.updateProd( this.data );
      }
  },
  mounted: function() 
  { 
      var self = this;
      
      this.$eventBus.$on( 'selectedModel', function()
      {
          self.model = {};
          self.propVals = [];
      });
      
      this.$eventBus.$on( 'updateNode', function()
      {
          self.propVals = [];
      });
      
      this.$eventBus.$on( 'showSelProps', function()
      {
          self.propVals = [];
      });
      
      self.updateProd( self.data );
  },   
  beforeDestroy: function()
  {
      this.$eventBus.$off( 'selectedModel' );
      this.$eventBus.$off( 'updateNode' );
      this.$eventBus.$off( 'showSelProps' );
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .propTbl td
    {
        height: 30px;
    }
</style>
