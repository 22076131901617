<template>
    <v-card style="height: calc(100vh - 181px);">

      <v-flex xs8 sm6 offset-xs1 d-flex v-if="prop.type !== 'doc'" style="padding-top: 10px;">
<!--          <v-select :label="listLabel" item-text="txt" item-value="val" v-model="nodeType"
                        :items="types" @change="typeChanged()"></v-select>     -->
          <v-autocomplete :label="listLabel" v-model="nodeType" @change="typeChanged()"
                         no-data-text="All types" :clearable="nodeType !== model.rdlName"
                        :items="types" item-text="txt" item-value="val">
          </v-autocomplete>
      </v-flex>
      <v-flex xs8 sm6 offset-xs1 d-flex v-if="readOnlyStruct">
             <span style="font-size: small; color: red;">{{$t( 'message.struct_in_use' )}}</span>
      </v-flex>
        
<!--      <v-flex xs8 sm5 d-flex v-if="isForNode" style="padding-left: 20px; padding-top: 20px">
        <v-select :label="$t( 'message.node_type_order_by' )" item-text="txt" item-value="val" v-model="orderBy"
                        :items="orderFlds" @change="orderChanged()"></v-select>    
      </v-flex>-->
        
      <v-data-table :headers="heads" :items="propVals" hide-default-footer class="elevation-1 propTbl"
                    :items-per-page=-1 fixed-header :height="tHi">
        <template v-slot:header.num="{ header }">
            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
        </template>
        <template v-slot:header.val_view="{ header }">
            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
        </template>
        <template v-slot:header.name="{ header }">
            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
        </template>
        <template v-slot:header.prnType="{ header }">
            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
        </template>
        <template v-slot:header.type="{ header }">
            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
        </template>
        <template v-slot:header.vals="{ header }">
            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
        </template>
        <template v-slot:header.units="{ header }">
            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
        </template>
        <template v-slot:header.ro="{ header }">
            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
        </template>
        <template v-slot:header.act="{ header }">
            <v-tooltip bottom v-if="( prop.type !== 'struct' || nodeType !== 'urn:plcs:rdl:TruePLM:ComplexDataType' )
                       && prop.rdlName !== 'urn:plcs:rdl:TruePLM:System_event' && !readOnlyStruct">
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on" @click="addProp()">add</v-icon>
                </template>
                <span>{{ $t( 'message.prop_add_btn' ) }}</span>
            </v-tooltip>
            <v-tooltip bottom v-if="prop.type !== 'struct' && prop.type !== 'prod' && prop.rdlName !== 'urn:plcs:rdl:TruePLM:System_event'">
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on" @click="addDProp()">playlist_add</v-icon>
                </template>
                <span>{{ $t( 'message.prop_dadd_btn' ) }}</span>
            </v-tooltip>
        </template>
        
        <template v-slot:item="{ item }">
            <tr draggable @dragstart="startDrag( $event, item )" @drop="onDrop($event, item)" 
                @dragover.prevent @dragenter.prevent>
                  <td>{{ item.num + 1 }}</td>
                  <td :class="{ mandatory_field: item.isMandatory }">{{ item.val_view }}</td>
                  <td>{{ item.prnType }}</td>
                  <td>{{ item.type }}</td>
                  <td>{{ item.vals || item.exp }}</td>
                  <td>{{ item.units }}</td>
                  <td>
                      <v-checkbox v-model="item.ro" disabled></v-checkbox>
                  </td>
                  <td>
                      <v-tooltip bottom v-if="!item.readOnly">
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" small class="mr-2" @click="editItem( item )">edit</v-icon>
                            </template>
                            <span>{{ $t( 'message.prop_editP_btn' ) }}</span>
                      </v-tooltip>
                      <v-tooltip bottom v-if="item.canDel">
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" small class="mr-2" @click="deleteItem( item )">delete</v-icon>
                            </template>
                            <span>{{ $t( 'message.prop_deleteP_btn' ) }}</span>
                      </v-tooltip>
                      <v-tooltip bottom v-if="prop.type === 'struct' && !item.readOnly">
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" small class="mr-2" @click="setKeyItem( item )">vpn_key</v-icon>
                            </template>
                            <span>{{ $t( 'message.prop_keyP_btn' ) }}</span>
                      </v-tooltip>
                      <v-tooltip bottom v-if="prop.rdlName === 'urn:rdl:epm-std:breakdown_element_type'">
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" @click="addNotify( item )" small>notifications</v-icon>
                            </template>
                            <span>{{ $t( 'message.prop_notify_btn' ) }}</span>
                      </v-tooltip>
<!--                      <v-tooltip bottom v-if="prop.rdlName === 'urn:rdl:epm-std:breakdown_element_type'">
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" @click="moveProp( item )" small>get_app</v-icon>
                            </template>
                            <span>{{ $t( 'message.prop_move_btn' ) }}</span>
                      </v-tooltip>-->
                  </td>
            </tr>
        </template>
      </v-data-table>
    </v-card>
</template>

<script>
import{ conf } from '@/utils/confTruePLM';

export default {
  name: 'PropInfoNode',
  props: {
      prop: null
  },
  data () {
    return {
        heads: [
            { text: 'message.prop_num_col', value: 'num', width: '10%', align: 'right' },
            { text: 'message.prop_name_col', value: 'val_view' }, 
            { text: 'message.prop_prn_col', value: 'prnType' }, 
            { text: 'message.prop_type_col', value: 'type' }, 
            { text: 'message.prop_vals_col', value: 'vals' }, 
            { text: 'message.prop_units_col', value: 'units' }, 
            { text: 'message.prop_ro_col', value: 'ro' }, 
            { width: '15%', sortable: false, value: 'act' }
        ],
        propVals : [],
        propList: [],
        model: this.prop,
        nodeType: null,
        types: [],
        listTypes: [],
        orderBy: 'name',
        orderByFin: 'name',
        orderFlds: []
    };
  },
  watch: {
      prop() 
      {
          this.nodeType = null;
          this.updateModel();
      }
  },
  computed:
  {
      readOnlyStruct()
      {
          return this.prop.type === 'struct' && this.propVals && this.propVals[ 0 ] && this.propVals[ 0 ].readOnly;
      },
      isForNode()
      {
          return this.prop.rdlName === 'urn:rdl:epm-std:breakdown_element_type';
      },
      listLabel()
      {
         if( this.prop.rdlName === 'urn:plcs:rdl:TruePLM:ComplexDataType' )
              return this.$t( 'message.node_prop_sel_struct' );
          else if( this.prop.rdlName === 'urn:plcs:rdl:TruePLM:Requirement' )
              return this.$t( 'message.node_prop_sel_req' );
          else if( this.prop.rdlName === 'urn:rdl:epm-std:Document_content_type' )
              return this.$t( 'message.node_prop_sel_doc' );
          else if( this.prop.rdlName === 'urn:rdl:epm-std:Part_type' )
              return this.$t( 'message.node_prop_sel_prod' );
          else if( this.prop.rdlName === 'urn:plcs:rdl:TruePLM:System_event' )
              return this.$t( 'message.node_prop_sel_event' );
          else
              return this.$t( 'message.node_prop_sel' );
      },
      tHi()
      {
//          if( !this.isForNode )
              return 'calc(100vh - 260px)';
//          else
//              return 'calc(100vh - 345px)';
      }
  },
  methods: 
  {
      moveProp( item )
      {
          console.log( item );
          let self = this;
 
          conf.moveProp( item.obj.urn, item.obj.assigned_to, this.nodeType ) 
                  .then( () => 
          {
               self.updateModel( self.model, self.nodeType );  
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      startDrag( evt, item ) 
      {
          evt.dataTransfer.dropEffect = 'move';
          evt.dataTransfer.effectAllowed = 'move';
          localStorage.setItem( 'dragID', '' + item.num );
      },
      onDrop( evt, item ) 
      {
          if( this.readOnlyStruct )
              return;
          
          let dragID = localStorage.getItem( 'dragID' );
          localStorage.removeItem( 'dragID' );

          let elem = this.propVals[ dragID ];
          this.propVals.splice( dragID, 1 );
          this.propVals.splice( item.num, 0, elem );
          
          let orderStr = '';
          this.propVals.forEach( p => 
          {
              if( orderStr === '' )
                  orderStr = p.obj.urn;
              else
                  orderStr += ',' + p.obj.urn;
          });

          let self = this;
          conf.setPropOrder( self.nodeType, orderStr )
                  .then( () => 
          {
               self.updateModel( self.model, self.nodeType );  
          } )
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      addNotify( item )
      {
          let self = this;
          let trgObj = null;
          
          let trgInfo = {};
    
          let tParts = item.type.split( ' ' );
          let isAggr = tParts.length > 1 && tParts[ tParts.length - 1 ] === 'List';
          let aggrRules = null;
          
          conf.listTriggers( true, 'urn:plcs:rdl:TruePLM:Node_property' )
                  .then( tList => 
          {
              let trg = { active: true, events: 'urn:plcs:rdl:TruePLM:Node_property',
                            condition: ( '#Updated_property = \'' + item.val_view + '\'' ) };
              const regExp = new RegExp( 'Updated_property\\s?=\\s?\'' + item.val_view + '\'' );          
              for( let i = 0; i < tList.length; i++ )
              {
                  if( tList[ i ].condition && tList[ i ].condition.match( regExp ) )
                  {
                      trg = tList[ i ];
                      break;
                  }
              }
          
              return this.$modalDlg.sysDlgs.addNotifyDlg.open( trg, isAggr ? item.obj : null );
          })
                  .then( info =>
          {
              aggrRules = info.aggrRules;
              trgInfo = info.trigger;
              if( trgInfo.act === 'NEW_ACT' )
                    return conf.defineAction( info.act );
              else
                    return Promise.resolve( { name: trgInfo.act } );
          }, () => {})
                  .then( act => 
          {
              if( act )
              {
                    trgInfo.act = act.name;
                    return conf.defineTrigger( trgInfo );
              }
          })
                  .then( trg =>
          {
              if( trg )
              {
                  trgObj = trg;
                  if( aggrRules )
                      return conf.setAggrRules( aggrRules, item.obj.urn );
                  else
                      return Promise.resolve( true );
              }
          })
                  .then( rez =>
          {
              if( rez )
              {
                  self.$modalDlg.sysDlgs.confirmDlg.open( 'create_trg_done_title', 'create_trg_done_msg', null, trgObj.name, true );
              }     
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });

      },
      
      addDProp()
      {
          let self = this;
          this.$modalDlg.sysDlgs.createDPropDlg.open( null, this.propList )
                  .then( ( prop ) =>
          {
              return conf.addDerivedProp( prop, self.nodeType );
          }, () => {} )
                  .then( () => 
          {
               self.updateModel( self.model, self.nodeType );  
          } )
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      addProp()
      {
          let self = this;
          let lists = this.isForNode ? this.listTypes : null;          
          this.$modalDlg.sysDlgs.createPropDlg.open( null, lists, this.isForNode )
                  .then( ( prop ) =>
          {
              self.addPropWithCheck( prop );
          }, () => {} );
      },
      addPropWithCheck( prop )
      {
          let self = this;
          conf.getPropInfo( prop.name )
                .then( info =>
          {
              if( this.$utils.clearType( info.urn ) === prop.name 
                      && this.$utils.clearType( info.domain ) !== prop.type )
                  self.addPropWarning( info, prop );
              else
                  self.addPropNow( prop );
          })
                .catch( ( err ) =>
          {
              self.addPropNow( prop );
          });
      },
      addPropWarning( info, prop )
      {
          let self = this;
          if( info.inUse )
              this.$modalDlg.sysDlgs.confirmDlg.openError( 'error_ttl', 'error_prop_exist_used_msg' );
          else
          {
              this.$modalDlg.sysDlgs.confirmDlg.openWarning( 'warning_ttl', 'warning_prop_exist_msg' )
                      .then( () =>
              {
                  self.addPropNow( prop );
              }, () => {} );
          }
      },
      addPropNow( prop )
      {
          let self = this;
          conf.addRefDataProp( prop, self.nodeType )
                  .then( () => 
          {
               self.updateModel( self.model, self.nodeType );  
          } )
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      editItem( inProp )
      {
          if( inProp.exp )
              this.editDPropItem( inProp );
          else
              this.editPropItem( inProp );
      },
      editDPropItem( inProp )
      {
          var self = this;
          this.$modalDlg.sysDlgs.createDPropDlg.open( inProp, this.propList )
                  .then( ( prop ) =>
          {
              return conf.addDerivedProp( prop, self.nodeType );
          }, () => {} )
                  .then( () => 
          {
               self.updateModel( self.model, self.nodeType );  
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      editPropItem( inProp )
      {
          let self = this;
          let lists = this.isForNode ? this.listTypes : null;         
          this.$modalDlg.sysDlgs.createPropDlg.open( inProp, lists, this.isForNode )
                  .then( ( prop ) =>
          {
//              console.log( prop );
              return conf.addRefDataProp( prop, self.nodeType );
          }, () => {} )
                  .then( () => 
          {
               self.updateModel( self.model, self.nodeType );  
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      setKeyItem( prop )
      {
  //        console.log( prop );
          let self = this;
          this.$modalDlg.sysDlgs.confirmDlg.open( 'nodeprop_set_key_title', 'nodeprop_set_key_msg', null, prop.val_view )
                  .then( () =>
          {
              if( prop.obj.cur_type === 'urn:plcs:rdl:std:Date_time' )
                  return this.$modalDlg.sysDlgs.dateFormatDlg.open( prop.obj );
              else
                  return Promise.resolve();
          }, () => {} )
                .then( format =>
          {
              if( format )
                  return conf.setDateFormat( self.nodeType, format );
              else
                  return Promise.resolve();
          }, () => {} )
                  .then( () =>
          {
              return conf.setKeyProp( prop.obj.urn, self.nodeType );
          }, () => {} )
                  .then( () =>
          {
              self.updateModel( self.model, self.nodeType );              
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });  
      },
      deleteItem( prop )
      {
          let self = this;
          let msg = 'nodeprop_delete_msg';
          if( prop.obj && prop.obj.inUse )
              msg = 'nodeprop_used_delete_msg';
          let ttl = 'nodeprop_delete_title';
          if( this.prop.rdlName === 'urn:rdl:epm-std:Document_content_type' )
              ttl = 'docprop_delete_title'
          this.$modalDlg.sysDlgs.confirmDlg.open( ttl, msg )
                  .then( () =>
          {
              return conf.deleteRefData( prop.obj.urn, self.nodeType );
          }, () => {} )
                .then( () =>
          {
              return conf.deletePropVals( prop.obj.urn, self.nodeType );
          }, () => {} )
                  .then( () =>
          {
              self.updateModel( self.model, self.nodeType );              
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });          
      },
      getPropType( elem )
      {
          let curType = '';
          let type = '';
          
          for( let i = 0; i < elem.super_types.length; i++ )
          {
              if( elem.super_types[ i ] === 'urn:plcs:rdl:TruePLM:Derived_property' )
              {
                  type = 'Derived /';
                  break;
              }
          }
          
          let isList = false;
          for( let i = 0; elem.domains && i < elem.domains.length; i++ )
          {
              curType = elem.domains[ i ];
              let info = this.$utils.clearType( curType );
              if( info === 'Date_time' )
                  type += ' Date';
              else if( info === 'formatted_counter' )
                  type += ' Formatted Counter'; 
              else if( info === 'formatted_serial' )
                  type += ' Formatted Serial'; 
              else if( info === 'counter' )
                  type += ' Counter'; 
              else if( info === 'serial' )
                  type += ' Serial'; 
              else if( info === 'extended_counter' )
                  type += ' Extended Counter'; 
              else if( info === 'Boolean' )
                  type += ' Boolean'; 
              else if( info === 'oneOf' )
                  type += ' Enumeration';
              else if( info === 'Numeric_value' )
                  type += ' Numeric';
              else if( info === 'Text_value' )
                  type += ' Text';
              else if( info === 'List' )
                  isList = true;
              else
                  type += ' ' + info;
          }
          if( isList )
              type += ' List';
          
          elem.cur_type = curType;
          return type;
      },
      
      orderChanged()
      {
          var self = this;
          this.$modalDlg.sysDlgs.confirmDlg.open( 'nodeprop_set_order_title', 'nodeprop_set_order_msg', null, this.orderBy )
                  .then( () =>
          {
              return conf.setOrderProp( self.orderBy, self.nodeType );
          }, () => { self.orderBy = self.orderByFin; } )
                  .then( rez => 
          {
             //console.log( rez );
          }) 
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });  
      },
      
      typeChanged()
      {
          let self = this;
          self.propVals = [];
          
          this.orderBy = null;
          this.orderByFin = null;
          this.orderFlds = [ { txt: 'Name', val: 'urn:plcs:rdl:std:Name' } ];
          
          self.$modalDlg.sysDlgs.progressDlg.open();
          
    //      console.log( this.nodeType, this.prop.type, this.prop.rdlName  );
          if( !self.nodeType )
              self.nodeType = self.model.rdlName
              
          conf.getOrderProp( self.nodeType )
                  .then( data =>
          {
              if( data && data.length > 0 )
              {
                    self.orderBy = data[ 0 ];
                    self.orderByFin = data[ 0 ];
              }
          });
          
          if( this.prop.rdlName === 'urn:rdl:epm-std:Document_content_type' )
          {
              self.propList = [ { txt: 'Title', val: 'title' }, 
                                { txt: 'File name', val: 'file_name' }, 
                                { txt: 'Description', val: 'description' },
                                { txt: 'Created by', val: 'created_by_user' },
                                { txt: 'Created date', val: 'date_created' },
                                { txt: 'Last modified by', val: 'last_ver_user' },
                                { txt: 'Last modified date', val: 'last_ver_date' },
                                { txt: 'Version', val: 'last_ver_id' },
                                { txt: 'Phase', val: 'last_ver_phase' },
                                { txt: 'Approver', val: 'approver' },
                                { txt: 'Release manager', val: 'release_manager' },
                                { txt: 'Responsible', val: 'responsible' },
                                { txt: 'Reviewer', val: 'reviewer' },
                                { txt: 'Discipline', class: 'urn:rdl:epm-std:project_discipline' },
                                { txt: 'External version', val: 'external_version' },
                                { txt: 'Format', val: 'data_type' },
                                { txt: 'RID references', val: 'rid'  },
                                { txt: 'Source', val: 'source', class: 'urn:rdl:epm-std:Source_of_information' },
                                { txt: 'Status', val: 'status', class: 'urn:rdl:epm-std:Document_status' },
                                { txt: 'Type', val: 'document_type', class: 'urn:rdl:epm-std:Document_content_type' },
                                { txt: 'UID', val: 'id' },
                                { txt: 'Instance ID', val: 'instance_id' },
                                { txt: 'Assigning_document instance ID', val: 'ass_doc_instance_id' },
                                { txt: 'File size', val: 'size' }, ];
          }
          else
          {
                self.propList = [ { txt: 'Name', val: 'name' }, 
                                { txt: 'Description', val: 'description' },
                                { txt: 'Created by', val: 'created_by_user' },
                                { txt: 'Created date', val: 'date_created' },
                                { txt: 'Last modified by', val: 'last_modified_by_user' },
                                { txt: 'Last modified date', val: 'date_last_modified' },
                                { txt: 'Phase', val: 'phase' },
                                { txt: 'Type', val: 'element_type' },
                                { txt: 'Version', val: 'version_id' },
                                { txt: 'UID', val: 'id' },
                                { txt: 'Instance ID', val: 'instance_id' } ];
         }
                      
          let tmpValList = [];
          let key = '';
          let dateFormat = null;
          let func = () => { return Promise.resolve( [] ); };
          if( this.prop.type === 'struct' && self.nodeType )
            func = () => { return Promise.all( [ conf.getKeyProp( self.nodeType ), conf.getDateFormat( self.nodeType ) ] ) };
          
          func()
                  .then( ( data ) =>
          {
              if( data && data.length > 0 )
              {
                key = data[ 0 ][ 0 ];
                dateFormat = data[ 1 ][ 0 ];
              }
              
              return conf.getPropWithStatus( self.nodeType, self.prop.type === 'struct' );
          })
                  .then( ( data ) =>
          {
              if( !data || !data.length )
              {
                  self.$modalDlg.sysDlgs.progressDlg.close();
                  return;
              }
              
              let ind = 0;
              const curUrn = 'urn:rdl:' + conf.getProjModel() + ':';
              data.forEach( ( elem ) =>
              {
                  let prnType = '';
                  let type = self.getPropType( elem );
                  
                  let urn_view = self.$utils.clearType( elem.urn );
                  let urn_val = ( ( curUrn + urn_view ) === elem.urn ) ? urn_view : elem.urn;
                  
                  if( 'Derived' !== type.substring( 0, 7 ) )
                      self.propList.push( { txt: urn_view, val: urn_val } );
                  
                  if( elem.urn === key )
                  {
                      type += ' / Key';
                      if( dateFormat )
                      {
                          let fParts = dateFormat.split( '&&&' );
                          elem.dateType = fParts[ 0 ];
                          if( elem.dateType === 'date' )
                              elem.expression = fParts[ 1 ];
                          else if( elem.dateType === 'sec' )
                              elem.expression = this.$t( 'message.date_format_sec_type' );
                          else if( elem.dateType === 'msec' )
                              elem.expression = this.$t( 'message.date_format_msec_type' );
                      }
                  }
                  
                  if( elem.assigned_to !== this.nodeType )
                  {
                      prnType = self.$utils.clearType( elem.assigned_to );
                      if( 'breakdown_element_type' === prnType )
                          prnType = 'Breakdown element';
                  }
                  
                  var vals = '';
                  var units = '';
                  if( elem.units )
                  {
                      units = self.$utils.clearType( elem.units[ 0 ] );
                      for( var i = 1; i < elem.units.length; i++ )
                          units += ', ' + self.$utils.clearType( elem.units[ i ] );
                  }
                  if( elem.options )
                  {
                      vals = self.$utils.clearType( elem.options[ 0 ] );
                      for( var i = 1; i < elem.options.length; i++ )
                          vals += ', ' + self.$utils.clearType( elem.options[ i ] );
                  }
                  
                  let ro = false;
                  for( let i = 0; elem.editable_for && i < elem.editable_for.length; i++ )
                  {
                      if( elem.editable_for[ i ] === 'urn:rdl:epm-std:project_manager' )
                      {
                          ro = true;
                          break;
                      }
                  }
                  
                  if( 'Derived' !== type.substring( 0, 7 ) ) 
                      self.orderFlds.push( { val: elem.urn, txt: urn_view } );
                  
                  let canDel = ( self.nodeType === elem.assigned_to ) 
                          && ( !elem.inUse ||
                                 self.prop.rdlName === 'urn:rdl:epm-std:breakdown_element_type' ||
                                 self.prop.rdlName === 'urn:rdl:epm-std:Document_content_type' );
                  let readOnly = ( self.nodeType !== elem.assigned_to ) && ( 'Derived' !== type.substring( 0, 7 ) );
                  if( self.prop.type === 'struct' )
                      readOnly = elem.inUse;
                  
                  tmpValList.push( { num: ind++, obj: elem, val_view: urn_view, type: type, vals: vals, units: units, 
                      isMandatory: !elem.optional_flag, prnType: prnType, exp: elem.expression, readOnly: 
                      readOnly || !elem.editable, ro: ro, canDel: canDel } );   
              });

              self.propVals = tmpValList;
              
              self.$modalDlg.sysDlgs.progressDlg.close();
          })
                .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              this.$eventBus.$emit( 'queryError', err );
          });
      },
      updateModel( nodeType )
      {
          this.propVals = [];
          this.model = this.prop;
          this.types = ( this.model.type === 'struct' ) ? [] : [ { val: this.model.rdlName, txt: 'All types' } ];
          this.nodeType = this.nodeType || nodeType || this.model.rdlName;
          let self = this;
          
          self.$modalDlg.sysDlgs.progressDlg.open();
          
          conf.getChildTypesInContext( this.model.rdlName )
                  .then( ( data ) =>
          {
              this.$utils.sortArray( data, ( a ) => ( this.$utils.clearType( a ) ) );
              data.forEach( ( val ) =>
              {
                  self.types.push( { val: val, txt: this.$utils.clearType( val ) } );     
              });
              
              if( self.model.rdlName === 'urn:plcs:rdl:TruePLM:System_event' )
                  return conf.getChildTypesInContext( 'urn:plcs:rdl:TruePLM:User_defined_event' );
              else
                  return Promise.resolve();
          } )
                  .then( ( data ) =>
          {
              if( data )
              {
                  data.forEach( ( val ) =>
                  {
                      self.types.push( { val: val, txt: this.$utils.clearType( val ) } );                                  
                  }); 
              }
          })
                  .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.$eventBus.$emit( 'queryError', err );
          });
          
          conf.getChildTypes( 'urn:plcs:rdl:TruePLM:ComplexDataType' )
                  .then( ( data ) =>
          {
              self.listTypes = data;
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
          
          self.typeChanged();          
      }
  },
  mounted: function() 
  { 
      this.updateModel();
  },   
  beforeDestroy: function()
  {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .propTbl td
    {
        height: 30px;
    }        
</style>
