<template>
    <v-card style="height: calc( 100vh - 181px ); overflow-y: auto;">
        <ul class="tree_view v-list--dense">
            <tree-node-view :key="model.update_ind" :model="model" :hideProps="hideProps"></tree-node-view>
        </ul>
    </v-card>
</template>

<script>
    
import TreeNode from './Node.vue'
    
export default {
  name: 'TreeRoot',
  props: {
      path: null
  },
  data () 
  {
    return {
        model: { update_ind: 0 },
        hideProps: true
    };
  },
  methods: {
      procPath( path )
      {
    //      console.log( path );
          if( !path )
              return null;
          
          var tree;
          tree = { name: path[ 1 ].name, instance_id: path[ 1 ].instance_id, opened: true, element_type: path[ 1 ].element_type };
          for( var i = 1, node = tree; i < path.length; i++ )
          {
              node = this.addChildren( node, path[ i ] );
              node.opened = true;
              if( i === ( path.length - 1 ) )
                  node.selected = true;
          }
          tree.update_ind = this.model.update_ind + 1;
          this.model = tree;
//          console.log( this.model );
      },
      addChildren( prn, infoNode )
      {
          if( prn.children )
          {
              var node = prn.children.filter( ( ch ) => { return ( ch.instance_id === infoNode.instance_id ); } );
              if( node && node.length > 0 )
                  prn = node[ 0 ];
          }
          
          prn.children = [];
          if( infoNode.children )
            infoNode.children.forEach( ( ch ) => { prn.children.push( { name: ch.name, instance_id: ch.instance_id, element_type: ch.element_type } ); } );
        
          return prn;              
      }
      
    },
   watch: {
      path( val ) 
      {
          this.procPath( this.path );
      }
  },
  components: {
    'tree-node-view': TreeNode
    
  },
  mounted: function()
  {
      this.procPath( this.path );
  },
  beforeDestroy: function()
  {
  }
}
</script>

<style>
    .tree_view
    {
        list-style-type: none;
    }
</style>
