<template>
    <v-card>
      <v-data-table :headers="heads" :items="items" :sort-by="sortProp" :sort-desc=true hide-default-footer class="elevation-1 propTbl"
                    :items-per-page=-1 fixed-header height="calc(100vh - 130px)">
        <template v-slot:header.name="{ header }">
            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
        </template>
        <template v-slot:header.adr="{ header }">
            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
        </template>
         <template v-slot:header.proj="{ header }">
            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
        </template>
        <template v-slot:header.act="{ header }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on" @click="addProj()">add</v-icon>
                </template>
                <span>{{ $t( 'message.proj_list_add_btn' ) }}</span>
            </v-tooltip>
        </template>
        
        <template v-slot:item="{ item }">
            <tr>
                  <td>{{ item.name }}</td>
                  <td>{{ item.adr }}</td>
                  <td>{{ item.proj }}</td>
                  <td nowrap>
<!--                      <v-tooltip bottom v-if="item.obj.is_deleted">
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" @click="setAct( item )">restore</v-icon>
                            </template>
                            <span>{{ $t( 'message.proj_list_act_btn' ) }}</span>
                      </v-tooltip>-->
<!--                      <v-tooltip bottom v-else>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" @click="setInact( item )">delete</v-icon>
                            </template>
                            <span>{{ $t( 'message.proj_list_inact_btn' ) }}</span>
                      </v-tooltip>-->
                      <v-tooltip bottom >
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" @click="deleteItem( item )">delete_forever</v-icon>
                            </template>
                            <span>{{ $t( 'message.sync_list_delete_btn' ) }}</span>
                      </v-tooltip>
                  </td>
            </tr>
        </template>
      </v-data-table>
    </v-card>
</template>

<script>
import{ conf } from '@/utils/confTruePLM';

export default {
  name: 'SyncList',
  props: {
//      proj: null
  },
  data () {
    return {
        sortProp: 'status',
        heads: [
            { text: 'message.sync_list_name_col', value: 'name' }, 
            { text: 'message.sync_list_adr_col', value: 'adr' }, 
            { text: 'message.sync_list_proj_col', value: 'proj' }, 
            { width: '15%', sortable: false, value: 'act' }
        ],
        globalState: this.$globalStore.state,
        items : []
    };
  },
  methods: 
  {
      addProj()
      {
          var self = this;
          self.$modalDlg.sysDlgs.createSyncDlg.open( true )
                  .then( ( sync ) =>
          {
              if( sync )
                  self.createSync( sync );  
          }, () => {} );   
      },
      createSync( info )
      {
          var self = this;
          self.$modalDlg.sysDlgs.progressDlg.open();
          conf.createSync( info.name, info.adr, info.proj )
                  .then( ( rez ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.procList();  
    
              if( rez )
                  this.$modalDlg.sysDlgs.confirmDlg.open( 'create_sync_done_title', 'create_sync_done_msg', null, info.name, true );
          }, ( err )=>
          { 
              self.$modalDlg.sysDlgs.progressDlg.close();
              
              self.$eventBus.$emit( 'queryError', err ); 
          } );          
      },
      setInact( item )
      {
          var self = this;
          this.$modalDlg.sysDlgs.confirmDlg.open( 'proj_list_inact_title', 'proj_list_inact_msg', null, item.name )
                  .then( ( info ) =>
          {
              return conf.inactiveProj( item.obj.guid );
          }, () => {} )
                  .then( () => 
          {
              self.procList();  
              self.$eventBus.$emit( 'updateProjList' );               
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });      
      },
      setAct( item )
      {
          var self = this;
          this.$modalDlg.sysDlgs.confirmDlg.open( 'proj_list_act_title', 'proj_list_act_msg', null, item.name )
                  .then( ( info ) =>
          {
              return conf.activeProj( item.obj.guid );
          }, () => {} )
                  .then( () => 
          {
              self.procList();  
              self.$eventBus.$emit( 'updateProjList' );               
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });      
      },
      deleteItem( item )
      {
          var self = this;
          this.$modalDlg.sysDlgs.confirmDlg.open( 'sync_list_delete_title', 'sync_list_delete_msg', null, item.name )
                  .then( () =>
          {
              return conf.deleteSync( item.name );
          }, () => {} )
                  .then( () =>
          {
              self.procList();              
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      procList()
      {
          this.items = [];
          var self = this;
          conf.getAllSync()
                  .then( ( data ) =>
          {
          //    console.log( data );
              if( !data )
                  return;
              
              data.forEach( ( elem ) =>
              {
                  self.items.push( { name: elem.id, adr: elem.url, proj: elem.project_id } );                                        
              });     
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      }
  },
  mounted: function() 
  { 
      var self = this;
      this.$eventBus.$on( 'editSyncList', function()
      {
          self.procList();
      });
  },
  beforeDestroy: function()
  {
      this.$eventBus.$off( 'editSyncList' );
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .propTbl td
    {
        height: 30px;
    }        
</style>
