<template>
    <v-card>
      <v-data-table :headers="heads" :items="users" sort-by="name"  hide-default-footer class="elevation-1 propTbl" 
                    :items-per-page=-1 fixed-header height="calc(100vh - 130px)">
        <template v-slot:header.name="{ header }">
            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
        </template>
        <template v-slot:header.rName="{ header }">
            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
        </template>
        <template v-slot:header.email="{ header }">
            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
        </template>
        <template v-slot:header.org="{ header }">
            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
        </template>
        <template v-slot:header.use2fa="{ header }">
            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
        </template>
        <template v-slot:header.act="{ header }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on" @click="setItem()">add</v-icon>
                </template>
                <span>{{ $t( 'message.user_add_btn' ) }}</span>
            </v-tooltip>
        </template>
        
        <template v-slot:item="{ item }">
            <tr>
                <td>{{ item.name }}</td>
                <td>{{ item.rName }}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.org }}</td>
                <td>{{ item.use2fa ? 'Yes' : 'No' }}</td>
                <td>
                    <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                              <v-icon v-on="on" @click="setPass( item )">security</v-icon>
                          </template>
                          <span>{{ $t( 'message.user_edit_pass_btn' ) }}</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                              <v-icon v-on="on" @click="setItem( item )">edit</v-icon>
                          </template>
                          <span>{{ $t( 'message.user_edit_edit_btn' ) }}</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                              <v-icon v-on="on" @click="deleteItem( item )">delete</v-icon>
                          </template>
                          <span>{{ $t( 'message.user_edit_delete_btn' ) }}</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                              <v-icon v-on="on" @click="use2fa( item )">verified_user</v-icon>
                          </template>
                          <span>{{ $t( 'message.user_edit_2fa_btn' ) }}</span>
                    </v-tooltip>
                </td>
            </tr>
        </template>
      </v-data-table>
    </v-card>
</template>

<script>
import{ conf } from '@/utils/confTruePLM';

export default {
  name: 'UserList',
  props: {
//      proj: null
  },
  data () {
    return {
        heads: [
            { text: 'message.user_name_col', value: 'name' }, 
            { text: 'message.user_real_name_col', value: 'rName' }, 
            { text: 'message.user_email_col', value: 'email' }, 
            { text: 'message.user_org_col', value: 'org' }, 
            { text: 'message.user_2fa_col', value: 'use2fa' }, 
            { width: '15%', sortable: false, value: 'act' }
        ],
        globalState: this.$globalStore.state,
        users : []
    };
  },
  methods: 
  {
      setPass( user )
      {
          let self = this;
          this.$modalDlg.sysDlgs.setUserPassDlg.open( user.name, true )
                  .then( ( info ) =>
          {
              return conf.setUserPassByAdmin( user.name, info.newPass );
          }, () => { return Promise.resolve(); } )
                  .then( ( rez ) => 
          {
              if( rez && !rez.error_code )
                this.$modalDlg.sysDlgs.confirmDlg.open( 'user_pass_done_title', 'user_pass_done_msg', null, user.name, true )
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      setItem( user )
      {
          var self = this;
          this.$modalDlg.sysDlgs.createUserDlg.open( user )
                  .then( ( info ) =>
          {
              return conf.setUser( info );
          }, () => {} )
                  .then( () => 
          {
               self.procList();  
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });      
      },
      use2fa( user )
      {
//          let self = this;
//          this.$modalDlg.sysDlgs.confirmDlg.open( 'user_2fa_disable_title', 'user_2fa_disable_msg', null, user.name )
//                  .then( () =>
//          {
//              return conf.disble2faUser( user.name );
//          }, () => {} )
//                  .then( () =>
//          {
//              self.procList();              
//          })
//                  .catch( ( err ) =>
//          {
//              self.$eventBus.$emit( 'queryError', err );
//          });
          let self = this;
          
          this.$modalDlg.sysDlgs.set2faDlg.open( user.name )
                  .then( rez =>
          {
              return conf.set2fa( user.name, rez.use2fa, !rez.code );
          }, () => { return Promise.resolve(); } )
                  .then( rez =>
          {
              if( rez && rez.user_2FA_code )
              {
                  if( rez.use_2FA )
                    this.$modalDlg.sysDlgs.confirm2faDlg.open( rez.user_2FA_code, user.name );
                  else
                    this.$modalDlg.sysDlgs.confirmDlg.open( 'set_2fa_title', 'notuse_2fa_msg', user.name, null, true );
                
                  self.procList();      
              }
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });  
      },
      deleteItem( user )
      {
          let self = this;
          this.$modalDlg.sysDlgs.confirmDlg.open( 'user_name_delete_title', 'user_name_delete_msg', null, user.name )
                  .then( () =>
          {
              return conf.deleteUser( user.name );
          }, () => {} )
                  .then( () =>
          {
              self.procList();              
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      procList()
      {
          this.users = [];
          var self = this;
          conf.getAllUsers()
                  .then( ( data ) =>
          {
              if( !data )
                  return;
              
              data.forEach( ( elem ) =>
              {
                  if( elem.user_name !== 'system' && elem.user_name !== 'superuser' )
                      self.users.push( { name: elem.user_name, email: elem.user_email, org: elem.organization, 
                          rName: elem.real_name, use2fa: elem.use_2FA } );                                        
              });     
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      }
  },
  mounted: function() 
  { 
      var self = this;
      this.$eventBus.$on( 'editUsers', function()
      {
          self.procList();
      });
  },
  beforeDestroy: function()
  {
      this.$eventBus.$off( 'editUsers' );
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .propTbl td
    {
        height: 30px;
    }        
</style>
