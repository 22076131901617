<template>
    
<!--      <v-container fluid fill-height>
          <v-layout align-center justify-center>-->
            <v-dialog v-model="visFl" persistent max-width="800">
                <v-toolbar flat color="white">
                    <v-toolbar-title>{{ $t( 'message.doc_links_title' ) }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
               <v-card>
                <v-card-text>
                    <v-list v-show="links1.length">
                        <v-subheader>{{ $t( 'message.doc_links1_title' ) }}</v-subheader>
                        <v-list-item v-for="(link, index) in links1" :key="link.instance_id"  @click="">
                            <v-list-item-action>
                                <v-img :src="imgSrc( link )" max-width="24"></v-img>
                            </v-list-item-action>
                            <v-list-item-content>
                                <span style="white-space: nowrap;">
                                    {{link.title}}
                                </span>
                                <v-list-item-subtitle>
                                    {{ link.description }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <span style="white-space: nowrap;">
                                    <v-icon @click="gotoFile( link )">visibility</v-icon>
                                    <v-icon @click="deleteLink( link, 1 )">delete_forever</v-icon>
                                </span>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                    <v-list v-show="links2.length">
                        <v-subheader>{{ $t( 'message.doc_links2_title' ) }}</v-subheader>
                        <v-list-group v-for="(link, index) in links2" :key="link.instance_id"  @click="" append-icon="">
                            <v-list-item slot="activator" style="padding-left: 0px; padding-right: 0px;">
                                <v-list-item-action >
                                    <v-img :src="imgSrc( link )" max-width="24"></v-img>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <span style="white-space: nowrap;">
                                        {{link.title}}
                                        <v-icon color="red lighten-2" v-if="isChanged( link )">flag</v-icon>
                                    </span>
                                    <v-list-item-subtitle>
                                        {{ link.description }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <span style="white-space: nowrap;">
                                        <v-icon @click.stop.prevent="gotoFile( link )">visibility</v-icon>
                                        <v-icon @click.stop.prevent="deleteLink( link, 2 )">delete_forever</v-icon>
                                    </span>
                                </v-list-item-action>
                            </v-list-item>
                            <v-list-item v-for="(flag, index) in flags( link )" :key="flag.instance_id"  @click="">
                                    <v-list-item-content>
                                        <span style="white-space: nowrap;">
                                            {{flag.title}} (ver.{{flagVer( flag )}})
                                            <v-icon color="red lighten-2">flag</v-icon>
                                        </span>
                                        <v-list-item-subtitle>
                                            {{ flagDescr( flag ) }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <span style="white-space: nowrap;">
                                            <v-icon @click="clearFlag( flag )">done</v-icon>
                                        </span>
                                    </v-list-item-action>
                            </v-list-item>
                            
                        </v-list-group>
                    </v-list>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click.native="close()">{{ $t( 'message.close_btn' ) }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>   
<!--          </v-layout>
      </v-container>-->
    
</template>

<script>
    
import Vue from 'vue'
import{ conf } from '../../utils/confTruePLM';

export default {
  name: 'stickyNotes',
  data () 
  {
    return {
        visFl: false,
        links1: [],
        links2: [],
        file: null,
        changed: false
    };
  },
  computed: {
  },
  methods: {
      imgSrc: function( link )
      { 
            try
            {
                let ext = link.data_type.substring( 1 ).toLowerCase();
                if( ext === 'docx' )
                    ext = 'doc';
                else if( ext === 'step' )
                    ext = 'stp';
                return require( '@/assets/file_icons/' + ext + '.png' );             
            }
            catch( err )
            {
                return require( '@/assets/file_icons/_blank.png' );             
            }
      },
      flagVer( flag )
      {
          var rez = flag.version_id;
          var ind = rez.indexOf( '|' );
          if( ind > -1 )
              rez = rez.substring( 0, ind );
          return rez;
      },
      
      flagDescr( flag )
      {
//          var rez = flag.version_id;
//          var ind = rez.indexOf( '|' );
//          if( ind > -1 )
//              rez = rez.substring( ind + 1 );
//          return rez;
        return flag.changes_description;
      },
      clearFlag( flag )
      {
          var self = this;
          conf.clearRedFlag( flag, self.file )
                  .then( ( rez ) => 
          {
              if( !rez.error_code )
              {
                  self.changed = true;
                  var ind = self.file.red_flags.indexOf( flag );
                  if( ind > -1 )
                      self.file.red_flags.splice( ind, 1 );
              }
          });
      },
      
      deleteLink( link, type )
      {
          var self = this;
          this.$modalDlg.sysDlgs.confirmDlg.open( 'conf_del_link_title', 'conf_del_msg' )
                  .then( () =>
          {
              if( type === 1 )
                  return conf.deleteDocLink( self.file, link );
              else
                  return conf.deleteDocLink( link, self.file );
          })
                  .then( ( rez ) => 
          {
              if( !rez.error_code )
              {
                  var lArr = self.links2;
                  if( type === 1 )
                      lArr = self.links1;
                      
                  var ind = lArr.indexOf( link );
                  if( ind > -1 )
                      lArr.splice( ind, 1 );
                  self.changed = true;
              }
          })
                  .catch( () => {} );
      },
      gotoFile( link )
      {
//          console.log( link );
          this.$eventBus.$emit( 'procRowMenuCmd', [ 'gotoNode', link, this.$globalStore.state.actVer ] );            
          this.$globalStore.setSelectedID( link.instance_id );  
          this.close();
      },
      flags( link )
      {
          let rez = [];
          for( let i = 0; link && this.file.red_flags && ( i < this.file.red_flags.length ); i++ )
          {
              if( link.id === this.file.red_flags[ i ].id )
                  rez.push( this.file.red_flags[ i ] );
          }
          
          return rez;
      },
      isChanged( link )
      {
          for( let i = 0; link && this.file.red_flags && ( i < this.file.red_flags.length ); i++ )
          {
              if( link.id === this.file.red_flags[ i ].id )
                  return true;
          }
          
          return false;
      },
      open( file )
      {
          this.file = file;
          this.links1 = file.affects_documents || [];
          this.links2 = file.affected_by_documents || [];
          this.visFl = true;
          
          return new Promise( ( resolve, reject ) => 
          {
            this.resolve = resolve;
            this.reject = reject;
          });
      },
      close()
      {
          this.visFl = false;
          this.resolve( this.changed );
      }
    }
}
</script>



