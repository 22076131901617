<template>
    <div>
        <v-card :class="'normalSize'" style="overflow-y: hidden">

            <search-table :headerInfo="headers" :items="items" pID="nodeTbl" :wHi="wHi" 
                          :rowMenuElems="rowMenu" @selectRow="selectRow" @rowMenuClick="rowMenuClick"
                          :exportXLSX="exportXLSX" :exportCSV="exportCSV" :exportJSON="exportJSON"></search-table>
            <div style="font-size: 14px; font-weight: 700; padding-left: 10px; padding-top: 3px;" 
                                 v-if="nodeListInfo && nodeListInfo.nodeList">
                Found {{ nodeListInfo.nodeList.length }} result<span v-if="nodeListInfo.nodeList.length !== 1">s</span>
            </div>
        </v-card>
    </div>
</template>

<script>

import{ conf } from '../utils/confTruePLM';
import SearchTable from './SearchTable.vue';

export default {
  name: 'SearchNodeListT',
  props: {
      nodeListInfo: null
  },
  data () {
    return {
        sortDec: false,
        pageData: [],
        headerInfo: [ { text: 'Name', value: 'name', sorter: 'string' },
                    { text: 'Type', value: 'element_type', sorter: 'string', clear: true },
                    { text: 'Description', value: 'description', sorter: 'string' },
                    { text: 'Path', value: 'path', sorter: 'string' },
                    { text: 'Created by', value: 'created_by_user', sorter: 'string' },
                    { text: 'Created date', value: 'date_created', sorter: 'datetime', hozAlign: 'right'  },
                    { text: 'Last modified by', value: 'last_modified_by_user', sorter: 'string' },
                    { text: 'Last modified date', value: 'date_last_modified', sorter: 'datetime', hozAlign: 'right' },
                    { text: 'Phase', value: 'phase', clear: true, sorter: 'string' },
                    { text: 'Version', value: 'version_id', sorter: 'number', hozAlign: 'right' }],
        items: [],
        tabulator: null,
        headers: [],
        rowMenu: [ { txt: this.$t( 'message.menu_file_goto' ), cmd: 'gotoNode' }, 
                   { txt: this.$t( 'message.menu_copy' ), cmd: 'copyNode' } ],
        exportXLSX: false,
        exportJSON: false,
        exportCSV: false
    };
  },
  components: {
      'search-table': SearchTable
  },
  computed:
  {
      wHi()
      {
             return "calc( 100vh - 200px )";
      }    
  },
  methods: 
  {
      procFunc( cmd, data, row )
      {
          //this.selectRow( row );
          this.$eventBus.$emit( 'procRowMenuCmd', [ cmd, data ] );   
      }, 
      selectRow( row )
      {
          let data = row.getData();
          this.$globalStore.setSelectedID( data.item_id );
          data.fullData.isSearch = true;
          this.$eventBus.$emit( 'updateNode', data.fullData );   
      },
      rowMenuClick( info )
      {
          if( info.cmd === 'copyNode' )
              this.procFunc( 'copyNode', info.row.getData().fullData, info.row );
          else if( info.cmd === 'gotoNode' )
              this.procFunc( 'gotoNode', info.row.getData().pathData, info.row );
      },
      getProp( el, name )
      {
          
      }
  },
  watch: {
      nodeListInfo() 
      {
          let nodeList = this.nodeListInfo.nodeList || [];
          let propList = this.nodeListInfo.propList || [];
          this.items = [];
          let hInfo = this.headerInfo.slice();
          this.propCols = [];
          if( propList.length > 0 )
          {
              for( let pr of propList )
              {
                  let clName = pr;
                  let col = { text: this.$utils.clearType( clName ) + ' prop.', value: clName, isProp: true };
                  col.sorter = 'string';
                  hInfo.push( col );
              }
          }
          this.headers = hInfo;
          for( let el of nodeList )
          {
              let path = '';
              for( let i = 0; el.path && i < el.path.length - 1; i++ )
                path += el.path[ i ] + ' / ';
            
              let row = {};
              row.pathData = el.tree_path_to_element;
              
              el = el.bkdn_elem_info;
              el.path = path;
              
              row.item_id = el.instance_id;
              row.fullData = el;
              for( let h of this.headers )
              {
                 let val = el[ h.value ];
                 if( h.isProp )
                 {
                     let prop = null;
                     for( let i = 0; i < el.properties.length; i++ )
                     {
                          if( el.properties[ i ].name === h.value )
                          {
                              prop = el.properties[ i ];
                              break;
                          }
                     }
                     if( prop !== null )
                         val = prop.val;
                 }
                 
                 if( val )
                 {
                    if( h.clear )
                        val = this.$utils.clearType( val );
                    
                    if( h.sorter === 'datetime' )
                    {
                        row[ h.value + '_dVal' ] = Date.parse( val );
                        val = this.$utils.getLocalDateFromUTC( val );
                    }
                 }
                 row[ h.value ] = val;
              }
              
              this.items.push( row );
          }
      }
  },
  mounted: function() 
  {  
      let self = this;

      self.$eventBus.$on( 'exportNodeSearchXLSX', function()
      {
           self.exportXLSX = !self.exportXLSX;
      });
      self.$eventBus.$on( 'exportNodeSearchCSV', function()
      {
           self.exportCSV = !self.exportCSV;
      });
      self.$eventBus.$on( 'exportNodeSearchJSON', function()
      {
           self.exportJSON = !self.exportJSON;
      });
  },   
  beforeDestroy: function()
  {
      this.$eventBus.$off( 'exportNodeSearchXLSX' );
      this.$eventBus.$off( 'exportNodeSearchCSV' );
      this.$eventBus.$off( 'exportNodeSearchJSON' );
  }
}
</script>

<style scoped>
  .scroll 
  {
      overflow-y: auto;
  }
  .normalSizeWithPage
  {
        height: calc(100vh - 251px);
  }
  .normalSize
  {
        height: calc(100vh - 181px);
  }
  .propTbl td
  {
        height: 30px;
  }        

  .propTbl div table.v-table thead tr
  {
      height: 30px;
  }  
</style>
