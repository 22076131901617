<template>
    <v-menu ref="menu" :close-on-content-click="false" v-model="menu" :nudge-right="40"
                                        transition="scale-transition" offset-y max-width="290px" min-width="290px">
        <template v-slot:activator="{ on }">
            <v-text-field v-on="on" v-model="dateFormatted" :label="label_txt" hint="YYYY-MM-DD format" 
                      persistent-hint prepend-icon="event" readonly clearable @click:clear="clear()" style="padding-bottom: 10px;">
            </v-text-field>
        </template>
        <v-date-picker v-model="date" no-title @input="menu = false"></v-date-picker>
    </v-menu>
</template>

<script>

export default {
  name: 'DateInput',
  props: {
      model: null,
      label_txt: ''
  },
  data () {
    return {
        date: null,
        dateFormatted: this.formatDate( this.model ),
        menu: false
    };
  },
  computed: {
      computedDateFormatted() 
      {
          return this.formatDate( this.date );
      }
  },
  watch: {
      date( val ) 
      {
          this.dateFormatted = this.formatDate( this.date );
      },
      model( val ) 
      {
          this.procModel();
      }
  },
  methods: 
  {
      procModel()
      {
       //   console.log( this.model );
          if( this.model )
              this.date = this.model.split( ' ' )[ 0 ];
          else
              this.date = null;
      },
      setVals( val )
      {
          this.date = val;
      },
      clear()
      {
          this.dateFormatted = this.date = null;          
      },
      formatDate( dt ) 
      {
        if( !dt ) 
            return null;

        const [year, month, day] = dt.split('-');
        return `${year}-${month}-${day}`;
      },
      parseDate( dt ) 
      {
        if( !dt ) 
            return null;

        const [month, day, year] = dt.split('-');
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
      }
  },
  components: {
  },
  mounted: function() 
  { 
      this.procModel();
  },   
  beforeDestroy: function()
  {
  }
}
</script>

<style scoped>
   
</style>
