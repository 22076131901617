<template>
    
            <v-dialog v-model="visFl" persistent max-width="1000">
                <v-toolbar flat color="white">
                    <v-toolbar-title>{{ $t( 'message.token_list_title' ) }}</v-toolbar-title>
                </v-toolbar>
               <v-card>
                <v-card-text>
                    <v-data-table dense :headers="headers" :items="tokenData" hide-default-footer class="elevation-1 propTbl" 
                                  :items-per-page=-1 fixed-header>
                        <template v-slot:header.descr="{ header }">
                            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
                        </template>
                         <template v-slot:header.createMoment="{ header }">
                            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
                        </template>
                        <template v-slot:header.act="{ header }">
                            <v-tooltip bottom v-if="!noAdd">
                                <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" @click="addToken()">add</v-icon>
                                </template>
                                <span>{{ $t( 'message.token_add_btn' ) }}</span>
                            </v-tooltip>
                        </template>
                        <template v-slot:item="{ item }">
                            <tr>
                              <td>{{ item.descr }}</td>
                              <td>{{ getDateStr( item.createMoment ) }}</td>
                              <td>
                                  <span style="white-space: nowrap">
                                      <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-icon v-on="on" @click="deleteToken( item )">delete_forever</v-icon>
                                            </template>
                                            <span>
                                                {{$t( 'message.token_delete' )}}
                                            </span>
                                      </v-tooltip>
                                  </span>
                              </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click.native="close()">{{ $t( 'message.close_btn' ) }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>   
    
</template>

<script>
    
import Vue from 'vue'
import{ conf } from '../../utils/confTruePLM';

export default {
  name: 'tokenList',
  data () 
  {
    return {
        globalState: this.$globalStore.state,
        visFl: false,
        headers: [
            { text: 'message.token_descr_col', value: 'descr' },
            { text: 'message.token_date_col', value: 'createMoment', width: '20%' },
            { text: 'message.bsl_act_col', value: 'act', sortable: false, width: '10%' }
        ],
        tokenData : [],
        noAdd: false,
        resolve: null,
        reject: null
    };
  },
  computed: {
  },
  methods: {
      getDateStr( dt )
      {
          dt = new Date( dt );
          return dt.toLocaleString();
      },
      addToken()
      {
          let self = this;
          this.$modalDlg.sysDlgs.confirmOneValDlg.open( 'add_token_title', 'add_token_msg' )
                  .then( ( descr ) =>
          {
              return conf.addToken( descr );
          }, () => { return Promise.resolve(); } )
                  .then( ( rez ) => 
          {
              if( rez )
                this.$modalDlg.sysDlgs.confirmTokenDlg.open( rez.result );
            
              self.updateList();
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });    
      },
      deleteToken( token )
      {
          let self = this;
          this.$modalDlg.sysDlgs.confirmDlg.open( 'token_remove_title', 'token_remove_msg' )
                  .then( () =>
          {
              return conf.deleteToken( token.id );
          }, () => {} )
                  .then( () =>
          {
              self.updateList();
          })
                  .catch( ( err ) =>
          {
              this.$eventBus.$emit( 'queryError', err );
          });
      },
      open( noAdd )
      {
          let self = this;
          self.tokenData = [];
          self.noAdd = noAdd;
          self.visFl = true;
          
          this.updateList();
          
          return new Promise( ( resolve, reject ) => 
          {
              self.resolve = resolve;
              self.reject = reject;
          });          
      },
      updateList()
      {
          let self = this;
          let tList = [];
          conf.getTokenList()
                  .then( ( data ) => 
          {
              for( let i = 0; data && i < data.length; i++ )
                  tList.push( data[ i ] ); 
              
              self.tokenData = tList;
          })
                .catch( ( err ) =>
          {
              self.tokenData = [];
              this.$eventBus.$emit( 'queryError', err );
          });
      },
      close()
      {
          this.visFl = false;
          this.resolve();
      }
    }
}
</script>

<style scoped>
    .propTbl td
    {
        height: 30px;
    }        
</style>



