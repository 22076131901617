<template>
        <v-dialog v-model="visFl" persistent max-width="290">
           <v-card>
            <v-card-title class="headline">
              {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${title}`, { val: titleData } ) } ) }}
            </v-card-title>
            <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-autocomplete :label="$t( 'message.put_prod_prod' )" required v-model="elem"
                        :rules="[v => !!v || $t( 'message.value_required' )]" :auto-select-first="true"
                        :items="elems" :search-input.sync="search" item-text="bkdn_elem_info.name" return-object>
                    </v-autocomplete>
                </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
                <v-btn color="green darken-1" text :disabled="!valid" @click.native="doAct()">{{ $t( 'message.ok_btn' ) }}</v-btn>
                <v-btn color="green darken-1" text @click.native="close()">{{ $t( 'message.cancel_btn' ) }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>   
</template>

<script>
    
import Vue from 'vue'
import{ conf } from '@/utils/confTruePLM';

export default {
  name: 'searchOneVal',
  data () 
  {
    return {
        titleVal: 'search_ttl',
        titleData: null,
        visFl: false,
        elem: null,
        info: null,
        resolve: null,
        reject: null,
        valid: true,
        search: null,
        elems: []
    };
  },
  computed: {
      title() 
      {
           return 'message.' + this.titleVal;
      }
  },
  watch: {
      search( val ) 
      {
          if( val && this.elem && val !== this.elem.bkdn_elem_info.name )
              this.fillElems( val );
      }
  },
  methods: {
      fillElems( val )
      {
          if( val )
              val = '*' + val + '*';
          conf.searchInOtherModel( this.info.rep, this.info.model, val )
                  .then( data =>
          {
              this.elems = data;
          });
      },
      open( titleIn, titleData, info )
      {   
          this.titleVal = titleIn || 'search_ttl';
          this.titleData = titleData;
          this.info = info;
          this.fillElems();
          this.elem = null;
          
          this.visFl = true;
          
          return new Promise( ( resolve, reject ) => 
          {
            this.resolve = resolve;
            this.reject = reject;
          });
      },
      close()
      {
          this.visFl = false;
          this.reject();
      },
      doAct()
      {
          if( this.$refs.form.validate() )
          {
                this.visFl = false;
                this.resolve( { elem: this.elem } );
          }
      }
    }
}
</script>



