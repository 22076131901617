<template>

    <v-dialog v-model="visFl" persistent max-width="350">
       <v-card>
        <v-card-title class="headline" v-if="edit">
          {{ $t( 'message.edit_act_title' ) }}
        </v-card-title>
        <v-card-title class="headline" v-else>
          {{ $t( 'message.add_act_title' ) }}
        </v-card-title>
        <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field :label="$t( 'message.add_notify_act_name' )" required v-model="act.name"
                        :rules="[v => !!v || $t( 'message.value_required' )]"></v-text-field>

                <v-select :label="$t( 'message.add_notify_act_type' )" required v-model="act.action_type" item-text="txt" item-value="val" 
                            :items="act_types" :rules="[v => !!v || $t( 'message.value_required' )]" :disabled="edit"></v-select>

                <v-select :label="$t( 'message.add_notify_act_users' )" v-model="act.users" item-text="txt" item-value="val" 
                            :items="users" multiple></v-select>
                <v-select :label="$t( 'message.add_notify_act_roles' )" v-model="act.roles" item-text="txt" item-value="val" 
                            :items="roles" multiple></v-select>
                <v-select :label="$t( 'message.add_notify_act_subdomain' )" v-model="act.subdomain_id" item-text="txt" item-value="val" 
                            :items="subdomains" clearable ></v-select>

                <v-text-field :label="$t( 'message.add_notify_act_subj' )" v-model="act.subj"></v-text-field>

                <v-textarea :label="$t( 'message.add_notify_act_msg' )" required v-model="act.message" 
                            :rules="[v => !!v || $t( 'message.value_required' )]"></v-textarea>
            </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn color="green darken-1" text :disabled="!valid" @click.native="doAct()">{{ $t( 'message.ok_btn' ) }}</v-btn>
            <v-btn color="green darken-1" text @click.native="close()">{{ $t( 'message.cancel_btn' ) }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>   
    
</template>

<script>
    
import Vue from 'vue'

import{ conf } from '@/utils/confTruePLM';

export default {
  name: 'addAction',
  data () 
  {
    return {
        visFl: false,
        
        roles: [],
        users: [],
        subdomains: [],
        act: {},
        act_types: [ { txt: 'Notification', val: 'NOTIFY'}, { txt: 'EMail', val: 'SEND_EMAIL' } ],
        edit: false,
        
        resolve: null,
        reject: null,
        valid: true
    };
  },
  computed: {
      
  },
  components: {
  },
  methods: {
     
      open( inAct )
      {
          this.act = inAct || {};
          this.edit = !!this.act.id;
          
          let self = this;

          this.roles = [];
          conf.getChildTypes( 'urn:rdl:epm-std:project_role' )
                  .then( ( data ) =>
          {
              self.roles = self.$utils.getEnum( data );
          });

          this.users = [];
          conf.getProjectUsersEnum()
                  .then( ( data ) =>
          {
              self.users = data;
          });
          
          this.subdomains = [];
          conf.subdomainList()
                  .then( data => 
          {
              data.forEach( s => 
              {
                  self.subdomains.push( { txt: s.root_bkdn_elem.name, val: s.root_bkdn_elem.instance_id } );
              });
          });
          
          this.title = '';
          
          this.visFl = true;
          
          return new Promise( ( resolve, reject ) => 
          {
            this.resolve = resolve;
            this.reject = reject;
          });
      },
      close()
      {
          this.visFl = false;
          this.reject();
      },
      doAct()
      {
          if( this.$refs.form.validate() )
          {
                this.visFl = false;
                this.resolve( this.act );
          }
      }
    }
}
</script>





