<template>
        <v-dialog v-model="visFl" scrollable max-width="290">
           <v-card>
            <v-card-title class="headline" v-if="edit">
              {{ $t( 'message.edit_node_title' ) }}
            </v-card-title>
            <v-card-title class="headline" v-else>
              {{ $t( 'message.create_node_title' ) }}
            </v-card-title>
            <v-card-text id="createNodeCard">
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-text-field id="node_name" :label="$t( 'message.create_node_name' )" required v-model="name" :disabled="edit"
                        :rules="[v => !!v || $t( 'message.value_required' )]"></v-text-field>
                    <v-textarea id="node_descr" :label="$t( 'message.create_node_descr' )" required v-model="descr" 
                        :rules="[v => !!v || $t( 'message.value_required' )]"></v-textarea>
                    <v-select id="node_type" :label="$t( 'message.create_node_type' )" required v-model="selectedType" 
                              v-if="!is_root" :rules="[v => !!v || $t( 'message.value_required' )]" no-data-text=""
                              :menu-props="{ closeOnContentClick: true }" hide-selected :items="typesList" @focus="selectFocus"
                              :disabled="is_req || !has_parent || for_prod_list" item-text="name" item-value="urn">
                        <template v-slot:prepend-item>
                            <v-treeview :items="types" style="font-size: 14px;" :active="treeActive"
                              activatable item-key="urn" dense transition @update:active="selectType">
                            </v-treeview>
                        </template>
                    </v-select>
                    <v-select id="note_tmpl" :label="$t( 'message.create_node_tmpl' )" item-text="txt" item-value="val" 
                              v-model="selectedTmpl" v-if="!edit && !for_prod_list" clearable :items="tmpls">
                    </v-select>
                    
                </v-form>
                <!--<small>*{{ $t( 'message.req_info_msg' ) }}</small>-->
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
                <v-btn id="createNodeOk" color="green darken-1" text :disabled="!valid" @click.native="doAct()">{{ $t( 'message.ok_btn' ) }}</v-btn>
                <v-btn id="createNodeClose" color="green darken-1" text @click.native="close()">{{ $t( 'message.cancel_btn' ) }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>   
</template>

<script>
    
import Vue from 'vue'
import{ conf } from '@/utils/confTruePLM';

export default {
  name: 'createNode',
  data () 
  {
    return {
        visFl: false,
        name: '',
        descr: '',
        typesList: [],
        types: [],
        tmpls: [],
        selectedType: '',
        selectedTmpl: null,
        resolve: null,
        reject: null,
        oneVal: null,
        valid: true,
        edit: false,
        is_root: false,
        is_req: false,
        has_parent: true,
        for_prod_list: false,
        treeActive: []
    };
  },
  computed: {
      title() 
      {
           return 'message.' + this.titleVal;
      },
      msg() 
      {
           return 'message.' + this.msgVal;
      }
  },
  methods: {
      selectFocus( el1, el2, el3, el4 )
      {
  //        console.log( el1, el2, el3, el4 );
          
      },
      selectType( elems )
      {
          this.typesList = [ { urn: elems[ 0 ], name: this.$utils.clearType( elems[ 0 ] ) } ];
          this.selectedType = elems[ 0 ];
      },
      open( info, isRoot, forProdList, prnNode )
      {
          if( info )
              prnNode = info.parent;
          
          let self = this;
          this.types = [];
          this.treeActive = [];
          this.selectedType = null;
          this.typesList = [];
          conf.getTypesTree( 'urn:rdl:epm-std:breakdown_element_type' )
                  .then( data =>
          {
              self.types = data;
              if( info )
                  self.treeActive = [ info.element_type ];
              self.visFl = true;
              this.$nextTick( () => { this.$utils.scrollTop( 'createNodeCard' ); } );
          })
                .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.$eventBus.$emit( 'queryError', err );
          });

//          conf.getChildTypesInContext( 'urn:rdl:epm-std:breakdown_element_type', prnNode ? prnNode.element_type : '' )
//                  .then( ( data ) =>
//          {
//              data.forEach( ( item ) =>
//              {
//                   this.types.push( { txt: this.$utils.clearType( item ), val: item } );
//              });
//              this.$utils.sortArray( this.types, ( a ) => ( a.txt ) );
//              
//              if( this.types.length > 0 )
//              {
//                  if( this.types.length < 2 )
//                      this.selectedType = this.types[ 0 ].val;
//                  this.visFl = true;
//              }
//              else
//              {
//                  if( info )
//                  {
//                      this.types.push( { txt: this.$utils.clearType( info.element_type ), val: info.element_type } );
//                      this.visFl = true;
//                  }
//                  else
//                  {
//                      this.$modalDlg.sysDlgs.confirmDlg.open( 'no_ch_elems_title', 'no_ch_elems_msg', null, null, true );
//                      this.close();
//                  }
//              }
//          });
         
          this.is_root = isRoot;
          this.for_prod_list = forProdList;
       
          if( info )
          {
              this.name = info.name;
              this.descr = info.description;
              this.selectedType = this.$utils.noGUID( info.element_type );
              
              this.edit = true;
              this.is_req = info.is_requirement;
              this.has_parent = !!info.parent;
          }
          else
          {
              this.name = null;
              this.descr = null;
              this.selectedType = null;
              this.selectedTmpl = null;
              
              this.edit = false;
              this.is_req = false;
              if( !this.for_prod_list )
              {
                    this.tmpls = [];
                    conf.getAllTmpl()
                              .then( ( data ) =>
                      {
                          data.forEach( ( elem ) =>
                          {
                              if( ( !conf.getProj().in_project.is_bkd_template_project 
                                      || conf.getProj().in_project.project_model_id !== elem.in_project.project_model_id )
                                            && elem.in_project.is_bkd_template_project )
                                this.tmpls.push( { val: elem, txt: elem.in_project.name } );
                          });
                      });
              }
          }
          
          if( this.for_prod_list )
          {
              this.types = [ { urn: 'urn:rdl:epm-std:product_list', name: 'Product list' } ];
              this.treeActive = [ this.types[ 0 ].urn ];
          }
          
          if( info || this.types.length > 0 )
          {
                this.visFl = true;
                this.$nextTick( () => { this.$utils.scrollTop( 'addIssueCard' ); } );
          }
          
          return new Promise( ( resolve, reject ) => 
          {
                this.resolve = resolve;
                this.reject = reject;
          });
      },
      close()
      {
          this.visFl = false;
          this.reject();
      },
      doAct()
      {
          if( this.$refs.form.validate() )
          {
            this.visFl = false;
            this.resolve( { name: this.name, descr: this.descr, type: this.selectedType, tmpl: this.selectedTmpl } );
          }
      }
    }
}
</script>
<style scoped>
        
    .v-list-item
    {
        display: none !important;
    }
</style>
    



