<template>
    
            <v-dialog v-model="visFl" persistent max-width="1000">
                <v-toolbar flat color="white">
                    <v-toolbar-title>{{ $t( 'message.hinder_list_title' ) }}</v-toolbar-title>
                </v-toolbar>
               <v-card>
                <v-card-text>
                    <v-data-table dense :headers="headers" :items="items" hide-default-footer class="elevation-1 propTbl" 
                                  :items-per-page=-1 fixed-header>
                        <template v-slot:header.document_title="{ header }">
                            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
                        </template>
                        <template v-slot:header.element_string_path="{ header }">
                            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
                        </template>
                        <template v-slot:header.role="{ header }">
                            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
                        </template>
                        <template v-slot:header.act="{ header }">
                            <span></span>
                        </template>
                        <template v-slot:item="{ item }">
                            <tr>
                              <td>{{ item.document_title }}</td>
                              <td>{{ ( item.element_string_path || [] ).join( ' / ' ) }}</td>
                              <td>{{ item.role }}</td>
                              <td>
                                  <span style="white-space: nowrap">
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-bind="attrs" v-on="on" small @click="gotoFile( item )">visibility</v-icon>
                                        </template>
                                        <span>{{ $t( 'message.hinder_goto_btn' ) }}</span>
                                    </v-tooltip>
                                  </span>
                              </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="green darken-1" text @click.native="doAct()">{{ $t( 'message.hinder_del_btn' ) }}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click.native="close()">{{ $t( 'message.hinder_cancel_btn' ) }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>   
    
</template>

<script>
    
import Vue from 'vue'
import{ conf } from '../../utils/confTruePLM';

export default {
  name: 'nodeDelHinderList',
  data () 
  {
    return {
        globalState: this.$globalStore.state,
        visFl: false,
        headers: [
            { text: 'message.hinder_name_col', value: 'document_title' },
            { text: 'message.hinder_path_col', value: 'element_string_path' },
            { text: 'message.hinder_role_col', value: 'role' },
            { text: 'message.bsl_act_col', value: 'act', sortable: false }
        ],
        items : [],
        resolve: null,
        reject: null
    };
  },
  computed: {
  },
  methods: {
      gotoFile( item )
      {
          this.$eventBus.$emit( 'procRowMenuCmd', [ 'gotoNode', item.element_path ] );            
          this.$globalStore.setSelectedID( item.document_id );  
          this.close();
      },
      
      open( items )
      {
          console.log( items )
          let self = this;
          self.items = items || [];
          self.visFl = true;
          
          return new Promise( ( resolve, reject ) => 
          {
              self.resolve = resolve;
              self.reject = reject;
          });          
      },
      doAct()
      {
          this.visFl = false;
          this.resolve();
      },
      close()
      {
          this.visFl = false;
          this.reject();
      }
    }
}
</script>

<style scoped>
    .propTbl td
    {
        height: 30px;
    }        
</style>



