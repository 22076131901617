<template>
    <v-card style="height: calc( 100vh - 181px ); overflow-y: auto;">
            <v-treeview activatable :items="items" :load-children="getChildren" open-on-click transition dense
                        item-key="instance_id" >
                <template v-slot:label="{ item }">
                    <div style="display: flex; flex-direction: row" @contextmenu.prevent="cMenuOpen( $event, item )">
                        <img :src="imgSrc( item )" width="20" height="16" style="padding-right: 5px;"></img>
                        {{ item.name }}
                    </div> 
                </template>
            </v-treeview>
    </v-card>
</template>

<script>
    
import TreeNode from './Node.vue'
import{ conf } from '../../utils/confTruePLM';
    
export default {
  name: 'TreeRootWithLoad',
  props:{
      type: null,
      showFiles: false,
      hideProps: false,
      path: null,
      fixed: false
  },
  data () 
  {
    return {
        model: {},
        items: [],
        root: null
    };
  },
  methods: {
      cMenuOpen( e, item )
      {
          this.$eventBus.$emit( 'openTreeMenu', { event: e, item: item } );
      },
      imgSrc( item )
      { 
          return this.$utils.getImage( item.type );
      },
      showBOM( path )
      {
          this.model = {};
          
          if( !path )
              return null;
          
          this.root = path[ 1 ];
          this.openTree( path[ path.length - 1 ] );
      },
      getChildren( node )
      {
         let self = this;
         return conf.getTreeNodeChildren( node, this.hideProps ? this.root.version_id : '', 2, 1, 199 )
                  .then( ( data ) =>
          {
              if( data.element.children_count > 0 )
              {
                  data.element.children.forEach( ch =>
                  {
                      node.children.push( { name: ch.name, instance_id: ch.instance_id, 
                          children: ch.children_count > 0 ? [] : null, type: ch.element_type } );
                  });
              }
              if( data.element.children_count - 200 > 0 )
                self.getMoreChildren( node, 201 );
          })
                .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      getMoreChildren( node, pos )
      {
         let self = this;
         conf.getTreeNodeChildren( node, this.hideProps ? this.root.version_id : '', 2, pos, 199 )
                  .then( ( data ) =>
          {
              if( data.element.children && data.element.children.length > 0 )
              {
                  data.element.children.forEach( ch =>
                  {
                      node.children.push( { name: ch.name, instance_id: ch.instance_id, 
                          children: ch.children_count > 0 ? [] : null, type: ch.element_type } );
                  });
              }
              pos += 200;
              if( data.element.children_count - pos > 0 )
                self.getMoreChildren( node, pos );
          })
                .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      openTree( node )
      {
          if( !this.root || node.isProj )
              return;
          
          this.items = [];
          let self = this;
          conf.getTreeNodeChildren( node, this.hideProps ? this.root.version_id : '', 2 )
                  .then( ( data ) =>
          {
              let elem = { name: data.element.name, instance_id: data.element.instance_id, 
                                            children: null, type: data.element.element_type };
              if( data.element.children_count > 0 )
              {
                  elem.children = [];
                  data.element.children.forEach( ch =>
                  {
                      elem.children.push( { name: ch.name, instance_id: ch.instance_id, 
                          children: ch.children_count > 0 ? [] : null, type: ch.element_type } );
                  });
              }
              self.items.push( elem );
          })
                .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      openFromRoot( info )
      {
          var self = this;
          if( info.base )
          {
            conf.getBaselineList()
                  .then( ( data ) => 
            {
                for( let i = 0; i < data.length; i++ )
                {
                    if( data[ i ].id === info.base )
                    {
                        self.openTree( data[ i ].root_bkdn_elem, { version_id:  data[ i ].in_bkdn_version } );
                        self.$eventBus.$emit( 'openedTreeFromRoot', [ { type: self.type, root: data[ i ].root_bkdn_elem } ] );   
                        break;
                    }
                }
            })
                .catch( ( err ) =>
            {
                  self.$eventBus.$emit( 'queryError', err );
            });
          }
          else if( info.ver )
          {
            conf.getPBS( info.ver ).then( ( data )=>
            {
                data = data.root_bkdn_elem;
                self.openTree( data, data );
                self.$eventBus.$emit( 'openedTreeFromRoot', [ { type: self.type, root: data } ] );   
            })
                .catch( ( err ) =>
            {
                  self.$eventBus.$emit( 'queryError', err );
            });
          }
      }
    },
  watch: {
      path( val ) 
      {
          if( !this.fixed )
              this.showBOM( this.path );
      }
  },
  components: {
    'tree-node-view': TreeNode    
  },
  mounted: function()
  {
      var self = this;
//      self.$eventBus.$on( 'showBOMFromPath', function( path )
//      {
          self.showBOM( this.path );
//      });
      self.$eventBus.$on( 'openTreeFromRoot', function( info )
      {
          if( info[ 0 ].type === self.type )
            self.openFromRoot( info[ 0 ] );
      });
  },
  beforeDestroy: function()
  {
//      this.$eventBus.$off( 'showBOMFromPath' );
      this.$eventBus.$off( 'openTreeFromRoot' );
  }
}
</script>

<style scoped>
    .v-treeview
    {
       font-size: 14px; 
    }
</style>
