<template>
    
            <v-dialog v-model="visFl" persistent max-width="1100">
                <v-toolbar flat color="white">
                    <v-toolbar-title>{{ $t( 'message.node_hist_title' ) }}</v-toolbar-title>
                </v-toolbar>
               <v-card>
                <v-card-text>
                    <v-data-table :headers="headers" :items="histData" hide-default-footer class="elevation-1 propTbl" 
                                  :items-per-page=-1 fixed-header :height=280>
                        <template v-slot:header.timestamp="{ header }">
                            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
                        </template>
                        <template v-slot:header.operation="{ header }">
                            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
                        </template>
                        <template v-slot:header.user="{ header }">
                            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
                        </template>
                        <template v-slot:header.breakdown_version="{ header }">
                            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
                        </template>
                        <template v-slot:header.change_trace="{ header }">
                            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
                        </template>
                        <template v-slot:header.user_remark="{ header }">
                            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
                        </template>
                        <template v-slot:item="{ item }">
                            <tr>
                                  <td>{{ item.timestamp }}</td>
                                  <!--<td>{{ item.operation }}</td>-->
                                  <td>{{ item.user }}</td>
                                  <td>{{ item.breakdown_version }}</td>
                                  <td>{{ item.change_trace }}</td>
                                  <td>{{ item.user_remark }}</td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click.native="close()">{{ $t( 'message.close_btn' ) }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>   
    
</template>

<script>
    
import Vue from 'vue'
import{ conf } from '../../utils/confTruePLM'

export default {
  name: 'nodeHistory',
  data () 
  {
    return {
        visFl: false,
        headers: [
            { text: 'message.node_hist_time', value: 'timestamp', width: 170 },
//            { text: 'message.node_hist_operation', value: 'operation', width: 120 },
            { text: 'message.node_hist_user', value: 'user', width: 100 },
            { text: 'message.node_hist_ver', value: 'breakdown_version', width: 100 },
            { text: 'message.node_hist_trace', value: 'change_trace' },
            { text: 'message.node_hist_remark', value: 'user_remark', width: 100 },
        ],
        histData : [],
        node: {},
        resolve: null,
        reject: null
    };
  },
  computed: {
  },
  methods: {
      
      open( node )
      {
          this.node = node;
          this.histData = [];
          
          conf.getNodeChangeLog( node.instance_id )
                  .then( data => 
          {
              this.histData = data;
          })
                .catch( ( err ) =>
          {
              this.$eventBus.$emit( 'queryError', err );
          });
          
          this.visFl = true;
          
          var self = this;                    
          return new Promise( ( resolve, reject ) => 
          {
              self.resolve = resolve;
              self.reject = reject;
          });          
      },
      close()
      {
          this.visFl = false;
          this.file = null;
          this.node = {};
          this.resolve();
      }
    }
}
</script>

<style scoped>
    .propTbl td
    {
        height: 30px;
    }        
</style>



