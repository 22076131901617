<template>
    
            <v-dialog v-model="visFl" persistent max-width="1100">
                <v-toolbar flat color="white">
                    <v-toolbar-title >{{ $t( 'message.orgs_list_title' ) }}</v-toolbar-title>
               </v-toolbar>
               <v-card>
                <v-card-text>
                    <v-data-table dense :headers="headers" :items="orgsData" hide-default-footer class="elevation-1 propTbl" 
                                  :items-per-page=-1 fixed-header :height=270>
                        <template v-slot:header.organization.title="{ header }">
                            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
                        </template>
                        <template v-slot:header.role="{ header }">
                            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
                        </template>
                        <template v-slot:header.organization.description="{ header }">
                            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
                        </template>
                        <template v-slot:header.organization.registered="{ header }">
                            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
                        </template>
                        <template v-slot:header.organization.roles="{ header }">
                            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
                        </template>
                        <template v-slot:header.state="{ header }">
                            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
                        </template>
                        <template v-slot:header.act="{ header }">
                            <span></span>
                        </template>
                        <template v-slot:item="{ item }">
                            <tr>
                              <td>{{ item.organization.title }}</td>
                              <td>{{ $utils.clearType( item.role ) }}</td>
                              <td>{{ item.organization.description }}</td>
                              <td>{{ item.organization.registered }}</td>
                              <td>{{ getRoles( item.organization ) }}</td>
                              <td>{{ item.organization.invalid_from ? ' invalid from ' + item.organization.invalid_from : '' }}
                              </td>
                              <td>
                                  <span style="white-space: nowrap">
                                      <v-tooltip bottom v-if="!globalState.actVer">
                                            <template v-slot:activator="{ on }">
                                                <v-icon v-on="on" @click="deleteOrg( item )">delete_forever</v-icon>
                                            </template>
                                            <span>
                                                {{$t( 'message.orgL_delete_btn' )}}
                                            </span>
                                      </v-tooltip>
                                  </span>
                              </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click.native="close()">{{ $t( 'message.close_btn' ) }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>   
    
</template>

<script>
    
import Vue from 'vue'
import{ conf } from '../../utils/confTruePLM';

export default {
  name: 'relsList',
  data () 
  {
    return {
        globalState: this.$globalStore.state,
        visFl: false,
        headers: [
            { text: 'message.orgL_name_col', value: 'organization.title' },
            { text: 'message.orgL_type_col', value: 'role' },
            { text: 'message.orgL_descr_col', value: 'organization.description', width: '15%' },
            { text: 'message.orgL_create_col', value: 'organization.registered' },
            { text: 'message.orgL_class_col', value: 'organization.roles' },
            { text: 'message.orgL_state_col', value: 'state', width: '10%' },
            { text: 'message.orgL_act_col', value: 'act', sortable: false, width: '5%' }
        ],
        orgsData : [],
        node: null,
        resolve: null,
        reject: null
    };
  },
  computed: {
  },
  methods: {
      
      deleteOrg( org )
      {
          var self = this;
          this.$modalDlg.sysDlgs.confirmDlg.open( 'orgL_remove_title', 'orgL_remove_msg', null, org.organization.title )
                  .then( () =>
          {
              return conf.deleteNodeOrg( this.node, org, org.role );
          }, () => {} )
                  .then( () =>
          {
              return self.getData();
          })
                  .catch( ( err ) =>
          {
              this.$eventBus.$emit( 'queryError', err );
          });
      },
      getRoles( org )
      {
          let val = org.roles;
          if( !val )
              return '';
          
          let parts = val.split( ',' );
          val = '';
          parts.forEach( ( v ) => 
          {
              if( val !== '' )
                  val += ', ';
              val += this.$utils.clearType( v );
          });
          return val;
      },
      getData()
      {
          this.orgsData = [];
          let self = this;
          conf.getNodeOrgs( this.node, this.$globalStore.state.actVer )
                  .then( ( data ) =>
          {
              self.orgsData = data;
          });
      },
      open( node )
      {
          this.node = node;
          if( node.orgs )
            this.orgsData = node.orgs;
          else
            this.getData();
     
          let self = this;
          self.visFl = true;
          
          return new Promise( ( resolve, reject ) => 
          {
              self.resolve = resolve;
              self.reject = reject;
          });          
      },
      close()
      {
          this.visFl = false;
          this.resolve();
      }
    }
}
</script>

<style scoped>
    .propTbl td
    {
        height: 30px;
    }        
</style>



