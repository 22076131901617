<template>

    <v-dialog v-model="visFl" persistent max-width="350">
       <v-card>
        <v-card-title class="headline" v-if="edit">
          {{ $t( 'message.edit_trg_title' ) }}
        </v-card-title>
        <v-card-title class="headline" v-else>
          {{ $t( 'message.add_trg_title' ) }}
        </v-card-title>
        <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field :label="$t( 'message.add_notify_name' )" required v-model="info.name"
                        :rules="[v => !!v || $t( 'message.value_required' )]"></v-text-field>

                <v-select :label="$t( 'message.add_notify_events' )" required v-model="info.events" item-text="txt" 
                          item-value="val" item-disabled="abstr" :items="events" 
                          :rules="[v => ( !!v && v.length > 0 ) || $t( 'message.value_required' )]" multiple></v-select>

                <v-textarea :label="$t( 'message.add_notify_cond' )" required v-model="info.condition"
                        :rules="[v => !!v || $t( 'message.value_required' )]"></v-textarea>

                <v-select :label="$t( 'message.add_notify_act' )" required v-model="info.act" item-text="txt" item-value="val" 
                     :items="acts" :rules="[v => !!v || $t( 'message.value_required' )]"></v-select>

                <v-checkbox :label="$t( 'message.add_notify_active' )" v-model="info.active"></v-checkbox>
            </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn color="green darken-1" text :disabled="!valid" @click.native="doAct()">{{ $t( 'message.ok_btn' ) }}</v-btn>
            <v-btn color="green darken-1" text @click.native="close()">{{ $t( 'message.cancel_btn' ) }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>   
    
</template>

<script>
    
import Vue from 'vue'

import{ conf } from '@/utils/confTruePLM';

export default {
  name: 'addTrigger',
  data () 
  {
    return {
        visFl: false,
        
        events: [],
        info: {},
        acts: [],
        edit: false,
        
        resolve: null,
        reject: null,
        valid: true,
        active: null
    };
  },
  computed: {
      
  },
  components: {
  },
  methods: {
      open( inTrg )
      {
          this.info = inTrg || { active: true, condition: 'TRUE' };
          this.edit = !!this.info.id;
          
          let self = this;
          
          this.acts = [];
          conf.listActions()
                .then( ( data ) =>
          {
              self.$utils.sortArray( data, ( p ) => ( p.name || '' ) );
              data.forEach( ( item ) =>
              {
                   self.acts.push( { val: item.name, txt: item.name } );    
              });
          })
                .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.$eventBus.$emit( 'queryError', err );
          });
          
          this.events = [];
          Promise.all( [ conf.getChildTypesAbst( 'urn:plcs:rdl:TruePLM:System_event' ), 
                conf.getChildTypesAbst( 'urn:plcs:rdl:TruePLM:User_defined_event' ) ] )
                  .then( ( data ) =>
          {
            console.log( data );
              this.$utils.sortArray( data[ 0 ], ( a ) => ( this.$utils.clearType( a.name ) ) );
              data[ 1 ].forEach( ( val ) =>
              {
                  self.events.push( { val: val.name, txt: this.$utils.clearType( val.name ), abstr: val.abstr } );                  
              });
              data[ 0 ].forEach( ( val ) =>
              {
                  self.events.push( { val: val.name, txt: this.$utils.clearType( val.name ), abstr: val.abstr } );                  
              });
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });

          this.visFl = true;
          
          return new Promise( ( resolve, reject ) => 
          {
            this.resolve = resolve;
            this.reject = reject;
          });
      },
      close()
      {
          this.visFl = false;
          this.reject();
      },
      doAct()
      {
          if( this.$refs.form.validate() )
          {
                this.visFl = false;
                this.resolve( this.info );
          }
      }
    }
}
</script>





