<template>
            <v-dialog v-model="visFl" persistent max-width="290">
               <v-card>
                <v-card-title class="headline">
                    <span v-if="isNew">{{ $t( 'message.create_sync_title' ) }}</span>
                    <span v-else>{{ $t( 'message.edit_sync_title' ) }}</span>
                </v-card-title>
                <v-card-text>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-text-field :label="$t( 'message.create_sync_name' )" required v-model="name" id="ename"        
                            :rules="[v => !!v || $t( 'message.value_required' )]"></v-text-field>
                        <v-text-field :label="$t( 'message.create_sync_adr' )" v-model="adr"
                            required :rules="[v => !!v || $t( 'message.value_required' )]"></v-text-field>
                        <v-select :label="$t( 'message.create_sync_proj' )" v-model="proj" item-text="txt" 
                            item-value="val" :items="items" required 
                            :rules="[v => !!v || $t( 'message.value_required' )]"></v-select>
                    </v-form>
                    <!--<small>*{{ $t( 'message.req_info_msg' ) }}</small>-->
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text :disabled="!valid" @click.native="doAct()">{{ $t( 'message.ok_btn' ) }}</v-btn>
                    <v-btn color="green darken-1" text @click.native="close()">{{ $t( 'message.cancel_btn' ) }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>   
</template>

<script>
    
import Vue from 'vue'
import{ conf } from '@/utils/confTruePLM';

export default {
  name: 'createSync',
  data () 
  {
    return {
        visFl: false,
        isNew: false,
        name: '',
        adr: '',
        proj: '',
        resolve: null,
        reject: null,
        valid: true,
        items: []
    };
  },
  computed: {
  },
  methods: {
      open( info )
      {
          this.isNew = !info;
          if( this.isNew )
          {
              this.name = '';
              this.adr = null;
              this.proj = null;
          }
          else
          {
              this.name = info.name;
              this.adr = info.adr;
              this.proj = info.proj;
          }
          
          let self = this;
          conf.getAllProj()
                  .then( ( data ) =>
          {
        //     console.log( data );
              if( !data )
                  return;
              
              data.forEach( ( elem ) =>
              {
                  if( !elem.is_bkd_template_project && !elem.is_template_project && !elem.is_deleted )
                      self.items.push( { txt: elem.name, val: elem.project_model_id } );
              });     
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
          
          this.visFl = true;
          
          return new Promise( ( resolve, reject ) => 
          {
            this.resolve = resolve;
            this.reject = reject;
          });
      },
      close()
      {
          this.visFl = false;
          this.reject();
      },
      doAct()
      {
          if( this.$refs.form.validate() )
          {
            this.visFl = false;
            this.resolve( { name: this.name, adr: this.adr, proj: this.proj } );
          }
      }
    }
}
</script>



