<template>
    <v-card>
      <v-data-table :headers="heads" :items="propVals" hide-default-footer class="elevation-1 propTbl" 
                    :items-per-page=-1 fixed-header height="calc( ( 100vh - 181px )">
        <template v-slot:header.name="{ header }">
            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
        </template>
        <template v-slot:header.act="{ header }">
            <v-tooltip bottom v-if="prop.id">
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on" @click="addItem()">add</v-icon>
                </template>
                <span>{{ $t( 'message.prop_add_btn' ) }}</span>
            </v-tooltip>
        </template>
        <template v-slot:item="{ item }">
            <tr>
                  <td>{{ item.val_view }}</td>
                  <td>
                      <v-icon v-if="!item.in_use" small @click="deleteItem( item )">delete</v-icon>
                  </td>
            </tr>
        </template>
      </v-data-table>
    </v-card>
</template>

<script>
import{ conf } from '@/utils/confTruePLM';

export default {
  name: 'PropInfo',
  props: {
      prop: null
  },
  data () {
    return {
        heads: [
            { text: 'message.prop_value_col', value: 'name' }, 
            { width: '15%', sortable: false, value: 'act' }
        ],
        globalState: this.$globalStore.state,
        propVals : [],
        type: null,
        model: this.prop        
    };
  },
  watch: {
      prop( val ) 
      {
            this.updateModel();
      }
  },
  methods: 
  {
      addItem()
      {
          var self = this;
          this.$modalDlg.sysDlgs.confirmOneValDlg.open( 'refdata_add_title', 'refdata_add_msg', '' )
                  .then( ( val ) =>
          {
              return conf.addRefDataClass( val, self.model.rdlName );
          }, () => {} )
                  .then( () => 
          {
               self.updateModel( self.model );  
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      deleteItem( prop )
      {
          var self = this;
          this.$modalDlg.sysDlgs.confirmDlg.open( 'refdata_delete_title', 'refdata_delete_msg' )
                  .then( () =>
          {
              return conf.deleteRefData( prop.val, self.model.rdlName );
          }, () => {} )
                  .then( () =>
          {
              self.updateModel( self.model );              
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      updateModel()
      {
          this.propVals = [];
          this.model = this.prop;
          var self = this;
          
          conf.getChildTypesWithStatus( this.model.rdlName )
                  .then( ( data ) =>
          {
              data.forEach( ( elem ) =>
              {
                  self.propVals.push( { val: elem.rdlInfo.class_uri, val_view: elem.rdlInfo.name || this.$utils.clearType( elem.rdlInfo.class_uri ), 
                      in_use: elem.inUse || ( elem.rdlInfo.status === 'CHECKED' ) } );                                        
              });     
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      }
  },
  mounted: function() 
  { 
      this.updateModel();
  },   
  beforeDestroy: function()
  {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .propTbl td
    {
        height: 30px;
    }        
</style>
