<template>
    <div>
        <v-card :class="smallWin ? 'smallSize' : ( showPager ? 'normalSizeWithPage' : 'normalSize' )" style="overflow-y: auto;">
            <v-list dense>
                <prod-list-row-view v-if="backNode.element_type === 'product_elem'" :model="backNode" @procRowMenuCmd="procRowMenuCmd"></prod-list-row-view>
                <node-list-row-view v-else-if="backNode.name" :model="backNode" @procRowMenuCmd="procRowMenuCmd"></node-list-row-view>
                
                <node-list-row-view v-for="( node, index ) in nodeList" :model="node" :versionList="verList" :key="'n' + index" @procRowMenuCmd="procRowMenuCmd"></node-list-row-view>
                <prod-list-row-view v-for="( prod, index ) in prodList" :model="prod" :key="'p' + index" @procRowMenuCmd="procRowMenuCmd"></prod-list-row-view>
                <node-list-file-row-view v-for="file in curModel.data_contents" :model="file" @procRowMenuCmd="procRowMenuCmd"
                                         :node="curModel" :key="file.instance_id"></node-list-file-row-view>
            </v-list>
        </v-card>
        <v-pagination v-model="curPage" class="my-4" :length="pageCnt" v-show="showPager" @input="updatePage"></v-pagination>
        <snotes-view ref="sNotesDlg"></snotes-view>
        <dlinks-view ref="dLinksDlg"></dlinks-view>
        <member-list-view ref="memberListDlg"></member-list-view>
        <role-list-view ref="roleListDlg"></role-list-view>
    </div>
</template>

<script>
import NodeListRow from './NodeListRow.vue';
import ProdListRow from './ProdListRow.vue';
import FileListRow from './FileListRow.vue';
import{ conf } from '../utils/confTruePLM';
import StickyNotes from './Dlg/StickyNotes.vue'
import DocLinks from './Dlg/DocLinks.vue'
import MemberList from './Dlg/MemberList.vue'
import RoleList from './Dlg/RoleList.vue'

import router from '../router';

export default {
  name: 'NodeList',
  props: {
      listStyle: {},
      smallWin: false
  },
  data () {
    return {
        sortDec: false,
        nodeList: [],
        prodList: [],
        
        pageSize: 100,
        curPage: 1,
        pageCnt: 1,
        showPager: false,
        rootNode: {},
        curModel: {},
        verList: [],
        backNode: { permissions: {} },
        showOccur: false,
        showShape: false,
        childOccur: false
    };
  },
  components: {
       'node-list-row-view': NodeListRow,
       'prod-list-row-view': ProdListRow,
       'node-list-file-row-view': FileListRow,
       'snotes-view': StickyNotes,
       'dlinks-view': DocLinks,
       'member-list-view': MemberList,
       'role-list-view': RoleList
  },
  methods: 
  {
      getPBS: function( info )
      {
          this.curModel = {};
          let self = this;
       
          self.$modalDlg.sysDlgs.progressDlg.open();
          
          return conf.getPBS().then( ( data )=>
          { 
              if( data.error )
              {
                  console.log( error );
              }
              
              if( !data.root_bkdn_elem )
                  throw new Error( 'no root' );
              
              conf.checkIsBlockChain()
                      .then( rez => 
              {
                  self.$globalStore.setBlockChainReady( rez.result === 'true' );
              });
              conf.checkIsDDIProject()
                      .then( rez => 
              {
                  self.$globalStore.setDDIProject( rez.result === 'True' );
              });
              
               conf.getChildTypesInContext( 'urn:plcs:rdl:TruePLM:User_defined_event' )
                      .then( rez => 
              {
                  if( rez && rez.length > 0 && rez[ 0 ] === 'urn:plcs:rdl:TruePLM:Broadcast_message' )
                      self.$globalStore.setUserMsgReady( true );
                  else
                      self.$globalStore.setUserMsgReady( false );
              });
              
              conf.getProjPhase()
                  .then( ( rez ) =>
              {
                  conf.getProj().in_project.current_phase = rez.result;
              });
              self.$globalStore.setSubdomainInfo( data.subdomains );
              data = data.root_bkdn_elem;
            
              self.showPager = false;
              self.rootNode = data;
              self.fillVerList();
              self.fillNode( self.rootNode, self.rootNode );
              self.prodList = [];
              self.nodeList = [ self.rootNode ];
              self.nodeList.forEach( function( item ){ item.root = true; } );
              self.backNode = { permissions: {} };
              
//              var func = [];
//              func.push( conf.getChildTypesInContext( enumNames[ 0 ] ) ); //0 node types
//              func.push( conf.getChildTypes( enumNames[ 1 ] ) ); //1 sources
//              func.push( conf.getChildTypes( enumNames[ 2 ] ) ); //2 content types
//              func.push( conf.getChildTypes( enumNames[ 3 ] ) ); //3 disciplines
//              func.push( conf.getChildTypes( enumNames[ 4 ] ) ); //4 project phases
//              func.push( conf.getChildTypes( enumNames[ 5 ] ) ); //5 current statuses
//              func.push( conf.getProjectUsers() ); //6 project users
//              return Promise.all( func );
//          } )
//                  .then( ( data ) =>
//          {
//              var enums = {
//                            nodeTypesList: [],
//                            sourcesList: [],
//                            cTypesList: [],
//                            disciplinesList: [],
//                            pPhasesList: [],
//                            statusesList: [],
//                            usersList: []
//                          };
//              let dt = [];
//              data[ 0 ].forEach( ( el ) =>
//              {
//                  if( el !== 'urn:rdl:epm-std:product_list' )
//                      dt.push( el );
//              });
//              self.fillEnum( enums.nodeTypesList, dt );
//              enums[ enumNames[ 0 ] ] = enums.nodeTypesList;
//              self.fillEnum( enums.sourcesList, data[ 1 ] );
//              enums[ enumNames[ 1 ] ] = enums.sourcesList;
//              self.fillEnum( enums.cTypesList, data[ 2 ] );
//              enums[ enumNames[ 2 ] ] = enums.cTypesList;
//              self.fillEnum( enums.disciplinesList, data[ 3 ] );
//              enums[ enumNames[ 3 ] ] = enums.disciplinesList;
//              self.fillEnum( enums.pPhasesList, data[ 4 ] );
//              enums[ enumNames[ 4 ] ] = enums.pPhasesList;
//              self.fillEnum( enums.statusesList, data[ 5 ] );
//              enums[ enumNames[ 5 ] ] = enums.statusesList;
//              
//              if( data[ 6 ] && data[ 6 ].available_users )
//              {
//                    data[ 6 ].available_users.forEach( ( item ) =>
//                    {
//                        if( item.user_name !== 'system' )
//                            enums.usersList.push( { txt: item.user_name, val: item.user_name, roles: item.user_type } );
//                    });
//              }
//              enums[ enumNames[ 6 ] ] = enums.usersList;
//              enums[ enumNames[ 7 ] ] = enums.usersList;
//              enums[ enumNames[ 8 ] ] = enums.usersList;
//              enums[ enumNames[ 9 ] ] = enums.usersList;
//              
//              this.$globalStore.setModelEnums( enums );
//              
//              var func = [ conf.getPropsByName( 'urn:rdl:epm-std:Document_content_type' ), conf.getPropsByName( 'urn:rdl:epm-std:breakdown_element_type' ) ];
//              enums.nodeTypesList.forEach( ( type ) =>
//              {
//                  func.push( conf.getPropsByName( type.val ) );                   
//              });
//              return Promise.all( func );
//          })
//                  .then( ( data ) =>
//          {
//              this.$globalStore.setPropEnums( data );

              if( info )
              {
                    if( info.search )
                    {
                        self.gotoSearch( info.search );
                    }
                    else if( info.path ) 
                    {
                        let path = info.path;
                        if( typeof path === 'string' )
                            path = JSON.parse( info.path );
                        self.gotoNode( path );
                    }
              }
              else
                self.$modalDlg.sysDlgs.progressDlg.close();
            
              return Promise.resolve();
          })
                  .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              
              self.nodeList = [];
              self.prodList = [];
              self.rootNode = {};
              self.curModel = {};
              self.verList = [];
              self.backNode = { permissions: {} };
              if( err.message === 'no root' )
              {
                    if( this.$globalStore.state.curUserRole === 'urn:rdl:epm-std:project_manager' 
                        || this.$globalStore.state.curUserRole === 'urn:rdl:epm-std:project_admin' )
                    {
                        this.$modalDlg.sysDlgs.confirmDlg.open( 'no_root_title', 'no_root_msg' )
                                  .then( () =>
                        {
                              self.inactiveProj();
                        }, () => {} )
                    }
              }
              else
                  self.$eventBus.$emit( 'queryError', err );
          });
      },
      inactiveProj: function()
      {
          let self = this;
          conf.inactiveProj( conf.getProj().in_project.guid ) 
                .then( ( rez ) => 
          {
              if( rez && !rez.error_code )
              {
                  this.$modalDlg.sysDlgs.confirmDlg.open( 'deact_proj_done_title', 'deact_proj_done_msg', null, null, true );
                  this.$eventBus.$emit( 'updateProjList' );
              }
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });             
      },
      createRoot: function()
      {
          var self = this;
          this.$modalDlg.sysDlgs.createNodeDlg.open( null, true )
                  .then( ( data ) =>
          {
              return conf.createRoot( data );
          }, () => {} )
                  .then( ( rez ) => 
          {
              if( rez )
                  self.getPBS();
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });                 
      },
      fillEnum: function( enumList, data )
      {
          data.forEach( ( item ) =>
          {
                enumList.push( { txt: this.$utils.clearType( item ), val: item } );
          });
          this.$utils.sortArray( enumList, ( a ) => ( a.txt ) );
      },
      setActVer: function( actVer )
      {
          if( !actVer )
              return;
         
          if( actVer === this.verList[ 0 ] )
              this.$globalStore.clearActVer();
          else          
            this.$globalStore.setActVer( actVer );
          
          var self = this;          
          conf.getPBS( actVer ).then( ( data )=>
          { 
              //console.log( data );
              data = data.root_bkdn_elem;
              self.rootNode = data;
              self.fillVerList();
              self.prodList = [];
              self.nodeList = [ self.rootNode ];
              self.nodeList.forEach( function( item ){ item.root = true; } );
              self.fillNode( self.rootNode, self.rootNode );
              self.rootNode.version_id = actVer || data.version_id;
          })
            .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      updateRootVer: function( newVer )
      {
          if( !newVer )
              return;
          
          this.rootNode.version_id = newVer;
          this.fillVerList( newVer );
      },
      fillVerList: function()
      {
          if( !this.rootNode )
              return;
          
          conf.versionList()
                .then( ls => 
          {
              this.verList = ls;
          })
                .catch( ( err ) =>
          {
              this.$eventBus.$emit( 'queryError', err );
          });
      },
      openBaseline: function( bsl )
      {
          this.$globalStore.setActVer( bsl.in_bkdn_version );
          this.goInNode( bsl.root_bkdn_elem );
      },
      setRenameAttr: function( node )
      {
          let fAttr = null;
          
          let path = localStorage.getItem( 'curr_path' );
          if( !path )
              return;
          path = JSON.parse( path );
          let nodeUsed = false;
          
          for( let i = 0; i < path.length; i++ )
          {
              let p = path[ i ];
              let attr = this.$globalStore.state.renameNodeAttr[ p ];
              if( attr )
                  fAttr = attr;
              
              if( node.instance_id === p )    
              {
                  nodeUsed = true;
                  break;
              }
          }
          
          if( !nodeUsed )
          {
              let attr = this.$globalStore.state.renameNodeAttr[ node.instance_id ];
              if( attr )
                  fAttr = attr;
          }
          
          if( fAttr )
              this.$globalStore.setCurRenameNodeAttr( fAttr );
          else
              this.$globalStore.clearCurRenameNodeAttr();
      },      
      goInProd( info )
      {
          let self = this;
          this.nodeList = [];
          this.prodList = [];
    // console.log( 'in prod' );
          this.backNode = this.getBackNode( info );
         // this.backNode.parent = info;
          this.backNode.element_type = 'product_elem';
          this.backNode.elemID = info.elemID;
   //  console.log( this.backNode );
          conf.getProdInfo( info )
            .then( ( data ) =>
          {
              self.$eventBus.$emit( 'updateProd', data );
              ( data.parts || [] ).forEach( ( el ) =>
              {
                  el.parent = info;
                  el.name = el.part.name;
                  el.elemID = el.part.view_id;
              });
              data.elemID = data.view_id;
              self.prodList = data.parts;
              self.curModel = data;
          })
              .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      updatePage()
      {
          this.goInNode( this.curModel );
      },
      goInNode( model, isUpdate, tabType )//, emitUpdate )
      {
//          let dd = Date.now();
           let self = this;
                      
           if( !isUpdate || !self.$globalStore.state.selectedID )
               self.$globalStore.setSelectedID( model.instance_id );          
           
           if( model.isProj )
           {
               self.curPage = 1;
               self.showPager = false;
               self.$eventBus.$emit( 'selectedModel' ); 
               self.$globalStore.setCurFolderInfo( model );
//               self.nodeList = [ self.rootNode ];
//               self.curModel = {};
//               this.backNode = {};
//               this.$eventBus.$emit( 'updateNode', {} ); 
             //  self.$globalStore.clearPropNode();
             return Promise.resolve();
           }
           else
           {
                self.$modalDlg.sysDlgs.progressDlg.open();
                
                self.$eventBus.$emit( 'setURLData', { node: model.instance_id } );
                //self.$router.push( '/home/' + conf.getProj().in_project.guid + '/' + model.instance_id );
                if( model.filled )
                {
                    self.nodeList = model.children;
                    return Promise.resolve();
                }
                else
                {
                    self.setRenameAttr( model );
                    
                    let ver = self.$globalStore.state.actVer || ''; //self.rootNode.version_id;
                    
                    if( model.instance_id !== self.curModel.instance_id )
                        this.curPage = 1;
                    
                    let strInd = ( self.curPage - 1 ) * self.pageSize + 1;                 
                    return Promise.all( [ conf.getNodeChildren( model, ver, strInd, self.pageSize, self.sortDec, 
                                        self.showOccur, self.showShape, self.childOccur ), conf.getProdsInNode( model ) ] )
                           .then( ( data )=>
                   {
                       self.prodList = data[ 1 ];
                       self.prodList.forEach( ( el ) =>
                       {
                           el.parent = model;
                           el.elemID = el.view_id;
                       });
                       self.fillNode( model, data[ 0 ].element );
                       self.nodeList = model.children;
                       self.curModel = model;
                       self.pageCnt = Math.floor( self.curModel.children_count / self.pageSize ) + 1;
                       self.showPager = self.pageCnt > 1;
                       self.backNode = self.getBackNode( model );
                       self.$globalStore.setCurFolderInfo( self.curModel );
//                       model.name + ' (ver.' + model.version_id + ')', 
//                                self.curModel.is_root_of_subdomain > 0, self.curModel.is_part_of_subdomain > 0 );
                       
                      // self.$globalStore.setPropNode( model );
                      // if( emitUpdate )
                       this.rootNode.version_id = data[ 0 ].bkd_version_id;
                       if( tabType !== 'file' )
                       {
                           if( self.$globalStore.state.selectedID === self.curModel.instance_id )
                               self.$eventBus.$emit( 'updateNode', self.curModel ); 
                           else
                           {
                               for( let ind = 0; ind < self.nodeList.length; ind++ )
                               {
                                   if( self.nodeList[ ind ].instance_id === self.$globalStore.state.selectedID )
                                   {
                                       self.$eventBus.$emit( 'showSelProps', self.nodeList[ ind ] );
                                       break;
                                   }
                               }
                           }
                       }
                        
                       self.$modalDlg.sysDlgs.progressDlg.close();
                        
//                        dd = Date.now() - dd;
//                        console.log( 'time = ' + dd + ' ms' );
                       return Promise.resolve();
                   }, ( err )=>
                   { 
                       self.$modalDlg.sysDlgs.progressDlg.close();
                       self.$eventBus.$emit( 'queryError', err ); 
                   } );
                }          
            }
      },
      goOutNode: function( model )
      {
          let self = this;

          if( model.parent )
          {
              if( model.parent.part_type )
                  self.goInProd( model.parent );
              else
                  self.goInNode( model.parent );
//              self.nodeList = model.parent.children;
//              self.curModel = model.parent;
//              self.$globalStore.setCurFolderName( model.parent.name );
          }
          else
          {
              self.nodeList = [ model ];
              self.curModel = {};
              self.prodList = [];
              self.backNode = { permissions: {} };
              self.$globalStore.setCurFolderInfo( model );
              
              //self.$globalStore.clearURLDataNode();
              self.$eventBus.$emit( 'setURLData', { proj: conf.getProj().in_project.guid } );
              //self.$router.push( '/home/' + conf.getProj().in_project.guid );
              //model.name, 
                //    self.curModel.is_root_of_subdomain > 0, self.curModel.is_part_of_subdomain > 0 );
          }
      },
      fillNode: function( node, data )
      {
          if( !node || !data )
              return;
          
          if( !data.children )
              data.children = [];
          
//          data.children.sort( function( a, b ) 
//          {
//               return ( a.name > b.name ) ? 1 : ( ( b.name > a.name ) ? -1 : 0 ); 
//          } );
          //node.filled = true;
          
          let ch = [];
          data.children.forEach( item =>
          {
//              if( item.permissions.can_i_read )
//              {
                    item.parent = node;
                    ch.push( item );
//              }
          });
          node.children = ch;

          node.data_contents = data.data_contents || [];
          node.properties = data.properties || [];
          node.permissions = data.permissions || {};
          node.version_id = data.version_id;
          node.children_count = data.children_count;
          node.last_modified_by_user = data.last_modified_by_user;
          node.date_last_modified = data.date_last_modified;
          node.created_by_user = data.created_by_user;
          node.date_created = data.date_created;
          node.phase = data.phase;
          node.name = data.name;
          node.description = data.description;
          node.element_type = data.element_type;
          node.is_root_of_subdomain = data.is_root_of_subdomain;
          node.rels = data.rels;
          node.orgs = data.orgs;
      },
      getBackNode( data )
      {
          let bNode = Object.assign( {}, data );
          bNode.root = false;
          bNode.back = true;
          bNode.parent = data;
          bNode.version_id = '';
          bNode.permissions = data.permissions || {};
          bNode.data_contents = null;
          bNode.children = null;

          return bNode;
//          return { name: data.name, instance_id: data.instance_id, back: true, is_part: data.is_part,
//                  permissions: data.permissions || {}, is_part_of_subdomain: data.is_part_of_subdomain,
//                  parent: data, is_root_of_subdomain: data.is_root_of_subdomain, id: data.id, 
//                  is_requirement: data.is_requirement, element_type: data.element_type };
      },
      procPath( path, ver )
      {
         // console.log( path );
          let self = this;
          self.$modalDlg.sysDlgs.progressDlg.open();
          conf.getPathInfo( path, ver )
                  .then( ( objPath ) =>
          {
              if( objPath && objPath.length > 0 )
              {
                    for( var i = objPath.length - 1; i > 0; i-- )
                        objPath[ i ].parent = objPath[ i - 1 ];

                    self.curModel = objPath[ objPath.length - 1 ];
                    self.fillNode( self.curModel, self.curModel );
                    self.nodeList = self.curModel.children;
                    self.backNode = self.getBackNode( self.curModel );
                    self.pageCnt = Math.floor( self.curModel.children_count / self.pageSize ) + 1;
                    self.showPager = self.pageCnt > 1;
                       
                    conf.getProdsInNode( self.curModel )
                            .then( ( data ) =>
                    {
                        self.prodList = data;
                        self.prodList.forEach( ( el ) =>
                        {
                            el.parent = self.curModel;
                            el.elemID = el.view_id;
                        });
                        self.$modalDlg.sysDlgs.progressDlg.close();
                    }, ( err )=>
                    { 
                        self.$modalDlg.sysDlgs.progressDlg.close();
                        self.$eventBus.$emit( 'queryError', err ); 
                    } );
                    
                    objPath[ 0 ] = self.rootNode;

                    self.$globalStore.setCurFolderInfo( self.curModel );
                    self.$globalStore.setSelectedID( self.curModel.instance_id );
                    
                    self.$eventBus.$emit( 'setURLData', { node: self.curModel.instance_id } );
                    //self.$router.push( '/home/' + conf.getProj().in_project.guid + '/' + self.curModel.instance_id );
                    
                    //self.curModel.name + ' (ver.' + self.curModel.version_id + ')', 
                      //      self.curModel.is_root_of_subdomain > 0, self.curModel.is_part_of_subdomain > 0 );

                    self.$eventBus.$emit( 'updateNode', self.curModel ); 
                    self.$eventBus.$emit( 'setPath', objPath );  
                    
                    let fileID = localStorage.getItem( 'fileID' );
                    localStorage.removeItem( 'fileID' );
                    for( let j = 0; j < self.curModel.data_contents.length; j++ )
                    {
                        if( ( '' + self.curModel.data_contents[ j ].instance_id ) === fileID )
                        {
                            self.$eventBus.$emit( 'updateFile', { file: self.curModel.data_contents[ j ], node: self.curModel } );  
                            self.$globalStore.setSelectedID( self.curModel.data_contents[ j ].instance_id );   
                            self.$eventBus.$emit( 'setURLData', { file: self.curModel.data_contents[ j ].instance_id } ); 
                            break;
                        }
                    }
              }
              else 
                  self.$modalDlg.sysDlgs.progressDlg.close();
              
          }, ( err )=>
          { 
            self.$modalDlg.sysDlgs.progressDlg.close();
            self.$eventBus.$emit( 'queryError', err ); 
          } );
      },
      gotoSearch( searchPrm )
      {
          let sP = JSON.parse( searchPrm );
          let self = this;
          
          conf.getNodePath( 0, sP.id )
                .then( ( data ) =>
          {
            if( data && data.tree_path_to_element )
                self.gotoNode( data.tree_path_to_element );
          });
         // let info = { guid: true, val: sP.id };
         // this.$eventBus.$emit( 'searchQuick', info );    
      },
      gotoNode( info, ver )
      {
          //console.log( info );
          if( info && info.tree_path_to_linked_bkdn_elem )
              this.procPath( info.tree_path_to_linked_bkdn_elem, ver );
          else if( info && info.length )
              this.procPath( info, ver );   
          else
              this.$modalDlg.sysDlgs.progressDlg.close();
          
      },
      deleteNode( node )
      {
          if( this.$globalStore.state.curUserRole === 'urn:rdl:epm-std:project_member' )
              this.doDelNode( node, false );
          else
          {
              let self = this;
              conf.getNodeDelHinders( node )
                      .then( info =>
              {
                  if( !info.items )
                      self.doDelNode( node, false );
                  else
                      self.showHinders( node, info );
              })
                    .catch( ( err ) =>
              {
                  self.$modalDlg.sysDlgs.progressDlg.close();
                  self.$eventBus.$emit( 'queryError', err );
              });
          }
      },
      showHinders( node, info )
      {
          let self = this;
          this.$modalDlg.sysDlgs[ 'nodeDelHinderDlg' ].open( info.items )
                  .then( rez =>
          {
              self.doDelNode( node, true );
                      
          }, () => {} )
                .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      doDelNode( node, forAll )
      {
          let self = this;
//          let mInd = "_ch";
//          if( node.children_count < 1 )
//              mInd = "";
//          this.$modalDlg.sysDlgs.confirmDlg.open( 'conf_del_title', 'conf_del_msg' + mInd )
//                  .then( () =>
//          let isMember = this.$globalStore.state.curUserRole === 'urn:rdl:epm-std:project_member';
//          this.$modalDlg.sysDlgs.deleteDocDlg.open( isMember, true )
//                  .then( ( forAll ) =>
//          {
              self.$modalDlg.sysDlgs.progressDlg.open();
              conf.deleteNode( node, forAll, self.$globalStore.setActTimestamp() )
//          }, () => {} )
                  .then( ( rez ) => 
          {
              if( rez && rez.result )
                  self.updateRootVer( rez.result.updated_node_version );
              
              self.refreshData();
          })
                  .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      updateFileClass( info )
      {
          var self = this;
          conf.updateFileClass( info.node, info.props )
                  .then( ( rez ) =>
          {
              if( rez )
                self.updateRootVer( rez.updated_node_version );
            
              return self.refreshData( 'file' );
          })
                  .then( () => 
          {
              for( var i = 0; self.curModel.data_contents 
                      && i < self.curModel.data_contents.length; i++ )
              {
                  if( self.curModel.data_contents[ i ].instance_id === self.$globalStore.state.selectedID )
                  {
                      this.$eventBus.$emit( 'updateFile', { file: self.curModel.data_contents[ i ], node: self.curModel } ); 
                      break;
                  }
              }
          });
      },
      uploadAggrPropData( info )
      {
          let self = this;
          let types = [ { txt: 'JSON', val: 'json' }, { txt: 'CSV', val: 'csv' } ];
          this.$modalDlg.sysDlgs.importFileToProjDlg.open( 'aggr_prop', types )
                  .then( ( data ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.open();
              return conf.uploadAggrPropData( info.node, info.prop, data, self.$globalStore.setActTimestamp() );
          }, () => {} )
                  .then( ( rez ) => 
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.refreshData();
          })
                  .catch( ( err ) => 
          { 
              self.$eventBus.$emit( 'queryError', err );
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.refreshData();
          } );
      },
      downloadAggrPropData( info )
      {
         // console.log( info );
          let self = this;
          let items = [ { txt: 'JSON', val: 'json' }, { txt: 'CSV', val: 'csv' } ];
          this.$modalDlg.sysDlgs.confirmOneValDlg.open( 'download_aggr_title', 'download_aggr_msg', 'json', null, null, 'select', items )
                  .then( format => 
          {
              self.$modalDlg.sysDlgs.progressDlg.open();
              return conf.exportAggrProp( info.node.instance_id, info.prop.obj.urn, null, null, null, null, format );
          }, () => {} )
                  .then( ( flInfo ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              if( flInfo )
              {
                  let url = conf.getFileLink( flInfo.title, flInfo.source );
                  window.location.href = encodeURI( url );     
              }
          })
                  .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              this.$eventBus.$emit( 'queryError', err );
          });
      },
      updateProdProps( info )
      {
          //console.log( info );
          var self = this;
          conf.updateNodeProps( info.node, info.props, 'prod', self.$globalStore.setActTimestamp() )
                  .then( ( rez ) =>
          {
              this.$eventBus.$emit( 'updateProd', rez ); 
          })
                .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
              self.refreshData();
          });
      },
      updateProps( info, nodeType )
      {
          var self = this;
          conf.updateNodeProps( info.node, info.props, nodeType, self.$globalStore.setActTimestamp() )
                  .then( ( rez ) =>
          {
              if( rez )
                self.updateRootVer( rez.updated_node_version );
            
              return self.refreshData( nodeType );
          })
                  .then( () => 
          {
              if( nodeType === 'file' )
              {
                  for( var i = 0; self.curModel.data_contents 
                          && i < self.curModel.data_contents.length; i++ )
                  {
                      if( self.curModel.data_contents[ i ].instance_id === self.$globalStore.state.selectedID )
                      {
                          this.$eventBus.$emit( 'updateFile', { file: self.curModel.data_contents[ i ], node: self.curModel } ); 
                          break;
                      }
                  }
              }
          })
                .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
              self.refreshData();
          });
      },
      renameNode( node )
      {
          let self = this;
          this.$modalDlg.sysDlgs.confirmOneValDlg.open( 'conf_rename_title', 'conf_rename_msg', node.name )
                  .then( ( name ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.open();
              return conf.renameNode( node, name, self.$globalStore.setActTimestamp() );
          }, () => {} )
                  .then( ( rez ) => 
          {
              if( rez )
                self.updateRootVer( rez.updated_node_version );
            
              self.refreshData();
          })
                  .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      renameNodeByAttr( node )
      {
          var self = this;
          this.$modalDlg.sysDlgs.confirmOneValDlg.open( 'set_rename_attr_title', 'set_rename_attr_msg', '' )
                  .then( ( name ) =>
          {
              name = name.trim();
              if( name !== '' )
              {
                  self.$globalStore.addRenameNodeAttr( node.instance_id, name );
                  self.refreshData();
              }
          }, () => {} )
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      showNodeHistory( node )
      {
          this.$modalDlg.sysDlgs.nodeHistoryDlg.open( node )
                  .then( () => {}, () => {} );
      },
      createBaseline( node )
      {
          var self = this;
          var bName = null;
          this.$modalDlg.sysDlgs.createBaselineDlg.open( node )
                  .then( ( data ) =>
          {
              bName = data.id;
              return conf.createBaseline( node, data );
          }, () => {} )
                  .then( ( rez ) => 
          {
              if( rez )
                  return this.$modalDlg.sysDlgs.confirmDlg.open( 'created_baseline_title', 'created_baseline_msg', null, bName, true );
          })
                  .catch( ( err ) =>
          {
//              console.log( err );
//              err.message = "baseline_create";
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      infoSubdomain( node )
      {
          this.$modalDlg.sysDlgs.subInfoDlg.open( node );
      },
      createSubdomain( node )
      {
          var self = this;
          this.$modalDlg.sysDlgs.createSubdomainDlg.open( node )
                  .then( ( data ) =>
          {
              return conf.createSubdomain( node, data );
          }, () => {} )
                  .then( ( rez ) => 
          {
              if( rez )
              {
                  //console.log( rez );
                  self.refreshData();
              }
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      subdomainUsers( node )
      {
          var self = this;
          this.$refs.memberListDlg.open( node )
                  .then( ( rez ) =>
          {
              if( rez )
                  self.refreshData();                              
          });
      },
      subdomainRoles( node )
      {
          var self = this;
          this.$refs.roleListDlg.open( node )
                  .then( ( rez ) =>
          {
              if( rez )
                  self.refreshData();                              
          });          
      },
      deleteSubdomain( node )
      {
          var self = this;
          this.$modalDlg.sysDlgs.confirmDlg.open( 'subdomain_delete_title', 'subdomain_delete_msg' )
                  .then( () =>
          {
              return conf.deleteSubdomain( node );
          }, () => {} )
                  .then( ( rez ) => 
          {
              if( rez )
              {
                  //console.log( rez );
                  self.refreshData();
              }
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      createNode( node )
      {
          var self = this;
          this.$modalDlg.sysDlgs.createNodeDlg.open( null, null, node.element_type === 'urn:rdl:epm-std:product_list', node )
                  .then( ( data ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.open();
              return conf.createNode( node, data, this.$globalStore.setActTimestamp() );
          }, () => {} )
                  .then( ( rez ) => 
          {
              if( rez )
                self.updateRootVer( rez.version_id );
            
              self.refreshData();
          })
                  .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      createReq( node )
      {
          var self = this;
          this.$modalDlg.sysDlgs.createReqDlg.open()
                  .then( ( data ) =>
          {
              return conf.createNode( node, data, self.$globalStore.setActTimestamp() );
          }, () => {} )
                  .then( ( rez ) => 
          {
              if( rez )
                self.updateRootVer( rez.version_id );
            
              self.refreshData();
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      editNode( node )
      {
          var self = this;
          this.$modalDlg.sysDlgs.createNodeDlg.open( node )
                  .then( ( data ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.open();
              return conf.editNode( node, data, self.$globalStore.setActTimestamp() );
          }, () => {} )
                  .then( ( rez ) => 
          {
              if( rez )
                self.updateRootVer( rez.updated_node_version );
            
              self.refreshData();
          })
                  .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      checkOutFile( file )
      {
          var self = this;
          
          conf.checkOutFile( file, self.$globalStore.setActTimestamp() )
                  .then( ( rez ) =>
          {
              if( !rez.error_code )
                  return conf.downloadFileVer( file.file_name, file.versions[ file.versions.length - 1 ].instance_id );
              else
                  throw new Error( "error: " + rez.error_code );
          })
                  .then( ( flInfo ) =>
          {
                let url = conf.getFileLink( flInfo.title, flInfo.source );
                window.location.href = encodeURI( url );          
                self.refreshData();
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
              self.refreshData();
          });
      },
      cancelCheckOutFile( file )
      {
          var self = this;
          
          conf.cancelCheckOutFile( file, self.$globalStore.setActTimestamp() )
                  .then( ( rez ) =>
          {
              if( !rez.error_code )
                  self.refreshData();
          })
                .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
              self.refreshData();
          });;
      },
      checkInFile( file )
      {
          var self = this;
          this.$modalDlg.sysDlgs.checkInFileDlg.open( file )
                  .then( ( data ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.open();
              return conf.checkInFile( file, data, self.$globalStore.setActTimestamp() );
          }, () => {} )
                  .then( ( rez ) => 
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              if( rez )
              {
                  if( rez.updated_node_version )
                    self.updateRootVer( rez.updated_node_version );
                
                  self.refreshData();
              }
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.refreshData();
          });    
      },
      downloadFile( file )
      {
          const self = this;
          self.$modalDlg.sysDlgs.progressDlg.open();
          conf.downloadFileVer( file.file_name, file.versions[ file.versions.length - 1 ].instance_id, file.last_ver_id )
                  .then( ( flInfo ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              let url = conf.getFileLink( flInfo.title, flInfo.source );
              window.location.href = encodeURI( url );          
          })
                  .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              this.$eventBus.$emit( 'queryError', err );
          });
      },
      convertFileToWCAX( file, node )
      {
          let self = this;
          
          self.$modalDlg.sysDlgs.progressDlg.open();
          conf.convertFileToWCAX( node.instance_id, file.file_name, 
                file.versions[ file.versions.length - 1 ].instance_id, self.$globalStore.setActTimestamp() )
                  .then( rez =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.refreshData();
          })
                  .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      openFile( file )
      {
          let self = this;
          
          conf.downloadFileVer( file.file_name, file.versions[ file.versions.length - 1 ].instance_id )
                  .then( ( flInfo ) =>
          {
              let fExt = file.file_name.substring( file.file_name.length - 5 );
              if( fExt === '.wcax' )
              {
                  let fileURL = window.location.origin + '/VCollabWeb/viewer.html?file=' 
                        + window.location.href.substring( 0, window.location.href.length - window.location.hash.length )
                        + conf.getFileLink( flInfo.title, flInfo.source );
                  window.open( fileURL, '_blank' );  
                  return Promise.resolve( null );
              }
              else
                  return conf.getFileLinkData( flInfo.title, flInfo.source );
          })
                  .then( ( data ) =>
          {
              if( !data )
                  return;
              
              let blob = this.$utils.getFileBlob( data, file.file_name );
              this.$utils.saveFile( blob, file.file_name );
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
              self.refreshData();
          });
      },
      exportToAP242( node )
      {
          let self = this;
          self.$modalDlg.sysDlgs.progressDlg.open();
          conf.exportToAP242( node )
                  .then( ( flInfo ) =>
          {
               let url = conf.getFileLink( flInfo[ 0 ].title, flInfo[ 0 ].source );
               self.$modalDlg.sysDlgs.progressDlg.close();
               window.location.href = encodeURI( url );   
               
               return conf.getFileStrings( flInfo[ 1 ].source );    
          })
                .then( info =>
          {
                self.$modalDlg.sysDlgs.reportDlg.open( info ); 
          })
                  .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      exportNodeToZip( node )
      {
          let self = this;
          self.$modalDlg.sysDlgs.progressDlg.open();
          conf.exportNodeToZip( node )
                  .then( ( flInfo ) =>
          {
              let url = conf.getFileLink( flInfo.title, flInfo.source );
              self.$modalDlg.sysDlgs.progressDlg.close();
              
              window.location.href = url;   
              
              if( flInfo.descr )
              {
                  let repInfo = flInfo.descr.split( "\n" );
                  self.$modalDlg.sysDlgs.reportDlg.open( repInfo, 'rename_list_title' );
              }
          })
                  .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      exportToDEX()
      {
          let self = this;
          this.$modalDlg.sysDlgs.confirmOneValDlg.open( 'export_proj_DEX_title', 'export_proj_DEX_msg', null, null, null, 'check' )
                  .then( ( val ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.open();
              this.doExportToDEX( val );
          }, () => {});
      },       
      doExportToDEX( asStruct )
      {
          let self = this;
          conf.exportProjToDEX( asStruct )
                  .then( ( flInfo ) =>
          {
              let url = conf.getFileLink( flInfo.title, flInfo.source );
              self.$modalDlg.sysDlgs.progressDlg.close();
              window.location.href = encodeURI( url );          
          })
                  .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              this.$eventBus.$emit( 'queryError', err );
          });
      },
      exportToFile( node )
      {
          let self = this;
          self.$modalDlg.sysDlgs.progressDlg.open();
          conf.exportProjToFile( node )
                  .then( ( flInfo ) =>
          {
               let url = conf.getFileLink( flInfo[ 0 ].title, flInfo[ 0 ].source );
               self.$modalDlg.sysDlgs.progressDlg.close();
               window.location.href = encodeURI( url );   
               
               return conf.getFileStrings( flInfo[ 1 ].source );    
          })
                .then( info =>
          {
                self.$modalDlg.sysDlgs.reportDlg.open( info ); 
          })
                  .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              this.$eventBus.$emit( 'queryError', err );
          });
      },
      exportToTxt()
      {
          let self = this;
          self.$modalDlg.sysDlgs.progressDlg.open();
          conf.exportStructToFile()
                  .then( ( flInfo ) =>
          {
              let url = conf.getFileLink( flInfo.title, flInfo.source );
              self.$modalDlg.sysDlgs.progressDlg.close();
              window.location.href = encodeURI( url );          
          })
                  .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              this.$eventBus.$emit( 'queryError', err );
          });
      },
      deleteFile( file )
      {
          let self = this;
          let isMember = this.$globalStore.state.curUserRole === 'urn:rdl:epm-std:project_member';

          this.$modalDlg.sysDlgs.deleteDocDlg.open( isMember )
                  .then( ( forAll ) =>
          {
              return conf.deleteFile( file, forAll, self.$globalStore.setActTimestamp() );
          }, () => {} )
                  .then( ( rez ) => 
          {
              if( rez )
                  self.updateRootVer( rez.updated_node_version );
              
              self.refreshData();
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
          
      },
      setFileReadOnly( file, val )
      {
          var self = this;
          conf.setFileReadOnly( file, val, self.$globalStore.setActTimestamp() )
                  .then( ( rez ) => 
          {
              if( !rez.error_code )
                  self.refreshData();
          })
                  .catch( () => {} );                    
      },
      linkFileToFile( file, type )
      {
          var self = this;
          var file1 = this.$globalStore.state.fileForLink;
          var file2 = file;
          if( type === 1 )
          {
              file1 = file;          
              file2 = this.$globalStore.state.fileForLink;
          }
          
          conf.linkFileToFile( file1, file2 )
                  .then( ( rez ) => 
          {
              if( !rez.error_code )
              {
                  this.$globalStore.clearFileForLink();
                  this.$globalStore.clearFileForPaste();
                  self.refreshData();
              }
          })
                .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });       
      },
      incProjVer()
      {
          let self = this;
          this.$modalDlg.sysDlgs.confirmDlg.open( 'conf_inc_ver_title', 'conf_inc_ver_msg' )
                  .then( () =>
          {
              self.$modalDlg.sysDlgs.progressDlg.open();
              return conf.incProjVer();
          }, () => {} )
                  .then( ( rez ) => 
          {
//              if( rez )
//                  self.updateRootVer( rez.updated_node_version );
              
              self.refreshData();
          })
                  .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      setProjPhase( node )
      {
          let self = this;
          let item = { val: node.phase, class: 'urn:rdl:epm-std:project_phase' };
   
          Promise.all( [ conf.getProjPhase(), conf.getChildTypes( item.class ) ] )
                  .then( ( data ) =>
          {
              item.val = data[ 0 ].result;
               
              item.obj = { type: item.class, domains: [ 'urn:owl:oneOf' ], options: data[ 1 ] };
                    item.type = item.class;
                    
              return this.$modalDlg.sysDlgs.assignPropDlg.open( item, null, 'phase_ttl' );
          })
                    .then( ( rez ) =>
          {
              return conf.updateProjPhase( rez.val );
          }, () => {} )
                  .then( () =>
          {
              self.curModel = null;
              self.refreshData();
          })
                  .catch( () => {} );
      },
      importFromTxt( node )
      {
          var self = this;
          this.$modalDlg.sysDlgs.importFileToProjDlg.open( 'txt' )
                  .then( ( data ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.open();
              return conf.importStructToNode( node, data );
          })
                  .then( ( rez ) => 
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.refreshData();
          })
                  .catch( ( err ) => 
          { 
              self.$eventBus.$emit( 'queryError', err );
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.refreshData();
          } );
      },
      importFile( node )
      {
          var self = this;
          this.$modalDlg.sysDlgs.importFileToProjDlg.open()
                  .then( ( data ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.open();
              return conf.importFileToProj( node, data );
          }, () => {} )
                  .then( ( rez ) => 
          {
              this.$eventBus.$emit( 'updateProjList', conf.getProj().in_project );
              self.$modalDlg.sysDlgs.progressDlg.close();
          })
                  .catch( ( err ) => 
          { 
              self.$eventBus.$emit( 'queryError', err );
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.refreshData();
          } );
      },
      importFileToNode( node )
      {
          var self = this;
          this.$modalDlg.sysDlgs.importFileToProjDlg.open()
                  .then( ( data ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.open();
              return conf.importFileToNode( node, data, self.$globalStore.setActTimestamp() );
          }, () => {} )
                  .then( ( rez ) => 
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
//              if( rez )
//                self.updateRootVer( rez.updated_node_version );
                          
              self.refreshData();
          })
                  .catch( ( err ) => 
          { 
              self.$eventBus.$emit( 'queryError', err );
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.refreshData();
          } );
      },
      importReqIFToNode( node )
      {
          var self = this;
          this.$modalDlg.sysDlgs.importFileToProjDlg.open( 'reqif' )
                  .then( ( data ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.open();
              return conf.importReqIFToNode( node, data, self.$globalStore.setActTimestamp() );
          }, () => {} )
                  .then( ( rez ) => 
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
//              if( rez )
//                self.updateRootVer( rez.updated_node_version );
                          
              self.refreshData();
          })
                  .catch( ( err ) => 
          { 
              self.$eventBus.$emit( 'queryError', err );
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.refreshData();
          } );
      },
      importAP242FileToNode( node )
      {
          var self = this;
          this.$modalDlg.sysDlgs.importFileToProjDlg.open( 'ap242' )
                  .then( ( data ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.open();
              return conf.importAP242FileToNode( node, data, self.$globalStore.setActTimestamp() );
          }, () => {} )
                  .then( ( rez ) => 
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
//              if( rez )
//                self.updateRootVer( rez.updated_node_version );
                          
              self.refreshData();
          })
                  .catch( ( err ) => 
          { 
              self.$eventBus.$emit( 'queryError', err );
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.refreshData();
          } );
      },
      importTxtToNode( node )
      {
          let self = this;
          this.$modalDlg.sysDlgs.importFileToProjDlg.open( 'txt' )
                  .then( ( data ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.open();
              return conf.importTxtFileToNode( node, data, self.$globalStore.setActTimestamp() );
          }, () => {} )
                  .then( ( rez ) => 
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              if( rez && rez.length && rez.length > 0 )
                self.$modalDlg.sysDlgs.reportDlg.open( rez );
                          
              self.refreshData();
          })
                  .catch( ( err ) => 
          { 
              self.$eventBus.$emit( 'queryError', err );
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.refreshData();
          } );
     },
      importCSVFileToNode( node )
      {
          let self = this;
          this.$modalDlg.sysDlgs.importFileToProjDlg.open( 'csv' )
                  .then( ( data ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.open();
              return conf.importCSVFileToNode( node, data, self.$globalStore.setActTimestamp() );
          }, () => {} )
                  .then( ( rez ) => 
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              if( rez && rez.length && rez.length > 0 )
                self.$modalDlg.sysDlgs.reportDlg.open( rez );
                          
              self.refreshData();
          })
                  .catch( ( err ) => 
          { 
              self.$eventBus.$emit( 'queryError', err );
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.refreshData();
          } );
      },
      importCSVFileToProj( node )
      {
          var self = this;
          this.$modalDlg.sysDlgs.importFileToProjDlg.open( 'csv' )
                  .then( ( data ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.open();
              return conf.importCSVFileToProj( data, self.$globalStore.setActTimestamp() );
          }, () => {} )
                  .then( ( rez ) => 
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              if( rez && rez.length && rez.length > 0 )
                    self.$modalDlg.sysDlgs.reportDlg.open( rez );
                          
              self.refreshData();
          })
                  .catch( ( err ) => 
          { 
              self.$eventBus.$emit( 'queryError', err );
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.refreshData();
          } );
      },
      procPropStatus( info )
      {
          if( info.node.instance_id === this.curModel.instance_id )
              this.curModel.emptyFields = info.emptyFields;
      },
      
      genDocByTmpl( node )
      {
          let self = this;
          if( node.back && self.curModel.emptyFields.length > 0 )
          {
              let msg = self.curModel.emptyFields.length < 2 ? 'empty_props_msg' : 'empty_props_msg2';
              let msgDt = self.curModel.emptyFields.join( '\', \'' );
              self.$modalDlg.sysDlgs.confirmDlg.open( 'empty_props_title', msg, null, msgDt, false, 'warning', '#fb8c00' )
                  .then( () =>
              {
                    self.genDocByTmplProc( node );
              }, () => {} );
          }
          else
              self.genDocByTmplProc( node );
      },
              
      genDocByTmplProc( node )
      {
          let self = this;
          this.$modalDlg.sysDlgs.addFileToNodeDlg.open( 'tmpl' )
                  .then( ( data ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.open();
              return conf.genDocFromTmpl( node, data, self.$globalStore.setActTimestamp() );
          }, () => {} )
                  .then( ( flInfo ) => 
          {
              if( flInfo )
              {
                  let url = conf.getFileLink( flInfo.title, flInfo.source );
                  self.$modalDlg.sysDlgs.progressDlg.close();
                  window.location.href = encodeURI( url );    
                  self.refreshData();
              }
          })
                  .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              this.$eventBus.$emit( 'queryError', err );
          });
      },
      addFileToNode( node )
      {
          var self = this;
          this.$modalDlg.sysDlgs.addFileToNodeDlg.open()
                  .then( ( data ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.open();
              return conf.addFileToNode( node, data, self.$globalStore.setActTimestamp() );
          }, () => {} )
                  .then( ( rez ) => 
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
//              if( rez )
//                self.updateRootVer( rez.updated_node_version );
                          
              self.refreshData();
          })
                  .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.$eventBus.$emit( 'queryError', err ); 
              self.refreshData();
          });
      },
      pasteFileToNode( node )
      {
          var self = this;
          
          var moveFl = true;
          var file = this.$globalStore.state.fileForPaste;
          if( !this.$globalStore.state.fileForPaste && this.$globalStore.state.fileForLink )
          {
              moveFl = false;
              file = this.$globalStore.state.fileForLink;
          }
          
          conf.pasteFileToNode( node, file, moveFl, self.$globalStore.setActTimestamp() )
                  .then( ( rez ) => 
          {
              if( !rez.error_code )
              { 
                  if( rez.updated_node_version )
                    self.updateRootVer( rez.updated_node_version );
                
                  file.parentNode.filled = false;
                  self.refreshData();
                  this.$globalStore.clearFileForLink();
                  this.$globalStore.clearFileForPaste();
              }                        
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      addIssue( subj )
      {
          this.$eventBus.$emit( 'procIssueRowMenuCmd', { cmd: 'addIssue', data: { subj: subj } } );
      },
      sendMsg( node, file )
      {
          this.$eventBus.$emit( 'sendNewMsg', { node: node, file: file } );
      },
      openLinks( file )
      {
          let self = this;
          this.$refs.dLinksDlg.open( file )
                  .then( ( rez ) =>
          {
              if( rez )
                  self.refreshData();                                
          });
      },
      openNotes( file )
      {
          let self = this;
          this.$refs.sNotesDlg.open( file, this.curModel )
                  .then( ( rez ) =>
          {
              if( rez )
                  self.refreshData();                              
          });
      },
      cutNode( node )
      {
          this.$globalStore.setNodeForPaste( node, false );
      },
      copyNode( node )
      {
          this.$globalStore.setNodeForPaste( node, true );
      },
      getProdInfo( info )
      {
          let self = this;
          conf.getProdInfo( info )
            .then( ( data ) =>
          {
              self.$eventBus.$emit( 'updateProd', data );
          })
              .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      removeProdFromProd( prod )
      {
          var self = this;
          this.$modalDlg.sysDlgs.confirmDlg.open( 'conf_remove_title', 'conf_remove_msg' )
                  .then( () =>
          {
              return conf.removeProd( prod, self.$globalStore.setActTimestamp() );
          }, () => {} )
                  .then( ( rez ) => 
          {   
              self.refreshData();
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      removeProdFromNode( prod )
      {
          var self = this;
          this.$modalDlg.sysDlgs.confirmDlg.open( 'conf_remove_title', 'conf_remove_msg' )
                  .then( () =>
          {
              return conf.removeProdFromNode( prod, self.$globalStore.setActTimestamp() );
          }, () => {} )
                  .then( ( rez ) => 
          {   
              self.refreshData();
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      copyProd( prod )
      {
          this.$globalStore.setProdForPut( prod );
      },
      createProd( node )
      {
          var self = this;
          this.$modalDlg.sysDlgs.createProdDlg.open()
                  .then( ( data ) =>
          {
              return conf.createProd( data, this.$globalStore.setActTimestamp() );
          }, () => { throw new Error( 'cancelled' ); } )
                  .then( ( prod ) => 
          {
              return conf.putProdToNode( node, { elemID: prod.view_id },
                                        self.$globalStore.setActTimestamp() );
          })
                  .then( () =>
          {
              self.refreshData();
          })
                  .catch( ( err ) =>
          {
              if( err.message !== 'cancelled' )
                    self.$eventBus.$emit( 'queryError', err );
          });
      },
      putProdToProd( prod )
      {
          var self = this;

          this.$modalDlg.sysDlgs.putProdDlg.open( self.$globalStore.state.prodForPut )
                  .then( ( data ) =>
          {
              return conf.putProdToAUnit( prod, { elemID: data.prod }, 
                    data.location, data.num, self.$globalStore.setActTimestamp() );
          }, () => {} )
                    .then( () => 
          {
              self.$globalStore.clearProdForPut();
              self.refreshData();
          })
                    .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      addOrgToNode( node )
      {
          var self = this;
          
          this.$modalDlg.sysDlgs.addOrgToNodeDlg.open()
                  .then( ( data ) =>
          {
             // console.log( data );
              return conf.addOrgToNode( node, data.org, data.role );
          }, () => {} )
                    .then( () => 
          {
              self.refreshData();
          })
                    .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      putProd( node )
      {
          var self = this;
//          if( !self.$globalStore.state.prodForPut )
//              return;
          
          this.$modalDlg.sysDlgs.putProdDlg.open( self.$globalStore.state.prodForPut, true )
                  .then( ( data ) =>
          {
                return conf.putProdToNode( node, { elemID: data.prod },
                                        self.$globalStore.setActTimestamp() );
          }, () => {} )
                    .then( () => 
          {
              self.$globalStore.clearProdForPut();
              self.refreshData();
          })
                    .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      deleteRel( rel )
      {
          let self = this;
          this.$modalDlg.sysDlgs.confirmDlg.open( 'rel_remove_title', 'rel_remove_msg' )
                  .then( () =>
          {
              return conf.deleteRelNode( rel );
          }, () => {} )
                  .then( () =>
          {
              self.$eventBus.$emit( 'updateCurFolder' );
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      editRel( data )
      {
          let node1 = data.node;
          let node2 = data.rel.related;
          if( data.rel.parent_child === "PARENT" )
          {
              node1 = data.rel.related;
              node2 = data.node;
          }
          let self = this;
          conf.getRelInfo( data.node, data.rel.parent_child, data.rel.role )
                  .then( ( rel ) =>
          {
              rel = rel[ 0 ];
              return self.$modalDlg.sysDlgs.addRelDlg.open( { name1: node1.name, name2: node2.name, 
                            type: data.rel.role, rem: rel.remark } );
          })
                .then( ( info ) =>
          {
              return conf.updateRelInfo( data.rel.instance_id, info.rem );
          }, () => {} )
                .then( ( rez ) =>
          {
              self.refreshData();
          })
                .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      pasteNodeRel( node, asRelating )
      {
          let self = this;
          if( !self.$globalStore.state.nodeForPaste || 
                  self.$globalStore.state.nodeForPaste.instance_id === node.instance_id )
              return;
          
          let node1 = node, node2 = self.$globalStore.state.nodeForPaste;
          if( asRelating )
          {
              node1 = self.$globalStore.state.nodeForPaste;
              node2 = node;
          }
          
          self.$modalDlg.sysDlgs.addRelDlg.open( { name1: node1.name, name2: node2.name } )
                .then( ( data ) =>
          {
              return conf.addRelNode( node1, node2, data.type, data.rem, data.cap1, data.cap2 );
          }, () => {} )
                .then( ( rez ) =>
          {
              self.$globalStore.clearNodeForPaste();
              self.refreshData();
          })
                .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      
      pasteNode( node )
      {
          var self = this;
          if( !self.$globalStore.state.nodeForPaste )
              return;
          
          if( !self.$globalStore.state.copyNode && self.$globalStore.state.nodeForPaste.parent.instance_id === node.instance_id )
          {
              self.$globalStore.clearNodeForPaste();
          }
          else
          {
              let func = () => { return Promise.resolve( 300 ); };
              if( self.$globalStore.state.copyNode )
                  func = () => { return this.$modalDlg.sysDlgs.confirmOneValDlg.open( 'copy_node_title', 'copy_node_msg', 300 ); };
              
              func()
                      .then( tLim => 
              {
                    self.$modalDlg.sysDlgs.progressDlg.open();
                    return conf.moveNode( node, self.$globalStore.state.nodeForPaste, 
                            self.$globalStore.state.copyNode, self.$globalStore.setActTimestamp(), tLim );
              }, () => {} )
                        .then( ( rez ) => 
              {
                  if( rez )
                      self.updateRootVer( rez.updated_node_version );
                    
                  //self.$globalStore.state.nodeForPaste.parent.filled = false;
                  self.$globalStore.clearNodeForPaste();
                  self.refreshData();
              })
                        .catch( ( err ) =>
              {
                  self.$modalDlg.sysDlgs.progressDlg.close();
                  self.$eventBus.$emit( 'queryError', err );
              });
          }
      },
      refreshData( tabType )
      {
          if( this.curModel && this.curModel.version_id )
          {
              this.curModel.filled = false;
              if( this.curModel.prt_id )
                  return this.goInProd( this.curModel );
              else
                return this.goInNode( this.curModel, true, tabType );        
          }
          else
              return this.getPBS();          
      },
      
      procRowMenuCmd( data )
      {
          let self = this;
          
          if( data[ 0 ] === 'deleteNode' )
              self.deleteNode( data[ 1 ] );
          else if( data[ 0 ] === 'renameNodeByAttr' )
              self.renameNodeByAttr( data[ 1 ] );
          else if( data[ 0 ] === 'renameNode' )
              self.renameNode( data[ 1 ] );
          else if( data[ 0 ] === 'createNode' )
              self.createNode( data[ 1 ] );
          else if( data[ 0 ] === 'createReq' )
              self.createReq( data[ 1 ] );
          else if( data[ 0 ] === 'cutNode' )
              self.cutNode( data[ 1 ] );
          else if( data[ 0 ] === 'copyNode' )
              self.copyNode( data[ 1 ] );
          else if( data[ 0 ] === 'pasteNode' )
              self.pasteNode( data[ 1 ] );
          else if( data[ 0 ] === 'pasteNodeRel1' )
              self.pasteNodeRel( data[ 1 ], true );
          else if( data[ 0 ] === 'pasteNodeRel2' )
              self.pasteNodeRel( data[ 1 ], false );
          else if( data[ 0 ] === 'addOrgToNode' )
              self.addOrgToNode( data[ 1 ] );
          else if( data[ 0 ] === 'putProdToNode' )
              self.putProd( data[ 1 ] );
          else if( data[ 0 ] === 'putProdToProd' )
              self.putProdToProd( data[ 1 ] );
          else if( data[ 0 ] === 'copyProd' )
              self.copyProd( data[ 1 ] );
          else if( data[ 0 ] === 'getProdInfo' )
              self.getProdInfo( data[ 1 ] );  
          else if( data[ 0 ] === 'createProd' )
              self.createProd( data[ 1 ] );  
          else if( data[ 0 ] === 'removeProdFromNode' )
              self.removeProdFromNode( data[ 1 ] );
           else if( data[ 0 ] === 'removeProdFromProd' )
              self.removeProdFromProd( data[ 1 ] );
          else if( data[ 0 ] === 'editNode' )
              self.editNode( data[ 1 ] );
          else if( data[ 0 ] === 'showNodeHistory' )
              self.showNodeHistory( data[ 1 ] );
          else if( data[ 0 ] === 'createBaseline' )
              self.createBaseline( data[ 1 ] );
          else if( data[ 0 ] === 'createSubdomain' )
              self.createSubdomain( data[ 1 ] );
          else if( data[ 0 ] === 'deleteSubdomain' )
              self.deleteSubdomain( data[ 1 ] );
          else if( data[ 0 ] === 'subdomainUsers' )
              self.subdomainUsers( data[ 1 ] );
          else if( data[ 0 ] === 'subdomainRoles' )
              self.subdomainRoles( data[ 1 ] );
          else if( data[ 0 ] === 'infoSubdomain' )
              self.infoSubdomain( data[ 1 ] );
          else if( data[ 0 ] === 'checkInFile' )
              self.checkInFile( data[ 1 ] );
          else if( data[ 0 ] === 'addFileToNode' )
              self.addFileToNode( data[ 1 ] );
          else if( data[ 0 ] === 'pasteFileToNode' )
              self.pasteFileToNode( data[ 1 ] );
          else if( data[ 0 ] === 'downloadFile' )
              self.downloadFile( data[ 1 ] );        
          else if( data[ 0 ] === 'openFile' )
              self.openFile( data[ 1 ] );    
          else if( data[ 0 ] === 'convertFile' )
              self.convertFileToWCAX( data[ 1 ], data[ 2 ] ); 
          else if( data[ 0 ] === 'genDocByTmpl' )
              self.genDocByTmpl( data[ 1 ] );        
          else if( data[ 0 ] === 'deleteFile' )
              self.deleteFile( data[ 1 ] );        
          else if( data[ 0 ] === 'checkOutFile' )
              self.checkOutFile( data[ 1 ] );
          else if( data[ 0 ] === 'cancelCheckOutFile' )
              self.cancelCheckOutFile( data[ 1 ] );    
          else if( data[ 0 ] === 'setFileReadOnly' )
              self.setFileReadOnly( data[ 1 ], true );
          else if( data[ 0 ] === 'clearFileReadOnly' )
              self.setFileReadOnly( data[ 1 ], false );
          else if( data[ 0 ] === 'linkFile1' )
              self.linkFileToFile( data[ 1 ], 1 );
          else if( data[ 0 ] === 'linkFile2' )
              self.linkFileToFile( data[ 1 ], 2 );
          else if( data[ 0 ] === 'openNotes' )
              self.openNotes( data[ 1 ] );
          else if( data[ 0 ] === 'openLinks' )
              self.openLinks( data[ 1 ] );
          else if( data[ 0 ] === 'addIssue' )
              self.addIssue( data[ 1 ] );
          else if( data[ 0 ] === 'sendMsg' )
              self.sendMsg( data[ 1 ] );
          else if( data[ 0 ] === 'sendFileMsg' )
              self.sendMsg( data[ 2 ], data[ 1 ] );
          else if( data[ 0 ] === 'gotoNode' )
              self.gotoNode( data[ 1 ], data[ 2 ] );
          else if( data[ 0 ] === 'exportToFile' )
              self.exportToFile();
          else if( data[ 0 ] === 'exportToTxt' )
              self.exportToTxt( data[ 1 ] );
          else if( data[ 0 ] === 'exportToDEX' )
              self.exportToDEX( data[ 1 ] );
          else if( data[ 0 ] === 'importCSVFileToProj' )
              self.importCSVFileToProj( data[ 1 ] );
          else if( data[ 0 ] === 'importFromTxt' )
              self.importFromTxt( data[ 1 ] );
          else if( data[ 0 ] === 'importFile' )
              self.importFile( data[ 1 ] );
          else if( data[ 0 ] === 'importFileToNode' )
              self.importFileToNode( data[ 1 ] );
          else if( data[ 0 ] === 'importReqIFToNode' )
              self.importReqIFToNode( data[ 1 ] );
          else if( data[ 0 ] === 'importAP242FileToNode' )
              self.importAP242FileToNode( data[ 1 ] );
          else if( data[ 0 ] === 'importCSVFileToNode' )
              self.importCSVFileToNode( data[ 1 ] );
          else if( data[ 0 ] === 'importTxtToNode' )
              self.importTxtToNode( data[ 1 ] );
          else if( data[ 0 ] === 'exportNodeToZip' )
              self.exportNodeToZip( data[ 1 ] );
          else if( data[ 0 ] === 'exportNodeToPDM' )
              self.exportToFile( data[ 1 ] );
          else if( data[ 0 ] === 'exportToAP242' )
              self.exportToAP242();
          else if( data[ 0 ] === 'exportNodeToAP242' )
              self.exportToAP242( data[ 1 ] );
          else if( data[ 0 ] === 'setPhase' )
              self.setProjPhase( data[ 1 ] );
          else if( data[ 0 ] === 'incVer' )
              self.incProjVer( data[ 1 ] );
          else if( data[ 0 ] === 'cutFile' )
          {
              var file = data[ 1 ];
              file.parentNode = self.curModel;
              this.$globalStore.clearFileForLink();          
              this.$globalStore.setFileForPaste( file );          
          }
          else if( data[ 0 ] === 'copyFile' )
          {
              var file = data[ 1 ];
              file.parentNode = self.curModel;
              this.$globalStore.clearFileForPaste();          
              this.$globalStore.setFileForLink( file );          
          }
      }
  },
  watch: 
  {
      '$route'( to, from ) 
      {
          let paths = to.path.split( '/' );
          let data = this.$globalStore.state.urlData;
         
          if( paths[ 2 ] === ( '' + data.proj ) )
          {
              if( paths.length > 3 )
              {
                  if( paths[ 3 ] !== ( '' + data.node ) )
                  {
                      conf.getNodePath( paths[ 3 ] )
                              .then( ( rez ) =>
                      {
                          if( rez && rez.tree_path_to_element )
                          {
                               if( paths.length > 4 )
                                   localStorage.setItem( 'fileID', paths[ 4 ] );
                              this.gotoNode( rez.tree_path_to_element );    
                          }
                      });
                  }
              }
              else
                  this.$eventBus.$emit( 'selectedModel' );
          }
          else
              this.$eventBus.$emit( 'openProjFromURL', { paths } );
      }
  },
  mounted: function() 
  { 
      var self = this;
      self.$eventBus.$on( 'selectedModel', function( info )
      {
           self.verList = [];
           self.$globalStore.clearActVer();
           self.$globalStore.clearNodeForPaste();
           self.$globalStore.clearFileForPaste();
           self.$globalStore.clearFileForLink();
           self.getPBS( info );
           
           self.$eventBus.$emit( 'setURLData', { proj: conf.getProj().in_project.guid } );
           //self.$router.push( '/home/' + conf.getProj().in_project.guid );
      });
      self.$eventBus.$on( 'goInProd', function( info )
      {
          self.goInProd( info );
      });
      self.$eventBus.$on( 'goOutProd', function( info )
      {
          self.goOutProd( info );
      });
      self.$eventBus.$on( 'goInNode', function( model )
      {
//           self.nodeList = [];
           self.goInNode( model );
      });
      self.$eventBus.$on( 'goOutNode', function( model )
      {
//          self.nodeList = [];
          self.goOutNode( model );
      });
      self.$eventBus.$on( 'openPath', function( data )
      {
          self.updateModel( data[ 0 ], data[ 1 ] );
      });
      self.$eventBus.$on( 'setActVer', function( data )
      {
          self.setActVer( data );
      });
      self.$eventBus.$on( 'deleteFile', function( file )
      {
          self.deleteFile( file );
      });
      self.$eventBus.$on( 'openBaseline', function( bsl )
      {
          self.openBaseline( bsl );
      });
      self.$eventBus.$on( 'updateProps', function( props )
      {
          self.updateProps( props );
      });
      self.$eventBus.$on( 'uploadAggrPropData', function( props )
      {
          self.uploadAggrPropData( props );
      });
      self.$eventBus.$on( 'downloadAggrPropData', function( props )
      {
          self.downloadAggrPropData( props );
      });
      self.$eventBus.$on( 'updateFileProps', function( props )
      {
          if( props.isClass )
              self.updateFileClass( props );
          else
              self.updateProps( props, 'file' );
      });
      self.$eventBus.$on( 'updateProdProps', function( props )
      {
          self.updateProdProps( props );
      });
      self.$eventBus.$on( 'sortNodeListTab', function()
      {
          self.sortDec = !self.sortDec;
          self.updatePage();
      });
      self.$eventBus.$on( 'updateNodeListTab', function( state )
      {
          self.showOccur = state.showOccur;
          self.showShape = state.showShape;
          self.childOccur = state.childOccur;
          self.refreshData();
      });
      self.$eventBus.$on( 'updateCurFolder', function()
      {
          self.refreshData();
      });
      self.$eventBus.$on( 'editRel', function( data )
      {
          self.editRel( data );
      });
      self.$eventBus.$on( 'deleteRel', function( rel )
      {
          self.deleteRel( rel );
      });
      self.$eventBus.$on( 'procRowMenuCmd', function( data )
      {
          self.procRowMenuCmd( data );
      });
      self.$eventBus.$on( 'propStatus', function( data )
      {
          self.procPropStatus( data );
      });
      
  },   
  beforeDestroy: function()
  {
      this.$eventBus.$off( 'openBaseline' );
      this.$eventBus.$off( 'selectedModel' );
      this.$eventBus.$off( 'goInNode' );
      this.$eventBus.$off( 'goOutNode' );
      this.$eventBus.$off( 'goInProd' );
      this.$eventBus.$off( 'goOutProd' );
      this.$eventBus.$off( 'updateProps' );
      this.$eventBus.$off( 'uploadAggrPropData' );
      this.$eventBus.$off( 'downloadAggrPropData' );
      this.$eventBus.$off( 'updateFileProps' );
      this.$eventBus.$off( 'setActVer' );
      this.$eventBus.$off( 'openPath' );
      this.$eventBus.$off( 'procRowMenuCmd' );
      this.$eventBus.$off( 'sortNodeListTab' );
      this.$eventBus.$off( 'updateNodeListTab' );
      this.$eventBus.$off( 'updateCurFolder' );
      this.$eventBus.$off( 'editRel' );
      this.$eventBus.$off( 'deleteRel' );
      this.$eventBus.$off( 'propStatus' );
  }
}
</script>

<style scoped>
  .scroll 
  {
      overflow-y: auto;
  }
  .smallSize
  {
        height: calc(100vh - 105px);
  }
  .normalSizeWithPage
  {
        height: calc(100vh - 251px);
  }
  .normalSize
  {
        height: calc(100vh - 181px);
  }
  
</style>
