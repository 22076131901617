
export default 
{
    login( API_URL, params )
    {
        let info = { user: params.user, pass: params.password,
            group: params.server.group, server: params.server.address,
            port: params.server.port, clientURL: params.pageURL }; 
        if( params.sso_id )
            info.sso_id = params.sso_id;

        let fd = new FormData();
        fd.append( 'data', btoa( JSON.stringify( info ) ) );
      
        return this.ajaxReq( { url: API_URL + 'admin/token',
                             method: 'POST',
                             dataType: 'json',
                             data: fd,
                             credentials: false, 
                             cache: false,
                             contentType: 'multipart/form-data'
                          } );
    },
    
    logout( API_URL, params )
    {
        return this.ajaxReq( { url: API_URL + 'admin/token/' + params.accessToken,
                             method: 'DELETE',
                             dataType: 'json',
                             credentials: false, 
                             cache: false
                          } );
    },
    
    apiReq( API_URL, params )
    {
        var self = this;
        var url = API_URL + params.path + '/' + params.accessToken;
        if( params.query_prm )
            url += '/?' + params.query_prm;
        
       // url = encodeURI( url );
        if( params.onlyURL )
            return url;
        
        return self.ajaxReq({
                    url: url,
                    dataType: params.dataType || 'json',
                    data: params.data,
                    method: params.method,
                    credentials: params.credentials, 
                    cache: params.cache || false,
                    contentType: params.contentType
                } );
    },
      
    ajaxReq( params )
    {
        let opt = {};
        opt.url = params.url;
            opt.dataType = params.dataType || 'text';
        
        opt.method = params.method || 'GET';
        opt.cache = params.cache || false;
        opt.data = params.data;
        opt.contentType = params.contentType || 'json';

        if( !opt.cache && opt.method === 'GET' )
        {
            if( opt.url.indexOf( '?' ) < 0 )
                opt.url += '?_=' + new Date().getTime();
            else
                opt.url += '&_=' + new Date().getTime();
        }

        let xhr = new XMLHttpRequest();//getXmlHttp();
        let promise = new Promise( function( resolve, reject )
        {
            xhr.onreadystatechange = function()
            {
              if( this.readyState === 4 )
              {
                if( this.status === 200 )
                {
                    var rez = this.response;
                    if( rez && opt.dataType === 'json' )
                    {
                        rez = JSON.parse( this.response );
                        if( rez.error )
                        {
                            var error = new Error( rez.error );
                            error.response = this.response;
                            reject( error );
                        }
                    }
                    resolve( rez );
                }
                else
                {
                    let error = new Error( this.statusText );
                    error.codeHTTP = this.status;
                    error.code = 400;
                    error.response = this.response;
                    reject( error );
                }
              }
            };

            xhr.onerror = function()
            {
              reject( new Error( "Network Error" ) );
            };

            xhr.open( opt.method, opt.url, true);

//            if( 'multipart/form-data' !== opt.contentType )
//                xhr.setRequestHeader( 'Content-Type', opt.contentType );
        
            xhr.setRequestHeader( 'Accept', 'application/json, text/javascript, */*; q=0.01' );

            if( params.credentials )
            {
                xhr.withCredentials = true;
                xhr.mozBackgroundRequest = true;
            }

            if( opt.dataType === 'arraybuffer' || opt.dataType === 'blob' || opt.dataType === 'document')
            {
                xhr.responseType = opt.dataType;
            }

            if( opt.data )
            {
                xhr.send( opt.data );
            }
            else
            {
                xhr.send();
            }

        } );
        promise.xhr = {
          abort: xhr.abort
        };
        return promise;
    }
}

