<template>
    <v-card>
        <v-data-table dense :headers="headers" :items="propVals" sort-by="num" hide-default-footer class="elevation-1 propTbl" 
                    :items-per-page=-1 fixed-header height="calc( ( 100vh - 229px ) / 2 )" group-by="groupData">
          <template v-slot:header.num="{ header }">
            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
          </template>
          <template v-slot:header.name="{ header }">
            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
          </template>
          <template v-slot:header.val="{ header }">
            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
          </template>
          <template v-slot:header.type="{ header }">
            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
          </template>
          <template v-slot:header.act="{ header }">
              <span></span>
          </template>
          <template v-slot:group.header="{ items, isOpen, toggle }">
            <th colspan="7" v-if="items[ 0 ].groupInfo && ( !isOpen || !items[ 0 ].cols )">
              <v-icon @click="toggle">
                  {{ isOpen ? 'remove' : 'add' }}
              </v-icon>
              {{ items[0].groupInfo }}
            </th>
            <th v-if="items[ 0 ].groupInfo && isOpen && items[ 0 ].cols">
              <v-icon @click="toggle">
                  {{ isOpen ? 'remove' : 'add' }}
              </v-icon>
              {{ items[0].groupInfo }}
            </th>
            <th v-if="items[ 0 ].groupInfo && isOpen && items[ 0 ].cols">
              {{ items[0].cols[ 0 ] }}
            </th>
             <th v-if="items[ 0 ].groupInfo && isOpen && items[ 0 ].cols">
              {{ items[ 0 ].cols.length > 1 ? items[0].cols[ 1 ] : '' }}
            </th>
            <th v-if="items[ 0 ].groupInfo && isOpen && items[ 0 ].cols">
              {{ items[ 0 ].cols.length > 2 ? items[0].cols[ 2 ] : '' }}
            </th>
            <th v-if="items[ 0 ].groupInfo && isOpen && items[ 0 ].cols">
                <span v-if="items[ 0 ].groupDataVal === 'orgs'">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" small @click="openOrgs()">info_outline</v-icon>
                        </template>
                        <span>{{ $t( 'message.prop_info_btn' ) }}</span>
                    </v-tooltip>
                </span>
                <span v-if="items[ 0 ].groupDataVal === 'rels'">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" small @click="openRels()">info_outline</v-icon>
                        </template>
                        <span>{{ $t( 'message.prop_info_btn' ) }}</span>
                    </v-tooltip>
                </span>
                <span v-else></span>
            </th>
          </template>
          <template v-slot:item="{ item }">
            <tr v-if="item.groupDataVal === 'parents'">
                <td>{{ item.num }}</td>
                <td colspan="3">
                    <span>{{ item.val }}</span>
                </td>
                <td>
                    <v-tooltip bottom v-if="item.obj.node_id !== globalState.curFolderInfo.instance_id">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" small @click="gotoParent( item.obj )">visibility</v-icon>
                        </template>
                        <span>{{ $t( 'message.prop_rel_btn' ) }}</span>
                    </v-tooltip>
                </td>
            </tr>
            <tr v-else>
              <td>{{ item.num }}</td>
              <td :class="{ mandatory_field: item.isMandatory }">
                  <span :class="{ deleted_prop: item.deleted }">{{ item.name }}</span> 
                  <span v-if="item.unit">({{ item.unit }})</span>
              </td>
              <td>
                  <span v-if="item.url_data">
                      <span v-for="data in item.url_data">
                          <a v-if="data.isURL" :href="data.val" target="_blank" rel="noopener noreferrer" >{{ data.val }}</a>
                      </span>
                  </span>
                  <span v-else-if="item.isList"><a @click="openListGr( item )">{{ item.val_view }}</a></span>
                  <rels-row-view v-else-if="item.val && ( item.chRels || item.prRels )" 
                           :prRels="item.prRels" :chRels="item.chRels" @openRels="openRels"></rels-row-view>
                  <span v-else-if="item.val && item.val.length && item.val.length > 0 && item.val[ 0 ].ass_id">
                      <a @click="openOrgs( item.val )">{{ item.val.length }} elements</a>
                  </span>
                  <span v-else-if="!item.val_view && item.isMandatory" class="mandatory_value"></span>
                  <span v-else-if="item.name === 'Material'"><a @click="openMaterial( item )">{{ item.val_view }}</a></span>
                  <span v-else>{{ item.val_view }}</span>
              </td>
              <td>{{ item.type_view }}</td>
              <td v-if="( !globalState.actVer && ( item.edit || item.msg || item.class || item.groupData ) ) || item.name === 'Hash'">
                  <span style="white-space: nowrap;">
                    <v-tooltip bottom v-if="item.isList && model.permissions && model.permissions.can_i_write">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon small class="mr-2" @click="uploadData( item )" v-bind="attrs" v-on="on">vertical_align_top</v-icon>
                        </template>
                        <span>{{ $t( 'message.prop_upload_btn' ) }}</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="item.isList">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon small class="mr-2" @click="downloadData( item )" v-bind="attrs" v-on="on">vertical_align_bottom</v-icon>
                        </template>
                        <span>{{ $t( 'message.prop_download_btn' ) }}</span>
                    </v-tooltip>
                    <v-tooltip bottom v-else>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-show="model.permissions && model.permissions.can_i_write && item.name !== 'Hash' && !item.groupData" 
                                    small class="mr-2" @click="editItem(item)" v-bind="attrs" v-on="on">edit</v-icon>
                        </template>
                        <span>{{ $t( 'message.prop_edit_btn' ) }}</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-if="item.name !== 'Quantity' && !item.msg && !item.class && !item.groupData
                                    && item.val !== undefined && model.permissions && model.permissions.can_i_write" small 
                                    @click="deleteItem(item)" v-bind="attrs" v-on="on">clear</v-icon>
                        </template>
                        <span>{{ $t( 'message.prop_clear_btn' ) }}</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-if="item.name === 'Hash'" v-bind="attrs" v-on="on" small @click="calcHash()">restore</v-icon>
                        </template>
                        <span>{{ $t( 'message.prop_calc_btn' ) }}</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="item.groupDataVal === 'rels'">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" small @click="gotoRel( item.obj )">visibility</v-icon>
                        </template>
                        <span>{{ $t( 'message.prop_rel_btn' ) }}</span>
                    </v-tooltip>
                  </span>
              </td>
              <td v-else>
              </td>
            </tr>
        </template>
      </v-data-table>
    </v-card>
</template>

<script>
//import EventBus from './Bus.vue'
import{ conf } from '../utils/confTruePLM';

export default {
  name: 'PnlDocProps',
  props: {
      forSys: false,
      data: null
  },
  data () {
    return {
        globalState: this.$globalStore.state,
        headers: [
            { text: 'message.prop_num_col', value: 'num', width: '15%', align: 'right' },
            { text: 'message.prop_name_col', value: 'name', width: '20%' },
            { text: 'message.prop_value_col', value: 'val' }, 
            { text: 'message.prop_type_col', value: 'type', width: '15%' }, 
            { width: '5%', sortable: false, val: 'act' }
        ],
        propVals : [],
        model: {},
        node: { permissions: {} },
        propList: [ { title: 'Title', value: 'title', class_name: 'title' }, 
                    { title: 'Name', value: 'file_name' }, 
                    { title: 'Description', value: 'description' },
//                    { title: 'ID', value: 'id' }, 
                    { title: 'Size', value: 'size' },
                    { title: 'Created by', value: 'created_by_user' },
                    { title: 'Created date', value: 'date_created' },
                    { title: 'Last modified by', value: 'last_ver_user' },
                    { title: 'Last modified date', value: 'last_ver_date' },
                    { title: 'Version', value: 'last_ver_id' },
                    { title: 'Phase', value: 'last_ver_phase' },
                    { title: 'Approver', value: 'approver', class: 'Approver', is_user: true },
                    { title: 'Release manager', value: 'release_manager', class: 'Release manager', is_user: true },
                    { title: 'Responsible', value: 'responsible', class: 'Responsible', is_user: true },
                    { title: 'Reviewer', value: 'reviewer', class: 'Reviewer', is_user: true },
                    { title: 'Discipline', value: 'discipline', class: 'urn:rdl:epm-std:project_discipline' },
                    { title: 'External version', value: 'external_version', class_name: 'ext_ver' },
                    { title: 'Format', value: 'data_type' },
                  //  { title: 'Project phase', value: 'project_phase', class: 'urn:rdl:epm-std:project_phase' },
                    { title: 'RID references', value: 'rid', class_name: 'rid'  },
                    { title: 'Source', value: 'source', class: 'urn:rdl:epm-std:Source_of_information' },
                    { title: 'Status', value: 'status', class: 'urn:rdl:epm-std:Document_status' },
                    { title: 'Type', value: 'document_type', class: 'urn:rdl:epm-std:Document_content_type' }
                  ]
    };
  },
  methods: 
  {
      editItem( item )
      {
          let self = this;
          let propList = [];
          if( item.is_user )
          {
              conf.getProjectUsersEnum()
                  .then( ( data ) =>
              {
                  let opt = [];
                  data.forEach( ( elem ) =>
                  {
                      opt.push( elem.val );
                  });
                  item.obj = { type: item.class, options: opt };
                  item.type = item.class;
                  self.openUpdateProp( item, propList );
              })
                .catch( ( err ) =>
              {
                    self.$eventBus.$emit( 'queryError', err );
              });     
          }
          else if( item.class === 'urn:rdl:epm-std:Document_content_type' || item.class === 'urn:rdl:epm-std:Document_status' )
          {
              let opts = [];
              conf.getChildTypesInContext( item.class, item.val )
                  .then( data =>
                {
                  self.$utils.getEnum( data, item.val, true ).forEach( ( opt ) =>
                  {
                      opts.push( opt.val );
                  });
                  item.obj = { type: item.class, domain: 'urn:owl:oneOf', options: opts };
                  self.openUpdateProp( item, propList );
                })
                    .catch( ( err ) =>
                {
                      self.$eventBus.$emit( 'queryError', err );
                });      
                item.type = item.class;
          }
          else if( item.class )
          {
              let opts = [];
              conf.getChildTypes( item.class )
                      .then( ( data ) =>
              {
                  self.$utils.getEnum( data ).forEach( ( opt ) =>
                  {
                      opts.push( opt.val );
                  });
                  item.obj = { type: item.class, domain: 'urn:owl:oneOf', options: opts };
                  self.openUpdateProp( item, propList );
              })
                    .catch( ( err ) =>
              {
                    self.$eventBus.$emit( 'queryError', err );
              });      
              item.type = item.class;
          }
          else if( item.class_name )
          {
              item.obj = { type: item.class_name, domains: [ 'urn:plcs:rdl:TruePLM:Text_value' ] };
              item.type = item.class_name;
              this.openUpdateProp( item, propList );
          }
          else
          {
              propList = this.$globalStore.state.propEnums.fileProps;
              this.openUpdateProp( item, propList );
          }
      },
      
      openUpdateProp( item, propList )
      {
          let self = this;
          this.$modalDlg.sysDlgs.assignPropDlg.open( item, propList )
                    .then( ( rez ) =>
          {
              self.$eventBus.$emit( 'updateFileProps', { node: self.model, props: [ item ], isClass: item.class || item.class_name } ); 
          }, () => {} );
      },
      
      deleteItem( item )
      {
          var self = this;
          this.$modalDlg.sysDlgs.confirmDlg.open( 'prop_clear_title', 'prop_clear_msg' )
                  .then( () =>
          {
              item.delete = true;
              self.$eventBus.$emit( 'updateFileProps', { node: self.model, props: [ item ] } ); 
//              self.hasChanges = true;
          }, () => {} );
      },
      
      async procModel()
      {
          let self = this;
          let ind = 1;
          let emptyFields = [];
          let pVals = [];
          if( this.forSys )
          {
                let tView = 'T';
                self.propList.forEach( ( item ) =>
                {
                    let urlData = null;
                    let val = self.model[ item.value ] || '';
                    let valView = val;
                    if( item.value.indexOf( 'date' ) < 0 )
                    {
                        //val = self.$utils.clearType( self.model[ item.value ] );
                        valView = this.$utils.clearType( val );
                        if( item.parse )
                            urlData = this.$utils.getURLData( val );
                        if( item.value === 'size' )
                        {
                            val = Math.round( val * 1000 / 1024 / 1024 ) / 1000;
                            valView = val + ' Mbytes';
                        }
                    }
                    else if( val )
                    {
                        val = this.$utils.getLocalDateFromUTC( val );
                        valView = val;
                    }
                    
                    pVals.push( { num: ind++, name: item.title, val: val, val_view: valView, msg: item.msg, 
                        class: item.class, class_name: item.class_name, url_data: urlData, type_view: tView, is_user: item.is_user } );
                });
                let aDataInfo = [ { aData: 'ids', title: 'Identifiers', nameFld: 'role_name', valFld: 'id', 
                                    addValFld: 'org_context', cols: [ 'Role', 'ID', 'Context' ] },
                                { aData: 'classified_as', title: 'Classified by', nameFld: 'role_name', 
                                        valFld: 'class_name', cols: [ 'Role', 'Class' ] },
                                { aData: 'described_as', title: 'Described as', nameFld: 'role_name', 
                                        valFld: 'text', cols: [ 'Role', 'Text' ] },
                                { aData: 'datetimes', title: 'Timestamps', nameFld: 'role_name', 
                                        valFld: 'dt_text', cols: [ 'Role', 'Date' ] },
                                { aData: 'persons', title: 'Persons', nameFld: 'role_name', valFld: 'person',
                                    addValFld: 'org', cols: [ 'Role', 'Name', 'Organisation' ] },
                                
                                { aData: 'rels', title: 'Links', nameFld: 'caption', addValFld: 'parent_child',
                                        valFld: 'related.name', cols: [ 'Role', 'Node', 'Type' ] },
                                { aData: 'parents', title: 'Parents', valFld: 'node_name', cols: [ 'Path' ] },
                                { aData: 'orgs', title: 'Organisations', nameFld: 'caption', 
                                        valFld: 'organization.title', cols: [ 'Role', 'Organisation' ] }];
                ind = 0;
                aDataInfo.forEach( info => 
                {
                    if( this.model[ info.aData ] )
                    {
                        ind++;
                        this.model[ info.aData ].forEach( el =>
                        {
                            let val = this.$utils.getValByPath( el, info.valFld );
                            if( info.aData === 'datetimes' )
                                val = this.$utils.getLocalDateFromUTC( val );
                            else if( info.aData === 'parents' && el.paths[ 0 ] )
                                val = el.paths[ 0 ].names.join( ' / ' );
                            
                            let addVal = this.$utils.getValByPath( el, info.addValFld );
                            if( info.aData === 'rels' )
                                addVal = ( addVal === 'CHILD' ) ? 'direct' : 'backward';
                            
                            pVals.push( { groupData: ind + '_' + info.aData, groupInfo: info.title, 
                                name: this.$utils.getValByPath( el, info.nameFld ), obj: el, groupDataVal: info.aData,
                                val_view: val, val: val, type_view: addVal, type: addVal, cols: info.cols } );
                        });
                    }
                } );

                self.propVals = pVals;
                
//                for( let i = self.model.versions.length - 1; i >= 0; i-- )
//                {
//                    let hash = await conf.getHash( self.model.versions[ i ].instance_id );
//                    
//                    if( hash && hash.result )
//                    {
//                        self.propVals.push( { num: ind, name: 'Hash', val: hash.result, 
//                            val_view: hash.result, type_view: 'T' } );
//                        
//                        break;
//                    }
//                }
                
                let hash = await conf.getHash( self.model.instance_id );
                if( hash && hash.result )
                    self.propVals.push( { num: ind, name: 'Hash', val: hash.result, 
                        val_view: hash.result, type_view: 'T' } );
          }
          
          if( !this.forSys )
          {
                let usedProps = [];
                for( let i = 0; self.model.properties && i < self.model.properties.length; i++ )
                    usedProps.push( i );
                
                conf.getPropsByName( self.model.document_type )
                        .then( ( fullPList ) =>
                {
                      for( var i = 0; i < fullPList.length; i++ )
                      {
                          let isList = false;
                          let item = fullPList[ i ];
                          let item_val = {};
                          if( self.model.properties )
                          {
                              for( var j = 0; j < self.model.properties.length; j++ )
                              {
                                  if( this.$utils.noGUID( self.model.properties[ j ].name ) === item.urn )
                                  {
                                      item_val = self.model.properties[ j ];
                                      usedProps[ j ] = -1;
                                      break;
                                  }
                              }
                          }
                          
                          let tView = this.$utils.getPropType( item.domains ) || 'T';
                          
                          let pVal = this.$utils.procPropVal( item_val.val, tView );

                          let urlData = null;
                      
                          if( pVal && tView === 'N' )
                          {
                              item_val.val = item_val.num_val;
                              pVal = item_val.num_val.toString();
                          }
                          else if( tView === 'A' )
                          {
                              isList = true;
                          }
                          else
                            urlData = this.$utils.getURLData( pVal );    

                          if( this.$utils.isDerivedProp( item.super_types ) )
                              tView = 'D' + tView;
                        
                          let propObj = { num: ind++, name: self.$utils.clearType( item.urn ), type: item.urn, unit_code: item_val.unit, type_view: tView,
                                  val_view: pVal, val: item_val.val, unit: self.$utils.clearType( item_val.unit ), url_data: urlData, isList: isList,
                                  isMandatory: !item.optional_flag,
                                  edit: ( !item.expression && ( !( item.editable_for && item.editable_for.length > 0 ) || 
                                            this.globalState.curUserRole === 'urn:rdl:epm-std:project_manager' ) ), obj: item };
                          
                          pVals.push( propObj );    
                      }
                      self.propVals = pVals;
                      
                      if( self.$globalStore.state.actVer )
                      {
                          usedProps.forEach( i =>
                          {
                              if( i > -1 )
                              {
                                  let prop = self.model.properties[ i ];
                                  let tView = this.$utils.getPropType( ( prop.value_domain || '' ).split( ',' ) ) || 'T';
                                  let pVal = this.$utils.procPropVal( prop.val, tView );

                                  let urlData = null;
                                  let isList = false;

                                  if( pVal && tView === 'N' )
                                  {
                                        prop.val = prop.num_val;
                                        pVal = prop.num_val.toString();
                                  }
                                  else if( tView === 'A' )
                                  {
                                        isList = true;
                                  }
                                  else
                                    urlData = this.$utils.getURLData( pVal );    

                                  if( prop.is_derived )
                                      tView = 'D' + tView;
                          
                                  let propObj = { num: ind++, name: self.$utils.clearType( prop.name ), type: prop.name, 
                                      unit_code: prop.unit, type_view: tView, val_view: pVal, val: prop.val, 
                                      unit: self.$utils.clearType( prop.unit ), url_data: urlData, isList: isList,
                                      edit: false, obj: {}, isMandatory: false, deleted: true };
                                  self.propVals.push( propObj );    
                              }
                          });
                      }
                      
                     // self.$eventBus.$emit( 'propStatus', { emptyFields: emptyFields, node: self.model } );
                 })
                        .catch( ( err ) =>
                 {
                     self.$eventBus.$emit( 'queryError', err );
                 });     
          }
      },
      updateFile( data )
      {
          if( !data )
              return;

          this.model = data.file;
          this.node = data.node;
          if( this.model && this.model.file_name )
             this.procModel();
          else
              this.propVals = [];
      }
  },
  watch: {
      data( val ) 
      {
          this.updateFile( this.data );
      }
  },
  mounted: function() 
  { 
      var self = this;
      
      this.$eventBus.$on( 'selectedModel', function()
      {
          self.model = {};
          self.propVals = [];
      });
      
      this.$eventBus.$on( 'updateNode', function()
      {
          self.propVals = [];
      });
      
      self.updateFile( self.data );
  },   
  beforeDestroy: function()
  {
      this.$eventBus.$off( 'selectedModel' );
      this.$eventBus.$off( 'updateNode' );
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .propTbl td
    {
        height: 30px;
    }
</style>
