<template>
    <div>
        <v-card style="height: calc(100vh - 181px); overflow-y: auto;">
            <v-list dense>
                <v-list-item v-for="( item, index ) in vOrgs" :key="item.org_id" @click="selectOrg( item )">
                    <v-list-item-content>
                      <v-list-item-title v-bind:class="{ select: ( selOrg.org_id === item.org_id ) }">
                          {{item.title || item.uid}}
                      </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-for="( item, index ) in iOrgs" :key="item.org_id" @click="selectOrg( item )">
                    <v-list-item-content>
                      <v-list-item-title v-bind:class="{ select: ( selOrg.org_id === item.org_id ) }">
                          <span class="invalid">{{item.title || item.uid}}</span>
                      </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card>
    </div>
</template>

<script>

export default {
  name: 'OrgList',
  props: {
      vOrgs: null,
      iOrgs: null,
      selOrg: null
  },
  data () {
    return {
        globalState: this.$globalStore.state
    };
  },
  components: {
  },
  methods: 
  {
      selectOrg( org )
      {
          this.$emit( 'selectOrg', org );
      }
  },
  mounted: function() 
  { 
  },   
  beforeDestroy: function()
  {
  }
}
</script>

<style scoped>
  .scroll 
  {
      overflow-y: auto;
  }
  .select
  {
      font-weight: 700 !important;
  }
  .invalid
  {
      font-style: italic;
  }
</style>
