<template>
        <v-dialog v-model="visFl" persistent max-width="290">
           <v-card>
            <v-card-title class="headline" v-if="edit">
              {{ $t( 'message.edit_rel_title' ) }}
            </v-card-title>
            <v-card-title class="headline" v-else>
              {{ $t( 'message.add_rel_title' ) }}
            </v-card-title>
            <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-select :label="$t( 'message.add_rel_type' )" required item-text="txt" item-value="val" v-model="selectedType"
                        :items="types" :rules="[v => !!v || $t( 'message.value_required' )]" :disabled="edit"></v-select>
                    <v-text-field :label="$t( 'message.add_rel_name1' )" v-model="name1" disabled></v-text-field>
                    <v-text-field v-if="cap1" :label="$t( 'message.add_rel_cap1' )" v-model="cap1" disabled></v-text-field>
                    <v-text-field :label="$t( 'message.add_rel_name2' )" v-model="name2" disabled></v-text-field>
                    <v-text-field v-if="cap2" :label="$t( 'message.add_rel_cap2' )" v-model="cap2" disabled></v-text-field>
                    
                    <v-textarea :label="$t( 'message.add_rel_rem' )" v-model="rem"></v-textarea>
                </v-form>
                <!--<small>*{{ $t( 'message.req_info_msg' ) }}</small>-->
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
                <v-btn color="green darken-1" text :disabled="!valid" @click.native="doAct()">{{ $t( 'message.ok_btn' ) }}</v-btn>
                <v-btn color="green darken-1" text @click.native="close()">{{ $t( 'message.cancel_btn' ) }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>   
</template>

<script>
    
import Vue from 'vue'
import{ conf } from '@/utils/confTruePLM';

export default {
  name: 'addRel',
  data () 
  {
    return {
        visFl: false,
        name1: '',
        name2: '',
        cap1: '',
        cap2: '',
        rem: '',
        types: [],
        selectedType: '',
        resolve: null,
        reject: null,
        valid: true,
        edit: false
    };
  },
  computed: {
  },
  watch: {
      selectedType() 
      {
          this.fillCaps();
      }
  },
  methods: {
      fillCaps()
      {
          if( !this.selectedType )
              return;
        //  console.log( this.selectedType );
          for( let i = 0; i < this.types.length; i++ )
          {
              if( this.types[ i ].val === this.selectedType )
              {
                  this.cap1 = this.types[ i ].cap1;
                  this.cap2 = this.types[ i ].cap2;
                  break;
              }
          }
      },
      fillTypes( types )
      {
          types.forEach( ( elem ) =>
          {
              let cap1 = '', cap2 = '';
              if( elem.description )
              {
                    let caps = elem.description.split( ':' ); 
                    cap1 = caps[ 0 ];
                    cap2 = caps.length > 1 ? caps[ 1 ] : '';
              }
              this.types.push( { val: elem.urn, cap1: cap1, cap2: cap2,
                                 txt: elem.name } );  
              if( elem.children && elem.children.length > 0 )
                  this.fillTypes( elem.children );
          });  
      },
      open( info )
      {
          this.types = [];
          let self = this;
          conf.getTypesTree( 'urn:plcs:rdl:std:Product_version_relationship' )
                  .then( ( data ) =>
          {
              self.fillTypes( data );
              self.fillCaps();
          });
          
          this.cap1 = null;
          this.cap2 = null;
              
          if( info )
          {
              this.name1 = info.name1;
              this.name2 = info.name2;
              this.rem = info.rem;
              this.selectedType = info.type ? this.$utils.noGUID( info.type ) : null;
          }
          else
          {
              this.name = null;
              this.name = null;
              this.rem = null;
              this.selectedType = null;
          }

          this.edit = !!this.selectedType;
          
          this.visFl = true;
          
          return new Promise( ( resolve, reject ) => 
          {
            this.resolve = resolve;
            this.reject = reject;
          });
      },
      close()
      {
          this.visFl = false;
          this.reject();
      },
      doAct()
      {
          if( this.$refs.form.validate() )
          {
            this.visFl = false;
            this.resolve( { rem: this.rem, type: this.selectedType, cap1: this.cap1, cap2: this.cap2 } );
          }
      }
    }
}
</script>



