<template>
    
            <v-dialog v-model="visFl" persistent max-width="1100">
                <v-toolbar flat color="white">
                    <v-toolbar-title >{{ $t( title ) }}</v-toolbar-title>
               </v-toolbar>
               <v-card>
                <v-card-text>
                    <v-data-table dense :headers="headers" :items="repData" hide-default-footer class="elevation-1 propTbl" 
                                  :items-per-page=-1 fixed-header>
                        <template v-slot:header.info="{ header }">
                            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
                        </template>
                        
                        <template v-slot:item="{ item }">
                            <tr>
                              <td>{{ item }}</td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="green darken-1" text @click.native="save()">{{ $t( 'message.save_btn' ) }}</v-btn>
                  <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click.native="close()">{{ $t( 'message.close_btn' ) }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>   
    
</template>

<script>
    
import Vue from 'vue'
import{ conf } from '../../utils/confTruePLM';

export default {
  name: 'report',
  data () 
  {
    return {
        globalState: this.$globalStore.state,
        visFl: false,
        headers: [
            { text: 'message.rep_info_col', value: 'info' }
        ],
        repData: [],
        resolve: null,
        reject: null,
        title: ''
    };
  },
  computed: {
  },
  methods: {
      save()
      {
           let blob = new Blob( [ this.repData.join( '\r\n' ) ], { type: "text/plain;charset=utf-8" } );
           this.$utils.saveFile( blob, 'report.txt', true );
      },
      open( data, title )
      {
          let self = this;
          
          this.title = 'message.' + ( title || 'rep_list_title' );
          
          self.repData = [];
          if( data )
              self.repData = data;
          
          self.visFl = true;
            
          return new Promise( ( resolve, reject ) => 
          {
              self.resolve = resolve;
              self.reject = reject;
          });          
      },
      close()
      {
          this.visFl = false;
          this.resolve();
      }
    }
}
</script>

<style scoped>
    .propTbl td
    {
        height: 30px;
    }        
</style>



