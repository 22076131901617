<template>
   
        <div>
            <v-banner v-if="!hideCookies" color="light-green" style="z-index: 10; bottom: 1px; position: absolute; width: 100%;">
                {{ $t( 'message.cookies_banner' ) }}
                <template v-slot:actions>
                  <v-btn text color="primary" @click="closeCookies()">{{ $t( 'message.cookies_banner_close' ) }}</v-btn>
                </template>
            </v-banner>
            
            <node-del-hinder-view ref="nodeDelHinderDlg"></node-del-hinder-view>
            <add-p2p-msg-view ref="addP2PMsgDlg"></add-p2p-msg-view>
            <user-noti-view ref="userNotiDlg"></user-noti-view>
            <search-one-val-view ref="searchOneValDlg"></search-one-val-view>
            <set-user-pass-view ref="setUserPassDlg"></set-user-pass-view>
            <confirm-token-view ref="confirmTokenDlg"></confirm-token-view>
            <confirm-2fa-view ref="confirm2faDlg"></confirm-2fa-view>
            <set-2fa-view ref="set2faDlg"></set-2fa-view>
            <gen-doc-view ref="genDocDlg"></gen-doc-view>
            <sub-info-view ref="subInfoDlg"></sub-info-view>
            <select-role-view ref="selectRoleDlg"></select-role-view>
            <assign-prop-view ref="assignPropDlg"></assign-prop-view>
            <put-prod-view ref="putProdDlg"></put-prod-view>
            <delete-doc-view ref="deleteDocDlg"></delete-doc-view>
            <create-prod-view ref="createProdDlg"></create-prod-view>
            <create-node-view ref="createNodeDlg"></create-node-view>
            <create-req-view ref="createReqDlg"></create-req-view>
            <create-baseline-view ref="createBaselineDlg"></create-baseline-view>
            <create-subdomain-view ref="createSubdomainDlg"></create-subdomain-view>
            <add-file-node-view ref="addFileToNodeDlg"></add-file-node-view>
            <checkin-file-view ref="checkInFileDlg"></checkin-file-view>
            <qsearch-node-view ref="qSearchNodeDlg"></qsearch-node-view>
            <search-node-view ref="searchNodeDlg"></search-node-view>
            <search-doc-view ref="searchDocDlg"></search-doc-view>
            <search-doc-txt-view ref="txtSearchDocDlg"></search-doc-txt-view>
            <baseline-list-view ref="baselineListDlg"></baseline-list-view>
            <token-list-view ref="tokenListDlg"></token-list-view>
            <rels-list-view ref="relsListDlg"></rels-list-view>
            <orgs-list-view ref="orgsListDlg"></orgs-list-view>
            <props-list-view ref="propsListDlg"></props-list-view>
            <add-org-to-node-view ref="addOrgToNodeDlg"></add-org-to-node-view>
            <rep-list-view ref="repListDlg"></rep-list-view>
            <file-history-view ref="fileHistoryDlg"></file-history-view>
            <node-history-view ref="nodeHistoryDlg"></node-history-view>
            <add-rel-view ref="addRelDlg"></add-rel-view>
            <make-diff-view ref="makeDiffDlg"></make-diff-view> 
            <create-proj-view ref="createProjDlg"></create-proj-view>
            <import-file-view ref="importFileToProjDlg"></import-file-view>
            <add-issue-view ref="addIssueDlg"></add-issue-view>
            <issue-list-view ref="issueListDlg"></issue-list-view>
            
            <context-menu id="context-menu" ref="ctxMenu" style="padding-left: 20px;">
                <li @click="sortTab()" v-show="menuTab === 1" class="v-list-item v-list-item--link theme--light close-menu cMenuCor">{{ $t( 'message.tab_menu_sort' ) }}</li>
<!--                <li @click="switchOccur()" v-show="menuTab === 1" class="v-list-item v-list-item--link theme--light close-menu cMenuCor">
                    {{ showOccur ? $t( 'message.tab_menu_no_occur' ) : $t( 'message.tab_menu_occur' ) }}
                </li>
                <li @click="switchShape()" v-show="menuTab === 1" class="v-list-item v-list-item--link theme--light close-menu cMenuCor">
                    {{ showShape ? $t( 'message.tab_menu_no_shape' ) : $t( 'message.tab_menu_shape' ) }}
                </li>
                <li @click="switchChildOccur()" v-show="menuTab === 1" class="v-list-item v-list-item--link theme--light close-menu cMenuCor">
                    {{ childOccur ? $t( 'message.tab_menu_no_child_occur' ) : $t( 'message.tab_menu_child_occur' ) }}
                </li>-->
                <li  v-show="menuTab === 1" class="v-list-item v-list-item--link theme--light close-menu cMenuCor">
                    <v-checkbox dense v-model="showOccur" :label="$t( 'message.tab_menu_occur' )" 
                                class="check_correction" @change="updateMode()"></v-checkbox>
                </li>
                <li  v-show="menuTab === 1" class="v-list-item v-list-item--link theme--light close-menu cMenuCor">
                    <v-checkbox dense v-model="showShape" :label="$t( 'message.tab_menu_shape' )" 
                                class="check_correction" @change="updateMode()"></v-checkbox>
                </li>
                <li  v-show="menuTab === 1" class="v-list-item v-list-item--link theme--light close-menu cMenuCor">
                    <v-checkbox dense v-model="childOccur" :label="$t( 'message.tab_menu_child_occur' )" 
                                class="check_correction" @change="updateMode()"></v-checkbox>
                </li>
                <li @click="exportTabXLSX()" v-show="menuTab > 1" class="v-list-item v-list-item--link theme--light close-menu cMenuCor">{{ $t( 'message.tab_menu_export_xslx' ) }}</li>
                <li @click="exportTabCSV()" v-show="menuTab > 1" class="v-list-item v-list-item--link theme--light close-menu cMenuCor">{{ $t( 'message.tab_menu_export_csv' ) }}</li>
                <li @click="exportTabJSON()" v-show="menuTab > 1" class="v-list-item v-list-item--link theme--light close-menu cMenuCor">{{ $t( 'message.tab_menu_export_json' ) }}</li>
                <li @click="closeTab()" v-show="menuTab > 1" class="v-list-item v-list-item--link theme--light close-menu cMenuCor">{{ $t( 'message.tab_menu_close' ) }}</li>
            </context-menu>
            
            <context-menu id="context-menu-for-tree" ref="treeMenu" style="padding-left: 20px;">
                <li @click="gotoTreeNode()" class="v-list-item v-list-item--link theme--light close-menu cMenuCor">{{ $t( 'message.menu_file_goto' ) }}</li>
                <li @click="copyTreeNode()" class="v-list-item v-list-item--link theme--light close-menu cMenuCor">{{ $t( 'message.menu_copy' ) }}</li>
            </context-menu>
            
            <v-snackbar v-model="showUp" multi-line :timeout="timeout" color="info" top>
                {{ $t( 'message.change_note_msg' ) }}
                <v-btn dark text @click="showUp = false">{{ $t( 'message.ignore_btn' ) }}</v-btn>
                <v-btn dark text @click="getUpdate">{{ $t( 'message.update_btn' ) }}</v-btn>
            </v-snackbar>
        
            <nav-models-view></nav-models-view>
            <tool-bar-view></tool-bar-view>    
            <v-main class="main_correction">
                
                <v-container fill-height fluid v-show="globalState.curFolderName" style="padding-top: 0px; padding-bottom: 24px; margin-top: -5px;">
                 <v-layout row wrap>
                  <v-flex >
                      <path-el-view></path-el-view>
                  </v-flex>

                  <Split v-if="$vuetify.breakpoint.mdAndUp" :gutterSize="5" style="height: calc(100vh - 120px); overflow-y: hidden;" ref="mainSplit">
                    <SplitArea :size="hSizeL">
                      <v-card height="95%">
                          <v-tabs v-model="active" show-arrows ref="tabs" @change="tabClick">
                            <v-tab href="#tab-1" @contextmenu.prevent="cMenuOpen( $event,1 )" @click="selectCurFolder">
                                <span style="overflow: hidden; text-overflow: ellipsis;">{{ globalState.curFolderName }}</span>
                                <v-icon color="yellow darken-2" v-show="globalState.readOnly">phonelink_lock</v-icon>
                                <v-icon v-show="globalState.curFolderIsSubdomain === 'root'" color="green">domain</v-icon>
                                <!--<v-icon v-show="globalState.curFolderIsSubdomain === 'part'" small color="green">domain</v-icon>-->
                            </v-tab>
                            <v-tab href="#tab-2" v-show="docSearchRez" @contextmenu.prevent="cMenuOpen( $event, 2 )">{{ $t( 'message.search_doc_tab' ) }}</v-tab>
                            <v-tab href="#tab-3" v-show="nodeSearchRez" @contextmenu.prevent="cMenuOpen( $event, 3 )">{{ $t( 'message.search_node_tab' ) }}</v-tab>
                            <!--<v-tab href="#tab-4" v-show="prodTabShow" @contextmenu.prevent="cMenuOpen( $event, 4 )">{{ $t( 'message.prod_list_tab' ) }}</v-tab>-->
                            <v-tab-item value="tab-1">
                                <node-list-view></node-list-view>
                            </v-tab-item>
                            <v-tab-item value="tab-2" v-show="docSearchRez">
                                <search-doc-list-tbl-view ref="searchDocList" :fileList="docSearchList"></search-doc-list-tbl-view>
                            </v-tab-item>
                            <v-tab-item value="tab-3" v-show="nodeSearchRez">
                                <search-node-list-tbl-view ref="searchNodeList" :nodeListInfo="nodeSearchList"></search-node-list-tbl-view>
                            </v-tab-item>
                            <v-tab-item value="tab-4" v-show="prodTabShow">
                                <prod-list-view></prod-list-view>
                            </v-tab-item>
                          </v-tabs>
                      </v-card>
                    </SplitArea>       
                       
                    <SplitArea :size="hSizeR">
                      <v-card height="95%">
                        <big-home-view></big-home-view>
                      </v-card>
                    </SplitArea>
                  </Split>

                  <v-flex v-else fill-height>
                    <v-card height="100%">
                        <small-home-view></small-home-view>
                    </v-card>
                  </v-flex>
                     
                </v-layout>
              </v-container>
          </v-main>
        </div>
   
</template>

<script>
    import PathEl from './Path.vue';
    import NavModels from './NavModels.vue';
    import ToolBar from './ToolBar.vue';
    import NodeList from './NodeList.vue';
    import ProdList from './ProdList.vue';
    // import SearchDocList from './SearchDocList.vue';
    import SearchDocListT from './SearchDocListT.vue';
   // import SearchNodeList from './SearchNodeList.vue';
    import SearchNodeListT from './SearchNodeListT.vue';
    import BigHome from './Big/Home.vue';
    import SmallHome from './Small/Home.vue';

    import SubInfo from './Dlg/SubInfo.vue';
    import SelectRole from './Dlg/SelectRole.vue';
    
    import NodeDelHinderList from './Dlg/NodeDelHinderList.vue'
    import AddP2PMsg from './Dlg/AddP2PMsg.vue'
    import UserNotiList from './Dlg/UserNotiList.vue'
    import SearchOneVal from './Dlg/SearchOneVal.vue'
    import GenDoc from './Dlg/GenDoc.vue';
    import CreateNode from './Dlg/CreateNode.vue';
    import PutProd from './Dlg/PutProd.vue';
    import CreateProd from './Dlg/CreateProd.vue';
    import CreateReq from './Dlg/CreateReq.vue';
    import CreateBaseline from './Dlg/CreateBaseline.vue';
    import CreateSubdomain from './Dlg/CreateSubdomain.vue';
    import AddFileToNode from './Dlg/AddFileToNode.vue';
    import CheckInFile from './Dlg/CheckInFile.vue';
    import QSearchNode from './Dlg/QSearchNode.vue';
    import SearchNode from './Dlg/SearchNode.vue';
    import SearchDoc from './Dlg/SearchDoc.vue';
    import TxtSearchDoc from './Dlg/TxtSearchDoc.vue';
    import BaselineList from './Dlg/BaselineList.vue';
    import TokenList from './Dlg/TokenList.vue';
    import AddRel from './Dlg/AddRel.vue';
    import RelsList from './Dlg/RelsList.vue';
    import OrgsList from './Dlg/OrgsList.vue';
    import PropsList from './Dlg/PropsList.vue';
    import AddOrgToNode from './Dlg/AddOrgToNode.vue';
    import Report from './Dlg/Report.vue';
    import FileHistoryList from './Dlg/FileHistory.vue';
    import NodeHistoryList from './Dlg/NodeHistory.vue';
    import MakeDiff from './Dlg/MakeDiff.vue';
    import CreateProj from './Dlg/CreateProj.vue';
    import ImportFileToProj from './Dlg/ImportFile.vue';
    import AssignProp from './Dlg/AssignProp.vue';
    import Set2fa from './Dlg/Set2fa.vue';
    import Confirm2fa from './Dlg/Confirm2fa.vue';
    import ConfirmToken from './Dlg/ConfirmToken.vue';
    import SetUserPass from './Dlg/SetUserPass.vue';
    import DeleteDoc from './Dlg/DeleteDoc.vue';
    import AddIssue from './Dlg/AddIssue.vue';
    import IssueListT from './Dlg/IssueListT.vue';
    
    import contextMenu from 'vue-context-menu';
    import { Split, SplitArea } from 'vue-split-panel';
    
    import{ conf } from '../utils/confTruePLM';
    import { auth } from '../utils/auth';
    
    import router from '../router';
    
export default {
  name: 'home',
  props: {
    projID: { 
      required: false
    },
    nodeID: { 
      required: false
    },
    fileID: { 
      required: false
    }
  },
  data () 
  {
    return {
        active: null,
        prevTab: null,
        globalState: this.$globalStore.state,
        isModelSelected: false,
        docSearchRez: false,
        nodeSearchRez: false,
        prodTabShow: false,
        menuTab: null,
        showUp: false,
        timeout: -1,
        nodeSearchList: {},
        docSearchList: [],
        hideCookies: false,
        hSizeL: 30,
        hSizeR: 70,
        treeMenuNode: null,
        showOccur: false,
        showShape: false,
        childOccur: false,
        navMode: "part",
    };
  },
  methods: {
      closeCookies()
      {
          this.hideCookies = true;
          localStorage.setItem( 'hideCookies', true );          
      },
//      selectedModel()
//      {
//          this.isModelSelected = true;          
//          
////          conf.setModel( model );
////          this.$eventBus.$emit( 'selectedModel' );
//      },
      getUpdate()
      {
          this.$eventBus.$emit( 'updateCurFolder' );        
          this.showUp = false;
      },

      createProj( info )
      {
          let self = this;
          self.$modalDlg.sysDlgs.progressDlg.open();
          conf.createProj( info.name, info.descr, info.files, info.type, info.tmpl, info.srcType, info.folder )
                  .then( ( rez ) =>
          {
              localStorage.removeItem( 'curr_path'  );
              rez.fromURL = true;
              self.$eventBus.$emit( 'updateProjList', rez );
              self.$modalDlg.sysDlgs.progressDlg.close();
              
              if( rez )
                  this.$modalDlg.sysDlgs.confirmDlg.open( 'create_proj_done_title', 'create_proj_done_msg', null, info.name, true );
          }, ( err )=>
          { 
              self.$modalDlg.sysDlgs.progressDlg.close();
              if( err.message === 'Rstat Error: 100002' )
                  err.message = 'Wrong proj name';
              self.$eventBus.$emit( 'queryError', err ); 
          } );
      },
      gotoTreeNode()
      {
          let self = this;
          conf.getNodePath( this.treeMenuNode.instance_id )
                  .then( ( rez ) =>
          {
              if( rez && rez.tree_path_to_element )
                 self.$eventBus.$emit( 'procRowMenuCmd', [ 'gotoNode', rez.tree_path_to_element ] ); 
          })
                .catch( ( err ) =>
          {
            self.$eventBus.$emit( 'queryError', err );
          });  
      },
      copyTreeNode()
      {
          this.$eventBus.$emit( 'procRowMenuCmd', [ 'copyNode' , this.treeMenuNode ] );       
      },
      openTreeMenu( info )
      {
          this.treeMenuNode = info.item;
          this.$refs.treeMenu.open( info.event );
      },
      cMenuOpen( e, num )
      {
        //  console.log( e ); 
          this.menuTab = num;
          this.$refs.ctxMenu.open( e );
      },
      exportTabXLSX()
      {
          if( this.menuTab === 2 )
              this.$eventBus.$emit( 'exportDocSearchXLSX' );
          else if( this.menuTab === 3 )
              this.$eventBus.$emit( 'exportNodeSearchXLSX' );
      },
      exportTabCSV()
      {
          if( this.menuTab === 2 )
              this.$eventBus.$emit( 'exportDocSearchCSV' );
          else if( this.menuTab === 3 )
              this.$eventBus.$emit( 'exportNodeSearchCSV' );
      },
      exportTabJSON()
      {
          if( this.menuTab === 2 )
              this.$eventBus.$emit( 'exportDocSearchJSON' );
          else if( this.menuTab === 3 )
              this.$eventBus.$emit( 'exportNodeSearchJSON' );
      },
      sortTab()
      {
          if( this.menuTab === 1 )
              this.$eventBus.$emit( 'sortNodeListTab' );
          else if( this.menuTab === 2 )
              this.$eventBus.$emit( 'sortDocTab' );
          else if( this.menuTab === 3 )
              this.$eventBus.$emit( 'sortNodeTab' );
          
      },
      updateMode()
      {
          this.$eventBus.$emit( 'updateNodeListTab', { showOccur: this.showOccur, 
              showShape: this.showShape, childOccur: this.childOccur } );
      },
//      switchChildOccur()
//      {
//          this.childOccur = !this.childOccur;
////          if( this.childOccur )
////          {
////              this.showShape = false;
////              this.showOccur = false;
////          }
//          this.$eventBus.$emit( 'updateNodeListTab', { showOccur: this.showOccur, 
//              showShape: this.showShape, childOccur: this.childOccur } );
//      },
//      switchOccur()
//      {
//          this.showOccur = !this.showOccur;
//          if( this.showOccur )
//              this.childOccur = false;
//          
//          this.$eventBus.$emit( 'updateNodeListTab', { showOccur: this.showOccur, 
//              showShape: this.showShape, childOccur: this.childOccur } );
//      },
//      switchShape()
//      {
//          this.showShape = !this.showShape;
//          if( this.showShape )
//              this.childOccur = false;
//          
//          this.$eventBus.$emit( 'updateNodeListTab', { showOccur: this.showOccur, 
//              showShape: this.showShape, childOccur: this.childOccur } );
//      },
      closeTab()
      {
          if( this.menuTab === 2 )
              this.docSearchRez = false;
          if( this.menuTab === 3 )
              this.nodeSearchRez = false;
          if( this.menuTab === 4 )
              this.prodTabShow = false;
          this.menuTab = null;
          this.active = 'tab-1';
          if( this.$refs.tabs )
              this.$refs.tabs.onResize();   
      },
      tabClick()
      {
        //  console.log( this.active );
          
          if( this.$refs.tabs )
            this.$refs.tabs.onResize();
      },    
      doSearchNodes( info )
      {
          let self = this;
          self.nodeSearchList = {};
          self.$modalDlg.sysDlgs.progressDlg.open();
          conf.searchNodes( info, 0, self.$globalStore.state.curFolderInfo )
                  .then( ( list )=>
           {
//               self.$utils.sortArray( list, ( a ) => ( a.bkdn_elem_info.name ), self.$refs.searchNodeListT.sortDec );
               self.$modalDlg.sysDlgs.progressDlg.close();
               self.nodeSearchList = { nodeList: list, propList: [] };

//               if( list.length === 200 )
//                    this.$modalDlg.sysDlgs.confirmDlg.openOkOnly( 'search_limit_title', 'search_limit_msg' );
                
           }, ( err )=>
           { 
               self.$modalDlg.sysDlgs.progressDlg.close();
               self.$eventBus.$emit( 'queryError', err ); 
           } );
      },
      doSearchNodesQuick( info )
      {
          let self = this;
          self.nodeSearchList = {};
    
          self.$modalDlg.sysDlgs.progressDlg.open();
          conf.searchNodesQuick( info, self.$globalStore.state.curFolderInfo )
                  .then( ( list )=>
           {
               self.$modalDlg.sysDlgs.progressDlg.close();
               self.nodeSearchList = { nodeList: list, propList: ( info.prop ? info.prop_list : [] ) };
           }, ( err )=>
           { 
               self.$modalDlg.sysDlgs.progressDlg.close();
               self.$eventBus.$emit( 'queryError', err ); 
           } );
      },
      doSearchDocsQuick( info )
      {
          let self = this;
          self.docSearchList = [];
          self.$modalDlg.sysDlgs.progressDlg.open();
          conf.searchFilesQuick( info, self.$globalStore.state.curFolderInfo )
                  .then( ( list )=>
           {
               self.$modalDlg.sysDlgs.progressDlg.close();
               self.docSearchList = list;
           }, ( err )=>
           { 
               self.$modalDlg.sysDlgs.progressDlg.close();
               self.$eventBus.$emit( 'queryError', err ); 
           } );
      },
      doSearchDocs( info )
      {
          let self = this;
          self.docSearchList = [];
          self.$modalDlg.sysDlgs.progressDlg.open();
          conf.searchFiles( info, 0, self.$globalStore.state.curFolderInfo )
                  .then( ( list )=>
           {
               self.$modalDlg.sysDlgs.progressDlg.close();
               self.docSearchList = list;
           }, ( err )=>
           { 
               self.$modalDlg.sysDlgs.progressDlg.close();
               self.$eventBus.$emit( 'queryError', err ); 
           } );
      },
      doSearchDocsTxt( info )
      {
          let self = this;
          self.docSearchList = [];
          self.$modalDlg.sysDlgs.progressDlg.open();
          conf.searchFilesByContent( info, 100 )
                  .then( ( list )=>
           {
               self.$modalDlg.sysDlgs.progressDlg.close();
               self.docSearchList = list;
           }, ( err )=>
           { 
               self.$modalDlg.sysDlgs.progressDlg.close();
               self.$eventBus.$emit( 'queryError', err ); 
           } );
      },
      procError( err )
      {
          if( !err.message && err.response )
              err.message = err.response;
          
          if( err.message === 'Session expired!' )
              this.procLogOut( err + ' LogOut?', 'Error' );
          console.log( 'E - ' + err );
      },
      setURLData( info )
      {
          let self = this;
          let newURL = '/home/';
          if( info.proj )
          {
              self.$globalStore.setURLData( { proj: info.proj } );
              newURL += self.$globalStore.state.urlData.proj;
          }
          
          if( info.node )
          {
              self.$globalStore.setURLData( { proj: self.$globalStore.state.urlData.proj, node: info.node } );
              newURL += self.$globalStore.state.urlData.proj + '/' + info.node;
          }
          
          if( info.file )
          {
              self.$globalStore.setURLData( { proj: self.$globalStore.state.urlData.proj, node: self.$globalStore.state.urlData.node, file: info.file } );
              newURL += self.$globalStore.state.urlData.proj + '/' + self.$globalStore.state.urlData.node + '/' + self.$globalStore.state.urlData.file;
          }
          
          if( newURL !== '/home/' && self.$router.currentRoute.fullPath !== newURL )
              self.$router.push( newURL );
      },
      onClosePage()
      {
          localStorage.setItem( 'closeTime', Date.now() );
      },
      selectCurFolder()
      {
          this.$globalStore.setSelectedID( this.globalState.curFolderInfo.instance_id );  
          this.$eventBus.$emit( 'showSelProps', this.globalState.curFolderInfo );
      }
    },
    watch: {
        active( v ) 
        {
            if( this.prevTab )
                localStorage.setItem( 'size_' + this.prevTab, JSON.stringify( this.$refs.mainSplit.getSizes() ) );
            
            let tSize = localStorage.getItem( 'size_' + this.active );
            if( !tSize )
            {
                if( this.active === 'tab-1' || this.active === 'tab-2' )
                    tSize = [ 30, 70 ];
                else
                    tSize = [ 95, 5 ];
            }
            else
                tSize = JSON.parse( tSize );
            
            this.hSizeL = tSize[ 0 ];
            this.hSizeR = tSize[ 1 ];
            
            this.prevTab = this.active;
        }
    },
    created() 
    {    
        window.addEventListener( 'beforeunload', this.onClosePage );  
    },
  components: {
    'path-el-view': PathEl,
    'nav-models-view': NavModels,
    'tool-bar-view': ToolBar,
    'node-list-view': NodeList,
    'prod-list-view': ProdList,
    'big-home-view': BigHome,
    'small-home-view': SmallHome,
    //'search-doc-list-view': SearchDocList,
    'search-doc-list-tbl-view': SearchDocListT,
    //'search-node-list-view': SearchNodeList,
    'search-node-list-tbl-view': SearchNodeListT,
    'assign-prop-view': AssignProp,
    'set-2fa-view': Set2fa,
    'confirm-2fa-view': Confirm2fa,
    'confirm-token-view': ConfirmToken,
    'set-user-pass-view': SetUserPass,
    'delete-doc-view': DeleteDoc,
    'search-one-val-view': SearchOneVal,
       
    'node-del-hinder-view': NodeDelHinderList,   
    'add-p2p-msg-view': AddP2PMsg,
    'user-noti-view': UserNotiList,
    'gen-doc-view': GenDoc,
    'sub-info-view': SubInfo,
    'select-role-view': SelectRole,
    'create-node-view': CreateNode,
    'put-prod-view': PutProd,
    'create-prod-view': CreateProd,
    'create-req-view': CreateReq,
    'create-proj-view': CreateProj,
    'import-file-view': ImportFileToProj,
    'create-baseline-view': CreateBaseline,
    'create-subdomain-view': CreateSubdomain,
    'add-file-node-view': AddFileToNode,
    'checkin-file-view': CheckInFile,
    'qsearch-node-view': QSearchNode,
    'search-node-view': SearchNode,
    'search-doc-view': SearchDoc,
    'search-doc-txt-view': TxtSearchDoc,
    'baseline-list-view': BaselineList,
    'token-list-view': TokenList,
    'add-rel-view': AddRel,
    'rels-list-view': RelsList,
    'orgs-list-view': OrgsList,
    'props-list-view': PropsList,
    'add-org-to-node-view': AddOrgToNode,
    'rep-list-view': Report,
    'file-history-view': FileHistoryList,
    'node-history-view': NodeHistoryList,
    'make-diff-view': MakeDiff,
    'context-menu': contextMenu,
    'add-issue-view': AddIssue,
    'issue-list-view': IssueListT,
    
    'Split' : Split,
    'SplitArea': SplitArea
  },
  beforeMount: function()
  {
      if( this.projID )
          localStorage.setItem( 'projID', this.projID );
      if( this.nodeID )
          localStorage.setItem( 'nodeID', this.nodeID );
      if( this.fileID )
          localStorage.setItem( 'fileID', this.fileID );
      
      auth.checkAuth();
      if( !auth.getUser().authenticated )
          router.push( '/login' );
      else if( auth.getUser().name === 'superuser' || auth.getUser().name === 'system' )
      {
          router.push( '/admin' );   
      }
      else
      {
          if( !auth.getUser().name )
          {
                 conf.getCurrentUsers()
                        .then( ( data ) =>
                {
                    if( data )
                        this.$globalStore.setCurUser( data[ 0 ].user_name );
                });
          }
      }
  },
  mounted: function()
  {
      this.hideCookies = localStorage.getItem( 'hideCookies' );          

      this.$modalDlg.sysDlgs[ 'nodeDelHinderDlg' ] = this.$refs.nodeDelHinderDlg;
      this.$modalDlg.sysDlgs[ 'addP2PMsgDlg' ] = this.$refs.addP2PMsgDlg;
      this.$modalDlg.sysDlgs[ 'userNotiDlg' ] = this.$refs.userNotiDlg;
      this.$modalDlg.sysDlgs[ 'searchOneValDlg' ] = this.$refs.searchOneValDlg;
      this.$modalDlg.sysDlgs[ 'deleteDocDlg' ] = this.$refs.deleteDocDlg;
      this.$modalDlg.sysDlgs[ 'genDocDlg' ] = this.$refs.genDocDlg;
      this.$modalDlg.sysDlgs[ 'subInfoDlg' ] = this.$refs.subInfoDlg;
      this.$modalDlg.sysDlgs[ 'selectRoleDlg' ] = this.$refs.selectRoleDlg;
      this.$modalDlg.sysDlgs[ 'createNodeDlg' ] = this.$refs.createNodeDlg;
      this.$modalDlg.sysDlgs[ 'putProdDlg' ] = this.$refs.putProdDlg;
      this.$modalDlg.sysDlgs[ 'createProdDlg' ] = this.$refs.createProdDlg;
      this.$modalDlg.sysDlgs[ 'createReqDlg' ] = this.$refs.createReqDlg;
      this.$modalDlg.sysDlgs[ 'createBaselineDlg' ] = this.$refs.createBaselineDlg;
      this.$modalDlg.sysDlgs[ 'createSubdomainDlg' ] = this.$refs.createSubdomainDlg;
      this.$modalDlg.sysDlgs[ 'addFileToNodeDlg' ] = this.$refs.addFileToNodeDlg;
      this.$modalDlg.sysDlgs[ 'checkInFileDlg' ] = this.$refs.checkInFileDlg;
      this.$modalDlg.sysDlgs[ 'qSearchNodeDlg' ] = this.$refs.qSearchNodeDlg;
      this.$modalDlg.sysDlgs[ 'searchNodeDlg' ] = this.$refs.searchNodeDlg;
      this.$modalDlg.sysDlgs[ 'searchDocDlg' ] = this.$refs.searchDocDlg;
      this.$modalDlg.sysDlgs[ 'txtSearchDocDlg' ] = this.$refs.txtSearchDocDlg;
      this.$modalDlg.sysDlgs[ 'baselineListDlg' ] = this.$refs.baselineListDlg;
      this.$modalDlg.sysDlgs[ 'tokenListDlg' ] = this.$refs.tokenListDlg;
      this.$modalDlg.sysDlgs[ 'addRelDlg' ] = this.$refs.addRelDlg;
      this.$modalDlg.sysDlgs[ 'relsListDlg' ] = this.$refs.relsListDlg;
      this.$modalDlg.sysDlgs[ 'orgsListDlg' ] = this.$refs.orgsListDlg;
      this.$modalDlg.sysDlgs[ 'propsListDlg' ] = this.$refs.propsListDlg;
      this.$modalDlg.sysDlgs[ 'addOrgToNodeDlg' ] = this.$refs.addOrgToNodeDlg;
      this.$modalDlg.sysDlgs[ 'reportDlg' ] = this.$refs.repListDlg;
      this.$modalDlg.sysDlgs[ 'fileHistoryDlg' ] = this.$refs.fileHistoryDlg;
      this.$modalDlg.sysDlgs[ 'nodeHistoryDlg' ] = this.$refs.nodeHistoryDlg;
      this.$modalDlg.sysDlgs[ 'makeDiffDlg' ] = this.$refs.makeDiffDlg;
      this.$modalDlg.sysDlgs[ 'createProjDlg' ] = this.$refs.createProjDlg;
      this.$modalDlg.sysDlgs[ 'importFileToProjDlg' ] = this.$refs.importFileToProjDlg;
      this.$modalDlg.sysDlgs[ 'assignPropDlg' ] = this.$refs.assignPropDlg;
      this.$modalDlg.sysDlgs[ 'set2faDlg' ] = this.$refs.set2faDlg;
      this.$modalDlg.sysDlgs[ 'confirm2faDlg' ] = this.$refs.confirm2faDlg;
      this.$modalDlg.sysDlgs[ 'confirmTokenDlg' ] = this.$refs.confirmTokenDlg;
      this.$modalDlg.sysDlgs[ 'setUserPassDlg' ] = this.$refs.setUserPassDlg;
      this.$modalDlg.sysDlgs[ 'addIssueDlg' ] = this.$refs.addIssueDlg;
      this.$modalDlg.sysDlgs[ 'issueListDlg' ] = this.$refs.issueListDlg;
            
      let self = this;
      self.$eventBus.$on( 'selectedModel', function()
      {
           self.nodeSearchList = [];
           self.docSearchList = [];
           self.prodTabShow = false;
           self.active = 'tab-1';
      });
      self.$eventBus.$on( 'searchDocTxt', function( info )
      {
           self.doSearchDocsTxt( info );
           self.docSearchRez = true;
           self.active = 'tab-2';
           
           if( this.$refs.tabs )
               self.$refs.tabs.onResize();           
      });
      self.$eventBus.$on( 'searchDoc', function( info )
      {
           self.doSearchDocs( info );
           self.docSearchRez = true;
           self.active = 'tab-2';
           
           if( this.$refs.tabs )
               self.$refs.tabs.onResize();           
      });
      self.$eventBus.$on( 'searchDocQuick', function( info )
      {
           self.doSearchDocsQuick( info );
           self.docSearchRez = true;
           self.active = 'tab-2';
           
           if( this.$refs.tabs )
               self.$refs.tabs.onResize();           
      });
      self.$eventBus.$on( 'searchNode', function( info )
      {
           self.doSearchNodes( info );
           self.nodeSearchRez = true;
           self.active = 'tab-3';
           
           if( this.$refs.tabs )
               self.$refs.tabs.onResize();
      });
      self.$eventBus.$on( 'searchQuick', function( info )
      {
           self.doSearchNodesQuick( info );
           self.nodeSearchRez = true;
           self.active = 'tab-3';
           
           if( this.$refs.tabs )
               self.$refs.tabs.onResize();
      });
      self.$eventBus.$on( 'openProds', function()
      {
          self.prodTabShow = true;
          self.active = 'tab-4';
           
           if( this.$refs.tabs )
               self.$refs.tabs.onResize();
      });
      self.$eventBus.$on( 'setPath', function()
      {
           self.active = 'tab-1';
      });
      self.$eventBus.$on( 'goInNode', function()
      {
           self.active = 'tab-1';
      });
      self.$eventBus.$on( 'openBaseline', function()
      {
           self.active = 'tab-1';
      });
      
      self.$eventBus.$on( 'createProj', function( info )
      {
           self.createProj( info );
      });
      
      self.$eventBus.$on( 'updateInfoMsg', function( data )
      {
          if( this.$globalStore.state.actVer || this.$globalStore.state.curActTimestamp === data.regNum )
              return;
              
          if( this.$globalStore.state.curFolderInfo )
          { 
            if( ( '' + this.$globalStore.state.curFolderInfo.instance_id ) === data.folderID )
            {
                self.showUp = true;
                return;
            }

            let ch = this.$globalStore.state.curFolderInfo.children;
            for( let i = 0; ch && i < ch.length; i++ )
            {
                if( ( '' + ch[ i ].instance_id ) === data.folderID )
                {
                    self.showUp = true;
                    return;
                }
            }
          }
      });
      
      self.$eventBus.$on( 'setURLData', function( info )
      {
          self.setURLData( info );
      });
      
      self.$eventBus.$on( 'openTreeMenu', function( info )
      {
          self.openTreeMenu( info );
      });
  },
  beforeDestroy: function()
  {
      this.$eventBus.$off( 'openTreeMenu' );
      this.$eventBus.$off( 'openProds' );
      this.$eventBus.$off( 'searchDoc' );
      this.$eventBus.$off( 'searchDocQuick' );
      this.$eventBus.$off( 'searchQuick' );
      this.$eventBus.$off( 'searchNode' );
      this.$eventBus.$off( 'searchDocTxt' );
      this.$eventBus.$off( 'setPath' );
      this.$eventBus.$off( 'goInNode' );
      this.$eventBus.$off( 'openBaseline' );
      this.$eventBus.$off( 'createProj' );
      this.$eventBus.$off( 'updateInfoMsg' );
      this.$eventBus.$off( 'setURLData' );
      this.$eventBus.$off( 'selectedModel' );
  }
}
</script>

<style>
    .main_correction > div.v-main__wrap
    {
        height: 90%;            
    }
    .check_correction
    {
        padding-top: 0px;
        margin-top: 0px;
        font-size: 14px;
    }
    .check_correction > div > div.v-messages
    {
        display: none;
    }
    .check_correction .v-label
    {
        color: black;
    }
    
    .close-menu
    {
        font-size: 14px;
        padding-left: 0px;
        min-height: 25px !important;
    }
    
    .close-menu ul
    {
        min-width: 2em;
        white-space: nowrap;
    }
    .sub
    {
        color: green;
        font-style: italic;
    }
    .multipane.cust.layout-v .multipane-resizer 
    {
        margin: 0; left: 0; /* reset default styling */
        width: 5px;
        background: graytext;
    }
    .cMenuCor
    {
        margin-left: -10px;
        padding-left: 15px;
        white-space: nowrap;
    }
    .ctx-menu.ctx-menu-left
    {
        padding-left: 10px;
    }
    
    .v-label
    {
        font-size: 14px;
    }
</style>
