<template>
        <v-dialog v-model="visFl" persistent max-width="390">
           <v-card>
            <v-card-title class="headline" v-if="edit">
              {{ $t( 'message.edit_elem_ch_title' ) }}
            </v-card-title>
            <v-card-title class="headline" v-else>
              {{ $t( 'message.create_elem_ch_title' ) }}
            </v-card-title>
            <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-text-field :label="$t( 'message.create_elem_ch_name' )" required v-model="name" :disabled="edit"
                        :rules="[v => !!v || $t( 'message.value_required' )]"></v-text-field>
                    
                    <v-select :label="$t( 'message.create_elem_ch_children' )" item-text="txt" item-value="val" v-model="alChildren"
                        :items="children" multiple></v-select>
                    
                </v-form>
                <!--<small>*{{ $t( 'message.req_info_msg' ) }}</small>-->
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
                <v-btn id="createElemWithAChildrenOk" color="green darken-1" text :disabled="!valid" @click.native="doAct()">{{ $t( 'message.ok_btn' ) }}</v-btn>
                <v-btn id="createElemWithAChildrenClose" color="green darken-1" text @click.native="close()">{{ $t( 'message.cancel_btn' ) }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>   
</template>

<script>
    
import Vue from 'vue'
import{ conf } from '@/utils/confTruePLM';

export default {
  name: 'createElemWithAChildren',
  data () 
  {
    return {
        visFl: false,
        name: '',
        children: [],
        alChildren: [],
        resolve: null,
        reject: null,
        valid: true,
        edit: false
    };
  },
  computed: {
  },
  methods: {
      open( info, children )
      {
     //     console.log( info, children );
          this.children = children || [];
          if( info )
          {
              this.name = info.name;
              this.alChildren = info.alChildren;
              
              this.edit = true;
          }
          else
          {
              this.name = null;
              this.alChildren = [];
              
              this.edit = false;
              this.types = [];
          }
          
          // console.log( this.alChildren );
          this.visFl = true;
          
           return new Promise( ( resolve, reject ) => 
           {
                this.resolve = resolve;
                this.reject = reject;
           });
      },
      close()
      {
          this.visFl = false;
          this.reject();
      },
      doAct()
      {
          if( this.$refs.form.validate() )
          {
                this.visFl = false;
                this.resolve( { name: this.name, alChildren: this.alChildren } );
          }
      }
    }
}
</script>



