  import ajax from './ajaxSTM';
  
  const VALID_TIME = 1000 * 60 * 60 * 13;

var user = { authenticated: false, access_token: '', valid_time_end: 0, name: null, hash: '' };
var server = { api_url: '', info: '' };
  
export const auth =  
{
//  user: { authenticated: false, access_token: '', valid_time_end: 0, name: null },
//  server: { api_url: '', info: '' },
//  
  getServer()
  {
        return server;
  },
  getUser()
  {
        return user;
  },
  query( params )
  {
      if( !user.authenticated || !server.api_url )
          return Promise.reject( new Error( 'Not authenticated user!' ) );
      
      if( user.valid_time_end < Date.now() )
          return Promise.reject( new Error( 'Session expired!' ) );
      
      params.accessToken = user.access_token;
      return ajax.queryRez( server.api_url, params )
              .then( ( data )=>
      {
          return data[ 0 ];
      });
  },
  
  apiReq( params )
  {
      if( !user.authenticated || !server.api_url )
          return Promise.reject( new Error( 'Not authenticated user!' ) );
      
      if( user.valid_time_end < Date.now() )
          return Promise.reject( new Error( 'Session expired!' ) );
      
      params.accessToken = user.access_token;
      return ajax.apiReq( server.api_url, params );
  },

  login( creds ) 
  {
      let self = this;
      let loginRez = {};
      
      localStorage.removeItem( 'closeTime' );
      
      return ajax.login( creds.server.url, creds )
              .then( ( info ) => 
      {
        let access_token = null;
        if( info === null )
            throw new Error( "unknown error" );
        else if( typeof info === 'string' || info instanceof String )
            access_token = info;
        else if( info.token )
            access_token = info.token;
        else if( info.error )
            throw new Error( info.error );
        else
            throw new Error( "unknown error" );
        
        loginRez = { status: true, use2fa: info.use2FA }; 
        
        localStorage.setItem( 'access_token', access_token );
        localStorage.setItem( 'api_url', creds.server.url );
        localStorage.setItem( 'ws_url', creds.server.ws );
        let valid_time_end = Date.now() + VALID_TIME;
        localStorage.setItem( 'valid_time_end', valid_time_end );
        user.name = creds.user;
        user.authenticated = true;
        user.access_token = access_token;
        user.valid_time_end = valid_time_end;
        server.api_url = creds.server.url;
        server.ws_url = creds.server.ws;

        return self.updateServerInfo();
      })
             .then( () => 
      {
        return loginRez;
      });
  },
  
  async getHash( str )
  {
        const encoder = new TextEncoder();
        const data = encoder.encode( str );
        const hash = await crypto.subtle.digest('SHA-256', data);
        return hash;
  },
  
  loginSSO( provider, token, callbackUrl )
  {
      let api_url = localStorage.getItem( 'api_url' );
      let url = api_url + 'admin/sso/' + provider + '/?token=' + token;
      
      if( callbackUrl )
          url += '&callback=' + callbackUrl;
      
      let self = this;
      let loginRez = {};
      
      localStorage.removeItem( 'closeTime' );
      
      return ajax.ajaxReq( { url: url, dataType: 'json' } )
              .then( info => 
      {
          if( info.token === 'no sso account' )
              loginRez = { status: false, sso_id: info.name };
          else
              loginRez = { status: true, use2fa: info.use2FA };

          localStorage.setItem( 'access_token', info.token );
          let valid_time_end = Date.now() + VALID_TIME;
          localStorage.setItem( 'valid_time_end', valid_time_end );
          user.name = info.name;
          user.authenticated = true;
          user.access_token = info.token;
          user.valid_time_end = valid_time_end;
          
          return self.updateServerInfo();
      })
              .then( () => 
      {
          return loginRez; 
      });
  },

  logout() 
  {
      return ajax.logout( server.api_url, { accessToken: user.access_token } )
              .then( () =>
      {
        localStorage.removeItem( 'access_token' );
        localStorage.removeItem( 'api_url' );
        localStorage.removeItem( 'ws_url' );
        user.authenticated = false;
        user.access_token = '';
        user.hash = '';
        server.api_url = '';
      }, () => {} );
  },
  
  sendRegReq( serverUrl, info )
  {
        var fd = new FormData();
        Object.keys( info ).forEach( function( key ) 
        {
            var val = info[ key ];
            if( val && val !== '' )
                fd.append( key, val );
        });

        return ajax.ajaxReq( { url: serverUrl + 'admin/regreq', data: fd, method: 'POST', dataType: 'json' } );
  },
  
  check2fa( code )
  {
      let access_token = localStorage.getItem( 'access_token' );
      let api_url = localStorage.getItem( 'api_url' );
      
      return ajax.ajaxReq( { url: api_url + 'admin/check2fa/' + access_token + '?'
          + "code=" + code, dataType: 'json' } );
  },
  
  getInitInfo( serverInfo )
  {
      localStorage.setItem( 'api_url', serverInfo.url );
      localStorage.setItem( 'ws_url', serverInfo.ws );
      server.api_url = serverInfo.url;
      server.ws_url = serverInfo.ws;
      return ajax.ajaxReq( { url: server.api_url + 'initinfo', dataType: 'json' } );
  },
  
  updateServerInfo()
  {
      return ajax.ajaxReq( { url: server.api_url + 'version' } )
                .then( ( data ) =>
      {
            server.info = JSON.parse( data );
      });      
  },

  checkAuth() 
  {
    let access_token = localStorage.getItem( 'access_token' );
    let api_url = localStorage.getItem( 'api_url' );
    let ws_url = localStorage.getItem( 'ws_url' );
    let valid_time_end = localStorage.getItem( 'valid_time_end' );
    server.api_url = api_url;      
    server.ws_url = ws_url;     
    
    let closeT = localStorage.getItem( 'closeTime' ) || 0;
 //   console.log( valid_time_end, Date.now() );
    
    if( access_token && api_url && valid_time_end > Date.now() 
            && ( !closeT || ( Date.now() - closeT ) < 3000 ) ) 
    {
            user.authenticated = true;
            user.access_token = access_token;
            user.valid_time_end = valid_time_end;
            this.updateServerInfo();
    }
    else 
    {
       user.authenticated = false;      
       user.access_token = '';
       user.hash = '';
       user.valid_time_end = 0;
    }
    
    localStorage.removeItem( 'closeTime' );
  }
};
 

