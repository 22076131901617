<template>
    <v-list-item :key="model.name" @click.stop="toggleNode">
<!--        <v-list-item-avatar>
            <v-icon v-if='model.back' large>chevron_left</v-icon>
            <img v-else :src="imgSrc">
        </v-list-item-avatar>-->
        <v-list-item-action>
            <v-icon v-if='model.back' large>chevron_left</v-icon>
            <v-img v-else :src="imgSrc" max-width="24"></v-img>
        </v-list-item-action>
        <v-list-item-content>
            <v-list-item-title>
                <span v-bind:class="{ select: ( globalState.selectedID === modelID ), back: model.back, gray: ( !model.permissions.can_i_read ) }">
                    
                    <v-tooltip top v-if="!model.root && !model.back">
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                                {{modelName}} (ver.{{model.version_id}}) {{chNumber}}/{{docNumber}}
                                <v-icon v-if="model.is_root_of_subdomain" color="green">domain</v-icon>
                            </span>
                       </template>
                        <div v-show="!model.root">
                            <div v-for="( node, index ) in nodeInfo">{{node}}</div>
                        </div>
                    </v-tooltip>
                    <span v-if="model.root && globalState.actVer">
                         {{modelName}} (ver.{{globalState.actVer}})
                    </span>
                    <span v-if="model.root && !globalState.actVer">
                         {{modelName}} (ver.{{model.version_id}})
                    </span>                    
                </span>
            </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <div style="display: flex;" v-if="!noMenu">
              <!--<v-icon @click.stop="showProp">list</v-icon>-->
<!----------- Node menu-->             
            <v-menu v-if="model.root || model.back || 
                    ( !globalState.actVer  
                    && !model.back && model.element_type !== 'urn:rdl:epm-std:product_list' )"
                    bottom left v-model="showMenu" :close-on-content-click="false">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon @click.stop="openMenu()" v-if="">
                        <v-icon>more_vert</v-icon>
                    </v-btn>
                </template>
<!----------- Root node menu with prev version-->                
                <v-list dense v-if="model.root && ( globalState.actVer || globalState.curUserRole === 'urn:rdl:epm-std:project_member' )">
                  <v-list-item key="7" @click.stop="" v-if="model.root">
                        <v-autocomplete :items="verList" item-text="txt" item-value="val"
                            v-model="selectedVer" @input="setActVer()" light dense class="selectCl"></v-autocomplete>    
                  </v-list-item>
                </v-list>
<!----------- Node menu with current version --->
                <v-list dense v-else-if="!model.back && ( !globalState.actVer )" >
    <!--              <v-list-item key="1" @click.stop="procFunc( 'openNode' )">
                    <v-list-item-title>{{$t( 'message.menu_open' )}}</v-list-item-title>
                  </v-list-item>-->
                  <v-list-item key="2" @click.stop="procFunc( 'renameNode' )" v-if='model.permissions.can_i_write'>
                      <v-list-item-title>{{$t( 'message.menu_rename' )}}</v-list-item-title>
                      <v-tooltip bottom>
                         <template v-slot:activator="{ on }">
                            <v-icon v-on="on" @click.stop="openHelp( 'Rename+element' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                         </template>
                         <span>
                             {{$t( 'message.open_help' )}}
                         </span>
                      </v-tooltip>
                  </v-list-item>
                  <v-list-item key="22" @click.stop="procFunc( 'renameNodeByAttr' )" v-if='model.permissions.can_i_write'>
                      <v-list-item-title>{{$t( 'message.menu_rename_by_attr' )}}</v-list-item-title>
                      <v-tooltip bottom>
                         <template v-slot:activator="{ on }">
                             <v-icon v-on="on" @click.stop="openHelp( 'Rename+by+attribute' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                         </template>
                         <span>
                             {{$t( 'message.open_help' )}}
                         </span>
                     </v-tooltip>
                  </v-list-item>
                  <v-list-item key="3" @click.stop="procFunc( 'editNode' )" v-if='model.permissions.can_i_write'>
                     <v-list-item-title>{{$t( 'message.menu_edit' )}}</v-list-item-title>
                     <v-tooltip bottom>
                         <template v-slot:activator="{ on }">
                             <v-icon v-on="on" @click.stop="openHelp( 'Edit+element' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                         </template>
                         <span>
                             {{$t( 'message.open_help' )}}
                         </span>
                     </v-tooltip>
                  </v-list-item>
                  <v-list-item key="4" @click.stop="procFunc( 'deleteNode' )" v-if="!model.root && model.permissions.can_i_delete">
                     <v-list-item-title>{{$t( 'message.menu_delete' )}}</v-list-item-title>
                     <v-tooltip bottom>
                         <template v-slot:activator="{ on }">
                            <v-icon v-on="on" @click.stop="openHelp( 'Delete+element' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                         </template>
                         <span>
                             {{$t( 'message.open_help' )}}
                         </span>
                     </v-tooltip>
                  </v-list-item>
                  <v-list-item key="5" @click.stop="procFunc( 'cutNode' )" v-if="!model.root && model.permissions.can_i_write && model.permissions.can_i_delete">
                     <v-list-item-title>{{$t( 'message.menu_cut' )}}</v-list-item-title>
                     <v-tooltip bottom>
                         <template v-slot:activator="{ on }">
                            <v-icon v-on="on" @click.stop="openHelp( 'Cut+element' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                         </template>
                         <span>
                             {{$t( 'message.open_help' )}}
                         </span>
                     </v-tooltip>
                  </v-list-item>
                  <v-list-item key="6" @click.stop="procFunc( 'copyNode' )" v-if="!model.root && model.permissions.can_i_write">
                     <v-list-item-title>{{$t( 'message.menu_copy' )}}</v-list-item-title>
                     <v-tooltip bottom>
                         <template v-slot:activator="{ on }">
                            <v-icon v-on="on" @click.stop="openHelp( 'Copy+element' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                         </template>
                         <span>
                             {{$t( 'message.open_help' )}}
                         </span>
                     </v-tooltip>
                  </v-list-item>
                  <v-list-item key="7" @click.stop="" v-if="model.root">
                      <v-autocomplete :items="verList" item-text="txt" item-value="val" 
                                      v-model="selectedVer" @input="setActVer()" dense class="selectCl"></v-autocomplete>    
                  </v-list-item>
<!--                  <v-list-item key="17" @click.stop="procFunc( 'importFile' )" v-if="model.root && model.permissions.can_i_write">
                    <v-list-item-title>{{$t( 'message.menu_import' )}}</v-list-item-title>
                  </v-list-item>-->
                  <v-list-item key="18" @click.stop="procFunc( 'exportToFile' )" v-if="model.root && model.permissions.can_i_read
                               && ( globalState.curUserRole === 'urn:rdl:epm-std:project_manager' || globalState.curUserRole === 'urn:rdl:epm-std:project_admin' )">
                    <v-list-item-title>{{$t( 'message.menu_export' )}}</v-list-item-title>
                    <v-tooltip bottom>
                         <template v-slot:activator="{ on }">
                            <v-icon v-on="on" @click.stop="openHelp( 'Export-to-STEP-PDM-file' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                         </template>
                         <span>
                             {{$t( 'message.open_help' )}}
                         </span>
                    </v-tooltip>
                  </v-list-item>
                  <v-list-item key="48" @click.stop="procFunc( 'exportToTxt' )" v-if="model.root && model.permissions.can_i_read 
                               && ( globalState.curUserRole === 'urn:rdl:epm-std:project_manager' || globalState.curUserRole === 'urn:rdl:epm-std:project_admin' )">
                    <v-list-item-title>{{$t( 'message.menu_export_txt' )}}</v-list-item-title>
                    <v-tooltip bottom>
                         <template v-slot:activator="{ on }">
                            <v-icon v-on="on" @click.stop="openHelp( 'Export-structure-to-text-file' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                         </template>
                         <span>
                             {{$t( 'message.open_help' )}}
                         </span>
                    </v-tooltip>
                  </v-list-item>
                  <v-list-item key="49" @click.stop="procFunc( 'exportToDEX' )" v-if="model.root && model.permissions.can_i_read 
                               && ( globalState.curUserRole === 'urn:rdl:epm-std:project_manager' || globalState.curUserRole === 'urn:rdl:epm-std:project_admin' )">
                    <v-list-item-title>{{$t( 'message.menu_export_DEX' )}}</v-list-item-title>
                    <v-tooltip bottom>
                         <template v-slot:activator="{ on }">
                            <v-icon v-on="on" @click.stop="openHelp( 'Export-project-to-DEX' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                         </template>
                         <span>
                             {{$t( 'message.open_help' )}}
                         </span>
                    </v-tooltip>
                  </v-list-item>
                  <v-list-item key="50" @click.stop="procFunc( 'exportToAP242' )" v-if="model.root && model.permissions.can_i_read 
                               && ( globalState.curUserRole === 'urn:rdl:epm-std:project_manager' || globalState.curUserRole === 'urn:rdl:epm-std:project_admin' )">
                    <v-list-item-title>{{$t( 'message.menu_export_AP242' )}}</v-list-item-title>
                    <v-tooltip bottom>
                         <template v-slot:activator="{ on }">
                            <v-icon v-on="on" @click.stop="openHelp( 'Export-to-AP242-domain' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                         </template>
                         <span>
                             {{$t( 'message.open_help' )}}
                         </span>
                    </v-tooltip>
                  </v-list-item>
                  <v-list-item key="29" @click.stop="procFunc( 'importCSVFileToProj' )" v-if="model.root && model.permissions.can_i_write
                               && ( globalState.curUserRole !== 'urn:rdl:epm-std:project_member' || globalState.curUserRole === 'urn:rdl:epm-std:project_admin' )">
                    <v-list-item-title>{{$t( 'message.menu_importCSV' )}}</v-list-item-title>
                    <v-tooltip bottom>
                         <template v-slot:activator="{ on }">
                            <v-icon v-on="on" @click.stop="openHelp( 'Import-CSV-file' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                         </template>
                         <span>
                             {{$t( 'message.open_help' )}}
                         </span>
                    </v-tooltip>
                  </v-list-item>
                  <v-list-item key="19" @click.stop="procFunc( 'setPhase' )" v-if="model.root  
                               && ( globalState.curUserRole === 'urn:rdl:epm-std:project_manager' || globalState.curUserRole === 'urn:rdl:epm-std:project_admin' )">
                    <v-list-item-title>{{$t( 'message.menu_phase' )}}</v-list-item-title>
                    <v-tooltip bottom>
                         <template v-slot:activator="{ on }">
                            <v-icon v-on="on" @click.stop="openHelp( 'Current-project-phase' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                         </template>
                         <span>
                             {{$t( 'message.open_help' )}}
                         </span>
                   </v-tooltip>
                  </v-list-item>
                  <v-list-item key="51" @click.stop="procFunc( 'incVer' )" v-if="model.root && !globalState.projAutoVer
                               && ( globalState.curUserRole === 'urn:rdl:epm-std:project_manager' || globalState.curUserRole === 'urn:rdl:epm-std:project_admin' )">
                    <v-list-item-title>{{$t( 'message.menu_inc_ver' )}}</v-list-item-title>
<!--                    <v-tooltip bottom>
                         <template v-slot:activator="{ on }">
                            <v-icon v-on="on" @click.stop="openHelp( 'Current-project-phase' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                         </template>
                         <span>
                             {{$t( 'message.open_help' )}}
                         </span>
                   </v-tooltip>-->
                  </v-list-item>
<!--                  <v-list-item v-show="!model.is_root_of_subdomain" key="20" @click.stop="procFunc( 'createSubdomain' )">
                    <v-list-item-title>{{$t( 'message.menu_create_subdomain' )}}</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-show="model.is_root_of_subdomain" key="21" @click.stop="procFunc( 'deleteSubdomain' )">
                    <v-list-item-title>{{$t( 'message.menu_delete_subdomain' )}}</v-list-item-title>
                  </v-list-item>-->
                </v-list>
<!----------- Back prod list node menu -->
                <v-list dense v-else-if="model.back && model.element_type === 'urn:rdl:epm-std:product_list' 
                        && ( !globalState.actVer )">
                  <v-list-item key="41" @click.stop="procFunc( 'createProd' )">
                        <v-list-item-title>{{$t( 'message.menu_create_prod' )}}</v-list-item-title>
                  </v-list-item>
                  <v-list-item key="8" @click.stop="procFunc( 'createNode' )" v-if="model.permissions.can_i_write">
                    <v-list-item-title>{{$t( 'message.menu_create' )}}</v-list-item-title>
                  </v-list-item>
                </v-list>
<!--------- Back prod node menu -->
                <v-list dense v-else-if="model.back && model.element_type === 'product_elem'
                        && ( !globalState.actVer )">
                  <v-list-item key="42" @click.stop="procFunc( 'putProdToProd' )" v-if="model.permissions.can_i_write">
                        <v-list-item-title>{{$t( 'message.menu_put_prod' )}}</v-list-item-title>
                  </v-list-item>
                </v-list>
<!----------- Back requirement node menu -->
                <v-list dense v-else-if="model.back && model.is_requirement && ( !globalState.actVer )">
                  <v-list-item key="38" @click.stop="procFunc( 'createReq' )" v-if="model.permissions.can_i_write">
                    <v-list-item-title>{{$t( 'message.menu_req' )}}</v-list-item-title>
                  </v-list-item>
                  <v-list-item key="30" @click.stop="procFunc( 'addFileToNode' )" v-if="!model.parent.root && !model.is_part && model.permissions.can_i_write">
                    <v-list-item-title>{{$t( 'message.menu_add_file' )}}</v-list-item-title>
                  </v-list-item>
                  <v-list-item key="31" @click.stop="procFunc( 'pasteFileToNode' )" v-if="!model.parent.root && !model.is_part && model.permissions.can_i_write"
                               :disabled="( !globalState.fileForPaste || ( globalState.fileForPaste.parentNode.instance_id === model.instance_id ) )
                               && ( !globalState.fileForLink || ( globalState.fileForLink.parentNode.instance_id === model.instance_id ) )">
                    <v-list-item-title>{{$t( 'message.menu_paste_file' )}}</v-list-item-title>
                  </v-list-item>
                  <v-list-item key="32" @click.stop="showNodeTree()">
                    <v-list-item-title>{{$t( 'message.menu_show_node_tree' )}}</v-list-item-title>
                  </v-list-item>
                  <v-list-item key="33" @click.stop="showBOM()">
                    <v-list-item-title v-if="model.is_requirement">{{$t( 'message.menu_show_bom_req' )}}</v-list-item-title>
                    <v-list-item-title v-else>{{$t( 'message.menu_show_bom' )}}</v-list-item-title>
                  </v-list-item> 
                  <v-list-item key="34" @click.stop="procFunc( 'importReqIFToNode' )" v-if="model.permissions.can_i_write && !model.parent.root && !model.is_part 
                               && globalState.curUserRole !== 'urn:rdl:epm-std:project_member'">
                    <v-list-item-title>{{$t( 'message.menu_importReqIF' )}}</v-list-item-title>
                  </v-list-item>
                </v-list>
<!----------- Back node menu --->
                <v-list dense v-else-if="model.back && ( !globalState.actVer )">
                  <v-list-item key="8" @click.stop="procFunc( 'createNode' )" v-if="model.permissions.can_i_write">
                    <v-list-item-title>{{$t( 'message.menu_create' )}}</v-list-item-title>
                    <v-tooltip bottom>
                         <template v-slot:activator="{ on }">
                            <v-icon v-on="on" @click.stop="openHelp( 'Create+element' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                         </template>
                         <span>
                             {{$t( 'message.open_help' )}}
                         </span>
                    </v-tooltip>
                  </v-list-item>
                  <v-list-item key="9" @click.stop="procFunc( 'pasteNode' )" :disabled="!globalState.nodeForPaste" v-if="model.permissions.can_i_write">
                    <v-list-item-title>{{$t( 'message.menu_paste' )}}</v-list-item-title>
                    <v-tooltip bottom>
                         <template v-slot:activator="{ on }">
                            <v-icon v-on="on" @click.stop="openHelp( 'Paste+element' )" style="font-size: 18px; padding-left: 5px; pointer-events: auto;">help</v-icon>
                         </template>
                         <span>
                             {{$t( 'message.open_help' )}}
                         </span>
                    </v-tooltip>
                  </v-list-item>
                  <!-- <v-list-item key="31" @click.stop="procFunc( 'pasteNodeRel1' )" :disabled="!globalState.nodeForPaste" v-if="!model.is_part && model.permissions.can_i_write">
                    <v-list-item-title>{{$t( 'message.menu_paste' )}}</v-list-item-title>
                    <v-tooltip bottom>
                         <template v-slot:activator="{ on }">
                            <v-icon v-on="on" @click.stop="openHelp( 'Paste+element' )" style="font-size: 18px; padding-left: 5px; pointer-events: auto;">help</v-icon>
                         </template>
                         <span>
                             {{$t( 'message.open_help' )}}
                         </span>
                    </v-tooltip>
                  </v-list-item> -->
                  <v-list-item key="31" @click.stop="procFunc( 'pasteNodeRel1' )" :disabled="!globalState.nodeForPaste" v-if="!model.is_part && model.permissions.can_i_write">
                    <v-list-item-title>{{$t( 'message.menu_paste_rel1' )}}</v-list-item-title>
                    <v-tooltip bottom>
                         <template v-slot:activator="{ on }">
                            <v-icon v-on="on" @click.stop="openHelp( 'Paste+element+as+relating' )" style="font-size: 18px; padding-left: 5px; pointer-events: auto;">help</v-icon>
                         </template>
                         <span>
                             {{$t( 'message.open_help' )}}
                         </span>
                    </v-tooltip>
                  </v-list-item>
                  <v-list-item key="32" @click.stop="procFunc( 'pasteNodeRel2' )" :disabled="!globalState.nodeForPaste" v-if="!model.is_part && model.permissions.can_i_write">
                    <v-list-item-title>{{$t( 'message.menu_paste_rel2' )}}</v-list-item-title>
                    <v-tooltip bottom>
                         <template v-slot:activator="{ on }">
                            <v-icon v-on="on" @click.stop="openHelp( 'Paste+element+as+related' )" style="font-size: 18px; padding-left: 5px; pointer-events: auto;">help</v-icon>
                         </template>
                         <span>
                             {{$t( 'message.open_help' )}}
                         </span>
                    </v-tooltip>
                  </v-list-item>
                  <v-list-item key="33" @click.stop="procFunc( 'addOrgToNode' )" v-if="!model.is_part && model.permissions.can_i_write">
                    <v-list-item-title>{{$t( 'message.menu_add_org' )}}</v-list-item-title>
                    <v-tooltip bottom>
                         <template v-slot:activator="{ on }">
                            <v-icon v-on="on" @click.stop="openHelp( 'Add-Organisation' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                         </template>
                         <span>
                             {{$t( 'message.open_help' )}}
                         </span>
                    </v-tooltip>
                  </v-list-item>
                  <v-list-item key="29" @click.stop="procFunc( 'putProdToNode' )" 
                                 v-if="!model.is_part && model.permissions.can_i_write">
                    <v-list-item-title>{{$t( 'message.menu_put_prod' )}}</v-list-item-title>
                    <v-tooltip bottom>
                         <template v-slot:activator="{ on }">
                            <v-icon v-on="on" @click.stop="openHelp( 'Put-Product' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                         </template>
                         <span>
                             {{$t( 'message.open_help' )}}
                         </span>
                    </v-tooltip>
                  </v-list-item>
                  <v-list-item key="34" @click.stop="procFunc( 'genDocByTmpl' )"  v-if="!model.is_part && model.permissions.can_i_write">
                    <v-list-item-title>{{$t( 'message.menu_gen_doc' )}}</v-list-item-title>
                    <v-tooltip bottom>
                         <template v-slot:activator="{ on }">
                            <v-icon v-on="on" @click.stop="openHelp( 'Generate-document' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                         </template>
                         <span>
                             {{$t( 'message.open_help' )}}
                         </span>
                    </v-tooltip>
                  </v-list-item>
                  <v-list-item key="10" @click.stop="procFunc( 'addFileToNode' )" v-if="!model.parent.root && !model.is_part && model.permissions.can_i_write">
                    <v-list-item-title>{{$t( 'message.menu_add_file' )}}</v-list-item-title>
                    <v-tooltip bottom>
                         <template v-slot:activator="{ on }">
                            <v-icon v-on="on" @click.stop="openHelp( 'Add+data+file' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                         </template>
                         <span>
                             {{$t( 'message.open_help' )}}
                         </span>
                    </v-tooltip>
                  </v-list-item>
                  <v-list-item key="11" @click.stop="procFunc( 'pasteFileToNode' )" v-if="!model.parent.root && !model.is_part && model.permissions.can_i_write"
                               :disabled="( !globalState.fileForPaste || ( globalState.fileForPaste.parentNode.instance_id === model.instance_id ) )
                               && ( !globalState.fileForLink || ( globalState.fileForLink.parentNode.instance_id === model.instance_id ) )">
                    <v-list-item-title>{{$t( 'message.menu_paste_file' )}}</v-list-item-title>
                    <v-tooltip bottom>
                         <template v-slot:activator="{ on }">
                            <v-icon v-on="on" @click.stop="openHelp( 'Paste+data+file' )" style="font-size: 18px; padding-left: 5px; pointer-events: auto;">help</v-icon>
                         </template>
                         <span>
                             {{$t( 'message.open_help' )}}
                         </span>
                    </v-tooltip>
                  </v-list-item>
                  <v-list-item key="12" @click.stop="showNodeTree()">
                    <v-list-item-title>{{$t( 'message.menu_show_node_tree' )}}</v-list-item-title>
                    <v-tooltip bottom>
                         <template v-slot:activator="{ on }">
                            <v-icon v-on="on" @click.stop="openHelp( 'Show+element+in+context' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                         </template>
                         <span>
                             {{$t( 'message.open_help' )}}
                         </span>
                    </v-tooltip>
                  </v-list-item>
                  <v-list-item key="13" @click.stop="showBOM()">
                    <v-list-item-title v-if="model.is_requirement">{{$t( 'message.menu_show_bom_req' )}}</v-list-item-title>
                    <v-list-item-title v-else>{{$t( 'message.menu_show_bom' )}}</v-list-item-title>
                    <v-tooltip bottom>
                         <template v-slot:activator="{ on }">
                            <v-icon v-on="on" @click.stop="openHelp( 'Show-Structure-Tree' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                         </template>
                         <span>
                             {{$t( 'message.open_help' )}}
                         </span>
                    </v-tooltip>
                  </v-list-item>
                  <v-list-item key="36" @click.stop="showNodeGraph()">
                    <v-list-item-title>{{$t( 'message.menu_show_node_graph' )}}</v-list-item-title>
                    <v-tooltip bottom>
                         <template v-slot:activator="{ on }">
                            <v-icon v-on="on" @click.stop="openHelp( 'Open-connection-graph' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                         </template>
                         <span>
                             {{$t( 'message.open_help' )}}
                         </span>
                    </v-tooltip>
                  </v-list-item>
                  <v-list-item key="35" @click.stop="procFunc( 'sendMsg' )" v-if="globalState.userMsgReady">
                    <v-list-item-title>{{$t( 'message.menu_send_msg' )}}</v-list-item-title>
                    <v-tooltip bottom>
                         <template v-slot:activator="{ on }">
                            <v-icon v-on="on" @click.stop="openHelp( 'Personal-messages-(Task-or-Info-notifications)' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                         </template>
                         <span>
                             {{$t( 'message.open_help' )}}
                         </span>
                    </v-tooltip>
                  </v-list-item>
                  <v-list-item key="37" @click.stop="procFunc( 'addIssue' )">
                    <v-list-item-title>{{$t( 'message.menu_add_issue' )}}</v-list-item-title>
                    <!-- <v-tooltip bottom>
                         <template v-slot:activator="{ on }">
                            <v-icon v-on="on" @click.stop="openHelp( 'Personal-messages-(Task-or-Info-notifications)' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                         </template>
                         <span>
                             {{$t( 'message.open_help' )}}
                         </span>
                    </v-tooltip> -->
                  </v-list-item>
                  <v-list-item key="14" @click.stop="procFunc( 'showNodeHistory' )" v-if="!model.parent.root && !model.is_part">
                    <v-list-item-title>{{$t( 'message.menu_show_node_history' )}}</v-list-item-title>
                    <v-tooltip bottom>
                         <template v-slot:activator="{ on }">
                            <v-icon v-on="on" @click.stop="openHelp( 'View+Change+log' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                         </template>
                         <span>
                             {{$t( 'message.open_help' )}}
                         </span>
                    </v-tooltip>
                  </v-list-item>
                  <v-list-item key="15" @click.stop="procFunc( 'createBaseline' )" v-if="globalState.curUserRole !== 'urn:rdl:epm-std:project_member'">
                    <v-list-item-title>{{$t( 'message.menu_create_baseline' )}}</v-list-item-title>
                    <v-tooltip bottom>
                         <template v-slot:activator="{ on }">
                            <v-icon v-on="on" @click.stop="openHelp( 'Create+baseline' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                         </template>
                         <span>
                             {{$t( 'message.open_help' )}}
                         </span>
                    </v-tooltip>
                  </v-list-item>
                    
                  <v-list-item v-show="model.is_part_of_subdomain > 0" key="40" @click.stop="procFunc( 'infoSubdomain' )">
                    <v-list-item-title>{{$t( 'message.menu_info_subdomain' )}}</v-list-item-title>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" @click.stop="openHelp( 'Subdomain-info' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                        </template>
                        <span>
                             {{$t( 'message.open_help' )}}
                        </span>
                    </v-tooltip>
                  </v-list-item>
 <!----------- subDomain -->                   
                  <v-list-group :value="subG" key="20" no-action v-show="!model.parent.root && !model.is_part && globalState.projType === 'Proj' &&
                      ( globalState.curUserRole === 'urn:rdl:epm-std:project_manager' || globalState.curUserRole === 'urn:rdl:epm-std:project_leader' 
                            || globalState.curUserRole === 'urn:rdl:epm-std:project_admin' )">
                      <template v-slot:activator>
                          <v-list-item style="padding-left: 0px;">
                            <v-list-item-content>
                                <v-list-item-title>{{ $t( 'message.menu_subdomain' ) }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                      </template>
                         
                      <v-list-item v-show="!model.is_root_of_subdomain" key="21" @click.stop="procFunc( 'createSubdomain' )">
                        <v-list-item-title>{{$t( 'message.menu_create_subdomain' )}}</v-list-item-title>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" @click.stop="openHelp( 'Subdomain-management' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                            </template>
                            <span>
                                 {{$t( 'message.open_help' )}}
                            </span>
                        </v-tooltip>
                      </v-list-item>
                      <v-list-item v-show="model.is_root_of_subdomain" key="22" @click.stop="procFunc( 'deleteSubdomain' )">
                        <v-list-item-title>{{$t( 'message.menu_delete_subdomain' )}}</v-list-item-title>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" @click.stop="openHelp( 'Subdomain-management' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                            </template>
                            <span>
                                 {{$t( 'message.open_help' )}}
                            </span>
                        </v-tooltip>
                      </v-list-item>
                      <v-list-item v-show="model.is_root_of_subdomain" key="23" @click.stop="procFunc( 'subdomainUsers' )">
                        <v-list-item-title>{{$t( 'message.menu_users_subdomain' )}}</v-list-item-title>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" @click.stop="openHelp( 'Subdomain-management' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                            </template>
                            <span>
                                 {{$t( 'message.open_help' )}}
                            </span>
                        </v-tooltip>
                      </v-list-item>
                      <v-list-item v-show="model.is_part_of_subdomain || model.is_root_of_subdomain" key="24" @click.stop="procFunc( 'subdomainRoles' )">
                        <v-list-item-title>{{$t( 'message.menu_roles_subdomain' )}}</v-list-item-title>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" @click.stop="openHelp( 'Subdomain-management' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                            </template>
                            <span>
                                 {{$t( 'message.open_help' )}}
                            </span>
                        </v-tooltip>
                      </v-list-item>
                  </v-list-group>
<!----------- subDomain -->                                        
                  <v-list-item key="18" @click.stop="procFunc( 'importFileToNode' )" v-if="model.permissions.can_i_write && !model.parent.root && !model.is_part
                               && globalState.curUserRole !== 'urn:rdl:epm-std:project_member'">
                    <v-list-item-title>{{$t( 'message.menu_import' )}}</v-list-item-title>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" @click.stop="openHelp( 'Import+from+STEP+PDM+file' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                        </template>
                        <span>
                             {{$t( 'message.open_help' )}}
                        </span>
                    </v-tooltip>
                  </v-list-item>
                  <v-list-item key="19" @click.stop="procFunc( 'importAP242FileToNode' )" v-if="model.permissions.can_i_write && !model.parent.root && !model.is_part
                               && globalState.curUserRole !== 'urn:rdl:epm-std:project_member'">
                    <v-list-item-title>{{$t( 'message.menu_importAP242' )}}</v-list-item-title>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon slot="activator" @click.stop="openHelp( 'Import-from-AP242-Domain-model' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                        </template>
                        <span>
                             {{$t( 'message.open_help' )}}
                        </span>
                    </v-tooltip>
                  </v-list-item>
<!--                  <v-list-item key="29" @click.stop="procFunc( 'importCSVFileToNode' )" v-if="model.permissions.can_i_write && !model.parent.root && !model.is_part
                               && globalState.curUserRole !== 'urn:rdl:epm-std:project_member'">
                    <v-list-item-title>{{$t( 'message.menu_importCSV' )}}</v-list-item-title>
                  </v-list-item>-->
                  <v-list-item key="22" @click.stop="procFunc( 'importReqIFToNode' )" v-if="model.permissions.can_i_write && !model.parent.root && !model.is_part
                               && globalState.curUserRole !== 'urn:rdl:epm-std:project_member'">
                    <v-list-item-title>{{$t( 'message.menu_importReqIF' )}}</v-list-item-title>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon slot="activator" @click.stop="openHelp( 'Import+from+Req+IF+file' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                        </template>
                        <span>
                             {{$t( 'message.open_help' )}}
                        </span>
                    </v-tooltip>
                  </v-list-item>
                  <v-list-item key="23" @click.stop="procFunc( 'importTxtToNode' )" v-if="model.permissions.can_i_write && !model.parent.root && !model.is_part
                               && globalState.curUserRole !== 'urn:rdl:epm-std:project_member'">
                    <v-list-item-title>{{$t( 'message.menu_importTxt' )}}</v-list-item-title>
                    <!-- <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon slot="activator" @click.stop="openHelp( 'Import+from+Req+IF+file' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                        </template>
                        <span>
                             {{$t( 'message.open_help' )}}
                        </span>
                    </v-tooltip> -->
                  </v-list-item>
                  <v-list-item key="21" @click.stop="procFunc( 'exportNodeToZip' )" v-if="model.permissions.can_i_write && !model.parent.root && !model.is_part
                               && globalState.curUserRole !== 'urn:rdl:epm-std:project_member'">
                    <v-list-item-title>{{$t( 'message.menu_export_to_zip' )}}</v-list-item-title>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon slot="activator" @click.stop="openHelp( 'Export-to-zipped-folder' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                        </template>
                        <span>
                             {{$t( 'message.open_help' )}}
                        </span>
                    </v-tooltip>
                  </v-list-item>
                  <v-list-item key="24" @click.stop="procFunc( 'exportNodeToPDM' )" v-if="model.permissions.can_i_write && !model.parent.root && !model.is_part
                               && globalState.curUserRole !== 'urn:rdl:epm-std:project_member'">
                    <v-list-item-title>{{$t( 'message.menu_export_to_pdm' )}}</v-list-item-title>
                    <!-- <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon slot="activator" @click.stop="openHelp( 'Export-to-zipped-folder' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                        </template>
                        <span>
                             {{$t( 'message.open_help' )}}
                        </span>
                    </v-tooltip> -->
                  </v-list-item>
                  <v-list-item key="25" @click.stop="procFunc( 'exportNodeToAP242' )" v-if="model.permissions.can_i_write && !model.parent.root && !model.is_part
                               && globalState.curUserRole !== 'urn:rdl:epm-std:project_member'">
                    <v-list-item-title>{{$t( 'message.menu_export_to_ap242' )}}</v-list-item-title>
                    <!-- <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon slot="activator" @click.stop="openHelp( 'Export-to-zipped-folder' )" style="font-size: 18px; padding-left: 5px;">help</v-icon>
                        </template>
                        <span>
                             {{$t( 'message.open_help' )}}
                        </span>
                    </v-tooltip> -->
                  </v-list-item>
                </v-list>
<!----------->
                <v-list dense v-else-if="model.back && ( globalState.actVer )">
                    <v-list-item v-show="model.is_part_of_subdomain > 0" key="41" @click.stop="procFunc( 'infoSubdomain' )">
                    <v-list-item-title>{{$t( 'message.menu_info_subdomain' )}}</v-list-item-title>
                  </v-list-item>
                  <v-list-item key="15" @click.stop="showNodeTree()">
                    <v-list-item-title>{{$t( 'message.menu_show_node_tree' )}}</v-list-item-title>
                  </v-list-item>
                  <v-list-item key="16" @click.stop="showBOM()">
                    <v-list-item-title v-if="model.is_requirement">{{$t( 'message.menu_show_bom_req' )}}</v-list-item-title>
                    <v-list-item-title v-else>{{$t( 'message.menu_show_bom' )}}</v-list-item-title>
                  </v-list-item>
<!--                  <v-list-item key="30" @click.stop="procFunc( 'addFileToNode' )" v-if="model.permissions.can_i_write">
                    <v-list-item-title>{{$t( 'message.menu_add_file' )}}</v-list-item-title>
                  </v-list-item>
                  <v-list-item key="31" @click.stop="procFunc( 'pasteFileToNode' )" v-if="model.permissions.can_i_write"
                               :disabled="( !globalState.fileForPaste || ( globalState.fileForPaste.parentNode.instance_id === model.instance_id ) )
                               && ( !globalState.fileForLink || ( globalState.fileForLink.parentNode.instance_id === model.instance_id ) )">
                    <v-list-item-title>{{$t( 'message.menu_paste_file' )}}</v-list-item-title>
                  </v-list-item>-->
                </v-list>
<!----------->
            </v-menu>
          </div>
        </v-list-item-action>
    </v-list-item>
    
</template>

<script>

import{ conf } from '../utils/confTruePLM';

export default {
  name: 'NodeListRow', 
  props: {
      model: {},
      noMenu: false,
      versionList: []
  },
  data () {
    return {
        globalState: this.$globalStore.state,
        showMenu: false,
        showVerMenu: false,
        selectedVer: 0,
        subG: false
    };
  },
  computed: {
    verList()
    {
        let vLs = [];
        let ls = this.versionList;

        for( let i = ls.length - 1; i > -1; i-- )
        {
            vLs.push( { txt: ( 'Ver. ' + ls[ i ] + ' ' ), val: parseInt( ls[ i ] ) } );
        }

        return vLs;
    },
    chNumber()
    {
        return this.model.children_count ? this.model.children_count - this.docNumber : 0;
    },
    docNumber()
    {
        return this.model.data_contents ? this.model.data_contents.length : 0;
    },
    nodeInfo()
    {
        let info = [];
        
        let docNum = this.docNumber;
        let chNum = this.chNumber;
        
        if( chNum > 0 )
            info.push( 'Children: ' + chNum );
        else
            info.push( 'Children: none' );
        
        if( docNum > 0 )
            info.push( 'Documents: ' + docNum );
        else
            info.push( 'Documents: none' );
        
        if( this.model.is_root_of_subdomain )
            info.push( 'Root of subdomain' );
        else if( this.model.is_part_of_subdomain )
            info.push( 'Part of subdomain' );
        
        return info;
    },
    imgSrc()
    { 
        return this.$utils.getImage( this.model.element_type, this.model.quantity );
    },
    accRt()
    {
        let rez = '';
        if( this.model.permissions )
        {
            rez += this.model.permissions.can_i_read ? 'r' : '';
            rez += this.model.permissions.can_i_write ? 'w' : '';
            rez += this.model.permissions.can_i_delete ? 'd' : '';
        }
        
        return '(' + rez + ')';
    },
    modelID()
    {
        if( this.model.message_id )
            return this.model.message_id; 
        else if( this.model.occurrence && !this.model.back )
            return this.model.occurrence.instance_id;
        else
            return this.model.instance_id;
    },
    modelName()
    {
        let attr = this.$globalStore.state.renameNodeAttr[ this.model.instance_id ];
        if( !attr )
            attr = this.$globalStore.state.curRenameNodeAttr;
        
        if( !attr )
            attr = 'NameInList';
        
        if( attr )
        {
            for( let i = 0; i < this.model.properties.length; i++ )
            {
                let prop = this.model.properties[ i ];
                if( this.$utils.clearType( prop.name ) === attr )
                    return prop.val;
            }
        }
        
        if( this.model.occurrence )
            return this.model.name + ' / ' + this.model.occurrence.name;
        
//        if( this.$utils.clearType( this.model.element_type ) === 'EPTI' )
//        {
//            for( let i = 0; i < this.model.properties.length; i++ )
//            {
//                let prop = this.model.properties[ i ];
//                if( this.$utils.clearType( prop.name ) === 'HCNT' )
//                    return ( prop.val + ' ' + this.model.name );
//            }
//        }
        
        return this.model.name;        
    },
    modelHasChildren()
    {
        return !this.model.back;//( this.model.children && this.model.children.length && this.model.children.length > 0 );
    }
  },
  components: {
       
  },
  methods: 
  {
      showNodeGraph()
      {
          let proj = conf.getProj();
          let url = '#/graphvis?';
          
          window.open( url + '&rep=' + proj.repository + '&model=' + proj.in_project.project_model_id 
                  + '&node=' + this.model.instance_id + '&role=' + proj.userType, '_blank' );
      },
      openHelp( link )
      {
          this.$utils.openHelp( link );
      },
      showBOM()
      {
          this.showMenu = false;      
          this.$eventBus.$emit( 'showBOM', this.model );  
      },
      showNodeTree()
      {
          this.showMenu = false;      
          this.$eventBus.$emit( 'showNodeTree' );  
      },
      setActVer: function()
      {
          this.showMenu = false;      
          this.$eventBus.$emit( 'setActVer', this.selectedVer );  
      },
      procFunc: function( cmd )
      {
          this.showMenu = false;      
          this.$emit( 'procRowMenuCmd', [ cmd , this.model ] );       
      },      
      openMenu: function()
      {     
          if( this.model.root )
          {
            if( this.$globalStore.state.actVer )
                this.selectedVer = this.$globalStore.state.actVer - 0;
            else 
                this.selectedVer = this.model.version_id - 0;
          }      
          this.$globalStore.setSelectedID( this.modelID ); 
          this.showMenu = true; 
      },
      showInfo: function()
      {
          var self = this;
          conf.openNode( this.model )
                  .then( ( data )=>
          { 
              self.showNodeInfo( data );                  
          } );
      },              
      showNodeInfo: function( data )
      {
          this.$eventBus.$emit( 'nodeInfo', data ); 
          var nodeID = data.instance_id;
          if( data.message_id )
            nodeID = data.message_id;     
          this.$globalStore.setSelectedID( nodeID );
//          console.log( this.globalState.selectedID );
      },
      toggleNode: function()
      {
   //                 console.log( 'node', this.model );
          this.showMenu = false;
          if( this.globalState.selectedID === this.modelID )
          {
              if( this.modelHasChildren )
                  this.$eventBus.$emit( 'goInNode', this.model );       
              else if( this.model.back )
                  this.$eventBus.$emit( 'goOutNode', this.model.parent );       
          }
          else
          {
              if( this.model.elemID )
              {
                    this.$globalStore.setSelectedID( this.model.elemID );  
                    this.$eventBus.$emit( 'updateProd', this.model );
              }
              else
              {
                    this.$globalStore.setSelectedID( this.modelID );  
                    this.$eventBus.$emit( 'showSelProps', this.model );
              }
          }
      }
  },
  mounted: function() 
  { 
     //console.log( this.model );
  },   
  beforeDestroy: function()
  {

  }
}
</script>

<style scoped>
    .select
    {
        font-weight: 700;
    }
    .sub
    {
        color: green;
        font-style: italic;
    }
    .gray
    {
        color: gray;
    }
    .back
    {
        font-weight: 600;
        font-size: 20px;
    }
    .selectCl
    {
        font-size: 13px;
    }
    
</style>
