<template>
        <v-dialog v-model="visFl" persistent max-width="290">
           <v-card>
            <v-card-title class="headline" v-if="edit">
              {{ $t( 'message.edit_org_title' ) }}
            </v-card-title>
            <v-card-title class="headline" v-else>
              {{ $t( 'message.add_org_title' ) }}
            </v-card-title>
            <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-text-field :label="$t( 'message.add_org_title' )" v-model="info.title" required
                                  :rules="[v => !!v || $t( 'message.value_required' )]"></v-text-field>
                    <v-text-field :label="$t( 'message.add_org_uid' )" v-model="info.uid"></v-text-field>
                    <v-text-field :label="$t( 'message.add_org_address' )" v-model="info.address"></v-text-field>
                    <v-text-field :label="$t( 'message.add_org_email' )" v-model="info.email"
                                  :rules="emailRules"></v-text-field>
                    <v-text-field :label="$t( 'message.add_org_url' )" v-model="info.url"></v-text-field>
                    <v-text-field :label="$t( 'message.add_org_phone' )" v-model="info.phone"></v-text-field>
                    
                    <v-textarea :label="$t( 'message.add_org_description' )" v-model="info.description"></v-textarea>
                </v-form>
                <!--<small>*{{ $t( 'message.req_info_msg' ) }}</small>-->
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
                <v-btn id="addOrgOk" color="green darken-1" text :disabled="!valid" @click.native="doAct()">{{ $t( 'message.ok_btn' ) }}</v-btn>
                <v-btn id="addOrgClose" color="green darken-1" text @click.native="close()">{{ $t( 'message.cancel_btn' ) }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>   
</template>

<script>
    
import Vue from 'vue'
import{ conf } from '@/utils/confTruePLM';

export default {
  name: 'addOrg',
  data () 
  {
    return {
        emailRules: [
            v => ( !v || /.+@.+\..+/.test(v) ) || this.$t( 'message.value_valid' )
          ],
        urlRules: [
            v => ( !v || /.+@.+\..+/.test(v) ) || this.$t( 'message.value_valid' )
          ],
        visFl: false,
        info: {},
        resolve: null,
        reject: null,
        valid: true,
        edit: false
    };
  },
  computed: {
  },
  methods: {
      open( data )
      {
          if( data )
              this.info = JSON.parse( JSON.stringify( data ) );
          else
              this.info = {}
          
          this.edit = !!this.info.org_id;
          
          this.visFl = true;
          
          return new Promise( ( resolve, reject ) => 
          {
            this.resolve = resolve;
            this.reject = reject;
          });
      },
      close()
      {
          this.visFl = false;
          this.reject();
      },
      doAct()
      {
          if( this.$refs.form.validate() )
          {
            this.visFl = false;
            this.resolve( this.info );
          }
      }
    }
}
</script>



