<template>
    <li class="tree_node">
        <div class="v-list-item v-list-item--link theme--light">
            <div class="v-list-item__action" style="width: 27px; margin-right: 0;">
                <span v-if="node.subtypes && node.subtypes.length > 0" @click="toggleNode" style="cursor: pointer;">
                    <transition name="arrow" >
                        <v-icon medium v-bind:class="{ rot_trans: !opened }">arrow_drop_down</v-icon>
                    </transition>
                </span>
                <span v-else style="width: 27px;">
                    
                </span>
            </div>
            <div class="v-list-item__content" v-bind:class="{ select: ( node.selected ) }"> 
                <div>
                    {{node.name}}
                </div>
            </div>
            <div class="v-list-item__action">
                <v-tooltip bottom v-if="!readOnly">
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on" small @click="addItem()">add</v-icon>
                    </template>
                    <span>{{ $t( 'message.prop_type_add_btn' ) }}</span>
                </v-tooltip>
                <v-tooltip bottom v-if="!node.inUse">
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on" small @click="deleteItem()">delete</v-icon>
                    </template>
                    <span>{{ $t( 'message.prop_type_del_btn' ) }}</span>
                </v-tooltip>
                <span v-else style="width: 15px"></span>
            </div>
        </div>
        <ul v-show="!!opened" class="tree_view">
             <tree-row-view v-for="( el, ind ) in node.subtypes" :pkey="pkey + ' ' + ind"
                            :key="pkey + ' ' + ind" :node="el" :readOnly="readOnly"></tree-row-view>                    
        </ul>
    </li>
</template>

<script>
    
import{ conf } from '../../utils/confTruePLM';
    
export default {
  name: 'tree-row-view',
  props: {
      node: { sybtypes:[] },
      pkey: 0,
      readOnly: false
  },
  data () 
  {
    return {
        //node: this.model,
        opened: true
    };
  },
  computed: 
  {
   
  },
  methods: 
  {
      toggleNode()
      {
          if( this.opened )
              this.closeNode();
          else
              this.openNode();
      },
      closeNode()
      {
          this.opened = false; 
      },
      openNode()
      {
         this.opened = true;
      },
      addItem()
      {
          this.$eventBus.$emit( 'addBkdProp', this.node );
      },
      deleteItem()
      {
          this.$eventBus.$emit( 'deleteBkdProp', this.node );
      }
  },
  components: {
  },
  mounted: function()
  {
  },
  beforeDestroy: function()
  {
  }
}
</script>

<style scoped>
    .tree_view ol, ul
    {
        padding-left: 15px;
        padding-right: 0px; 
    }
    
    .tree_view span
    {
        padding-right: 0px; 
    }
    
    .tree_view 
    {
        padding-right: 0px; 
        list-style-type: none;
    }
    
    .select
    {
        font-weight: 700;
    }
    
    .flip-list
    {
        transition: transform 1s;
    }
    
    .ch-list-enter-active, .ch-list-leave-active 
    {
        transition: opacity .5s;
    }
    .ch-list-enter, .ch-list-leave-to
    {
        opacity: 0;
    }
    
    .arrow-enter-active, .arrow-leave-active 
    {
        transition: transform 1.3s;
    }
    .rot_trans
    {
        transform: rotate( -90deg );
    }
    
    .tree_node .v-list-item__action
    {
        margin-right: 0px;
        margin-top: 0px;
    }

    .tree_node 
    {
        font-size: 12px;        
    }
   
</style>
