<template>
            <v-dialog v-model="visFl" persistent max-width="290">
               <v-card>
                <v-card-title class="headline">
                    <span v-if='isNew'>{{ $t( 'message.create_prop_title' ) }}</span>
                    <span v-else>{{ $t( 'message.edit_prop_title' ) }}</span>
                </v-card-title>
                <v-card-text>
                    <span v-show="isUsed" style="color: red;">{{$t( 'message.prop_in_use' )}}</span>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-select :label="$t( 'message.create_prop_type' )" required item-text="txt" item-value="val" v-model="propType" :readonly="!isNew && isUsed"
                            :items="types" :rules="[v => !!v || $t( 'message.value_required' )]"></v-select>
                        
                        <v-select :label="$t( 'message.create_prop_type_elem' )" required item-text="txt" item-value="val" v-model="propElemType" :readonly="!isNew && isUsed"
                            :items="listTypes" :rules="[v => !!v || $t( 'message.value_required' )]" v-if="propType === 'List'"></v-select>
                        
                        <v-text-field :label="$t( 'message.create_prop_name' )" required v-model="propName" :readonly="!isNew"
                                :rules="[v => !!v || $t( 'message.value_required' )]"></v-text-field>
                                                
                        <v-text-field :label="$t( 'message.create_prop_vals' )" v-model="propVals" v-show="propType === 'oneOf'"></v-text-field>
                        
                        <v-text-field :label="$t( 'message.create_prop_units' )" v-model="propUnits" v-show="propType === 'Numeric_value'"></v-text-field>
                        
                        <v-checkbox :label="$t( 'message.create_prop_readonly' )" v-model="readOnly"></v-checkbox>
                        <v-checkbox :label="$t( 'message.create_prop_mandatory' )" v-model="mandatory"></v-checkbox>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                    <v-btn id="createPropOk" color="green darken-1" text :disabled="!valid" @click.native="doAct()">{{ $t( 'message.ok_btn' ) }}</v-btn>
                    <v-btn id="createPropClose" color="green darken-1" text @click.native="close()">{{ $t( 'message.cancel_btn' ) }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>   
</template>

<script>
    
import Vue from 'vue'

export default {
  name: 'createProp',
  data () 
  {
    return {
        visFl: false,
        propVals: null,
        propName: null,
        propType: null,
        propElemType: null,
        propUnits: null,
        propUrn: null,
        readOnly: false,
        mandatory: false,
        isNew: false,
        canDel: true,
        isUsed: false,
        types: [],
        domainMap: { extended_counter: ' urn:plcs:rdl:TruePLM:extended_counter', formatted_counter: ' urn:plcs:rdl:TruePLM:formatted_counter',
            counter: ' urn:plcs:rdl:TruePLM:counter', formatted_serial: ' urn:plcs:rdl:TruePLM:formatted_serial', serial: ' urn:plcs:rdl:TruePLM:serial'},
        listTypes: [],
        resolve: null,
        reject: null,
        valid: true
    };
  },
  computed: {
  },
  components: {
  },
  methods: {
      open( prop, lists, isForNode )
      {
         // console.log( prop );
          this.isNew = !prop;
          this.isUsed = prop ? prop.obj.inUse : false;
          this.canDel = prop ? prop.canDel : true;
          this.propUrn = prop ? prop.obj.urn : null;
          this.propName = prop ? prop.val_view : null;
          this.propVals = prop ? prop.vals : null;
          this.propType = null;
          if( prop )
          {
              if( prop.obj.domains.length > 1 )
              {
                  this.propType = 'List';
                  this.propElemType = prop.obj.cur_type;
              }
              else
                  this.propType = this.$utils.clearType( prop.obj.cur_type );
          }
          
          this.propUnits = prop ? prop.units : null;
          this.readOnly = prop ? ( prop.obj.editable_for && prop.obj.editable_for.length > 0 ) : false;
          this.mandatory = prop ? prop.isMandatory : false;
          
          this.types = [ { txt: 'Text', val: 'Text_value' },
                         { txt: 'Numeric', val: 'Numeric_value' },
                         { txt: 'Date', val: 'Date_time' },
                         { txt: 'Enumeration', val: 'oneOf' },
                         { txt: 'Boolean', val: 'Boolean' } ];
          if( isForNode )
            this.types.push( { txt: 'Formatted Counter', val: 'formatted_counter' },
                               { txt: 'Extended Counter', val: 'extended_counter' },
                               { txt: 'Formatted Serial', val: 'formatted_serial' },
                               { txt: 'Serial', val: 'serial' },
                               { txt: 'Counter', val: 'counter' } );
                     
          this.listTypes = [ { txt: 'Text', val: 'Text_value' },
                             { txt: 'Numeric', val: 'Numeric_value' },
                             { txt: 'Date', val: 'Date_time' } ];                  
                         
          if( lists )
          {
              this.types.push( { txt: 'List', val: 'List' } );
                         
              lists.forEach( ( val ) =>
              {
                  this.listTypes.push( { val: val, txt: this.$utils.clearType( val ) } );
              });          
          }
          this.visFl = true;
          
          return new Promise( ( resolve, reject ) => 
          {
            this.resolve = resolve;
            this.reject = reject;
          });
      },
      close()
      {
          this.visFl = false;
          this.reject();
      },
      doAct()
      {
          if( this.$refs.form.validate() )
          {
              this.visFl = false;
              
              this.resolve( { type: this.propType, vals: this.propVals, units: this.propUnits, 
                  domain: this.domainMap[ this.propType ], name: this.propName, readOnly: this.readOnly, 
                  urn: this.propUrn, elemType: this.propElemType, mandatory: this.mandatory } );
          }
      }
    }
}
</script>



