<template>
    
<!--      <v-container fluid fill-height>
          <v-layout align-center justify-center>-->
            <v-dialog v-model="visFl" persistent width="auto" max-width="800" @keydown.esc="close" @keydown.enter="doAct">
              <v-card>
                <v-card-title class="headline">
                  {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${title}`, { val: titleData } ) } ) }}
                </v-card-title>
                <v-card-text>
<!--                    <v-container grid-list-md>
                        <v-layout wrap>
                          <v-flex xs12>-->
                          <v-form ref="form" v-model="valid" lazy-validation>
                              <v-otp-input v-if="type === 'otp'" length="6" type="number" v-model="oneVal" required></v-otp-input>
                              <v-checkbox v-else-if="type === 'check'" :label="$t( 'message.dlg_custom_msg', { cur_msg: $t(`${msg}`, { val: msgData } ) } )" 
                                                                                    required v-model="oneVal"></v-checkbox>
                              <v-select v-else-if="type === 'select'" :label="$t( 'message.dlg_custom_msg', { cur_msg: $t(`${msg}`, { val: msgData } ) } )"
                                        item-text="txt" item-value="val" v-model="oneVal" :items="items" required></v-select>
                              <v-text-field v-else :label="$t( 'message.dlg_custom_msg', { cur_msg: $t(`${msg}`, { val: msgData } ) } )" 
                                      required v-model="oneVal" :type="type" :rules="[v => !!v || $t( 'message.value_required' )]"></v-text-field>
                              
                          </v-form>
<!--                          </v-flex>
                        </v-layout>
                    </v-container>-->
                    <!--<small>*{{ $t( 'message.req_info_msg' ) }}</small>-->
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                    <v-btn id="oneValOk" color="green darken-1" text @click.native="doAct" :disabled="!valid">{{ $t( 'message.ok_btn' ) }}</v-btn>
                    <v-btn id="oneValClose" color="green darken-1" text @click.native="close">{{ $t( 'message.cancel_btn' ) }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>   
<!--          </v-layout>
      </v-container>-->
    
</template>

<script>
    
import Vue from 'vue'

export default {
  name: 'confirmOneVal',
  data () 
  {
    return {
        visFl: false,
        titleVal: 'confirm_ttl',
        msgVal: 'confirm_msg',
        msgData: null,
        titleData: null,
        type: 'text',
        resolve: null,
        reject: null,
        oneVal: null,
        valid: true,
        items: []
    };
  },
  computed: {
      title() 
      {
           return 'message.' + this.titleVal;
      },
      msg() 
      {
           return 'message.' + this.msgVal;
      }
  },
  methods: {
      open( titleIn, msgIn, curVal, titleData, msgData, valType, valList, checkVal )
      {
          this.titleVal = titleIn || 'confirm_ttl';
          this.msgVal = msgIn || 'confirm_msg';     
          this.oneVal = curVal;
          this.titleData = titleData;
          this.msgData = msgData;
          this.valid = true;
          this.visFl = true;
          
          if( valType === 'pass' )
              this.type = 'password';
          else if( valType === 'check' )
          {
              this.type = 'check';    
              this.oneVal = !!checkVal;
              console.log( this.oneVal )
          }
          else if( valType === 'select' && valList )
          {
              this.type = 'select';
              this.items = valList;
          }
          else if( valType === 'otp' )
              this.type = 'otp';
          else
              this.type = 'text';    
          
          return new Promise( ( resolve, reject ) => 
          {
            this.resolve = resolve;
            this.reject = reject;
          });
      },
      close()
      {
          this.visFl = false;
          this.reject();
      },
      doAct()
      {
          if( this.$refs.form.validate() )
          {
                this.visFl = false;
                this.resolve( this.oneVal );
          }
      }
    }
}
</script>



