<template>
    
<!--      <v-container fluid fill-height>
          <v-layout align-center justify-center>-->
              <v-dialog :value="visFl" max-width="330" persistent>
                <v-card>
                  <v-card-title class="headline">
                    {{ $t( 'message.sub_info_ttl' ) }}
                  </v-card-title>
                  <v-card-text>
                      <div v-if="!info">
                          {{ $t( 'message.no_subinfo_msg' ) }}
                      </div>
                      <div v-else>
                          <div><strong>{{ $t( 'message.sub_create_user' ) }} </strong> {{info.created_by_user.user_name}}</div>
                          <br>
                          <div><strong>{{ $t( 'message.sub_create_date' ) }} </strong> {{ $utils.getLocalDateFromUTC( info.creation_date ) }}</div>
                          <br>
                          <div><strong>{{ $t( 'message.sub_lead_user' ) }} </strong> {{info.project_leader.user_name}}</div>
                      </div>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                      <v-btn color="green darken-1" text @click.native="close">{{ $t( 'message.ok_btn' ) }}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
<!--          </v-layout>
      </v-container>-->
   
</template>

<script>
    
export default {
  name: 'sub-info',
  data () 
  {
    return {
        visFl: false,
        info: null,
    };
  },
  computed: {
  },
  methods: {
      close()
      {
          this.visFl = false;
          this.info = null;
      },
      open( node )
      {
          this.visFl = true;
          let subs = this.$globalStore.state.subdomainsInfo;
          for( let i = 0; subs && i < subs.length; i++ )
          {
              if( node.is_part_of_subdomain === subs[ i ].root_bkdn_elem.instance_id )
              {
                  this.info = subs[ i ];
                  break;
              }
          }
      }
  },
  mounted: function() 
  { 
  },
  beforeDestroy: function()
  {
  }
}
</script>



