<template>
    
<!--      <v-container fluid fill-height>
          <v-layout align-center justify-center>-->
            <v-dialog v-model="visFl" persistent max-width="800">
                <v-toolbar flat color="white">
                    <v-toolbar-title>{{ $t( 'message.sticky_notes_title' ) }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn color="green" left dark @click.native="addNew()" v-if="node.permissions.can_i_write">{{ $t( 'message.new_btn' ) }}</v-btn>
                </v-toolbar>
               <v-card>
<!--                <v-card-title class="headline">
                  {{ $t( 'message.sticky_notes_title' ) }}
                </v-card-title>-->
                <v-card-text>
                    <v-list >
                        <v-list-item v-for="(note, index) in notes" :key="note.time"  @click="">
                            <v-list-item-content>
                                <v-list-item-subtitle>
                                    {{ note.comment_by }}, {{ getNoteTime( note.time ) }}
                                </v-list-item-subtitle>
                                {{note.note_content}}
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-icon @click="deleteNote( note )">delete_forever</v-icon>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                    <div v-show="newNote">
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <v-textarea required v-model="content" 
                                                :rules="[v => !!v || $t( 'message.value_required' )]"></v-textarea>                        
                             <v-btn small color="green darken-1" text :disabled="!valid" @click="submitNewNote()">{{ $t( 'message.submit_btn' ) }}</v-btn>
                             <v-btn small color="green darken-1" text @click="cancelNewNote()">{{ $t( 'message.cancel_btn' ) }}</v-btn>
                        </v-form>
                    </div>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click.native="close()">{{ $t( 'message.close_btn' ) }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>   
<!--          </v-layout>
      </v-container>-->
    
</template>

<script>
    
import Vue from 'vue'
import{ conf } from '../../utils/confTruePLM';

export default {
  name: 'stickyNotes',
  data () 
  {
    return {
        visFl: false,
        notes: [],
        file: null,
        node: { permissions: {} },
        ver: null,
        changed: false,
        content: '',
        valid: true,
        newNote: false
    };
  },
  computed: {
  },
  methods: {
      submitNewNote()
      {
          if( this.$refs.form.validate() )
          {
            var self = this;
            self.newNote = false;          
            conf.addStickyNote( self.file, self.ver, self.content )
                    .then( ( rez ) =>
            {
                if( !rez.error_code )
                {
                    self.notes.push( rez );
                    self.changed = true;
                }              
            })
                    .catch( () => {} );
          }
      },
      cancelNewNote()
      {
          this.newNote = false;          
      },
      addNew()
      {
          this.content = '';
          this.newNote = true;          
      },
      getNoteTime( val )
      {
          return this.$utils.getStringDate( val );                   
      },
      deleteNote( note )
      {
          let self = this;
          this.$modalDlg.sysDlgs.confirmDlg.open( 'conf_del_note_title', 'conf_del_msg' )
                  .then( () =>
          {
              return conf.deleteStickyNote( note.note_id );
          })
                  .then( ( rez ) => 
          {
              if( !rez.error_code )
              {
                  let ind = self.notes.indexOf( note );
                  if( ind > -1 )
                      self.notes.splice( ind, 1 );
                  self.changed = true;
              }
          })
                  .catch( () => {} );
      },
      open( file, node )
      {
          this.file = file;
          this.node = node;
          this.ver = file.versions[ file.versions.length - 1 ];
          this.notes = this.ver.sticky_notes || [];
          this.visFl = true;
          this.newNote = false;
          this.changed = false;
          this.content = '';
          
          return new Promise( ( resolve, reject ) => 
          {
            this.resolve = resolve;
            this.reject = reject;
          });
      },
      close()
      {
          this.visFl = false;
          this.resolve( this.changed );
      }
    }
}
</script>



