<template>
            <v-dialog v-model="visFl" persistent max-width="690">
               <v-card>
                <v-card-title class="headline">
                    <span v-if='isNew'>{{ $t( 'message.create_dprop_title' ) }}</span>
                    <span v-else>{{ $t( 'message.edit_dprop_title' ) }}</span>
                </v-card-title>
                <v-card-text>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-row>
                            <v-col>
                                <v-select :label="$t( 'message.create_prop_type' )" required item-text="txt" item-value="val" v-model="type" :readonly="!isNew"
                                    :items="types" :rules="[v => !!v || $t( 'message.value_required' )]"></v-select>                                
                            </v-col>
                            <v-col>
                                <v-text-field :label="$t( 'message.create_prop_name' )" required v-model="prop.name" :readonly="!isNew"
                                    :rules="[v => !!v || $t( 'message.value_required' )]"></v-text-field>                                
                            </v-col>
                        </v-row>
                        <v-row  align="center">
                            <v-col>
                                <v-text-field :label="$t( 'message.create_prop_units' )" v-model="prop.units" v-show="type === 'Numeric_value'"></v-text-field>                                
                            </v-col>
                            <v-col>
                                <v-row align="center">
                                    <v-col>
                                        <v-select :label="$t( 'message.create_prop_prop' )" item-text="txt" item-value="val" v-model="propFromList" :items="propList"></v-select>                                           
                                    </v-col>
                                    <v-col cols="3">
                                        <v-btn small @click.native="addProp()">{{ $t( 'message.create_prop_add' ) }}</v-btn>                                        
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-textarea :label="$t( 'message.create_prop_exp' )" required v-model="propExp" 
                                    :hint="expHint" persistent-hint
                                        :rules="[v => !!v || $t( 'message.value_required' )]"></v-textarea>
<!--                        <v-select :label="$t( 'message.search_prop_name' )" v-model="prop.name" 
                                        item-text="txt" item-value="val" :items="props" clearable></v-select>
                        <v-btn small @click.native="addProp()">{{ $t( 'message.search_prop_add_btn' ) }}</v-btn>-->
                    </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text :disabled="!valid" @click.native="doAct()">{{ $t( 'message.ok_btn' ) }}</v-btn>
                    <v-btn color="green darken-1" text @click.native="close()">{{ $t( 'message.cancel_btn' ) }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>   
</template>

<script>
    
import Vue from 'vue'
import{ conf } from '../../utils/confTruePLM';

export default {
  name: 'createDProp',
  data () 
  {
    return {
        visFl: false,
        propFromList: null,
        propExp: null,
        prop: {},
        props: [],
        types: [],
        type: null,
        isNew: false,
        resolve: null,
        reject: null,
        valid: true,
        propList: []
    };
  },
  computed: {
      expHint()
      {
          return ( this.type === 'Text_value' ) ? this.$t( 'message.create_prop_exp_text' ) : '';
      }
  },
  components: {
  },
  methods: {
      addProp()
      {
          if( !this.propFromList )
              return;
          
          if( this.propExp )
              this.propExp += ' + ';
          else
              this.propExp = '';
          
          this.propExp += 'ATTR(\'' + this.propFromList + '\')';                              
      },
      open( prop, propList )
      {
          this.propList = propList;
          this.isNew = !prop;
          this.prop.urn = prop ? prop.obj.urn : null;
          this.prop.name = prop ? prop.val_view : null;
          this.type = prop ? this.$utils.clearType( prop.obj.cur_type ) : null;
          this.prop.units = prop ? prop.units : null;
          this.propExp = prop ? prop.exp : null;
          this.readOnly = prop ? ( prop.obj.editable_for && prop.obj.editable_for.length > 0 ) : false;
          
           this.types = [ { txt: 'Text', val: 'Text_value' },
                         { txt: 'Numeric', val: 'Numeric_value' },
                         { txt: 'Date', val: 'Date_time' },
                         { txt: 'Enumeration', val: 'oneOf' },
                         { txt: 'Boolean', val: 'Boolean' } ];
          
          this.visFl = true;
          
          return new Promise( ( resolve, reject ) => 
          {
              this.resolve = resolve;
              this.reject = reject;
          });
      },
      close()
      {
          this.visFl = false;
          this.reject();
      },
      doAct()
      {
          if( this.$refs.form.validate() )
          {
              this.visFl = false;
              this.prop.type = this.type;  
              this.prop.exp = this.propExp;
              this.resolve( this.prop );
          }
      }
    }
}
</script>



