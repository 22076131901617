var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.visFl),callback:function ($$v) {_vm.visFl=$$v},expression:"visFl"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$t( 'message.checkin_file_title' ))+" ")]),_c('v-card-text',[_c('file-upload',{ref:"fileUpload",attrs:{"rules":[ ( v ) => 
                            { 
                                if( v && v.length > 0 ) 
                                {
                                    if( v[ 0 ].name !== _vm.info.prevFileName ) 
                                        return _vm.$t( 'message.checkin_file_wrong_name' );
                                    else
                                        return true;
                                }
                                else
                                    return _vm.$t( 'message.file_required' );
                            } ]}}),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-textarea',{attrs:{"label":_vm.$t( 'message.create_node_descr' ),"required":"","rules":[v => !!v || _vm.$t( 'message.value_required' )]},model:{value:(_vm.info.descr),callback:function ($$v) {_vm.$set(_vm.info, "descr", $$v)},expression:"info.descr"}}),_c('v-select',{attrs:{"label":_vm.$t( 'message.add_file_node_status' ),"required":"","item-text":"txt","item-value":"val","items":_vm.statuses,"rules":[v => !!v || _vm.$t( 'message.value_required' )]},model:{value:(_vm.info.statusVal),callback:function ($$v) {_vm.$set(_vm.info, "statusVal", $$v)},expression:"info.statusVal"}}),_c('v-checkbox',{attrs:{"label":_vm.$t( 'message.checkin_file_new_issue' )},model:{value:(_vm.info.isNewIssue),callback:function ($$v) {_vm.$set(_vm.info, "isNewIssue", $$v)},expression:"info.isNewIssue"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":"","disabled":!_vm.valid || ( _vm.$refs.fileUpload && _vm.$refs.fileUpload.items.length < 1 )},nativeOn:{"click":function($event){return _vm.doAct()}}},[_vm._v(_vm._s(_vm.$t( 'message.ok_btn' )))]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},nativeOn:{"click":function($event){return _vm.close()}}},[_vm._v(_vm._s(_vm.$t( 'message.cancel_btn' )))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }