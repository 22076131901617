<template>
    <v-card flat>
      <ul class="v-breadcrumbs" style="justify-content: flex-start;">
        <li v-for="( item, index ) in path" :key="item.instance_id">
            <a class="v-breadcrumbs__item" @click="toNode( index )" v-bind:class="{ active: ( index === ( path.length - 1 ) || item.elemID || ( item.instance_id < 1 ) ) }">{{ modelName( item ) }}</a>
            <v-icon>chevron_right</v-icon>
        </li>
      </ul>
<!--      <span v-for="( node, index ) in path" class="item">
          <span class="title" v-bind:class="{ titleFirst: ( index === 0 ), titleLast: ( index === ( path.length - 1 ) ), active: ( index === ( path.length - 1 ) ) }" @click="toNode( index )">{{node.name}}</span>
          <span class="sep" v-show="index !== ( path.length - 1 )">/</span>
          <span class="sep"></span>
      </span>-->
    </v-card>
</template>

<script>
    
    import{ conf } from '../utils/confTruePLM';
    
export default {
  name: 'PathEl',
  props: {
      noSave: false
  },
  data () {
    return {
        path: []    
    };
  },
  components: {
  },
  methods: 
  {
      modelName: function( model )
      {
            var rez = model.name;
            if( model.alt_name )
                rez = model.alt_name;
            else if( model.root && this.$globalStore.state.actVer )
                rez += ' (ver.' + this.$globalStore.state.actVer + ')';
            else if( model.root && !this.$globalStore.state.actVer )
                rez += ' (ver.' + model.version_id + ')';
            return rez;
      },
      toNode: function( index )
      {
          if( index == 0 && this.noSave )
              return;
          
          if( index < ( this.path.length - 1 ) 
                  && ( this.path[ index ].isProj || this.path[ index ].instance_id > 0 ) )
          {
              this.$eventBus.$emit( 'goInNode', this.path[ index ] ); 
              this.path.splice( index + 1, Number.MAX_VALUE );
          }
      },
      saveCurrentPath: function()
      {
          if( this.noSave )
              return;
          
          var ids = [];
          for( var i = 1; i < this.path.length; i++ )
              ids.push( this.path[ i ].instance_id );
          
          localStorage.setItem( 'curr_path', JSON.stringify( ids ) );
      }
  },
  mounted: function() 
  { 
      var self = this;
      self.$eventBus.$on( 'selectedModel', function()
      {
          var model = conf.getProj();
          model.in_project.isProj = true;
          self.path = [ model.in_project ];
          self.saveCurrentPath();
      });
      
      self.$eventBus.$on( 'goInProd', function( model )
      {
          if( self.path[ self.path.length - 1 ].elemID !== model.elemID )
              self.path.push( model );
          self.saveCurrentPath();
      });
      
      self.$eventBus.$on( 'goInNode', function( model )
      {
          if( self.path[ self.path.length - 1 ].instance_id !== model.instance_id )
              self.path.push( model );
          self.saveCurrentPath();
      });
      
      self.$eventBus.$on( 'goOutNode', function( model )
      {
          self.path.pop();
          self.saveCurrentPath();
      });
      
      self.$eventBus.$on( 'showNodeTree', function()
      {
          self.$eventBus.$emit( 'showPathAsTree', self.path );  
      });
      
      self.$eventBus.$on( 'showBOM', function()
      {
          self.$eventBus.$emit( 'showBOMFromPath', self.path );  
      });
      
      self.$eventBus.$on( 'openBaseline', function( bsl )
      {
          self.path.splice( 1, Number.MAX_VALUE, { name: 'Baseline', instance_id: 0, version_id: bsl.root_bkdn_elem.version_id } );
          bsl.root_bkdn_elem.alt_name = bsl.id;
          self.path.push( bsl.root_bkdn_elem );
      });
      
      self.$eventBus.$on( 'setPath', function( newPath )
      {
          newPath.unshift( self.path[ 0 ] );
          self.path = newPath;
          self.saveCurrentPath();
      });
  },   
  beforeDestroy: function()
  {
      this.$eventBus.$off( 'openBaseline' );
      this.$eventBus.$off( 'selectedModel' );
      this.$eventBus.$off( 'goInProd' );
      this.$eventBus.$off( 'goInNode' );
      this.$eventBus.$off( 'goOutNode' );
      this.$eventBus.$off( 'setPath' );
      this.$eventBus.$off( 'showNodeTree' );
      this.$eventBus.$off( 'showBOM' );
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    
    .path
    {
        /*margin-left: 0.1em;*/
        text-align: left;
        font-size: 14px;
    }
    
    .sep
    {
/*        margin-left: 0.1em;
        margin-right: 0.3em;
        margin-bottom: 2em;*/
    }
    
    .item
    {
        line-height: 2.4em;
    }
    
    .title
    {
        white-space: nowrap;
        cursor: pointer;
        
        background: #eef1f6;
	text-align: center;
        padding: 0.5em 1em 0.5em 2em;
	position: relative;
        margin-left: 0.2em;
	
	font-size: 8px;
	text-decoration: none;
	color: #48576a;
    }
    
    .title:before 
    {
	content: "";  
	border-top: 1em solid transparent;
	border-bottom: 1em solid transparent;
	border-left: 1em solid #fff;
	position: absolute; left: 0; top: 0;
    }
    
    .title:after
    {
        content: "";  
	border-top: 1em solid transparent;
	border-bottom: 1em solid transparent;
	border-left: 1em solid #eef1f6;
	position: absolute; right: -1em; top: 0;
        z-index: 1;
    }
    
    .titleFirst
    {
	border-top-left-radius: 10px; border-bottom-left-radius: 10px;
    }
    .titleFirst:before
    {
        display: none;
    }
    .titleLast:after
    {
        display: none;
    }
    .titleLast
    {
        padding-right: 1em;
        border-top-right-radius: 10px; border-bottom-right-radius: 10px;
    }
          
    .active
    {
        color: #555555;
        cursor: text;
    }
    
    
</style>
