<template>
        <div>
            <tool-bar-view type="ntasks"></tool-bar-view>   
            
            <v-main class="main_correction">
                <v-container fill-height fluid v-show="globalState.curFolderName" style="padding-top: 0px; padding-bottom: 24px; margin-top: -5px;">
                 <v-layout row wrap>
                  <v-flex v-show="$vuetify.breakpoint.mdAndUp" lg12 md12>
                      <path-el-view no-save="true"></path-el-view>
                  </v-flex>

                  <v-card  width="100%">
                      <v-tabs show-arrows ref="tabs">
                        <v-tab href="#tab-1">
                            {{ $t( 'message.ntasks_tab' ) }}
                        </v-tab>
                        <v-tab-item value="tab-1">
                            <v-data-table :headers="heads" :items="items" :sort-by="sortProp" :sort-desc=true 
                                        hide-default-footer class="elevation-1 propTbl"
                                        :items-per-page=-1 fixed-header height="calc(100vh - 130px)">
                            <template v-slot:header.serial="{ header }">
                                {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
                            </template>
                            <template v-slot:header.action.name="{ header }">
                                {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
                            </template>
                             <template v-slot:header.action.action_type="{ header }">
                                {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
                            </template>
                            <template v-slot:header.event.name="{ header }">
                                {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
                            </template>
                            <template v-slot:header.event.timestamp="{ header }">
                                {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
                            </template>
                             <template v-slot:header.resolved_subj="{ header }">
                                {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
                            </template>
                            <template v-slot:header.resolved_message="{ header }">
                                {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
                            </template>
                             <template v-slot:header.open="{ header }">
                                {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
                            </template>
                            <template v-slot:header.act="{ header }">
                               
                            </template>

                            <template v-slot:item="{ item }">
                                <tr>
                                      <td>{{ item.serial }}</td>
                                      <td>{{ item.action.name }}</td>
                                      <td>{{ item.action.action_type === 'SEND_EMAIL' ? 'EMail' : 'Notification' }}</td>
                                      <td>{{ item.event.name }}</td>
                                      <td>{{ item.event.timestamp }}</td>
                                      <td>{{ item.resolved_subj }}</td>
                                      <td>{{ item.resolved_message }}</td>
                                      <td>{{ item.open ? 'Open' : 'Closed' }}</td>
                                      <td nowrap>
                                          <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-icon v-on="on" @click="delItem( item )">delete_forever</v-icon>
                                                </template>
                                                <span>{{ $t( 'message.ntasks_delete_btn' ) }}</span>
                                          </v-tooltip>
                                          <v-tooltip bottom v-if="!item.open">
                                                <template v-slot:activator="{ on }">
                                                    <v-icon v-on="on" @click="actItem( item )">restore</v-icon>
                                                </template>
                                                <span>{{ $t( 'message.ntasks_act_btn' ) }}</span>
                                          </v-tooltip>
                                          
                                      </td>
                                </tr>
                            </template>
                          </v-data-table>
                        </v-tab-item>
                      </v-tabs>
                  </v-card>
                </v-layout>
              </v-container>
          </v-main>
        </div>
   
</template>

<script>
    import PathEl from '@/components/Path.vue';
    
    import ToolBar from '@/components/ToolBar.vue'
    
    import{ conf } from '@/utils/confTruePLM';
    import { auth } from '@/utils/auth';
    import router from '@/router';
    
export default {
  name: 'NTasks',
  data () 
  {
    return {
        sortProp: [ 'open', 'serial' ],
        heads: [
            { width: '10%', text: 'message.ntasks_serial_col', value: 'serial' }, 
            { width: '10%', text: 'message.ntasks_act_col', value: 'action.name' }, 
            { width: '10%', text: 'message.ntasks_atype_col', value: 'action.action_type' }, 
            { text: 'message.ntasks_etype_col', value: 'event.name' }, 
            { width: '15%', text: 'message.ntasks_data_col', value: 'event.timestamp' }, 
            { text: 'message.ntasks_subj_col', value: 'resolved_subj' }, 
            { text: 'message.ntasks_msg_col', value: 'resolved_message' }, 
            { width: '10%', text: 'message.ntasks_state_col', value: 'open' }, 
            { width: '10%', sortable: false, value: 'act' }
        ],
        globalState: this.$globalStore.state,
        items : [],
        selItem: {}
    };
  },
  methods: {
      delItem( item )
      {
          let self = this;
          this.$modalDlg.sysDlgs.confirmDlg.open( 'ntasks_del_title', 'ntasks_del_msg', null, item.serial )
                  .then( () =>
          {
              self.$modalDlg.sysDlgs.progressDlg.open();
              return conf.delNTask( item );
          }, () => {} )
                  .then( () => 
          {
              self.initData();
          })
                  .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      actItem( item )
      {
          let self = this;
          this.$modalDlg.sysDlgs.confirmDlg.open( 'ntasks_act_title', 'ntasks_act_msg', null, item.serial )
                  .then( () =>
          {
              self.$modalDlg.sysDlgs.progressDlg.open();
              return conf.actNTask( item );
          }, () => {} )
                  .then( () => 
          {
              self.initData();
          })
                  .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.$eventBus.$emit( 'queryError', err );
          });
      },
     
      initData()
      {
          let self = this;
          
          if( self.$modalDlg.sysDlgs.progressDlg )
                self.$modalDlg.sysDlgs.progressDlg.open();
            
          conf.listNTasks()
                .then( ( data ) =>
          {
            //  self.$utils.sortArray( data, ( p ) => ( p.serial ) );
              self.items = [];
              
              data.forEach( ( item ) =>
              {
                  item.event.timestamp = this.$utils.getLocalDateFromUTC( item.event.timestamp );
                  self.items.push( item );                  
              });
              self.$modalDlg.sysDlgs.progressDlg.close();
          })
                .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      selectProj( proj, role )
      {
          if( role )
          {
              conf.setProj( proj, role );
              localStorage.setItem( 'proj_model_id', proj.in_project.project_model_id );
              this.$eventBus.$emit( 'selectedModel' );
              this.$eventBus.$emit( 'setPath', [ { alt_name: 'Notification tasks' } ] );
              this.$globalStore.setCurFolderInfo( proj.in_project );
          }
      },
      updateProjList( info )
      {
            var selProj = null;
            var self = this;
            conf.getRepositoryList()
                    .then( ( data )=>
            {
                for( var i = 0; data && i < data.length; i++ )
                {
                    var proj = data[ i ];
                    if( proj.is_active_in_project && proj.user_registered_as.length > 0 )
                    {
                        if( info && proj.in_project.project_model_id === info.project_model_id )
                        {
                            selProj = proj;
                            break;
                        }
                    }
                }
                
                if( selProj )
                {
                    let selRole = null;
                    let prevRole = localStorage.getItem( 'proj_role' );
                    if( localStorage.getItem( 'proj_model_id' ) === info.project_model_id )
                    {
                        for( var i = 0; selProj.user_registered_as && i < selProj.user_registered_as.length; i++ )
                        {
                            if( selProj.user_registered_as[ i ] === prevRole )
                            {
                                selRole = prevRole;
                                break;
                            }
                        }
                    }
                    if( selRole )
                        self.selectProj( selProj, selRole, true ); 
                    
                    this.initData();
                }

            }, ( err )=>{ self.$eventBus.$emit( 'queryError', err ); } );
      },    
      onClosePage()
      {
          localStorage.setItem( 'closeTime', Date.now() );
      }
  },
  created() 
  {    
       window.addEventListener( 'beforeunload', this.onClosePage );  
  },
  components: {
    'path-el-view': PathEl,
    
    'tool-bar-view': ToolBar
  },
  beforeMount: function()
  {
      auth.checkAuth();
      if( ! auth.getUser().authenticated )
          router.push( '/login' );
      
      if( !conf.getProj() )
          router.push( '/' );
      
      if( !this.$globalStore.isAdmin() )
          router.push( '/home' );
  },
  mounted: function()
  {
     if(  auth.getUser().authenticated )
     {
          var projModelID = localStorage.getItem( 'proj_model_id' );
          if( projModelID )
              this.updateProjList( { project_model_id: projModelID } );        
          else
              this.updateProjList();        
     }
  },
  beforeDestroy: function()
  {

  }
}
</script>

<style>
    .main_correction > div.v-main__wrap
    {
        height: 90%;            
    }
    
    .close-menu ul
    {
        min-width: 2em;
        white-space: nowrap;
    }
    
    .multipane.cust.layout-v .multipane-resizer 
    {
        margin: 0; left: 0; /* reset default styling */
        width: 5px;
        background: graytext;
    }
</style>
