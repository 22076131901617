<template>
   
        <div>
            <create-proj-view ref="createProjDlg"></create-proj-view>
            <set-user-on-proj-view ref="setUserOnProjDlg"></set-user-on-proj-view>
            <set-proj-info-view ref="setProjInfoDlg"></set-proj-info-view>
            <create-user-view ref="createUserDlg"></create-user-view>
            <create-sync-view ref="createSyncDlg"></create-sync-view>
            <confirm-2fa-view ref="confirm2faDlg"></confirm-2fa-view>
            <set-2fa-view ref="set2faDlg"></set-2fa-view>
            <set-user-pass-view ref="setUserPassDlg"></set-user-pass-view>
            <token-list-view ref="tokenListDlg"></token-list-view>
            
            <admin-tool-bar-view></admin-tool-bar-view>    
            <v-main class="main_correction">
                <v-container fill-height fluid style="padding-top: 0px; padding-bottom: 24px; margin-top: -5px;">
                 <!--<v-layout row wrap>-->
                        <admin-list-view></admin-list-view>
                        <v-card width="100%" fill-height v-show="selProj && selProj !== 'EDIT_USERS' 
                                && selProj !== 'EDIT_PROJ_LIST' && selProj !== 'EDIT_SYNC_LIST'">
                            <v-tabs v-model="active1" show-arrows ref="tabs">
                                <v-tab href="#tab-1">
                                    {{ $t( 'message.admin_user_tab' ) }}
                                </v-tab>
                                <v-tab href="#tab-2">
                                    {{ $t( 'message.admin_info_tab' ) }}
                                </v-tab>
                                <v-tab-item value="tab-1">
                                    <proj-users-view></proj-users-view>
                                </v-tab-item>
                                <v-tab-item value="tab-2">
                                    <proj-info-view :proj="selProj"></proj-info-view>
                                </v-tab-item>
                            </v-tabs>
                        </v-card>
                        <v-card width="100%" fill-height v-show="selProj && selProj === 'EDIT_USERS'">
                            <v-tabs v-model="active2" show-arrows ref="tabs">
                                <v-tab href="#tab-1">
                                    {{ $t( 'message.admin_user_tab' ) }}
                                </v-tab>
                                <v-tab-item value="tab-1">
                                    <user-list-view></user-list-view>
                                </v-tab-item>
                            </v-tabs>
                        </v-card>
                        <v-card width="100%" fill-height v-show="selProj && selProj === 'EDIT_PROJ_LIST'">
                            <v-tabs v-model="active2" show-arrows ref="tabs">
                                <v-tab href="#tab-1">
                                    {{ $t( 'message.admin_proj_list_tab' ) }}
                                </v-tab>
                                <v-tab-item value="tab-1">
                                    <proj-list-view></proj-list-view>
                                </v-tab-item>
                            </v-tabs>
                        </v-card>
                        <v-card width="100%" fill-height v-show="selProj && selProj === 'EDIT_SYNC_LIST'">
                            <v-tabs v-model="active2" show-arrows ref="tabs">
                                <v-tab href="#tab-1">
                                    {{ $t( 'message.admin_sync_list_tab' ) }}
                                </v-tab>
                                <v-tab-item value="tab-1">
                                    <sync-list-view></sync-list-view>
                                </v-tab-item>
                            </v-tabs>
                        </v-card>
              </v-container>
          </v-main>
        </div>
   
</template>

<script>
    import PathEl from '../Path.vue'
    import AdminToolBar from './AdminToolBar.vue'
    import AdminList from './AdminList.vue'
    
    import ProjUsers from './ProjUsers.vue'
    import ProjInfo from './ProjInfo.vue'
    import UserList from './UserList.vue'
    import ProjList from './ProjList.vue'
    import SyncList from './SyncList.vue'
  
    import CreateProj from '../Dlg/CreateProj.vue'
    import SetUserOnProj from '../Dlg/SetUserOnProj.vue'
    import CreateUser from '../Dlg/CreateUser.vue'
    import CreateSync from '../Dlg/CreateSync.vue'
    import SetProjInfo from '../Dlg/SetProjInfo.vue'
    import Confirm2fa from '../Dlg/Confirm2fa.vue';
    import Set2fa from '../Dlg/Set2fa.vue';
    import SetUserPass from '../Dlg/SetUserPass.vue';
    import TokenList from '../Dlg/TokenList.vue';
    
    import{ conf } from '@/utils/confTruePLM';
    import { auth } from '@/utils/auth';
    import Vue from 'vue'
    import router from '@/router';
       
export default {
  name: 'admin',
  data () 
  {
    return {
        active1: null,
        active2: null,
        active3: null,
        globalState: this.$globalStore.state,
        selProj: null
    };
  },
  methods: 
  {
      onClosePage()
      {
          localStorage.setItem( 'closeTime', Date.now() );
      }
  },
  created() 
  {    
      window.addEventListener( 'beforeunload', this.onClosePage );  
  },
  components: {
    'admin-tool-bar-view': AdminToolBar,
    'admin-list-view': AdminList,
    'create-proj-view': CreateProj,
    'proj-users-view': ProjUsers,
    'proj-info-view': ProjInfo,
    'user-list-view': UserList,
    'proj-list-view': ProjList,
    'sync-list-view': SyncList,
    'set-user-on-proj-view': SetUserOnProj,
    'set-proj-info-view': SetProjInfo,
    'create-user-view': CreateUser,
    'create-sync-view': CreateSync,
    'set-2fa-view': Set2fa,
    'confirm-2fa-view': Confirm2fa,
    'set-user-pass-view': SetUserPass,
    'token-list-view': TokenList
  },
  mounted: function()
  {
      this.$modalDlg.sysDlgs[ 'createProjDlg' ] = this.$refs.createProjDlg;
      this.$modalDlg.sysDlgs[ 'setUserOnProjDlg' ] = this.$refs.setUserOnProjDlg;
      this.$modalDlg.sysDlgs[ 'setProjInfoDlg' ] = this.$refs.setProjInfoDlg;
      this.$modalDlg.sysDlgs[ 'createUserDlg' ] = this.$refs.createUserDlg;
      this.$modalDlg.sysDlgs[ 'createSyncDlg' ] = this.$refs.createSyncDlg;
      this.$modalDlg.sysDlgs[ 'set2faDlg' ] = this.$refs.set2faDlg;
      this.$modalDlg.sysDlgs[ 'confirm2faDlg' ] = this.$refs.confirm2faDlg;
      this.$modalDlg.sysDlgs[ 'setUserPassDlg' ] = this.$refs.setUserPassDlg;
      this.$modalDlg.sysDlgs[ 'tokenListDlg' ] = this.$refs.tokenListDlg;
      
      var self = this;
      this.$eventBus.$on( 'editProj', function( proj )
      {
          self.selProj = proj;
      });
      this.$eventBus.$on( 'editUsers', function()
      {
          self.selProj = 'EDIT_USERS';
      });
      this.$eventBus.$on( 'editProjList', function()
      {
          self.selProj = 'EDIT_PROJ_LIST';
      });
      this.$eventBus.$on( 'editSyncList', function()
      {
          self.selProj = 'EDIT_SYNC_LIST';
      });
  },
  beforeDestroy: function()
  {
      this.$eventBus.$off( 'editProj' );
      this.$eventBus.$off( 'editUsers' );
      this.$eventBus.$off( 'editProjList' );
      this.$eventBus.$off( 'editSyncList' );
  }
}
</script>

<style>
    .main_correction > div.v-main__wrap
    {
        height: 90%;            
    }
    
    .close-menu ul
    {
        min-width: 2em;
        white-space: nowrap;
    }
</style>
