<template>
    <div>
        <v-card style="height: calc(100vh - 181px); overflow-y: auto;">
            <v-list dense>
                <v-list-item v-for="( item, index ) in items" :key="index" @click="updateInfo( item )">
                    <v-list-item-content>
                      <v-list-item-title v-bind:class="{ select: ( selProp.id === item.id ) }">
                          {{item.name}}
                      </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card>
    </div>
</template>

<script>

export default {
  name: 'PropList',
  props: {
      selProp: null
  },
  data () {
    return {
        globalState: this.$globalStore.state,
        items: [ { name: 'Project phase', rdlName: 'urn:rdl:epm-std:project_phase', id: 1 }, 
                 { name: 'Discipline', rdlName: 'urn:rdl:epm-std:project_discipline', id: 2 }, 
                 { name: 'Source of information', rdlName: 'urn:rdl:epm-std:Source_of_information', id: 3 },  
                 { name: 'Document status', type: 'prop_type_with_children', rdlName: 'urn:rdl:epm-std:Document_status', id: 5 }, 
                 { name: 'Document type', type: 'node_type', rdlName: 'urn:rdl:epm-std:Document_content_type', id: 4 },
                 { name: 'Document properties', type: 'prop_type', rdlName: 'urn:rdl:epm-std:Document_content_type', id: 21 },
                 { name: 'Issue priority', rdlName: 'urn:plcs:rdl:TruePLM:IssuePriority', id: 41 },
                 { name: 'Issue status', type: 'prop_type_with_children', rdlName: 'urn:plcs:rdl:TruePLM:IssueStatus', id: 42 },
                 { name: 'Issue type', rdlName: 'urn:plcs:rdl:TruePLM:GenericIssue', id: 43 },
                 { name: 'Issue relationship type', rdlName: 'urn:plcs:rdl:TruePLM:IssueRelationshipType', id: 44 },
                 { name: 'Issue time', rdlName: 'urn:plcs:rdl:TruePLM:IssueDateTime', id: 45 },
                 { name: 'Issue person role', type: 'prop_type_in_prn', rdlName: 'urn:plcs:rdl:TruePLM:IssueUser', prnType: 'urn:plcs:rdl:TruePLM:GenericIssue', id: 46 },
                 { name: 'Subdomain roles', rdlName: 'urn:rdl:epm-std:project_role', id: 7 }, 
                 { name: 'Breakdown relationship roles', type: 'node_type', rdlName: 'urn:plcs:rdl:std:Product_version_relationship', id: 28 },
                 { name: 'Organisation roles', rdlName: 'urn:plcs:rdl:std:Organization_or_person_in_organization_assignment', id: 29 },
                 { name: 'Breakdown element type', type: 'node_type', rdlName: 'urn:rdl:epm-std:breakdown_element_type', id: 6 }, 
                 { name: 'Breakdown element properties', type: 'prop_type', rdlName: 'urn:rdl:epm-std:breakdown_element_type', id: 20 },
//                 { name: 'Requirement types', rdlName: 'urn:plcs:rdl:TruePLM:Requirement', id: 22 },
//                 { name: 'Requirement properties', type: 'prop_type', rdlName: 'urn:plcs:rdl:TruePLM:Requirement', id: 23 },
                 { name: 'Product element type', rdlName: 'urn:rdl:epm-std:Part_type', id: 26 }, 
                 { name: 'Product element properties', type: 'prod', rdlName: 'urn:rdl:epm-std:Part_type', id: 27 },
                 { name: 'Product domains', type: 'node_type', rdlName: 'urn:plcs:rdl:std:Application_domain', id: 30 },
                 { name: 'Product stages', type: 'node_type', rdlName: 'urn:plcs:rdl:std:Life_cycle_stage', id: 31 },
                 { name: 'Aggregate struct', rdlName: 'urn:plcs:rdl:TruePLM:ComplexDataType', id: 24 },
                 { name: 'Aggregate struct elements', type: 'struct', rdlName: 'urn:plcs:rdl:TruePLM:ComplexDataType', id: 25 },
                 { name: 'Event types', type: 'node_type', rdlName: 'urn:plcs:rdl:TruePLM:System_event', id: 32 },
                 { name: 'Event properties', type: 'prop_type', rdlName: 'urn:plcs:rdl:TruePLM:System_event', id: 33 }
        ]
    };
  },
  components: {
  },
  methods: 
  {
      updateInfo( prop )
      {
          this.$emit( 'selectProp', prop );
      }
  },
  mounted: function() 
  { 
  },   
  beforeDestroy: function()
  {
  }
}
</script>

<style scoped>
  .scroll 
  {
      overflow-y: auto;
  }
  .select
  {
      font-weight: 700 !important;
  }
</style>
