<template>
            <v-dialog v-model="visFl" persistent max-width="290">
               <v-card>
                <v-card-title class="headline">
                    <span v-if="isNew">{{ $t( 'message.user_add_title' ) }}</span>
                    <span v-else>{{ $t( 'message.user_add_title' ) }}</span>
                </v-card-title>
                <v-card-text>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-select v-if="isNew" :label="$t( 'message.set_user_proj_name' )" required item-text="txt" item-value="val" v-model="userName"
                            :items="users" :rules="[v => !!v || $t( 'message.value_required' )]"></v-select>
                        <v-text-field v-else :label="$t( 'message.set_user_proj_name' )" required v-model="userName" readonly
                                :rules="[v => !!v || $t( 'message.value_required' )]"></v-text-field>
                        
                        <v-select :label="$t( 'message.set_user_proj_role' )" item-text="txt" required
                              item-value="val" v-model="userRoles" :items="roles" multiple :rules="[ ( v ) => 
                              { 
                                    if( !v || v.length < 1 ) 
                                        return $t( 'message.value_required' )
                                    else
                                        return true; 
                              } ]" >
                           <template v-slot:selection="{ item, index }">
                                <span v-if="index === 0">{{ item.txt }}</span>
                                <span v-if="index === 1" class="grey--text caption">
                                    <span style="padding-left: 7px;">
                                        +{{ userRoles.length - 1 }}
                                    </span>
                                </span>
                           </template>
                        </v-select>    
                        
                    </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                    <v-btn id="setUserOnProjOk" color="green darken-1" text :disabled="!valid" @click.native="doAct()">{{ $t( 'message.ok_btn' ) }}</v-btn>
                    <v-btn id="setUserOnProjClose" color="green darken-1" text @click.native="close()">{{ $t( 'message.cancel_btn' ) }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>   
</template>

<script>
    
import Vue from 'vue'

export default {
  name: 'setUserOnProj',
  data () 
  {
    return {
        visFl: false,
        prop: null,
        titleVal: null,
        userName: null,
        userRoles: [],
        roles: [],
        users: [],
        isNew: true,
        resolve: null,
        reject: null,
        valid: true
    };
  },
  computed: {
  },
  components: {
  },
  methods: {
      getRoleName( role )
      {
          role = this.$utils.clearType( role );
          let rT = this.$t( 'message.user_role_name__' + role );
          if( rT === 'message.user_role_name__' + role )
          {
              rT = role.replace( '_', ' ' );
              rT = rT.substring( 0, 1 ).toUpperCase() + rT.substring( 1 );
          }
          return rT;
      },
      open( user, users, roles, isTmpl )
      {
          this.isNew = !user;
          this.users = users;
          this.roles = [];
          roles.forEach( ( elem ) =>
          {
              let r = this.$utils.clearType( elem );
              if( r !== 'trueplm_system' )
              {
                  if( !isTmpl || r === 'project_manager' || r === 'project_admin' || r === 'project_member' )
                      this.roles.push( { val: elem, txt: this.getRoleName( elem ) } );
              }
          });
          if( this.isNew )
          {
              this.userName = null;
              this.userRoles = [];                                          
          }
          else
          {
              this.userName = user.name;
              this.userRoles = user.roles;
          }
          
          this.visFl = true;
          
          return new Promise( ( resolve, reject ) => 
          {
            this.resolve = resolve;
            this.reject = reject;
          });
      },
      close()
      {
          this.visFl = false;
          this.reject();
      },
      doAct()
      {
          if( this.$refs.form.validate() )
          {
              this.visFl = false;
              this.resolve( { user: this.userName, roles: this.userRoles } );
          }
      }
    }
}
</script>



