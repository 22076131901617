<template>
    
            <v-dialog v-model="visFl" persistent max-width="1100">
                <v-toolbar flat color="white">
                    <v-toolbar-title v-if="type === 'PARENT'">{{ $t( 'message.rels_list_prn_title' ) }}</v-toolbar-title>
                    <v-toolbar-title v-if="type === 'CHILD'">{{ $t( 'message.rels_list_ch_title' ) }}</v-toolbar-title>
                    <v-toolbar-title v-if="!type">{{ $t( 'message.rels_list_title' ) }}</v-toolbar-title>
                </v-toolbar>
               <v-card>
                <v-card-text>
                    <v-data-table dense :headers="headers" :items="relsData" hide-default-footer class="elevation-1 propTbl" 
                                  :items-per-page=-1 fixed-header :height=270>
                        <template v-slot:header.role="{ header }">
                            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
                        </template>
                        <template v-slot:header.caption="{ header }">
                            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
                        </template>
                        <template v-slot:header.related.name="{ header }">
                            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
                        </template>
                        <template v-slot:header.related.element_type="{ header }">
                            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
                        </template>
                        <template v-slot:header.created="{ header }">
                            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
                        </template>
                        <template v-slot:header.by_user="{ header }">
                            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
                        </template>
                        <template v-slot:header.remark="{ header }">
                            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
                        </template>
                        <template v-slot:header.act="{ header }">
                            <span></span>
                        </template>
                        <template v-slot:item="{ item }">
                            <tr>
                              <!--<td>{{ item.role }}</td>-->
                              <td>{{ item.caption }}</td>
                              <td>{{ item.related.name }}</td>
                              <td>{{ $utils.clearType( item.related.element_type ) }}</td>
                              <td>{{ item.created }}</td>
                              <td>{{ item.by_user }}</td>
                              <td>{{ item.remark }}</td>
                              <td>
                                  <span style="white-space: nowrap">
                                      <v-tooltip bottom v-if="!globalState.actVer">
                                            <template v-slot:activator="{ on }">
                                                <v-icon v-on="on" @click="showRel( item )">visibility</v-icon>
                                            </template>
                                            <span>
                                                {{$t( 'message.rel_show' )}}
                                            </span>
                                      </v-tooltip>
<!--                                      <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-icon  v-on="on" @click="editItem(item)">edit</v-icon>
                                            </template>
                                            <span>{{ $t( 'message.rels_edit_btn' ) }}</span>
                                      </v-tooltip>-->
                                      <v-tooltip bottom v-if="!globalState.actVer">
                                            <template v-slot:activator="{ on }">
                                                <v-icon v-on="on" @click="deleteRel( item )">delete_forever</v-icon>
                                            </template>
                                            <span>
                                                {{$t( 'message.rels_delete_btn' )}}
                                            </span>
                                      </v-tooltip>
                                  </span>
                              </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click.native="close()">{{ $t( 'message.close_btn' ) }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>   
    
</template>

<script>
    
import Vue from 'vue'
import{ conf } from '../../utils/confTruePLM';

export default {
  name: 'relsList',
  data () 
  {
    return {
        globalState: this.$globalStore.state,
        visFl: false,
        headers: [
//            { text: 'message.rel_role_col', value: 'role' },
            { text: 'message.rel_name_col', value: 'caption' },
            { text: 'message.rel_node_col', value: 'related.name' },
            { text: 'message.rel_node_type_col', value: 'related.element_type' },
            { text: 'message.rel_dt_col', value: 'created', width: 170 },
            { text: 'message.rel_use_col', value: 'by_user', width: 110 },
            { text: 'message.rel_rem_col', value: 'remark' },
            { text: 'message.rel_act_col', value: 'act', sortable: false }
        ],
        relsData : [],
        type: null,
        node: null,
        resolve: null,
        reject: null
    };
  },
  computed: {
  },
  methods: {
      showRel( rel )
      {
          this.visFl = false;
          this.resolve( rel );
      },
      deleteRel( rel )
      {
          var self = this;
          this.$modalDlg.sysDlgs.confirmDlg.open( 'rel_remove_title', 'rel_remove_msg' )
                  .then( () =>
          {
              return conf.deleteRelNode( rel );
          }, () => {} )
                  .then( () =>
          {
              return self.getData();
          })
                  .catch( ( err ) =>
          {
              this.$eventBus.$emit( 'queryError', err );
          });
      },
      getData()
      {
          let self = this;
          conf.getRelsInfo( this.node, this.type, this.$globalStore.state.actVer )
                  .then( ( data ) => 
          {
              self.relsData = [];
              for( let i = 0; data && i < data.length; i++ )
              {
                  if( data[ i ].role )
                      data[ i ].role = this.$utils.clearType( data[ i ].role );
                  let cName = data[ i ].caption;
                  let cParts = cName.split( ':' );
                  if( cParts.length > 1 )
                  {
                       if( data[ i ].parent_child === 'CHILD' )
                           cName = cParts[ 1 ] || data[ i ].role;
                       else if( data[ i ].parent_child === 'PARENT' )
                           cName = cParts[ 0 ] || data[ i ].role;
                       
                       data[ i ].caption = cName;
                  }
                  
                  self.relsData.push( data[ i ] );    
              }
          })
                .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      open( node, type )
      {
          this.type = type;
          this.relsData = [];
          this.node = node;
          
//          if( node.rels )
//            this.relsData = node.rels;
//          else
            this.getData();
        
          this.visFl = true;
          
          let self = this;
          return new Promise( ( resolve, reject ) => 
          {
              self.resolve = resolve;
              self.reject = reject;
          });          
      },
      close()
      {
          this.visFl = false;
          this.resolve();
      }
    }
}
</script>

<style scoped>
    .propTbl td
    {
        height: 30px;
    }        
</style>



