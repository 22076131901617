<template>
    
<v-app id="reg">
    <v-main>
        <v-banner v-if="!hideCookies" color="light-green" style="z-index: 10; bottom: 1px; position: absolute; width: 100%;">
            {{ $t( 'message.cookies_banner' ) }}
            <template v-slot:actions>
              <v-btn text color="primary" @click="closeCookies()">{{ $t( 'message.cookies_banner_close' ) }}</v-btn>
            </template>
        </v-banner>
        <confirm-view ref="confirmDlg"></confirm-view>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-1">
              <v-toolbar dark color="primary">
                <v-toolbar-title>{{ $t( 'message.reg_req_ttl' ) }}</v-toolbar-title>
                <v-spacer></v-spacer>
                 <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn color="primary" dark v-on="on">{{langInfo.locale}}</v-btn>
                  </template>

                  <v-list>
                    <v-list-item v-for="l in langs" :key="l" @click="setLang( l )">
                      <v-list-item-title>{{ l }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-toolbar>
              <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
                   
<!--                    <v-text-field prepend-icon="account_circle" :label="$t( 'message.reg_req_login' )" type="text" 
                        v-model="info.login" @keypress.native="keyCheck" required :rules="[v => !!v || $t( 'message.value_required' )]">
                    </v-text-field>-->
                    <v-text-field prepend-icon="person" :label="$t( 'message.reg_req_f_name' )" type="text" 
                        v-model="info.fName" @keypress.native="keyCheck" required :rules="[v => !!v || $t( 'message.value_required' )]">
                    </v-text-field>
                    <v-text-field prepend-icon="person" :label="$t( 'message.reg_req_s_name' )" type="text" 
                        v-model="info.sName" @keypress.native="keyCheck">
                    </v-text-field>
                    <v-text-field prepend-icon="business" :label="$t( 'message.reg_req_comp' )" type="text" 
                        v-model="info.company" @keypress.native="keyCheck" required :rules="[v => !!v || $t( 'message.value_required' )]">
                    </v-text-field>
                     <v-text-field prepend-icon="email" :label="$t( 'message.reg_req_email' )" type="text" 
                        v-model="info.email" @keypress.native="keyCheck" required :rules="emailRules">
                    </v-text-field>
                                        
                </v-form>
              </v-card-text>
              <v-card-actions>
                <vue-recaptcha ref="recaptcha" @verify="onCaptchaVerified" expired="onCaptchaExpired" size="invisible"
                               sitekey="6LfwR9oUAAAAAI3AhXCTyRK65U7s71jPQ_FDwMc7" :loadRecaptchaScript="true"></vue-recaptcha>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="submit" :disabled="!valid || isBusy">{{ $t( 'message.reg_req_send_btn' ) }}</v-btn>
                <v-btn color="primary" @click="close">{{ $t( 'message.reg_req_close_btn' ) }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
    
  </v-app>
    
</template>

  <script>
      
  import router from '../router';
  import { auth } from '@/utils/auth';
  import Confirm from './Dlg/Confirm.vue'
  import VueRecaptcha from 'vue-recaptcha';
  
  export default {
    data() {
      return {
        langInfo: this.$i18n,
        hideCookies: false,
        settings: require( process.env.VUE_APP_CONFIG_ROOT + '/settings' ),
        info: {},
        emailRules: [
            v => !!v || this.$t( 'message.value_required' ),
            v => /.+@.+\..+/.test(v) || this.$t( 'message.value_valid' )
          ],
        valid: false,
        isBusy: false
      };
    },
    computed: {
        langs() 
        {
            return Object.keys( this.$i18n.messages );
        }        
    },
    methods: 
    {
      setLang( l )
      {
          this.$i18n.locale = l;
      },  
      keyCheck( e )
      {
          if( e.keyCode === 13 )
              this.submit();
      }, 
      closeCookies()
      {
          this.hideCookies = true;
          localStorage.setItem( 'hideCookies', true );          
      },
      submit() 
      {
          if( this.$refs.form.validate() )
          {
              this.isBusy = true;
              this.$refs.recaptcha.execute();
          }
      },
      onCaptchaVerified( rToken ) 
      {
          this.$refs.recaptcha.reset();
          this.doSend( rToken );
      },
      onCaptchaExpired() 
      {
          this.$refs.recaptcha.reset();
      },
      doSend( rToken )
      {
          let serverInfo = this.settings.servers[ 0 ];

          this.info.host = window.location.hostname;
          this.info.rToken = rToken;

          auth.sendRegReq( serverInfo.url, this.info )
                  .then( ( rez ) =>
          {
              if( rez )
                  return this.$refs.confirmDlg.openOkOnly( 'reg_req_send_title', 'reg_req_send_msg' );
              else
                  throw new Error( 'Can not send registration request' );
          })
                  .then( () =>
          {
             this.close();
          })
                  .catch( err =>
          {
              console.log( err );
              this.isBusy = false;
              this.$refs.confirmDlg.openError( 'reg_req_send_title', 'reg_req_err_msg' );
          });
      },
      close()
      {
          this.info = {};
          router.push( '/login' );          
      }
    },
    mounted: function()
    {
        this.hideCookies = localStorage.getItem( 'hideCookies' );          
    },
    components: {
        'confirm-view': Confirm,
        'vue-recaptcha': VueRecaptcha 
    }
  }
  </script>