<template>
    
<!--      <v-container fluid fill-height>
          <v-layout align-center justify-center>-->
            <v-dialog v-model="visFl" persistent max-width="290">
               <v-card>
                <v-card-title class="headline">
                  {{ $t( 'message.checkin_file_title' ) }}
                </v-card-title>
                <v-card-text>
                        <file-upload ref="fileUpload" :rules="[ ( v ) => 
                            { 
                                if( v && v.length > 0 ) 
                                {
                                    if( v[ 0 ].name !== info.prevFileName ) 
                                        return $t( 'message.checkin_file_wrong_name' );
                                    else
                                        return true;
                                }
                                else
                                    return $t( 'message.file_required' );
                            } ]">
                        </file-upload>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-textarea :label="$t( 'message.create_node_descr' )" required v-model="info.descr" 
                                :rules="[v => !!v || $t( 'message.value_required' )]"></v-textarea>
                        <v-select :label="$t( 'message.add_file_node_status' )" required v-model="info.statusVal" item-text="txt" item-value="val" 
                                    :items="statuses" :rules="[v => !!v || $t( 'message.value_required' )]"></v-select>
                        <v-checkbox :label="$t( 'message.checkin_file_new_issue' )" v-model="info.isNewIssue"></v-checkbox>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text :disabled="!valid || ( $refs.fileUpload && $refs.fileUpload.items.length < 1 )" @click.native="doAct()">{{ $t( 'message.ok_btn' ) }}</v-btn>
                    <v-btn color="green darken-1" text @click.native="close()">{{ $t( 'message.cancel_btn' ) }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>   
<!--          </v-layout>
      </v-container>-->
    
</template>

<script>
    
import Vue from 'vue'
import{ conf } from '../../utils/confTruePLM';
import FileUpload from '../FileUpload'

export default {
  name: 'checkInFile',
  data () 
  {
    return {
        visFl: false,
        
        statuses: [],
        info: {},
        
        resolve: null,
        reject: null,
        valid: true
    };
  },
  computed: {
      
  },
  components: {
       'file-upload': FileUpload
  },
  methods: {
      open( file )
      {
          let self = this;
          let fStatus = file.status;
          this.statuses = [];
          conf.getChildTypesInContext( 'urn:rdl:epm-std:Document_status', fStatus )
                  .then( ( data ) =>
          {
              self.$utils.getEnum( data, fStatus, true ).forEach( ( opt ) =>
              {
                  self.statuses.push( opt );
              });
//              data.forEach( ( item ) =>
//              {
//                  this.statuses.push( { txt: this.$utils.clearType( item ), val: item } );
//              });
          })
                .catch( ( err ) =>
          {
                self.$eventBus.$emit( 'queryError', err );
          });  
          this.info = { statusVal: fStatus, isNewIssue: false, prevFileName: file.file_name };
          
          this.visFl = true;
          
          return new Promise( ( resolve, reject ) => 
          {
            this.resolve = resolve;
            this.reject = reject;
          });
      },
      close()
      {
          this.visFl = false;
          this.reject();
      },
      doAct()
      {
          if( this.$refs.form.validate() && this.$refs.fileUpload.items.length > 0 )
          {
              this.visFl = false;
              this.info.files = this.$refs.fileUpload.items;
              this.$refs.fileUpload.clear();
            
              this.resolve( this.info );
          }
      }
    }
}
</script>





