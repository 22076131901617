<template>
    <li class="tree_node">
        <div class="v-list-item theme--light">
            <div class="v-list-item__action" style="width: 27px;">
                <span v-if="node.has_children" @click="toggleNode" style="cursor: pointer;">
                    <transition name="arrow" >
                        <v-icon medium v-bind:class="{ rot_trans: !node.opened }">arrow_drop_down</v-icon>
                    </transition>
                     
                </span>
                <span v-else style="width: 27px;">
                    
                </span>
            </div>
            <div class="v-list-item__action" style="width: 27px;">
                <v-img :src="imgSrc" max-width="24"></v-img>                  
            </div>
            <div class="v-list-item__content" v-bind:class="{ select: ( node.selected ) }"> 
                <div v-if="!hideProps">
                    {{modelName}}
                    <v-menu v-model="showMenu" v-if="node.element_type !== 'urn:rdl:epm-std:product_list'">
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" icon>
                                <v-icon>more_vert</v-icon>
                            </v-btn>
                        </template>

                        <v-list dense>
                          <v-list-item key="1" @click.stop="copyNode">
                              <v-list-item-title>{{$t( 'message.menu_copy' )}}</v-list-item-title>
                          </v-list-item>
                          <v-list-item key="2" @click="gotoNode">
                              <v-list-item-title>{{$t( 'message.menu_file_goto' )}}</v-list-item-title>
                          </v-list-item>
<!--                          <v-list-item key="2" @click.stop="pasteNode">
                              <v-list-item-title>{{$t( 'message.menu_paste' )}}</v-list-item-title>
                          </v-list-item>-->
                        </v-list>
                    </v-menu>
                </div>
                <div v-else>
                    {{modelName}}
                </div>
            </div>
<!--            <div class="v-list-item__action" v-if="!hideProps">
                <span v-show="node.quantity">Quantity: {{node.quantity}}</span>
            </div>-->
        </div>
        <ul v-if="node.opened" class="tree_view">
             <tree-node-view v-for="ch in node.children" :key="ch.instance_id" :model="ch" :hideProps="hideProps" :showFiles="showFiles"></tree-node-view>                    
        </ul>
    </li>
</template>

<script>
    
import{ conf } from '../../utils/confTruePLM';
    
export default {
  name: 'tree-node-view',
  props: {
      model: {},
      hideProps: false,
      showFiles: false      
  },
  data () 
  {
    return {
        node: this.model,
        showMenu: false
    };
  },
  computed: 
  {
    imgSrc: function()
    { 
        return this.$utils.getImage( this.node.element_type );
    },
    accRt: function()
    {
        let rez = '';
        if( this.node.permissions )
        {
            rez += this.node.permissions.can_i_read ? 'r' : '';
            rez += this.node.permissions.can_i_write ? 'w' : '';
            rez += this.node.permissions.can_i_delete ? 'd' : '';
        }
        
        return '(' + rez + ')';
    },
    modelName: function()
    {
        let attr = this.$globalStore.state.renameNodeAttr[ this.node.instance_id ];
        if( !attr )
            attr = this.node.nameAttr;
        if( attr )
        {
            for( let i = 0; this.node.properties && i < this.node.properties.length; i++ )
            {
                let prop = this.node.properties[ i ];
                if( this.$utils.clearType( prop.name ) === attr )
                    return prop.val;
            }
        }
        
        return this.node.name;        
    },
  },
  methods: 
  {
      gotoNode()
      {
          let self = this;
          conf.getNodePath( self.model.instance_id )
                  .then( ( rez ) =>
          {
              if( rez && rez.tree_path_to_element )
                 self.$eventBus.$emit( 'procRowMenuCmd', [ 'gotoNode', rez.tree_path_to_element ] ); 
          })
                .catch( ( err ) =>
          {
            self.$eventBus.$emit( 'queryError', err );
          });  
      },
      copyNode()
      {
          this.$eventBus.$emit( 'procRowMenuCmd', [ 'copyNode' , this.model ] );       
          this.showMenu = false;
      },
      pasteNode()
      {
          this.showMenu = false;
      },
      toggleNode()
      {
          if( this.node.opened )
              this.closeNode();
          else
              this.openNode();
      },
      
      closeNode()
      {
          this.node.opened = false; 
      },
      
      openNode()
      {
          if( !this.node.filled )
            this.fillNode();
          else
            this.node.opened = true;
      },
      fillNode()
      {
          var self = this;
          let attr = this.$globalStore.state.renameNodeAttr[ this.node.instance_id ];
          self.$modalDlg.sysDlgs.progressDlg.open();
          conf.getTreeNodeChildren( self.node, self.node.root_version, 2 )
                  .then( ( data ) =>
          {
              self.node.children = [];
              
              self.$utils.sortArray( data.element.children, ( a ) => ( a.name ), self.sortDec );
              data.element.children.forEach( ( ch ) => 
              { 
                  ch.nameAttr = attr || self.node.nameAttr;
                  ch.filled = false;
                  ch.opened = false;
                  ch.has_children = ( ch.children_count - ( ch.data_contents || [] ).length > 0 );
                  ch.children = [];
                  ch.parent = self.node;
                  ch.root_version = self.node.root_version;
                  self.node.children.push( ch );
              } );
              if( this.showFiles )
              {
                  data.element.data_contents.forEach( ( fl ) => 
                  {
                      var name = fl.title;
                      self.node.children.push( { has_children: false, name: name } );
                  });
              }
              self.node.filled = true;
              self.node.opened = true;
              self.$modalDlg.sysDlgs.progressDlg.close();
          })
                .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.$eventBus.$emit( 'queryError', err );
          });
      }
  },
  components: {
  },
  mounted: function()
  {
  },
  beforeDestroy: function()
  {
  }
}
</script>

<style scoped>
    .tree_view ol, ul
    {
        padding-left: 15px;
        padding-right: 0px; 
    }
    
    .tree_view span
    {
        padding-right: 0px; 
    }
    
    .tree_view 
    {
        padding-right: 0px; 
    }
    
    .select
    {
        font-weight: 700;
    }
    
    .flip-list
    {
        transition: transform 1s;
    }
    
    .ch-list-enter-active, .ch-list-leave-active 
    {
        transition: opacity .5s;
    }
    .ch-list-enter, .ch-list-leave-to
    {
        opacity: 0;
    }
    
    .arrow-enter-active, .arrow-leave-active 
    {
        transition: transform 1.3s;
    }
    .rot_trans
    {
        transform: rotate( -90deg );
    }
    
    .tree_node .v-list-item__action
    {
        margin-right: 0px;
        margin-top: 0px;
    }

    .tree_node 
    {
        font-size: 12px;        
    }
   
</style>
