<template>
    <div style="height: 100%;">
        <v-tabs v-model="active" show-arrows ref="tabs" @change="tabClick" grow>
            <v-tab href="#tab-1">{{ $t( 'message.structure_tb' ) }}</v-tab>
            <v-tab href="#tab-2">{{ $t( 'message.props_tb' ) }}</v-tab>
            <v-tab href="#tab-3">{{ $t( 'message.user_props_tb' ) }}</v-tab>
            <v-tab-item value="tab-1">
               <node-list-viewS smallWin="true"></node-list-viewS>
            </v-tab-item>
            <v-tab-item value="tab-2">
               <pnl-props-view forSys="true" smallWin="true" elemID="2"></pnl-props-view> 
            </v-tab-item>
            <v-tab-item value="tab-3">
               <pnl-props-view smallWin="true"></pnl-props-view> 
            </v-tab-item>
        </v-tabs>
    </div>
</template>

<script>
    import NodeList from '../NodeList.vue'
    import PnlProps from '../Pnl_Props.vue'
export default {
  name: 'home',
  data () 
  {
    return {
        active: null
    };
  },
  methods: {
      tabClick()
      {
          if( this.$refs.tabs )
            this.$refs.tabs.onResize();
      }
    },
  components: {
     'node-list-viewS': NodeList,
     'pnl-props-view': PnlProps
  },
  beforeMount: function()
  {
  },
  mounted: function()
  {
      var self = this;
      this.$eventBus.$on( 'nodeInfo', function( data )
      {
          self.active = 'tab-2';
      });
      
      this.$eventBus.$on( 'goInNode', function( data )
      {
          self.active = 'tab-1';
      });
      
      this.$eventBus.$emit( 'reInitState', {} );
  },
  beforeDestroy: function()
  {
      this.$eventBus.$off( 'goInNode' );
      this.$eventBus.$off( 'nodeInfo' );
  }
}
</script>

<style scoped>
</style>
