<template>
    
            <v-dialog v-model="visFl" persistent max-width="600">
                <v-toolbar flat color="white">
                    <v-toolbar-title>{{ $t( 'message.role_list_title' ) }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn color="green" left dark @click.native="addNew()" v-show="node.is_root_of_subdomain && rolesForUse.length > 0">{{ $t( 'message.add_btn' ) }}</v-btn>
                </v-toolbar>
               <v-card>
                   
                <v-card-text>
                    <div v-show="newItem">
                        <v-form ref="form1" v-model="valid">
                            <v-container>
                                <v-layout>
                                  <v-flex md5 >
                                    <v-select :label="$t( 'message.role_list_name' )" item-text="txt" 
                                                required :rules="[v => !!v || $t( 'message.value_required' )]"
                                                item-value="val" v-model="newName" :items="rolesForUse" ></v-select>
<!--                                    <v-text-field :label="$t( 'message.role_list_name' )" v-model="newName" 
                                        required :rules="[v => !!v || $t( 'message.value_required' )]"></v-text-field>-->
                                  </v-flex>
                                  <v-flex md5>

                                    <v-select :label="$t( 'message.role_list_perms' )" item-text="txt" item-disabled="dis"
                                        required :rules="[v => { if( !v || v.length < 1 ) return $t( 'message.value_required' ); else return true; } ]"
                                            item-value="val" v-model="newPerms" :items="perms" multiple></v-select>    
                                  </v-flex>
                                  <v-flex md1>
                                      <div style="white-space: nowrap; padding-top: 24px;">
                                        <v-icon @click="submitNew()">done</v-icon>
                                        <v-icon @click="cancelNew()">clear</v-icon>
                                      </div>
                                  </v-flex>
                                </v-layout>
                            </v-container>
                        </v-form>
                    </div>
                    <v-list two-line>
                        <v-list-item v-for="(item, index) in items" :key="item.name"  @click="">
                            <v-list-item-content>
                                <v-container v-if="item.isEdit">
                                    <v-layout>
                                      <v-flex xs6 md5 >
                                          <v-text-field :label="$t( 'message.role_list_name' )" v-model="item.nameV" readonly></v-text-field>
                                      </v-flex>
                                      <v-flex xs6 md5 offset-md1 >
                                        <v-select :label="$t( 'message.role_list_perms' )" item-text="txt" required item-disabled="dis"
                                                item-value="val" v-model="item.permsNew" :items="perms" multiple ></v-select>    
                                      </v-flex>
                                    </v-layout>
                                </v-container>
                                        
                                <v-list-item-title v-else>
                                    <b>{{item.nameV}}</b> ( {{item.permsV}} )
                                </v-list-item-title>
                                
                            </v-list-item-content>
                            
                            <v-list-item-action>
                                <span style="white-space: nowrap">
                                    <v-icon @click="editItem( index )" v-show="!item.isEdit && !item.isNew">edit</v-icon>
                                    <v-icon @click="submitEdit( index )" v-show="item.isEdit || item.isNew">done</v-icon>
                                    <v-icon @click="cancelEdit( index )" v-show="item.isEdit || item.isNew">clear</v-icon>
                                    <v-icon @click="deleteItem( index )" v-show="node.is_root_of_subdomain">delete_forever</v-icon>
                                </span>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                    
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click.native="close()">{{ $t( 'message.close_btn' ) }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>   
    
</template>

<script>
    
import Vue from 'vue'
import{ conf } from '../../utils/confTruePLM';

export default {
  name: 'memberList',
  data () 
  {
    return {
        visFl: false,
        items: [],
        perms: [ { txt: 'Read', val: 'r' }, { txt: 'Write', val: 'w' }, { txt: 'Delete', val: 'd' } ],
        node: {},
        roles: [],
        rolesForUse: [],
        newItem: false,
        newName: null,
        newPerms: [],
        changed: false,
        valid: true,
    };
  },
  computed: {
  },
  methods: {
      checkReadRole( item )
      {
          if( item.permsNew.length < 1 )
              return;
          
          let r = false;
          item.permsNew.forEach( ( elem ) =>
          {
              if( elem === 'r' )
                  r = true;
          });
          if( !r )
              item.permsNew.unshift( 'r' );
      },
      submitNew()
      {
          //console.log( this.newPerms );
          if( !this.$refs.form1.validate() )
              return;
          
          var self = this;
          this.newItem = false;
          this.checkReadRole( { permsNew: this.newPerms } );
          self.$modalDlg.sysDlgs.progressDlg.open();
          conf.roleAdd( this.node, this.newName, this.newPerms )
                  .then( ( data ) =>
          {
                self.fillRoles( data );
                self.$modalDlg.sysDlgs.progressDlg.close();
          })
                .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      cancelNew()
      {
          this.newItem = false;
      },
      submitEdit( ind )
      {
          var self = this;
          this.items[ ind ].isEdit = false;
          this.checkReadRole( this.items[ ind ] );
          self.$modalDlg.sysDlgs.progressDlg.open();
          conf.roleAdd( this.node, this.items[ ind ].name, this.items[ ind ].permsNew )
                  .then( ( data ) =>
          {
                self.fillRoles( data );
                self.$modalDlg.sysDlgs.progressDlg.close();
          })
                .catch( ( err ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      cancelEdit( ind )
      {
          this.items[ ind ].isEdit = false;
          Vue.set( this.items, ind, this.items[ ind ] );
      },
      addNew()
      {
          this.newPerms = [];
          this.newItem = true;
      },
      editItem( ind )
      {
          this.items[ ind ].isEdit = true;
          var p = [];
          this.items[ ind ].perms.forEach( ( el ) =>
          {
              p.push( el );                            
          });
          this.items[ ind ].permsNew = p;
          Vue.set( this.items, ind, this.items[ ind ] );
      },
      deleteItem( ind )
      {
          var self = this;
          this.$modalDlg.sysDlgs.confirmDlg.open( 'conf_del_role_title', 'conf_del_role_msg' )
                  .then( () =>
          {
              self.$modalDlg.sysDlgs.progressDlg.open();
              return conf.roleDelete( this.node, this.items[ ind ].name );
          }, () => { throw new Error( 'canceled' ); } ) 
                  .then( ( data ) =>
          {
                self.fillRoles( data );
                self.$modalDlg.sysDlgs.progressDlg.close();
          })
                   .catch( ( err ) =>
          {
               self.$modalDlg.sysDlgs.progressDlg.close();
               if( err.message !== 'canceled' )
                  self.$eventBus.$emit( 'queryError', err );
          });
      },
      fillRoles( data )
      {
          var self = this;
          self.items = [];
          self.rolesForUse = [];
          self.rolesForUse = self.rolesForUse.concat( self.roles );
          data.forEach( ( elem ) =>
          {
              for( let i = 0; i < self.rolesForUse.length; i++ )
              {
                  if( self.rolesForUse[ i ].val === elem.name )
                  {
                      self.rolesForUse.splice( i, 1 );
                      break;
                  }
              }
              
              let permsV = '';
              let perms = [];
              if( elem.read )
              {
                  perms.push( 'r' );
                  permsV += 'Read';
              }
              if( elem.write )
              {
                  perms.push( 'w' );
                  if( permsV !== '')
                      permsV += ', ';
                  permsV += 'Write';
              }
              if( elem.delet )
              {
                  perms.push( 'd' );
                  if( permsV !== '')
                      permsV += ', ';
                  permsV += 'Delete';
              }
              
              if( elem.name !== 'urn:rdl:epm-std:ALL' )
                  this.items.push( { name: elem.name, nameV: this.$utils.clearType( elem.name ), perms: perms, permsV: permsV } );
          });
      },
      open( node )
      {
          this.node = node;
          this.changed = false;
          this.visFl = true;
                    
          var self = this;      
          conf.getChildTypesWithStatus( 'urn:rdl:epm-std:project_role' )
                  .then( ( data ) =>
          {
              self.roles = [];
              data.forEach( ( elem ) =>
              {
                  if( elem.rdlInfo.class_uri !== 'urn:rdl:epm-std:ALL' )
                      self.roles.push( { val: elem.rdlInfo.class_uri, txt: this.$utils.clearType( elem.rdlInfo.class_uri ) } );
              });
              return conf.roleList( node );
          })
                  .then( ( data ) =>
          {
              self.fillRoles( data );                            
          });
          
          return new Promise( ( resolve, reject ) => 
          {
            this.resolve = resolve;
            this.reject = reject;
          });
      },
      close()
      {
          this.visFl = false;
          this.resolve( this.changed );
      }
    }
}
</script>



