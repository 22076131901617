<template>
            <v-dialog v-model="visFl" persistent max-width="290">
               <v-card>
                <v-card-title class="headline">
                    <span v-if="!isAdmin">{{ $t( 'message.set_pass_title' ) }}</span>
                    <span v-else>{{ $t( 'message.set_pass_admin_title', { val: user } ) }}</span>
                </v-card-title>
                <v-card-text>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        
                        <v-text-field :label="$t( 'message.set_pass_old' )" required v-model="old_pass" type="password"  id="u_pass0"
                            :rules="oldPassRules" autocomplete="off" v-show="!isAdmin"></v-text-field>
                        <v-text-field :label="$t( 'message.set_pass_new1' )" required v-model="new_pass1" type="password"  id="u_pass1"
                            :rules="[v => !!v || $t( 'message.value_required' )]" autocomplete="off"></v-text-field>
                        <v-text-field :label="$t( 'message.set_pass_new2' )" required v-model="new_pass2" type="password"  id="u_pass2"
                                      :rules="passRules" autocomplete="off"></v-text-field>
                    </v-form>
                    <small style="color: red">{{ $t( 'message.set_pass_token_msg' ) }}</small>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text :disabled="!valid" @click.native="doAct()">{{ $t( 'message.ok_btn' ) }}</v-btn>
                    <v-btn color="green darken-1" text @click.native="close()">{{ $t( 'message.cancel_btn' ) }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>   
</template>

<script>
    
import Vue from 'vue'

export default {
  name: 'createUser',
  data () 
  {
    return {
        visFl: false,
        user: null,
        old_pass: null,
        new_pass1: null,
        new_pass2: null,
        isAdmin: false,
        resolve: null,
        reject: null,
        valid: true,
        oldPassRules: [v => ( !!v || this.isAdmin ) || this.$t( 'message.value_required' )],
        passRules: [v => !!v || this.$t( 'message.value_required' ), 
                                      v => v === this.new_pass1 || this.$t( 'message.wrong_pass' ) ]
    };
  },
  computed: {
  },
  methods: {
      checkName( val )
      {
          this.name = val.toLowerCase();
      },
      open( user, isAdmin )
      {
          this.isAdmin = isAdmin;
          this.user = user;
          
          this.old_pass = null;
          this.new_pass1 = null;
          this.new_pass2 = null;
          
          this.visFl = true;
          
          return new Promise( ( resolve, reject ) => 
          {
            this.resolve = resolve;
            this.reject = reject;
          });
      },
      close()
      {
          this.visFl = false;
          this.reject();
      },
      doAct()
      {
          if( this.$refs.form.validate() )
          {
              this.visFl = false;
              this.resolve( { oldPass: this.old_pass, newPass: this.new_pass2 } );
          }
      }
    }
}
</script>



