<template>

    <v-dialog v-model="visFl" persistent max-width="350">
       <v-card>
        <v-card-title class="headline">
          {{ $t( 'message.add_pmsg_title' ) }}
        </v-card-title>
        <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
                <!-- <v-select :label="$t( 'message.add_pmsg_type' )" v-model="info.type" :readonly="isRep" 
                          :items="types" required :rules="[v => !!v || $t( 'message.value_required' )]"></v-select> -->
                
                <v-select :label="$t( 'message.add_pmsg_status' )" v-model="info.status" v-if="info.type !== 'Info'"  
                          :items="statuses" required :rules="[v => !!v || $t( 'message.value_required' )]"></v-select>
                
                <v-text-field :label="$t( 'message.add_pmsg_subj' )" v-model="info.subj" required
                              :rules="[v => !!v || $t( 'message.value_required' )]"></v-text-field>
                
                <v-textarea :label="$t( 'message.add_pmsg_text' )" required v-model="info.text"
                        :rules="[v => !!v || $t( 'message.value_required' )]"></v-textarea>
                
                <v-select :label="$t( 'message.add_pmsg_users' )" v-model="info.users" item-text="txt" item-value="val" 
                            :items="users" multiple required :rules="[v => !!v || $t( 'message.value_required' )]"></v-select>
                
                <date-input-view :model="info.end_date" :label_txt="$t( 'message.add_pmsg_end_date' )" 
                                 ref="end_date" v-if="info.type !== 'Info'"></date-input-view>
                
                <v-text-field :label="$t( 'message.add_pmsg_node' )" dense v-model="nodeName" v-if="nodeName && !docName" readonly></v-text-field>
                <v-text-field :label="$t( 'message.add_pmsg_doc' )" dense v-model="docName" v-if="docName" readonly></v-text-field>

            </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn color="green darken-1" text :disabled="!valid" @click.native="doAct()">{{ $t( 'message.ok_btn' ) }}</v-btn>
            <v-btn color="green darken-1" text @click.native="close()">{{ $t( 'message.cancel_btn' ) }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>   
    
</template>

<script>
    
import Vue from 'vue'

import DateInput from '../DateInput.vue'

import{ conf } from '@/utils/confTruePLM';

export default {
  name: 'addP2PMsg',
  data () 
  {
    return {
        visFl: false,
        node: null,
        doc: null,
        
        types: [ 'Info' ],
        statuses: [ 'Open', 'In progress', 'Completed' ],
        info: {},
        isRep: false,
        users: [],
        resolve: null,
        reject: null,
        valid: true,
        active: null
    };
  },
  computed: {
      nodeName()
      {
          return this.node ? this.node.name : null;
      },
      docName()
      {
          return this.doc ? this.doc.title : null;
      }
  },
  components: {
      'date-input-view': DateInput
  },
  methods: {
      open( node, doc, subj, users, type, status, endDate, proj )
      {
          let self = this;
          
          conf.getConcept( 'Task_status', proj )
                  .then( data => 
          {
              self.statuses = [];
              ( data.oneof_elements || [] ).forEach( el =>
              {
                  self.statuses.push( this.$utils.clearType( el ) );
              });
          });
      //    console.log( users );
          this.node = node;
          this.doc = doc;
          this.isRep = !!type;
          if( type === 'Issue' )
            this.types = [ 'Issue' ];
          else if( type === 'Action' )
            this.types = [ 'Action' ];
          this.info = { subj: subj, users: users, type: type || 'Info' };
          if( type !== 'Info' )
          {
              this.info.status = status;
              this.info.end_date = endDate;
          }
          this.users = [];
          conf.getProjectUsersEnum( proj )
                  .then( ( data ) =>
          {
              self.users = data;
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
          
          this.visFl = true;
          
          return new Promise( ( resolve, reject ) => 
          {
            this.resolve = resolve;
            this.reject = reject;
          });
      },
      close()
      {
          this.visFl = false;
          this.reject();
      },
      doAct()
      {
          if( this.$refs.form.validate() )
          {
                this.visFl = false;
                if( this.node )
                    this.info.node = JSON.stringify( { instance_id: this.node.instance_id, name: this.node.name } );
                if( this.doc )
                    this.info.doc = JSON.stringify( { instance_id: this.doc.instance_id, title: this.doc.title } );
          
                this.info.end_date = ( this.$refs.end_date && this.$refs.end_date.computedDateFormatted ) ? this.$refs.end_date.computedDateFormatted : null;
          
                if( this.info.type === 'Info' )
                {
                    this.info.status = null;
                    this.info.end_date = null;
                }
                this.resolve( this.info );
          }
      }
    }
}
</script>





