<template>
    
  <v-app id="kcl_login">
    <v-main>
       
    </v-main>
    
  </v-app>
    
</template>

  <script>
      
  import { v4 as uuidv4 } from 'uuid';
  
  export default {
    data() {
      return {
          globalState: this.$globalStore.state
      };
    },
    computed: {
              
    },
    methods: 
    {
      
    },
    mounted: function()
    {
        let reqInfo = { id: 'keycloak', 
            auth_url: this.globalState.initInfo.keycloak_url + '/protocol/openid-connect/auth',
            callback_url: window.location.href.replace( '/#/kcllogin', '/kclcallback.html' ),
            client_id: this.globalState.initInfo.keycloak_id,  
            scope: 'openid email profile',
            response_type: 'code',
            state: uuidv4() };
        
        let url = this.$utils.getAuthURL( reqInfo );
        
        localStorage.setItem( 'keycloak-' + reqInfo.state, JSON.stringify( reqInfo ) );

        window.location.href = url;
    },
    components: {
        
    }
  }
  </script>