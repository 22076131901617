<template>
    
  <v-app id="ggl_login">
    <v-main>
       
    </v-main>
    
  </v-app>
    
</template>

  <script>
      
  import { v4 as uuidv4 } from 'uuid';
  
  export default {
    data() {
      return {
          globalState: this.$globalStore.state
      };
    },
    computed: {
              
    },
    methods: 
    {
      
    },
    mounted: function()
    {
        let reqInfo = { id: 'google', 
            auth_url: 'https://accounts.google.com/o/oauth2/v2/auth',
            callback_url: window.location.href.replace( '/#/ggllogin', '/gglcallback.html' ),
            client_id: this.globalState.initInfo.google_id, 
            scope: 'openid email profile',
            response_type: 'code',
            state: uuidv4() };
        
        let url = this.$utils.getAuthURL( reqInfo );
        
        localStorage.setItem( 'google-' + reqInfo.state, JSON.stringify( reqInfo ) );

        window.location.href = url;
        
//        let callBackUrl = window.location.href.replace( '/#/ggllogin', '/gglcallback.html' );
//        let ggl = new OAuth.Provider({
//                id: 'google',
//                authorization_url: 'https://accounts.google.com/o/oauth2/v2/auth'
//            });
//            
//        // Create a new request
//        let request = new OAuth.Request({
//            client_id: this.globalState.initInfo.google_id, 
//            redirect_uri: callBackUrl,
//            scope: 'openid email profile'
//        });
//
//        // Give it to the provider
//        let uri = ggl.requestToken( request );
//
//        // Later we need to check if the response was expected
//        // so save the request
//        ggl.remember( request );
//
//        // Do the redirect
//        window.location.href = uri;
             
    },
    components: {
        
    }
  }
  </script>