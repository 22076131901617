<template>
    <v-card>
      <v-data-table :headers="heads" :items="items" :sort-by="sortProp" :sort-desc=true hide-default-footer class="elevation-1 propTbl"
                    :items-per-page=-1 fixed-header height="calc(100vh - 130px)">
        <template v-slot:header.name="{ header }">
            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
        </template>
        <template v-slot:header.type="{ header }">
            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
        </template>
         <template v-slot:header.status="{ header }">
            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
        </template>
        <template v-slot:header.dataSize="{ header }">
            {{ $t( 'message.full_custom_title', { cur_ttl: $t(`${header.text}`) } ) }}
        </template>
        <template v-slot:header.act="{ header }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on" @click="addProj()">add</v-icon>
                </template>
                <span>{{ $t( 'message.proj_list_add_btn' ) }}</span>
            </v-tooltip>
        </template>
        
        <template v-slot:item="{ item }">
            <tr>
                  <td>{{ item.name }}</td>
                  <td>{{ item.type }}</td>
                  <td>{{ item.status }}</td>
                  <td style="text-align: right;">{{ item.dataSize }} M</td>
                  <td nowrap>
                      <v-tooltip bottom v-if="item.obj.is_deleted">
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" @click.native="setAct( item )">restore</v-icon>
                            </template>
                            <span>{{ $t( 'message.proj_list_act_btn' ) }}</span>
                      </v-tooltip>
                      <v-tooltip bottom v-else>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" @click.native="setInact( item )">delete</v-icon>
                            </template>
                            <span>{{ $t( 'message.proj_list_inact_btn' ) }}</span>
                      </v-tooltip>
                      <v-tooltip bottom v-if="item.obj.is_deleted">
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" @click.native="deleteItem( item )">delete_forever</v-icon>
                            </template>
                            <span>{{ $t( 'message.proj_list_delete_btn' ) }}</span>
                      </v-tooltip>
                  </td>
            </tr>
        </template>
      </v-data-table>
    </v-card>
</template>

<script>
import{ conf } from '@/utils/confTruePLM';

export default {
  name: 'ProjList',
  props: {
//      proj: null
  },
  data () {
    return {
        sortProp: 'status',
        heads: [
            { text: 'message.proj_list_name_col', value: 'name' }, 
            { text: 'message.proj_list_type_col', value: 'type' }, 
            { text: 'message.proj_list_status_col', value: 'status' }, 
            { text: 'message.proj_list_size_col', value: 'dataSize', align: 'end' },
            { width: '10%', sortable: false, value: 'act' }
        ],
        globalState: this.$globalStore.state,
        items : []
    };
  },
  methods: 
  {
      addProj()
      {
          let self = this;
          self.$modalDlg.sysDlgs.createProjDlg.open( true )
                  .then( ( proj ) =>
          {
              if( proj )
                  self.createProj( proj );  
          }, () => {} );   
      },
      createProj( info )
      {
          let self = this;
          self.$modalDlg.sysDlgs.progressDlg.open();
          conf.createProj( info.name, info.descr, info.files, info.type, info.tmpl )
                  .then( ( rez ) =>
          {
              self.$modalDlg.sysDlgs.progressDlg.close();
              self.procList();  
              self.$eventBus.$emit( 'updateProjList' );     
              if( rez )
                  this.$modalDlg.sysDlgs.confirmDlg.open( 'create_proj_done_title', 'create_proj_done_msg', null, info.name, true );
          }, ( err )=>
          { 
              self.$modalDlg.sysDlgs.progressDlg.close();
              if( err.message === 'Rstat Error: 100002' )
                  err.message = 'Wrong proj name';
              self.$eventBus.$emit( 'queryError', err ); 
          } );          
      },
      setInact( item )
      {
          let self = this;
          this.$modalDlg.sysDlgs.confirmDlg.open( 'proj_list_inact_title', 'proj_list_inact_msg', null, item.name )
                  .then( () =>
          {
              return conf.inactiveProj( item.obj.guid );
          }, () => {} )
                  .then( () => 
          {
              self.procList();  
              self.$eventBus.$emit( 'updateProjList' );               
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });      
      },
      setAct( item )
      {
          let self = this;
          this.$modalDlg.sysDlgs.confirmDlg.open( 'proj_list_act_title', 'proj_list_act_msg', null, item.name )
                  .then( ( info ) =>
          {
              return conf.activeProj( item.obj.guid );
          }, () => {} )
                  .then( () => 
          {
              self.procList();  
              self.$eventBus.$emit( 'updateProjList' );               
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });      
      },
      deleteItem( item )
      {
          let self = this;
          this.$modalDlg.sysDlgs.confirmDlg.open( 'proj_list_delete_title', 'proj_list_delete_msg', null, item.name )
                  .then( () =>
          {
              return conf.deleteProj( item.obj.guid );
          }, () => {} )
                  .then( () =>
          {
              self.procList();              
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      },
      procList()
      {
          this.items = [];
          let self = this;
          conf.getAllProj()
                  .then( ( data ) =>
          {
            // console.log( data );
              if( !data )
                  return;
              
              data.forEach( ( elem ) =>
              {
                  let size = Math.round( elem.dataSize * 1000 / 1024 / 1024 ) / 1000;
                  size = size.toFixed( 3 );
                  self.items.push( { name: elem.name, dataSize: size, status: elem.is_deleted ? 'Inactive' : 'Active', obj: elem,
                      type: elem.is_bkd_template_project ? 'Breakdown template' : elem.is_template_project ? 'Project template' : 'Project' } );                                        
              });     
          })
                  .catch( ( err ) =>
          {
              self.$eventBus.$emit( 'queryError', err );
          });
      }
  },
  mounted: function() 
  { 
      let self = this;
      this.$eventBus.$on( 'editProjList', function()
      {
          self.procList();
      });
  },
  beforeDestroy: function()
  {
      this.$eventBus.$off( 'editProjList' );
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .propTbl td
    {
        height: 30px;
    }        
</style>
